import { GetterTree } from './types'
import { useAgentStore } from 'Store/stores'

export const getUnreadCounter: GetterTree['getUnreadCounter'] = function ({
  inceptionChats,
}) {
  const currentAgent = useAgentStore().currentAgent

  if (!currentAgent || !inceptionChats) return null

  return inceptionChats.filter((chat) =>
    chat.mustReadAgentIds.includes(currentAgent.id),
  ).length
}

export const getRoomUnreadCounter: GetterTree['getRoomUnreadCounter'] =
  function ({ inceptionChats }) {
    return (roomId, agentId) => {
      if (!inceptionChats) return null
      return inceptionChats.filter(
        (chat) =>
          chat.mustReadAgentIds.includes(agentId) && chat.roomId === roomId,
      ).length
    }
  }
