<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import useScopedI18n from '@/hooks/scoped-i18n'
import useServices from '@/hooks/services'
import { isEmpty } from '@/utils/is-empty'
import { useSavedReplyStore, useCoreChatStore } from 'Store/stores'

import Actions from '@/components/panel/middle/chat/footer/chat-area/Actions.vue'
import SavedReplyPicker from '@/domains/saved-reply/views/SavedReplyPicker.vue'
import BaseRichTextEditor from '@/components/base/BaseRichTextEditor.vue'
import BaseSimpleTextEditor from '@/components/base/BaseSimpleTextEditor.vue'
import QuickReplyPicker from '@/domains/saved-reply/views/QuickReplyPicker.vue'

const props = defineProps({
  roomId: {
    type: String,
    required: true,
  },
})

const { t } = useScopedI18n()
const { isSavedReplyPickerOpen } = storeToRefs(useSavedReplyStore())
const { toggleSavedReplyPicker } = useSavedReplyStore()
const { postText, postImage } = useCoreChatStore()
const services = useServices()

const resetText = () => (text.value = '')
const text = ref<string>('')
const simpleTextEditor = ref<InstanceType<typeof BaseSimpleTextEditor> | null>(
  null,
)
const richTextEditor = ref<InstanceType<typeof BaseRichTextEditor> | null>(null)
const isChatareaFocused = ref(false)
const isSpellChecking = ref(false)

const textEditor = computed(
  () => simpleTextEditor.value || richTextEditor.value,
)
const hasMessage = computed(() => !!text?.value)
const draft = computed(() =>
  services.localStorage.getDraftMessage(props.roomId),
)

// Actions
const appendText = (textToAppend: string) => {
  text.value = isEmpty(text.value)
    ? textToAppend
    : `${text.value}\n\n${textToAppend}`
  hideSavedReplyPicker()
}
const editText = (textToAppend: string) => {
  text.value = textToAppend
  if (richTextEditor.value) richTextEditor.value.setText(text.value)
}

const sendText = (newText?: string) => {
  postText(newText || text.value)
  hideSavedReplyPicker()
  resetText()
}
const sendImage = (image: File | string) => {
  postImage(image)
  hideSavedReplyPicker()
}
const insertEmoji = (unicode: string) => {
  if (textEditor.value) textEditor.value.insertText(unicode)
}
const showSavedReplyPicker = () => (isSavedReplyPickerOpen.value = true)
const hideSavedReplyPicker = () => (isSavedReplyPickerOpen.value = false)

// while
watch(
  () => props.roomId,
  () => {
    // Apply draft or reset text value
    text.value = draft.value ?? ''
  },
  { immediate: true },
)

watch(
  () => text.value,
  (newText) => {
    // Prevent loop
    if (newText === draft.value) return

    if (newText === '/') {
      showSavedReplyPicker()
      resetText()
      return
    }

    services.localStorage.setDraftMessage(props.roomId, newText)
  },
  { immediate: true },
)
</script>

<template>
  <div class="flex flex-col pb-5 px-5">
    <div
      class="relative border rounded transition-all duration-300"
      :class="
        isChatareaFocused
          ? 'border-primary shadow-xl border-opacity-40'
          : 'border-default-100'
      "
    >
      <QuickReplyPicker
        v-if="text && !isSavedReplyPickerOpen"
        :text="text"
        @edit-text="editText"
      />
      <SavedReplyPicker
        v-if="isSavedReplyPickerOpen"
        v-keydown:Escape="toggleSavedReplyPicker"
        v-click-outside="toggleSavedReplyPicker"
        @append-text="appendText"
        @send-image="sendImage"
      />
      <div v-else>
        <BaseRichTextEditor
          v-if="$canSpellcheckMessage()"
          :key="`room-rich-text-editor-${roomId}`"
          ref="richTextEditor"
          v-model="text"
          :spellcheck="true"
          :placeholder="t('placeholder')"
          @focus="isChatareaFocused = true"
          @blur="isChatareaFocused = false"
          @send-text="sendText"
          @checking="isSpellChecking = $event"
        />
        <BaseSimpleTextEditor
          v-else
          :key="`room-simple-text-editor-${roomId}`"
          ref="simpleTextEditor"
          v-model:text="text"
          :placeholder="t('placeholder')"
          @focus="isChatareaFocused = true"
          @blur="isChatareaFocused = false"
          @send-text="sendText"
        />
        <div class="flex w-full px-5">
          <div class="border-b border-default-50 flex flex-1"></div>
        </div>
      </div>

      <Actions
        :is-saved-reply-picker-open="isSavedReplyPickerOpen"
        :has-message="hasMessage"
        :spellchecker-enabled="$canSpellcheckMessage()"
        :is-spell-checking="isSpellChecking"
        v-bind="$attrs"
        @send-text="sendText"
        @send-image="sendImage"
        @pick-emoji="insertEmoji"
      />
    </div>
  </div>
</template>

<style scoped>
textarea {
  font-family: ui-sans-serif, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
</style>
