import { GetterTree } from './types'
import { buildRoomListClosed } from './concerns/build-assigned-room-lists'
import { useAgentStore, useRoomStore } from 'Store/stores'

export const getRoomListClosed: GetterTree['getRoomListClosed'] = function () {
  return (pickedAgentId) => {
    const closedRooms = useRoomStore().getClosedRooms
    const agents = useAgentStore().agents

    if (!closedRooms || !agents) return null

    return buildRoomListClosed(agents, closedRooms, pickedAgentId)
  }
}
