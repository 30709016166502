<script lang="ts" setup>
import { computed, ref, provide, ComponentInternalInstance, Ref } from 'vue'
import { TabsStateKey } from './symbols'

const props = defineProps({
  modelValue: {
    type: [String, Number],
  },
  navClass: {
    type: String,
  },
  tabClass: {
    type: String,
  },
})

const emit = defineEmits(['update:modelValue'])

const active = computed(() => props.modelValue)
const tabs: Ref<ComponentInternalInstance[]> = ref([])

const selectTab = (tab: unknown) => {
  emit('update:modelValue', tab)
}

provide(TabsStateKey, {
  active,
  tabs,
})
</script>

<template>
  <ul class="flex space-x-4" :class="navClass">
    <li
      v-for="(tab, index) of tabs"
      :key="index"
      :class="
        active === index
          ? 'border-b-2 border-primary box-content'
          : 'cursor-pointer border-b-2 border-white text-primary'
      "
      class="flex items-center py-2 rounded-tl-md rounded-tr-md overflow-hidden hover:border-primary text-sm"
      @click="selectTab(index)"
    >
      {{ tab.props.title }}
    </li>
  </ul>
  <slot />
</template>
