import { IAgentService } from 'Services/__types__'
import { IFirebaseService } from 'External/firebase/types'
import { Agent } from 'Models/agent'
import { convertFrom } from 'Models/converters/agent-converter'
import {
  CollectionReference,
  DocumentReference,
  Query,
  query,
  orderBy,
} from 'firebase/firestore'

export default class AgentService implements IAgentService {
  firebaseService: IFirebaseService

  constructor(firebaseService: IFirebaseService) {
    this.firebaseService = firebaseService
  }

  listenAll(onChange: (newAgents: Agent[]) => void): void {
    this.firebaseService.listenCollection(
      'agents',
      this.findAll(),
      (newAgents: Agent[]) => {
        onChange(newAgents)
      },
    )
  }

  listen(agentId: string, onChange: (newAgent: Agent) => void): void {
    return this.firebaseService.listenDocument(
      `agent-${agentId}`,
      this.find(agentId),
      (newAgent: Agent) => {
        onChange(newAgent)
      },
    )
  }

  load(agentId: string): Promise<Agent | undefined> {
    return this.firebaseService.loadDocument(this.find(agentId))
  }

  protected findAll(): Query<Agent> {
    return query(this.getCollection(), orderBy('firstName', 'asc'))
  }

  protected find(agentId: string): DocumentReference<Agent> {
    return this.firebaseService.getDocument('agents', agentId, convertFrom)
  }

  protected getCollection(): CollectionReference<Agent> {
    return this.firebaseService.getCollection(
      this.getCollectionName(),
      convertFrom,
    )
  }

  protected getCollectionName(): string {
    return 'agents'
  }
}
