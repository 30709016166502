<script setup lang="ts">
import { PropType, computed } from 'vue'

const props = defineProps({
  hint: {
    type: String,
    required: true,
  },
  suggestions: {
    type: Object as PropType<string[]>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'select', suggestion: string): void
}>()

const hasSuggestions = computed(() => props.suggestions.length > 0)
</script>

<template>
  <div
    className="            
            shadow-lg   
            rounded-md
            focus:outline-none
          "
  >
    <h3
      class="px-3 rounded-t-md bg-default-50 border border-default-200 py-2 font-semibold text-xs uppercase"
    >
      💡&nbsp;{{ hint }}
    </h3>
    <div
      class="bg-white rounded-b-md border border-t-0 border-default-200 px-3 py-2"
    >
      <ul v-if="hasSuggestions">
        <li
          v-for="(suggestion, index) in suggestions"
          :key="index"
          class="text-default-500 hover:text-default-900 cursor-pointer"
          @click="emits('select', suggestion)"
        >
          {{ suggestion }}
        </li>
      </ul>
      <p v-else>{{ $t('richTextEditor.spellchecker.noSuggestions') }}</p>
    </div>
  </div>
</template>
