import { ActionTree } from './types'
import { refreshCurrentAgent } from './refresh-current-agent'
import { setAgentGroups } from './set-agent-groups'
import { setCurrentAgent } from './set-current-agent'
import { setAgentsPresence } from './set-agents-presence'
import { setAgents } from './set-agents'

export const actions: ActionTree = {
  refreshCurrentAgent,
  setAgentGroups,
  setCurrentAgent,
  setAgentsPresence,
  setAgents,
}
