<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import { PropType, ref, watch } from 'vue'
import { sleep } from '@/utils/sleep'
import { Message } from 'Models/message'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseLoader from '@/components/base/BaseLoader.vue'
import Spacer from '../shared/Spacer.vue'
import BaseAccordion from '@/components/base/BaseAccordion.vue'

const props = defineProps({
  src: {
    type: String as PropType<Message['content']>,
    required: true,
  },
})

const { t, mt } = useScopedI18n()

const isMediaLoaded = ref(false)
const video = ref()

const onMediaLoad = () => (isMediaLoaded.value = true)

watch(
  () => props.src,
  async () => {
    isMediaLoaded.value = false
    video.value.load()
    await sleep(1000) // hack
    isMediaLoaded.value = true
  },
)
</script>

<template>
  <BaseLoader :is-video="true" :is-loading="!isMediaLoaded">
    <video
      ref="video"
      controls
      disablepictureinpicture
      download
      class="max-w-full max-h-full"
      @loadeddata="onMediaLoad"
    >
      <source :src="src" type="video/mp4" />
    </video>

    <Spacer />
    <BaseAccordion header-classes="py-5" class="border-t border-default-100">
      <template #title>
        {{ t('title') }}
      </template>

      <div class="p-3 rounded-lg bg-default-100">
        <ul class="space-y-5">
          <li>
            <p v-html="mt('stepA')" />
          </li>
          <li>
            {{ t('stepB') }} (<a
              href="https://www.loom.com/share/9ce886d657a14b5883a86d675376a956"
              target="_blank"
              class="text-primary"
              >{{ t('stepBa') }}</a
            >)
          </li>
          <li>{{ t('stepC') }}</li>
        </ul>
      </div>
    </BaseAccordion>
  </BaseLoader>
</template>
