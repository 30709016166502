<script lang="ts" setup>
import { PropType } from 'vue'
import { UIPlanningSlots, UIPlanningSlot } from '@/domains/planning/ui-models'
import { Agent } from 'Models/agent'
import { PlanningAgentsTypes } from 'Models/planning'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'
import PlanningModalHour from './PlanningModalHour.vue'
import PlanningModalAvatars from './avatars/PlanningModalAvatars.vue'

defineProps({
  slots: {
    type: Object as PropType<UIPlanningSlots>,
    required: true,
  },
  now: {
    type: Date,
    required: true,
  },
  agentPicked: {
    type: Object as PropType<Agent | null>,
    required: false,
  },
})

const emits = defineEmits<{
  (
    e: 'openAgentList',
    planningSlot: UIPlanningSlot,
    agentsType: PlanningAgentsTypes,
  ): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <div
    class="flex flex-col flex-1 border-r border-default-100 scrollbar overflow-y-auto"
  >
    <div class="flex flex-col">
      <div
        class="flex items-center font-medium py-2 px-4 bg-white sticky top-0 z-10"
      >
        <Title class="w-32 flex-none">{{ $formatDate(now, 'long') }}</Title>
        <Title class="w-60 flex-none pl-2">{{ t('doctors') }}</Title>
        <Title class="flex-grow pl-2">{{ t('nurses') }}</Title>
      </div>
      <div class="flex flex-col">
        <div
          v-for="slot in slots"
          :key="`slot-${slot.hour}`"
          class="flex items-center py-1 pl-4 hover:bg-blue-50"
          :class="{
            'bg-blue-50': slot.isCurrentAgentDutyHour,
            'bg-yellow-50 hover:bg-yellow-50':
              !!agentPicked && $arrayIncludes(slot.agentIds, agentPicked.id),
            'opacity-50': slot.isPast,
          }"
        >
          <PlanningModalHour
            :planning-slot="slot"
            class="w-32 font-medium flex-none"
          />
          <PlanningModalAvatars
            :planning-slot="slot"
            class="w-60 flex-none"
            :type="PlanningAgentsTypes.Doctors"
            @open-agent-list="
              (planningSlot, agentsType) =>
                emits('openAgentList', planningSlot, agentsType)
            "
          />
          <PlanningModalAvatars
            :planning-slot="slot"
            class="flex-grow pr-4"
            :type="PlanningAgentsTypes.Nurses"
            @open-agent-list="
              (planningSlot, agentsType) =>
                emits('openAgentList', planningSlot, agentsType)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
