<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

const { t } = useScopedI18n()
</script>
<template>
  <div class="h-full w-full flex items-center justify-center">
    <p class="text-base opacity-50">{{ t('message') }}</p>
  </div>
</template>
