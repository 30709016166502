import { GetterTree } from './types'
import { BuiltInAgentGroups } from 'Models/agent-group'

export const getIsCurrentAgentAdmin: GetterTree['getIsCurrentAgentAdmin'] =
  function ({ currentAgent, agentGroups }) {
    const admins = agentGroups?.find(
      (group) => group.name === BuiltInAgentGroups.SystemAdministrators,
    )
    const agentId = currentAgent?.id

    if (admins && agentId) return admins.agentIds.includes(agentId)

    return false
  }
