import { ActionTree } from './types'

export const setInceptionChats: ActionTree['setInceptionChats'] = function (
  chats,
) {
  // don't refresh the UI if no modifications have been brought to the
  // inception chat rooms
  if (JSON.stringify(chats) === JSON.stringify(this.inceptionChats)) return
  this.inceptionChats = chats
}
