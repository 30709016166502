import { ActionTree } from './types'
import { MRLItem } from 'Models/medical-report-library-item'
import Services from 'Services/services'

export const removeCurrentMedicalReportLibraryItem: ActionTree['removeCurrentMedicalReportLibraryItem'] =
  async function (topic, id) {
    const newItems: MRLItem[] = []
    const currentMedicalReport = this.currentMedicalReport
    const library = Services.getInstance().medicalReport.getMRLibrary(topic, {
      causes: this.causes,
      diagnosis: this.diagnosis,
      directions: this.directions,
      supervisions: this.supervisions,
    })

    if (!currentMedicalReport || !library) return

    const idsToKeep = (
      Services.getInstance().medicalReport.getMRLItemIds(
        topic,
        currentMedicalReport,
      ) || []
    ).filter((idToKeep) => idToKeep !== id)

    idsToKeep.forEach((itemId) => {
      const mrlItem = library.find((mrlItem) => mrlItem.id === itemId)
      if (!mrlItem) return undefined
      const canBeHidden =
        mrlItem.canBeHidden === undefined ? false : mrlItem.canBeHidden

      const hidden =
        mrlItem.canBeHidden === false
          ? false
          : !currentMedicalReport.visibleDiagnosticIds?.includes(itemId)

      newItems.push({ ...mrlItem, canBeHidden, hidden })
    })

    await Services.getInstance().medicalReport.update(currentMedicalReport.id, {
      [topic]: newItems,
    })
  }
