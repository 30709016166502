<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { AgentPickable } from 'Models/agent'
import { $fullname } from '@/plugins/vue-helpers/fullname'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseAvatar from '@/components/base/BaseAvatar.vue'

const props = defineProps({
  selectedAgent: {
    type: Object as PropType<AgentPickable | null>,
    required: false,
  },
  emptyLabel: {
    type: String,
    required: false,
    default: 'emptyLabel',
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()

const label = computed(() =>
  props.selectedAgent ? $fullname(props.selectedAgent) : t(props.emptyLabel),
)
</script>

<template>
  <div
    class="flex flex-row items-center space-x-2 text-sm px-2 py-1"
    :class="isOpen ? 'text-default' : 'text-primary'"
  >
    <BaseAvatar
      v-if="selectedAgent"
      type="agent"
      :first-name="selectedAgent.firstName"
      :profile-pic="selectedAgent.profilePic"
      :size="1"
    />
    <div>
      {{ label }}
    </div>
    <font-awesome-icon :icon="['fas', isOpen ? 'caret-up' : 'caret-down']" />
  </div>
</template>
