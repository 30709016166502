import { Extension } from '@tiptap/core'

export interface ControlEnterOptions {
  callback: () => void
}

export const ControlEnter = Extension.create<ControlEnterOptions>({
  name: 'ControlEnterShortcut',
  addOptions() {
    return {
      callback: () => ({}),
    }
  },
  addKeyboardShortcuts() {
    return {
      'Control-Enter': () => {
        this.options.callback()
        return this.editor.commands.focus() // just here to please Typescript
      },
    }
  },
})
