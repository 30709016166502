import { Ref, ref, onMounted, onBeforeUpdate, onUnmounted } from 'vue'

export default function useNow(refreshPeriod: number = 60 * 1000): {
  now: Ref<Date>
} {
  const now = ref<Date>(new Date())
  const intervalId = ref<number>(0)

  const setNow = () => (now.value = new Date())
  const refreshNow = () =>
    (intervalId.value = window.setInterval(setNow, refreshPeriod))
  onMounted(() => (setNow(), refreshNow()))

  onBeforeUpdate(() => {
    clearInterval(intervalId.value)
    setNow()
    refreshNow()
  })

  onUnmounted(() => {
    clearInterval(intervalId.value)
  })

  return {
    now,
  }
}
