<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { AgentSlot } from 'Models/planning'

import BaseButtonLink from '@/components/base/BaseButtonLink.vue'
import compareAsc from 'date-fns/compareAsc'

const props = defineProps({
  dailySlot: {
    type: Object as PropType<AgentSlot>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'pickDate', date: Date): void
}>()

const now = new Date()
const isPastSlot = computed(
  () =>
    now.getHours() >= props.dailySlot.startHour.getHours() &&
    compareAsc(now, props.dailySlot.startHour) !== -1,
)
</script>

<template>
  <BaseButtonLink
    :class="{
      'text-default-light cursor-default': isPastSlot,
      'hover:underline': !isPastSlot,
    }"
    @click="!isPastSlot && emits('pickDate', dailySlot.startHour)"
  >
    {{ `${dailySlot.startHour.getHours()}h-${dailySlot.endHour.getHours()}h` }}
  </BaseButtonLink>
</template>
