<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { Agent } from 'Models/agent'
import { MessageSender, MessageSenderType } from 'Models/message'

import BaseAvatar from '@/components/base/BaseAvatar.vue'

const props = defineProps({
  agent: {
    type: Object as PropType<Agent | null>,
    required: false,
  },
  sender: {
    type: Object as PropType<MessageSender>,
    required: true,
  },
  postedAt: {
    type: Date,
    required: true,
  },
  useTimeAgo: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const isUser = computed(() => props.sender.type === MessageSenderType.User)
</script>
<template>
  <article class="flex items-start space-x-3">
    <BaseAvatar
      v-if="agent"
      type="agent"
      :first-name="agent.firstName"
      :last-name="agent.lastName"
      :profile-pic="agent.profilePic"
      :size="3"
      :is-working="agent.isWorking"
      :working-dot-enabled="true"
      class="shrink-0 mt-1 top-px"
    />
    <BaseAvatar
      v-else
      :type="isUser ? 'user' : undefined"
      :first-name="sender.firstName"
      :last-name="sender.lastName"
      :profile-pic="sender.profilePic"
      :size="3"
      class="shrink-0 mt-1 top-px"
    />
    <div class="overflow-hidden w-full">
      <div class="space-x-2 flex flex-row items-center">
        <span class="font-medium text-sm">
          {{ agent ? $agentFullname(agent) : $fullname(sender) ?? 'Biloba' }}
        </span>

        <TimeAgo
          v-if="useTimeAgo"
          v-tooltip.right="
            `${$formatDate(postedAt, 'long')} • ${$formatTime(postedAt)}`
          "
          class="text-default-400 text-xxs"
          :date="postedAt"
        />
        <time v-else class="text-default-400 text-xxs">
          {{ $formatTime(postedAt) }}
        </time>
      </div>
      <slot />
    </div>
  </article>
</template>
