<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { useMedicalReportStore } from 'Store/stores'
import { MRLStrictTopics } from 'Models/medical-report-library-item'
import {
  UIMRLCategory,
  UIMRLCoreItem,
} from '@/domains/medical-report/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'
import { PanelPosition } from '@/domains/panel/store/types'

import Loader from '@/components/panel/side/shared/Loader.vue'
import HeaderTitle from '@/components/panel/shared/header/Title.vue'
import Search from '@/components/panel/side/shared/Search.vue'
import AddSingleItem from '@/domains/medical-report/components/library-items/AddSingleItem.vue'
import AddItems from '@/domains/medical-report/components/library-items/AddItems.vue'

const props = defineProps({
  topic: {
    type: String as PropType<MRLStrictTopics>,
    required: true,
  },
})

const { t } = useScopedI18n()
const medicalReportStore = useMedicalReportStore()

const {
  addCurrentMedicalReportLibraryItem,
  removeCurrentMedicalReportLibraryItem,
} = useMedicalReportStore()

const query = ref<string>('')

const groupedItems = computed<UIMRLCategory[] | null>(() => {
  return props.topic
    ? medicalReportStore.getAvailableMedicalReportLibraryItems(
        props.topic,
        query.value,
      )
    : null
})
const hasSingleItem = computed(
  () =>
    props.topic === MRLStrictTopics.InitialDirections ||
    props.topic === MRLStrictTopics.Directions,
)

const onSelect = ({
  item,
  checked,
  hidden,
}: {
  item: UIMRLCoreItem
  checked: boolean
  hidden: boolean
}) => {
  if (checked)
    return addCurrentMedicalReportLibraryItem(props.topic, {
      id: item.id,
      checked,
      hidden,
    })
  removeCurrentMedicalReportLibraryItem(props.topic, item.id)
}
</script>

<template>
  <div class="w-80 overflow-y-hidden">
    <HeaderTitle class="px-5 py-3">{{ t(`title.${topic}`) }}</HeaderTitle>
    <Search v-model:query="query" />

    <AddSingleItem
      v-if="topic && groupedItems && hasSingleItem"
      v-model:query="query"
      :topic="topic"
      :grouped-items="groupedItems"
      @select="onSelect"
    />

    <AddItems
      v-if="topic && groupedItems && !hasSingleItem"
      v-model:query="query"
      :topic="topic"
      :grouped-items="groupedItems"
      @select="onSelect"
    />

    <Loader
      v-if="!topic || !groupedItems"
      :panel-position="PanelPosition.Right"
    />
  </div>
</template>
