import {
  FBMRLItem,
  MRLItem,
  MRLItemFilters,
  MRLItemFilterSexType,
} from 'Models/medical-report-library-item'

export const convertFrom = (fbMRLItem: FBMRLItem): MRLItem => ({
  id: fbMRLItem.id,
  parentId: fbMRLItem.parentId,
  title: fbMRLItem.title,
  published: fbMRLItem.published,
  canBeHidden: fbMRLItem.canBeHidden,
  hidden: fbMRLItem.canBeHidden ? true : false,
  triggerRDLetter: fbMRLItem.triggerRDLetter || false,
  severity: fbMRLItem.severity,
  position: fbMRLItem.position,
  agentGroupIds: fbMRLItem.agentGroupIds?.map((groupId) =>
    groupId === 'ALL' ? 'all' : groupId,
  ),
  filters: convertFiltersFrom(fbMRLItem),
})

const convertFiltersFrom = (fbMRLItem: FBMRLItem): MRLItemFilters => {
  return {
    sex: convertGenderToSex(fbMRLItem.filters.gender),
    minAge: fbMRLItem.filters.minAge,
    maxAge: fbMRLItem.filters.maxAge,
  }
}

const convertGenderToSex = (gender: string): MRLItemFilterSexType => {
  if (gender === 'all' || gender === 'ALL' || gender === '')
    return MRLItemFilterSexType.All
  return gender === 'girl'
    ? MRLItemFilterSexType.Female
    : MRLItemFilterSexType.Male
}
