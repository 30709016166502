<script lang="ts" setup>
import { PropType } from 'vue'
import { Agent, AgentTitle } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'

import AgentsCategory from './AgentsCategory.vue'

defineProps({
  currentSlotAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  nextSlotAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  assignedAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  agentTitle: {
    type: String as PropType<AgentTitle>,
    required: true,
  },
  areDoctors: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{
  (e: 'agentPicked'): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex items-center px-5 py-3 space-x-4 border-b border-default-200 bg-default-50 sticky top-0 z-10"
    >
      <div class="flex items-center justify-center w-6">
        <font-awesome-icon
          :icon="['fal', areDoctors ? 'user-md' : 'user-nurse']"
          class="text-xl text-default"
        />
      </div>
      <div class="uppercase text-default-light text-sm">
        {{ t(agentTitle) }}
      </div>
    </div>
    <AgentsCategory
      v-if="currentSlotAgents.length > 0"
      category="currentSlot"
      :agent-title="agentTitle"
      :agents="currentSlotAgents"
      @agent-picked="emits('agentPicked')"
    />
    <AgentsCategory
      v-if="assignedAgents.length > 0"
      category="assigned"
      :agent-title="agentTitle"
      :agents="assignedAgents"
      @agent-picked="emits('agentPicked')"
    />
    <AgentsCategory
      v-if="nextSlotAgents.length > 0"
      category="nextSlot"
      :agent-title="agentTitle"
      :agents="nextSlotAgents"
      @agent-picked="emits('agentPicked')"
    />
  </div>
</template>
