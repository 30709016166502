import { TLDs } from '@/utils/tlds'

export const replaceURLs = (
  text: string,
): { text: string; url: string | undefined } => {
  const regexp =
    /((https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.+\d~#?|&//=]*)\b)/gm
  let firstUrl: string | undefined

  const modifiedText = text.replace(regexp, (href) => {
    const isMail = href.indexOf('@') !== -1 // NOTE: not very reliable but will work for most of the cases;

    if (isMail) {
      return `<a href="mailto:${href}">${href}</a>`
    } else {
      const urlString =
        href.startsWith('http://') || href.startsWith('https://')
          ? href
          : `https://${href}`
      let url = null

      // defensive code because the Regexp is not bullet proof
      try {
        url = new URL(urlString)
      } catch {
        return href
      }

      const tld = url?.origin?.split('.')?.pop()?.toUpperCase()

      if (tld) {
        if (TLDs.includes(tld)) {
          if (!firstUrl) firstUrl = href
          return `<a href="${url.href}" target="_blank">${href}</a>`
        }
      }
    }
    return href
  })

  return { text: modifiedText, url: firstUrl }
}
