<script lang="ts" setup>
import { PropType, onMounted, onBeforeUnmount, ref, watch, nextTick } from 'vue'
import useStickToLastMessage from '@/hooks/stick-to-last-message'
import { UICoreChatMessage } from '@/domains/core-chat/ui-models'
import { Agent } from 'Models/agent'

import CoreChatMessageListGroupedByDay from '@/domains/core-chat/components/CoreChatMessageListGroupedByDay.vue'

const props = defineProps({
  messages: {
    type: Array as PropType<UICoreChatMessage[]>,
    required: true,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  currentAgentId: {
    type: String,
    required: true,
  },
  isRoomClosed: {
    type: Boolean,
    required: true,
  },
})

const body = ref()
const scrollToMe = ref()

const { setup, teardown, scrollToLastMessage } = useStickToLastMessage(
  body,
  scrollToMe,
)

onMounted(() => setup())

onBeforeUnmount(() => teardown())

watch(
  () => props.messages.length,
  async (newLength, oldLength) => {
    await nextTick() // mandatory

    if (newLength === oldLength) return

    scrollToLastMessage()
  },
  { immediate: true },
)
</script>

<template>
  <div
    ref="body"
    class="flex flex-col flex-auto pt-5 px-5 overflow-y-auto scrollbar overflow-hidden"
  >
    <CoreChatMessageListGroupedByDay
      :messages="messages"
      :agents="agents"
      :current-agent-id="currentAgentId"
      :use-time-ago="!isRoomClosed"
    />
    <div ref="scrollToMe" class="flex leading-none mt-2 xl:mt-5"></div>
  </div>
</template>
