<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { kidAgeInMonths } from '@/utils/kid-age-in-months'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIKidProfile, UIKidMeasureSet } from '@/domains/kid/ui-models'

import BaseModal from '@/components/base/BaseModal.vue'
import KidMeasureModalBody from '@/domains/kid/components/kid-measures/modal/KidMeasureModalBody.vue'

const props = defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
  measureSet: {
    type: Object as PropType<UIKidMeasureSet>,
    required: true,
  },
})

const { t } = useScopedI18n()

const ageInMonths = computed(() => kidAgeInMonths(props.kidProfile.birthDate))
</script>

<template>
  <BaseModal>
    <template #header>
      <h1 class="font-semibold text-base">
        {{
          t('title', { name: kidProfile.firstName, age: $kidAge(kidProfile) })
        }}
      </h1>
    </template>
    <div class="h-full">
      <div class="pt-2 pb-12 h-full">
        <KidMeasureModalBody
          v-if="measureSet"
          :age-in-months="ageInMonths"
          :sex="kidProfile.sex"
          :measure-set="measureSet"
        />
      </div>
    </div>
  </BaseModal>
</template>
