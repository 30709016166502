import { GetterTree } from './types'
import { buildRoomListTeam } from './concerns/build-assigned-room-lists'
import { useAgentStore } from 'Store/stores'

export const getRoomListTeam: GetterTree['getRoomListTeam'] = function () {
  return (pickedAgentId) => {
    const currentAgent = useAgentStore().currentAgent
    const agents = useAgentStore().agents

    if (!currentAgent || !agents) return null

    const assignedRooms = this.getAssignedRooms
    if (!assignedRooms) return null

    return buildRoomListTeam(assignedRooms, agents, currentAgent, pickedAgentId)
  }
}
