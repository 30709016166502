<script lang="ts" setup>
import { PropType, ref, computed } from 'vue'
import { AvailableDrugDurations } from '@/utils/get-durations'
import { AvailableDrugPackaging } from '@/utils/get-packaging'
import {
  UIBrandedDrug,
  UIDrugDosage,
  UIDrugDuration,
} from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'
import { isEmpty } from '@/utils/is-empty'
import { PanelPosition } from '@/domains/panel/store/types'

import Header from '@/views/layouts/panels/Header.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import Title from '@/components/panel/side/shared/Title.vue'
import Summary from './Summary.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'
import Section from '@/components/panel/side/shared/Section.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import TextSmall from '@/components/panel/side/shared/TextSmall.vue'
import TitleAndSubTitleSlots from '@/components/panel/side/shared/TitleAndSubTitleSlots.vue'
import BaseAccordion from '@/components/base/BaseAccordion.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  brandedDrug: {
    type: Object as PropType<UIBrandedDrug>,
    required: true,
  },
  drugDosage: {
    type: Object as PropType<UIDrugDosage>,
    required: true,
  },
  durations: {
    type: Object as PropType<AvailableDrugDurations>,
    required: true,
  },
  packaging: {
    type: Object as PropType<AvailableDrugPackaging>,
    required: true,
  },
  kidWeight: {
    type: Number,
    required: true,
  },
  kidAgeToday: {
    type: String,
    required: true,
  },
})

const emits = defineEmits(['select'])

const { t, tc } = useScopedI18n()

const durationInput = ref<string | null>(null)

const isCustomDurationValid = computed(() => !isEmpty(durationInput.value))
const customDuration = computed<UIDrugDuration | null>(() => {
  if (!durationInput.value) return null
  return { label: durationInput.value, value: durationInput.value }
})

const submitCustomDuration = () => {
  if (!isCustomDurationValid.value || !customDuration.value) return
  emits('select', customDuration.value)
}
</script>

<template>
  <Header :panel-position="panelPosition" :can-close="false">
    <TitleAndSubTitleSlots>
      <template #title>{{ t('title') }}</template>
      <template #subTitle
        >{{ brandedDrug.name }} | {{ drugDosage.drugName }} ({{
          drugDosage.dosageName
        }})</template
      >
    </TitleAndSubTitleSlots>
  </Header>

  <Body>
    <Summary :kid-age-today="kidAgeToday" :kid-weight="kidWeight" />
    <Spacer />
    <Section>
      <Title class="mb-3">
        {{ t('duration') }}
      </Title>
      <div class="space-y-2">
        <div v-for="(scope, translationKey, index) in durations" :key="index">
          <TextSmall>
            {{ tc(`durationScopes.${translationKey}`, scope.length) }}
          </TextSmall>
          <div
            v-for="(item, itemIndex) in scope"
            :key="itemIndex"
            class="inline-block"
          >
            <button
              type="button"
              class="text-primary focus:outline-none"
              @click="emits('select', item)"
            >
              {{ item.label }}
            </button>
            <span v-if="itemIndex + 1 < scope.length">,&nbsp;&nbsp;</span>
          </div>
        </div>
      </div>
    </Section>
    <Section>
      <Title class="mb-3">
        {{ t('packaging') }}
      </Title>
      <div class="space-y-2">
        <div v-for="(scope, translationKey, index) in packaging" :key="index">
          <TextSmall>
            {{ tc(`packagingScopes.${translationKey}`, scope.length) }}
          </TextSmall>
          <div
            v-for="(item, itemIndex) in scope"
            :key="itemIndex"
            class="inline-block"
          >
            <button
              type="button"
              class="focus:outline-none text-primary"
              @click="emits('select', item)"
            >
              {{ item.label }}
            </button>
            <span v-if="itemIndex + 1 < scope.length">,&nbsp;&nbsp;</span>
          </div>
        </div>
      </div>
    </Section>

    <BaseAccordion class="border-t border-default-100" header-classes="py-5">
      <template #title>{{ t('customDuration') }}</template>
      <Section>
        <form id="customDuration" @submit.prevent="submitCustomDuration">
          <label for="duration" class="text-default-light">
            {{ t('treatmentDuration') }}
          </label>
          <input
            id="duration"
            v-model.trim="durationInput"
            type="text"
            name="duration"
            class="w-full rounded border border-gray-100 px-3 py-2 h-10 outline-none"
            :placeholder="t('durationPlaceholder')"
          />
          <Spacer />
          <BasePrimaryButton :disabled="!isCustomDurationValid">
            {{ t('button') }}
          </BasePrimaryButton>
        </form>
      </Section>
    </BaseAccordion>
    <Spacer />
  </Body>
</template>
