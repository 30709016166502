<script lang="ts" setup>
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  items: {
    type: Array as PropType<
      Array<{ key: string; value: string | boolean; label: string }>
    >,
    required: true,
  },
  initialItem: {
    type: [String, Boolean],
    required: false,
    default: '',
  },
})

const emits = defineEmits<{
  (e: 'update:initialItem', newPickedItem: string | boolean): void
}>()

const pickedItem = ref<string | boolean>('')

// NOTE: keep this watcher to update the local ref and to not have a delay when choosing options
watch(
  () => props.initialItem,
  (newInitialItem) => (pickedItem.value = newInitialItem),
  { immediate: true },
)
watch(pickedItem, (newPickedItem) => emits('update:initialItem', newPickedItem))
</script>

<template>
  <div class="flex items-center">
    <div
      v-for="(item, index) in items"
      :key="`${item}-${index}`"
      class="flex flex-grow transition border-t border-b border-r text-center"
      :class="{
        'border-l rounded-l': index === 0,
        'rounded-r': index === items.length - 1,
      }"
    >
      <input
        :id="item.key"
        v-model="pickedItem"
        :value="item.value"
        type="radio"
        class="hidden"
      />
      <label
        :for="item.key"
        class="flex flex-1 text-sm items-center justify-center"
      >
        <span
          class="border-b-2 px-2 py-1 flex items-center justify-center"
          :class="
            pickedItem === item.value
              ? 'border-primary hover:border-primary'
              : 'border-transparent text-primary hover:border-primary cursor-pointer'
          "
          >{{ item.label }}</span
        >
      </label>
    </div>
  </div>
</template>
