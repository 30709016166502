<script lang="ts" setup>
import {
  computed,
  inject,
  watchEffect,
  getCurrentInstance,
  ComponentInternalInstance,
} from 'vue'
import { TabsStateKey } from './symbols'

defineProps({
  title: {
    type: String,
    required: true,
  },
})

const instance = getCurrentInstance()
const tabsState = inject(TabsStateKey)

const index = computed(() => {
  const tabs = tabsState?.tabs?.value
  if (!tabs) return -1
  return tabs.findIndex(
    (target: ComponentInternalInstance) => target.uid === instance?.uid,
  )
})

const isActive = computed(() => index.value === tabsState?.active.value)

watchEffect(() => {
  const tabs = tabsState?.tabs?.value
  if (!tabs) return
  if (index.value === -1 && instance) {
    tabs.push(instance)
  }
})
</script>

<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>
