<script lang="ts" setup>
import { computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useInceptionChatStore } from 'Store/stores'

const props = defineProps({
  roomId: {
    type: String,
    required: true,
  },
  agentId: {
    type: String,
    required: true,
  },
  hasMessages: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()
const store = useInceptionChatStore()

const hasUnreadMessages = computed(
  () => (store.getRoomUnreadCounter(props.roomId, props.agentId) || 0) > 0,
)
const displayDot = computed(() => hasUnreadMessages.value || props.hasMessages)
</script>

<template>
  <div v-tooltip.top="t('tooltip')" class="relative">
    <font-awesome-icon :icon="['fal', 'message']" class="text-primary" />
    <div
      v-if="displayDot"
      class="absolute top-0 -right-1 h-2 w-2 rounded-full"
      :class="{
        'bg-purple-500': hasUnreadMessages,
        'bg-purple-300': hasMessages && !hasUnreadMessages,
      }"
    >
      &nbsp;
    </div>
  </div>
</template>
