<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useAgentStore, useRoomListStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoomListAwake } from '../../ui-models'

import RoomCardList from '@/domains/room-list/components/lists/shared/RoomCardList.vue'
import RoomsByAgent from '@/domains/room-list/components/lists/shared/RoomsByAgent.vue'
import BaseAccordion from '@/components/base/BaseAccordion.vue'

defineProps({
  roomListAwake: {
    type: Object as PropType<UIRoomListAwake>,
    required: true,
  },
})

const { tc } = useScopedI18n()
const roomListStore = useRoomListStore()

const roomListAsleep = computed(() =>
  roomListStore.getRoomListAsleep(useAgentStore().agents),
)
</script>

<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <div
      class="flex flex-col flex-grow overflow-y-auto scrollbar overflow-x-hidden"
    >
      <!-- AWAKE -->
      <BaseAccordion :force-active="roomListAwake.hasRooms">
        <template #title>
          {{ tc('awake', roomListAwake.counter) }}
        </template>

        <template #default>
          <div class="px-5 pb-5 space-y-5">
            <RoomCardList
              :title="tc('unassigned', roomListAwake.rooms.length)"
              :list="roomListAwake.rooms"
              color="orange"
            />

            <RoomsByAgent
              v-for="({ rooms, agent }, index) in roomListAwake.roomsByAgent"
              :key="index"
              :rooms="rooms"
              :assigned-agent="agent"
            />
          </div>
        </template>
      </BaseAccordion>

      <!-- ASLEEP -->
      <BaseAccordion v-if="roomListAsleep">
        <template #title>
          {{ tc('asleep', roomListAsleep.counter) }}
        </template>

        <template #default>
          <div class="px-5 pb-5 space-y-5">
            <RoomCardList
              :title="tc('unassigned', roomListAsleep.rooms.length)"
              :list="roomListAsleep.rooms"
            />

            <RoomsByAgent
              v-for="({ rooms, agent }, index) in roomListAsleep.roomsByAgent"
              :key="index"
              :rooms="rooms"
              :assigned-agent="agent"
            />
          </div>
        </template>
      </BaseAccordion>
    </div>
  </div>
</template>
