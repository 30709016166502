<script lang="ts" setup>
import { PropType, toRefs } from 'vue'
import { UIPlanningSlot } from '@/domains/planning/ui-models'
import { PlanningAgentsTypes } from 'Models/planning'
import controller from '@/domains/planning/controllers/query-agents'

import Body from '@/components/panel/side/layout/Body.vue'
import Search from '@/components/panel/side/shared/Search.vue'
import PlanningModalAgentsTabs from './agents/PlanningModalAgentsTabs.vue'
import PlanningModalAgentsHeader from './agents/PlanningModalAgentsHeader.vue'
import PlanningModalAgentList from './agents/PlanningModalAgentList.vue'

const props = defineProps({
  planningSlot: {
    type: Object as PropType<UIPlanningSlot>,
    required: true,
  },
  agentsType: {
    type: String as PropType<PlanningAgentsTypes>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'closeAgentsList'): void
  (e: 'selectAgents', agentsType: PlanningAgentsTypes): void
}>()

const { planningSlot, agentsType } = toRefs(props)
const { query, agents, onClose, onSelectAgents } = controller(
  planningSlot,
  agentsType,
  emits,
)
</script>

<template>
  <div class="flex flex-col w-96 h-full">
    <PlanningModalAgentsHeader
      :planning-slot="planningSlot"
      @close-agents-list="onClose"
    />
    <Search v-model:query="query" class="pt-5" />
    <PlanningModalAgentsTabs
      :planning-slot="planningSlot"
      :selected-agents-type="agentsType"
      :query="query"
      @select-agents="onSelectAgents"
    />
    <Body>
      <PlanningModalAgentList :agents="agents" :query="query" />
    </Body>
  </div>
</template>
