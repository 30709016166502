<script lang="ts" setup>
import { PropType, ref, computed } from 'vue'
import { useMessageColors } from '@/domains/core-chat/controllers/message-colors'
import { UICoreChatMessageColor } from '@/domains/core-chat/ui-models'

import BaseLoader from '@/components/base/BaseLoader.vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  color: {
    type: String as PropType<UICoreChatMessageColor>,
    required: false,
    default: 'default',
  },
})

const color = computed(() => props.color)
const { messageColors } = useMessageColors(color)

const isMediaLoaded = ref(false)

const onMediaLoad = () => (isMediaLoaded.value = true)
</script>

<template>
  <div
    class="p-3 relative overflow-hidden h-auto w-40 rounded-xl cursor-pointer border"
    :class="messageColors"
  >
    <BaseLoader class="pt-5" :is-loading="!isMediaLoaded">
      <img
        v-bind="$attrs"
        class="rounded-lg"
        :src="src"
        alt=""
        @load="onMediaLoad"
      />
    </BaseLoader>
  </div>
</template>
