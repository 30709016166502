import { DocumentData, FieldValue, WithFieldValue } from 'firebase/firestore'
import { Agent } from 'Models/agent'
import {
  FBMedicalReport,
  FBMedicalReportAgent,
  MedicalReport,
  MedicalReportAgent,
  MedicalReportKid,
  FBMedicalReportDoctor,
  MedicalReportDoctor,
} from 'Models/medical-report'
import { MRLItem } from 'Models/medical-report-library-item'
import { Room, RoomAssignedAgent } from 'Models/room'

export const convertFrom = (
  fbMedicalReport: FBMedicalReport,
): MedicalReport => {
  let visibleDiagnosticIds: string[] = []

  const visibleDiagnosis = fbMedicalReport.diagnosis
    ?.filter((diagnostic) => diagnostic.hidden === false)
    .map((dianostic) => dianostic.id)

  if (visibleDiagnosis) visibleDiagnosticIds = [...visibleDiagnosis]

  return {
    id: fbMedicalReport.id,
    kidId: fbMedicalReport.kid.id,
    parentId: fbMedicalReport.userId,
    kidParent: undefined,
    roomId: fbMedicalReport.roomId,
    hasPrescription: false,
    prescription: undefined,
    hasLetter: false,
    roomCreatedAt: fbMedicalReport.roomCreatedAt.toDate(),
    agent: convertAgentFrom(fbMedicalReport.agent),
    instructions: fbMedicalReport.instructions,
    supervisionsIds: fbMedicalReport.supervisions?.map(
      (supervision) => supervision.id,
    ),
    causesIds: fbMedicalReport.causes?.map((cause) => cause.id),
    diagnosisIds: fbMedicalReport.diagnosis?.map((diagnostic) => diagnostic.id),
    visibleDiagnosticIds,
    initialDirectionsIds: fbMedicalReport.initialDirections?.map(
      (initialDirection) => initialDirection.id,
    ),
    directionsIds: fbMedicalReport.directions?.map((direction) => direction.id),
    estimatedTimeSpent: fbMedicalReport.estimatedTimeSpent,
    estimatedComplexity: fbMedicalReport.estimatedComplexity,
    medicalOpinionRequested: fbMedicalReport.medicalOpinionRequested,
    requestedDoctor: convertDoctorFrom(fbMedicalReport.requestedDoctor),
  }
}

export const convertDoctorFrom = (
  agent: FBMedicalReportDoctor | undefined | null,
): MedicalReportDoctor | undefined | null => {
  if (!agent) return undefined
  return {
    id: agent.id,
    firstName: agent.firstName,
    lastName: agent.lastName,
    titleKey: agent.titleKey,
    gender: agent.gender,
    profilePic: agent.profilePic,
  }
}

export const convertAgentFrom = (
  agent: FBMedicalReportAgent | null,
): MedicalReportAgent | null => {
  if (!agent) return null
  return {
    id: agent.id,
    firstName: agent.firstName,
    lastName: agent.lastName,
    titleKey: agent.titleKey,
    gender: agent.gender,
    profilePic: agent.profilePic,
  }
}

export const convertMRLItemsTo = (items: MRLItem[]): DocumentData[] => {
  return items.map(({ id, hidden, severity, title, triggerRDLetter }) => {
    return {
      id,
      hidden,
      severity,
      title,
      triggerRDLetter,
    }
  })
}

export const convertRoomTo = (room: Room): MedicalReport => {
  return {
    id: room.id,
    roomId: room.id,
    kidId: room.kid.id,
    kid: room.kid,
    parentId: room.parent.id,
    agent: convertRoomAssignedAgentTo(room.assignedAgent),
    roomCreatedAt: room.createdAt,
    hasPrescription: false,
    hasLetter: false,
    medicalOpinionRequested: false,
  }
}

export const convertRoomAssignedAgentTo = (
  agent: RoomAssignedAgent | null,
): MedicalReportAgent | null => {
  if (!agent) return null
  return {
    id: agent.id,
    firstName: agent.firstName,
    lastName: agent.lastName,
    titleKey: agent.titleKey,
    gender: agent.gender,
    profilePic: agent.profilePic ?? undefined,
  }
}

// NOTE: only called when creating a new medical report
export const convertTo = (
  medicalReport: WithFieldValue<MedicalReport>,
): DocumentData => {
  return {
    kid: convertKidTo(medicalReport.kid),
    userId: medicalReport.parentId,
    agent: convertAgentTo(medicalReport.agent),
    roomId: medicalReport.roomId,
    roomCreatedAt: medicalReport.roomCreatedAt,
    medicalOpinionRequested: medicalReport.medicalOpinionRequested,
  }
}

export const convertKidTo = (
  kid: WithFieldValue<MedicalReportKid | FieldValue | undefined>,
): DocumentData => {
  if (!isMedicalReportKid(kid)) return {} // shouldn't happen
  return {
    id: kid.id,
    firstName: kid.firstName,
    lastName: kid.lastName,
    gender: kid.gender === 'female' ? 0 : 1,
    birthDate: kid.birthDate,
  }
}

export const convertAgentTo = (
  agent: WithFieldValue<
    MedicalReportAgent | MedicalReportDoctor | Agent | FieldValue | null
  >,
): DocumentData | null => {
  if (!agent) return null // at the creation of the MR, the MR isn't assigned to an agent.
  if (!isMedicalReportAgent(agent)) return {} // it shouldn't happen
  return {
    id: agent.id,
    firstName: agent.firstName,
    lastName: agent.lastName,
    titleKey: agent.titleKey,
    gender: agent.gender,
    profilePic: agent.profilePic,
  }
}

function isMedicalReportKid(
  kid: WithFieldValue<MedicalReportKid | FieldValue | undefined>,
): kid is WithFieldValue<MedicalReportKid> {
  if (!kid) return false
  return (kid as MedicalReportKid).id !== undefined
}

function isMedicalReportAgent(
  agent: WithFieldValue<MedicalReportAgent | Agent | FieldValue>,
): agent is WithFieldValue<MedicalReportAgent | Agent> {
  return (
    (agent as MedicalReportAgent).id !== undefined ||
    (agent as Agent).id !== undefined
  )
}
