import { GetterTree } from './types'
import { useAgentStore, useUIStore } from 'Store/stores'
import { buildUIMedicalReport } from './concerns/build-ui-medical-report'
import { UIMedicalReport } from '../../ui-models'
import { makeTimeTree } from '@/utils/make-time-tree'
import { buildUIMedicalReports } from './concerns/build-ui-medical-reports'

export const getPastMedicalReports: GetterTree['getPastMedicalReports'] =
  function ({ pastMedicalReports }) {
    const agents = useAgentStore().agents
    const locale = useUIStore().locale

    if (
      !pastMedicalReports ||
      !this.supervisions ||
      !this.causes ||
      !this.diagnosis ||
      !this.directions ||
      !agents ||
      !locale
    )
      return null

    const uiMedicalReports: UIMedicalReport[] = pastMedicalReports.map(
      (pastMedicalReport) => {
        return buildUIMedicalReport(
          pastMedicalReport,
          pastMedicalReport.prescription,
          locale,
          agents,
          this.causes,
          this.diagnosis,
          this.directions,
          this.supervisions,
        )
      },
    )

    const tree = makeTimeTree<UIMedicalReport>(
      { items: uiMedicalReports, dateField: 'roomCreatedAt' },
      {},
    )

    if (!tree) return null

    return buildUIMedicalReports(tree)
  }
