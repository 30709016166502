<script lang="ts" setup>
import { toRefs } from 'vue'
import controller from '../controllers/load-prescription-preview'
import deletePrescription from '../controllers/delete-prescription'

import BaseModal from '@/components/base/BaseModal.vue'
import PrescriptionModalTitle from './prescription-modal/PrescriptionModalTitle.vue'
import PrescriptionModalBody from './prescription-modal/PrescriptionModalBody.vue'

import useScopedI18n from '@/hooks/scoped-i18n'

const props = defineProps({
  prescriptionId: {
    type: String,
    required: true,
  },
})

const { t } = useScopedI18n()

const prescriptionId = toRefs(props).prescriptionId
const onDeletePrescription = async () => {
  if (confirm(t('deletionConfirmation'))) {
    await deletePrescription(prescriptionId)
  }
}
const { prescriptionPreview } = controller(prescriptionId)
</script>

<template>
  <BaseModal :is-loading="!prescriptionPreview">
    <template #header>
      <PrescriptionModalTitle
        v-if="prescriptionPreview"
        :with-delete-button="true"
        :prescription-preview="prescriptionPreview"
        @delete-prescription="onDeletePrescription"
      />
    </template>

    <PrescriptionModalBody
      v-if="prescriptionPreview"
      :prescription-preview="prescriptionPreview"
    />
  </BaseModal>
</template>
