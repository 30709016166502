import { GetterTree } from './types'
import Services from 'Services/services'
import compareAsc from 'date-fns/compareAsc'
import { UIRoom } from '../../ui-models'
import { MessageSenderType } from 'Models/message'

export const getRoomListUnassigned: GetterTree['getRoomListUnassigned'] =
  function ({ rooms }) {
    if (!rooms) return null

    const unassignedRooms = Services.getInstance().room.filterUnassigned(
      rooms,
    ) as UIRoom[] // required

    if (!unassignedRooms) return null

    const now = new Date()
    const { pendingRooms, recentRooms, inactiveRooms } =
      getRecentOrPendingRooms(unassignedRooms, now)
    const pendingCounter = pendingRooms.length
    const recentCounter = recentRooms.length
    const inactiveCounter = inactiveRooms.length

    const counter = pendingCounter + recentCounter
    const hasRooms = counter > 0

    return {
      counter,
      hasRooms,
      recentRooms,
      recentCounter,
      pendingRooms,
      pendingCounter,
      inactiveCounter,
      inactiveRooms,
    }
  }
//export to test...
export const getRecentOrPendingRooms = (
  rooms: UIRoom[],
  now: Date,
): {
  pendingRooms: UIRoom[]
  recentRooms: UIRoom[]
  inactiveRooms: UIRoom[]
} => {
  const pendingRooms = []
  const recentRooms = []
  const inactiveRooms = []
  rooms = rooms.sort((roomA, roomB) =>
    compareAsc(
      roomA.lastParentThreadStartedAt ?? roomA.status.updatedAt,
      roomB.lastParentThreadStartedAt ?? roomB.status.updatedAt,
    ),
  )
  for (const room of rooms) {
    if (isInactiveFromUnassigned(room)) {
      inactiveRooms.push(room)
      continue
    }
    const isPending = Services.getInstance().room.isPendingRoom(
      room.lastHumanSender,
      room.lastParentThreadStartedAt,
      now,
    )
    if (isPending) {
      pendingRooms.push(room)
      continue
    }
    recentRooms.push(room)
  }
  return { pendingRooms, recentRooms, inactiveRooms }
}

export function isInactiveFromUnassigned(room: UIRoom): boolean {
  return room.lastHumanSender === MessageSenderType.Agent
}
