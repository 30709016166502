import { GetterTree } from './types'
import { buildUIRooms } from '@/domains/room-list/store/concerns/build-ui-room'
import { useAgentStore } from 'Store/stores'

export const getClosedRooms: GetterTree['getClosedRooms'] = function ({
  closedRooms,
}) {
  const currentAgent = useAgentStore().currentAgent

  if (!closedRooms || !currentAgent) return null

  return buildUIRooms(closedRooms, currentAgent.id)
}
