<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { UIMRLCoreItem } from '@/domains/medical-report/ui-models'

import ItemLabel from './ItemLabel.vue'

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  item: {
    type: Object as PropType<UIMRLCoreItem>,
    required: true,
  },
  query: {
    type: String,
    required: false,
  },
})

const emit = defineEmits<{
  (
    e: 'change',
    {
      item,
      checked,
      hidden,
    }: { item: UIMRLCoreItem; checked: boolean; hidden: boolean },
  ): void
}>()

const checked = computed({
  get: () => props.item.isAlreadyAdded,
  set: (checked) => {
    if (checked === undefined) return
    emit('change', { item: props.item, checked, hidden: false })
  },
})
</script>

<template>
  <li class="my-1 flex items-center">
    <div class="flex space-x-2 flex-1 items-center px-5 hover:bg-default-100">
      <input
        :id="`${name}-${item.id}`"
        v-model="checked"
        :name="name"
        type="radio"
        class="pr-1 transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed text-primary border-default-100"
      />
      <label
        class="cursor-pointer flex flex-1 py-px"
        :for="`${name}-${item.id}`"
      >
        <ItemLabel :query="query" :item="item" />
      </label>
    </div>
  </li>
</template>
