<script lang="ts" setup>
import { PropType } from 'vue'
import { UIPrescriptionItem } from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import Section from '@/components/panel/side/shared/Section.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'
import TextSmall from '@/components/panel/side/shared/TextSmall.vue'

defineProps({
  prescriptedDrug: {
    type: Object as PropType<UIPrescriptionItem>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'toggleReplaceable', index: number): void
  (e: 'delete', index: number): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <article>
    <header class="bg-default-100 pl-5 py-3 flex flex-row space-x-2">
      <div class="flex flex-1 flex-row space-x-1 items-center">
        <div
          v-for="(molecule, moleculeIndex) in prescriptedDrug.molecules"
          :key="moleculeIndex"
          class="capitalize"
        >
          {{ molecule }}
        </div>
        <div class="text-sm">({{ prescriptedDrug.drug }})</div>
      </div>
      <div class="flex flex-row items-center space-x-2">
        <button
          v-tooltip.bottom="
            t(prescriptedDrug.isReplaceable ? 'replaceable' : 'notReplaceable')
          "
          class="px-5 focus:outline-none flex flex-row space-x-1 items-center"
          @click="emits('toggleReplaceable', index)"
        >
          <font-awesome-icon
            :icon="
              prescriptedDrug.isReplaceable
                ? ['fas', 'check-square']
                : ['fal', 'square']
            "
            class="focus:outline-none text-sm"
            :class="
              prescriptedDrug.isReplaceable
                ? 'text-green-500'
                : 'text-default-300'
            "
          />
          <div class="text-primary text-xs">
            {{ t('toggleReplaceableButton') }}
          </div>
        </button>
        <button
          v-tooltip.bottom="t('deleteButton')"
          class="text-primary text-sm px-5 focus:outline-none"
          @click="emits('delete', index)"
        >
          <font-awesome-icon
            :icon="['fal', 'trash-alt']"
            class="focus:outline-none text-primary"
          />
        </button>
      </div>
    </header>
    <Spacer />
    <Section class="space-y-3">
      <div class="flex flex-col">
        <TextSmall>{{ t('presentation') }}</TextSmall>
        <div>{{ prescriptedDrug.presentation.short }}</div>
      </div>
      <div class="flex flex-col">
        <TextSmall>{{ t('dosage') }}</TextSmall>
        <div>
          {{ prescriptedDrug.dosage.short }} {{ prescriptedDrug.duration }}
        </div>
      </div>
    </Section>
  </article>
</template>
