<script lang="ts" setup>
import { ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

const emits = defineEmits<{
  (e: 'reOpenRoom'): void
}>()

const { t } = useScopedI18n()

const isReOpening = ref(false)

const handleClick = () => ((isReOpening.value = true), emits('reOpenRoom'))
</script>

<template>
  <button
    class="w-8 h-8 hover:bg-default-100 rounded flex items-center justify-center"
    :disabled="isReOpening"
    @click="handleClick"
  >
    <font-awesome-icon
      v-tooltip.bottom="t('tooltip')"
      :icon="['fal', 'inbox-out']"
      class="focus:outline-none text-primary"
    />
  </button>
</template>
