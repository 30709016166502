<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import { computed, ref, PropType, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore } from 'Store/stores'
import { differenceInDays } from '@/utils/difference-in-days'
import addDays from '@/utils/add-days'
import useScopedI18n from '@/hooks/scoped-i18n'
import isBefore from '@/utils/is-before'
import { Agent } from 'Models/agent'
import useModal from '@/hooks/modal'
import useNow from '@/hooks/now'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import AgentPicker from '@/domains/agent/views/AgentPicker.vue'
import TextSmall from '@/components/panel/side/shared/TextSmall.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import DatePicker from 'vue3-datepicker'
import AgentPlanningModal from '@/domains/planning/views/AgentPlanningModal.vue'

const props = defineProps({
  snoozedUntil: {
    type: Date as PropType<Date | null>,
  },
})

const emits = defineEmits<{
  (e: 'snoozeRoom', pickedDate: Date): void
}>()

const { now } = useNow(600000) // refresh it every 1h
const daysOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 14, 21]

const { t, tc } = useScopedI18n()
const agentStore = useAgentStore()
const { currentAgent } = storeToRefs(agentStore)
const agents = computed(() => agentStore.getAssignableAgents)
const agentPlanningModal = useModal()

const selectedAgent = ref<Agent | null>(null)
const pickedDate = ref<Date>()

const pickedDays = computed(
  () => pickedDate.value && differenceInDays(pickedDate.value, now.value),
)
const hasSnoozedUntilDate = computed(
  () =>
    !!props.snoozedUntil && differenceInDays(props.snoozedUntil, now.value) > 0,
)

const pickDays = (days: number) => (pickedDate.value = addDays(now.value, days))

const openAgentPlanningModal = (agent: Agent) => {
  selectedAgent.value = agent
  agentPlanningModal.openModal()
}

watch(
  () => props.snoozedUntil,
  (snoozedUntil) => {
    if (snoozedUntil && !isBefore(snoozedUntil, now.value))
      pickedDate.value = snoozedUntil
    else pickedDate.value = now.value
  },
  { immediate: true },
)
</script>

<template>
  <div>
    <BaseDropdown
      button-custom-class="w-8 h-8 rounded hover:bg-default-100"
      container-class="overflow-y-visible"
    >
      <template #button>
        <font-awesome-icon
          v-tooltip.bottom="t(hasSnoozedUntilDate ? 'altTitle' : 'title')"
          :icon="['fal', 'clock']"
          class="focus:outline-none text-primary"
        />
      </template>
      <template #default="{ close: closeDropdown }">
        <div class="w-80">
          <div class="p-5 font-semibold">
            {{ t(hasSnoozedUntilDate ? 'altTitle' : 'title') }}
          </div>
          <div class="border-t border-b border-default-100">
            <div v-if="agents && currentAgent" class="p-5">
              <AgentPicker
                :current-agent="currentAgent"
                :agents="agents"
                :split-by-title="true"
                empty-label="allTeamMembers"
                clear-label="allTeamMembers"
                button-custom-class="py-1 border rounded border-default-300"
                @select-agent="
                  (agent) => (closeDropdown(), openAgentPlanningModal(agent))
                "
              />
            </div>
            <div class="px-5 pb-5">
              <TextSmall>{{ t('subTitle') }}</TextSmall>
              <button
                v-for="(days, index) in daysOptions"
                :key="index"
                type="button"
                class="focus:outline-none"
                :class="days === pickedDays && 'cursor-default'"
                @click="pickDays(days)"
              >
                <span
                  :class="
                    days === pickedDays
                      ? 'font-semibold underline'
                      : 'text-primary'
                  "
                  >{{ t(`days.${days}`) }}</span
                >
                <span v-if="index < daysOptions.length - 1">,&nbsp;&nbsp;</span>
              </button>
              {{ t('or') }}
              <DatePicker
                v-model="pickedDate"
                :typeable="true"
                :locale="$dateLocale()"
                :input-format="$dateFormat('short')"
                :lower-limit="addDays(now, 1)"
                class="inline cursor-pointer text-primary focus:outline-none"
                size="9"
              />
            </div>

            <div
              v-if="pickedDays"
              class="p-5 text-sm bg-default-50"
              v-html="
                tc(hasSnoozedUntilDate ? 'altText' : 'text', pickedDays, {
                  days: pickedDays,
                  date: $formatDate(addDays(now, pickedDays), 'long'),
                })
              "
            />

            <div
              class="flex flex-col items-center justify-center p-5 space-y-3 border-t border-default-100"
            >
              <BasePrimaryButton
                :disabled="!pickedDays"
                @click="pickedDate && emits('snoozeRoom', pickedDate)"
              >
                {{ t(hasSnoozedUntilDate ? 'altButton' : 'button') }}
              </BasePrimaryButton>
            </div>
          </div>
        </div>
      </template>
    </BaseDropdown>
    <!-- Modals -->
    <AgentPlanningModal
      v-if="agentPlanningModal.isModalOpen.value && selectedAgent"
      :selected-agent="selectedAgent"
      @close="agentPlanningModal.closeModal"
    />
  </div>
</template>
<style scoped>
.v3dp__datepicker {
  display: inline-block;
}
</style>
