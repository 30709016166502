import { toAPIDate } from '@/utils/format-date'
import { isEmpty } from '@/utils/is-empty'
import { IAPIService } from 'External/api/types'
import { Agent } from 'Models/agent'
import {
  convertFromAPI,
  convertToAPIForm,
  convertFromDraft,
  convertToDraft,
  convertPreviewFromAPI,
} from 'Models/converters/prescription-converter'
import { Kid } from 'Models/kid'
import {
  Prescription,
  PrescriptionListFilter,
  PrescriptionPreview,
  PrescriptionStatus,
  PrescriptionType,
} from 'Models/prescription'
import { Room } from 'Models/room'
import { IPrescriptionService } from './__types__'

export default class PrescriptionService implements IPrescriptionService {
  // dependencies
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    this.apiService = apiService
  }

  async loadOrBuildFromRoom(
    room: Room,
    kid: Kid,
    agent: Agent,
    supervisorAgentId: string,
  ): Promise<Prescription> {
    let prescription = await this.loadFromRoomId(room.id)

    if (!prescription) prescription = await this.loadFromDraft(room.id)

    if (!prescription) {
      prescription = this.build(
        room.id,
        room.parent.id,
        kid,
        agent,
        supervisorAgentId,
      )
    }

    return prescription
  }

  async loadAll(filter: PrescriptionListFilter): Promise<Prescription[]> {
    const response = await this.apiService.getAllPrescriptions(
      filter.doctor?.id || null,
      filter.supervisor?.id || null,
      filter.kidId || null,
      filter.startDate ? toAPIDate(filter.startDate) : null,
      filter.endDate ? toAPIDate(filter.endDate) : null,
      true,
    )
    return response.prescriptions.map(convertFromAPI)
  }

  async load(prescriptionId: string): Promise<Prescription | null> {
    const response = await this.apiService.getPrescription(prescriptionId)

    if (!response.prescription) return null

    return convertFromAPI(response.prescription)
  }

  async delete(prescriptionId: string): Promise<void> {
    await this.apiService.deletePrescription(prescriptionId)
  }

  async loadFromRoomId(roomId: string): Promise<Prescription | null> {
    const prescriptions = await this.apiService.getPrescriptions(roomId)
    // NOTE: we only handle single TREATMENT prescriptions for now
    const apiPrescriptions = prescriptions.prescriptions.filter(
      (prescription) => prescription.type === PrescriptionType.Treatment,
    )
    if (apiPrescriptions.length === 0) return null
    return convertFromAPI(apiPrescriptions[0])
  }

  async persist(roomId: string, prescription: Prescription): Promise<string> {
    const form = convertToAPIForm(prescription)
    const response = await this.apiService.persistPrescription(roomId, form)

    return response.prescriptions[0].id
  }

  async loadFromDraft(roomId: string): Promise<Prescription | null> {
    let prescription: Prescription | null = null
    try {
      const response = await this.apiService.getPrescriptionDraft(roomId)
      prescription = convertFromDraft(response.prescriptionDraft[0])
    } catch {
      console.log(
        `[PrescriptionService] Unable to load a draft for room #${roomId}`,
      )
    }
    return prescription
  }

  persistToDraft(roomId: string, prescription: Prescription): Promise<void> {
    // NOTE: add a safeguard because of a potential bug
    if (prescription.roomId !== roomId)
      throw `Houston, we've got a problem, the roomId doesn't match the one of the prescription (${roomId}/${prescription.roomId})`

    const draft = convertToDraft(prescription)
    return this.apiService.persistPrescriptionDraft(roomId, draft)
  }

  async loadPreview(
    prescriptionId: string,
  ): Promise<PrescriptionPreview | null> {
    const response = await this.apiService.getPrescriptionPreview(
      prescriptionId,
    )

    if (!response.preview) return null

    return convertPreviewFromAPI(response.preview)
  }

  async buildPreview(
    roomId: string,
    prescription: Prescription,
  ): Promise<PrescriptionPreview> {
    const form = convertToAPIForm(prescription)
    const response = await this.apiService.buildPrescriptionPreview(
      roomId,
      form,
    )
    return response.previews[0]
  }

  build(
    roomId: string,
    kidParentId: string,
    kid: Kid,
    agent: Agent,
    supervisorAgentId: string,
  ): Prescription {
    // TODO: check if the kid has a weight and if the weightdatetaken is old from today
    // if the weight is filled and not old, prefill the kidweight with it
    // if the weight is filled but old or weight undefined then require kidWeight to be provided
    // and save the weight and the date into the kid
    return {
      roomId: roomId,
      doctorId: agent.isRegisteredDoctor ? agent.id : supervisorAgentId,
      agentId: agent.id,
      userId: kidParentId,
      kidId: kid.id,
      kidWeightInKg: kid.weight || 0,
      kidLastName: kid.lastName || '',
      hadKidLastName: !isEmpty(kid.lastName),
      type: PrescriptionType.Treatment,
      status: PrescriptionStatus.Draft,
      prescriptedDrugs: [],
    }
  }
}
