import { ActionTree } from './types'
import {
  useAgentStore,
  useConfigStore,
  useCoreChatStore,
  useKidStore,
  useMedicalReportStore,
  usePrescriptionStore,
} from 'Store/stores'
import Services from 'Services/services'

export const setCurrentRoom: ActionTree['setCurrentRoom'] = async function (
  roomId,
) {
  const currentAgent = useAgentStore().currentAgent

  if (!roomId || !currentAgent) return null

  const config = useConfigStore().getConfig

  if (!config || !config.desk) return null

  this.currentRoomId = roomId
  this.currentRoom = null
  this.isCurrentRoomJustClosed = false

  usePrescriptionStore().updateCurrentPrescription(null)
  useCoreChatStore().updateCurrentMessages(null)
  useKidStore().updateCurrentKid(null)
  useMedicalReportStore().updateCurrentMedicalReport(null)
  useMedicalReportStore().updatePastMedicalReports(null)

  Services.getInstance().roomWorkspace.add(roomId, {
    currentAgent,
    supervisorAgentId: config.desk.supervisorAgent,
    maxPastMedicalReports: 10,
  })
}
