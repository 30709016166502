<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { isEmpty } from '@/utils/is-empty'
import useScopedI18n from '@/hooks/scoped-i18n'

const props = defineProps({
  breadcrumb: {
    type: Array as PropType<Array<{ id: string; title: string }>>,
    required: false,
  },
})

const { t } = useScopedI18n()

const emits = defineEmits<{
  (e: 'resetBreadcrumb'): void
  (e: 'backBreadcrumb'): void
}>()

const hasParent = computed(() => !isEmpty(props.breadcrumb))
</script>

<template>
  <div
    class="flex flex-row space-x-2 px-5 py-2 border-b border-default-100 text-xs items-center"
  >
    <button
      class="focus:outline-none"
      :class="hasParent ? 'text-primary' : 'cursor-default'"
      @click="emits('resetBreadcrumb')"
    >
      {{ t('root') }}
    </button>

    <font-awesome-icon
      :icon="['far', 'chevron-right']"
      class="text-xxs text-default-400"
    />

    <div v-if="breadcrumb" class="text-primary space-x-2">
      <button
        v-for="(item, index) in breadcrumb"
        :key="index"
        class="focus:outline-none"
        @click="emits('backBreadcrumb')"
      >
        {{ item.title }}
        <span v-if="breadcrumb.length - 1 > index">></span>
      </button>
    </div>
  </div>
</template>
