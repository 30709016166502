<script lang="ts" setup>
import { PropType } from 'vue'
import { EstimatedComplexity } from 'Models/medical-report'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'

defineProps({
  estimatedComplexity: {
    type: String as PropType<EstimatedComplexity>,
    required: false,
    default: EstimatedComplexity.Unknown,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <article class="flex flex-col">
    <Title>
      {{ t('title') }}
    </Title>
    <div>• {{ t(estimatedComplexity) }}</div>
  </article>
</template>
