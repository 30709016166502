import { ActionTree } from './types'
import { useUIStore } from 'Store/stores'
import { startup } from './concerns/startup'
import Services from 'Services/services'

export const localSignIn: ActionTree['localSignIn'] = async function () {
  useUIStore().setIsLoading(true)
  const services = Services.getInstance()
  try {
    const authInfo = await services.authentication.localSignIn()
    await startup(authInfo, services)
  } catch (error) {
    console.error('[LocalSignIn]', error)
    // TODO: not sure if we've have to "mask" the exception
  }
}
