<script lang="ts" setup>
import { computed, PropType, onBeforeUnmount, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import { RoomListFilterChanges } from '@/domains/room-list/store/actions/types'
import { useAgentStore, useRoomListStore } from 'Store/stores'
import { UIRoomListFilter } from '../../ui-models'

import AgentPicker from '@/domains/agent/views/AgentPicker.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'
import TeamRooms from '@/domains/room-list/components/lists/shared/TeamRooms.vue'
import BaseRoomStatusPicker from '@/components/base/BaseRoomStatusPicker.vue'

const props = defineProps({
  filter: {
    type: Object as PropType<UIRoomListFilter>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'updateFilter', changes: RoomListFilterChanges): void
  (e: 'updateCounter', counter: number): void
}>()

const { currentAgent } = storeToRefs(useAgentStore())
const roomListStore = useRoomListStore()

const roomList = computed(() =>
  roomListStore.getRoomListFavorites(
    props.filter.agent?.id ?? null,
    props.filter.status ?? null,
  ),
)

watch(
  () => roomList.value?.counterFiltered,
  (newValue) => newValue && emits('updateCounter', newValue),
  { immediate: true },
)

onBeforeUnmount(() => roomListStore.resetRoomListFilter())
</script>

<template>
  <div v-if="roomList" class="flex flex-col flex-1 overflow-hidden">
    <header class="flex flex-col">
      <div
        v-if="roomList.hasRooms && currentAgent"
        class="flex flex-row items-center px-5 pb-3 border-b border-default-100"
      >
        <AgentPicker
          v-if="roomList.agents.length > 1"
          :selected-agent="filter.agent"
          :current-agent="currentAgent"
          :agents="roomList.agents"
          :split-by-presence="false"
          :show-number-of-room-assigned-by-agent="false"
          empty-label="allTeamMembers"
          clear-label="allTeamMembers"
          :allow-clear="true"
          menu-align="left"
          button-custom-class="z-index-30 py-1 rounded border border-default-300 mr-2"
          :placement="DropdownPlacement.BottomStart"
          @select-agent="(agent) => emits('updateFilter', { agent })"
          @clear-agent="emits('updateFilter', { agent: null })"
        />

        <BaseRoomStatusPicker
          :status="filter.status"
          empty-label="all"
          clear-label="allRoomStatus"
          :allow-clear="true"
          menu-align="left"
          button-custom-class="z-index-30 py-1 rounded border border-default-300"
          @select-status="(status) => emits('updateFilter', { status })"
          @clear-status="emits('updateFilter', { status: null })"
        />
      </div>
    </header>

    <TeamRooms
      :has-agent-picked="!!filter.agent"
      :rooms-by-agent="roomList.roomsByAgent"
      :unassigned-rooms="roomList.unassignedRooms"
    />
  </div>
  <div v-else class="flex flex-1 flex-col items-center justify-center">
    <BaseLoader :is-loading="true" />
  </div>
</template>
