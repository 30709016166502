export enum LocalKeys {
  ApiToken = 'api_token',
  ApiTokenExpiresAt = 'api_token_expires_at',
  FirebaseToken = 'firebase_token',
}

export interface ILocalStorageService {
  setDraftMessage(roomId: string, message: string): void
  getDraftMessage(roomId: string): string | null
  clearDraftMessage(roomId: string): void
  getAPIToken(): string | null
  setAPIToken(value: string, now?: number | null): void
  clearAPIToken(): void
  getAPITokenExpiresAt(): number | null
  getFirebaseToken(): string | null
  setFirebaseToken(value: string): void
  clearFirebaseToken(): void
  clearAll(): void
}

export interface ILocalStorage {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
}
