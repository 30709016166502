import MedicalReportPolicy from '@/policies/medical-report'
import MessagePolicy from '@/policies/message'
import RoomPolicy from '@/policies/room'
import AdminAppPolicy from '@/policies/admin-app'
import PrescriptionPolicy from '@/policies/prescription'
import InceptionChatPolicy from '@/policies/inception-chat'
import { Agent } from 'Models/agent'
import { AgentGroup } from 'Models/agent-group'
import { Room } from 'Models/room'
import { storeToRefs } from 'pinia'
import { useAgentStore } from 'Store/stores'
import BasePolicy from '@/policies/base'

export const $canUseNewFeatures = (): boolean =>
  can((currentAgent, agentGroups) =>
    new BasePolicy(currentAgent, agentGroups).useNewFeatures(),
  )

export const $canPostMessage = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new MessagePolicy(currentAgent, agentGroups).post(room),
  )

export const $canAssignRoom = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new RoomPolicy(currentAgent, agentGroups).assign(room),
  )

export const $canSelfAssignRoom = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new RoomPolicy(currentAgent, agentGroups).selfAssign(room),
  )

export const $canAssignMedicalReport = (): boolean =>
  can((currentAgent, agentGroups) =>
    new MedicalReportPolicy(currentAgent, agentGroups).assign(),
  )

export const $canCloseRoom = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new RoomPolicy(currentAgent, agentGroups).close(room),
  )

export const $canSnoozeRoom = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new RoomPolicy(currentAgent, agentGroups).snooze(room),
  )

export const $canCancelPendingAssignment = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new RoomPolicy(currentAgent, agentGroups).cancelPendingAssignment(room),
  )

export const $canSpellcheckMessage = (): boolean =>
  can((currentAgent, agentGroups) =>
    new MessagePolicy(currentAgent, agentGroups).spellcheck(),
  )

export const $canOpenPrescription = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new PrescriptionPolicy(currentAgent, agentGroups).open(room),
  )

export const $canDeletePrescription = (): boolean =>
  can((currentAgent, agentGroups) =>
    new PrescriptionPolicy(currentAgent, agentGroups).delete(),
  )

export const $canOpenPrescriptionInReadOnly = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new PrescriptionPolicy(currentAgent, agentGroups).openReadonly(room),
  )

export const $canDisplayDoctorPickerOnPrescription = (): boolean =>
  can((currentAgent, agentGroups) =>
    new PrescriptionPolicy(currentAgent, agentGroups).requireDoctorPicker(),
  )

export const $canListPrescriptions = (): boolean =>
  can((currentAgent, agentGroups) =>
    new PrescriptionPolicy(currentAgent, agentGroups).list(),
  )

export const $canOpenAdminApp = (): boolean =>
  can((currentAgent, agentGroups) =>
    new AdminAppPolicy(currentAgent, agentGroups).show(),
  )

export const $canPostInceptionChatMessage = (room: Room): boolean =>
  can((currentAgent, agentGroups) =>
    new InceptionChatPolicy(currentAgent, agentGroups).postText(room),
  )

const can = (
  fn: (currentAgent: Agent, agentGroups: AgentGroup[]) => boolean,
): boolean => {
  const { currentAgent, agentGroups } = storeToRefs(useAgentStore())
  if (!currentAgent.value || !agentGroups.value) return false
  return fn(currentAgent.value, agentGroups.value)
}
