import { kidAge, KidAgeScope } from '@/utils/kid-age'
import { Kid } from 'Models/kid'
import { RoomKid } from 'Models/room'
import i18nPlugin from '@/plugins/vue-use/i18n'
import { UIKidProfile } from '@/domains/kid/ui-models'

function isRoomKid(kid: Kid | RoomKid | UIKidProfile): kid is RoomKid {
  return (kid as RoomKid).gender !== undefined
}

const kidAgeWrapper = (
  kid: Kid | RoomKid | UIKidProfile,
  today: Date | undefined,
  scope?: KidAgeScope,
): string => {
  const sex = isRoomKid(kid) ? kid.gender : kid.sex
  return kidAge(kid.birthDate, sex, i18nPlugin.global.locale, today, scope)
}

export const $kidAge = (
  kid: Kid | RoomKid | UIKidProfile,
  today?: Date,
  scope?: KidAgeScope,
): string => {
  return kidAgeWrapper(kid, today, scope)
}

export const $shortKidAge = (
  kid: Kid | RoomKid | UIKidProfile,
  today?: Date,
): string => {
  return kidAgeWrapper(kid, today, KidAgeScope.Short)
}

export const $mrhKidAge = (
  kid: Kid | RoomKid | UIKidProfile,
  today?: Date,
): string => {
  return kidAgeWrapper(kid, today, KidAgeScope.MRH)
}

export const $growthKidAge = (
  kid: Kid | RoomKid | UIKidProfile,
  today?: Date,
): string => {
  return kidAgeWrapper(kid, today, KidAgeScope.Growth)
}
