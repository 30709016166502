import { ActionTree } from './types'
import { endOfDay, startOfDay, subWeeks } from 'date-fns'
import { Agent } from 'Models/agent'
import { PrescriptionListFilter } from 'Models/prescription'
import { UIPrescriptionListUrlParams } from '@/domains/prescription/ui-models'
import { setPrescriptions } from './concerns/load-prescriptions'
import { useAgentStore } from 'Store/stores'
import Services from 'Services/services'

export const initPrescriptionListFilter: ActionTree['initPrescriptionListFilter'] =
  async function (urlParams) {
    const agents = useAgentStore().agents

    if (!agents) return

    const filter = buildFilter(urlParams, agents)
    this.prescriptionListFilter = filter

    const prescriptionList = await setPrescriptions(
      Services.getInstance().prescription,
      Services.getInstance().kid,
      filter,
    )
    this.filteredPrescriptionList = prescriptionList
  }

const buildFilter = (
  urlParams: UIPrescriptionListUrlParams,
  agents: Agent[],
) => {
  // default filter
  const filter: PrescriptionListFilter = {
    doctor: null,
    supervisor: null,
    startDate: subWeeks(new Date(), 1),
    endDate: new Date(),
  }

  if (urlParams.agentId)
    filter.doctor =
      agents.find((agent) => agent.id === urlParams.agentId) || null

  // NOTE: be careful, doctorId (params) is used to the supervisor (desk v1 compatibility)
  if (urlParams.doctorId)
    filter.supervisor =
      agents.find((agent) => agent.id === urlParams.doctorId) || null

  if (urlParams.startDate)
    filter.startDate = startOfDay(new Date(urlParams.startDate))

  if (urlParams.endDate) filter.endDate = endOfDay(new Date(urlParams.endDate))

  return filter
}
