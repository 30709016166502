import { UIQuickReply } from '@/domains/saved-reply/ui-models'
import { getMatches } from '@/utils/matches'

export const useQuickReply = (): {
  search: Search
} => {
  const search: Search = (quickReplies, query) => {
    if (query.length < 2 || !quickReplies) return []

    const result = getMatches<UIQuickReply>(
      {
        items: quickReplies ?? [],
        fields: ['content'],
      },
      query,
    )

    // Hard limit the number of results
    return result.splice(0, 6)
  }

  return {
    search,
  }
}

/* === Types === */

type Search = (
  quickReplies: UIQuickReply[] | null,
  query: string,
) => UIQuickReply[]
