import { ILocalStorageService, ILocalStorage, LocalKeys } from './types'

const SESSION_DURATION = 3600 * 24 * 1000 // in milliseconds

export default class LocalStorageService implements ILocalStorageService {
  localStorage: ILocalStorage

  constructor(localStorage: ILocalStorage) {
    this.localStorage = localStorage
  }

  setDraftMessage(roomId: string, message: string): void {
    this.localStorage.setItem(roomId, message)
  }
  getDraftMessage(roomId: string): string | null {
    return this.localStorage.getItem(roomId)
  }
  clearDraftMessage(roomId: string): void {
    this.localStorage.removeItem(roomId)
  }

  getAPIToken(): string | null {
    return this.get(LocalKeys.ApiToken)
  }
  setAPIToken(value: string, now: number | null): void {
    const expiresAt = (now || Date.now()) + SESSION_DURATION
    this.set(LocalKeys.ApiToken, value)
    this.set(LocalKeys.ApiTokenExpiresAt, expiresAt.toString())
  }
  clearAPIToken(): void {
    this.localStorage.removeItem(LocalKeys.ApiToken)
    this.localStorage.removeItem(LocalKeys.ApiTokenExpiresAt)
  }

  getAPITokenExpiresAt(): number | null {
    const value = this.get(LocalKeys.ApiTokenExpiresAt)
    return value ? parseInt(value) : null
  }

  getFirebaseToken(): string | null {
    return this.get(LocalKeys.FirebaseToken)
  }
  setFirebaseToken(value: string): void {
    this.set(LocalKeys.FirebaseToken, value)
  }
  clearFirebaseToken(): void {
    this.localStorage.removeItem(LocalKeys.FirebaseToken)
  }

  clearAll(): void {
    for (const key in LocalKeys) {
      this.localStorage.removeItem(key)
    }
  }

  protected get(key: LocalKeys): string | null {
    return this.localStorage.getItem(key)
  }

  protected set(key: LocalKeys, value: string): void {
    this.localStorage.setItem(key, value)
  }
}
