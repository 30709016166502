import { ActionTree } from './types'
import { useAgentStore } from 'Store/stores'
import Services from 'Services/services'

export const unassignCurrentRoom: ActionTree['unassignCurrentRoom'] =
  async function () {
    const room = this.currentRoom
    const assignedBy = useAgentStore().currentAgent

    if (!room || !assignedBy) return

    await Services.getInstance().room.assign(room, null, assignedBy)
    return Services.getInstance().message.postUnassignDeskMessage(
      room.id,
      assignedBy,
    )
  }
