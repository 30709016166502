export const includes = (
  haystack: Array<string | number>,
  needle: string | number,
): boolean => haystack.includes(needle)

export const moveElement = <T>(
  array: Array<T>,
  fromIndex: number,
  toIndex: number,
): Array<T> => {
  const newArray = [...array] // to not mutate original
  const element = newArray[fromIndex]
  newArray.splice(fromIndex, 1)
  newArray.splice(toIndex, 0, element)

  return newArray
}
