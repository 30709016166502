<script lang="ts" setup>
import { PropType, ref, computed } from 'vue'
import { UISavedReply } from '@/domains/saved-reply/ui-models'
import { lastElOfArray } from '@/utils/last-el-of-array'
import { makeTree } from '@/utils/make-tree'
import { isEmpty } from '@/utils/is-empty'

import Body from '@/components/panel/side/layout/Body.vue'
import Tree from './Tree.vue'
import Breadcrumb from './Breadcrumb.vue'

const props = defineProps({
  savedReplies: {
    type: Array as PropType<UISavedReply[]>,
    required: true,
  },
  usedSavedReplyIds: {
    type: Array as PropType<string[]> | undefined,
    required: false,
  },
  selectedSavedReply: {
    type: String,
    required: false,
  },
})

const breadcrumb = ref<{ id: string; title: string }[]>([])

const savedReplyParentId = computed(() => {
  if (isEmpty(breadcrumb.value)) return
  return lastElOfArray(breadcrumb.value).id
})
const originalTree = computed(() => makeTree(props.savedReplies))
const tree = computed(() =>
  savedReplyParentId.value
    ? makeTree(
        props.savedReplies.filter(
          (savedReply) => savedReply.parentId === savedReplyParentId.value,
        ),
      )
    : originalTree.value,
)

const onUpdateBreadcrumb = (id: string, title: string) =>
  breadcrumb.value.push({ id, title })
const onResetBreadcrumb = () => {
  breadcrumb.value = []
}
const onBackBreadcrumb = () => breadcrumb.value.pop()
</script>

<template>
  <div class="flex flex-col flex-1 border-r border-default-100 w-1/2">
    <Breadcrumb
      :breadcrumb="breadcrumb"
      @reset-breadcrumb="onResetBreadcrumb"
      @back-breadcrumb="onBackBreadcrumb"
    />

    <Body class="py-3">
      <Tree
        v-for="(node, nodeIndex) in tree"
        :key="node.id"
        :used-saved-reply-ids="usedSavedReplyIds"
        :selected-saved-reply="selectedSavedReply"
        :node="node"
        :index="nodeIndex"
        v-bind="$attrs"
        @update-breadcrumb="onUpdateBreadcrumb"
      />
    </Body>
  </div>
</template>
