import { RoutesNames } from '@/router/routes/type'
import {
  LocationQueryValue,
  NavigationFailure,
  RouteLocationRaw,
} from 'vue-router'

export function checkAuthAndRedirect(
  isAuthenticated: boolean,
  fullPath: string | undefined,
  nextUrl: LocationQueryValue | LocationQueryValue[] | undefined,
  push: (to: RouteLocationRaw) => Promise<void | NavigationFailure | undefined>,
): void {
  if (isAuthenticated) {
    // context: first time loading the application while still being authenticated, we don't want to interrupt
    // Router to render the requested view.
    if (fullPath === '/') return

    // context: the user has just signed in with success (2FA or email/password)
    push(nextUrl ? String(nextUrl) : { name: RoutesNames.Main })
    return
  }

  push({
    name: RoutesNames.SignIn,
    query: { nextUrl: fullPath },
  })
}
