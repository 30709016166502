import { Agent } from 'Models/agent'
import { Room } from 'Models/room'
import {
  IRoomClosingService,
  IRoomService,
  IInceptionChatService,
  IMedicalReportService,
} from './__types__'

export default class RoomClosingService implements IRoomClosingService {
  roomService: IRoomService
  inceptionChatService: IInceptionChatService
  medicalReportService: IMedicalReportService

  constructor(
    roomService: IRoomService,
    inceptionChatService: IInceptionChatService,
    medicalReportService: IMedicalReportService,
  ) {
    this.roomService = roomService
    this.inceptionChatService = inceptionChatService
    this.medicalReportService = medicalReportService
  }

  async close(room: Room, agent: Agent): Promise<void> {
    await Promise.all([
      this.roomService.close(room, agent),
      this.inceptionChatService.close(room.id),
    ])
  }

  async reOpen(
    room: Room,
    agent: Agent,
    medicalReportId: string,
  ): Promise<void> {
    await Promise.all([
      this.roomService.reOpen(room, agent),
      this.medicalReportService.update(medicalReportId, { agent }),
      this.inceptionChatService.reOpen(room.id),
    ])
  }
}
