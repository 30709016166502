import { RoomStatuses } from 'Models/room'
import { ref, Ref } from 'vue'

export default function useRoomsStatusTab(
  roomsStatusFromRoute?: RoomStatuses,
): {
  tabIndex: Ref<number>
  roomsStatusFromIndex: Ref<RoomStatuses>
  setRoomsStatusFromIndex: (index: number) => void
} {
  const tabIndex = ref(0)
  const roomsStatusFromIndex = ref()

  const statuses = [
    RoomStatuses.Open,
    RoomStatuses.Closed,
    RoomStatuses.Awake,
    RoomStatuses.Asleep,
  ]

  tabIndex.value = statuses.indexOf(roomsStatusFromRoute ?? RoomStatuses.Open)

  const setRoomsStatusFromIndex = (index: number) =>
    (roomsStatusFromIndex.value = statuses[index])

  return { tabIndex, roomsStatusFromIndex, setRoomsStatusFromIndex }
}
