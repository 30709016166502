import { nextTick } from 'vue'

export default function useInsertText(): {
  insertText: (
    textarea: HTMLTextAreaElement,
    text: string,
    callback: (text: string, startPos: number, endPos: number) => void,
  ) => void
} {
  const insertText = async (
    textarea: HTMLTextAreaElement,
    text: string,
    callback: (text: string, startPos: number, endPos: number) => void,
  ): Promise<void> => {
    const startPos = textarea.selectionStart
    const endPos = textarea.selectionEnd
    const oldText = textarea.value

    const newText =
      oldText.substring(0, startPos) +
      text +
      oldText.substring(endPos, oldText.length)

    callback(newText, startPos, endPos)

    await nextTick()

    textarea.focus()
    textarea.selectionStart = startPos + text.length
    textarea.selectionEnd = startPos + text.length
  }

  return { insertText }
}
