// Allow to create a fake promise to use with async/await
export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Wait until the condition becomes false OR the max attempts is reached.
 * @param condition
 * @param duration By default, 250ms
 * @param maxAttempts By default, 5 attempts
 */
export async function sleepWhile(
  condition: () => boolean,
  duration?: number,
  maxAttempts?: number,
) {
  duration = duration || 250
  maxAttempts = maxAttempts || 5
  let attempts = 0
  do {
    await sleep(duration)
    attempts += 1
  } while (attempts < maxAttempts && condition())
}
