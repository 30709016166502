<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'
import useOpenRoom from '@/hooks/open-room'

import AssignmentHistory from '../AssignmentHistory.vue'

defineProps({
  roomId: {
    type: String,
    required: true,
  },
  roomCreatedAt: {
    type: Date,
    required: true,
  },
  isCurrentRoom: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()
const { goToRoom } = useOpenRoom()
</script>

<template>
  <header
    v-if="isCurrentRoom"
    class="flex flex-row w-full items-center border-b rounded-t-lg border-default-200 px-3 py-2 mb-3"
  >
    <div class="flex flex-1">
      {{ $formatDate(roomCreatedAt, 'long') }}
    </div>
  </header>

  <div
    v-else
    class="flex flex-row w-full items-center border-b rounded-t-lg border-default-200 px-3 py-2 mb-3 bg-default-50"
  >
    <div
      v-tooltip.top="t('open')"
      class="flex font-semibold cursor-pointer"
      @click="goToRoom(roomId, true)"
    >
      {{ $formatDate(roomCreatedAt, 'long') }}
    </div>

    <div class="flex flex-1 justify-end space-x-4">
      <AssignmentHistory :room-id="roomId" icon-class="text-lg" />

      <div
        v-tooltip.left="t('open')"
        class="flex items-center text-primary text-sm cursor-pointer"
        @click="goToRoom(roomId, true)"
      >
        <font-awesome-icon :icon="['fal', 'external-link']" />
      </div>
    </div>
  </div>
</template>
