import { MessageSenderType } from 'Models/message'

export const $messageSenderTypeToAvatarType = (
  type: MessageSenderType,
): 'agent' | 'user' | undefined => {
  switch (type) {
    case MessageSenderType.Agent:
      return 'agent'
    case MessageSenderType.User:
      return 'user'
    default:
      return undefined
  }
}
