<script lang="ts" setup>
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIKidAntecedentCategory } from '@/domains/kid/ui-models'
import { UIMRLCoreItem } from '@/domains/medical-report/ui-models'

import Body from '@/components/panel/side/layout/Body.vue'
import Search from '@/components/panel/side/shared/Search.vue'
import SelectableItem from './SelectableItem.vue'
import Section from '@/components/panel/side/shared/Section.vue'
import List from '@/components/panel/side/shared/list/List.vue'

const props = defineProps({
  groupedAntecedents: {
    type: Object as PropType<UIKidAntecedentCategory[]>,
    required: true,
  },
  query: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'update:query', query: string): void
  (
    e: 'select',
    {
      item,
      checked,
      hidden,
    }: {
      item: UIMRLCoreItem
      checked: boolean
      hidden: boolean
    },
  ): void
}>()

const { t } = useScopedI18n()

const searchQuery = computed({
  get: () => props.query,
  set: (newQuery) => emits('update:query', newQuery),
})

const hasGroupedAntecedents = computed(
  () => props.groupedAntecedents.length > 0,
)
</script>

<template>
  <div>
    <Search
      v-model:query="searchQuery"
      class="flex items-center justify-center mt-3"
    />
    <Body
      class="w-72 h-60"
      :class="{ 'items-center justify-center': !hasGroupedAntecedents }"
    >
      <div v-if="hasGroupedAntecedents" class="divide-y divide-default-100">
        <List
          v-for="(group, groupIndex) in groupedAntecedents"
          :key="'antecedent-group-' + groupIndex"
          :title="group.name"
          :list="group.antecedents"
          :is-big-title="true"
        >
          <SelectableItem
            v-for="(item, index) in group.antecedents"
            :key="'item-' + index"
            name="antecedent"
            :item="item"
            :query="query"
            @select="emits('select', { ...$event })"
          />
        </List>
      </div>
      <Section v-else class="mt-5 text-default-light">
        {{ t('noResult') }}
      </Section>
    </Body>
  </div>
</template>
