<script lang="ts" setup>
import { toRefs } from 'vue'
import { PropType } from '@vue/runtime-core'
import { PlanningAgentsTypes } from 'Models/planning'
import { UIPlanningSlot } from '@/domains/planning/ui-models'
import controller from '@/domains/planning/controllers/avatars/display-avatars'

import BaseAvatar from '@/components/base/BaseAvatar.vue'
import BaseCounter from '@/components/base/BaseCounter.vue'

const props = defineProps({
  type: {
    type: String as PropType<PlanningAgentsTypes>,
    required: true,
  },
  planningSlot: {
    type: Object as PropType<UIPlanningSlot>,
    required: true,
  },
})

const emits = defineEmits<{
  (
    e: 'openAgentList',
    planningSlot: UIPlanningSlot,
    agentsType: PlanningAgentsTypes,
  ): void
}>()

const { type, planningSlot } = toRefs(props)
const { counterMinimum, agentsCount, agentsOverMax, displayedAgents } =
  controller(type, planningSlot)
</script>

<template>
  <div
    class="flex h-full pl-2 space-x-2 cursor-pointer"
    :class="`avatars-${type}`"
    @click="emits('openAgentList', planningSlot, type)"
  >
    <div
      v-for="(agent, idx) in displayedAgents"
      :key="`planning-${agent.id}-${idx}`"
    >
      <BaseAvatar
        v-tooltip.bottom="$fullname(agent, false)"
        type="agent"
        :first-name="agent.firstName"
        :last-name="agent.lastName"
        :profile-pic="agent.profilePic"
        :size="3"
        :working-dot-enabled="true"
        :is-working="agent.isWorking"
      />
    </div>

    <div
      v-if="agentsCount > counterMinimum"
      :class="[`avatars-counter-${type}`, { hidden: !agentsOverMax }]"
    >
      <BaseCounter :size="3" :count="agentsCount" :has-ring="true" />
    </div>
  </div>
</template>

<style scoped>
@media screen and (max-width: 1620px) {
  .avatars-nurses div:nth-child(n + 6):nth-last-child(n + 2) {
    display: none;
  }

  .avatars-counter-nurses.hidden {
    display: block;
  }
}
</style>
