import { GetterTree } from './types'
import { getCurrentKidAvailableAntecedents } from './get-current-kid-available-antecedents'
import { getCurrentKidParentRoomsTabs } from './get-current-kid-parent-rooms-tabs'
import { getCurrentKidParent } from './get-current-kid-parent'
import { getCurrentKidProfile } from './get-current-kid-profile'

export const getters: GetterTree = {
  getCurrentKidAvailableAntecedents,
  getCurrentKidParentRoomsTabs,
  getCurrentKidParent,
  getCurrentKidProfile,
}
