export enum MRLTopics {
  Antecedents = 'antecedents',
  Causes = 'causes',
  Diagnosis = 'diagnosis',
  Directions = 'directions',
  Supervisions = 'supervisions',
}

// only used in medical reports
export enum MRLStrictTopics {
  Causes = 'causes',
  Diagnosis = 'diagnosis',
  InitialDirections = 'initialDirections',
  Directions = 'directions',
  Supervisions = 'supervisions',
}

export type MRLItem = CoreMRLItem & {
  filters: MRLItemFilters
  triggerRDLetter: boolean
  hidden?: boolean
}

export type FBMRLItem = CoreMRLItem & {
  filters: FBMRLItemFilters
  triggerRDLetter?: boolean
}

type CoreMRLItem = {
  id: string
  parentId?: string
  title: Record<string, string>
  published: boolean
  canBeHidden?: boolean
  severity: number
  position: number
  agentGroupIds?: string[]
}

type FBMRLItemFilters = {
  gender: 'girl' | 'boy' | 'all'
  maxAge: number
  minAge: number
}

export type MRLItemFilters = {
  sex: MRLItemFilterSexType
  maxAge: number
  minAge: number
}

export type MRLibraries = {
  causes: MRLItem[] | null
  diagnosis: MRLItem[] | null
  directions: MRLItem[] | null
  supervisions: MRLItem[] | null
}

export enum MRLItemFilterSexType {
  All = 'all',
  Female = 'female',
  Male = 'male',
}
