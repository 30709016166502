<script lang="ts" setup>
import { PropType, ref, watch, Ref } from 'vue'
import { UIPrescription } from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'
import Section from '@/components/panel/side/shared/Section.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'

const props = defineProps({
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'updateInstructions', newInstructions: string): void
}>()

const { t, tc } = useScopedI18n()

const instructions: Ref<string> = ref('')

watch(
  () => instructions.value,
  (newValue) => emits('updateInstructions', newValue),
)

watch(
  () => props.prescription,
  (prescription) => (instructions.value = prescription.instructions.join('\n')),
  { immediate: true },
)
</script>

<template>
  <Section>
    <Spacer />
    <Title>
      {{ tc('title', prescription.instructions.length) }}
    </Title>
    <Spacer />
    <textarea
      v-model="instructions"
      class="bg-default-50 rounded-sm w-full scrollbar px-2 py-2 h-32 placeholder-default-400 border border-default-100 focus:border-primary focus:outline-none focus:shadow-lg"
      :placeholder="t('placeholder')"
    />
  </Section>
</template>
