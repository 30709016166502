import { GetterTree } from './types'
import { useMedicalReportStore, useRoomStore, useUIStore } from 'Store/stores'
import { buildAntecedentCategoriesFromIds } from './concerns/build-antecedent-categories'
import { UIKidProfile } from '../../ui-models'

export const getCurrentKidProfile: GetterTree['getCurrentKidProfile'] =
  function ({ currentKid }) {
    const locale = useUIStore().locale
    const antecedents = useMedicalReportStore().antecedents
    const currentRoom = useRoomStore().currentRoom

    if (!currentKid || !locale || !antecedents || !currentRoom) return null

    let groupedAntecedents = null
    if (currentKid.antecedentIds && currentKid.hiddenAntecedentIds)
      groupedAntecedents = buildAntecedentCategoriesFromIds(
        antecedents,
        currentKid.antecedentIds,
        currentKid.hiddenAntecedentIds,
        locale,
      )

    const profile: UIKidProfile = {
      id: currentKid.id,
      roomId: currentRoom.id,
      firstName: currentKid.firstName,
      lastName: currentKid.lastName,
      sex: currentKid.sex,
      isBoy: currentKid.sex === 'male',
      birthDate: currentKid.birthDate,
      hasAntecedents: !!groupedAntecedents && groupedAntecedents.length > 0,
      groupedAntecedents,
    }

    return profile
  }
