import {
  FBSavedReply,
  SavedReply,
  SavedReplyMedicalReport,
} from 'Models/saved-reply'

export const convertFrom = (fbSavedReply: FBSavedReply): SavedReply => ({
  id: fbSavedReply.id,
  title: fbSavedReply.title,
  image: fbSavedReply.image,
  isImage: fbSavedReply.isImage,
  content: fbSavedReply.content,
  published: fbSavedReply.published,
  parentId: fbSavedReply.parentId,
  leaf: fbSavedReply.leaf,
  position: fbSavedReply.position,
  agentGroupIds: fbSavedReply.agentGroupIds,
  medicalReport: convertMedicalReportFrom(fbSavedReply),
})

const convertMedicalReportFrom = (
  fbSavedReply: FBSavedReply,
): SavedReplyMedicalReport | undefined => {
  if (!fbSavedReply.medicalReport) return

  const medicalReport: SavedReplyMedicalReport = {}

  if (fbSavedReply.medicalReport.causes)
    medicalReport.causeIds = fbSavedReply.medicalReport.causes?.map(
      (item) => item.id,
    )

  if (fbSavedReply.medicalReport.diagnosis)
    medicalReport.diagnosisIds = fbSavedReply.medicalReport.diagnosis?.map(
      (item) => item.id,
    )

  if (fbSavedReply.medicalReport.directions)
    medicalReport.directionIds = fbSavedReply.medicalReport.directions?.map(
      (item) => item.id,
    )

  if (fbSavedReply.medicalReport.supervisions)
    medicalReport.supervisionIds = fbSavedReply.medicalReport.supervisions?.map(
      (item) => item.id,
    )

  return medicalReport
}
