import { ActionTree } from './types'
import { initRoomListFilter } from './init-room-list-filter'
import { changeRoomListFilter } from './change-room-list-filter'
import { resetRoomListFilter } from './reset-room-list-filter'
import { setRooms } from './set-rooms'
import { setInceptionChatRooms } from './set-inception-chat-rooms'

export const actions: ActionTree = {
  initRoomListFilter,
  changeRoomListFilter,
  resetRoomListFilter,
  setRooms,
  setInceptionChatRooms,
}
