import { ActionTree } from './types'
import Services from 'Services/services'

export const loadCurrentFilteredPrescription: ActionTree['loadCurrentFilteredPrescription'] =
  async function (prescriptionId) {
    if (!prescriptionId) return

    this.currentFilteredPrescription = null
    const prescription = await Services.getInstance().prescription.load(
      prescriptionId,
    )

    if (prescription) {
      const kid = await Services.getInstance().kid.load(prescription.kidId)
      if (kid) prescription.kid = { ...kid, weight: prescription.kidWeightInKg }

      const parent = await Services.getInstance().kid.loadKidParent(
        prescription.userId,
      )
      if (parent) prescription.parent = { ...parent }
    }

    this.currentFilteredPrescription = prescription
  }
