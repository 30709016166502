<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { useLoadTodayPlanning } from '../controllers/load-today-planning'

import BaseModal from '@/components/base/BaseModal.vue'
import PlanningModalTitle from '../components/planning-modal/header/PlanningModalTitle.vue'
import PlanningModalBody from '../components/planning-modal/body/PlanningModalBody.vue'

const {
  setup,
  now,
  todayRoomsCount,
  planning,
  agentPicked,
  updateAgentPicked,
} = useLoadTodayPlanning()

onBeforeMount(async () => await setup())
</script>

<template>
  <BaseModal :is-loading="!planning">
    <template #header>
      <PlanningModalTitle
        v-if="planning"
        :today-rooms-count="todayRoomsCount"
        :agents="planning.agents"
        :agent-picked="agentPicked"
        @select-agent="updateAgentPicked"
        @clear-agent="updateAgentPicked(null)"
      />
    </template>
    <PlanningModalBody
      v-if="planning"
      :slots="planning.slots"
      :now="now"
      :agent-picked="agentPicked"
    />
  </BaseModal>
</template>
