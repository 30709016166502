import {
  UIRoom,
  UIRoomListMe,
  UIRoomListTeam,
  UIRoomListClosed,
} from '@/domains/room-list/ui-models'
import { groupRoomsBy } from '@/utils/group-rooms-by'
import { isEmpty } from '@/utils/is-empty'
import compareAsc from 'date-fns/compareAsc'
import { Agent } from 'Models/agent'
import Services from 'Services/services'
import { removeCurrentAgent, getTotalOfRooms } from './build-room-list'

export const buildRoomListMe = (
  rooms: UIRoom[],
  awakeRooms: UIRoom[],
  asleepRooms: UIRoom[],
  inceptionChatRooms: UIRoom[] | null,
  currentAgent: Agent,
  now: Date,
  roomServices: Services['room'],
): UIRoomListMe => {
  const counter = rooms.length

  const inceptionChatCounter = inceptionChatRooms?.length || null
  const readInceptionChatRooms =
    inceptionChatRooms?.filter(
      (room) =>
        !room.hasUnreadInceptionChatMessages && room.hasInceptionChatMessages,
    ) || null
  const unreadInceptionChatRooms =
    inceptionChatRooms?.filter((room) => room.hasUnreadInceptionChatMessages) ||
    null
  const hasUnreadInceptionChats =
    inceptionChatRooms?.some((room) => room.hasUnreadInceptionChatMessages) ||
    false

  // NOTE: Displays the purple dot for all the room not coming from inception chat rooms
  if (unreadInceptionChatRooms)
    rooms.forEach((room) => {
      room.hasUnreadInceptionChatMessages = unreadInceptionChatRooms.some(
        (unreadInceptionChat) => unreadInceptionChat.id === room.id,
      )
    })

  // NOTE: waiting for Agent's answer
  const pendingRooms: UIRoom[] = getPendingRooms(rooms, roomServices, now)
  const pendingCounter = pendingRooms.length

  // NOTE: waiting for Parent's answer
  const inactiveRooms: UIRoom[] = getInactiveRooms(rooms, roomServices, now)
  const inactiveCounter = inactiveRooms.length

  const activeRooms = getActiveRooms(rooms, pendingRooms, inactiveRooms)
  const activeCounter = activeRooms.length

  const hasUnreadRooms = rooms.some(
    (room) => !room.haveAgentsRead.includes(currentAgent.id),
  )

  const awakeRoomsCounter = awakeRooms.length
  const asleepRoomsCounter = asleepRooms.length

  return {
    currentAgent,
    activeRooms,
    activeCounter,
    pendingRooms,
    pendingCounter,
    inactiveRooms,
    inactiveCounter,
    awakeRooms,
    awakeRoomsCounter,
    asleepRooms,
    asleepRoomsCounter,
    hasUnreadRooms,
    inceptionChatCounter,
    readInceptionChatRooms,
    unreadInceptionChatRooms,
    hasUnreadInceptionChats,
    counter,
    hasRooms: counter > 0,
  }
}

export const buildRoomListTeam = (
  assignedRooms: UIRoom[],
  agents: Agent[],
  currentAgent: Agent,
  pickedAgentId: string | null,
): UIRoomListTeam => {
  let hasFilters = false

  let roomsByAgent = groupRoomsBy('agent', agents, assignedRooms).sort(
    // Sort alphabetically by firstName
    (groupA, groupB) =>
      groupA.agent.firstName.localeCompare(groupB.agent.firstName),
  )

  roomsByAgent = removeCurrentAgent(roomsByAgent, currentAgent)

  // Set new list of agents
  agents = roomsByAgent.map(({ agent }) => agent)

  const counter = getTotalOfRooms(roomsByAgent)
  const hasRooms = counter > 0

  let counterFiltered = counter

  // Filter: single agent
  if (pickedAgentId) {
    roomsByAgent = roomsByAgent.filter(
      ({ agent }) => agent.id === pickedAgentId,
    )
    counterFiltered = getTotalOfRooms(roomsByAgent)
    hasFilters = true
  }

  return {
    roomsByAgent,
    agents,
    counter,
    hasRooms,
    hasFilters,
    counterFiltered,
  }
}

export const buildRoomListClosed = (
  agents: Agent[],
  closedRooms: UIRoom[],
  pickedAgentId: string | null,
): UIRoomListClosed => {
  let hasFilters = false
  const counter = closedRooms.length
  const hasRooms = counter > 0

  let roomsByAgent = groupRoomsBy('agent', agents, closedRooms).sort(
    // Sort alphabetically by firstName
    (groupA, groupB) =>
      groupA.agent.firstName.localeCompare(groupB.agent.firstName),
  )

  const unassignedRooms = closedRooms.filter((room) =>
    isEmpty(room.assignedAgent),
  )

  // Set new agents list
  agents = roomsByAgent.map(({ agent }) => agent)

  let counterFiltered = counter

  // Filter by agent
  if (pickedAgentId) {
    roomsByAgent = roomsByAgent.filter(
      ({ agent }) => agent.id === pickedAgentId,
    )
    counterFiltered = getTotalOfRooms(roomsByAgent)
    hasFilters = true
  }

  return {
    unassignedRooms,
    roomsByAgent,
    agents,
    counter,
    hasRooms,
    hasFilters,
    counterFiltered,
  }
}

const getPendingRooms = (
  rooms: UIRoom[],
  roomServices: Services['room'],
  now: Date,
) =>
  rooms
    .filter((room) => {
      return roomServices.isPendingRoom(
        room.lastHumanSender,
        room.lastParentThreadStartedAt ?? room.lastMessage?.createdAt,
        now,
      )
    })
    .sort((roomA, roomB) =>
      compareAsc(
        roomA.lastMessage?.createdAt ?? roomA.createdAt,
        roomB.lastMessage?.createdAt ?? roomB.createdAt,
      ),
    )

const getInactiveRooms = (
  rooms: UIRoom[],
  roomServices: Services['room'],
  now: Date,
) =>
  rooms
    .filter((room) => {
      return roomServices.isInactiveRoom(room, now)
    })
    .sort((roomA, roomB) =>
      compareAsc(
        roomA.lastMessage?.createdAt ?? roomA.createdAt,
        roomB.lastMessage?.createdAt ?? roomB.createdAt,
      ),
    )

const getActiveRooms = (
  rooms: UIRoom[],
  pendingRooms: UIRoom[],
  inactiveRooms: UIRoom[],
) =>
  rooms
    .filter(
      (room) =>
        ![...pendingRooms, ...inactiveRooms]
          .map((room) => room.id)
          .includes(room.id),
    )
    .sort((roomA, roomB) =>
      compareAsc(
        roomB.lastMessage?.createdAt ?? roomB.createdAt,
        roomA.lastMessage?.createdAt ?? roomA.createdAt,
      ),
    )
