import { lastElOfArray } from '@/utils/last-el-of-array'
import { GetterTree } from './types'

export const getPanelName: GetterTree['getPanelName'] = function (state) {
  return (panelPosition) => {
    const components = state[panelPosition].components
    if (components.length === 0) return null
    return lastElOfArray(components)
  }
}
