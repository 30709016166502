<script lang="ts" setup>
import { PropType, toRefs } from 'vue'
import { PlanningAgentsTypes } from 'Models/planning'
import { UIPlanningSlot } from '@/domains/planning/ui-models'
import controller from '../../../../controllers/agents/build-agents-tabs'

import PlanningModalAgentsTab from './PlanningModalAgentsTab.vue'

const props = defineProps({
  planningSlot: {
    type: Object as PropType<UIPlanningSlot>,
    required: true,
  },
  selectedAgentsType: {
    type: String as PropType<PlanningAgentsTypes>,
    required: true,
  },
  query: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'selectAgents', agentsType: PlanningAgentsTypes): void
}>()

const { tabs, onSelectAgents } = controller(toRefs(props).planningSlot, emits)
</script>

<template>
  <ul class="flex space-x-4 px-4 pt-1 pb-2 border-b border-default-100">
    <PlanningModalAgentsTab
      v-for="tab in tabs"
      :key="tab.agentsType"
      :agents="tab.agents"
      :agents-type="tab.agentsType"
      :selected-agents-type="selectedAgentsType"
      :title="tab.title"
      :query="query"
      @select-agents="onSelectAgents"
    />
  </ul>
</template>
