import { GetterTree } from './types'
import { BuiltInAgentGroups } from 'Models/agent-group'
import { filterAgentsBy } from './concerns/filter-agents'

export const getMedicalReportAssignableAgents: GetterTree['getMedicalReportAssignableAgents'] =
  function ({ agents, agentGroups }) {
    return filterAgentsBy(
      BuiltInAgentGroups.MedicalReportAssignees,
      agents,
      agentGroups,
    )
  }
