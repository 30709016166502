<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { KidGender } from 'Models/kid'

import IconGirl from '@/components/icons/IconGirl.vue'
import IconBoy from '@/components/icons/IconBoy.vue'

const props = defineProps({
  sex: {
    type: String as PropType<KidGender>,
    required: true,
  },
  width: {
    type: [Number, String],
    default: 24,
  },
  height: {
    type: [Number, String],
    default: 24,
  },
})
const isBoy = computed(() => props.sex === 'male')
</script>

<template>
  <IconBoy
    v-if="isBoy"
    v-tooltip.auto="$t('tooltips.boy')"
    :width="width"
    :height="height"
    class="mt-2"
  />
  <IconGirl
    v-else
    v-tooltip.auto="$t('tooltips.girl')"
    :width="width"
    :height="height"
  />
</template>
