import { VueRenderer, JSONContent } from '@tiptap/vue-3'
import tippy, { Instance as TippyInstance } from 'tippy.js'
import { Component } from 'vue'
import { SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion'
import { visitJSON } from '../helpers'
import includes from '@/utils/string-includes'

export type MentionSuggestionItem = {
  id: string
  fullName: string
  profilePic: string
  title: string
  active: boolean
}

export const getMentionSuggestionItemIds = (content: JSONContent): string[] => {
  const ids: string[] = []
  visitJSON(content, (node) => {
    if (node.type === 'mention' && node.attrs?.id) ids.push(node.attrs.id)
  })

  return ids
}

export default (listComponent: Component, items: MentionSuggestionItem[]) => {
  return {
    items: (props: { query: string }) => {
      return items
        .filter((item) => includes(item.fullName, props.query))
        .slice(0, 5)
    },

    render: () => {
      let componentInstance: VueRenderer
      let popup: TippyInstance[]

      return {
        onStart: (props: SuggestionProps) => {
          componentInstance = new VueRenderer(listComponent, {
            props,
            editor: props.editor,
          })

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: componentInstance.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props: SuggestionProps) {
          componentInstance.updateProps(props)

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props: SuggestionKeyDownProps) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
            return true
          }
          return componentInstance.ref?.onKeyDown(props.event)
        },

        onExit() {
          popup[0].destroy()
          componentInstance.destroy()
        },
      }
    },
  }
}
