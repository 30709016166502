import { ActionTree } from './types'
import { useAgentStore } from 'Store/stores'
import Services from 'Services/services'

export const closeRoom: ActionTree['closeRoom'] = async function () {
  const currentAgent = useAgentStore().currentAgent

  if (!this.currentRoom || !currentAgent) return

  Services.getInstance().roomClosing.close(this.currentRoom, currentAgent)

  this.isCurrentRoomJustClosed = true
}
