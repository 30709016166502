import { UISavedReply } from '../ui-models'
import includes from '@/utils/string-includes'

export const searchSavedReply = (
  savedReplies: UISavedReply[],
  query: string,
  isTitleOnly: boolean,
): UISavedReply[] => {
  // Prevent empty string
  if (!query) return savedReplies

  const leafs = savedReplies.filter((savedReply) => savedReply.leaf)

  return leafs.filter(
    ({ title, content }) =>
      isTitleMatching(title, query) ||
      isContentMatching(content, query, isTitleOnly),
  )
}

const isTitleMatching = (field: string, query: string): boolean =>
  includes(field, query)

const isContentMatching = (
  field: string | undefined,
  query: string,
  isTitleOnly: boolean,
): boolean => {
  if (isTitleOnly || !field) return false
  return includes(field, query)
}
