import { GetterTree } from './types'
import { getPanelName } from './get-panel-name'
import { getPanelData } from './get-panel-data'
import { isPanelActive } from './is-panel-active'

export const getters: GetterTree = {
  isPanelActive,
  getPanelName,
  getPanelData,
}
