export const htmlToMobileContent = (html: string): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(
    html.replace(/^-\s*/gim, '•  '),
    'text/html',
  )
  const body = doc.body
  let content = ''

  for (let i = 0; i < body.childNodes.length; i++) {
    const element = body.childNodes[i]
    switch (element.nodeName) {
      case 'P':
        content = `${content}${htmlToMobileContent(
          (<HTMLParagraphElement>element).innerHTML,
        )}\n`
        break

      case 'A':
        content = `${content}${element.textContent} (${
          (<HTMLLinkElement>element).href
        })`
        break

      case '#text':
        content = `${content}${element.textContent}`
        break

      case 'UL':
        element.childNodes.forEach((childElement) => {
          if (childElement.nodeName === 'LI')
            content = `${content}•  ${childElement.textContent}\n`
        })
        break

      case 'BR':
        content = `${content}\n`
        break

      default:
        break
    }
  }
  return content.trim()
}
