import { GetterTree } from './types'
import { getAssignableAgents } from './get-assignable-agents'
import { getCurrentAgentGroupIds } from './get-current-agent-group-ids'
import { getIsCurrentAgentAdmin } from './get-is-current-agent-admin'
import { getMedicalReportAssignableAgents } from './get-medical-report-assignable-agents'
import { getPrescriptionInitiatorAgents } from './get-prescription-initiator-agents'
import { getPrescriptionReferrerAgents } from './get-prescription-referrer-agents'
import { getTeamStatus } from './get-team-status'

export const getters: GetterTree = {
  getAssignableAgents,
  getCurrentAgentGroupIds,
  getIsCurrentAgentAdmin,
  getMedicalReportAssignableAgents,
  getPrescriptionInitiatorAgents,
  getPrescriptionReferrerAgents,
  getTeamStatus,
}
