<script lang="ts" setup>
import { PropType, ref } from 'vue'
import Popper from '@askbiloba/vue3-popper'
import { DropdownPlacement } from '@/plugins/__types__/popper'

defineProps({
  tooltip: { type: String, default: null },
  disabled: { type: Boolean, default: false },
  fullWidth: { type: Boolean, default: false },
  reducedHeight: { type: Boolean, default: false },
  buttonCustomClass: { type: String, default: '' },
  containerClass: { type: String, default: '' },
  offsetDistance: { type: Number, default: 8 },
  placement: {
    type: String as PropType<DropdownPlacement>,
    default: DropdownPlacement.Bottom,
  },
  scroll: { type: Boolean, default: true },
})

const emits = defineEmits<{
  (e: 'openCallback'): void
  (e: 'closeCallback'): void
}>()

const isDropdownOpen = ref(false)

const toggleIsDropdownOpen = () =>
  (isDropdownOpen.value = !isDropdownOpen.value)

defineExpose({
  isDropdownOpen,
})
</script>

<template>
  <Popper
    z-index="30"
    v-bind="$attrs"
    :offset-distance="String(offsetDistance)"
    :placement="placement"
    :disabled="disabled"
    :class="{ 'w-full': fullWidth }"
    @open:popper="emits('openCallback'), (isDropdownOpen = true)"
    @close:popper="emits('closeCallback'), (isDropdownOpen = false)"
  >
    <button
      v-tooltip.bottom="tooltip"
      class="focus:outline-none"
      type="button"
      aria-haspopup="true"
      aria-expanded="false"
      :disabled="disabled"
      :class="{
        [buttonCustomClass]: !!buttonCustomClass,
        'flex items-center w-full': fullWidth,
      }"
      @click="toggleIsDropdownOpen"
    >
      <slot name="button" :is-open="isDropdownOpen" />
    </button>
    <template v-if="isDropdownOpen" #content="{ close, isOpen }">
      <div
        v-keydown:Escape.stop="close"
        class="flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-default-100 focus:outline-none"
        :class="[
          containerClass,
          reducedHeight ? 'max-h-1/2' : 'max-h-2/3',
          { 'overflow-y-auto scrollbar': scroll },
        ]"
      >
        <slot :close="close" :is-open="isOpen" />
      </div>
    </template>
  </Popper>
</template>

<style scoped>
:deep(.popper) {
  transition: background 0s ease-in-out;
}

:deep(.fade-enter-active),
:deep(.fade-leave-active) {
  transition: opacity 0s ease-in-out;
}

:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
