import {
  defineComponent,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  ref,
  computed,
} from 'vue'
import { distanceInWords } from '@/utils/distance-in-words'
import { useUIStore } from 'Store/stores'

const TimeAgo = defineComponent({
  name: 'TimeAgo',
  props: {
    date: { type: Date, required: true },
    autoUpdate: { type: Number, required: false, default: 60 * 1000 },
  },
  setup(props) {
    const locale = computed(() => useUIStore().locale)
    const distance = ref()
    let intervalId: number

    // to refresh it right away
    function setDistance() {
      return (distance.value = distanceInWords(props.date, locale.value))
    }

    // to refresh it every (60s by default)
    function refreshDistance() {
      intervalId = window.setInterval(setDistance, props.autoUpdate)
    }

    onMounted(() => {
      setDistance()

      refreshDistance()
    })

    onBeforeUpdate(() => {
      clearInterval(intervalId)

      setDistance()

      refreshDistance()
    })

    onUnmounted(() => {
      clearInterval(intervalId)
    })

    return { distance }
  },
  template: `
    <time v-tooltip.bottom="$formatDate(date)">
      {{ distance }}
    </time>
  `,
})

export default TimeAgo
