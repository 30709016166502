import { IAPIService, SpellcheckError } from 'External/api/types'
import { ISpellcheckerService } from './__types__'

export default class SpellcheckerService implements ISpellcheckerService {
  // External services
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    this.apiService = apiService
  }

  async call(text: string): Promise<SpellcheckError[]> {
    const response = await this.apiService.spellcheck(text)
    return response.corrections?.errors || []
  }
}
