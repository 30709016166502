import {
  DocumentData,
  serverTimestamp,
  WithFieldValue,
} from 'firebase/firestore'
import {
  FBInceptionChatMessage,
  InceptionChatMessage,
} from 'Models/inception-chat-message'

export const convertFrom = (
  fbMessage: FBInceptionChatMessage,
): InceptionChatMessage => ({
  id: fbMessage.id,
  chatId: fbMessage.chatId,
  roomId: fbMessage.roomId,
  agentId: fbMessage.agentId,
  type: fbMessage.type,
  content: fbMessage.content,
  mentionedAgentIds: fbMessage.mentionedAgentIds,
  postedAt: fbMessage.createdAt?.toDate() || new Date(),
})

export const convertTo = (
  message: WithFieldValue<InceptionChatMessage>,
): DocumentData => {
  return {
    chatId: message.chatId,
    roomId: message.roomId,
    agentId: message.agentId,
    type: message.type,
    content: message.content,
    mentionedAgentIds: message.mentionedAgentIds,
    createdAt: serverTimestamp(),
  }
}
