import { ActionTree } from './types'
import { useAgentStore } from 'Store/stores'
import { isEmpty } from '@/utils/is-empty'
import Services from 'Services/services'

export const toggleCurrentRoomAsFavorite: ActionTree['toggleCurrentRoomAsFavorite'] =
  async function () {
    const currentAgent = useAgentStore().currentAgent

    if (!this.currentRoom || !currentAgent) return

    if (
      !isEmpty(this.currentRoom.markedByAgentsAsFavorite) &&
      this.currentRoom.markedByAgentsAsFavorite.includes(currentAgent.id)
    )
      return Services.getInstance().room.unmarkAsFavorite(
        this.currentRoom,
        currentAgent.id,
      )

    return Services.getInstance().room.markAsFavorite(
      this.currentRoom,
      currentAgent.id,
    )
  }
