<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import useServices from '@/hooks/services'
import usePolling from '@/hooks/poll'
import {
  buildChat,
  buildMessages,
  buildMessage,
  buildAgentsToMention,
} from '../helpers'
import { UICoreChatMessage } from '@/domains/core-chat/ui-models'
import { UIInceptionChat } from '@/domains/inception-chat/ui-models'
import { storeToRefs } from 'pinia'
import { useAgentStore, useRoomStore } from 'Store/stores'

import InceptionChat from '../components/InceptionChat.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'

const { currentRoom, currentRoomId } = storeToRefs(useRoomStore())
const agentStore = useAgentStore()
const { inceptionChat: inceptionChatService } = useServices()
usePolling(async () => pollMessages())

const chat = ref<UIInceptionChat | undefined>(undefined)
const messages = ref<UICoreChatMessage[] | undefined>(undefined)

const currentAgent = computed(() => agentStore.currentAgent)
const currentAgentId = computed(() => currentAgent.value?.id)
const agents = computed(() => agentStore.getAssignableAgents)
const agentsToMention = computed(() =>
  buildAgentsToMention(agents.value || [], currentAgent.value),
)
const isLoading = computed(
  () => !currentAgentId.value || !messages.value || !chat.value,
)
const participants = computed(() => (chat.value ? chat.value.participants : []))

const appendNewMessage = async (html: string, mentionedAgentIds: string[]) => {
  if (
    !chat.value ||
    !currentRoomId.value ||
    !currentAgentId.value ||
    !agents.value ||
    !messages.value
  )
    return
  const newMessage = await inceptionChatService.postText(
    chat.value.id,
    currentRoomId.value,
    currentAgentId.value,
    html,
    mentionedAgentIds,
  )
  if (!messages.value.find((message) => message.id === newMessage.id))
    messages.value.push(buildMessage(newMessage, agents.value))
}

const pollMessages = async () => {
  if (
    !chat.value ||
    !currentAgentId.value ||
    !agents.value ||
    !currentRoom.value
  )
    return
  const rawMessages = await inceptionChatService.loadAllMessages(
    chat.value.id,
    currentAgentId.value,
  )
  messages.value = buildMessages(rawMessages, agents.value)
}

watch(
  () => currentRoomId.value,
  async (roomId) => {
    if (!roomId) return

    const assignableAgentId = !!currentRoom.value?.assignedAgent
      ? currentRoom.value.assignedAgent.id
      : null
    const rawChat = await inceptionChatService.loadOrCreate(
      roomId,
      assignableAgentId,
    )
    chat.value = buildChat(rawChat, agents.value || [])
  },
  { immediate: true },
)
</script>

<template>
  <div class="pb-5 flex flex-1">
    <BaseLoader :is-loading="isLoading" />
    <InceptionChat
      v-if="
        chat &&
        currentAgentId &&
        currentAgent &&
        currentRoom &&
        messages &&
        agents
      "
      :agents="agents"
      :messages="messages"
      :agents-to-mention="agentsToMention"
      :current-agent-id="currentAgentId"
      :participants="participants"
      :read-only="!$canPostInceptionChatMessage(currentRoom)"
      @send-text="appendNewMessage"
    />
  </div>
</template>
