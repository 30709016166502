<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { UISavedReply } from '@/domains/saved-reply/ui-models'

const props = defineProps({
  node: {
    type: Object as PropType<UISavedReply>,
    required: true,
  },
  selectedSavedReply: {
    type: String,
    required: false,
  },
  isAlreadyUsed: {
    type: Boolean,
    required: true,
  },
})
const emits = defineEmits<{
  (
    e: 'pickSavedReply',
    savedReplyId: string,
    event: 'click' | 'mouseover',
  ): void
}>()

const isSelected = computed(() => props.selectedSavedReply === props.node.id)
</script>

<template>
  <button
    class="focus:outline-none text-left truncate w-full my-px hover:bg-default-100 px-5"
    :class="{
      'text-default underline': isSelected,
      'text-orange-500 underline': isSelected && isAlreadyUsed,
      'text-orange-500': !isSelected && isAlreadyUsed,
      'text-primary': !isSelected,
    }"
    @click="emits('pickSavedReply', node.id, 'click')"
    @mouseover="emits('pickSavedReply', node.id, 'mouseover')"
  >
    <font-awesome-icon
      v-if="node.image"
      class="focus:outline-none"
      :icon="['fas', 'image']"
    />
    {{ node.title }}
  </button>
</template>
