<script lang="ts" setup>
import { computed, PropType, watch } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { PanelPosition } from '@/domains/panel/store/types'
import { useKidStore, useMedicalReportStore } from 'Store/stores'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import Header from '@/views/layouts/panels/Header.vue'
import TitleAndSubTitle from '@/components/panel/shared/header/TitleAndSubTitle.vue'
import MedicalReportsHistoryComponent from '@/domains/medical-report/components/MedicalReportsHistory.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Right>,
    required: true,
  },
})

const { t } = useScopedI18n()
const kidStore = useKidStore()
const medicalReportStore = useMedicalReportStore()
const { loadPastMedicalReports } = useMedicalReportStore()

const kidProfile = computed(() => kidStore.getCurrentKidProfile)
const medicalReports = computed(() => medicalReportStore.getPastMedicalReports)
const thisYear = computed(() => new Date().getFullYear())

watch(
  () => kidProfile.value?.roomId,
  () => loadPastMedicalReports(),
  { immediate: true },
)
</script>

<template>
  <Container :panel-position="panelPosition">
    <Header
      v-if="medicalReports && kidProfile"
      :panel-position="panelPosition"
      :can-prev="false"
    >
      <template #default>
        <TitleAndSubTitle
          :title="t('title')"
          :sub-title="$fullname(kidProfile) + ' | ' + $kidAge(kidProfile)"
        />
      </template>
    </Header>
    <MedicalReportsHistoryComponent
      v-if="medicalReports && kidProfile"
      :this-year="thisYear"
      :medical-reports="medicalReports"
      :kid-profile="kidProfile"
    />

    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
