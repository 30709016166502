import { ActionTree } from './types'
import { changeLocale } from './change-locale'
import { setAuthMessage } from './set-auth-message'
import { setIsLoading } from './set-is-loading'
import { setIsPasswordRegistered } from './set-is-password-registered'

export const actions: ActionTree = {
  changeLocale,
  setAuthMessage,
  setIsLoading,
  setIsPasswordRegistered,
}
