<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useAgentStore } from 'Store/stores'

import Welcome from '@/components/panel/middle/Welcome.vue'
import MiddleContainer from '@/components/panel/middle/layout/MiddleContainer.vue'

const { currentAgent } = storeToRefs(useAgentStore())
</script>

<template>
  <MiddleContainer class="items-center justify-center bg-default-50">
    <Welcome v-if="currentAgent" :agent="currentAgent" />
  </MiddleContainer>
</template>
