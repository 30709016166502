import { Agent } from './agent'
import { Kid, KidParent } from './kid'

export enum PrescriptionType {
  Treatment = 'TREATMENT',
}

export enum PrescriptionStatus {
  Created = 'CREATED',
  Draft = 'DRAFT', // Status only known by the desk, not by the API
}

export type PrescriptionListFilter = {
  doctor?: Agent | null
  supervisor?: Agent | null
  kidId?: string | null
  startDate?: Date
  endDate?: Date
}

// === API ===

export type APIPrescription = {
  id: string
  type: PrescriptionType.Treatment
  status: PrescriptionStatus
  agentId: string
  doctorId: string
  userId: string
  kidId: string
  kidWeightInKg: number
  roomId: string
  instructions: string[]
  createdAt: string
  pdfFileInBase64: string
  prescriptedDrugs: APIPrescriptionDrug[]
  mobile: {
    instructions?: string[]
    prescriptionItems: PrescriptionItem[]
  }
}

export type APIPrescriptionPreview = {
  type: PrescriptionType.Treatment
  agentId: string
  createdAt: string
  pdfFileInBase64: string
  mobile: {
    instructions?: string[]
    prescriptionItems: PrescriptionItem[]
  }
}

export type APIPrescriptionDrug = {
  brandedDrug: {
    id: string
    name: string
    shortLabel: string
    drugs: APIPrescriptionDrugDrug[]
    molecules: APIPrescriptionMolecule[]
  }
  brandedDrugId?: string
  dosageId?: string
  dosage?: string
  drug?: string
  replaceable: boolean
  duration: string
}

export type APIPrescriptionMolecule = {
  id: string
  name: string
  shortLabel: string
}

export type APIPrescriptionDrugDrug = {
  id: string
  name: string
  shortLabel: string
  dosages: APIPrescriptionDrugDosage[]
}

export type APIPrescriptionDrugDosage = {
  id: string
  name: string
  shortLabel: string
}

export type APIPrescriptionForm = {
  type: PrescriptionType
  agentId: string
  doctorId: string
  userId: string
  kidId: string
  kidWeightInKg: number
  kidLastName?: string
  instructions?: string[]
  prescriptedDrugs: APIPrescriptionDrugForm[]
}

export type APIPrescriptionDrugForm = {
  brandedDrugId?: string
  dosageId?: string
  dosage?: string
  drug?: string
  replaceable: boolean
  duration: string
}

// === MODEL ===

export type Prescription = {
  id?: string
  roomId: string
  agentId: string
  doctorId: string
  userId: string
  kidId: string
  kidWeightInKg: number
  kidLastName?: string
  kid?: Kid // NOTE: filled in by the ChangePrescriptionListFilter action
  parent?: KidParent // NOTE: filled in by the ChangePrescriptionListFilter action
  hadKidLastName: boolean

  type: PrescriptionType
  status: PrescriptionStatus
  pdfFileInBase64?: string
  mobile?: {
    instructions?: string[]
    prescriptionItems: PrescriptionItem[]
  }
  createdAt?: Date
  instructions?: string[]
  prescriptedDrugs: PrescriptedDrug[]

  couldNotBePersisted?: boolean
}

export type PrescriptedDrug = {
  brandedDrug: PrescriptedBrandedDrug
  drug: PrescriptedDrugDrug
  dosage: PrescriptedDrugDosage
  duration: string
  replaceable: boolean
}

export type PrescriptedBrandedDrug = {
  id: string
  name: string
  shortLabel: string
  molecules: PrescriptionMolecule[]
}

export type PrescriptionMolecule = {
  id: string
  details?: string
  name: string
  shortLabel: string
}

export type PrescriptedDrugDrug = {
  id?: string
  name: string
  shortLabel: string
}

export type PrescriptedDrugDosage = {
  id?: string
  name: string
  shortLabel: string
}

// === PREVIEW ===

export type PrescriptionPreview = {
  type: PrescriptionType
  pdfFileInBase64: string
  agentId?: string
  mobile: {
    instructions?: string[]
    prescriptionItems: PrescriptionItem[]
  }
  createdAt?: Date
}

export type PrescriptionItem = {
  type: PrescriptionType
  title: string
  subtitle: string
  description: string
  card: string
  badge: string
  details: string
}

// === DRAFT ===

export type PrescriptionDraft = {
  roomId: string
  agentId: string
  doctorId: string
  userId: string
  kidId: string
  kidLastName?: string
  kidWeight: string
  instructions: string
  treatmentItems?: PrescriptionDraftDrug[]
  prescriptedDrugs?: PrescriptionDraftDrug[]
}

export type PrescriptionDraftDrug = {
  brandedDrug: {
    id: string
    name: string
    shortLabel: string
    molecules: PrescriptionDraftMolecule[]
  }
  drug: {
    id?: string
    name: string
    shortLabel: string
  }
  dosage: {
    id?: string
    name: string
    shortLabel: string
  }
  duration: string
  replaceable: boolean
}

export type PrescriptionDraftMolecule = {
  id: string
  details?: string
  mobileLabel?: string
  name: string
  shortLabel: string
}
