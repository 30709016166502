import { createPopper, Instance } from '@popperjs/core/lib/popper-lite'
import offset from '@popperjs/core/lib/modifiers/offset'
import arrow from '@popperjs/core/lib/modifiers/arrow'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'
import { DropdownPlacement as Placement } from '@/plugins/__types__/popper'
import { DirectiveBinding } from 'vue'
import { VTooltipReference, VTooltipBindingValue } from '../types'

const setTooltipInstance = (
  el: VTooltipReference,
  tooltipContainer: HTMLElement,
  modifiers: DirectiveBinding<VTooltipBindingValue>['modifiers'],
): Instance => {
  const placement: Placement =
    (Object.keys(modifiers)[0] as Placement) ?? 'auto'

  const customOffset = {
    ...offset,
    options: {
      offset: [0, 10],
    },
  }
  return createPopper(el, tooltipContainer, {
    placement,
    modifiers: [flip, preventOverflow, customOffset, arrow],
    strategy: 'absolute',
  })
}

export default setTooltipInstance
