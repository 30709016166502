import { Editor, JSONContent } from '@tiptap/vue-3'

export const replaceContent = (editor: Editor, text: string): boolean =>
  editor
    .chain()
    .setContent(`<p>${toHTML(text)}</p>`, false)
    .focus()
    .run()

export const toHTML = (text: string): string => text.replace(/\n/g, '<br>')

export const visitJSON = (
  node: JSONContent,
  callback: (node: JSONContent, isLeaf: boolean) => void,
) => {
  if (!node.content || node.content.length === 0) {
    callback(node, true)
  } else {
    if (node.type !== 'doc') callback(node, false)
    node.content?.forEach((child) => visitJSON(child, callback))
    if (node.type !== 'doc') callback(node, false)
  }
}
