<script lang="ts" setup>
import { PropType, ref, watch, computed } from 'vue'
import { isEmpty } from '@/utils/is-empty'
import { useUIStore } from 'Store/stores'
import useModal from '@/hooks/modal'
import useServices from '@/hooks/services'
import { buildUIKidMeasureSet } from '@/domains/kid/helpers/measure-helpers'
import { UIKidMeasureSet, UIKidProfile } from '@/domains/kid/ui-models'

import KidMeasuresModal from '@/domains/kid/components/kid-measures/KidMeasuresModal.vue'

const props = defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
})

const uiStore = useUIStore()
const services = useServices()
const { openModal, closeModal, isModalOpen } = useModal()

const locale = computed(() => uiStore.locale)
const isLoaded = computed(() => !!measureSet.value)
const hasData = computed(
  () => measureSet.value && !Object.values(measureSet.value).every(isEmpty),
)

const measureSet = ref<UIKidMeasureSet | undefined>()

watch(
  () => [props.kidProfile.id, locale.value],
  async () => {
    const kidId = props.kidProfile.id
    const rawMeasureSet = await services.kidMeasure.loadAll(kidId)
    if (!rawMeasureSet) return
    measureSet.value = buildUIKidMeasureSet(rawMeasureSet, props.kidProfile)
  },
  { immediate: true },
)
</script>

<template>
  <button
    v-if="isLoaded && hasData"
    class="focus:outline-none w-8 h-8 hover:bg-default-100 rounded relative"
    @click.stop="openModal"
  >
    <font-awesome-icon :icon="['fal', 'chart-line']" class="text-primary" />
    <div class="absolute top-1 right-1 h-2 w-2 bg-red-500 rounded-full"></div>
  </button>
  <KidMeasuresModal
    v-if="isModalOpen && measureSet"
    :kid-profile="kidProfile"
    :measure-set="measureSet"
    @close="closeModal"
  />
</template>
