import { ActionTree } from './types'
import { useAgentStore, useRoomStore } from 'Store/stores'
import Services from 'Services/services'

export const postImage: ActionTree['postImage'] = async function (image) {
  const currentAgent = useAgentStore().currentAgent
  const currentRoom = useRoomStore().currentRoom

  if (!currentAgent || !currentRoom) return

  if (image instanceof File)
    return await Services.getInstance().message.postImage(
      currentRoom.id,
      currentRoom.kid.id,
      currentAgent,
      image,
    )

  return await Services.getInstance().message.postImageUrl(
    currentRoom.id,
    currentAgent,
    image,
  )
}
