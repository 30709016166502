import { GetterTree } from './types'
import { BuiltInAgentGroups } from 'Models/agent-group'

export const getCurrentAgentGroupIds: GetterTree['getCurrentAgentGroupIds'] =
  function ({ currentAgent, agentGroups }) {
    if (!currentAgent || !agentGroups) return []

    let isAdmin = false
    const adminList: string[] = []
    const list: string[] = []

    agentGroups.forEach((group) => {
      const adminGroup = group.name === BuiltInAgentGroups.SystemAdministrators
      if (group.agentIds.indexOf(currentAgent.id) !== -1) {
        if (adminGroup) isAdmin = true
        list.push(group.id)
        return
      }
      if (group.addSystemAdministrators) adminList.push(group.id)
    })

    return isAdmin ? [...list, ...adminList] : list
  }
