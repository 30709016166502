import { GetterTree } from './types'
import { useAgentStore, useUIStore } from 'Store/stores'
import { buildUIMedicalReport } from './concerns/build-ui-medical-report'

export const getMedicalReport: GetterTree['getMedicalReport'] = function ({
  pastMedicalReports,
}) {
  return (medicalReportId) => {
    const agents = useAgentStore().agents

    if (
      !medicalReportId ||
      !pastMedicalReports ||
      !this.supervisions ||
      !this.causes ||
      !this.diagnosis ||
      !this.directions ||
      !agents
    )
      return null

    const medicalReport = pastMedicalReports.find(
      (pastMedicalReport) => pastMedicalReport.id === medicalReportId,
    )
    const locale = useUIStore().locale

    if (!medicalReport || !locale) return null

    return buildUIMedicalReport(
      medicalReport,
      medicalReport.prescription,
      locale,
      agents,
      this.causes,
      this.diagnosis,
      this.directions,
      this.supervisions,
    )
  }
}
