import { App } from '@vue/runtime-dom'
import { $kidAge, $shortKidAge, $mrhKidAge, $growthKidAge } from './kid-age'
import { $formatDate, $dateLocale, $dateFormat } from './format-date'
import { $formatTime } from './format-time'
import { $sanitizeHtml } from './sanitize-html'
import { $fullname } from './fullname'
import { $initials } from './initials'
import { $agentTitle } from './agent-title'
import { $agentFullname } from './agent-fullname'
import { $arrayIncludes } from './array-includes'
import { $doctors, $nurses } from './agents-profession'
import { $activeAgents, $inactiveAgents } from './agents-presence'
import { $htmlToMobileContent } from './html-to-mobile-content'
import { $month } from './month'
import { $timeAgo } from './time-ago'
import { $messageSenderTypeToAvatarType } from './message-sender-type-to-avatar-type'
import {
  $canUseNewFeatures,
  $canPostMessage,
  $canAssignRoom,
  $canSelfAssignRoom,
  $canCloseRoom,
  $canSnoozeRoom,
  $canCancelPendingAssignment,
  $canAssignMedicalReport,
  $canOpenPrescription,
  $canOpenPrescriptionInReadOnly,
  $canDeletePrescription,
  $canDisplayDoctorPickerOnPrescription,
  $canListPrescriptions,
  $canOpenAdminApp,
  $canSpellcheckMessage,
  $canPostInceptionChatMessage,
} from './can'
import { $isProduction } from './environment'
import { $mt } from './i18n-markdown'
import { $roomURL, $adminAppURL, $desideratasURL, $welcomeKitURL } from './urls'

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $isProduction: typeof $isProduction
    $fullname: typeof $fullname
    $agentTitle: typeof $agentTitle
    $agentFullname: typeof $agentFullname
    $arrayIncludes: typeof $arrayIncludes
    $doctors: typeof $doctors
    $nurses: typeof $nurses
    $activeAgents: typeof $activeAgents
    $inactiveAgents: typeof $inactiveAgents
    $initials: typeof $initials
    $kidAge: typeof $kidAge
    $shortKidAge: typeof $shortKidAge
    $mrhKidAge: typeof $mrhKidAge
    $growthKidAge: typeof $growthKidAge
    $messageSenderTypeToAvatarType: typeof $messageSenderTypeToAvatarType

    $roomURL: typeof $roomURL
    $adminAppURL: typeof $adminAppURL
    $desideratasURL: typeof $desideratasURL
    $welcomeKitURL: typeof $welcomeKitURL

    $timeAgo: typeof $timeAgo
    $formatDate: typeof $formatDate
    $formatTime: typeof $formatTime
    $dateLocale: typeof $dateLocale
    $dateFormat: typeof $dateFormat
    $month: typeof $month

    $sanitizeHtml: typeof $sanitizeHtml
    $htmlToMobileContent: typeof $htmlToMobileContent
    $mt: typeof $mt

    $canUseNewFeatures: typeof $canUseNewFeatures
    $canPostMessage: typeof $canPostMessage
    $canAssignRoom: typeof $canAssignRoom
    $canSelfAssignRoom: typeof $canSelfAssignRoom
    $canCloseRoom: typeof $canCloseRoom
    $canSnoozeRoom: typeof $canSnoozeRoom
    $canCancelPendingAssignment: typeof $canCancelPendingAssignment
    $canAssignMedicalReport: typeof $canAssignMedicalReport
    $canDisplayDoctorPickerOnPrescription: typeof $canDisplayDoctorPickerOnPrescription
    $canOpenPrescription: typeof $canOpenPrescription
    $canDeletePrescription: typeof $canDeletePrescription
    $canOpenPrescriptionInReadOnly: typeof $canOpenPrescriptionInReadOnly
    $canListPrescriptions: typeof $canListPrescriptions
    $canOpenAdminApp: typeof $canOpenAdminApp
    $canSpellcheckMessage: typeof $canSpellcheckMessage
    $canPostInceptionChatMessage: typeof $canPostInceptionChatMessage
  }
}

function install(app: App): void {
  app.config.globalProperties.$isProduction = $isProduction
  app.config.globalProperties.$kidAge = $kidAge
  app.config.globalProperties.$shortKidAge = $shortKidAge
  app.config.globalProperties.$mrhKidAge = $mrhKidAge
  app.config.globalProperties.$growthKidAge = $growthKidAge
  app.config.globalProperties.$timeAgo = $timeAgo
  app.config.globalProperties.$formatDate = $formatDate
  app.config.globalProperties.$formatTime = $formatTime
  app.config.globalProperties.$dateFormat = $dateFormat
  app.config.globalProperties.$dateLocale = $dateLocale
  app.config.globalProperties.$month = $month
  app.config.globalProperties.$sanitizeHtml = $sanitizeHtml
  app.config.globalProperties.$fullname = $fullname
  app.config.globalProperties.$initials = $initials
  app.config.globalProperties.$agentTitle = $agentTitle
  app.config.globalProperties.$agentFullname = $agentFullname
  app.config.globalProperties.$arrayIncludes = $arrayIncludes
  app.config.globalProperties.$doctors = $doctors
  app.config.globalProperties.$nurses = $nurses
  app.config.globalProperties.$activeAgents = $activeAgents
  app.config.globalProperties.$inactiveAgents = $inactiveAgents
  app.config.globalProperties.$htmlToMobileContent = $htmlToMobileContent
  app.config.globalProperties.$messageSenderTypeToAvatarType =
    $messageSenderTypeToAvatarType
  app.config.globalProperties.$canUseNewFeatures = $canUseNewFeatures
  app.config.globalProperties.$canPostMessage = $canPostMessage
  app.config.globalProperties.$canAssignRoom = $canAssignRoom
  app.config.globalProperties.$canSelfAssignRoom = $canSelfAssignRoom
  app.config.globalProperties.$canCloseRoom = $canCloseRoom
  app.config.globalProperties.$canSnoozeRoom = $canSnoozeRoom
  app.config.globalProperties.$canCancelPendingAssignment =
    $canCancelPendingAssignment
  app.config.globalProperties.$canAssignMedicalReport = $canAssignMedicalReport
  app.config.globalProperties.$canOpenPrescription = $canOpenPrescription
  app.config.globalProperties.$canDeletePrescription = $canDeletePrescription
  app.config.globalProperties.$canOpenPrescriptionInReadOnly =
    $canOpenPrescriptionInReadOnly
  app.config.globalProperties.$canDisplayDoctorPickerOnPrescription =
    $canDisplayDoctorPickerOnPrescription
  app.config.globalProperties.$canListPrescriptions = $canListPrescriptions
  app.config.globalProperties.$canOpenAdminApp = $canOpenAdminApp
  app.config.globalProperties.$canSpellcheckMessage = $canSpellcheckMessage
  app.config.globalProperties.$canPostInceptionChatMessage =
    $canPostInceptionChatMessage

  app.config.globalProperties.$mt = $mt
  app.config.globalProperties.$roomURL = $roomURL
  app.config.globalProperties.$adminAppURL = $adminAppURL
  app.config.globalProperties.$desideratasURL = $desideratasURL
  app.config.globalProperties.$welcomeKitURL = $welcomeKitURL
}

export default { install }
