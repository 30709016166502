<script lang="ts" setup>
import { PropType, computed, ref, Ref, watch } from 'vue'
import { UIPrescription } from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'
import Section from '@/components/panel/side/shared/Section.vue'

const props = defineProps({
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'updateKidWeight', weight: number): void
}>()

const { t } = useScopedI18n()

const kidWeight: Ref<number | null> = ref(null)

const hasKidWeight = computed(() => kidWeight.value && kidWeight.value > 0)

watch(
  () => props.prescription.roomId,
  () => {
    if (props.prescription.kidWeightInKg)
      kidWeight.value = props.prescription.kidWeightInKg
  },
  { immediate: true },
)

watch(
  () => kidWeight.value,
  (newWeight) => {
    if (newWeight !== null) emits('updateKidWeight', newWeight)
  },
)
</script>

<template>
  <Section class="flex flex-row items-center">
    <div class="flex flex-row items-center flex-1 space-x-1">
      <Title>
        {{ t('title') }}
      </Title>
      <font-awesome-icon
        v-if="!hasKidWeight"
        v-tooltip.auto="{
          content: t('tooltip'),
          contentClasses: ['max-w-xs', 'break-words'],
        }"
        :icon="['fal', 'info-circle']"
        class="text-default-light cursor-help focus:outline-none"
      />
    </div>
    <div class="flex items-center">
      <input
        v-model.number="kidWeight"
        class="w-20 rounded-l border border-r-0 border-gray-100 pl-3 py-2 outline-none"
        min="0"
        max="99"
        type="number"
        :placeholder="t('placeholder')"
      />
      <div
        class="border border-l-0 border-gray-100 pl-2 pr-3 py-2 font-bold rounded-r"
      >
        kg
      </div>
    </div>
  </Section>
</template>
