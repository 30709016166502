<script lang="ts" setup>
import { PropType } from 'vue'
import { EstimatedTimeSpent } from 'Models/medical-report'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'

defineProps({
  estimatedTimeSpent: {
    type: String as PropType<EstimatedTimeSpent>,
    required: false,
    default: EstimatedTimeSpent.Unknown,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <article class="flex flex-col">
    <Title>
      {{ t('title') }}
    </Title>
    <div>• {{ t(estimatedTimeSpent) }}</div>
  </article>
</template>
