<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'

defineProps({
  instructions: {
    type: String,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <article class="flex flex-col max-w-full w-full">
    <Title class="flex flex-1 mb-2">
      {{ t('title') }}
    </Title>
    <div class="italic bg-orange-50 p-2 rounded-sm whitespace-pre-wrap w-full">
      {{ $htmlToMobileContent(instructions) }}
    </div>
  </article>
</template>
