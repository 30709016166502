<script lang="ts" setup>
import { computed, PropType, toRefs } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore, useKidStore, useMedicalReportStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import useOpenRoom from '@/hooks/open-room'
import { UIMedicalReport } from '@/domains/medical-report/ui-models'
import useServices from '@/hooks/services'
import { useRoomComment } from '@/domains/room/controllers/room-comment'

import Spacer from '@/components/panel/side/shared/Spacer.vue'
import LinkToPanel from '@/components/panel/side/shared/block/LinkToPanel.vue'
import CommentListReadOnly from '@/domains/room/components/comment/CommentListReadOnly.vue'
import Instructions from '../Instructions.vue'
import GroupedMRLItems from '../GroupedMRLItems.vue'
import AssignedAgent from '../AssignedAgent.vue'
import PrescriptionDetails from '../PrescriptionDetails.vue'
import MedicalOpinionRequested from '../MedicalOpinionRequested.vue'
import EstimatedTimeSpent from '../EstimatedTimeSpent.vue'
import EstimatedComplexity from '../EstimatedComplexity.vue'
import MedicalReportAgentPicker from '../MedicalReportAgentPicker.vue'
import KidParent from '../KidParent.vue'

const props = defineProps({
  currentRoomId: {
    type: String,
    required: true,
  },
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
  isHistory: {
    type: Boolean,
    required: true,
  },
})
const { currentRoomId } = toRefs(props)

const agentStore = useAgentStore()
const { currentKidParent } = storeToRefs(useKidStore())
const { changeCurrentMedicalReport } = useMedicalReportStore()
const { goToRoom } = useOpenRoom()
const { t } = useScopedI18n()
const {
  uiComments,
  areCommentsLoading,
  hasError: noComment,
} = useRoomComment(useServices(), currentRoomId, agentStore.agents)

const currentAgent = computed(() => agentStore.currentAgent)
const medicalReportAssignableAgents = computed(
  () => agentStore.getMedicalReportAssignableAgents,
)
const kidParent = computed(
  () => props.medicalReport.kidParent ?? currentKidParent.value,
)
</script>

<template>
  <div class="flex flex-col px-3 space-y-1">
    <AssignedAgent
      v-if="medicalReport.agent"
      :is-history="isHistory"
      :medical-report="medicalReport"
    />
    <MedicalReportAgentPicker
      v-if="currentAgent && $canAssignMedicalReport() && !isHistory"
      :medical-report="medicalReport"
      :medical-report-assignable-agents="medicalReportAssignableAgents"
      :current-agent="currentAgent"
      @select-agent="(agent) => changeCurrentMedicalReport({ agent })"
    />

    <KidParent
      v-if="kidParent"
      :kid-parent="kidParent"
      :room-id="medicalReport.roomId"
      class="pb-2"
      @go-to-kid-parent-room-list="goToRoom"
    />

    <PrescriptionDetails
      v-if="medicalReport.hasPrescription && medicalReport.prescription"
      :room-id="medicalReport.roomId"
      :prescription="medicalReport.prescription"
      :class="
        !medicalReport.isEmpty &&
        !medicalReport.hasLetter &&
        'border-b border-default-100'
      "
    />

    <LinkToPanel
      v-if="medicalReport.hasLetter"
      class="cursor-text"
      :class="medicalReport.hasPrescription && 'border-t'"
    >
      {{ t('tooltipLetter') }}
      <template #right>
        <font-awesome-icon
          v-tooltip.auto="t('tooltipLetter')"
          :icon="['fal', 'envelope-open-text']"
          class="focus:outline-none text-default"
        />
      </template>
    </LinkToPanel>

    <div class="space-y-3 pt-1">
      <Instructions
        v-if="medicalReport.instructions"
        :instructions="medicalReport.instructions"
      />

      <CommentListReadOnly
        v-if="!noComment"
        :are-comments-loading="areCommentsLoading"
        :comments="uiComments"
      />

      <GroupedMRLItems
        :causes="medicalReport.causes"
        :diagnosis="medicalReport.diagnosis"
        :initial-directions="medicalReport.initialDirections"
        :directions="medicalReport.directions"
        :supervisions="medicalReport.supervisions"
      />

      <MedicalOpinionRequested
        v-if="
          medicalReport.medicalOpinionRequested &&
          !!medicalReport.requestedDoctor
        "
        :medical-opinion="medicalReport.medicalOpinionRequested"
        :requested-doctor="medicalReport.requestedDoctor"
      />
      <div class="hidden">
        <EstimatedTimeSpent
          v-if="medicalReport.estimatedTimeSpent"
          :estimated-time-spent="medicalReport.estimatedTimeSpent"
        />

        <EstimatedComplexity
          v-if="medicalReport.estimatedComplexity"
          :estimated-complexity="medicalReport.estimatedComplexity"
        />
      </div>
    </div>
    <Spacer v-if="!medicalReport.isEmpty" />
    <div v-else class="py-3 border-t border-default-100">
      <span class="bg-orange-50">{{ t('noMedicalReport') }}</span>
    </div>
  </div>
</template>
