<script lang="ts" setup>
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoomListMe } from '../../ui-models'

import BaseLoader from '@/components/base/BaseLoader.vue'
import RoomCardList from './shared/RoomCardList.vue'

const props = defineProps({
  roomList: {
    type: Object as PropType<UIRoomListMe>,
    required: true,
  },
})

const { tc } = useScopedI18n()

const isLoading = computed(
  () =>
    props.roomList?.unreadInceptionChatRooms === null &&
    props.roomList?.readInceptionChatRooms === null,
)
</script>

<template>
  <div class="flex flex-1 flex-col overflow-hidden">
    <BaseLoader v-if="isLoading" :is-loading="true" />

    <div
      v-else
      class="flex flex-col flex-grow overflow-y-scroll scrollbar overflow-x-hidden px-5 pt-3 pb-5 space-y-5"
    >
      <RoomCardList
        :title="tc('unread', roomList.unreadInceptionChatRooms?.length ?? 0)"
        :list="roomList.unreadInceptionChatRooms ?? []"
        color="purple"
        hash-url="#chat"
      />

      <RoomCardList
        :title="tc('read', roomList.readInceptionChatRooms?.length ?? 0)"
        :list="roomList.readInceptionChatRooms ?? []"
        color="white"
        hash-url="#chat"
      />
    </div>
  </div>
</template>
