const illustrationsCategories = [
  {
    name: 'teeth',
    illustrations: [
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fteeth%2Ftoothpaste-dosage.jpeg?alt=media&token=728a1658-59fe-4978-9b84-16f11bde02d9',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fteeth%2Fteething-symptoms.jpeg?alt=media&token=1b3b8d8d-b07b-4ea8-b648-12b8b5208600',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fteeth%2Fteething.jpeg?alt=media&token=cae9eb78-6173-40a6-a096-62077d3de5c9',
    ],
  },
  {
    name: 'growth',
    illustrations: [
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fgrowth%2Fweight-gain.jpeg?alt=media&token=42a9f65c-3b38-474b-9690-f1cf9fe16cdc',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fgrowth%2Fobject-permanence.jpeg?alt=media&token=a7452e67-886c-463c-a889-7bf4b04c0b84',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fgrowth%2Fpsychomotor-development.jpeg?alt=media&token=19c0efc5-85de-4bdd-8b6b-7b73db7048a6',
    ],
  },
  {
    name: 'feeding',
    illustrations: [
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Ffeeding%2Fbaby-dehydration.jpeg?alt=media&token=45934e35-f72e-419b-b946-fa99ea12fded',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Ffeeding%2Fchild-dehydration.jpeg?alt=media&token=ebbcf3ab-dc30-4a20-a8e1-a88f966b40df',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Ffeeding%2Fmilk-dosage.jpeg?alt=media&token=ffc98e16-fbfd-4b81-b70e-3f90a5d5d3b0',
    ],
  },
  {
    name: 'sleep',
    illustrations: [
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fsleep%2Fsleeping-train.jpeg?alt=media&token=cabf4a29-8767-4c9c-b052-33ed5a047875',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fsleep%2Fsleep-dressing.jpeg?alt=media&token=f40981c5-357c-4238-b76e-af9afc8a5a2f',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fsleep%2Fswaddling.jpeg?alt=media&token=49a2fa8f-844f-4489-93f9-73036ca0805c',
    ],
  },
  {
    name: 'digestive',
    illustrations: [
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fdigestive%2Fbristol-scale.jpeg?alt=media&token=295deb26-f399-4436-bdde-5ac17d650fb8',
      'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/illustrations%2Fdigestive%2Fbelly-massage.jpg?alt=media&token=5c9ae619-5385-4951-8365-c427ee78a051',
    ],
  },
]

export default illustrationsCategories
