import { IMessageService } from 'Services/__types__'
import { IFirebaseService } from 'External/firebase/types'
import {
  FBMessage,
  Message,
  MessageSenderType,
  MessageTypes,
} from 'Models/message'
import { convertFrom, convertTo } from 'Models/converters/message-converter'
import {
  CollectionReference,
  DocumentReference,
  orderBy,
  query,
  Query,
  where,
  UpdateData,
} from 'firebase/firestore'
import { htmlToMobileContent } from '@/utils/html-to-mobile-content'
import { Agent } from 'Models/agent'
import { FBRoom } from 'Models/room'

const SUPPORTED_IMAGE_MIME_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
]

export default class MessageService implements IMessageService {
  // dependencies
  firebaseService: IFirebaseService

  constructor(firebaseService: IFirebaseService) {
    this.firebaseService = firebaseService
  }

  async listenAll(
    roomId: string,
    onChange: (newMessages: Message[]) => void,
  ): Promise<void> {
    await this.firebaseService.listenCollection(
      `room-${roomId}-messages`,
      this.findAll(roomId),
      (newMessages: Message[]) => {
        onChange(newMessages)
      },
    )
  }

  unlistenAll(roomId: string): void {
    this.firebaseService.unlisten(`room-${roomId}-messages`)
  }

  loadAll(roomId: string): Promise<Message[] | undefined> {
    return this.firebaseService.loadDocuments(this.findAll(roomId))
  }

  postUnassignDeskMessage(roomId: string, agent: Agent): Promise<void> {
    const message = insertAgentName(
      '{agent} a repassé la conversation en non-assignée.',
      getDisplayName(agent, 'fr'),
    )
    return this.firebaseService.batchWrites((batch) => {
      const messageRef = this.getNewDocument(roomId)
      batch.set(messageRef, {
        id: 'NEW',
        content: message,
        createdAt: new Date('2021-05-31T07:53:53.000Z'), // NOTE: this date will be replaced on the server by the current timestamp
        type: MessageTypes.Text,
        sender: { type: MessageSenderType.Desk },
      })
    })
  }

  postText(roomId: string, agent: Agent, text: string): Promise<void> {
    return this.postMessage(roomId, agent, htmlToMobileContent(text))
  }

  async postImage(
    roomId: string,
    kidId: string,
    agent: Agent,
    image: File,
  ): Promise<void> {
    if (SUPPORTED_IMAGE_MIME_TYPES.indexOf(image.type) === -1)
      throw 'Unsupported image file type'
    const imageUrl = await this.firebaseService.uploadPicture(image, kidId)
    if (!imageUrl) return
    return this.postMessage(roomId, agent, imageUrl, MessageTypes.Image)
  }

  async postImageUrl(
    roomId: string,
    agent: Agent,
    imageUrl: string,
  ): Promise<void> {
    return this.postMessage(roomId, agent, imageUrl, MessageTypes.Image)
  }

  protected postMessage(
    roomId: string,
    agent: Agent,
    content: string,
    type: MessageTypes = MessageTypes.Text,
  ): Promise<void> {
    return this.firebaseService.batchWrites((batch) => {
      const message = this.buildMessage(agent, content, type)

      const messageRef = this.getNewDocument(roomId)
      batch.set(messageRef, message)

      const changes: UpdateData<FBRoom> = {
        hasUserRead: false,
        haveAgentsRead: [message.sender.id],
        lastMessage: convertTo({ ...message, id: messageRef.id }),
      }

      batch.update(this.getRoomDocument(roomId), changes)
    })
  }

  protected buildMessage(
    agent: Agent,
    content: string,
    type: MessageTypes,
  ): Message {
    return {
      id: 'NEW',
      sender: { ...agent, type: MessageSenderType.Agent },
      createdAt: new Date('2021-05-31T07:53:53.000Z'), // NOTE: this date will be replaced on the server by the current timestamp
      content,
      type,
    }
  }

  protected findAll(roomId: string): Query<Message> {
    return query(
      this.getCollection(roomId),
      where('type', 'in', ['text', 'image', 'video']),
      orderBy('createdAt'),
    )
  }

  protected getCollection(roomId: string): CollectionReference<Message> {
    return this.firebaseService.getCollection<FBMessage, Message>(
      ['rooms', roomId, 'messages'],
      convertFrom,
      convertTo,
    )
  }

  protected getNewDocument(roomId: string): DocumentReference<Message> {
    return this.firebaseService.getNewDocument<FBMessage, Message>(
      ['rooms', roomId, 'messages'],
      convertFrom,
      convertTo,
    )
  }

  protected getRoomDocument(roomId: string): DocumentReference {
    return this.firebaseService.getRawDocument('rooms', roomId)
  }
}

/** to be moved to backend, is currently copy / past from CFs */
const prefixedTitles = [
  'generalPractitioner',
  'pediatrician',
  'pediatricSurgeon',
  'psychiatrist',
  'childPsychiatrist',
  'obstetrician',
  'gynecologist',
]

export function getDisplayName(agent: Agent, locale: string): string {
  const { firstName, lastName } = agent
  if (isMD(agent)) {
    if (locale === 'fr') {
      return 'le Dr ' + firstName + ' ' + lastName
    }
    return firstName + ' ' + lastName + ', MD'
  }
  return firstName + ' ' + lastName
}

export function isMD({ titleKey }: Agent): boolean {
  const key = titleKey || ''
  return prefixedTitles.includes(key)
}

export function insertAgentName(
  message: string,
  agentDisplayName: string,
): string {
  const ret = message.replace('{agent}', agentDisplayName)

  return ret.charAt(0).toUpperCase() + ret.slice(1)
}
