<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import {
  useKidStore,
  usePanelStore,
  usePrescriptionStore,
  useUIStore,
} from 'Store/stores'
import { $kidAge } from '@/plugins/vue-helpers/kid-age'
import { getDurations } from '@/utils/get-durations'
import { getPackaging } from '@/utils/get-packaging'
import { UIDrugDuration } from '@/domains/prescription/ui-models'
import {
  PanelPosition,
  PanelsComponentNames,
} from '@/domains/panel/store/types'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import AddDuration from '@/components/panel/side/left/prescription/AddDuration.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
})

const kidStore = useKidStore()
const { nextPanel } = usePanelStore()
const panelStore = usePanelStore()
const { changeCurrentPrescription } = usePrescriptionStore()
const prescriptionStore = usePrescriptionStore()
const { locale } = storeToRefs(useUIStore())

const prescriptedDrug = computed(
  () => panelStore.getPanelData(PanelPosition.Left)?.prescriptedDrug,
)
const kidProfile = computed(() => kidStore.getCurrentKidProfile)
const prescription = computed(() => prescriptionStore.getCurrentPrescription)
const kidWeight = computed(() => prescription.value?.kidWeightInKg)
const kidAgeToday = computed(
  () => kidProfile.value && $kidAge(kidProfile.value),
)
const durations = computed(() => getDurations(locale.value))
const packaging = computed(() => getPackaging(locale.value))

const onSelect = (duration: UIDrugDuration): void => {
  if (!prescriptedDrug.value) return
  changeCurrentPrescription({
    newPrescriptedDrug: { ...prescriptedDrug.value, duration },
  })
  nextPanel(PanelsComponentNames.Prescription, {
    data: { prescriptedDrug: undefined },
  })
}
</script>

<template>
  <Container :panel-position="panelPosition">
    <AddDuration
      v-if="
        prescriptedDrug?.brandedDrug &&
        prescriptedDrug?.dosage &&
        kidWeight &&
        kidAgeToday
      "
      :kid-weight="kidWeight"
      :kid-age-today="kidAgeToday"
      :branded-drug="prescriptedDrug.brandedDrug"
      :drug-dosage="prescriptedDrug?.dosage"
      :durations="durations"
      :packaging="packaging"
      :panel-position="panelPosition"
      @select="onSelect"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
