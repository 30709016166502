<script lang="ts" setup>
import { PropType, computed } from 'vue'

import { UIMedicalReport } from '@/domains/medical-report/ui-models'

import ReportSummary from './history/ReportSummary.vue'
import ReportHeader from './history/ReportHeader.vue'

const props = defineProps({
  currentRoomId: {
    type: String,
    required: true,
  },
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
})

const isCurrentRoom = computed(
  () => props.medicalReport.roomId === props.currentRoomId,
)
</script>

<template>
  <section class="flex flex-col border rounded-lg border-default-200">
    <ReportHeader
      :is-current-room="isCurrentRoom"
      :room-created-at="medicalReport.roomCreatedAt"
      :room-id="medicalReport.roomId"
    />
    <ReportSummary
      :current-room-id="medicalReport.roomId"
      :medical-report="medicalReport"
      :is-history="true"
    />
  </section>
</template>
