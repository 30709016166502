import { GetterTree } from './types'
import { BuiltInAgentGroups } from 'Models/agent-group'
import { filterAgentsBy } from './concerns/filter-agents'

export const getPrescriptionInitiatorAgents: GetterTree['getPrescriptionInitiatorAgents'] =
  function ({ agents, agentGroups }) {
    return filterAgentsBy(
      BuiltInAgentGroups.PrescriptionInitiators,
      agents,
      agentGroups,
    )
  }
