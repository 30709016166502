<script lang="ts" setup>
import { AvailableLocales } from '@/locales'
import { computed, onMounted, PropType, ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

const props = defineProps({
  query: {
    type: String,
    required: true,
  },
  language: {
    type: String as PropType<AvailableLocales>,
    required: true,
  },
  numberOfResults: {
    type: Number,
    required: false,
    default: 0,
  },
  isTitleOnly: {
    type: Boolean,
    required: true,
    default: false,
  },
})
const emits = defineEmits<{
  (e: 'update:query', query: string): void
  (e: 'update:isTitleOnly', choice: boolean): void
  (e: 'switchLanguage'): void
}>()

const { t, tc } = useScopedI18n()

const search = ref<HTMLInputElement | null>(null)

const searchQuery = computed({
  get: () => props.query,
  set: (value) => emits('update:query', value),
})
const checked = computed({
  get: () => props.isTitleOnly,
  set: (value) => emits('update:isTitleOnly', value),
})

onMounted(() => search.value?.focus())
</script>

<template>
  <div
    class="flex flex-row items-center relative h-14 rounded border-t border-default-100 divide-x divide-default-100"
  >
    <button
      class="button"
      :class="query ? 'text-primary cursor-pointer' : 'text-default-300'"
      @click="emits('update:query', '')"
    >
      <font-awesome-icon
        v-if="query"
        v-tooltip.left="t('erase')"
        :icon="['fal', 'times-circle']"
        class="focus:outline-none text-lg"
      />
      <font-awesome-icon
        v-else
        :icon="['fal', 'search']"
        class="focus:outline-none text-default"
      />
    </button>

    <input
      v-model="checked"
      v-tooltip.top="t('filterByTitle')"
      name="isTitleOnly"
      class="cursor-pointer pr-1 transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed text-primary border-default-100 mr-3"
      type="checkbox"
    />

    <input
      ref="search"
      v-model="searchQuery"
      v-focus
      class="flex flex-1 h-14 px-3 bg-transparent focus:outline-none"
      :placeholder="t('search')"
    />

    <div
      v-if="query"
      class="hidden 2xl:flex items-center px-3 h-14 text-default-400 text-xs"
    >
      {{ tc('results', numberOfResults) }}
    </div>

    <button class="button text-primary" @click="emits('switchLanguage')">
      {{ language }}
    </button>
  </div>
</template>
<style scoped>
.button {
  @apply flex px-4 h-14 items-center justify-center focus:outline-none;
}
</style>
