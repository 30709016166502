<script lang="ts" setup>
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIPrescription } from '@/domains/prescription/ui-models'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { Agent } from 'Models/agent'
import { PanelPosition } from '@/domains/panel/store/types'

import Body from '@/components/panel/side/layout/Body.vue'
import Footer from '@/components/panel/side/layout/Footer.vue'
import HeaderTitle from '@/components/panel/shared/header/Title.vue'
import Header from '@/views/layouts/panels/Header.vue'
import LinkToPanel from '@/components/panel/side/shared/block/LinkToPanel.vue'
import KidLastNameInput from './new-prescription/KidLastNameInput.vue'
import KidWeightInput from './new-prescription/KidWeightInput.vue'
import DoctorInput from './new-prescription/DoctorInput.vue'
import InstructionsInput from './new-prescription/InstructionsInput.vue'
import PrescriptedDrugList from './new-prescription/PrescriptedDrugList.vue'
import ConfirmPrescriptionModalButton from '@/domains/prescription/components/ConfirmPrescriptionModalButton.vue'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  roomId: {
    type: String,
    required: true,
  },
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  prescriptionReferrerAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  canChangeDoctor: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{
  (e: 'newPrescriptedDrugStart'): void
}>()

const { t } = useScopedI18n()

const kidWeight = computed(() => props.prescription.kidWeightInKg)
const hasKidWeight = computed(() => kidWeight.value && kidWeight.value > 0)
</script>

<template>
  <Header :panel-position="panelPosition" :can-prev="false">
    <template #default>
      <HeaderTitle>{{ t('title') }}</HeaderTitle>
    </template>
  </Header>
  <Body class="flex-1" v-bind="$attrs">
    <KidLastNameInput
      v-if="kidProfile"
      v-bind="$attrs"
      :kid-profile="kidProfile"
      :prescription="prescription"
      class="pt-5 border-b border-default-100"
    />
    <KidWeightInput
      v-bind="$attrs"
      :prescription="prescription"
      class="pt-5 border-b border-default-100"
    />
    <DoctorInput
      v-if="canChangeDoctor"
      v-bind="$attrs"
      :kid-profile="kidProfile"
      :prescription="prescription"
      :current-agent="currentAgent"
      :prescription-referrer-agents="prescriptionReferrerAgents"
      class="pt-5 border-b border-default-100"
    />

    <PrescriptedDrugList
      v-if="prescription && hasKidWeight"
      v-bind="$attrs"
      :prescription="prescription"
      class="border-b border-default-100"
      @start-new="emits('newPrescriptedDrugStart')"
    />
    <LinkToPanel
      v-else-if="hasKidWeight && kidProfile.lastName"
      class="border-t text-primary"
      @click="emits('newPrescriptedDrugStart')"
    >
      {{ t('treatment.button') }}
      <template #right>
        <font-awesome-icon :icon="['far', 'plus']" class="focus:outline-none" />
      </template>
    </LinkToPanel>

    <InstructionsInput
      v-if="prescription && hasKidWeight"
      v-bind="$attrs"
      :prescription="prescription"
    />
  </Body>
  <Footer v-if="prescription.isValid">
    <ConfirmPrescriptionModalButton class="max-w-max">
      <template #default="{ isGeneratingPreview }">
        <span v-if="isGeneratingPreview">{{ t('previewing') }}</span>
        <span v-else>{{ t('preview') }}</span>
      </template>
    </ConfirmPrescriptionModalButton>
  </Footer>
</template>
