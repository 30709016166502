<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIMRLCategory, UIMRLItem } from '@/domains/medical-report/ui-models'
import { MRLStrictTopics } from 'Models/medical-report-library-item'

import List from '@/components/panel/side/shared/list/List.vue'
import Item from '@/components/panel/side/shared/list/Item.vue'
import CategoryHeader from './CategoryHeader.vue'
import ItemLabel from '@/components/panel/side/shared/list/ItemLabel.vue'

const props = defineProps({
  topic: {
    type: String as PropType<MRLStrictTopics>,
    required: true,
  },
  categories: {
    type: Array as PropType<UIMRLCategory[]>,
    required: false,
  },
})
const emits = defineEmits<{
  (e: 'remove', topic: MRLStrictTopics, item: UIMRLItem): void
}>()

const { t } = useScopedI18n()

const length = computed(() =>
  props.categories?.length === 1
    ? props.categories[0].items.length
    : props.categories?.length ?? 0,
)
</script>

<template>
  <div class="pb-3">
    <CategoryHeader
      :can-add-several="true"
      :topic="topic"
      :length="length"
      v-bind="$attrs"
    />

    <main v-if="categories && categories.length > 0" class="pl-2">
      <List
        v-for="(category, index) in categories"
        :key="`list-${topic}-${index}`"
        class="mt-1"
        :title="category.name"
      >
        <Item
          v-for="(item, itemIndex) in category.items"
          :key="`list-${topic}-${index}-${itemIndex}`"
          :item="item"
          @remove="emits('remove', topic, item)"
        >
          <ItemLabel :item="item" :dot="true" />
          <font-awesome-icon
            v-if="item.canBeHidden && item.hidden"
            v-tooltip.auto="t('privateTooltip')"
            :icon="['fal', 'eye-slash']"
            class="ml-2 mb-px text-xs focus:outline-none"
          />
        </Item>
      </List>
    </main>
  </div>
</template>
