import { RoomsGroupedByAgent } from '@/domains/room-list/ui-models'
import { Agent } from 'Models/agent'
import { RoomStatuses } from 'Models/room'

export const removeCurrentAgent = (
  roomsByAgent: RoomsGroupedByAgent[],
  currentAgent: Agent,
): RoomsGroupedByAgent[] =>
  roomsByAgent.filter(({ agent }) => agent.id !== currentAgent.id)

export const getTotalOfRooms = <T extends { counter: number }>(
  groupedRooms: T[],
): number => groupedRooms.reduce((total, { counter }) => total + counter, 0)

export const filterWithRoomsStatus = (
  roomsByAgent: RoomsGroupedByAgent[],
  roomsStatus: RoomStatuses,
): RoomsGroupedByAgent[] =>
  removeAgentsWithoutRooms(
    roomsByAgent.map((item) => {
      const newRooms = item.rooms.filter(
        (room) => room.status.type === roomsStatus,
      )
      return {
        agent: item.agent,
        rooms: newRooms,
        counter: newRooms.length,
      }
    }),
  )

const removeAgentsWithoutRooms = (
  roomsByAgent: RoomsGroupedByAgent[],
): RoomsGroupedByAgent[] => roomsByAgent.filter(({ rooms }) => rooms.length > 0)
