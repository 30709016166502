const CDN_BASE_URL = String(import.meta.env.VITE_CDN_BASE_URL)
const IMAGE_URL_REGEXP = new RegExp(
  `^https:\/\/firebasestorage.googleapis.com\/v0\/b\/[^\/]*.appspot.com\/(.*)`,
)

export function resizeImageUrl(
  imageUrl: string,
  geometry: { width?: number; height?: number },
) {
  const matches = imageUrl.match(IMAGE_URL_REGEXP)

  if (!matches) return imageUrl

  const newImageUrl = new URL(`${CDN_BASE_URL}/${matches[1]}`)

  if (geometry.width)
    newImageUrl.searchParams.append('width', geometry.width.toString())
  if (geometry.height)
    newImageUrl.searchParams.append('height', geometry.height.toString())

  return newImageUrl.toString()
}
