import { ComputedRef } from 'vue'
import { KidMeasureSet } from 'Models/kid'
import { preciseKidAgeInMonths } from '@/utils/kid-age-in-months'
import {
  GrowthSubDataset,
  GrowthTransformValue,
  MeasureType,
  GrowthPoint,
} from '../models'
import {
  TooltipFormatterContextObject,
  XrangePointOptionsObject,
} from 'highcharts'
import { $formatDate } from '@/plugins/vue-helpers/format-date'
import { $growthKidAge } from '@/plugins/vue-helpers/kid-age'
import { ScopedI18nTc } from '@/hooks/scoped-i18n'
import i18n from '@/plugins/vue-use/i18n'
import { UIKidMeasureSet, UIKidMeasureValue, UIKidProfile } from '../ui-models'

const MAX_MONTHS_BY_TYPE = {
  [MeasureType.Height]: 216,
  [MeasureType.Weight]: 216,
  [MeasureType.Head]: 60,
}

export const buildUIKidMeasureSet = (
  measureSet: KidMeasureSet,
  kid: UIKidProfile,
): UIKidMeasureSet => {
  return {
    height: buildSubDataset(
      measureSet,
      MeasureType.Height,
      kid,
      (value) => value * 100,
      'cm',
    ),
    weight: buildSubDataset(measureSet, MeasureType.Weight, kid),
    head: buildSubDataset(measureSet, MeasureType.Head, kid),
  }
}

const buildSubDataset = (
  measureSet: KidMeasureSet,
  name: MeasureType,
  kid: UIKidProfile,
  transform?: GrowthTransformValue,
  unit?: string,
): UIKidMeasureValue[] => {
  const maxMonth = MAX_MONTHS_BY_TYPE[name]
  // Typescript has a bug with nested scoped, this is why we need to pass by
  // another transform function.
  const safeTransform: GrowthTransformValue = transform || ((value) => value)
  return measureSet[name]
    .map((measure) => {
      const ageInMonths = preciseKidAgeInMonths(
        kid.birthDate,
        measure.createdAt,
      )
      if (ageInMonths > maxMonth) return undefined
      return {
        x: ageInMonths,
        y: safeTransform(measure.value),
        date: $formatDate(measure.createdAt),
        kidAge: $growthKidAge(kid, measure.createdAt),
        formattedValue: `${safeTransform(measure.value).toLocaleString(
          i18n.global.locale,
        )} ${unit || measure.unit}`,
      }
    })
    .filter((measure): measure is UIKidMeasureValue => measure !== undefined)
}

export const getTickInterval = (ageInMonths: number): number => {
  if (ageInMonths < 37) return 1
  if (ageInMonths < 121) return 6
  return 12
}

export const getMaxMonth = (ageInMonths: number): number => {
  return ageInMonths + (ageInMonths < 37 ? 2 : 12)
}

export const getChartRanges = (
  dataset: ComputedRef<GrowthSubDataset>,
  maxMonth: ComputedRef<number>,
  typeA: string,
  typeB: string,
  transform?: (y: number) => number,
): [number, number, number][] => {
  const range: [number, number, number][] = []
  const limit = dataset.value[typeA].x.length
  if (!transform) transform = (y) => y
  for (let i = 0; i < limit; i++) {
    const month = dataset.value[typeA].x[i]
    if (month > maxMonth.value) break
    range.push([
      month,
      transform(dataset.value[typeA].y[i]),
      transform(dataset.value[typeB].y[i]),
    ])
  }
  return range
}

export const getChartSeries = (
  dataset: ComputedRef<GrowthSubDataset>,
  maxMonth: ComputedRef<number>,
  type: string,
  transform?: GrowthTransformValue,
): XrangePointOptionsObject[] => {
  const series = []
  const limit = dataset.value[type].x.length
  if (!transform) transform = (y) => y
  for (let i = 0; i < limit; i++) {
    const month = dataset.value[type].x[i]
    if (month > maxMonth.value) break
    series.push({
      x: month,
      y: transform(dataset.value[type].y[i]),
    })
  }
  return series
}

export const formatTooltip = function (
  context: TooltipFormatterContextObject,
  tc: ScopedI18nTc,
) {
  if (context.series.name === 'Kid') {
    const point = context.point as GrowthPoint
    return [
      point.date,
      point.kidAge,
      '',
      `<span class="font-bold text-lg">${point.formattedValue}</span>`,
    ].join('<br/>')
  } else if (context.x && context.y) {
    return [
      context.series.name,
      formatXAxisLabel(context.x as number, tc),
      formatYAxisLabel(context.y, tc),
    ].join('<br/>')
  } else {
    return '🚨'
  }
}

export const formatXAxisLabel = (month: number, tc: ScopedI18nTc) => {
  if (month < 36) return tc('ageInMonths', month)
  return tc('ageInYears', month / 12)
}

export const formatYAxisLabel = (value: number, tc: ScopedI18nTc) => {
  return tc('size', Math.round(value * 100) / 100)
}
