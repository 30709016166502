// NOTE: this would have to be moved to the controllers folder of the Chat domain
import { throttle, debounce } from 'throttle-debounce'
import { Ref, ref } from 'vue'

const DEFAULT_THRESHOLD = 50 // in pixels

export default function useStickToLastMessage(
  body: Ref<HTMLElement>,
  endOfBody: Ref<HTMLElement>,
  options?: {
    threshold?: number
  },
): {
  scrollToLastMessage: () => void
  setup: () => void
  teardown: () => void
} {
  const stuck = ref(false)

  const scrollToLastMessage = throttle(
    25,
    () => {
      if (!endOfBody.value) return // the throttle can be triggered once the component has been unmounted
      endOfBody.value.scrollIntoView({ behavior: 'auto', block: 'end' })
      stuck.value = true
    },
    false,
  )

  const resizeObserver = new ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      if (stuck.value) scrollToLastMessage()
    })
  })

  const updateStuckOnScrolling = debounce(125, () => {
    const maxScrollTop = body.value.scrollHeight - body.value.offsetHeight
    const thresholdSpace = options?.threshold || DEFAULT_THRESHOLD
    stuck.value = body.value.scrollTop > maxScrollTop - thresholdSpace
  })

  const setup = () => {
    scrollToLastMessage()
    body.value.addEventListener('scroll', updateStuckOnScrolling)
    resizeObserver.observe(body.value)
  }

  const teardown = () => {
    body.value.removeEventListener('scroll', updateStuckOnScrolling)
    resizeObserver.unobserve(body.value)
  }

  return {
    scrollToLastMessage,
    setup,
    teardown,
  }
}
