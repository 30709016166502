<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { isEmpty } from '@/utils/is-empty'

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  body: {
    type: String,
    required: false,
  },
  color: {
    type: String as PropType<'default' | 'white' | 'orange' | 'purple'>,
    default: 'default',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
  titlePadding: {
    type: Boolean,
    default: true,
  },
  hideBody: {
    type: Boolean,
    default: false,
  },
  bodyPadding: {
    type: Boolean,
    default: true,
  },
})

const hasTitle = computed(() => !isEmpty(props.title) || !props.hideTitle)
const hasBody = computed(() => !isEmpty(props.body) || !props.hideBody)
</script>

<template>
  <div
    class="flex flex-col border rounded-lg"
    :class="{
      'border-default-200': color === 'default',
      'border-orange-200': color === 'orange',
      'border-purple-200': color === 'purple',
      'opacity-50': disabled,
    }"
  >
    <header
      v-if="hasTitle"
      class="rounded-t-lg flex flex-row items-center space-x-1"
      :class="{
        'p-3': titlePadding,
        'border-b': hasBody,
        'rounded-b-lg': !hasBody,
        'bg-default-50': color === 'default',
        'border-default-200': color === 'default' && hasBody,
        'bg-orange-100 text-orange-500': color === 'orange',
        'border-orange-200': color === 'orange' && hasBody,
        'bg-purple-100 text-purple-500': color === 'purple',
        'border-purple-200': color === 'purple' && hasBody,
      }"
    >
      <slot name="title">
        <div class="text-sm">{{ title }}</div>
      </slot>
    </header>
    <div
      v-if="hasBody"
      class="flex flex-col flex-grow overflow-y-auto scrollbar overflow-x-hidden h-full"
      :class="{
        'p-3': bodyPadding,
      }"
    >
      <slot name="body">
        {{ body }}
      </slot>
    </div>
  </div>
</template>
