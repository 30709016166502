<script lang="ts" setup>
import { ref, useSlots, watch } from 'vue'

const slots = useSlots()

const hasButtonSlot = !!slots.button

const props = defineProps({
  forceActive: {
    type: Boolean,
    required: false,
  },
  headerClasses: {
    type: String,
    default: '',
  },
  buttonClasses: {
    type: String,
    default: '',
  },
  chevronClasses: {
    type: String,
    default: '',
  },
  headerBottomBorder: {
    type: Boolean,
    default: false,
  },
  showBottomBorder: {
    type: Boolean,
    default: true,
  },
  thickBottomBorder: {
    type: Boolean,
    default: true,
  },
  showThickBottomBorder: {
    type: Boolean,
    default: true,
  },
  chevronFixedWidth: {
    type: Boolean,
    default: false,
  },
})

const isActive = ref<boolean>(props.forceActive)

const toggleActive = () => (isActive.value = !isActive.value)

watch(
  () => props.forceActive,
  (newForceActive) => (isActive.value = newForceActive),
  { immediate: true },
)
</script>

<template>
  <article
    :class="{
      'border-b-4': showThickBottomBorder && thickBottomBorder && isActive,
      'border-b': showBottomBorder && (!thickBottomBorder || !isActive),
    }"
  >
    <header
      class="flex items-center justify-between h-auto select-none px-5 py-3 cursor-pointer"
      :class="[headerClasses, { 'border-b': headerBottomBorder && isActive }]"
      @click.stop="toggleActive"
    >
      <div
        class="flex w-full focus:outline-none break-words text-left items-center"
        :class="[buttonClasses, { 'font-semibold': isActive }]"
      >
        <slot name="title"><!-- Title --></slot>
      </div>
      <div v-if="hasButtonSlot" class="flex justify-end">
        <slot name="button"><!-- Button --></slot>
      </div>
      <div class="flex items-center pl-2 focus:outline-none">
        <div class="text-sm" :class="chevronClasses">
          <font-awesome-icon
            v-if="isActive"
            :icon="['far', 'chevron-down']"
            :class="{ 'fa-fw': chevronFixedWidth }"
          />
          <font-awesome-icon
            v-else
            :icon="['far', 'chevron-right']"
            :class="{ 'fa-fw': chevronFixedWidth }"
          />
        </div>
      </div>
    </header>
    <main :class="{ hidden: !isActive }">
      <slot><!-- Default --></slot>
    </main>
  </article>
</template>
