<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

defineProps({
  clearLabel: {
    type: String,
    required: false,
    default: 'clearLabel',
  },
})

const emits = defineEmits<{
  (e: 'clear'): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <button
    type="button"
    class="flex flex-row p-4 text-left space-x-4 items-center hover:bg-blue-50 w-full"
    @click="emits('clear')"
  >
    <div class="flex items-center justify-center w-6 ml-px">
      <font-awesome-icon
        :icon="['fal', 'user-md']"
        class="text-xl focus:outline-none text-primary"
      />
    </div>
    <div class="text-sm text-primary">
      {{ t(clearLabel) }}
    </div>
  </button>
</template>
