<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import {
  useSavedReplyStore,
  useUIStore,
  useKidStore,
  useRoomStore,
} from 'Store/stores'
import { useQuickReply } from '@/hooks/quick-reply'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'editText', text: string): void
}>()

const { currentKid } = storeToRefs(useKidStore())
const { locale } = storeToRefs(useUIStore())
const { currentRoom } = storeToRefs(useRoomStore())
const savedReplyStore = useSavedReplyStore()
const { search } = useQuickReply()

const quickReplies = computed(() => {
  if (!currentRoom.value || !currentKid.value) return null

  return savedReplyStore.getQuickReplies(
    locale.value,
    currentRoom.value.parent.firstName,
    currentKid.value.firstName,
    currentKid.value.sex,
  )
})
const filteredQuickReplies = computed(() =>
  search(quickReplies.value, props.text),
)
const hasQuickReplies = computed(() => filteredQuickReplies.value.length > 0)
</script>

<template>
  <div
    v-if="text && hasQuickReplies"
    class="max-w-full overflow-hidden mx-2 py-1 border-b border-default-50 text-sm space-x-2 divide-x divide-default-100"
  >
    <div
      v-for="(reply, index) in filteredQuickReplies"
      :key="index"
      v-tooltip.top="{
        content: `${$htmlToMobileContent(reply.content)}`,
        contentClasses: [
          'max-w-[20rem]',
          'max-h-[16rem]',
          'text-ellipsis',
          'overflow-hidden',
        ],
      }"
      class="inline-flex items-center max-w-[8rem] py-1 pl-2 text-default-light"
    >
      <div
        class="text-primary cursor-pointer max-w-fit truncate"
        @click="emits('editText', $htmlToMobileContent(reply.content) ?? '')"
      >
        {{ $htmlToMobileContent(reply.content) }}
      </div>
    </div>
  </div>
</template>
