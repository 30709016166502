import { panelStore } from '@/domains/panel/store'
import { PanelStore } from '@/domains/panel/store/types'
import { savedReplyStore } from '@/domains/saved-reply/store'
import { SavedReplyStore } from '@/domains/saved-reply/store/types'
import { prescriptionStore } from '@/domains/prescription/store'
import { PrescriptionStore } from '@/domains/prescription/store/types'
import { inceptionChatStore } from '@/domains/inception-chat/store'
import { InceptionChatStore } from '@/domains/inception-chat/store/types'
import { roomListStore } from '@/domains/room-list/store'
import { RoomListStore } from '@/domains/room-list/store/types'
import { ConfigStore } from '@/domains/config/store/types'
import { configStore } from '@/domains/config/store'
import { PlanningStore } from '@/domains/planning/store/types'
import { planningStore } from '@/domains/planning/store'
import { CoreChatStore } from '@/domains/core-chat/store/types'
import { coreChatStore } from '@/domains/core-chat/store'
import { SystemStore } from '@/domains/system/store/types'
import { systemStore } from '@/domains/system/store'
import { UIStore } from '@/domains/ui/store/types'
import { uiStore } from '@/domains/ui/store'
import { agentStore } from '@/domains/agent/store'
import { AgentStore } from '@/domains/agent/store/types'
import { kidStore } from '@/domains/kid/store'
import { KidStore } from '@/domains/kid/store/types'
import { medicalReportStore } from '@/domains/medical-report/store'
import { MedicalReportStore } from '@/domains/medical-report/store/types'
import { roomStore } from '@/domains/room/store'
import { RoomStore } from '@/domains/room/store/types'
import { authStore } from '@/domains/auth/store'
import { AuthStore } from '@/domains/auth/store/types'

export const usePanelStore: () => PanelStore = () => panelStore()
export const usePrescriptionStore: () => PrescriptionStore = () =>
  prescriptionStore()
export const useInceptionChatStore: () => InceptionChatStore = () =>
  inceptionChatStore()
export const useRoomListStore: () => RoomListStore = () => roomListStore()
export const useSavedReplyStore: () => SavedReplyStore = () => savedReplyStore()
export const useConfigStore: () => ConfigStore = () => configStore()
export const usePlanningStore: () => PlanningStore = () => planningStore()
export const useCoreChatStore: () => CoreChatStore = () => coreChatStore()
export const useSystemStore: () => SystemStore = () => systemStore()
export const useUIStore: () => UIStore = () => uiStore()
export const useAgentStore: () => AgentStore = () => agentStore()
export const useKidStore: () => KidStore = () => kidStore()
export const useMedicalReportStore: () => MedicalReportStore = () =>
  medicalReportStore()
export const useRoomStore: () => RoomStore = () => roomStore()
export const useAuthStore: () => AuthStore = () => authStore()
