import { ActionTree } from './types'
import { setSavedReplies } from './set-saved-replies'
import { setUsedSavedReplyIds } from './set-used-saved-reply-ids'
import { markSavedReplyAsUsed } from './mark-saved-reply-as-used'
import { toggleSavedReplyPicker } from './toggle-saved-reply-picker'

export const actions: ActionTree = {
  setSavedReplies,
  setUsedSavedReplyIds,
  markSavedReplyAsUsed,
  toggleSavedReplyPicker,
}
