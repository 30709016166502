<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

import IllustrationModalImage from './IllustrationModalImage.vue'
import BaseImageOverlay from '@/components/base/BaseImageOverlay.vue'
import BaseModalLoader from '@/components/base/base-modal/BaseModalLoader.vue'

const props = defineProps({
  categories: {
    type: Object as PropType<Array<{ name: string; illustrations: string[] }>>,
    required: true,
  },
  selectedIllustration: {
    type: String,
  },
})

const { t } = useScopedI18n()

const loadedIllustrations = ref<string[]>([])

const allIllustrations = computed(() => {
  let illustrations: string[] = []
  props.categories.forEach(
    (category) =>
      (illustrations = illustrations.concat(category.illustrations)),
  )
  return illustrations
})

const allIllustrationsLoaded = computed(
  () =>
    allIllustrations.value.length === loadedIllustrations.value.length &&
    allIllustrations.value.every((illustration) =>
      loadedIllustrations.value.includes(illustration),
    ),
)

const onIllustrationLoaded = (url: string) =>
  loadedIllustrations.value.push(url)
</script>

<template>
  <BaseModalLoader v-if="!allIllustrationsLoaded" :with-header="false" />

  <div class="flex overflow-hidden">
    <div
      class="flex flex-row flex-wrap overflow-y-auto scrollbar overflow-x-hidden"
      :class="{ 'w-8/12': selectedIllustration }"
    >
      <div
        v-for="category in categories"
        :key="category.name"
        class="flex flex-col"
      >
        <div
          v-if="allIllustrationsLoaded"
          class="font-semibold text-default-light uppercase px-4 pt-2"
        >
          {{ t(category.name) }}
        </div>
        <div class="flex flex-row items-start flex-wrap px-2 mt-2">
          <IllustrationModalImage
            v-for="illustration in category.illustrations"
            :key="illustration"
            :src="illustration"
            :selected-illustration="selectedIllustration"
            :illustrations-loaded="allIllustrationsLoaded"
            v-bind="$attrs"
            @illustration-loaded="onIllustrationLoaded"
          />
        </div>
      </div>
    </div>
    <div
      v-if="selectedIllustration"
      class="p-4 border-l border-default-100 w-4/12 overflow-y-auto scrollbar overflow-x-hidden"
    >
      <BaseImageOverlay :src="selectedIllustration">
        <img
          class="rounded-lg w-full cursor-zoom-in"
          :src="selectedIllustration"
          alt=""
        />
      </BaseImageOverlay>
    </div>
  </div>
</template>
