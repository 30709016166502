<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoom } from '../../ui-models'

defineProps({
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
  hover: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div
    class="flex items-center flex-row pb-1 border-b-2 transition-colors duration-500"
    :class="[
      isActive
        ? 'border-primary'
        : hover
        ? 'border-default-300'
        : 'border-transparent',
    ]"
  >
    <div
      v-tooltip.bottom="
        t(`messageHasRead.${room.hasParentRead}`, {
          name: $fullname(room.parent),
        })
      "
      class="flex pr-2"
    >
      <font-awesome-icon
        :class="room.hasParentRead ? 'text-green-500' : 'text-default-300'"
        :icon="['fal', 'check']"
      />
    </div>
    <div class="flex text-sm font-semibold capitalize truncate">
      {{ room.parent.firstName }}
    </div>
    <div
      v-tooltip.right="
        t('tooltipKidAge', {
          kidAge: $kidAge(room.kid),
          birthdate: $formatDate(room.kid.birthDate),
        })
      "
      class="flex text-sm capitalize truncate"
      :class="isActive ? 'border-primary' : 'border-transparent'"
    >
      &nbsp;&nbsp;<span class="text-default-300">|</span>&nbsp;&nbsp;{{
        room.kid.firstName
      }}
    </div>
  </div>
</template>
