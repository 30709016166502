import { Drug } from 'Models/drug'
import { UIDrugDosage } from '@/domains/prescription/ui-models'

export const buildUIDrugDosages = (drugs: Drug[]): UIDrugDosage[] => {
  const list: UIDrugDosage[] = []

  drugs.forEach((drug) => {
    drug.dosages.forEach((dosage) => {
      list.push({
        drugId: drug.id,
        drugName: drug.shortLabel,
        dosageId: dosage.id,
        dosageName: dosage.shortLabel,
      })
    })
  })

  return list
}
