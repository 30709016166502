<script lang="ts" setup>
import { PropType } from 'vue'
import { UIMedicalReport } from '@/domains/medical-report/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseAvatar from '@/components/base/BaseAvatar.vue'
import AssignmentHistory from './AssignmentHistory.vue'

defineProps({
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
  isHistory: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div class="flex flex-row items-center space-x-2">
    <div>
      {{
        t('title', { date: $formatDate(medicalReport.roomCreatedAt, 'long') })
      }}
    </div>
    <div
      v-if="medicalReport.agent"
      v-tooltip.bottom="$agentTitle(medicalReport.agent)"
      class="flex flex-row items-center space-x-1"
    >
      <BaseAvatar
        type="agent"
        :first-name="medicalReport.agent.firstName"
        :last-name="medicalReport.agent.lastName"
        :profile-pic="medicalReport.agent.profilePic"
        :size="1"
      />
      <div class="text-sm">
        {{ $fullname(medicalReport.agent) }}
      </div>
    </div>
    <AssignmentHistory v-if="!isHistory" :room-id="medicalReport.roomId" />
  </div>
</template>
