import { Ref, nextTick } from 'vue'

let hasBeenScrolled = false

export const useScrollToRoom = (
  room: Ref<HTMLElement>,
): {
  scrollToRoom: () => void
} => {
  const scrollToRoom = async () => {
    if (!hasBeenScrolled) {
      await nextTick()
      room.value.scrollIntoView({ behavior: 'auto', block: 'center' })
      hasBeenScrolled = true
    }
  }

  return {
    scrollToRoom,
  }
}
