import { GetterTree } from './types'

import { getAllTodayRooms } from './get-all-today-rooms'
import { getClosedRooms } from './get-closed-rooms'
import { getCurrentRoom } from './get-current-room'
import { getFavoriteRooms } from './get-favorite-rooms'

export const getters: GetterTree = {
  getAllTodayRooms,
  getClosedRooms,
  getCurrentRoom,
  getFavoriteRooms,
}
