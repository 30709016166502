import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes/index'
import Services from 'Services/services'
import buildMiddlewares from './middlewares/build-middlewares'
import { useAuthStore, useUIStore } from 'Store/stores'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

const middlewares = buildMiddlewares(Services.getInstance().room)

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  if (!authStore.isAuthenticated) await authStore.localSignIn()

  // NOTE: naive implementation of a stack of middlewares
  // TODO: use a plugin for chaining middlewares
  await middlewares.requireAuthentication(
    to,
    from,
    next,
    async () => await middlewares.roomDispatcher(to, from, next),
  )
  useUIStore().setIsLoading(false)
})

export default router
