import { computed, ComputedRef, Ref } from 'vue'
import { Agent } from 'Models/agent'
import { PlanningAgentsTypes } from 'Models/planning'
import { UIPlanningSlot } from '@/domains/planning/ui-models'

export default function displayAvatars(
  type: Ref<PlanningAgentsTypes>,
  planningSlot: Ref<UIPlanningSlot>,
): {
  counterMinimum: number
  agentsCount: ComputedRef<number>
  agentsOverMax: ComputedRef<boolean>
  displayedAgents: ComputedRef<Agent[]>
} {
  const counterMinimum = 5
  const MAX_DISPLAYED_DOCTORS = 5
  const MAX_DISPLAYED_IDE = 10

  const maxAvatars = computed(() => {
    switch (type.value) {
      case PlanningAgentsTypes.Doctors:
        return MAX_DISPLAYED_DOCTORS
      case PlanningAgentsTypes.Nurses:
        return MAX_DISPLAYED_IDE
      default:
        return MAX_DISPLAYED_IDE
    }
  })
  const agents = computed(() => planningSlot.value[type.value])
  const agentsCount = computed(() => agents.value.length)
  const agentsOverMax = computed(() => agentsCount.value > maxAvatars.value)
  const displayedAgents = computed(() =>
    agents.value.slice(0, maxAvatars.value),
  )

  return {
    counterMinimum,
    agentsCount,
    agentsOverMax,
    displayedAgents,
  }
}
