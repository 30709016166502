import HoneybadgerVue from '@honeybadger-io/vue'
import Honeybadger from '@honeybadger-io/js'
import { App } from '@vue/runtime-dom'
import revision from '@/assets/revision.json'

let instance: Honeybadger | undefined

export default {
  install: (app: App): void => {
    if (
      import.meta.env.VITE_ENV !== 'production' &&
      import.meta.env.VITE_ENV !== 'development'
    )
      return

    const config = {
      apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
      environment: import.meta.env.VITE_HONEYBADGER_ENV,
      revision,
    }

    app.use(HoneybadgerVue, config)

    instance = app.$honeybadger
  },
}

export { instance }
