/* eslint-disable import/no-duplicates */
import i18nPlugin from '@/plugins/vue-use/i18n'
import format from 'date-fns/format'
import { fr, enGB } from 'date-fns/locale'

const DateLocales: Record<string, Locale> = {
  fr,
  en: enGB,
}

export const dateLocale = (locale: string): Locale => DateLocales[locale]

export const dateFormat = (
  formatName: DateFormatName,
  locale: string,
): string => {
  const i18n = i18nPlugin.global
  return i18n.t(`date.${formatName}`, locale)
}

export const formatDate = (
  date: Date,
  formatName: DateFormatName,
  locale: string,
): string =>
  format(date, dateFormat(formatName, locale), {
    locale: dateLocale(locale),
  })

export const toAPIDate = (date: Date): string => format(date, 'yyyy-MM-dd')

export type DateFormatName = 'shortTime' | 'short' | 'long' | 'full'
