<script lang="ts" setup>
import { computed, ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

import RichTextEditor from '@/components/base/BaseRichTextEditor.vue'
import IconButton from '@/components/base/BaseButtonIcon.vue'

const props = defineProps({
  comment: {
    type: String,
    required: true,
  },
  isCommentPresent: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'update:comment', comment: string): void
  (e: 'submitComment'): void
}>()

const { t } = useScopedI18n()

const localComment = computed({
  get: () => props.comment,
  set: (newComment) => emits('update:comment', newComment),
})

const hasFocus = ref<boolean>(false)
</script>

<template>
  <div
    class="border rounded transition-all duration-300"
    :class="{
      'border-primary shadow-xl': hasFocus,
      'border-default-200': !hasFocus,
    }"
  >
    <RichTextEditor
      ref="editor"
      v-model="localComment"
      class="w-full p-0"
      :placeholder="t('placeholder')"
      :auto-focus="false"
      @send-text="emits('submitComment')"
      @focus="hasFocus = true"
      @blur="hasFocus = false"
    />

    <hr class="mx-4 mt-2 h-px bg-default-100 border-none" />

    <div class="py-0 flex items-center justify-end">
      <IconButton
        class="flex items-center space-x-2 transition-all duration-300"
        :disabled="!isCommentPresent"
        :class="
          isCommentPresent ? 'text-primary' : 'text-default-200 cursor-default'
        "
        @click="emits('submitComment')"
      >
        <div class="text-sm">{{ t('send') }}</div>
        <font-awesome-icon :icon="['fas', 'paper-plane']" class="text-sm" />
      </IconButton>
    </div>
  </div>
</template>
