import Services from 'Services/services'
import { instance as honeybadger } from '@/plugins/vue-use/honeybadger'
import { listenAll } from './listen'
import { AuthInfo } from 'Models/credentials'
import {
  useAgentStore,
  useAuthStore,
  usePlanningStore,
  useUIStore,
} from 'Store/stores'

export async function startup(
  authInfo: AuthInfo,
  services: Services,
): Promise<void> {
  useUIStore().setIsLoading(true)

  const agent = await services.agent.load(authInfo.agentId)

  // Before going further, give a little bit of context to Honeybadger to track potential JS exceptions
  honeybadger?.setContext({
    agent,
    user_id: agent?.id,
    user_email: agent?.email,
  })

  useAgentStore().setCurrentAgent(agent ?? null)

  await listenAll(services)

  usePlanningStore().setTodayPlanning()

  useUIStore().setAuthMessage(null)

  useAuthStore().isAuthenticated = true
}
