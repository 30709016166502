<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIKidProfile } from '@/domains/kid/ui-models'

import TextSmall from '@/components/panel/side/shared/TextSmall.vue'
import KidFamily from './KidFamily.vue'

defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>
<template>
  <div class="w-60 p-5 space-y-3">
    <div v-if="kidProfile.birthDate">
      <TextSmall>{{ t('birthdateTitle') }}</TextSmall>
      {{ $formatDate(kidProfile.birthDate, 'long') }}
    </div>
    <div>
      <TextSmall>{{ t('sexTitle') }}</TextSmall>
      {{ t(`sex.${kidProfile.sex}`) }}
    </div>
    <div v-if="kidProfile.weight && kidProfile.weightDateTaken">
      <TextSmall>{{ t('weightTitle') }}</TextSmall>
      {{ kidProfile.weight }}
      <TextSmall>
        {{
          t('weightDateTaken', {
            date: $formatDate(kidProfile.weightDateTaken, 'long'),
          })
        }}
      </TextSmall>
    </div>
    <div class="border-t border-default-100 pt-3">
      <KidFamily :kid-profile="kidProfile" />
    </div>
  </div>
</template>
