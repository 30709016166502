import { GetterTree } from './types'

export const getPrescriptionListFilter: GetterTree['getPrescriptionListFilter'] =
  function ({ prescriptionListFilter }) {
    if (!prescriptionListFilter) return null
    const { doctor, supervisor, startDate, endDate } = prescriptionListFilter
    return {
      doctor: doctor || null,
      supervisor: supervisor || null,
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
    }
  }
