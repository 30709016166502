import { MiddlewareTree } from './type'
import buildRequireAuthenticationMiddleware from './require-authentication'
import buildRoomDispatcherMiddleware from './room-dispatcher'
import { IRoomService } from 'Services/__types__'

export default function buildMiddlewares(
  roomService: IRoomService,
): MiddlewareTree {
  return {
    ...buildRequireAuthenticationMiddleware(),
    ...buildRoomDispatcherMiddleware(roomService),
  }
}
