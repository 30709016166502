import { BuiltInAgentGroups } from 'Models/agent-group'
import { Room } from 'Models/room'
import BasePolicy from './base'

export default class MessagePolicy extends BasePolicy {
  post(room: Room): boolean {
    return room.status.type !== 'closed' && this.isRoomAssignedToAgent(room)
  }

  spellcheck(): boolean {
    return this.isMemberOf(BuiltInAgentGroups.SpellingBees)
  }

  protected isRoomAssignedToAgent({ assignedAgent }: Room): boolean {
    if (!assignedAgent) return false
    return (
      assignedAgent.assignmentAccepted && assignedAgent.id === this.agent.id
    )
  }
}
