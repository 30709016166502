import { BrandedDrug } from 'Models/branded-drug'
import { UIBrandedDrug } from '@/domains/prescription/ui-models'

export const buildUIBrandedDrugs = (
  brandedDrugs: BrandedDrug[],
): UIBrandedDrug[] => {
  return brandedDrugs
    .map(buildUIBrandedDrug)
    .sort((drugA, drugB) => drugA.name.localeCompare(drugB.name))
}

const buildUIBrandedDrug = (brandedDrug: BrandedDrug): UIBrandedDrug => {
  const molecules: string[] = []

  brandedDrug.molecules.forEach((molecule) => molecules.push(molecule.name))

  return {
    id: brandedDrug.id,
    name: brandedDrug.name,
    molecules,
  }
}
