import {
  formatDate,
  dateFormat,
  dateLocale,
  DateFormatName,
} from '@/utils/format-date'
import i18nPlugin from '@/plugins/vue-use/i18n'

export const $formatDate = (date: Date, formatName?: DateFormatName): string =>
  formatDate(date, formatName || 'short', i18nPlugin.global.locale)

export const $dateFormat = (formatName?: DateFormatName): string =>
  dateFormat(formatName || 'short', i18nPlugin.global.locale)

export const $dateLocale = (): Locale => dateLocale(i18nPlugin.global.locale)
