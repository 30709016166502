import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import { Component } from 'vue'

export default (component: Component): Node =>
  Node.create({
    name: 'vueComponent',
    group: 'inline',
    inline: true,
    content: 'text*',
    renderHTML({ HTMLAttributes }) {
      return ['vue-component', mergeAttributes(HTMLAttributes), 0]
    },
    addOptions() {
      return {}
    },
    addAttributes() {
      return {
        hint: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-hint'),
          renderHTML: (attributes) => ({ 'data-hint': attributes.hint }),
        },
        suggestions: {
          default: [],
          parseHTML: (element) => element.getAttribute('data-suggestions'),
          renderHTML: (attributes) => ({
            'data-suggestions': attributes.suggestions,
          }),
        },
      }
    },
    parseHTML() {
      return [
        {
          tag: 'vue-component',
        },
      ]
    },
    addNodeView() {
      return VueNodeViewRenderer(component)
    },
  })
