<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { MessageSenderType } from 'Models/message'
import { UIRoomComment } from '@/domains/room/ui-models'

import BaseLoader from '@/components/base/BaseLoader.vue'
import Title from '@/components/panel/side/shared/Title.vue'
import CoreChatMessageHeader from '@/domains/core-chat/components/CoreChatMessageHeader.vue'
import BaseMessageText from '@/domains/core-chat/components/base/BaseMessageText.vue'

defineProps({
  areCommentsLoading: {
    type: Boolean,
    required: true,
  },
  comments: {
    type: Array as PropType<UIRoomComment[] | null>,
    required: false,
  },
})

const { tc } = useScopedI18n()
</script>

<template>
  <BaseLoader v-if="areCommentsLoading" class="pt-5" :is-loading="true" />
  <article
    v-else-if="comments && comments.length > 0"
    class="flex flex-col pb-2"
  >
    <Title class="flex flex-1 mb-2">
      {{ tc('title', comments.length) }}
    </Title>
    <CoreChatMessageHeader
      v-for="{ id, agent, commentedAt, comment } in comments"
      :key="id"
      :agent="agent"
      :sender="{ ...agent, type: MessageSenderType.Agent }"
      :posted-at="commentedAt"
    >
      <BaseMessageText :text="comment" />
    </CoreChatMessageHeader>
  </article>
</template>
