<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { usePanelStore } from 'Store/stores'
import { TPanelsComponentNames } from '@/domains/panel/store/types'

const props = defineProps({
  content: {
    type: String,
    required: true,
    default: 'Action',
  },
  componentName: {
    type: String as PropType<TPanelsComponentNames>,
    required: true,
  },
})

const { togglePanel, isPanelActive } = usePanelStore()
const isActive = computed(() => isPanelActive(props.componentName))
</script>

<template>
  <button
    class="px-5 focus:outline-none"
    :class="{ 'underline underline-offset-4': isActive }"
    @click="togglePanel(componentName)"
  >
    {{ content }}
  </button>
</template>
