import { Editor, JSONContent } from '@tiptap/vue-3'
import { SpellcheckError } from 'External/api/types'
import { toHTML, visitJSON } from './helpers'

export default (
  editor: Editor,
  text: string,
  errors: SpellcheckError[],
): void => {
  const segments = []
  const anchor = getTextAnchor(editor.getJSON(), editor.state.selection.anchor)
  let lastOffset = -1
  let focusPosition = anchor + 1 // (count the first paragraph opening tag)

  errors.forEach(({ diagnosis, proposals, location: { offset, length } }) => {
    const suggestions = (proposals || []).join('~')

    segments.push(
      toHTML(text.slice(lastOffset === -1 ? 0 : lastOffset, offset)),
    )
    segments.push(
      `<vue-component data-hint="${diagnosis}" data-suggestions="${suggestions}">${toHTML(
        text.slice(offset, offset + length),
      )}</vue-component>`,
    )
    lastOffset = offset + length

    if (offset < anchor) {
      focusPosition = focusPosition + 1 + (anchor > lastOffset ? 1 : 0)
    }
  })
  if (lastOffset < text.length)
    segments.push(toHTML(text.slice(lastOffset === -1 ? 0 : lastOffset)))

  const highlightedMessage = `<p>${segments.join('')}</p>`

  editor.commands.setContent(highlightedMessage, false)
  editor.commands.focus(focusPosition)
}

export const getTextAnchor = (content: JSONContent, anchor: number): number => {
  let currentAnchor = 0,
    textAnchor = 0

  visitJSON(content, (node, isLeaf) => {
    if (!isLeaf) {
      currentAnchor += 1
      return
    }

    if (currentAnchor >= anchor) return

    const length = node.type === 'hardBreak' ? 1 : node.text?.length || 0
    textAnchor +=
      currentAnchor + length < anchor ? length : anchor - currentAnchor
    currentAnchor += length
  })

  return textAnchor
}
