<script lang="ts" setup>
import { computed, watch, PropType } from 'vue'
import { useKidStore, usePanelStore, usePrescriptionStore } from 'Store/stores'
import { $kidAge } from '@/plugins/vue-helpers/kid-age'
import { UIBrandedDrug, UIDrugDosage } from '@/domains/prescription/ui-models'
import {
  PanelPosition,
  PanelsComponentNames,
} from '@/domains/panel/store/types'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import AddDosage from '@/components/panel/side/left/prescription/AddDosage.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
})

const kidStore = useKidStore()
const panelStore = usePanelStore()
const prescriptionStore = usePrescriptionStore()

const prescriptedDrug = computed(
  () => panelStore.getPanelData(PanelPosition.Left)?.prescriptedDrug,
)
const dosages = computed(() => prescriptionStore.getDrugDosages)
const kidProfile = computed(() => kidStore.getCurrentKidProfile)
const prescription = computed(() => prescriptionStore.getCurrentPrescription)
const kidWeight = computed(() => prescription.value?.kidWeightInKg)
const kidAgeToday = computed(
  () => kidProfile.value && $kidAge(kidProfile.value),
)

const onSelect = (dosage: UIDrugDosage): void =>
  panelStore.nextPanel(PanelsComponentNames.AddDuration, {
    data: {
      prescriptedDrug: { ...prescriptedDrug.value, dosage },
    },
  })

watch(
  () => prescriptedDrug.value?.brandedDrug,
  async (brandedDrug: UIBrandedDrug | undefined) => {
    if (brandedDrug) await prescriptionStore.setDrugs(brandedDrug.id)
  },
  { immediate: true },
)
</script>

<template>
  <Container :panel-position="panelPosition">
    <AddDosage
      v-if="prescriptedDrug?.brandedDrug && dosages && kidAgeToday && kidWeight"
      :kid-weight="kidWeight"
      :kid-age-today="kidAgeToday"
      :branded-drug="prescriptedDrug.brandedDrug"
      :dosages="dosages"
      :panel-position="panelPosition"
      @select="onSelect"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
