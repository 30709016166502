import { computed, ComputedRef, Ref } from 'vue'
import { Agent } from 'Models/agent'
import { PlanningAgentsTypes } from 'Models/planning'
import { UIPlanningSlot } from '@/domains/planning/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

type Tab = {
  agentsType: PlanningAgentsTypes
  title: string
  agents: Agent[]
}

export default function buildAgentsTabs(
  planningSlot: Ref<UIPlanningSlot>,
  emits: {
    (e: 'selectAgents', agentsType: PlanningAgentsTypes): void
  },
): {
  tabs: ComputedRef<Tab[]>
  onSelectAgents: (agentsType: PlanningAgentsTypes) => void
} {
  const { t } = useScopedI18n()

  const tabs = computed(() => [
    {
      agentsType: PlanningAgentsTypes.Doctors,
      title: t('doctors'),
      agents: planningSlot.value.doctors,
    },
    {
      agentsType: PlanningAgentsTypes.Nurses,
      title: t('nurses'),
      agents: planningSlot.value.nurses,
    },
  ])

  const onSelectAgents = (agentsType: PlanningAgentsTypes) =>
    emits('selectAgents', agentsType)

  return { tabs, onSelectAgents }
}
