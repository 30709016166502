<script lang="ts" setup>
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIPrescription } from '@/domains/prescription/ui-models'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { PanelPosition } from '@/domains/panel/store/types'

import Body from '@/components/panel/side/layout/Body.vue'
import Footer from '@/components/panel/side/layout/Footer.vue'
import HeaderTitle from '@/components/panel/shared/header/Title.vue'
import Header from '@/views/layouts/panels/Header.vue'
import Title from '@/components/panel/side/shared/Title.vue'
import Section from '@/components/panel/side/shared/Section.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'
import PrescriptedDrug from './PrescriptedDrug.vue'
import ReadOnlyPrescriptionModalButton from '@/domains/prescription/components/ReadOnlyPrescriptionModalButton.vue'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
})

const { t, tc } = useScopedI18n()

const kidWeight = computed(() => props.prescription.kidWeightInKg)
const instructions = computed(() => props.prescription.instructions.join('\n'))
</script>

<template>
  <Header :panel-position="panelPosition" :can-prev="false">
    <template #default>
      <HeaderTitle>
        {{ t('title') }}
        <font-awesome-icon :icon="['fal', 'check']" class="text-green-500" />
      </HeaderTitle>
    </template>
  </Header>
  <Body class="flex-1">
    <Spacer />
    <Section
      v-if="prescription.createdAt"
      class="flex flex-row items-center border-default-100"
    >
      <div class="flex flex-row items-center flex-1 space-x-1">
        <Title>
          {{ t('issuedAt') }}
        </Title>
      </div>
      <div class="flex items-center font-semibold">
        {{ $formatDate(prescription.createdAt, 'long') }}
      </div>
    </Section>
    <Section class="flex flex-row items-center">
      <div class="flex flex-row items-center flex-1 space-x-1">
        <Title>
          {{ t('kidWeightInput.title') }}
        </Title>
        <font-awesome-icon
          v-if="!prescription.createdAt"
          v-tooltip.auto="{
            content: t('kidWeightInput.tooltip'),
            contentClasses: ['max-w-xs', 'break-words'],
          }"
          :icon="['fal', 'info-circle']"
          class="text-default-light cursor-help focus:outline-none"
        />
      </div>
      <div class="flex items-center">
        <input
          class="w-20 rounded-l border border-r-0 border-gray-100 pl-3 py-2 outline-none"
          type="number"
          :value="kidWeight"
          :readonly="true"
          :placeholder="t('kidWeightInput.placeholder')"
        />
        <div
          class="border border-l-0 border-gray-100 pr-3 py-2 font-bold rounded-r"
        >
          kg
        </div>
      </div>
    </Section>

    <div v-if="prescription" class="border-t border-b border-default-100">
      <div class="flex items-center mb-2 px-5 pt-5 pb-3">
        <Title class="flex flex-1">
          {{ t('prescriptedDrugList.title') }}
        </Title>
      </div>
      <PrescriptedDrug
        v-for="(drug, index) in prescription.items"
        :key="index"
        :prescripted-drug="drug"
        :index="index"
      />
    </div>
    <Section v-if="prescription && prescription.hasInstructions">
      <Spacer />
      <Title>
        {{ tc('instructionsInput.title', prescription.instructions.length) }}
      </Title>
      <Spacer />
      <div
        v-if="prescription.createdAt"
        class="bg-purple-50 p-2 rounded-sm whitespace-pre-wrap"
      >
        {{ instructions }}
      </div>
      <textarea
        v-else
        :value="instructions"
        class="bg-default-50 rounded-sm w-full scrollbar px-2 py-2 h-32 placeholder-default-400 border border-default-100 focus:border-primary focus:outline-none focus:shadow-lg"
        readonly
        :placeholder="t('instructionsInput.placeholder')"
      />
    </Section>
  </Body>
  <div
    class="flex items-center justify-center py-2 text-sm bg-green-100 border-t border-green-200"
  >
    <font-awesome-icon :icon="['fal', 'check']" class="text-green-500 mr-2" />
    {{ t('sent') }}
  </div>
  <Footer>
    <ReadOnlyPrescriptionModalButton
      v-if="prescription.id"
      :prescription-id="prescription.id"
      class="px-5 py-2 rounded focus:outline-none focus:shadow-outline bg-primary text-white disabled:opacity-50"
    >
      {{ t('preview') }}
    </ReadOnlyPrescriptionModalButton>
  </Footer>
</template>
