import { GetterTree } from './types'
import { buildUIRooms } from '@/domains/room-list/store/concerns/build-ui-room'
import { useAgentStore, useRoomListStore } from 'Store/stores'
import Services from 'Services/services'

export const getAllTodayRooms: GetterTree['getAllTodayRooms'] = function ({
  closedRooms,
}) {
  const rooms = useRoomListStore().rooms
  const currentAgent = useAgentStore().currentAgent

  if (!rooms || !closedRooms || !currentAgent) return []

  return buildUIRooms(
    Services.getInstance().room.filterAllTodayRooms([...rooms, ...closedRooms]),
    currentAgent.id,
  )
}
