import { App } from '@vue/runtime-dom'
import EmojiPickerComponent from './component'

export default {
  install: (app: App): void => {
    app.component('EmojiPicker', EmojiPickerComponent)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    EmojiPicker: typeof EmojiPickerComponent
  }
}
