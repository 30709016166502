import { BuiltInAgentGroups } from 'Models/agent-group'
import BasePolicy from './base'

export default class AdminAppPolicy extends BasePolicy {
  show(): boolean {
    return (
      this.isMemberOf(BuiltInAgentGroups.SystemAdministrators) &&
      this.isMemberOf(BuiltInAgentGroups.BetaTesters)
    )
  }
}
