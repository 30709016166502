import { processNewRooms } from '@/domains/room-list/store/actions/concerns/process-new-rooms'
import { deproxifyArrayOfObjects } from '@/utils/array/deproxify-array-of-objects'
import { TimerIntervals } from 'External/timer/types'
import { MRLTopics } from 'Models/medical-report-library-item'
import Services from 'Services/services'
import {
  useAgentStore,
  useConfigStore,
  useInceptionChatStore,
  useMedicalReportStore,
  useRoomListStore,
  useRoomStore,
  useSavedReplyStore,
} from 'Store/stores'

export const listenAll = (services: Services): void => {
  // NOTE: Firebase will fire a first request when listening a collection so no need to
  // manually force load the documents.

  listenDefault(services)
  listenMedicalReportLibrary(services)
  listenRelatedToCurrentAgent(services)
  listenSnoozedRooms(services)
  listenInceptionChatRooms(services)

  useRoomStore().listenCurrentRoomWorkspace()
}

const listenDefault = (services: Services): void => {
  services.config.listenDynamic((newDynamicConfig) => {
    useConfigStore().setDynamicConfig(newDynamicConfig)
  })

  services.config.listenDesk((newDeskConfig) => {
    useConfigStore().setDeskConfig(newDeskConfig)
  })

  services.agentGroup.listenAll((newAgentGroups) => {
    useAgentStore().setAgentGroups(newAgentGroups)
  })

  services.agent.listenAll((newAgents) => {
    useAgentStore().setAgents(newAgents)
  })

  services.room.listenAllOpen((newRooms, changeType) => {
    const currentAgent = useAgentStore().currentAgent
    if (!changeType) throw 'Missing listener changeType'
    if (!currentAgent) throw 'Missing currentAgent'

    const processedRooms = processNewRooms(
      newRooms,
      useRoomListStore().rooms,
      changeType,
      currentAgent.id,
    )

    if (!processedRooms) return

    useRoomListStore().setRooms(processedRooms)
  })

  services.savedReply.listenAll((newSavedReplies) => {
    useSavedReplyStore().setSavedReplies(newSavedReplies)
  })
}

const listenInceptionChatRooms = (services: Services): void => {
  services.timer.runEvery(
    'inceptionChatRooms',
    async () => {
      const currentAgent = useAgentStore().currentAgent
      if (!currentAgent) throw 'Missing currentAgent'
      const chats = await services.inceptionChat.loadAllOpen(currentAgent.id)
      useInceptionChatStore().setInceptionChats(chats)
      const roomIds = chats.map((chat) => chat.roomId)
      useRoomListStore().setInceptionChatRooms(roomIds, chats, currentAgent)
    },
    3000,
  )
}

const listenMedicalReportLibrary = (services: Services): void => {
  // Antecedents
  services.medicalReportLibrary.listenAll(
    MRLTopics.Antecedents,
    (antecedents) => (useMedicalReportStore().antecedents = antecedents),
  )

  // Causes
  services.medicalReportLibrary.listenAll(
    MRLTopics.Causes,
    (causes) => (useMedicalReportStore().causes = causes),
  )

  // Diagnosis
  services.medicalReportLibrary.listenAll(
    MRLTopics.Diagnosis,
    (diagnosis) => (useMedicalReportStore().diagnosis = diagnosis),
  )

  // Directions
  services.medicalReportLibrary.listenAll(
    MRLTopics.Directions,
    (directions) => (useMedicalReportStore().directions = directions),
  )

  // Supervisions
  services.medicalReportLibrary.listenAll(
    MRLTopics.Supervisions,
    (supervisions) => (useMedicalReportStore().supervisions = supervisions),
  )
}

const listenRelatedToCurrentAgent = (services: Services): void => {
  const currentAgent = useAgentStore().currentAgent

  if (!currentAgent) return

  services.room.listenAllFavorites(
    currentAgent.id,
    (newFavoritesRooms) => (useRoomStore().favoritesRooms = newFavoritesRooms),
  )
}

// FIXME: perhaps we should have a dedicated listener and state props for snoozed rooms instead of this hack
const listenSnoozedRooms = (services: Services): void => {
  // Force the snoozedRooms getters to be refreshed since the list
  // depends on the current time.
  services.timer.runEvery(
    'rooms',
    () => {
      const existingRooms = useRoomListStore().rooms
      if (!existingRooms) return
      const newRooms = deproxifyArrayOfObjects(existingRooms) // Required
      useRoomListStore().setRooms(newRooms)
    },
    TimerIntervals.TwoHours,
  )
}
