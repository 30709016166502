import { ActionTree } from './types'
import { useKidStore, useRoomStore } from 'Store/stores'
import Services from 'Services/services'

export const loadPastMedicalReports: ActionTree['loadPastMedicalReports'] =
  async function () {
    const currentKid = useKidStore().currentKid
    const currentRoomId = useRoomStore().currentRoomId

    if (!currentKid || !currentRoomId) return

    this.pastMedicalReports = null

    const medicalReports =
      await Services.getInstance().pastMedicalReport.loadAll(
        currentKid.id,
        undefined,
        currentRoomId,
      )

    if (medicalReports) this.pastMedicalReports = medicalReports
  }
