import { getAgentsInPlanningSlot } from '@/utils/agent/get-agents-in-planning-slot'
import { getAgentsWithRoom } from '@/utils/agent/get-agents-with-room'
import { removeCurrentPlanningSlotAgents } from '@/utils/agent/remove-current-planning-slot-agents'
import { Agent } from 'Models/agent'
import { Planning } from 'Models/planning'
import { UITeamStatus } from '@/domains/agent/ui-models'

export const buildUITeamStatus = (
  agents: Agent[],
  planning: Planning,
): UITeamStatus => {
  const currentSlotAgents = getAgentsInPlanningSlot(agents, planning)

  const nextSlotAgents = getAgentsInPlanningSlot(agents, planning, 1)

  // we remove agents already in the currentSlot to avoid duplication
  const assignedAgents = removeCurrentPlanningSlotAgents(
    getAgentsWithRoom(agents),
    currentSlotAgents,
  )

  return {
    currentSlot: getDoctorsAndNurses(currentSlotAgents),
    assigned: getDoctorsAndNurses(assignedAgents),
    nextSlot: getDoctorsAndNurses(nextSlotAgents),
  }
}

const getDoctorsAndNurses = (
  agents: Agent[],
): { doctors: Agent[]; nurses: Agent[] } => {
  const doctors = agents.filter(({ isDoctor }) => isDoctor)
  const nurses = agents.filter(({ isDoctor }) => !isDoctor)

  return { doctors, nurses }
}
