/**
 * Function to sort alphabetically an array of objects by some specific key.
 * source: https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 * @param {String} property Key of the object to sort.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function dynamicCompare<T extends Record<string, any>>(
  property: string,
): (a: T, b: T) => number {
  let sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.charAt(1)
  }

  return function (a, b): number {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property])
    } else {
      return a[property].localeCompare(b[property])
    }
  }
}
