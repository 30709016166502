<script lang="ts" setup>
import { ref, watch } from 'vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
    default: true,
  },
  firstName: {
    type: String,
  },
  classes: {
    type: String,
  },
})

const isLoading = ref(props.isLoading)

watch(
  () => props.isLoading,
  (newIsLoading) => {
    isLoading.value = newIsLoading
  },
)
</script>

<template>
  <div class="relative">
    <div
      v-if="isLoading"
      :class="classes"
      class="absolute flex items-center justify-center text-xs font-bold text-default-light bg-default-200 rounded-full h-full w-full"
    >
      {{ firstName?.charAt(0) }}
    </div>
    <slot />
  </div>
</template>
