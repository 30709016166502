import { ComputedRef, Ref, watch, computed } from 'vue'
import { usePanelStore, usePrescriptionStore } from 'Store/stores'

import { PanelsComponentNames } from '@/domains/panel/store/types'
import { PrescriptionStatus } from 'Models/prescription'
import {
  UIPrescription,
  UIPrescriptionPreview,
} from '@/domains/prescription/ui-models'
import { useAsyncRunner } from '@/hooks/async-runner'

export default function confirmPrescription(closeModal: () => void): {
  prescription: ComputedRef<UIPrescription | null>
  prescriptionPreview: ComputedRef<UIPrescriptionPreview | null>
  confirm: () => Promise<void | null>
  cancel: () => void
  isConfirming: Ref<boolean>
} {
  const { nextPanel } = usePanelStore()
  const { completeCurrentPrescription } = usePrescriptionStore()
  const prescriptionStore = usePrescriptionStore()
  const { isRunning: isConfirming, run } = useAsyncRunner()

  const prescription = computed(() => prescriptionStore.getCurrentPrescription)
  const prescriptionPreview = computed(
    () => prescriptionStore.getCurrentPrescriptionPreview,
  )

  const confirm = async () => await run(() => completeCurrentPrescription())

  const cancel = () => closeModal()

  watch(
    () => prescription.value?.status,
    (newValue) => {
      if (newValue === PrescriptionStatus.Created) {
        nextPanel(PanelsComponentNames.Prescription)
        closeModal()
      }
    },
  )

  return {
    prescription,
    prescriptionPreview,
    confirm,
    cancel,
    isConfirming,
  }
}
