import { ActionTree } from './types'
import { useRoomStore } from 'Store/stores'
import Services from 'Services/services'

export const setUsedSavedReplyIds: ActionTree['setUsedSavedReplyIds'] =
  async function () {
    const currentRoom = useRoomStore().currentRoom

    if (!currentRoom) return

    const usedSavedReplyIds =
      await Services.getInstance().room.getUsedSavedReplies(
        currentRoom.parent.id,
      )

    this.usedSavedReplyIds = usedSavedReplyIds
  }
