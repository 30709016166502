import { isEmpty } from '@/utils/is-empty'
import { DocumentData } from 'firebase/firestore'
import { Agent } from 'Models/agent'
import { KidGender } from 'Models/kid'
import {
  Room,
  FBRoom,
  RoomLastMessage,
  RoomKid,
  RoomStatus,
  RoomParent,
  RoomStatuses,
} from 'Models/room'

export const convertFrom = (fbRoom: FBRoom): Room => {
  const { id, haveAgentsRead, hasUserRead } = fbRoom

  const lastParentThreadStartedAt = fbRoom.lastUserThreadStartedAt?.toDate()
  const r: Room = {
    id,
    haveAgentsRead: haveAgentsRead || [],
    hasParentRead: hasUserRead,
    assignedAgent: fbRoom.agent,
    assignedBy: fbRoom.assignedBy,
    isUnassigned: !fbRoom.agent,
    isBeingAssigned: !!fbRoom.agent && !fbRoom.agent.assignmentAccepted,
    isClosed: fbRoom.status.type === RoomStatuses.Closed,
    parent: convertRoomUserFrom(fbRoom),
    createdAt: fbRoom.createdAt.toDate(),
    lastMessage: convertLastMessageFrom(fbRoom),
    kid: convertKidFrom(fbRoom),
    status: convertStatusFrom(fbRoom),
    prescriptions: fbRoom.prescriptions ?? [],
    hasPrescription: !isEmpty(fbRoom.prescriptions),
    hasLetter: fbRoom.hasLetter,
    agentShiftIsEnding: fbRoom.agentShiftIsEnding,
    markedByAgentsAsFavorite: fbRoom.markedByAgentsAsFavorite ?? [],
    lastParentThreadStartedAt,
    lastHumanSender: fbRoom.lastHumanSender,
  }
  if (fbRoom.payment) {
    r.payment = fbRoom.payment
  }
  return r
}

export const convertFromCurrentRoom = (fbRoom: FBRoom): Room => {
  return {
    ...convertFrom(fbRoom),
    isParentTyping: fbRoom.isUserTyping,
  }
}

const convertLastMessageFrom = (fbRoom: FBRoom): RoomLastMessage | null => {
  if (!fbRoom.lastMessage) return null
  const { id, type, content, sender } = fbRoom.lastMessage
  return {
    id,
    type,
    content,
    sender,
    createdAt: fbRoom.lastMessage.createdAt?.toDate() || new Date(),
  }
}

const convertKidFrom = (fbRoom: FBRoom): RoomKid => {
  const { id, firstName, lastName } = fbRoom.kid
  return {
    id,
    firstName,
    lastName,
    birthDate: fbRoom.kid.birthDate.toDate(),
    gender: fbRoom.kid.gender === 0 ? KidGender.Female : KidGender.Male,
  }
}

const convertRoomUserFrom = (fbRoom: FBRoom): RoomParent => {
  const { id, firstName, lastName } = fbRoom.user
  return { id, firstName, lastName }
}

const convertStatusFrom = (fbRoom: FBRoom): RoomStatus => {
  const { type, agent } = fbRoom.status
  return {
    type,
    agent,
    // NOTE: since FB might reset this field when settings it with ServerTimestamp,
    // so new Date() is a good compromise while FB sets the right now date.
    updatedAt: fbRoom.status.updatedAt?.toDate() || new Date(),
    snoozedUntil: fbRoom.status.snoozedUntil?.toDate() || null,
  }
}

export const convertToRoomAgent = (agent: Agent): DocumentData => {
  return {
    id: agent.id,
    firstName: agent.firstName,
    lastName: agent.lastName,
    email: agent.email,
    titleKey: agent.titleKey,
    gender: agent.gender,
    profilePic: agent.profilePic,
    title: agent.title,
    bio: agent.bio,
  }
}
