import { Agent } from 'Models/agent'
import { Planning } from 'Models/planning'
import { isHourInSlot } from '@/utils/is-hour-in-slot'

export const getPlanningSlotAgents = (
  agents: Agent[],
  planning: Planning,
  hour = new Date().getHours(),
): Agent[] => {
  return agents.filter(
    (agent) =>
      !!planning[agent.id] &&
      planning[agent.id].some((slot) => isHourInSlot(slot, hour)),
  )
}
