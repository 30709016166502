/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentReference } from 'firebase/firestore'
import { IFirebaseService } from 'External/firebase/types'
import { DeskConfig, DynamicConfig, FBConfigDocumentNames } from 'Models/config'
import {
  convertFromDesk,
  convertFromDynamic,
} from 'Models/converters/config-converter'
import { IConfigService } from './__types__'

export default class ConfigService implements IConfigService {
  firebaseService: IFirebaseService

  constructor(firebaseService: IFirebaseService) {
    this.firebaseService = firebaseService
  }

  listenDynamic(onChange: (newDynamicConfig: DynamicConfig) => void): void {
    this.firebaseService.listenDocument(
      'config-dynamic',
      this.find<DynamicConfig>(
        FBConfigDocumentNames.Dynamic,
        convertFromDynamic,
      ),
      (newDynamicConfig: DynamicConfig) => {
        onChange(newDynamicConfig)
      },
    )
  }

  listenDesk(onChange: (newDeskConfig: DeskConfig) => void): void {
    this.firebaseService.listenDocument(
      'config-desk',
      this.find<DeskConfig>(FBConfigDocumentNames.Desk, convertFromDesk),
      (newDeskConfig: DeskConfig) => {
        onChange(newDeskConfig)
      },
    )
  }

  protected find<T>(
    documentName: FBConfigDocumentNames,
    // FIXME: type issue
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    convertFrom: any,
  ): DocumentReference<T> {
    return this.firebaseService.getDocument(
      'configs',
      documentName,
      convertFrom,
    )
  }
}
