<script lang="ts" setup>
import { PropType } from 'vue'
import { MedicalReportDoctor } from 'Models/medical-report'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'
import BaseAvatar from '@/components/base/BaseAvatar.vue'

defineProps({
  medicalOpinion: {
    type: Boolean,
    required: false,
    default: false,
  },
  requestedDoctor: {
    type: Object as PropType<MedicalReportDoctor | null>,
    required: false,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <article class="flex flex-col">
    <Title>
      {{ t('title') }}
    </Title>
    <div v-if="!medicalOpinion">• {{ t(`${medicalOpinion}`) }}</div>

    <div
      v-if="medicalOpinion && requestedDoctor"
      v-tooltip.left="$agentTitle(requestedDoctor)"
      class="flex flex-row items-center space-x-1 max-w-max"
    >
      <div>•</div>
      <BaseAvatar
        type="agent"
        :first-name="requestedDoctor.firstName"
        :last-name="requestedDoctor.lastName"
        :profile-pic="requestedDoctor.profilePic"
        :size="1"
      />
      <div>
        {{ $fullname(requestedDoctor) }}
      </div>
    </div>
  </article>
</template>
