<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useKidStore } from 'Store/stores'
import { UIMRLCoreItem } from '@/domains/medical-report/ui-models'
import { UIKidSelectedAntecedent } from '@/domains/kid/ui-models'

import AddAntecedent from '@/domains/antecedent/components/AddAntecedent.vue'

const kidStore = useKidStore()

const query = ref('')

const availableAntecedents = computed(() =>
  kidStore.getCurrentKidAvailableAntecedents(query.value),
)

const onSelect = ({
  item,
  checked,
  hidden,
}: {
  item: UIMRLCoreItem
  checked: boolean
  hidden: boolean
}) => {
  const newItem: UIKidSelectedAntecedent = {
    id: item.id,
    hidden,
    checked,
  }
  if (checked) return kidStore.addOrUpdateCurrentKidAntecedent(newItem)
  kidStore.removeCurrentKidAntecedent(newItem.id)
}
</script>

<template>
  <AddAntecedent
    v-if="availableAntecedents"
    v-model:query="query"
    :grouped-antecedents="availableAntecedents"
    @select="onSelect"
  />
</template>
