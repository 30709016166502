<script lang="ts" setup>
import useModal from '@/hooks/modal'

import PrescriptionModal from './ReadOnlyPrescriptionModal.vue'

defineProps({
  prescriptionId: {
    type: String,
    required: true,
  },
})

const { openModal, closeModal, isModalOpen } = useModal()
</script>

<template>
  <button @click.stop="openModal">
    <slot>
      <font-awesome-icon :icon="['fal', 'clipboard-prescription']" />
    </slot>

    <PrescriptionModal
      v-if="isModalOpen"
      :prescription-id="prescriptionId"
      @close="closeModal"
    />
  </button>
</template>
