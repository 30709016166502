import { watch } from 'vue'
import { useAgentStore, usePlanningStore, useRoomListStore } from 'Store/stores'

export const setAgentsPresencePlugin = () => {
  const { setAgentsPresence } = useAgentStore()

  // Rooms
  watch(
    () => useRoomListStore().getAssignedRooms,
    (newRooms, oldRooms) => {
      if (!newRooms || newRooms === oldRooms) return
      setAgentsPresence()
    },
    { immediate: true },
  )

  // Planning
  watch(
    () => usePlanningStore().todayPlanning,
    (newPlanning, oldPlanning) => {
      if (!newPlanning || newPlanning === oldPlanning) return
      setAgentsPresence()
    },
    { immediate: true },
  )
}
