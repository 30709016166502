import { Editor } from '@tiptap/core'
import { Node as ProsemirrorNode } from 'prosemirror-model'

export default (editor: Editor, text: string, position: number): void => {
  editor
    .chain()
    .setNodeSelection(position)
    .focus()
    .command(({ tr }) => {
      const newNode = ProsemirrorNode.fromJSON(editor.schema, {
        type: 'text',
        text,
      })
      tr.replaceSelectionWith(newNode)
      tr.setMeta('preventSpellCheck', false)
      return false
    })
    .run()
}
