<script lang="ts" setup>
import { toRefs } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore } from 'Store/stores'
import useServices from '@/hooks/services'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useRoomComment } from '@/domains/room/controllers/room-comment'

import BaseLoader from '@/components/base/BaseLoader.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import CommentList from '@/domains/room/components/comment/CommentList.vue'
import PostComment from '@/domains/room/components/comment/PostComment.vue'
import ErrorMessage from '@/domains/room/components/comment/ErrorMessage.vue'

const props = defineProps({
  currentRoomId: {
    type: String,
    required: true,
  },
  roomStatusUpdatedAt: {
    type: Date,
  },
  assignedAgentId: {
    type: String,
  },
})

const { tc } = useScopedI18n()
const { currentRoomId, assignedAgentId, roomStatusUpdatedAt } = toRefs(props)
const { currentAgent, agents } = storeToRefs(useAgentStore())

const {
  comment,
  uiComments,
  isCommentPresent,
  isCommentEditable,
  isLastCommentHidden,
  isEditorVisible,
  submitComment,
  allowCommentEditing,
  areCommentsLoading,
  hasError,
} = useRoomComment(
  useServices(),
  currentRoomId,
  agents.value,
  roomStatusUpdatedAt,
  currentAgent.value?.id,
  assignedAgentId,
)
</script>

<template>
  <div v-if="!hasError" class="mx-5 py-5 border-t border-default-100">
    <div class="font-semibold pb-2">
      {{ tc('title', uiComments?.length ?? 0) }}
    </div>
    <BaseLoader v-if="areCommentsLoading" class="pt-5" :is-loading="true" />
    <Body v-else>
      <CommentList
        v-if="uiComments"
        :comments="uiComments"
        :is-editable="isCommentEditable"
        :is-last-comment-hidden="isLastCommentHidden"
        @allow-comment-editing="allowCommentEditing"
      />
      <PostComment
        v-if="isEditorVisible"
        v-model:comment="comment"
        :is-comment-present="isCommentPresent"
        @submit-comment="submitComment"
      />
    </Body>
  </div>
  <ErrorMessage v-else />
</template>
