<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore, useRoomStore } from 'Store/stores'
import Services from 'Services/services'
import useScopedI18n from '@/hooks/scoped-i18n'
import { Agent } from 'Models/agent'
import { AgentDailySlots } from 'Models/planning'
import addDays from 'date-fns/addDays'
import { useAsyncRunner } from '@/hooks/async-runner'
import { differenceInDays } from '@/utils/difference-in-days'

import BaseModal from '@/components/base/BaseModal.vue'
import AgentPicker from '@/domains/agent/views/AgentPicker.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import AgentDailySlotsRow from '../components/AgentDailySlots.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'

const props = defineProps({
  selectedAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
})

const startDate = new Date()
const endDate = addDays(startDate, 30)

const { t, tc } = useScopedI18n()
const { snoozeRoom } = useRoomStore()
const { run: fetch, isRunning: isFetching, hasError } = useAsyncRunner()

const { agents, currentAgent } = storeToRefs(useAgentStore())

const selectedAgent = ref(props.selectedAgent)
const agentDailySlots = ref<AgentDailySlots | null>(null)
const pickedDate = ref<Date>()

const pickedDays = computed(() =>
  pickedDate.value ? differenceInDays(pickedDate.value, startDate) : 0,
)

const selectAgent = (agent: Agent) => (selectedAgent.value = agent)

const onPickDate = (snoozedUntil: Date) => (pickedDate.value = snoozedUntil)
const onSnoozeRoom = () => {
  if (!pickedDate.value) return
  snoozeRoom(pickedDate.value, selectedAgent.value)
}

watch(
  () => selectedAgent.value,
  async () => {
    agentDailySlots.value = await fetch(() =>
      Services.getInstance().planning.getAgentDailySlots(
        selectedAgent.value.id,
        startDate,
        endDate,
      ),
    )
  },
  { immediate: true },
)
</script>

<template>
  <BaseModal
    :small="true"
    :is-loading="isFetching"
    :has-error="hasError"
    :error-message="t('error', { name: $fullname(selectedAgent, false) })"
  >
    <template #header>
      <div class="flex flex-row items-center">
        <div class="flex font-semibold items-center">
          <AgentPicker
            v-if="agents && currentAgent"
            container-class="flex"
            :selected-agent="selectedAgent"
            :current-agent="currentAgent"
            :agents="agents"
            :show-counters="false"
            :split-by-title="true"
            :allow-clear="false"
            :reduced-height="true"
            @select-agent="selectAgent"
          />
          {{ t('title') }}
        </div>
      </div>
    </template>
    <template #default>
      <div v-if="agentDailySlots && agentDailySlots?.length > 0">
        <div class="text-default-light p-4 border-b border-default-100">
          {{ t('instructions') }}
        </div>
        <Body class="max-h-1/2 divide-y divide-default-100">
          <AgentDailySlotsRow
            v-for="({ date, slots }, index) in agentDailySlots"
            :key="index"
            :date="date"
            :slots="slots"
            @pick-date="onPickDate"
          />
        </Body>
      </div>
      <div v-else class="flex items-center justify-center p-5">
        {{ t('noResult') }}
      </div>
    </template>
    <template #footer>
      <div class="flex flex-col space-y-3">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="pickedDate"
          class="text-sm"
          v-html="
            tc('text', pickedDays, {
              days: pickedDays,
              date: $formatDate(addDays(startDate, pickedDays), 'long'),
            })
          "
        />
        <!-- eslint-enable vue/no-v-html -->

        <BasePrimaryButton
          class="w-auto"
          :disabled="!pickedDate"
          @click="onSnoozeRoom()"
        >
          {{ t('button') }}
        </BasePrimaryButton>
      </div>
    </template>
  </BaseModal>
</template>
