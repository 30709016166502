import { MRLItem } from 'Models/medical-report-library-item'
import { UISelectedMRLItem } from '@/domains/medical-report/ui-models'

export const setItemVisibility = (
  mrlItem: MRLItem,
  itemToAdd: UISelectedMRLItem,
  visibleDiagnosticIds?: string[],
): MRLItem => {
  const canBeHidden = !mrlItem.canBeHidden ? false : true

  let hidden
  if (!canBeHidden) {
    hidden = false
  } else if (mrlItem.id === itemToAdd.id) {
    hidden = itemToAdd.hidden
  } else {
    hidden = !visibleDiagnosticIds?.includes(mrlItem.id)
  }

  return {
    ...mrlItem,
    canBeHidden,
    hidden,
  }
}
