import { Directive } from 'vue'

const directive: Directive = {
  // bind
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event: { target: HTMLElement }) {
      // NOTE: because the clicked event.target doesn't exist anymore in the DOM
      if (event.target.closest('body') === null) return

      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value(event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },

  // unbind
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
}
export const VClickOutside = directive
