<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { UIKidProfile } from '@/domains/kid/ui-models'

import BaseAccordion from '@/components/base/BaseAccordion.vue'

const props = defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
  year: {
    type: Number,
    required: true,
  },
  month: {
    type: Number,
    required: true,
  },
  counter: {
    type: Number,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
  isLastMonth: {
    type: Boolean,
    default: false,
  },
  showCounters: {
    type: Boolean,
    required: true,
  },
})

const fromDate = computed(
  () =>
    new Date(
      props.year,
      props.month - 1, // NOTE: -1 is mandatory here
      props.kidProfile.birthDate.getDate(),
      0,
      0,
      0,
      0,
    ),
)
</script>

<template>
  <BaseAccordion
    class="border-default-100"
    header-classes="py-5"
    button-classes="pr-5"
    :force-active="isActive"
    :show-thick-bottom-border="!isLastMonth"
  >
    <template #title>
      <div class="flex flex-1 flex-row space-x-1 items-center">
        <div>
          {{ $month(month) }}
        </div>
        <div v-if="showCounters" class="text-sm text-default-light font-normal">
          ({{ counter }})
        </div>
      </div>

      <div class="text-xs text-default-400">
        {{ $mrhKidAge(kidProfile, fromDate) }}
      </div>
    </template>
    <div class="px-3 pb-5 space-y-5">
      <slot />
    </div>
  </BaseAccordion>
</template>
