import { GetterTree } from './types'
import { toAPIDate } from '@/utils/format-date'
import { UIPrescriptionListFilterParams } from '../../ui-models'

export const getPrescriptionListFilterParams: GetterTree['getPrescriptionListFilterParams'] =
  function ({ prescriptionListFilter }) {
    if (!prescriptionListFilter) return null
    const params: UIPrescriptionListFilterParams = {}

    if (prescriptionListFilter.doctor)
      params.agentId = prescriptionListFilter.doctor.id

    if (prescriptionListFilter.supervisor)
      params.doctorId = prescriptionListFilter.supervisor.id

    if (prescriptionListFilter.startDate)
      params.startDate = toAPIDate(prescriptionListFilter.startDate)

    if (prescriptionListFilter.endDate)
      params.endDate = toAPIDate(prescriptionListFilter.endDate)

    return params
  }
