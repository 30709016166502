import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export interface MiddlewareNavigationGuard {
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
    nextMiddleware?: MiddlewareNavigationGuard,
  ): Promise<void>
}

export enum MiddlewareNames {
  RequireAuthentication = 'requireAuthentication',
  RoomDispatcher = 'roomDispatcher',
}

export interface MiddlewareTree {
  [MiddlewareNames.RequireAuthentication]: MiddlewareNavigationGuard
  [MiddlewareNames.RoomDispatcher]: MiddlewareNavigationGuard
}

export interface RequireAuthenticationMiddleware {
  [MiddlewareNames.RequireAuthentication](
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
    nextMiddleware?: MiddlewareNavigationGuard,
  ): Promise<void>
}

export interface RoomDispatcherMiddleware {
  [MiddlewareNames.RoomDispatcher](
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void>
}
