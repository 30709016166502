<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { useAgentStore, useRoomListStore } from 'Store/stores'
import { PanelPosition } from '@/domains/panel/store/types'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import RoomListComponent from '@/domains/room-list/components/RoomList.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
})

const roomListStore = useRoomListStore()

const assigned = computed(() => roomListStore.getRoomListAssigned)
const unassigned = computed(() => roomListStore.getRoomListUnassigned)
const roomListAwake = computed(() =>
  roomListStore.getRoomListAwake(useAgentStore().agents),
)
</script>

<template>
  <Container :panel-position="panelPosition">
    <RoomListComponent
      v-if="assigned && unassigned && roomListAwake"
      :assigned="assigned"
      :unassigned="unassigned"
      :room-list-awake="roomListAwake"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
