import { Directive } from 'vue'
import { EmojiClickEvent } from 'emoji-picker-element/shared'

const directive: Directive = {
  // bind
  beforeMount(el, binding) {
    el._clickEmoji = (event: EmojiClickEvent) => {
      binding.value.onPickEmoji(event)
      binding.value.close()
    }
    el.addEventListener('emoji-click', el._clickEmoji)
  },

  // unbind
  unmounted(el) {
    el.removeEventListener('emoji-click', el._clickEmoji)
    delete el._clickEmoji
  },
}
export const VEmojiPicker = directive
