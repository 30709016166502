import Services from 'Services/services'
import { ActionTree } from './types'

export const refuseCurrentRoomAssignment: ActionTree['refuseCurrentRoomAssignment'] =
  async function () {
    const room = this.currentRoom

    if (!room) return

    return Services.getInstance().room.refuseAssignment(room)
  }
