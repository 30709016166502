<script lang="ts" setup>
import { computed } from 'vue'

import Section from '@/components/panel/side/shared/Section.vue'
import BaseSearch from '@/components/base/BaseSearch.vue'

const props = defineProps({
  query: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'update:query', query: string): void
}>()

const searchQuery = computed({
  get: () => props.query,
  set: (newQuery) => emits('update:query', newQuery),
})
</script>

<template>
  <Section class="border-b border-default-100 shadow-md">
    <BaseSearch v-model:query="searchQuery" />
    <slot />
  </Section>
</template>
