<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

import Spacer from '@/components/panel/side/shared/Spacer.vue'
import Section from '@/components/panel/side/shared/Section.vue'
import Title from '@/components/panel/side/shared/Title.vue'

defineProps({
  kidWeight: {
    type: Number,
    required: true,
  },
  kidAgeToday: {
    type: String,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <Spacer />
  <Section class="border-b border-default-100">
    <div class="flex flex-row space-x-2 items-center">
      <div>
        <Title>
          {{ t('titleA') }}
        </Title>
      </div>
      <div class="flex items-center flex-row space-x-2">
        <div>{{ kidAgeToday }}</div>
      </div>
      <div>•</div>
      <div class="flex items-center flex-row space-x-2">
        <div>{{ kidWeight }}kg</div>
      </div>
    </div>
  </Section>
</template>
