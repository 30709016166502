import {
  UIMedicalReport,
  UIMedicalReports,
} from '@/domains/medical-report/ui-models'
import { TimeTree } from '@/utils/make-time-tree'

export const buildUIMedicalReports = (
  tree: TimeTree<UIMedicalReport>,
): UIMedicalReports => {
  const years = getSortedNumbers(tree)

  const uiMedicalReports: UIMedicalReports = []

  for (const year of years) {
    const months = getSortedNumbers(tree[year])

    const monthsArr = []
    let counter = 0
    for (const month of months) {
      monthsArr.push({
        month: month,
        medicalReports: tree[year][month],
        counter: tree[year][month].length,
      })
      counter += tree[year][month].length
    }

    uiMedicalReports.push({ year, counter, months: monthsArr })
  }

  return uiMedicalReports
}

const getSortedNumbers = <T>(obj: Record<number, T>): number[] =>
  Object.keys(obj)
    .sort((a, b) => Number(b) - Number(a))
    .map((key) => parseInt(key))
