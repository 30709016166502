<script lang="ts" setup>
import IconLogoLine from '@/components/icons/IconLogoLine.vue'
import { watch } from '@vue/runtime-core'
import { ref } from 'vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
    default: true,
  },
  isVideo: {
    type: Boolean,
    default: false,
  },
  iconSize: {
    type: Number,
    required: false,
    default: 60,
  },
  iconColor: {
    type: String,
    required: false,
    default: 'text-default-300',
  },
})

const isLoading = ref(props.isLoading)

watch(
  () => props.isLoading,
  () => {
    isLoading.value = !isLoading.value
  },
)
</script>

<template>
  <div
    v-if="isLoading"
    class="flex flex-grow min-h-full flex-1 items-center justify-center"
    :class="$attrs.class"
  >
    <div class="animate-bounce">
      <IconLogoLine :width="iconSize" :height="iconSize" :class="iconColor" />
    </div>
  </div>
  <div class="relative flex flex-col max-w-full max-h-full">
    <slot />
    <div
      v-if="isLoading && isVideo"
      class="absolute z-10 top-0 bottom-0 left-0 right-0 bg-white"
    ></div>
  </div>
</template>
