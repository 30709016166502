<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseBanner from '@/components/base/BaseBanner.vue'
import { UIConfig } from '@/domains/config/ui-models'

const props = defineProps({
  config: {
    type: Object as PropType<UIConfig>,
    required: true,
  },
  systemError: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()

const hasNewVersion = ref(false)

const appVersion = computed(() => props.config.desk?.semversion)
const systemError = computed(() => props.systemError)
const drowning = computed(() => props.config.dynamic?.drowning)
const almostDrowning = computed(() => props.config.dynamic?.almostDrowning)
const closing = computed(() => props.config.dynamic?.closing)
const closed = computed(() => props.config.dynamic?.closed)
const closedToDay = computed(() => props.config.dynamic?.closedToday)

const reloadPage = () => window.location.reload()

watch(
  () => appVersion.value,
  (newVersion, oldVersion) => {
    if (newVersion && oldVersion && newVersion > oldVersion) {
      hasNewVersion.value = true
    }
  },
)
</script>

<template>
  <BaseBanner
    v-if="hasNewVersion"
    type="info"
    :message="t('newRelease.text')"
    :has-button="true"
    :button-text="t('newRelease.button')"
    @reload-page="reloadPage"
  />

  <BaseBanner
    v-if="systemError"
    type="danger"
    :message="t('systemError.text')"
  />

  <BaseBanner v-if="closedToDay" type="warning" :message="t('closedToday')" />

  <BaseBanner v-else-if="closed" type="info" :message="t('closed')" />

  <BaseBanner v-else-if="closing" type="info" :message="t('closing')" />

  <BaseBanner
    v-else-if="drowning || almostDrowning"
    type="warning"
    :message="t('drowning')"
  />
</template>
