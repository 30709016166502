import { VTooltipReference } from '../types'
import { cleanTooltip } from './clean-tooltip'

export const hideTooltip = async (
  el: VTooltipReference,
  tooltipContainer: HTMLElement,
  tooltipContent: HTMLElement,
): Promise<void> => {
  // Disable the event listeners
  // NOTE: await is required!
  await el._tooltipInstance?.setOptions((options) => ({
    ...options,
    modifiers: [
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...options.modifiers,
      { name: 'eventListeners', enabled: false },
    ],
  }))

  cleanTooltip(tooltipContainer, tooltipContent)
}
