<script lang="ts" setup>
import { PropType } from 'vue'
import { Agent } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoom } from '@/domains/room-list/ui-models'

import AgentPicker from '@/domains/agent/views/AgentPicker.vue'
import BaseAvatar from '@/components/base/BaseAvatar.vue'

defineProps({
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  assignableAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div v-if="$canAssignRoom(room) && assignableAgents" class="px-2">
    <AgentPicker
      :selected-agent="room.assignedAgent"
      :agents="assignableAgents"
      :current-agent="currentAgent"
      empty-label="unassigned"
      clear-label="unassigned"
      v-bind="$attrs"
    />
  </div>
  <div
    v-else-if="room.assignedAgent"
    class="flex flex-row items-center space-x-2 text-sm px-4 py-1"
  >
    <BaseAvatar
      type="agent"
      :profile-pic="room.assignedAgent.profilePic"
      :size="1"
    />
    <div>
      {{ $fullname(room.assignedAgent) }}
    </div>
  </div>
  <div
    v-else
    class="flex flex-row items-center space-x-2 text-sm px-4 py-1 font-semibold"
  >
    {{ t('empty') }}
  </div>
</template>
