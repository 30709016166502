import { ComputedRef, computed, Ref, ref } from 'vue'
import { Agent } from 'Models/agent'
import { UIPlanning } from '@/domains/planning/ui-models'
import useNow from '@/hooks/now'
import { useAgentStore, usePlanningStore, useRoomStore } from 'Store/stores'
import { storeToRefs } from 'pinia'
import { buildUIPlanning } from '../helpers/build-ui-planning'

export const useLoadTodayPlanning = (): {
  setup: () => Promise<void[]>
  planning: ComputedRef<UIPlanning | null>
  todayRoomsCount: ComputedRef<number>
  now: Ref<Date>
  agentPicked: Ref<Agent | null>
  updateAgentPicked: (agent: Agent | null) => void
} => {
  const planningStore = usePlanningStore()
  const { todayPlanning } = storeToRefs(planningStore)
  const { currentAgent, agents } = storeToRefs(useAgentStore())
  const roomStore = useRoomStore()

  const { now } = useNow()

  const agentPicked = ref<Agent | null>(null)

  const planning = computed(() =>
    buildUIPlanning(
      todayPlanning.value,
      now.value,
      currentAgent.value,
      agents.value,
    ),
  )

  const todayRoomsCount = computed(() => roomStore.getAllTodayRooms.length)

  const setup = async () => {
    const { setClosedRoomsDate, setClosedRooms } = useRoomStore()
    const { setTodayPlanning } = planningStore

    return await Promise.all([
      // required to compute todayRoomsCount
      setClosedRoomsDate(now.value),
      // fetch today closed rooms
      setClosedRooms(),
      setTodayPlanning(),
    ])
  }
  const updateAgentPicked = (agent: Agent | null) => (agentPicked.value = agent)

  return {
    setup,
    planning,
    todayRoomsCount,
    now,
    agentPicked,
    updateAgentPicked,
  }
}
