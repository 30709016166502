import { defineStore } from 'pinia'
import { state } from './state'
import { actions } from './actions'
import { getters } from './getters'
import { cloneState } from '@/utils/clone-state'

export const medicalReportStore = defineStore('medicalReport', {
  state: () => cloneState(state),
  getters,
  actions,
})
