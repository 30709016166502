<script lang="ts" setup>
import { computed, PropType } from 'vue'
import {
  usePanelStore,
  usePrescriptionStore,
  useAgentStore,
  useKidStore,
} from 'Store/stores'
import { Agent } from '@/models/agent'
import {
  PanelPosition,
  PanelsComponentNames,
} from '@/domains/panel/store/types'

import Container from '@/components/panel/side/layout/Container.vue'
import Loader from '@/components/panel/side/shared/Loader.vue'
import NewPrescription from '@/components/panel/side/left/prescription/NewPrescription.vue'
import ReadOnlyPrescription from '@/components/panel/side/left/prescription/Prescription.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  roomId: {
    type: String,
    required: true,
  },
})

const agentStore = useAgentStore()
const { nextPanel } = usePanelStore()
const { changeCurrentPrescription } = usePrescriptionStore()
const kidStore = useKidStore()
const prescriptionStore = usePrescriptionStore()

const kidProfile = computed(() => kidStore.getCurrentKidProfile)
const prescription = computed(() => prescriptionStore.getCurrentPrescription)
const currentAgent = computed(() => agentStore.currentAgent)
const prescriptionReferrerAgents = computed(
  () => agentStore.getPrescriptionReferrerAgents,
)

const onNewPrescriptedDrugStart = () =>
  nextPanel(PanelsComponentNames.AddBrandedDrug)

const onChangeDoctor = (doctor: Agent) => changeCurrentPrescription({ doctor })

const onUpdateKidWeight = (newKidWeight: number) =>
  changeCurrentPrescription({ kidWeight: newKidWeight })

const onUpdateKidLastName = (newKidLastName: string) =>
  changeCurrentPrescription({ kidLastName: newKidLastName })

const onUpdateInstructions = (newInstructions: string) =>
  changeCurrentPrescription({
    instructions: newInstructions,
  })

const onTogglePrescriptedDrugReplaceable = (index: number) =>
  changeCurrentPrescription({
    prescriptedDrugToToggleReplaceable: index,
  })

const onDeletePrescriptedDrug = (index: number) =>
  changeCurrentPrescription({
    prescriptedDrugToDelete: index,
  })
</script>

<template>
  <Container :panel-position="panelPosition">
    <Loader
      v-if="!prescription || !kidProfile"
      :panel-position="panelPosition"
    />
    <NewPrescription
      v-else-if="
        prescription.isNew && currentAgent && prescriptionReferrerAgents
      "
      :panel-position="panelPosition"
      :room-id="roomId"
      :prescription="prescription"
      :kid-profile="kidProfile"
      :current-agent="currentAgent"
      :prescription-referrer-agents="prescriptionReferrerAgents"
      :can-change-doctor="$canDisplayDoctorPickerOnPrescription()"
      @new-prescripted-drug-start="onNewPrescriptedDrugStart"
      @change-doctor="onChangeDoctor"
      @update-kid-weight="onUpdateKidWeight"
      @update-kid-last-name="onUpdateKidLastName"
      @update-instructions="onUpdateInstructions"
      @toggle-prescripted-drug-replaceable="onTogglePrescriptedDrugReplaceable"
      @delete-prescripted-drug="onDeletePrescriptedDrug"
    />
    <ReadOnlyPrescription
      v-else
      :panel-position="panelPosition"
      :prescription="prescription"
      :kid-profile="kidProfile"
    />
  </Container>
</template>
