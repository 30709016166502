<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { useMessageColors } from '@/domains/core-chat/controllers/message-colors'
import { UICoreChatMessageColor } from '@/domains/core-chat/ui-models'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  color: {
    type: String as PropType<UICoreChatMessageColor>,
    required: false,
    default: 'default',
  },
})

const color = computed(() => props.color)
const { messageColors } = useMessageColors(color)
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="w-fit px-3 py-2 rounded-xl break-words message max-w-full border"
    :class="messageColors"
    v-html="$sanitizeHtml(text)"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<style scoped>
.message :deep(.mention) {
  @apply font-medium rounded bg-default-300 px-1 bg-opacity-40;
}

.message :deep(.emoji) {
  @apply mr-1;
  font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.message :deep(a) {
  @apply underline;
}
</style>
