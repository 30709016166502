import { UIRoomComment } from '@/domains/room/ui-models'
import { Agent } from 'Models/agent'
import { RoomComment } from 'Models/room'

export const buildUIRoomComments = (
  agents: Agent[] | null,
  comments: RoomComment[],
): UIRoomComment[] => {
  return comments.map((comment) => {
    return {
      id: comment.id,
      comment: comment.comment,
      commentedAt: new Date(comment.commentedAt),
      agent: agents?.find((agent) => agent.id === comment.agentId),
    }
  })
}
