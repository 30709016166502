import { IFirebaseService } from 'External/firebase/types'
import {
  CollectionReference,
  DocumentReference,
  orderBy,
  query,
  Query,
  setDoc,
  where,
  arrayRemove,
  UpdateData,
  limit,
  arrayUnion,
} from 'firebase/firestore'
import {
  convertFrom,
  convertTo,
} from 'Models/converters/inception-chat-converter'
import {
  convertFrom as convertMessageFrom,
  convertTo as convertMessageTo,
} from 'Models/converters/inception-chat-message-converter'
import { FBInceptionChat, InceptionChat } from 'Models/inception-chat'
import {
  FBInceptionChatMessage,
  InceptionChatMessage,
} from 'Models/inception-chat-message'
import { MessageTypes } from 'Models/message'
import { IInceptionChatService } from 'Services/__types__'

export default class InceptionChatService implements IInceptionChatService {
  firebaseService: IFirebaseService
  cachedRoomIds: string[] = []

  constructor(firebaseService: IFirebaseService) {
    this.firebaseService = firebaseService
  }

  async loadByRoomId(roomId: string): Promise<InceptionChat | undefined> {
    const chats = await this.firebaseService.loadDocuments(
      this.findByRoomId(roomId),
    )
    return chats ? chats[0] : undefined
  }

  /**
   * Regarding a room, load its InceptionChat from Firebase or create it.
   *
   * @param roomId
   * @returns a persisted InceptionChat object
   */
  async loadOrCreate(
    roomId: string,
    assignedAgentId: string | null,
  ): Promise<InceptionChat> {
    const chat = await this.loadByRoomId(roomId)
    if (chat) return chat
    return this.create(roomId, assignedAgentId)
  }

  async close(roomId: string): Promise<boolean> {
    return this.changeOpen(roomId, false)
  }

  async reOpen(roomId: string): Promise<boolean> {
    return this.changeOpen(roomId, true)
  }

  async loadAllOpen(agentId: string): Promise<InceptionChat[]> {
    const chats = await this.firebaseService.loadDocuments(
      this.findAllOpen(agentId),
    ) // miaou
    return chats || []
  }

  async getOpenRoomIds(agentId: string): Promise<string[]> {
    const chats = await this.firebaseService.loadDocuments(
      this.findAllOpen(agentId),
    )
    return (chats || []).map((chat) => chat.roomId)
  }

  async loadAllMessages(
    chatId: string,
    agentId: string | null,
  ): Promise<InceptionChatMessage[]> {
    const messages =
      (await this.firebaseService.loadDocuments(
        this.findAllMessages(chatId),
      )) || []

    if (agentId) await this.markAllMessagesAsRead(chatId, agentId)

    return messages
  }

  async hasUnreadMessages(roomId: string, agentId: string): Promise<boolean> {
    const chat = await this.loadByRoomId(roomId)
    if (!chat) return false
    return chat.mustReadAgentIds.includes(agentId)
  }

  async hasMessages(roomId: string): Promise<boolean> {
    const chat = await this.loadByRoomId(roomId)
    return !!chat && chat.hasMessages
  }

  async postText(
    chatId: string,
    roomId: string,
    agentId: string,
    content: string,
    mentionedAgentIds: string[],
  ): Promise<InceptionChatMessage> {
    const chat = await this.load(chatId)

    if (!chat) throw "Can't post a message without a persisted chat"

    const message = this.buildMessage(
      chatId,
      roomId,
      agentId,
      content,
      mentionedAgentIds,
    )

    await this.firebaseService.batchWrites((batch) => {
      // persist the message
      const messageRef = this.getNewMessageDocument()
      message.id = messageRef.id
      batch.set(messageRef, message)

      // update the chat
      const mustReadAgentIds = mentionedAgentIds
        .concat(chat.agentIds)
        .filter((localAgentId, index, self) => {
          return (
            localAgentId !== agentId && self.indexOf(localAgentId) === index
          )
        })

      const changes: UpdateData<FBInceptionChat> = {
        agentIds: arrayUnion(...[agentId, ...mentionedAgentIds]),
        mustReadAgentIds,
        hasMessages: true,
        updatedAt: new Date(),
      }

      batch.update(this.getDocument(chatId), changes)
    })

    return message
  }

  protected async changeOpen(
    roomId: string,
    newValue: boolean,
  ): Promise<boolean> {
    // we need to make sure that the related InceptionChat exists
    const chat = await this.loadByRoomId(roomId)

    if (!chat) return false

    const changes: UpdateData<FBInceptionChat> = { open: newValue }

    await this.update(chat.id, changes)

    return true
  }

  protected markAllMessagesAsRead(
    chatId: string,
    agentId: string,
  ): Promise<void> {
    const changes: UpdateData<FBInceptionChat> = {
      mustReadAgentIds: arrayRemove(agentId),
    }
    return this.update(chatId, changes)
  }

  async changeOwner(
    roomId: string,
    newAssignedAgentId: string,
    oldAssignedAgentId?: string,
  ): Promise<void> {
    const chat = await this.loadByRoomId(roomId)

    if (!chat) return

    const messages = await this.loadAllMessages(chat.id, null)

    const keepOldAgent = messages.some(
      (message) =>
        !!oldAssignedAgentId &&
        (message.agentId === oldAssignedAgentId ||
          message.mentionedAgentIds.includes(oldAssignedAgentId)),
    )

    // Remove previous agent
    if (
      !!oldAssignedAgentId &&
      !keepOldAgent &&
      newAssignedAgentId !== oldAssignedAgentId
    )
      this.update(chat.id, {
        agentIds: arrayRemove(oldAssignedAgentId),
      })

    // Add new agent
    this.update(chat.id, { agentIds: arrayUnion(newAssignedAgentId) })
  }

  /** __ Chat collection __ */

  protected async create(
    roomId: string,
    assignedAgentId: string | null,
  ): Promise<InceptionChat> {
    // !!!IMPORTANT!!! to avoid race conditions, we use the roomId as the id of the inception chat
    const chatRef = this.getNewDocument(roomId)
    const chat: InceptionChat = {
      id: roomId,
      roomId,
      agentIds: [],
      mustReadAgentIds: [],
      hasMessages: false,
      open: true,
      updatedAt: new Date(),
    }
    if (assignedAgentId) chat.agentIds = [assignedAgentId]
    await setDoc(chatRef, chat)
    return chat
  }

  protected update(
    chatId: string,
    data: UpdateData<FBInceptionChat>,
  ): Promise<void> {
    return this.firebaseService.updateDocument(
      this.getCollectionName(),
      chatId,
      data,
    )
  }

  protected async load(chatId: string): Promise<InceptionChat | undefined> {
    return this.firebaseService.loadDocument(this.find(chatId))
  }

  protected findAllOpen(agentId: string): Query<InceptionChat> {
    return query(
      this.getCollection(),
      where('agentIds', 'array-contains', agentId),
      where('open', '==', true),
      orderBy('updatedAt', 'asc'),
    )
  }

  protected findByRoomId(roomId: string): Query<InceptionChat> {
    return query(this.getCollection(), where('roomId', '==', roomId))
  }

  protected find(chatId: string): DocumentReference<InceptionChat> {
    return this.firebaseService.getDocument(
      this.getCollectionName(),
      chatId,
      convertFrom,
    )
  }

  protected getDocument(chatId: string): DocumentReference {
    return this.firebaseService.getRawDocument(this.getCollectionName(), chatId)
  }

  protected getNewDocument(roomId: string): DocumentReference<InceptionChat> {
    return this.firebaseService.getDocument<FBInceptionChat, InceptionChat>(
      this.getCollectionName(),
      roomId,
      convertFrom,
      convertTo,
    )
  }

  protected getCollection(): CollectionReference<InceptionChat> {
    return this.firebaseService.getCollection(
      this.getCollectionName(),
      convertFrom,
    )
  }

  protected getCollectionName(): string {
    return 'inception-chats'
  }

  /** __ Message collection __ */

  protected buildMessage(
    chatId: string,
    roomId: string,
    agentId: string,
    content: string,
    mentionedAgentIds: string[],
  ): InceptionChatMessage {
    return {
      id: 'NEW-MESSAGE',
      chatId,
      roomId,
      agentId,
      type: MessageTypes.Text,
      content,
      mentionedAgentIds,
      postedAt: new Date(), // the date will replaced by the FB server
    }
  }

  protected findAllMessages(chatId: string): Query<InceptionChatMessage> {
    return query(
      this.getMessageCollection(),
      where('chatId', '==', chatId),
      orderBy('createdAt', 'asc'),
    )
  }

  protected findFirstUnreadMessage(
    roomId: string,
    agentId: string,
  ): Query<InceptionChatMessage> {
    return query(
      this.getMessageCollection(),
      where('roomId', '==', roomId),
      where('mustReadAgentIds', 'array-contains', agentId),
      limit(1),
      orderBy('createdAt', 'asc'),
    )
  }

  protected findFirstMessage(roomId: string): Query<InceptionChatMessage> {
    return query(
      this.getMessageCollection(),
      where('roomId', '==', roomId),
      limit(1),
      orderBy('createdAt', 'asc'),
    )
  }

  protected getMessageCollection(): CollectionReference<InceptionChatMessage> {
    return this.firebaseService.getCollection(
      this.getMessageCollectionName(),
      convertMessageFrom,
    )
  }

  protected getNewMessageDocument(): DocumentReference<InceptionChatMessage> {
    return this.firebaseService.getNewDocument<
      FBInceptionChatMessage,
      InceptionChatMessage
    >([this.getMessageCollectionName()], convertMessageFrom, convertMessageTo)
  }

  protected getMessageDocument(messageId: string): DocumentReference {
    return this.firebaseService.getRawDocument(
      this.getMessageCollectionName(),
      messageId,
    )
  }

  protected getMessageCollectionName(): string {
    return 'inception-chat-messages'
  }
}
