<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'

import MedcialProtocolModalImage from './MedicalProtocolModalImage.vue'
import BaseModalLoader from '@/components/base/base-modal/BaseModalLoader.vue'
import BaseImageOverlay from '@/components/base/BaseImageOverlay.vue'

const props = defineProps({
  protocols: {
    type: Object as PropType<string[]>,
    required: true,
  },
  selectedProtocol: {
    type: String,
  },
})

const loadedProtocols = ref<string[]>([])

const allProtocolsLoaded = computed(
  () =>
    props.protocols.length === loadedProtocols.value.length &&
    props.protocols.every((protocol) =>
      loadedProtocols.value.includes(protocol),
    ),
)

const onProtocolLoaded = (url: string) => loadedProtocols.value.push(url)
</script>

<template>
  <BaseModalLoader v-if="!allProtocolsLoaded" :with-header="false" />

  <div class="flex overflow-hidden">
    <div
      class="flex flex-row flex-wrap overflow-y-auto scrollbar overflow-x-hidden"
      :class="{ 'w-8/12': selectedProtocol }"
    >
      <div class="flex flex-row items-start flex-wrap px-2">
        <MedcialProtocolModalImage
          v-for="protocol in protocols"
          :key="protocol"
          :src="protocol"
          :selected-protocol="selectedProtocol"
          :protocols-loaded="allProtocolsLoaded"
          v-bind="$attrs"
          @protocol-loaded="onProtocolLoaded"
        />
      </div>
    </div>
    <div
      v-if="selectedProtocol"
      class="p-4 border-l border-default-100 w-4/12 overflow-y-auto scrollbar overflow-x-hidden"
    >
      <BaseImageOverlay :src="selectedProtocol">
        <img
          class="rounded-lg w-full cursor-zoom-in"
          :src="selectedProtocol"
          alt=""
        />
      </BaseImageOverlay>
    </div>
  </div>
</template>
