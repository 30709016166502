import { ActionTree } from './types'
import {
  MRLibraries,
  MRLItem,
  MRLStrictTopics,
} from 'Models/medical-report-library-item'
import { useAgentStore, useKidStore, useUIStore } from 'Store/stores'
import Services from 'Services/services'
import { AvailableLocales } from '@/locales'
import { Kid } from 'Models/kid'
import { MedicalReport } from 'Models/medical-report'
import { IMedicalReportLibraryService } from 'Services/__types__'
import { SavedReplyMedicalReport } from 'Models/saved-reply'

export const completeCurrentMedicalReportWithSavedReply: ActionTree['completeCurrentMedicalReportWithSavedReply'] =
  async function (savedReplies, savedReplyId) {
    if (!savedReplies) return

    const savedReply = savedReplies.find(
      (savedReply) => savedReply.id === savedReplyId,
    )
    const locale = useUIStore().locale

    if (!savedReply?.medicalReport || !this.currentMedicalReport || !locale)
      return

    const changes: { [key in MRLStrictTopics]?: MRLItem[] } = {}

    for (const key of Object.keys(MRLStrictTopics)) {
      const topic = MRLStrictTopics[key as keyof typeof MRLStrictTopics]
      updateItemList(
        changes,
        topic,
        {
          causes: this.causes,
          diagnosis: this.diagnosis,
          directions: this.directions,
          supervisions: this.supervisions,
        },
        getSavedReplyMRLItemIds(topic, savedReply.medicalReport),
        Services.getInstance(),
        this.currentMedicalReport,
        locale,
        useKidStore().currentKid,
      )
    }

    await Services.getInstance().medicalReport.update(
      this.currentMedicalReport.id,
      changes,
    )
  }

const updateItemList = (
  changes: { [key in MRLStrictTopics]?: MRLItem[] },
  topic: MRLStrictTopics,
  libraries: MRLibraries,
  ids: string[] | undefined,
  services: Services,
  currentMedicalReport: MedicalReport,
  locale: AvailableLocales,
  currentKid: Kid | null,
) => {
  const library = services.medicalReport.getMRLibrary(topic, libraries)

  if (
    !library ||
    !ids ||
    ids.length === 0 ||
    !currentMedicalReport ||
    !currentKid
  )
    return

  const existingIds =
    services.medicalReport.getMRLItemIds(topic, currentMedicalReport) || []
  const updatedList: MRLItem[] = getMRLItems(library, existingIds)

  getSavedReplyMRLItems(
    services.medicalReportLibrary,
    ids,
    library,
    currentKid,
    useAgentStore().getCurrentAgentGroupIds,
    locale,
  ).forEach((item) => {
    if (existingIds.indexOf(item.id) !== -1) return // avoid duplicated items
    updatedList.push(item)
  })

  changes[topic] = updatedList
}

const getSavedReplyMRLItems = (
  service: IMedicalReportLibraryService,
  ids: string[],
  library: MRLItem[],
  kid: Kid,
  agentGroupIds: string[],
  locale: string,
) => {
  const items = getMRLItems(library, ids)
  return service.filter(items, '', kid, agentGroupIds, locale)
}

const getMRLItems = (library: MRLItem[], ids: string[]) => {
  return ids
    .map((itemId) => library.find((mrlItem) => mrlItem.id === itemId))
    .filter((item): item is MRLItem => !!item)
}

const getSavedReplyMRLItemIds = (
  topic: MRLStrictTopics,
  medicalReport: SavedReplyMedicalReport,
) => {
  switch (topic) {
    case MRLStrictTopics.Causes:
      return medicalReport.causeIds
    case MRLStrictTopics.Diagnosis:
      return medicalReport.diagnosisIds
    case MRLStrictTopics.Directions:
      return medicalReport.directionIds
    case MRLStrictTopics.Supervisions:
      return medicalReport.supervisionIds
    default:
      return
  }
}
