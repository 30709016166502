<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIMedicalReport } from '@/domains/medical-report/ui-models'

const props = defineProps({
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
  isInstructionSaved: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'update', newInstructions: string): void
}>()

const { t } = useScopedI18n()

const instructionsRef = ref<string>()

const instructions = computed({
  get: () => instructionsRef.value,
  set: (newInstructions) => {
    instructionsRef.value = newInstructions
    if (newInstructions || newInstructions === '')
      emits('update', newInstructions)
  },
})

watch(
  () => props.medicalReport.id,
  () => (instructions.value = props.medicalReport.instructions),
  { immediate: true },
)
</script>

<template>
  <div>
    <div class="flex flex-row px-5 pb-2">
      <div class="flex flex-1 items-center space-x-2">
        <div class="font-semibold">{{ t('title') }}</div>
        <font-awesome-icon
          v-tooltip="t('information')"
          :icon="['fal', 'circle-info']"
          class="text-orange-500"
          fixed-width
        />
      </div>
      <div v-if="medicalReport.instructions">
        <div v-if="isInstructionSaved">
          <font-awesome-icon :icon="['fal', 'check']" class="text-green-500" />
        </div>
        <div v-else>
          <font-awesome-icon
            :icon="['fal', 'ellipsis-h']"
            class="animate-ping-slow"
          />
        </div>
      </div>
    </div>
    <main class="px-5 mb-3">
      <textarea
        v-model="instructions"
        class="rounded-sm w-full scrollbar px-2 py-2 h-32 placeholder-default-400 border border-default-100 focus:border-primary focus:outline-none focus:shadow-lg bg-orange-50"
        :placeholder="t('placeholder')"
      />
    </main>
  </div>
</template>
