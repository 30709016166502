<script lang="ts" setup>
import { ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

const { t } = useScopedI18n()

const showWarn = ref(true)

const onCloseWarning = () => (showWarn.value = false)
</script>

<template>
  <div
    v-if="showWarn"
    class="absolute right-96 z-10 bg-orange-400 text-white h-12 mt-5 flex items-center rounded-lg animate-bounce"
  >
    <button
      class="px-3 py-2 border-r border-orange-300"
      @click="onCloseWarning"
    >
      <font-awesome-icon :icon="['fal', 'times']" class="text-orange-100" />
    </button>
    <div class="px-3">{{ t('medicalReportToFillUp') }}</div>
    <div class="absolute -right-2">
      <font-awesome-icon
        :icon="['fas', 'caret-right']"
        class="text-2xl text-orange-400"
      />
    </div>
  </div>
</template>
