import { RouteRecordRaw } from 'vue-router'
import { RoutesNames } from './type'

import Main from '@/domains/main/Main.vue'

// TODO: rename it with singular
export const buildPrescriptionsRoutes = (): RouteRecordRaw[] => [
  {
    path: '/prescriptions',
    name: RoutesNames.Prescriptions,
    component: Main,
    props: true,
    meta: {
      protected: true,
    },
    children: [
      {
        path: ':prescriptionId',
        name: RoutesNames.PrescriptionDetail,
        component: Main,
        props: true,
      },
    ],
  },
]
