<script lang="ts" setup>
import { ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import BaseButtonTextAndIcon from '@/components/base/BaseButtonTextAndIcon.vue'

const emits = defineEmits<{
  (e: 'closeRoom'): void
  (e: 'unassignRoom'): void
}>()

const { t, mt } = useScopedI18n()

const isLoading = ref(false)

const props = defineProps({
  displayUnassignBlock: {
    type: Boolean,
    required: true,
  },
})

const handleCloseClick = () => ((isLoading.value = true), emits('closeRoom'))
const handleUnassignClick = () => (
  (isLoading.value = true), emits('unassignRoom')
)
</script>

<template>
  <BaseDropdown button-custom-class="w-8 h-8 rounded hover:bg-default-100">
    <template #button>
      <font-awesome-icon
        v-tooltip.bottom="t('tooltip')"
        :icon="['fal', 'times-circle']"
        class="focus:outline-none text-primary"
      />
    </template>
    <div class="px-5">
      <div class="flex items-center py-5 border-b border-b-gray-100">
        <p class="mr-4 text-sm w-80" v-html:="mt('warningCloseText')" />
        <BaseButtonTextAndIcon
          :disabled="isLoading"
          class="w-56 h-12 text-sm text-white bg-primary"
          @click="handleCloseClick"
        >
          <font-awesome-icon
            :icon="['fal', 'times-circle']"
            class="w-6 h-6 my-auto mr-2 text-white focus:outline-none"
          /><span>{{ t('submitButtonClose') }}</span>
        </BaseButtonTextAndIcon>
      </div>
      <div v-if="props.displayUnassignBlock" class="flex items-center py-5">
        <p class="mr-4 text-sm w-80" v-html:="mt('warningUnassignedText')" />
        <BaseButtonTextAndIcon
          :disabled="isLoading"
          class="w-56 h-12 text-sm bg-white border text-primary border-primary"
          @click="handleUnassignClick"
        >
          {{ t('submitButtonUnassigned') }}
        </BaseButtonTextAndIcon>
      </div>
    </div>
  </BaseDropdown>
</template>
