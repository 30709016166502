import { Directive } from 'vue'

const directive: Directive = {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    el.focus()
  },
}
export const VFocus = directive
