import { ActionTree } from './types'
import { fail } from './concerns/fail'
import i18n from '@/plugins/vue-use/i18n'
import { startup } from './concerns/startup'
import Services from 'Services/services'

export const signIn: ActionTree['signIn'] = async function (credentials) {
  const services = Services.getInstance()
  try {
    const authInfo = await services.authentication.signIn(credentials)
    await startup(authInfo, services)
  } catch (error) {
    fail(error, i18n.global.t('alerts.auth.wrongCredentials'))
  }
}
