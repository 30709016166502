<script lang="ts" setup>
import { resizeImageUrl } from '@/utils/resize-image-url'
import { PropType } from '@vue/runtime-core'
import { computed, ref, watch } from 'vue'
import { DEFAULT_PROFILE_PIC_URL } from 'Models/agent'

import IconLogoLine from '@/components/icons/IconLogoLine.vue'
import BaseAvatarLoader from '@/components/base/BaseAvatarLoader.vue'
import BaseWorkingDot from '@/components/base/BaseWorkingDot.vue'

const props = defineProps({
  type: {
    type: String as PropType<'agent' | 'user' | undefined>,
    required: false,
  },
  profilePic: {
    type: String as PropType<string | null>,
    required: false,
  },
  firstName: {
    type: String,
  },
  lastName: {
    type: String,
  },
  size: {
    type: Number as PropType<1 | 2 | 3 | 4 | 5>,
    required: true,
  },
  workingDotEnabled: {
    type: Boolean,
    default: false,
  },
  isWorking: {
    type: Boolean,
    default: false,
  },
  workingDotClass: {
    type: Object as PropType<Record<string, boolean>>,
  },
  hasRing: {
    type: Boolean,
    default: false,
  },
  ringColor: {
    type: String,
    default: 'ring-white',
  },
})

const isPostedByAgent = computed(() => props.type === 'agent')
const isPostedByRobot = computed(
  () => props.type !== 'user' && props.type !== 'agent',
)
const resizeWidth = computed(() => (props.size < 5 ? 80 : 192))

const acceleratedAvatarUrl = ref<string | null>(null)
const isMediaLoaded = ref(false)
const onMediaLoad = () => (isMediaLoaded.value = true)
const onMediaError = () => {
  isMediaLoaded.value = false
  acceleratedAvatarUrl.value = resizeImageUrl(DEFAULT_PROFILE_PIC_URL, {
    width: resizeWidth.value,
  })
}

watch(
  () => props.profilePic,
  (avatarUrl) => {
    if (!avatarUrl) return
    acceleratedAvatarUrl.value = resizeImageUrl(avatarUrl, {
      width: resizeWidth.value,
    })
  },
  { immediate: true },
)
</script>

<template>
  <div
    class="flex relative"
    :class="{
      'w-4 h-4 text-xs': size === 1,
      'w-6 h-6 text-xs': size === 2,
      'w-8 h-8': size === 3,
      'w-10 h-10': size === 4,
      'w-24 h-24': size === 5,
    }"
  >
    <!-- Agent -->
    <BaseAvatarLoader
      v-if="isPostedByAgent && acceleratedAvatarUrl"
      :is-loading="!isMediaLoaded"
      :first-name="firstName"
      class="w-full h-full"
    >
      <img
        class="rounded-full w-full h-full"
        :class="{
          [ringColor]: hasRing,
          'ring-2': hasRing && size < 4,
          'ring-4 ': hasRing && size === 5,
          hidden: !isMediaLoaded,
        }"
        :src="acceleratedAvatarUrl"
        @load="onMediaLoad"
        @error="onMediaError"
      />
    </BaseAvatarLoader>

    <!-- Robot -->
    <div
      v-else-if="isPostedByRobot"
      :class="[
        {
          [ringColor]: hasRing,
          'ring-2': hasRing && size < 4,
          'ring-4 ': hasRing && size === 5,
        },
        $isProduction ? 'bg-primary' : 'bg-default-800',
      ]"
      class="flex items-center justify-center rounded-full overflow-hidden h-full w-full"
    >
      <IconLogoLine
        class="text-white max-h-full max-w-full transform-gpu scale-50"
        width="100%"
        height="100%"
      />
    </div>

    <!-- User -->
    <div
      v-else
      class="flex items-center justify-center font-bold text-white bg-primary rounded-full w-full h-full"
      :class="{
        [ringColor]: hasRing,
        'ring-2': hasRing && size < 4,
        'ring-4 ': hasRing && size === 5,
        'text-4xl': size === 5,
      }"
    >
      {{ firstName?.charAt(0) }}
    </div>

    <BaseWorkingDot
      v-if="workingDotEnabled"
      :is-working="isWorking"
      :working-dot-class="workingDotClass"
      :class="{
        'w-2 h-2 left-3 -bottom-0.5': size === 1,
        'w-3 h-3 left-5 -bottom-0.5': size === 2,
        'w-3 h-3 left-6 -bottom-0.5': size === 3,
        'w-3.5 h-3.5 left-8 -bottom-0.5': size === 4,
      }"
    />
  </div>
</template>
