import { ActionTree } from './types'
import {
  useAgentStore,
  useConfigStore,
  useMedicalReportStore,
  usePrescriptionStore,
} from 'Store/stores'
import Services from 'Services/services'

export const assignCurrentRoom: ActionTree['assignCurrentRoom'] =
  async function (agent) {
    const currentAgent = useAgentStore().currentAgent
    const currentPrescription = usePrescriptionStore().currentPrescription
    const config = useConfigStore().getConfig

    if (!config || !config.desk) return null

    const currentMedicalReport = useMedicalReportStore().currentMedicalReport

    if (
      !this.currentRoom ||
      !currentMedicalReport ||
      !currentAgent ||
      !currentPrescription
    )
      return

    await Services.getInstance().roomAssignment.assign(
      this.currentRoom,
      currentMedicalReport,
      currentPrescription,
      agent || currentAgent,
      currentAgent,
      config.desk.supervisorAgent,
    )

    // reload the prescription draft because it has been updated
    // the state will be asynchronously updated as well
    Services.getInstance().roomWorkspace.touch(this.currentRoom.id)
  }
