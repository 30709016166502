import i18n from '@/plugins/vue-use/i18n'

export default function useUploadImage(
  el: HTMLFormElement,
  callback: (image: File) => void,
): void {
  if (el) {
    const image = el.files[0]
    if (!image) return // it might happen that no file has been selected
    const fileSize = image.size / (1024 * 1024)
    if (fileSize > 1) {
      alert(i18n.global.t('imageTooBig'))
    } else {
      callback(image)
    }
  }
}
