import { ActionTree } from './types'
import { RoomListFilter } from '../types'

export const changeRoomListFilter: ActionTree['changeRoomListFilter'] =
  function (changes) {
    const roomListFilter = this.filter
    if (!roomListFilter) return

    const newFilter: RoomListFilter = { ...roomListFilter }

    if (changes.agent !== undefined) newFilter.agent = changes.agent
    if (changes.date !== undefined) newFilter.date = changes.date
    if (changes.status !== undefined) newFilter.status = changes.status

    this.filter = newFilter
  }
