import { APIDrug, APIDrugDosage, Drug, DrugDosage } from 'Models/drug'

export const convertFromAPI = (drugs: APIDrug[]): Drug[] => {
  if (!drugs) return [] // NOTE: Emilie created brandedDrugs without drugs and dosages
  return drugs.map((drug) => ({
    id: drug.id,
    name: drug.name,
    shortLabel: drug.shortLabel,
    dosages: convertDosagesFromAPI(drug.dosages),
  }))
}

const convertDosagesFromAPI = (dosages: APIDrugDosage[]): DrugDosage[] => {
  if (!dosages) return [] // NOTE: Emilie created brandedDrugs without drugs and dosages
  return dosages
    .filter((dosage) => !dosage.deleted)
    .map((dosage): DrugDosage => {
      return {
        id: dosage.id,
        name: dosage.name,
        shortLabel: dosage.shortLabel,
      }
    })
}
