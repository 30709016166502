<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { RoomKid } from 'Models/room'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseDropdown from './BaseDropdown.vue'
import { $fullname } from '@/plugins/vue-helpers/fullname'

const props = defineProps({
  selectedKid: {
    type: Object as PropType<RoomKid | null>,
    required: false,
    default: null,
  },
  kids: {
    type: Array as PropType<RoomKid[]>,
    required: true,
  },
  emptyLabel: {
    type: String,
    required: false,
    default: 'emptyLabel',
  },
  allowClear: {
    type: Boolean,
    required: false,
    default: true,
  },
  clearLabel: {
    type: String,
    required: false,
    default: 'clearLabel',
  },
  menuAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'right',
  },
  buttonCustomClass: {
    type: String,
    default: '',
  },
})

const emits = defineEmits<{
  (e: 'selectKid', kid: RoomKid): void
  (e: 'unselectKid'): void
}>()

const { t } = useScopedI18n()

const label = computed(() =>
  props.selectedKid ? $fullname(props.selectedKid) : t(props.emptyLabel),
)

const onSelectKid = (kid: RoomKid, close: () => void) => (
  emits('selectKid', kid), close()
)
const onUnselectKid = (close: () => void) => (emits('unselectKid'), close())
</script>

<template>
  <BaseDropdown :offset-distance="4" :button-custom-class="buttonCustomClass">
    <template #button>
      <div
        class="flex flex-row items-center space-x-2 text-sm text-primary px-2 py-1"
      >
        <div>
          <span class="text-default">{{ t('prefix') }}</span> {{ label }}
        </div>
        <font-awesome-icon :icon="['fas', 'caret-down']" />
      </div>
    </template>

    <template #default="{ close }">
      <div class="min-w-max">
        <button
          v-if="allowClear && selectedKid"
          type="button"
          class="flex px-5 py-2 text-left hover:bg-blue-50 w-full border-b border-default-100 text-sm"
          @click="onUnselectKid(close)"
        >
          <div class="text-primary">
            {{ t(clearLabel) }}
          </div>
        </button>

        <ul class="flex flex-col divide divide-y-default-100">
          <li
            v-for="(kid, index) in kids"
            :key="index"
            class="flex flex-row items-center px-5 py-2 divide divide-y-default-100 text-sm"
            :class="
              selectedKid === kid
                ? 'bg-green-100 cursor-default text-default-light'
                : 'hover:bg-blue-50 cursor-pointer text-primary'
            "
            @click="onSelectKid(kid, close)"
          >
            <div class="flex flex-1">
              {{ $fullname(kid) }}
            </div>
            <font-awesome-icon
              v-if="selectedKid === kid"
              class="text-green-500"
              :icon="['fal', 'check']"
            />
          </li>
        </ul>
      </div>
    </template>
  </BaseDropdown>
</template>
