import { PiniaPluginContext } from 'pinia'
import { trackAgentPlugin } from './track-agent'

export const augmentPlugin = ({ store }: PiniaPluginContext) => {
  // Actions
  store.$onAction(
    ({
      name, // name of the action
      args, // array of parameters passed to the action
      after, // hook after the action returns or resolves
    }) => {
      trackAgentPlugin(store, name, args, after)
    },
  )
}
