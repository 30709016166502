import { ActionTree } from './types'
import {
  useConfigStore,
  useMedicalReportStore,
  usePrescriptionStore,
} from 'Store/stores'
import Services from 'Services/services'

export const acceptCurrentRoomAssignment: ActionTree['acceptCurrentRoomAssignment'] =
  async function () {
    const currentPrescription = usePrescriptionStore().currentPrescription
    const config = useConfigStore().getConfig

    if (!config || !config.desk) return null

    const currentMedicalReport = useMedicalReportStore().currentMedicalReport

    if (!this.currentRoom || !currentMedicalReport || !currentPrescription)
      return

    await Services.getInstance().roomAssignment.acceptAssignment(
      this.currentRoom,
      currentMedicalReport,
      currentPrescription,
      config.desk.supervisorAgent,
    )

    // reload the prescription draft because it has been updated
    // the state will be asynchronously updated as well
    Services.getInstance().roomWorkspace.touch(this.currentRoom.id)
  }
