export function isEmpty<T>(value: T): boolean {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) &&
      Object.keys(value).length === 1 &&
      isEmpty(value[0]))
  )
}
