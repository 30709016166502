<script lang="ts" setup>
import { ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import controller from '../controllers/confirm-prescription'

import BaseModal from '@/components/base/BaseModal.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import BaseSecondaryButton from '@/components/base/BaseSecondaryButton.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'
import PrescriptionModalTitle from './prescription-modal/PrescriptionModalTitle.vue'
import PrescriptionModalBody from './prescription-modal/PrescriptionModalBody.vue'

const modal = ref<InstanceType<typeof BaseModal>>()

const { t } = useScopedI18n()

const closeModal = () => {
  if (modal.value) modal.value.close()
}

const { prescription, prescriptionPreview, confirm, cancel, isConfirming } =
  controller(closeModal)
</script>

<template>
  <BaseModal ref="modal" :is-loading="!prescription">
    <template #header>
      <PrescriptionModalTitle
        v-if="prescriptionPreview"
        :prescription-preview="prescriptionPreview"
      />
    </template>

    <div
      v-if="prescription?.couldNotBePersisted"
      class="p-5 bg-orange-100 text-orange-500 border-t border-b border-orange-200 text-center"
    >
      {{ t('error') }}
    </div>

    <PrescriptionModalBody
      v-if="prescriptionPreview"
      :prescription-preview="prescriptionPreview"
    />

    <template #footer>
      <div v-if="isConfirming" class="h-auto flex">
        <BaseLoader :is-loading="isConfirming" />
      </div>
      <div
        v-else
        class="flex flex-1 flex-col items-center justify-end space-y-5"
      >
        <div class="flex px-3 py-1 rounded bg-blue-100 text-blue-500">
          {{ t('warning') }}
        </div>
        <div class="flex flex-row space-x-5">
          <BaseSecondaryButton @click="cancel()">
            {{ t('cancelButton') }}
          </BaseSecondaryButton>
          <BasePrimaryButton @click="confirm()">
            {{ t('confirmButton') }}
          </BasePrimaryButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
