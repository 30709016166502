import { ComputedRef, Ref, watch, computed } from 'vue'
import { usePrescriptionStore } from 'Store/stores'
import { UIPrescriptionPreview } from '@/domains/prescription/ui-models'

export default function loadPrescriptionPreview(prescriptionId: Ref<string>): {
  prescriptionPreview: ComputedRef<UIPrescriptionPreview | null>
} {
  const prescriptionStore = usePrescriptionStore()

  const prescriptionPreview = computed(
    () => prescriptionStore.getPrescriptionPreview,
  )

  watch(
    () => prescriptionId,
    async (newValue, oldValue) => {
      if (newValue === oldValue) return
      await prescriptionStore.loadPrescriptionPreview(prescriptionId.value)
    },
    { immediate: true },
  )

  return {
    prescriptionPreview,
  }
}
