<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { EstimatedTimeSpent } from 'Models/medical-report'
import { listItems } from '@/utils/enum/list-items'

import BaseButtonRadioGroup from '@/components/base/BaseButtonRadioGroup.vue'

const props = defineProps({
  estimatedTimeSpent: {
    type: String as PropType<EstimatedTimeSpent>,
    required: false,
    default: EstimatedTimeSpent.Unknown,
  },
})

const emits = defineEmits<{
  (e: 'selectEstimatedTimeSpent', estimatedTimeSpent: EstimatedTimeSpent): void
}>()

const { t } = useScopedI18n()

const estimatedTimeSpentItems = computed(() =>
  listItems<typeof EstimatedTimeSpent, string>(
    EstimatedTimeSpent,
    'estimatedTimeSpent',
    EstimatedTimeSpent.Unknown,
  ),
)

const initialItem = computed({
  get: () => props.estimatedTimeSpent,
  set: (newItem) => emits('selectEstimatedTimeSpent', newItem),
})
</script>

<template>
  <div class="px-5">
    <div class="flex font-semibold">
      {{ t('title') }}
    </div>
    <main class="mt-1 mb-3">
      <BaseButtonRadioGroup
        v-model:initialItem="initialItem"
        :items="estimatedTimeSpentItems"
      />
    </main>
  </div>
</template>
