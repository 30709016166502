<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import { PanelPosition } from '@/domains/panel/store/types'
import { UIKidProfile } from '../../ui-models'

import PanelHeader from '@/views/layouts/panels/Header.vue'
import TitleAndSubTitleSlots from '@/components/panel/side/shared/TitleAndSubTitleSlots.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import KidDetails from './KidDetails.vue'
import BaseKidAvatar from '@/components/base/BaseKidAvatar.vue'
import KidMeasuresModalButton from '../kid-measures/modal/KidMeasuresModalButton.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Right>,
    required: true,
  },
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>
<template>
  <PanelHeader :panel-position="panelPosition" :can-close="false">
    <template #left>
      <div class="flex items-center justify-center h-full pl-5 pr-2">
        <BaseKidAvatar :width="38" :height="38" :sex="kidProfile.sex" />
      </div>
    </template>
    <TitleAndSubTitleSlots>
      <template #title>{{ $fullname(kidProfile) }}</template>
      <template #subTitle>
        <div class="flex flex-row">
          <div
            v-if="kidProfile.birthDate"
            v-tooltip.auto="
              t(`born.${kidProfile.sex}`, {
                date: $formatDate(kidProfile.birthDate, 'short'),
              })
            "
          >
            {{ $kidAge(kidProfile) }}
          </div>
          <div v-if="kidProfile.weight && kidProfile.weightDateTaken">
            &nbsp;|&nbsp;<span
              v-tooltip.auto="String(kidProfile.weightDateTaken)"
              >{{ kidProfile.weight }}</span
            >
          </div>
        </div>
      </template>
    </TitleAndSubTitleSlots>
    <template #right>
      <div class="px-4">
        <KidMeasuresModalButton :kid-profile="kidProfile" />
        <BaseDropdown
          button-custom-class="w-8 h-8 hover:bg-default-100 rounded"
          :placement="DropdownPlacement.BottomEnd"
        >
          <template #button>
            <font-awesome-icon
              class="focus:outline-none text-primary"
              :icon="['fal', 'info-circle']"
            />
          </template>

          <KidDetails :kid-profile="kidProfile" />
        </BaseDropdown>
      </div>
    </template>
  </PanelHeader>
</template>
