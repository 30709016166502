import i18nPlugin from '@/plugins/vue-use/i18n'
import {
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
} from 'date-fns'

export const distanceInWords = (
  date: Date,
  locale: string,
  today?: Date,
): string => {
  if (!today) today = new Date()
  const minutes = differenceInMinutes(today, date)
  const hours = differenceInHours(today, date)
  const days = differenceInDays(today, date)
  const i18n = i18nPlugin.global

  if (minutes < 1) {
    return i18n.t('timeago.lessAMinute', locale)
  } else if (hours < 1) {
    return i18n.tc('timeago.lessAHour', minutes, locale)
  } else if (hours < 24) {
    return i18n.tc('timeago.lessADay', hours, locale)
  } else return i18n.tc('timeago.greaterThanADay', days, locale)
}
