import { ActionTree } from './types'
import { useKidStore } from 'Store/stores'
import Services from 'Services/services'

export const resetCurrentKidParentRooms: ActionTree['resetCurrentKidParentRooms'] =
  async function () {
    if (!this.currentKidParentRooms) return

    const currentKidParent = useKidStore().currentKidParent

    if (!currentKidParent) return

    Services.getInstance().room.unlistenAllFromKidParent(currentKidParent.id)

    this.currentKidParentRooms = null
  }
