import defaultSanitizeHtml, { IOptions } from 'sanitize-html'
import { replaceEmojis } from './replace-emojis'
import { replaceURLs } from './replace-urls'

const SANITIZE_OPTIONS: IOptions = {
  allowedTags: ['span', 'br', 'a'],
  allowedAttributes: { span: ['class'], a: ['href', 'target'] },
}

const sanitize = (text: string): string => {
  return defaultSanitizeHtml(text, SANITIZE_OPTIONS)
}

export const sanitizeHtml = (text: string): string => {
  return sanitize(replaceEmojis(replaceURLs(text.replace(/\n/g, '<br/>')).text))
}
