<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

const { t } = useScopedI18n()
</script>
<template>
  <div
    class="flex flex-col h-screen items-center justify-center space-y-14 bg-default-900"
  >
    <font-awesome-icon
      :icon="['fas', 'wifi-slash']"
      class="text-7xl animate-bounce text-red-700"
    />
    <div class="text-xl text-default-400">
      {{ t('title') }}
    </div>
  </div>
</template>
