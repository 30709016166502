<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { Message } from 'Models/message'
import useModal from '@/hooks/modal'

defineProps({
  src: {
    type: String as PropType<Message['content']>,
    required: true,
  },
})

const {
  openModal: zoomImage,
  closeModal: unzoomImage,
  isModalOpen: isImageZoomed,
} = useModal()

const isZoomed = ref(false)

const toggleZoom = () => (isZoomed.value = !isZoomed.value)
</script>

<template>
  <div class="w-full h-full" @click="zoomImage">
    <slot />
  </div>

  <teleport v-if="isImageZoomed" to="#viewport">
    <div
      v-keydown:Escape="unzoomImage"
      class="absolute top-0 bottom-0 left-0 right-0 flex bg-default bg-opacity-60 z-30 cursor-zoom-out overflow-y-auto scrollbar p-2"
      @click.self="unzoomImage"
    >
      <button
        class="fixed top-0 right-0 flex items-center justify-center my-3 mx-5 w-8 h-8 focus:outline-none text-primary bg-default-100 rounded"
        @click="unzoomImage"
      >
        <font-awesome-icon
          :icon="['fal', 'times']"
          class="focus:outline-none text-2xl"
        />
      </button>
      <img
        class="m-auto h-full"
        :class="
          isZoomed
            ? 'cursor-zoom-out transform scale-[1.75] origin-top'
            : 'cursor-zoom-in'
        "
        :src="src"
        alt=""
        @click="toggleZoom"
      />
    </div>
  </teleport>
</template>
