import { isEmpty } from '@/utils/is-empty'
import { sortByPosition } from '@/utils/sort-by-position'
import { MRLItem } from 'Models/medical-report-library-item'
import { UIKidAntecedentCategory } from '@/domains/kid/ui-models'
import { buildUIMRLItem } from '@/domains/medical-report/store/getters/concerns/build-ui-medical-report-library-items'

export const buildAntecedentCategories = (
  library: MRLItem[],
  list: MRLItem[],
  hiddenAntecedentIds: string[],
  locale: string,
  idsAdded: string[], // NOTE: antecedents already added to the kidProfile
): UIKidAntecedentCategory[] => {
  const categoryNames = getCategoryNames(library, locale)
  const antecedentCategories = []

  for (const categoryName of categoryNames) {
    categoryName[1].antecedents = sortByPosition(list)
      .filter((item) => item.parentId === categoryName[0])
      .map((item, index) => {
        const isAlreadyAdded = idsAdded.includes(item.id)

        let hidden = false

        if (item.canBeHidden) hidden = true

        if (
          item.canBeHidden &&
          idsAdded.includes(item.id) &&
          !hiddenAntecedentIds.includes(item.id)
        )
          hidden = false

        return {
          ...buildUIMRLItem(
            item.id,
            item.title[locale],
            item.severity,
            item.canBeHidden,
            hidden,
            index,
            isAlreadyAdded,
          ),
        }
      })

    if (!isEmpty(categoryName[1].antecedents))
      antecedentCategories.push(categoryName[1])
  }

  return antecedentCategories
}

export const buildAntecedentCategoriesFromIds = (
  library: MRLItem[],
  antecedentIds: string[],
  hiddenAntecedentIds: string[],
  locale: string,
): UIKidAntecedentCategory[] => {
  const list: MRLItem[] = []
  antecedentIds.forEach((id) => {
    const item = library.find((item) => item.id === id)
    if (item) list.push({ ...item })
  })

  return buildAntecedentCategories(
    library,
    list,
    hiddenAntecedentIds,
    locale,
    list.map((item) => item.id),
  )
}

const getCategoryNames = (
  list: MRLItem[],
  locale: string,
): Map<string, UIKidAntecedentCategory> => {
  const categories = list.filter((cat) => isEmpty(cat.parentId))

  return new Map<string, UIKidAntecedentCategory>(
    sortByPosition(categories).map((category) => [
      category.id,
      { name: category.title[locale], antecedents: [] },
    ]),
  )
}
