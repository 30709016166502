import { RoutesNames } from '@/router/routes/type'
import { RouteRecordName } from 'vue-router'
import {
  PanelPosition,
  PanelsComponentNames,
} from '@/domains/panel/store/types'
import { PanelStoreActions } from '@/domains/panel/store/actions/types'

export const setPanelsFromRouteName = (
  routeName: RouteRecordName,
  nextPanel: PanelStoreActions['nextPanel'],
  killPanel: PanelStoreActions['killPanel'],
): void => {
  /* === Default === */
  // Left
  nextPanel(PanelsComponentNames.RoomList, {
    root: true,
  })
  // Middle
  nextPanel(PanelsComponentNames.Welcome, {
    root: true,
  })
  // Right
  killPanel(PanelPosition.Right)

  /* === Cases === */
  switch (routeName) {
    case RoutesNames.Prescriptions:
      // Left
      nextPanel(PanelsComponentNames.PrescriptionList, {
        root: true,
      })
      break
    case RoutesNames.PrescriptionDetail:
      // Left
      nextPanel(PanelsComponentNames.PrescriptionList, {
        root: true,
      })
      // Middle
      nextPanel(PanelsComponentNames.PrescriptionDetail, {
        root: true,
      })
      break
  }
}
