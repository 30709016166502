import { Agent } from 'Models/agent'
import { MedicalReport } from 'Models/medical-report'
import { Prescription } from 'Models/prescription'
import { Room } from 'Models/room'
import {
  IAgentService,
  IInceptionChatService,
  IMedicalReportService,
  IPrescriptionService,
  IRoomAssignmentService,
  IRoomService,
} from './__types__'

export default class RoomAssignmentService implements IRoomAssignmentService {
  roomService: IRoomService
  medicalReportService: IMedicalReportService
  prescriptionService: IPrescriptionService
  agentService: IAgentService
  inceptionChatService: IInceptionChatService

  constructor(
    roomService: IRoomService,
    medicalReportService: IMedicalReportService,
    prescriptionService: IPrescriptionService,
    agentService: IAgentService,
    inceptionChatService: IInceptionChatService,
  ) {
    this.roomService = roomService
    this.medicalReportService = medicalReportService
    this.prescriptionService = prescriptionService
    this.agentService = agentService
    this.inceptionChatService = inceptionChatService
  }

  async assign(
    room: Room,
    medicalReport: MedicalReport,
    prescription: Prescription,
    agent: Agent,
    assignedBy: Agent,
    supervisorAgentId: string,
  ): Promise<void> {
    if (agent.id === assignedBy.id) {
      await Promise.all([
        this.roomService.assign(room, agent, assignedBy),
        this.medicalReportService.update(medicalReport.id, { agent }),
        this.prescriptionService.persistToDraft(room.id, {
          ...prescription,
          doctorId: agent.isRegisteredDoctor ? agent.id : supervisorAgentId,
          agentId: agent.id,
        }),
        this.inceptionChatService.reOpen(room.id),
        this.inceptionChatService.changeOwner(room.id, agent.id, assignedBy.id),
      ])
    } else
      await Promise.all([
        this.roomService.assign(room, agent, assignedBy),
        this.inceptionChatService.reOpen(room.id),
      ])
  }

  async acceptAssignment(
    room: Room,
    medicalReport: MedicalReport,
    prescription: Prescription,
    supervisorAgentId: string,
  ): Promise<void> {
    if (!room.assignedAgent) return

    const agent = await this.agentService.load(room.assignedAgent.id)

    if (!agent)
      throw `Unkown agent ${room.assignedAgent.id} when accepting the room assignment`

    await Promise.all([
      this.roomService.acceptAssignment(room),
      this.medicalReportService.update(medicalReport.id, { agent }),
      this.prescriptionService.persistToDraft(room.id, {
        ...prescription,
        doctorId: agent.isRegisteredDoctor ? agent.id : supervisorAgentId,
        agentId: agent.id,
      }),
      this.inceptionChatService.changeOwner(
        room.id,
        agent.id,
        room.assignedBy?.id,
      ),
    ])
  }
  refuseAssignment(room: Room): Promise<void> {
    return this.roomService.refuseAssignment(room)
  }
}
