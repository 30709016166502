<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { RoutesNames } from '@/router/routes/type'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { useKidFamily } from '../../controllers/kid-family'

import TextSmall from '@/components/panel/side/shared/TextSmall.vue'
import TitleAndSubTitleSlots from '@/components/panel/side/shared/TitleAndSubTitleSlots.vue'
import BaseKidAvatar from '@/components/base/BaseKidAvatar.vue'

const props = defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
})

const { tc } = useScopedI18n()

const { kidFamily, isLoadingKidFamily } = useKidFamily(props.kidProfile.id)
</script>
<template>
  <font-awesome-icon
    v-if="isLoadingKidFamily"
    :icon="['fal', 'ellipsis-h']"
    class="ml-2 animate-ping-slow"
  />
  <div v-else class="flex flex-col">
    <TextSmall>{{ tc('parent', kidFamily.parents.length) }}</TextSmall>
    <ul>
      <li v-for="parent in kidFamily.parents" :key="parent.id">
        •
        <router-link
          :to="{
            name: RoutesNames.KidParent,
            params: { parentId: parent.id },
          }"
          class="text-primary"
        >
          {{ $fullname(parent) }}
        </router-link>
      </li>
    </ul>
    <div
      v-if="kidFamily.siblings.length > 1"
      class="border-t border-default-100 mt-3 pt-3"
    >
      <TextSmall>{{ tc('kid', kidFamily.siblings.length) }}</TextSmall>
      <ul class="space-y-3">
        <li
          v-for="sibling in kidFamily.siblings"
          :key="sibling.id"
          class="flex flex-row items-center space-x-5"
        >
          <BaseKidAvatar :width="35" :height="35" :sex="sibling.sex" />
          <TitleAndSubTitleSlots>
            <template #title>{{ $fullname(sibling) }}</template>
            <template #subTitle>
              <div
                v-if="sibling.birthDate"
                v-tooltip="$formatDate(sibling.birthDate, 'short')"
              >
                {{ $kidAge(sibling) }}
              </div>
            </template>
          </TitleAndSubTitleSlots>
        </li>
      </ul>
    </div>
  </div>
</template>
