import Credentials, {
  AuthInfo,
  ResetPasswordAttributes,
} from 'Models/credentials'
import { IAuthenticationService } from 'Services/__types__'
import { IFirebaseService } from 'External/firebase/types'
import { IAPIService, SignInResponse } from 'External/api/types'
import { ILocalStorageService } from 'External/local-storage/types'
import { parseJwt } from '@/utils/parse-jwt'

export default class AuthenticationService implements IAuthenticationService {
  // External services
  apiService: IAPIService
  firebaseService: IFirebaseService
  localStorageService: ILocalStorageService

  constructor(
    apiService: IAPIService,
    firebaseService: IFirebaseService,
    localStorageService: ILocalStorageService,
  ) {
    this.apiService = apiService
    this.firebaseService = firebaseService
    this.localStorageService = localStorageService
  }

  async localSignIn(): Promise<AuthInfo> {
    const jwtExpiresAt = this.localStorageService.getAPITokenExpiresAt()
    const apiToken = this.localStorageService.getAPIToken()
    const fbToken = this.localStorageService.getFirebaseToken()

    if (!jwtExpiresAt || !apiToken || !fbToken)
      throw 'No API/Firebase tokens in the localStorage'

    if (jwtExpiresAt < Date.now()) throw 'Expired API token'

    // ask Firebase if we're good to go with the current Firebase token
    const fbResult = await this.firebaseService.authenticate(fbToken)
    if (fbResult) {
      this.apiService.setJwt(apiToken)
      return {
        agentId: parseJwt(apiToken).id,
        apiToken,
        fbToken,
      }
    }

    // nope, one last try, ask the API for a new Firebase token
    const signInResponse = await this.apiService.refreshJwt(apiToken)
    if (signInResponse && signInResponse.token)
      return this.persistAuthInfo(signInResponse)

    // we weren't able to automatically sign in the agent
    throw 'Expired Firebase token'
  }

  async signIn(credentials: Credentials): Promise<AuthInfo> {
    let signInResponse: SignInResponse
    try {
      signInResponse = await this.apiService.signIn(credentials)
    } catch (error) {
      throw 'Invalid credentials or API down'
    }

    const fbResult = await this.firebaseService.authenticate(
      signInResponse.user.fbtoken,
    )
    if (!fbResult) throw 'Firebase refused to authenticate the agent'

    console.log('Agent signed in with success!')

    return this.persistAuthInfo(signInResponse)
  }

  async signInWithOAuth(token: string): Promise<AuthInfo> {
    let signInResponse: SignInResponse
    try {
      signInResponse = await this.apiService.signInWithOAuth(token)
    } catch (error) {
      throw 'Invalid OAuth token or API down'
    }

    const fbResult = await this.firebaseService.authenticate(
      signInResponse.user.fbtoken,
    )
    if (!fbResult) throw 'Firebase refused to authenticate the agent'

    console.log('Agent signed in with success!')

    return this.persistAuthInfo(signInResponse)
  }

  async signOut(): Promise<void> {
    await this.firebaseService.signOut()
    this.localStorageService.clearAPIToken()
    this.localStorageService.clearFirebaseToken()
    console.log('Signing out from Firebase')
  }

  async resetPassword(attributes: ResetPasswordAttributes): Promise<void> {
    try {
      await this.apiService.resetPassword(attributes)
    } catch (error) {
      throw 'Invalid token or agentId or API down'
    }
  }

  async changeCurrentPassword(newPassword: string): Promise<void> {
    try {
      await this.apiService.changeCurrentPassword(newPassword)
    } catch (error) {
      throw 'Invalid token or agentId or API down'
    }
  }

  protected persistAuthInfo(response: SignInResponse): AuthInfo {
    // notify the API service about the new token which will be passed from now on to every API request
    this.apiService.setJwt(response.token)

    // store in the local storage the 2 tokens required to sign in automatically
    // the agent if she refreshes the browser tab for instance
    this.localStorageService.setAPIToken(response.token)
    this.localStorageService.setFirebaseToken(response.user.fbtoken)

    return {
      agentId: response.user.id,
      fbToken: response.user.fbtoken,
      apiToken: response.token,
    }
  }
}
