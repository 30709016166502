<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useRoomStore, useSavedReplyStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import { PanelsComponentNames } from '@/domains/panel/store/types'
import useUploadImage from '@/hooks/upload-image'

import BaseButtonIcon from '@/components/base/BaseButtonIcon.vue'
import IllustrationsModalButton from '@/domains/knowledge-basis/components/illustrations/IllustrationsModalButton.vue'
import Text from '@/components/panel/middle/chat/footer/chat-area/actions/Text.vue'
import Emoji from './actions/Emoji.vue'

defineProps({
  hasMessage: {
    type: Boolean,
    required: true,
  },
  isSavedReplyPickerOpen: {
    type: Boolean,
    required: true,
  },
  spellcheckerEnabled: {
    type: Boolean,
    required: true,
  },
  isSpellChecking: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'sendText'): void
  (e: 'sendImage', image: File): void
}>()

const roomStore = useRoomStore()
const { toggleSavedReplyPicker } = useSavedReplyStore()
const { t } = useScopedI18n()

const file = ref()

const currentRoom = computed(() => roomStore.getCurrentRoom)

const openFileDialog = () => file.value.click()
const uploadImage = () => useUploadImage(file.value, sendImage)
const sendImage = (image: File) => emits('sendImage', image)
</script>

<template>
  <div v-if="currentRoom">
    <div class="flex items-center h-12 text-base text-primary">
      <div class="flex items-center w-full h-8">
        <div class="border-r border-default-100">
          <BaseButtonIcon
            v-tooltip.top="t('openSavedReplies')"
            class="flex flex-col space-y-1"
            @click.stop="toggleSavedReplyPicker"
          >
            <font-awesome-icon
              :icon="['fal', 'bolt']"
              class="focus:outline-none"
            />
            <div
              class="bg-primary h-px w-2"
              :class="isSavedReplyPickerOpen ? 'block' : 'hidden'"
            ></div>
          </BaseButtonIcon>
        </div>
        <div class="flex flex-row px-3 items-center">
          <Emoji v-bind="$attrs" />
          <BaseButtonIcon class="lg:px-2" @click="openFileDialog">
            <input
              ref="file"
              type="file"
              accept="image/*"
              class="hidden"
              @change="uploadImage"
            />
            <font-awesome-icon
              v-tooltip.auto="t('sendImage')"
              :icon="['fal', 'paperclip-vertical']"
              class="focus:outline-none"
              fixed-width
            />
          </BaseButtonIcon>
          <IllustrationsModalButton />
        </div>
        <div
          v-if="spellcheckerEnabled"
          class="border-l border-default-100 h-8 w-10 flex items-center justify-center text-default-light"
        >
          <font-awesome-icon
            v-if="isSpellChecking"
            :icon="['fal', 'ellipsis-h']"
            class="animate-ping-slow"
          />
          <font-awesome-icon
            v-else
            v-tooltip="t('spellcheckerEnabled')"
            :icon="['fal', 'spell-check']"
            class="text-green-500"
          />
        </div>
        <div
          class="border-l border-default-100 h-8 flex items-center pl-5 text-default-light"
        >
          <div
            v-if="currentRoom.isParentTyping"
            v-tooltip.left="
              t('parentTyping', {
                name: currentRoom.parent.firstName,
              })
            "
            class="flex flex-row items-center space-x-2"
          >
            <font-awesome-icon :icon="['fal', 'user-edit']" />
            <div class="hidden 2xl:flex text-xs leading-3 2xl:leading-4">
              {{
                t('parentTyping', {
                  name: currentRoom.parent.firstName,
                })
              }}
            </div>
          </div>
          <div
            v-else-if="currentRoom.hasParentRead"
            v-tooltip.left="
              t('parentHasRead', {
                name: currentRoom.parent.firstName,
              })
            "
            class="flex flex-row items-center space-x-2"
          >
            <font-awesome-icon
              class="text-green-500"
              :icon="['fal', 'check']"
            />
            <div class="hidden 2xl:flex text-xs leading-3 2xl:leading-4">
              {{
                t('parentHasRead', {
                  name: currentRoom.parent.firstName,
                })
              }}
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-end divide-x divide-default-100 text-sm flex-1 border-r border-default-100"
        >
          <Text
            v-if="$canOpenPrescription(currentRoom)"
            :component-name="PanelsComponentNames.Prescription"
            :content="t('prescription')"
          />
        </div>

        <BaseButtonIcon
          class="flex items-center space-x-3"
          :disabled="!hasMessage"
          :class="
            hasMessage ? 'text-primary' : 'text-default-200 cursor-default'
          "
          @click="emits('sendText')"
        >
          <div class="text-sm">{{ t('send') }}</div>
          <font-awesome-icon
            :icon="['fas', 'paper-plane']"
            class="focus:outline-none"
          />
        </BaseButtonIcon>
      </div>
    </div>
  </div>
</template>
