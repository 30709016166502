<script lang="ts" setup>
import { PropType } from 'vue'
import { Agent, AgentPickable } from 'Models/agent'
import { getAgentsByTitle } from '@/utils/agent/get-agents-by-title'

import AgentPickerCategory from './AgentPickerCategory.vue'

const props = defineProps({
  selectedAgent: {
    type: Object as PropType<AgentPickable | null>,
    required: false,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  showNumberOfRoomAssignedByAgent: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{
  (e: 'selectAgent', agent: Agent): void
}>()

const agentListByTitle = getAgentsByTitle(props.agents)
</script>

<template>
  <div class="overflow-y-auto scrollbar">
    <div v-for="(titleAgents, title, index) in agentListByTitle" :key="index">
      <AgentPickerCategory
        v-if="titleAgents.length > 0"
        :title="title"
        :selected-agent="selectedAgent"
        :agents="titleAgents"
        :show-counters="false"
        :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
        @on-select-agent="(agent) => emits('selectAgent', agent)"
      />
    </div>
  </div>
</template>
