export type FBAgentGroup = {
  id: string
  name: string
  agentIds: string[]
  addSystemAdministrators?: boolean
}

export type AgentGroup = {
  id: string
  name: string
  agentIds: string[]
  addSystemAdministrators: boolean
}

export enum BuiltInAgentGroups {
  SystemAdministrators = 'SystemAdministrators',
  ConversationsAssignees = 'ConversationsAssignees',
  ConversationsAssigners = 'ConversationsAssigners',
  BetaTesters = 'BetaTesters',
  MedicalReportAssignees = 'MedicalReportAssignees',
  MedicalReportAssigners = 'MedicalReportAssigners',
  PrescriptionInitiators = 'PrescriptionInitiators',
  PrescriptionErasers = 'PrescriptionErasers',
  PrescriptionReferrers = 'PrescriptionReferrers',
  RDLetterAdvancedModeAgents = 'RDLetterAdvancedModeAgents',
  SpellingBees = 'SpellingBees',
}
