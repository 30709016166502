<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { UIInceptionChatParticipant } from '../ui-models'

import BaseAvatarStack from '@/components/base/BaseAvatarStack.vue'

const props = defineProps({
  participants: {
    type: Object as PropType<UIInceptionChatParticipant[]>,
    required: true,
  },
})

const avatars = computed(() =>
  props.participants.map((participant) => ({
    type: 'agent' as 'agent' | 'user' | undefined,
    firstName: participant.firstName,
    lastName: participant.lastName,
    profilePic: participant.profilePic,
  })),
)
</script>

<template>
  <BaseAvatarStack :size="1" :avatars="avatars" />
</template>
