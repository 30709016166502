import { Room, RoomStatuses } from 'Models/room'
import i18n from '@/plugins/vue-use/i18n'
import { $fullname } from '@/plugins/vue-helpers/fullname'
import { formatDate } from '@/utils/format-date'
import { differenceInDays } from '@/utils/difference-in-days'
import { UIRoom } from '@/domains/room-list/ui-models'

export const buildUIRooms = (
  rooms: Room[],
  currentAgentId: string,
): UIRoom[] => {
  if (!rooms) return []
  return rooms.map((room) => buildUIRoom(room, currentAgentId))
}

export const buildUIRoom = (room: Room, currentAgentId: string): UIRoom => {
  let warningMessage: string | undefined
  switch (room.status.type) {
    case RoomStatuses.Closed:
      warningMessage = i18n.global.t('views.banner.closedWarning', {
        date: formatDate(room.status.updatedAt, 'long', i18n.global.locale),
        name: $fullname(room.status.agent),
      })
      break
    case RoomStatuses.Snoozed:
      if (room.status.snoozedUntil) {
        const snoozedUntilDays = differenceInDays(
          room.status.snoozedUntil,
          room.status.updatedAt,
        )
        warningMessage = i18n.global.t('views.banner.snoozedWarning', {
          date: formatDate(room.status.updatedAt, 'long', i18n.global.locale),
          name: $fullname(room.status.agent),
          snoozedUntilDays,
          snoozedUntilDate: formatDate(
            room.status.snoozedUntil,
            'long',
            i18n.global.locale,
          ),
        })
      }
      break
    default:
      warningMessage = undefined
      break
  }
  let isPayAsYouGo = false
  if (
    room.payment &&
    room.payment.status === 'OK' &&
    room.payment.type === 'CREDIT'
  ) {
    isPayAsYouGo = true
  }

  return {
    ...room,
    isBeingAssignedToMe:
      !!room.isBeingAssigned && room.assignedAgent?.id === currentAgentId,
    isNotAssignedToMe: room.assignedAgent
      ? room.assignedAgent?.id !== currentAgentId
      : true,
    isMarkedHasFavorite:
      !!room.markedByAgentsAsFavorite &&
      room.markedByAgentsAsFavorite.includes(currentAgentId),
    hasInceptionChatMessages: false,
    hasUnreadInceptionChatMessages: false,
    warningMessage,
    isPayAsYouGo,
  }
}
