<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { Agent } from 'Models/agent'
import { $fullname } from '@/plugins/vue-helpers/fullname'
import { $agentFullname } from '@/plugins/vue-helpers/agent-fullname'

import BaseAvatar from '@/components/base/BaseAvatar.vue'
import BaseWorkingDot from '@/components/base/BaseWorkingDot.vue'

const props = defineProps({
  agent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  counter: {
    type: Number,
    default: 0,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  query: {
    type: String,
    required: false,
  },
})

const isQuerying = computed(() => !!props.query && props.query.length > 0)
const fullName = computed(() =>
  isQuerying.value
    ? $fullname(props.agent, false)
    : $agentFullname(props.agent),
)
</script>

<template>
  <div
    class="flex justify-between items-center relative space-x-2 px-4 py-2"
    :class="!selected && 'cursor-pointer'"
  >
    <div class="flex items-center space-x-2 flex-nowrap">
      <BaseAvatar
        type="agent"
        :first-name="agent.firstName"
        :profile-pic="agent.profilePic"
        :size="2"
      />
      <BaseWorkingDot
        class="w-3 h-3 left-7 bottom-3"
        :is-working="agent.isWorking"
      />
      <div class="flex flex-col flex-1 pl-2">
        <span
          v-text-highlight="{ query, content: fullName }"
          class="font-semibold text-sm"
          :class="selected ? 'text-default' : 'text-primary'"
        />
        <span class="text-default-light text-xs whitespace-nowrap">{{
          $agentTitle(agent)
        }}</span>
      </div>
    </div>
    <div class="flex items-center space-x-2 flex-nowrap">
      <div>
        <font-awesome-icon
          v-if="selected"
          class="text-green-500"
          :icon="['fal', 'check']"
        />
      </div>
      <div v-if="counter > 0" class="text-default-light font-mono text-xs">
        ({{ counter }})
      </div>
    </div>
  </div>
</template>
