<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import useScopedI18n from '@/hooks/scoped-i18n'

import IconLogoLine from '@/components/icons/IconLogoLine.vue'

const { t, mt } = useScopedI18n()
</script>

<template>
  <div
    class="flex flex-col items-center justify-center h-screen hide-allowed-width px-20"
  >
    <div
      class="flex items-center justify-center p-3 overflow-hidden rounded-full"
      :class="$isProduction ? 'bg-primary' : 'bg-default-800'"
    >
      <IconLogoLine width="100" height="100" class="text-white" />
    </div>

    <div class="mt-10 px-5 py-2 bg-default-200 rounded text-center">
      <div class="font-bold text-xl">{{ t('disableOnSmallScreen.title') }}</div>
      <div
        class="font-medium text-base markdown"
        v-html="mt('disableOnSmallScreen.body')"
      />
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 1200px) {
  .hide-allowed-width {
    @apply hidden;
  }
}
</style>
