import { $isProduction } from '@/plugins/vue-helpers/environment'
import isEqual from 'lodash.isequal'
import { Room } from 'Models/room'

// NOTE: here is the whitelist that allows the firebase listener (room's collection) to trigger state updates
export const areRoomsEqual = (
  existingRoom: Room,
  newRoom: Room,
  currentAgentId: string,
): boolean => {
  // Prevent from updating the room if another agent reads it
  const areHaveAgentsReadArraysEqual =
    existingRoom.haveAgentsRead.includes(currentAgentId) ===
    newRoom.haveAgentsRead.includes(currentAgentId)

  const areMandatoryFieldsEqual =
    isEqual(existingRoom.assignedAgent, newRoom.assignedAgent) &&
    isEqual(existingRoom.parent, newRoom.parent) &&
    isEqual(existingRoom.lastMessage, newRoom.lastMessage) &&
    isEqual(existingRoom.assignedBy, newRoom.assignedBy) &&
    isEqual(existingRoom.hasParentRead, newRoom.hasParentRead) &&
    areHaveAgentsReadArraysEqual &&
    isEqual(existingRoom.isUnassigned, newRoom.isUnassigned) &&
    isEqual(existingRoom.isBeingAssigned, newRoom.isBeingAssigned) &&
    isEqual(existingRoom.isClosed, newRoom.isClosed) &&
    isEqual(existingRoom.kid, newRoom.kid) &&
    isEqual(existingRoom.status, newRoom.status) &&
    isEqual(existingRoom.prescriptions, newRoom.prescriptions) &&
    isEqual(existingRoom.hasPrescription, newRoom.hasPrescription) &&
    isEqual(
      existingRoom.markedByAgentsAsFavorite,
      newRoom.markedByAgentsAsFavorite,
    ) &&
    isEqual(
      existingRoom.lastParentThreadStartedAt,
      newRoom.lastParentThreadStartedAt,
    ) &&
    isEqual(existingRoom.lastHumanSender, newRoom.lastHumanSender)

  if (!$isProduction && !areMandatoryFieldsEqual) {
    console.warn(`[ROOM DIFF ${newRoom.id}]:`, {
      assignedAgent: isEqual(existingRoom.assignedAgent, newRoom.assignedAgent),
      parent: isEqual(existingRoom.parent, newRoom.parent),
      lastMessage: isEqual(existingRoom.lastMessage, newRoom.lastMessage),
      assignedBy: isEqual(existingRoom.assignedBy, newRoom.assignedBy),
      hasParentRead: isEqual(existingRoom.hasParentRead, newRoom.hasParentRead),
      haveAgentsRead: isEqual(
        existingRoom.haveAgentsRead,
        newRoom.haveAgentsRead,
      ),
      isUnassigned: isEqual(existingRoom.isUnassigned, newRoom.isUnassigned),
      isBeingAssigned: isEqual(
        existingRoom.isBeingAssigned,
        newRoom.isBeingAssigned,
      ),
      isClosed: isEqual(existingRoom.isClosed, newRoom.isClosed),
      kid: isEqual(existingRoom.kid, newRoom.kid),
      status: isEqual(existingRoom.status, newRoom.status),
      prescriptions: isEqual(existingRoom.prescriptions, newRoom.prescriptions),
      hasPrescription: isEqual(
        existingRoom.hasPrescription,
        newRoom.hasPrescription,
      ),
      markedByAgentsAsFavorite: isEqual(
        existingRoom.markedByAgentsAsFavorite,
        newRoom.markedByAgentsAsFavorite,
      ),
      lastParentThreadStartedAt: isEqual(
        existingRoom.lastParentThreadStartedAt,
        newRoom.lastParentThreadStartedAt,
      ),
    })
  }

  return areMandatoryFieldsEqual
}
