import { Agent } from './agent'
import { Kid } from './kid'
import { MedicalReport } from './medical-report'
import { Message } from './message'
import { Prescription } from './prescription'
import { Room } from './room'

export type RoomWorkspace = {
  roomId: string
  context: RoomWorkspaceContext
  room: Room | null
  kid: Kid | null
  messages: Message[] | null
  medicalReport: MedicalReport | null
  pastMedicalReports: MedicalReport[] | null
  prescription: Prescription | null
}

export type RoomWorkspaceContext = {
  currentAgent: Agent // the agent currently signed in
  supervisorAgentId: string // required to initialize a blank prescription
  maxPastMedicalReports: number
}

export type RoomWorkspaceEntityType =
  | Room
  | Kid
  | Message[]
  | MedicalReport
  | MedicalReport[]
  | Prescription
  | null

export enum RoomWorkspaceEntityName {
  Room = 'room',
  Kid = 'kid',
  Messages = 'messages',
  MedicalReport = 'medicalReport',
  PastMedicalReports = 'pastMedicalReports',
  Prescription = 'prescription',
}
