import { ActionTree } from './types'
import { isEmpty } from '@/utils/is-empty'
import Services from 'Services/services'

export const setBrandedDrugs: ActionTree['setBrandedDrugs'] =
  async function () {
    if (isEmpty(this.brandedDrugs)) {
      const brandedDrugs = await Services.getInstance().drug.loadBrandedDrugs()
      this.brandedDrugs = brandedDrugs
    }
  }
