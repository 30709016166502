import { ITimerService } from './types'

export default class TimerService implements ITimerService {
  intervals: Record<string, number>

  constructor() {
    this.intervals = {}
  }

  runEvery(key: string, callback: () => void, interval: number): void {
    if (this.intervals[key]) this.stop(key)
    this.intervals[key] = this.createInterval(callback, interval)
  }

  stop(key: string): void {
    this.clearInterval(this.intervals[key])
  }

  protected createInterval(callback: () => void, interval: number): number {
    return window.setInterval(callback, interval)
  }

  protected clearInterval(id: number): void {
    window.clearInterval(id)
  }
}
