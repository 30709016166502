<script lang="ts" setup>
import { PropType, ref, computed } from 'vue'
import { isEmpty } from '@/utils/is-empty'
import useScopedI18n from '@/hooks/scoped-i18n'
import {
  MentionSuggestionItem,
  getMentionSuggestionItemIds,
} from '@/lib/tiptap/extensions/mention-suggestion'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import { UIInceptionChatParticipant } from '../ui-models'

import RichTextEditor from '@/components/base/BaseRichTextEditor.vue'
import EmojiPicker from '@/components/base/BaseEmojiPicker.vue'
import IconButton from '@/components/base/BaseButtonIcon.vue'
import ParticipantList from '@/domains/inception-chat/components/ParticipantList.vue'

defineProps({
  agentsToMention: {
    type: Array as PropType<MentionSuggestionItem[]>,
    required: true,
  },
  participants: {
    type: Array as PropType<UIInceptionChatParticipant[]>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'sendText', html: string, mentionedAgentIds: string[]): void
}>()

const { t } = useScopedI18n()

const editor = ref<InstanceType<typeof RichTextEditor> | null>(null)
const message = ref<string>('')
const hasFocus = ref<boolean>(false)

const isMessagePresent = computed(() => !isEmpty(message.value))

const sendText = () => {
  const htmlContent = editor.value?.getHTML()
  const jsonContent = editor.value?.getJSON()
  if (!htmlContent || !jsonContent) return
  message.value = ''
  emits('sendText', htmlContent, getMentionSuggestionItemIds(jsonContent))
}

const insertEmoji = (emoji: string) => {
  if (editor.value) editor.value.insertText(emoji)
}
</script>
<template>
  <div
    class="border rounded transition-all duration-300"
    :class="{
      'border-purple-500 shadow-xl': hasFocus,
      'border-default-100': !hasFocus,
    }"
  >
    <RichTextEditor
      ref="editor"
      v-model="message"
      class="w-full p-0"
      :placeholder="t('placeholder')"
      :mentions="true"
      :mention-suggestions="agentsToMention"
      @send-text="sendText"
      @focus="hasFocus = true"
      @blur="hasFocus = false"
    />

    <hr class="mx-4 h-px bg-default-50 border-none" />

    <div class="py-3 flex items-center justify-between">
      <div class="flex items-center text-purple-500">
        <EmojiPicker
          :dropdown-placement="DropdownPlacement.TopEnd"
          @pick-emoji="insertEmoji"
        />
        <ParticipantList
          class="border-l border-default-200 pl-4"
          :participants="participants"
        />
      </div>

      <IconButton
        class="flex items-center space-x-3 transition-all duration-300"
        :disabled="!isMessagePresent"
        :class="
          isMessagePresent
            ? 'text-purple-500'
            : 'text-default-200 cursor-default'
        "
        @click="sendText"
      >
        <div class="text-sm">{{ t('send') }}</div>
        <font-awesome-icon
          :icon="['fas', 'paper-plane']"
          class="focus:outline-none"
        />
      </IconButton>
    </div>
  </div>
</template>
