import { FBKid, Kid, KidGender } from 'Models/kid'

export const convertFrom = (fbKid: FBKid): Kid => {
  const kid: Kid = {
    id: fbKid.id,
    firstName: fbKid.firstName,
    lastName: fbKid.lastName,
    birthDate: fbKid.birthDate.toDate(),
    sex: fbKid.gender === 0 ? KidGender.Female : KidGender.Male,
    antecedentIds: null,
    hiddenAntecedentIds: null,
    antecedents: null,
    parentIds: fbKid.userIds,
  }

  return kid
}
