import { GetterTree } from './types'
import { buildUIBrandedDrugs } from './concerns/build-ui-branded-drug'
import { useAgentStore, useKidStore } from 'Store/stores'
import Services from 'Services/services'

export const getBrandedDrugs: GetterTree['getBrandedDrugs'] = function ({
  brandedDrugs,
}) {
  return (query) => {
    const currentKid = useKidStore().currentKid

    if (!brandedDrugs || !currentKid) return null

    const filteredBrandedDrugs = Services.getInstance().drug.filterBrandedDrugs(
      brandedDrugs,
      currentKid,
      useAgentStore().getCurrentAgentGroupIds || [],
      query,
    )

    return buildUIBrandedDrugs(filteredBrandedDrugs)
  }
}
