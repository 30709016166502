<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { RoutesNames } from '@/router/routes/type'
import {
  UIPrescriptionListFilter,
  UIPrescriptionSummary,
} from '@/domains/prescription/ui-models'
import { Agent } from 'Models/agent'
import { PanelPosition } from '@/domains/panel/store/types'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import HeaderTitle from '@/components/panel/shared/header/Title.vue'
import Header from '@/views/layouts/panels/Header.vue'
import PrescriptionListFilter from './PrescriptionListFilter.vue'
import PrescriptionListItems from './PrescriptionListItems.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  prescriptionId: {
    type: String,
    required: false,
  },
  prescriptions: {
    type: Object as PropType<UIPrescriptionSummary[] | null>,
    required: false,
  },
  filter: {
    type: Object as PropType<UIPrescriptionListFilter>,
    required: true,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  prescriptionInitiatorAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  prescriptionReferrerAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <Header
    :panel-position="panelPosition"
    :to-route-name="RoutesNames.Main"
    :can-prev="false"
  >
    <template #default>
      <HeaderTitle>{{ t('title') }}</HeaderTitle>
    </template>
  </Header>
  <Body class="flex-1" v-bind="$attrs">
    <PrescriptionListFilter
      :filter="filter"
      :current-agent="currentAgent"
      :prescription-initiator-agents="prescriptionInitiatorAgents"
      :prescription-referrer-agents="prescriptionReferrerAgents"
      v-bind="$attrs"
    />
    <div class="flex-1 overflow-y-scroll scrollbar">
      <PrescriptionListItems
        v-if="prescriptions"
        :prescriptions="prescriptions"
        :prescription-id="prescriptionId"
        v-bind="$attrs"
      />
      <Loader v-else :panel-position="panelPosition" />
    </div>
  </Body>
</template>
