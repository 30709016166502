<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import { computed, PropType, ref, watch } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { $formatDate } from '@/plugins/vue-helpers/format-date'
import { RoomStatuses } from 'Models/room'
import { Agent } from 'Models/agent'
import { UIRoom } from '../ui-models'
import { MessageTypes } from 'Models/message'
import { useRouteIntoTab } from '../controllers/route-into-tab'
import { useScrollToRoom } from '../controllers/scroll-to-room'

import Parent from './room-summary/Parent.vue'
import BaseAvatar from '@/components/base/BaseAvatar.vue'
import ReadOnlyPrescriptionModalButton from '@/domains/prescription/components/ReadOnlyPrescriptionModalButton.vue'
import BaseBadge from '@/components/base/BaseBadge.vue'

const props = defineProps({
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
  currentRoomId: {
    type: String as PropType<string | null>,
  },
  currentAgent: {
    type: Object as PropType<Agent | null>,
    required: true,
  },
})
const emits = defineEmits<{
  (e: 'openRoom', roomId: string, targetBlank: boolean): void
}>()

const { t } = useScopedI18n()

const hover = ref(false)
const roomSummary = ref()

const {
  isTabClosed,
  isTabSnoozed,
  isTabKidParent,
  isTabUnassigned,
  isTabMe,
  isTabInceptionChat,
} = useRouteIntoTab()
const { scrollToRoom } = useScrollToRoom(roomSummary)

const isClosed = computed(() => props.room.status.type === RoomStatuses.Closed)
const isImage = computed(
  () => props.room.lastMessage?.type === MessageTypes.Image,
)
const isVideo = computed(
  () => props.room.lastMessage?.type === MessageTypes.Video,
)
const isActive = computed(() => props.currentRoomId === props.room.id)
const unreadByAgent = computed(
  () =>
    props.room.assignedAgent &&
    props.currentAgent &&
    props.room.assignedAgent.id === props.currentAgent.id &&
    !props.room.haveAgentsRead.includes(props.room.assignedAgent.id),
)

watch(
  () => isActive.value,
  async (newIsActive) => {
    if (newIsActive) scrollToRoom()
  },
  { immediate: true },
)
</script>

<template>
  <article
    v-if="room && room.lastMessage"
    ref="roomSummary"
    class="relative flex flex-row text-left"
    :class="!isActive && 'cursor-pointer'"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div v-if="isActive" class="flex w-1 pl-1 ml-px bg-primary" />

    <main class="flex flex-col flex-1 max-w-full pt-1">
      <header
        class="flex flex-row items-center pl-3"
        :class="hover ? 'pr-3' : 'pr-5'"
      >
        <div class="flex flex-1 my-2">
          <!-- Prescription -->
          <ReadOnlyPrescriptionModalButton
            v-if="room.hasPrescription"
            v-tooltip.bottom="t('tooltipPrescription')"
            :prescription-id="room.prescriptions[0].id"
            class="flex w-6 pt-px pl-px cursor-pointer focus:outline-none text-primary"
          >
            <font-awesome-icon :icon="['fal', 'clipboard-prescription']" />
          </ReadOnlyPrescriptionModalButton>
          <Parent :hover="hover" :is-active="isActive" :room="room" />
          <BaseBadge
            v-if="room.isPayAsYouGo"
            class="h-4 py-0 ml-2 font-normal text-xxs"
            :style="{
              marginTop: '0.15rem',
              paddingLeft: '0.35rem',
              paddingRight: '0.35rem',
              borderRadius: '0.40rem',
            }"
            >{{ t('payAsYouGo') }}</BaseBadge
          >
        </div>

        <div class="flex py-2 text-xs truncate text-default-400">
          <!-- date (mm/dd/yy) based on snoozedUntil -->
          <time
            v-if="(isTabSnoozed || isTabKidParent) && room.status.snoozedUntil"
          >
            {{ $formatDate(room.status.snoozedUntil) }}
          </time>

          <!-- date (mm/dd/yy) based on room updatedAt -->
          <time
            v-else-if="isClosed && !isTabClosed"
            v-tooltip.bottom="$formatTime(room.status.updatedAt)"
          >
            {{ $formatDate(room.status.updatedAt) }}
          </time>

          <!-- time (hh:mm) based on room updatedAt -->
          <time
            v-else-if="isTabClosed"
            v-tooltip.bottom="$formatDate(room.status.updatedAt)"
          >
            {{ $formatTime(room.status.updatedAt) }}
          </time>

          <!-- timeago based on room lastParentThreadStartedAt or lastMessage createdAt or updatedAt -->
          <TimeAgo
            v-else-if="isTabUnassigned"
            v-tooltip.bottom="
              $formatTime(
                room.lastParentThreadStartedAt ??
                  room.lastMessage.createdAt ??
                  room.status.updatedAt,
              )
            "
            :date="
              room.lastParentThreadStartedAt ??
              room.lastMessage.createdAt ??
              room.status.updatedAt
            "
          />

          <TimeAgo
            v-else-if="
              isTabInceptionChat && room.inceptionChatMessagesUpdatedAt
            "
            v-tooltip.bottom="$formatTime(room.inceptionChatMessagesUpdatedAt)"
            :date="room.inceptionChatMessagesUpdatedAt"
          />

          <!-- timeago based on lastMessage createdAt -->
          <TimeAgo
            v-else-if="room.lastMessage.createdAt"
            v-tooltip.bottom="$formatTime(room.lastMessage.createdAt)"
            :date="room.lastMessage.createdAt"
          />
        </div>

        <button
          v-tooltip.top="t('tooltipOpenRoom')"
          :class="
            hover
              ? 'opacity-100 transition-opacity duration-500'
              : 'absolute top-0 right-0 opacity-0'
          "
          class="flex p-2 text-xs text-primary"
          @click.stop="emits('openRoom', room.id, true)"
        >
          <font-awesome-icon :icon="['fal', 'external-link']" />
        </button>
      </header>

      <main
        class="relative flex flex-row items-center max-w-full pb-4 ml-3 mr-5 space-x-2 overflow-hidden truncate"
      >
        <BaseAvatar
          v-tooltip.bottom="$fullname(room.lastMessage.sender)"
          :type="$messageSenderTypeToAvatarType(room.lastMessage.sender.type)"
          :first-name="room.lastMessage.sender.firstName"
          :last-name="room.lastMessage.sender.lastName"
          :profile-pic="room.lastMessage.sender.profilePic"
          :size="1"
        />

        <div class="flex flex-1 text-sm truncate shrink">
          <!-- Image -->
          <div v-if="isImage">
            <font-awesome-icon :icon="['fas', 'image']" /> Image
          </div>

          <!-- Video -->
          <div v-else-if="isVideo">
            <font-awesome-icon :icon="['fas', 'play-circle']" />
            {{ t('video') }}
          </div>

          <!-- Text -->
          <div
            v-else
            class="flex flex-row items-center space-x-1 overflow-hidden shrink overflow-ellipsis"
          >
            <div
              class="h-5 pr-2 overflow-hidden truncate shrink overflow-ellipsis last-message"
              v-html="$htmlToMobileContent(room.lastMessage?.content)"
            />
          </div>
        </div>
        <div class="flex flex-row items-center justify-end space-x-2">
          <!-- Blue dot (unread by agent) -->
          <div v-if="unreadByAgent" class="mr-1">
            <div
              v-tooltip.bottom="t('tooltipYouUnread')"
              class="flex items-center justify-center w-2 h-2 rounded-full bg-primary max-h-min"
            />
          </div>
          <div
            v-if="room.hasUnreadInceptionChatMessages && isTabMe"
            class="mr-1"
          >
            <div
              v-tooltip.bottom="t('tooltipYouUnreadInceptionChat')"
              class="flex items-center justify-center w-2 h-2 bg-purple-500 rounded-full max-h-min"
            />
          </div>
          <div v-if="isActive" class="text-primary">
            <font-awesome-icon :icon="['far', 'chevron-double-right']" />
          </div>
        </div>
      </main>
    </main>
  </article>
</template>
<style scoped>
.last-message {
  font-family: ui-sans-serif, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
</style>
