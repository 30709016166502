import { App } from '@vue/runtime-dom'
import TimeAgoComponent from './component'

export default {
  install: (app: App): void => {
    app.component('TimeAgo', TimeAgoComponent)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TimeAgo: typeof TimeAgoComponent
  }
}
