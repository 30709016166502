export enum DoctorTitleKey {
  GeneralPractitioner = 'generalPractitioner',
  Pediatrician = 'pediatrician',
  PediatricSurgeon = 'pediatricSurgeon',
  Psychiatrist = 'psychiatrist',
  ChildPsychiatrist = 'childPsychiatrist',
  Obstetrician = 'obstetrician',
  Gynecologist = 'gynecologist',
}

export enum NonDoctorTitleKey {
  Nurse = 'nurse',
  PediatricNurse = 'pediatricNurse',
  PediatricNurseAssistant = 'pediatricNurseAssistant',
  Midwife = 'midwife',
  Psychologist = 'psychologist',
  ChildPsychologist = 'childPsychologist',
  Founder = 'founder',
  Developer = 'developer',
  Designer = 'designer',
  MedicalDirector = 'medicalDirector',
}

export enum MedicalTeamTitleKeys {
  GeneralPractitioner = 'generalPractitioner',
  Pediatrician = 'pediatrician',
  PediatricSurgeon = 'pediatricSurgeon',
  Psychiatrist = 'psychiatrist',
  ChildPsychiatrist = 'childPsychiatrist',
  Obstetrician = 'obstetrician',
  Gynecologist = 'gynecologist',
  Nurse = 'nurse',
  PediatricNurse = 'pediatricNurse',
  PediatricNurseAssistant = 'pediatricNurseAssistant',
  Midwife = 'midwife',
  Psychologist = 'psychologist',
  ChildPsychologist = 'childPsychologist',
  MedicalDirector = 'medicalDirector',
}

export type TitleKey = DoctorTitleKey | NonDoctorTitleKey

export interface AgentPickable {
  id: string
  firstName: string
  lastName: string
  profilePic?: string | null
  gender: AgentGender
}

export type Agent = CoreAgent & {
  isAccountDisabled: boolean
  isDoctor: boolean
  isRegisteredDoctor: boolean
  isWorking: boolean
  numberOfRooms: number
}
export type FBAgent = CoreAgent & {
  isDisabled?: boolean
  professionalAddress?: string
  professionalPhone?: string
}

// !!!CoreAgent must not be exported!!!
type CoreAgent = {
  id: string
  firstName: string
  lastName: string
  email: string
  titleKey: TitleKey
  title: string // deprecated but we still need it to please the iOS app
  gender: AgentGender
  profilePic: string
  locale?: string
  rpps?: string
  adeli?: string
  bio: {
    en: string
    fr: string
  }
}

export enum AgentGender {
  Female = 'female',
  Male = 'male',
}

export enum AgentTitle {
  Doctor = 'doctor',
  Nurse = 'nurse',
}

export const DEFAULT_PROFILE_PIC_URL = String(
  import.meta.env.VITE_DEFAULT_PROFILE_PIC_URL,
)
