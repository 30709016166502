<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

const { t } = useScopedI18n()
</script>

<template>
  <div
    class="h-full w-full flex items-center justify-center text-center opacity-50"
  >
    <p class="text-base px-4">{{ t('title') }}</p>
  </div>
</template>
