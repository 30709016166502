export enum FBConfigDocumentNames {
  Api = 'api',
  Desk = 'desk',
  Dynamic = 'dynamic',
  Static = 'static',
}

export type Config = {
  dynamic: DynamicConfig | null
  desk: DeskConfig | null
  systemError: boolean
}

export type FBDynamicConfig = {
  paywall: boolean
  drowning: boolean
  almostDrowning: boolean
  closedToday: boolean
  closing: boolean
  closingMessageAt: {
    h: number
    m: number
  }
  covid: boolean
  fakeWaitingTime: {
    max: number
    min: number
  }
  open: boolean
  openingHours: {
    closeAt: number
    openAt: number
  }
  waitingTime: {
    max: number
    min: number
  }
}

export type DynamicConfig = {
  paywall: boolean
  drowning: boolean
  almostDrowning: boolean
  closedToday: boolean
  closing: boolean
  closingMessageAt: {
    h: number
    m: number
  }
  covid: boolean
  fakeWaitingTime: {
    max: number
    min: number
  }
  open: boolean
  openingHours: {
    closeAt: number
    openAt: number
  }
  waitingTime: {
    max: number
    min: number
  }
}

export type FBDeskConfig = {
  version: number
  supervisorAgent: string
  semversion: string
}

export type DeskConfig = {
  version: number
  supervisorAgent: string
  semversion: string
}
