import { ActionTree } from './types'
import { Prescription } from 'Models/prescription'
import { useRoomStore } from 'Store/stores'
import Services from 'Services/services'

export const generateCurrentPrescriptionPreview: ActionTree['generateCurrentPrescriptionPreview'] =
  async function () {
    const currentRoom = useRoomStore().currentRoom

    if (!this.currentPrescription || !currentRoom) return

    const modifiedPrescription: Prescription = { ...this.currentPrescription }

    try {
      const preview = await Services.getInstance().prescription.buildPreview(
        currentRoom.id,
        this.currentPrescription,
      )
      modifiedPrescription.pdfFileInBase64 = preview.pdfFileInBase64
      modifiedPrescription.mobile = preview.mobile
    } catch {
      // TODO: try/catch if we can get a preview of the prescription. In that case, we would display a nice error message
    }

    this.currentPrescription = modifiedPrescription
  }
