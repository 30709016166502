import isBefore from '@/utils/is-before'
import { BuiltInAgentGroups } from 'Models/agent-group'
import { Room, RoomStatuses } from 'Models/room'
import BasePolicy from './base'

export default class RoomPolicy extends BasePolicy {
  selfAssign(room: Room): boolean {
    if (room.status.type == RoomStatuses.Closed) return false

    if (room.isUnassigned)
      return this.isMemberOf(BuiltInAgentGroups.ConversationsAssignees)

    return false
  }

  assign(room: Room): boolean {
    if (room.status.type == RoomStatuses.Closed) return false

    if (this.isAdmin()) return true

    if (room.isUnassigned)
      return this.isMemberOf(BuiltInAgentGroups.ConversationsAssigners)

    if (room.assignedAgent?.id === this.agent.id) return true

    if (
      !room.isUnassigned &&
      !this.isSimpleMemberOf(BuiltInAgentGroups.ConversationsAssigners)
    )
      return false

    return true
  }

  close(room: Room): boolean {
    if (room.status.type == RoomStatuses.Closed) return false

    if (this.isAdmin()) return true

    if (room.assignedAgent?.id === this.agent.id) return true

    return false
  }

  snooze(room: Room): boolean {
    if (
      room.status.type == RoomStatuses.Open &&
      room.status.snoozedUntil &&
      !isBefore(room.status.snoozedUntil, new Date())
    ) {
      return true
    }
    if (room.status.type == RoomStatuses.Open) return true

    return false
  }

  cancelPendingAssignment(room: Room): boolean {
    return (
      !room.isUnassigned &&
      this.isWaitingForAssignment(room) &&
      (this.isAdmin() || this.isAssignedByUser(room))
    )
  }

  isWaitingForAssignment(room: Room): boolean {
    return (
      !room.isClosed &&
      !!room.assignedAgent &&
      !room.assignedAgent.assignmentAccepted
    )
  }

  isAssignedByUser(room: Room): boolean {
    return room.assignedBy?.id === this.agent.id
  }
}
