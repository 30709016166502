<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useAgentStore } from 'Store/stores'
import useServices from '@/hooks/services'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import { storeToRefs } from 'pinia'
import { useAsyncRunner } from '@/hooks/async-runner'
import { UITimeline } from '@/domains/timeline/ui-models'
import { buildUITimeline } from '@/domains/timeline/concerns'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import HeaderTitle from '@/components/panel/shared/header/Title.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'
import AssignmentHistoryList from './AssignmentHistoryList.vue'

const props = defineProps({
  roomId: {
    type: String,
    required: true,
  },
  iconClass: {
    type: String,
    required: false,
    default: 'text-xl',
  },
})

const { t } = useScopedI18n()
const services = useServices()
const { run, isRunning: isTimelineLoading } = useAsyncRunner()
const { agents } = storeToRefs(useAgentStore())

const dropdown = ref<InstanceType<typeof BaseDropdown>>()
const timeline = ref<UITimeline | null>(null)

watch(
  () => dropdown.value?.isDropdownOpen,
  async (isDropdownOpen) => {
    if (!isDropdownOpen || !agents.value) return

    timeline.value = buildUITimeline(
      agents.value,
      await run(() => services.medicalReport.getTimeline(props.roomId)),
    )
  },
)
</script>

<template>
  <BaseDropdown
    ref="dropdown"
    :placement="DropdownPlacement.LeftStart"
    :reduced-height="true"
    :offset-distance="10"
    :scroll="false"
    class="flex-1 w-full justify-end"
    style="display: flex"
    container-class="min-w-[24.3rem]"
  >
    <template #button="{ isOpen }">
      <div
        class="cursor-pointer"
        :class="{
          'font-bold': isOpen,
          'text-primary': !isOpen,
        }"
      >
        <font-awesome-icon :icon="['fal', 'list-tree']" :class="iconClass" />
      </div>
    </template>

    <template #default>
      <HeaderTitle class="px-5 py-3">{{ t('label') }}</HeaderTitle>
      <BaseLoader v-if="isTimelineLoading" class="py-10" :is-loading="true" />
      <AssignmentHistoryList
        v-else-if="
          timeline &&
          timeline.assignmentHistory &&
          timeline.assignmentHistory.length > 0
        "
        :assignment-history="timeline.assignmentHistory"
      />
      <div v-else class="p-3 text-center">{{ t('noHistory') }}</div>
    </template>
  </BaseDropdown>
</template>
