<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseAccordion from '@/components/base/BaseAccordion.vue'

defineProps({
  year: {
    type: Number,
    required: true,
  },
  counter: {
    type: Number,
    required: true,
  },
  thisYear: {
    type: Number,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
  showCounters: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <BaseAccordion
    class="border-default-100"
    header-classes="py-5"
    button-classes="pr-5"
    :force-active="isActive"
  >
    <template #title>
      <div>
        {{ year === thisYear ? t('title') : year }}
      </div>
      <div
        v-if="showCounters"
        class="pl-1 text-sm text-default-light font-normal"
      >
        ({{ counter }})
      </div>
    </template>
    <div class="pb-5">
      <slot />
    </div>
  </BaseAccordion>
</template>
