<script lang="ts" setup>
import { PropType } from 'vue'
import { Agent } from 'Models/agent'
import { UIPlanningSlots } from '@/domains/planning/ui-models'
import controller from '../../../controllers/show-agent-list'

import PlanningModalSlots from './PlanningModalSlots.vue'
import PlanningModalAgents from './PlanningModalAgents.vue'

defineProps({
  slots: {
    type: Object as PropType<UIPlanningSlots>,
    required: true,
  },
  now: {
    type: Date,
    required: true,
  },
  agentPicked: {
    type: Object as PropType<Agent | null>,
    required: false,
  },
})

const {
  showAgents,
  selectedSlot,
  agentsType,
  onOpenAgentList,
  onCloseAgentsList,
  onSelectAgents,
} = controller()
</script>

<template>
  <div class="flex flex-row flex-1 h-full overflow-y-hidden">
    <PlanningModalSlots
      :slots="slots"
      :now="now"
      :agent-picked="agentPicked"
      @open-agent-list="onOpenAgentList"
    />
    <PlanningModalAgents
      v-if="showAgents"
      :planning-slot="selectedSlot"
      :agents-type="agentsType"
      @close-agents-list="onCloseAgentsList"
      @select-agents="onSelectAgents"
    />
  </div>
</template>
