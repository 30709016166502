<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { isEmpty } from '@/utils/is-empty'
import { RoomsGroupedByAgent, UIRoom } from '@/domains/room-list/ui-models'

import RoomsByAgent from '@/domains/room-list/components/lists/shared/RoomsByAgent.vue'
import NoRooms from './NoRooms.vue'
import RoomCardList from '@/domains/room-list/components/lists/shared/RoomCardList.vue'

const props = defineProps({
  hasAgentPicked: {
    type: Boolean,
    required: false,
  },
  roomsByAgent: {
    type: Object as PropType<RoomsGroupedByAgent[]>,
    required: true,
  },
  unassignedRooms: {
    type: Object as PropType<UIRoom[]>,
    required: false,
  },
})

const { tc } = useScopedI18n()

const hasRooms = computed(
  () =>
    !isEmpty(props.roomsByAgent) ||
    (!isEmpty(props.unassignedRooms) && !props.hasAgentPicked),
)
</script>

<template>
  <div
    v-if="hasRooms"
    class="flex flex-col flex-grow overflow-y-scroll scrollbar overflow-x-hidden space-y-3 px-5 pt-3 pb-5"
  >
    <RoomCardList
      v-if="unassignedRooms && !hasAgentPicked && unassignedRooms.length > 0"
      :title="tc('unassigned', unassignedRooms.length)"
      :list="unassignedRooms"
      color="white"
    >
      <template #icon>
        <font-awesome-icon :icon="['fal', 'clipboard-list']" class="mr-1" />
      </template>
    </RoomCardList>

    <RoomsByAgent
      v-for="({ rooms, agent }, index) in roomsByAgent"
      :key="index"
      :rooms="rooms"
      :assigned-agent="agent"
    />
  </div>
  <NoRooms v-else />
</template>
