<script lang="ts" setup>
import {
  computed,
  inject,
  watchEffect,
  getCurrentInstance,
  ComponentInternalInstance,
} from 'vue'
import { TabsStateKey } from './symbols'

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  position: {
    type: Number,
    required: false,
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
  hidden: {
    type: Boolean,
    default: false,
  },
})

const instance = getCurrentInstance()
const tabsState = inject(TabsStateKey)

const index = computed(() => {
  const tabs = tabsState?.tabs?.value
  if (!tabs) return -1
  return tabs.findIndex(
    (target: ComponentInternalInstance) => target.uid === instance?.uid,
  )
})

const indexOrName = computed(() => props.name || index.value)
const isActive = computed(() => indexOrName.value === tabsState?.active.value)

const hasTitleSlot = () => !!instance?.slots?.title
const renderTitleSlot = () =>
  instance?.slots?.title ? instance.slots.title() : null
defineExpose({ hasTitleSlot, renderTitleSlot })

watchEffect(() => {
  if (!tabsState?.tabs.value || !instance || index.value !== -1) return

  const tabs = [...(tabsState.tabs.value || [])]

  // the position is supposed to be required for Tab components that might be mounted asynchronously (if they own a v-if for instance)
  if (props.position === undefined || props.position === null)
    tabs.push(instance)
  else tabs.splice(props.position, 0, instance)

  tabsState.tabs.value = tabs
})
</script>

<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>
