import { ActionTree } from './types'
import { useAgentStore } from 'Store/stores'
import Services from 'Services/services'

export const snoozeRoom: ActionTree['snoozeRoom'] = async function (
  snoozedUntil,
  assignedAgent,
) {
  const currentAgent = useAgentStore().currentAgent

  if (!this.currentRoom || !currentAgent || !snoozedUntil) return

  Services.getInstance().roomSnoozing.snooze(
    this.currentRoom,
    currentAgent,
    snoozedUntil,
    assignedAgent,
  )
}
