<script lang="ts" setup>
import { computed, onBeforeMount, PropType, ref } from 'vue'
import { useKidStore, usePanelStore, usePrescriptionStore } from 'Store/stores'
import { $kidAge } from '@/plugins/vue-helpers/kid-age'
import { UIBrandedDrug } from '@/domains/prescription/ui-models'
import {
  PanelPosition,
  PanelsComponentNames,
} from '@/domains/panel/store/types'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import AddBrandedDrug from '@/components/panel/side/left/prescription/AddBrandedDrug.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
})

const kidStore = useKidStore()
const { nextPanel } = usePanelStore()
const { setBrandedDrugs } = usePrescriptionStore()
const prescriptionStore = usePrescriptionStore()

const query = ref('')

const kidProfile = computed(() => kidStore.getCurrentKidProfile)
const prescription = computed(() => prescriptionStore.getCurrentPrescription)
const kidWeight = computed(() => prescription.value?.kidWeightInKg)
const kidAgeToday = computed(
  () => kidProfile.value && $kidAge(kidProfile.value),
)
const brandedDrugs = computed(() =>
  prescriptionStore.getBrandedDrugs(query.value),
)

const onSelect = (brandedDrug: UIBrandedDrug): void =>
  nextPanel(PanelsComponentNames.AddDosage, {
    data: { prescriptedDrug: { brandedDrug } },
  })

onBeforeMount(async () => await setBrandedDrugs())
</script>

<template>
  <Container :panel-position="panelPosition">
    <AddBrandedDrug
      v-if="brandedDrugs && kidWeight && kidAgeToday"
      v-model:query="query"
      :branded-drugs="brandedDrugs"
      :kid-weight="kidWeight"
      :kid-age-today="kidAgeToday"
      :panel-position="panelPosition"
      @select="onSelect"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
