import { useRoomListStore } from 'Store/stores'
import { watch } from 'vue'
import { triggersIconAndSound } from './concerns/triggers-icon-and-sound'

export const roomListEffectsPlugin = () => {
  watch(
    () => useRoomListStore().getRoomListAssigned,
    (roomList, oldRoomList) => {
      triggersIconAndSound(roomList, oldRoomList)
    },
    { immediate: true },
  )
}
