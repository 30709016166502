import { IAPIService } from 'External/api/types'
import { convertSetFrom } from 'Models/converters/kid-measure-converter'
import { KidMeasureSet } from 'Models/kid'
import { IKidMeasureService } from './__types__'

export default class KidMeasureService implements IKidMeasureService {
  // dependencies
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    this.apiService = apiService
  }

  async loadAll(kidId: string): Promise<KidMeasureSet> {
    const response = await this.apiService.getKidMeasures(kidId)
    return convertSetFrom(response)
  }
}
