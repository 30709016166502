import { UIRoom } from '@/domains/room-list/ui-models'

export const removeDuplicateRooms = (
  existingRooms: UIRoom[],
  newRooms: UIRoom[],
): UIRoom[] =>
  existingRooms.filter(
    (existingRoom) =>
      !newRooms.some((newRoom) => newRoom.id === existingRoom.id),
  )
