import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth'
import { FirebaseApp, initializeApp } from 'firebase/app'

import {
  getFirestore,
  collection as getFirestoreCollection,
  getDocs,
  Firestore,
  WithFieldValue,
  DocumentData,
  CollectionReference,
  DocumentReference,
  Query,
  onSnapshot,
  getDoc,
  updateDoc,
  doc,
  writeBatch,
  WriteBatch,
  Unsubscribe,
  UpdateData,
} from 'firebase/firestore'
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  FirebaseStorage,
} from 'firebase/storage'
import { getDatabase, Database } from 'firebase/database'
import { v4 as uuidv4 } from 'uuid'
import { IFirebaseService, ListenerChangeType } from 'External/firebase/types'
import { firebaseConverter } from 'External/firebase/helpers'
import defaultConfig from 'External/firebase/config'
import inGroupsOf from '@/utils/array/in-groups-of'

export default class FirebaseService implements IFirebaseService {
  app: FirebaseApp
  db: Firestore // Firestore database (main database)
  database: Database // Real-time database (legacy one)
  storage: FirebaseStorage
  listeners: Record<string, Array<Unsubscribe>>

  constructor(config: typeof defaultConfig = defaultConfig) {
    this.app = initializeApp(config)
    this.db = getFirestore(this.app)
    this.database = getDatabase(this.app)
    this.storage = getStorage(this.app)
    this.listeners = {}
  }

  async authenticate(token: string): Promise<boolean> {
    try {
      await signInWithCustomToken(getAuth(this.app), token)
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(
        'Firebase Auth ERROR',
        'code =',
        error.code,
        ', message = ',
        error.message,
      )
      return false
    }
  }

  async signOut(): Promise<void> {
    this.detachListeners()
    await signOut(getAuth(this.app))
  }

  protected getDB(): Firestore {
    return this.db
  }

  protected getStorage(): FirebaseStorage {
    return this.storage
  }

  getCollection<S, D>(
    name: string | string[],
    converterFrom: (source: S) => D,
    converterTo?: (attributes: WithFieldValue<D>) => DocumentData,
  ): CollectionReference<D> {
    const pathSegments = typeof name === 'string' ? [name] : (name as string[])
    const collection = getFirestoreCollection(
      this.getDB(),
      pathSegments[0],
      ...pathSegments.slice(1),
    )
    return collection.withConverter(
      firebaseConverter<S, D>(converterFrom, converterTo),
    )
  }

  getRawDocument(name: string | string[], id: string): DocumentReference {
    const pathSegments = typeof name === 'string' ? [name] : (name as string[])
    pathSegments.push(id)
    return doc(this.getDB(), pathSegments[0], ...pathSegments.slice(1))
  }

  getDocument<S, D>(
    name: string | string[],
    id: string,
    converterFrom: (source: S) => D,
    converterTo?: (attributes: WithFieldValue<D>) => DocumentData,
  ): DocumentReference<D> {
    const docReference = this.getRawDocument(name, id)
    return docReference.withConverter(
      firebaseConverter<S, D>(converterFrom, converterTo),
    )
  }

  getNewDocument<S, D>(
    name: string | string[],
    converterFrom: (source: S) => D,
    converterTo?: (attributes: WithFieldValue<D>) => DocumentData,
  ): DocumentReference<D> {
    return doc(this.getCollection(name, converterFrom, converterTo))
  }

  batchWrites(operations: (batch: WriteBatch) => void): Promise<void> {
    const batch = writeBatch(this.getDB())
    operations(batch)
    return batch.commit()
  }

  listenDocument<T>(
    key: string,
    query: DocumentReference<T>,
    onChange: (record: T) => void,
  ): void {
    const listener = onSnapshot(query, (snapshot) => {
      const data = snapshot.data()
      // NOTE: we don't deal with a non existing record
      if (data) onChange({ ...data, id: snapshot.id })
    })
    this.registerListener(key, listener)
  }

  async listenCollection<T>(
    key: string,
    query: Query<T>,
    onChange: (records: T[], changeType?: ListenerChangeType) => void,
    atomicChanges = false,
  ): Promise<void> {
    const listener = onSnapshot(query, (querySnapshot) => {
      const records: T[] = []

      let changeType: ListenerChangeType | undefined
      if (atomicChanges) {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            records.push({ ...change.doc.data(), id: change.doc.id })
            changeType = 'added'
          }
          if (change.type === 'modified') {
            records.push({ ...change.doc.data(), id: change.doc.id })
            changeType = 'modified'
          }
          if (change.type === 'removed') {
            records.push({ ...change.doc.data(), id: change.doc.id })
            changeType = 'removed'
          }
        })
        return onChange(records, changeType)
      }

      querySnapshot.forEach((snapshot) => {
        records.push({ ...snapshot.data(), id: snapshot.id })
      })
      onChange(records)
    })
    this.registerListener(key, listener)
  }

  unlisten(key: string): void {
    const listeners = this.listeners[key]
    if (!listeners) return
    listeners.forEach((unsubscribe) => unsubscribe())
    delete this.listeners[key]
  }

  async loadDocument<T>(query: DocumentReference<T>): Promise<T | undefined> {
    return getDoc(query).then((docSnapshot) => {
      if (!docSnapshot.exists) return undefined
      const record = docSnapshot.data()
      if (!record) return undefined
      return { ...record, id: docSnapshot.id }
    })
  }

  loadDocuments<T>(query: Query<T>): Promise<T[] | undefined> {
    return getDocs(query).then((querySnapshot) => {
      const records: T[] = []
      querySnapshot.forEach((doc) => {
        records.push({ ...doc.data(), id: doc.id })
      })
      return records
    })
  }

  async loadDocumentsByIds<T extends { id: string }>(
    ids: string[],
    buildQuery: (ids: string[]) => Query<T>,
    batchSize = 10,
  ): Promise<T[]> {
    // since we're limited to a max of 10 different ids by Firebase regarding the IN query,
    // we have to batch them
    const groupsOfIds = inGroupsOf<string>(ids, batchSize)

    const documents = (
      await Promise.all(
        groupsOfIds.map((ids) => this.loadDocuments(buildQuery(ids))),
      )
    ).flat()

    // keep the same order as in the original array of ids
    const orderedDocuments: T[] = []

    ids.forEach((id) => {
      const doc = documents.find((localDoc) => localDoc?.id === id)
      if (doc) orderedDocuments.push(doc)
    })

    return orderedDocuments
  }

  updateDocument<T>(
    name: string | string[],
    id: string,
    changes: UpdateData<T>,
  ): Promise<void> {
    const pathSegments = typeof name === 'string' ? [name] : (name as string[])
    pathSegments.push(id)

    const converterFrom = (source: T): T => source

    const docReference = doc(
      this.getDB(),
      pathSegments[0],
      ...pathSegments.slice(1),
    ).withConverter(firebaseConverter<T, T>(converterFrom))

    return updateDoc(docReference, changes)
  }

  uploadPicture(file: File, folder: string): Promise<string | undefined> {
    const extension = file.name.split('.').pop() || '.jpg'
    const filename = `${folder}/${uuidv4()}.${extension}`
    const url = `attachments/${filename}`
    return this.uploadFile(file, url)
  }

  // NOTE: this function can't be unit tested because of https://github.com/jsdom/jsdom/issues/2555.
  // To be more precise, the Firebase SDK is not able to deal with the Blob implementation of the JSDOM package.
  uploadFile(file: File, url: string): Promise<string | undefined> {
    const storageRef = ref(this.getStorage(), url)
    return uploadBytes(storageRef, file).then((snapshot) =>
      getDownloadURL(snapshot.ref),
    )
  }

  detachListeners(): void {
    console.log(
      'detaching FB listeners',
      Object.values(this.listeners).flat().length,
    )
    Object.values(this.listeners)
      .flat()
      .forEach((unsubscribe) => unsubscribe())
  }

  private registerListener(key: string, listener: Unsubscribe): void {
    this.listeners[key] ||= []
    this.listeners[key].push(listener)
  }
}
