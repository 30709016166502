import { Agent } from 'Models/agent'
import { Planning } from 'Models/planning'
import { getPlanningSlotAgents } from './get-planning-slot-agents'

export const getAgentsInPlanningSlot = (
  agents: Agent[],
  planning: Planning,
  hourToAddFromNow = 0, // current slot by default
): Agent[] => {
  const now = new Date().getHours()
  const slotAgentIds = getPlanningSlotAgents(
    agents,
    planning,
    now + hourToAddFromNow,
  ).map((agent) => agent.id)

  return agents.filter((agent) => slotAgentIds.includes(agent.id))
}
