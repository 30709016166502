<script lang="ts" setup>
import { PropType } from 'vue'
import { RoutesNames } from '@/router/routes/type'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useRouteIntoTab } from '@/domains/room-list/controllers/route-into-tab'
import { UIRoomListAwake } from '../../ui-models'

defineProps({
  roomListAwake: {
    type: Object as PropType<UIRoomListAwake>,
    required: true,
  },
})

const { t } = useScopedI18n()

const { isTabSnoozed } = useRouteIntoTab()
</script>

<template>
  <router-link
    v-tooltip="t('title')"
    :to="{
      name: RoutesNames.RoomsSnoozed,
    }"
    :class="
      isTabSnoozed ? 'cursor-default shadow-inner' : 'hover:bg-default-50'
    "
    class="flex flex-col"
  >
    <div
      class="flex flex-1 items-center justify-center"
      :class="isTabSnoozed && 'pt-1'"
    >
      <div class="relative flex flex-row items-center">
        <div class="flex flex-row items-center space-x-2">
          <font-awesome-icon :icon="['fal', 'alarm-clock']" class="text-lg" />
          <div
            class="flex pt-1 font-mono rounded-full items-center justify-center w-6 h-6 text-sm font-semibold"
            :class="
              isTabSnoozed || roomListAwake.counter === 0
                ? 'bg-gray-100 text-default'
                : 'bg-primary text-white'
            "
          >
            <div class="flex pb-1">
              {{ roomListAwake.counter }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Border-bottom -->
    <div v-if="isTabSnoozed" class="border-b-2 border-primary w-full" />
  </router-link>
</template>
