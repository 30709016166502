import { GetterTree } from './types'
import { getPanelPosition } from '@/utils/get-panel-position'

export const isPanelActive: GetterTree['isPanelActive'] = function () {
  return (panelComponentName) => {
    const position = getPanelPosition(panelComponentName)
    const currentComponentName = this.getPanelName(position)

    return currentComponentName === panelComponentName
  }
}
