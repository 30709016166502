import { keywordConverter } from '@/utils/keyword-converter'
import { SavedReply } from 'Models/saved-reply'
import { UISavedReply } from '@/domains/saved-reply/ui-models'
import { AvailableLocales } from '@/locales'
import { KidGender } from 'Models/kid'

export const buildUISavedReply = (
  savedReply: SavedReply,
  locale: AvailableLocales,
  parentFirstName: string,
  kidFirstName: string,
  kidSex: KidGender,
): UISavedReply => {
  const {
    id,
    title,
    image,
    isImage,
    content,
    published,
    parentId,
    leaf,
    position,
    agentGroupIds,
  } = savedReply

  const localizedTitle = title[locale]
  const localizedContent = content[locale]
  const localizedImage = isImage ? image[locale] : undefined

  return {
    id,
    title: localizedTitle,
    image: localizedImage,
    isImage,
    content: isImage
      ? localizedContent
      : keywordConverter(
          localizedContent,
          parentFirstName,
          kidFirstName,
          kidSex,
        ),
    published,
    parentId,
    leaf,
    position,
    agentGroupIds,
  }
}

export const buildUISavedReplies = (
  savedReplies: SavedReply[],
  uiSavedReplies: UISavedReply[],
  locale: AvailableLocales,
  parentFirstName: string,
  kidFirstName: string,
  kidSex: KidGender,
): UISavedReply[] => {
  for (const savedReply of savedReplies) {
    if (savedReply.published)
      uiSavedReplies.push(
        buildUISavedReply(
          savedReply,
          locale,
          parentFirstName,
          kidFirstName,
          kidSex,
        ),
      )
  }
  return uiSavedReplies
}
