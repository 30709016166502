import { instance as honeybadger } from '@/plugins/vue-use/honeybadger'
import { ref } from 'vue'

export const useAsyncRunner = () => {
  const isRunning = ref(false)
  const hasError = ref(false)

  const run = async <T>(fn: () => Promise<T>): Promise<T | null> => {
    try {
      isRunning.value = true
      const data = await fn()
      isRunning.value = false
      return data
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      honeybadger?.notify(error as any)
      hasError.value = true
      isRunning.value = false
      return null
    }
  }

  return {
    isRunning,
    hasError,
    run,
  }
}
