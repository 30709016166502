import { GetterTree } from './types'
import { buildUIPrescription } from './concerns/build-ui-prescription'
import { useAgentStore, useRoomStore } from 'Store/stores'

export const getCurrentPrescription: GetterTree['getCurrentPrescription'] =
  function ({ currentPrescription }) {
    const currentRoom = useRoomStore().currentRoom
    const agents = useAgentStore().agents

    if (!currentPrescription || !currentRoom || !agents) return null
    return buildUIPrescription(
      currentPrescription,
      agents,
      currentRoom.kid.lastName,
    )
  }
