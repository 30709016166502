import { State } from './types'

export const state: State = {
  currentMedicalReport: null,
  pastMedicalReports: null,
  antecedents: null,
  causes: null,
  diagnosis: null,
  directions: null,
  supervisions: null,
}
