import {
  APIKidParentProblematic,
  FBKidParent,
  KidParent,
  KidParentProblematic,
} from 'Models/kid'

export const convertFrom = (fbKidParent: FBKidParent): KidParent => {
  return {
    id: fbKidParent.id,
    firstName: fbKidParent.firstName,
    lastName: fbKidParent.lastName,
  }
}

export const convertProblematicFromAPI = (
  problematic: APIKidParentProblematic,
): KidParentProblematic => {
  return {
    isProblematic: problematic.isProblematic,
    reason: problematic.reason,
  }
}
