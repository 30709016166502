<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { Agent } from 'Models/agent'
import { UICoreChatMessage } from '@/domains/core-chat/ui-models'

import CoreChatMessage from '@/domains/core-chat/components/CoreChatMessage.vue'
import CoreChatMessageHeader from '@/domains/core-chat/components/CoreChatMessageHeader.vue'
import BaseMessagesFromRobot from './base/BaseMessagesFromRobot.vue'

const props = defineProps({
  agent: {
    type: Object as PropType<Agent | null>,
    required: false,
  },
  message: {
    type: Object as PropType<UICoreChatMessage>,
    required: true,
  },
  useTimeAgo: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const messages = computed(() => [props.message, ...props.message.burstMessages])
const isPostedByRobot = computed(
  () =>
    props.message.sender.type !== 'agent' &&
    props.message.sender.type !== 'user',
)
</script>
<template>
  <BaseMessagesFromRobot
    v-if="isPostedByRobot"
    :messages="messages"
    :posted-at="message.postedAt"
  />
  <CoreChatMessageHeader
    v-else
    :agent="agent"
    :sender="message.sender"
    :posted-at="message.postedAt"
    :use-time-ago="useTimeAgo"
  >
    <div class="mt-px space-y-px">
      <CoreChatMessage
        v-for="(msg, index) in messages"
        :key="`MSG-${msg.id}-${index}`"
        class="rounded-r-xl rounded-l-sm"
        :class="{
          'rounded-tl-xl': index === 0,
          'rounded-bl-xl': index === messages.length - 1,
        }"
        :message-id="msg.id"
        :message-type="msg.type"
        :message-content="msg.content"
        :message-sender-type="msg.sender.type"
      />
    </div>
  </CoreChatMessageHeader>
</template>
