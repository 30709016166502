import { ActionTree } from './types'

import { addCurrentMedicalReportLibraryItem } from './add-current-medical-report-library-item'
import { completeCurrentMedicalReportWithSavedReply } from './complete-current-medical-report-with-saved-reply'
import { removeCurrentMedicalReportLibraryItem } from './remove-current-medical-report-library-item'
import { loadPastMedicalReports } from './load-past-medical-reports'
import { updateCurrentMedicalReport } from './update-current-medical-report'
import { updatePastMedicalReports } from './update-past-medical-reports'
import { changeCurrentMedicalReport } from './change-current-medical-report'

export const actions: ActionTree = {
  addCurrentMedicalReportLibraryItem,
  completeCurrentMedicalReportWithSavedReply,
  removeCurrentMedicalReportLibraryItem,
  loadPastMedicalReports,
  updateCurrentMedicalReport,
  updatePastMedicalReports,
  changeCurrentMedicalReport,
}
