import { GetterTree } from './types'
import { MRLStrictTopics } from 'Models/medical-report-library-item'
import { useAgentStore, useKidStore, useUIStore } from 'Store/stores'
import {
  buildUIMRLCategories,
  buildUIMRLItems,
} from './concerns/build-ui-medical-report-library-items'
import Services from 'Services/services'

export const getAvailableMedicalReportLibraryItems: GetterTree['getAvailableMedicalReportLibraryItems'] =
  function ({
    currentMedicalReport,
    causes,
    diagnosis,
    directions,
    supervisions,
  }) {
    return (topic, query) => {
      const library = Services.getInstance().medicalReport.getMRLibrary(topic, {
        causes,
        diagnosis,
        directions,
        supervisions,
      })
      const locale = useUIStore().locale
      const currentKid = useKidStore().currentKid

      if (!currentMedicalReport || !currentKid || !library || !locale)
        return null

      const allItems = Services.getInstance().medicalReportLibrary.filter(
        library,
        query,
        currentKid,
        useAgentStore().getCurrentAgentGroupIds,
        locale,
      )

      const allItemIds = allItems.map((item) => item.id)

      const itemIdsAlreadyAdded =
        Services.getInstance().medicalReport.getMRLItemIds(
          topic,
          currentMedicalReport,
        )

      // NOTE: Supervisions and Directions are not organized in categories
      if (
        topic === MRLStrictTopics.Supervisions ||
        topic === MRLStrictTopics.InitialDirections ||
        topic === MRLStrictTopics.Directions
      ) {
        return [
          {
            name: 'root',
            items:
              buildUIMRLItems(
                allItemIds,
                library,
                locale,
                itemIdsAlreadyAdded,
              ) || [],
          },
        ]
      } else
        return (
          buildUIMRLCategories(
            allItemIds,
            library,
            locale,
            itemIdsAlreadyAdded,
            currentMedicalReport.visibleDiagnosticIds,
          ) || null
        )
    }
  }
