/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from 'pinia'
import { actions as authActions } from '@/domains/auth/store/actions/index'
import { actions as roomActions } from '@/domains/room/store/actions/index'
import { actions as prescriptionActions } from '@/domains/prescription/store/actions/index'
import { actions as kidActions } from '@/domains/kid/store/actions/index'
import { actions as medicalReportActions } from '@/domains/medical-report/store/actions/index'
import {
  TrackAgentActionNames,
  TrackAgentResourceTypes,
} from 'Models/track-agent'
import { buildEnum } from '@/utils/enum/build-enum'
import { AuthStore } from '@/domains/auth/store/types'
import { RoomStore } from '@/domains/room/store/types'
import { PrescriptionStore } from '@/domains/prescription/store/types'
import { KidStore } from '@/domains/kid/store/types'
import { MedicalReportStore } from '@/domains/medical-report/store/types'
import { useRoomStore } from 'Store/stores'
import Services from 'Services/services'

const AuthActionNames = buildEnum(authActions)
const RoomActionNames = buildEnum(roomActions)
const PrescriptionActionNames = buildEnum(prescriptionActions)
const KidActionNames = buildEnum(kidActions)
const MedicalReportActionNames = buildEnum(medicalReportActions)

export const trackAgentPlugin = async (
  store: Store | AuthStore | PrescriptionStore | KidStore | MedicalReportStore,
  name: string,
  args: any[],
  after: (callback: (resolvedReturn: any) => any) => void,
) => {
  const services = Services.getInstance()

  // Before
  switch (name) {
    case AuthActionNames.SignOut:
      await services.trackAgent.do(TrackAgentActionNames.SignOut)
      break
    default:
      break
  }

  // After
  after(async () => {
    const isAuthenticated = (store as AuthStore).isAuthenticated
    const currentRoom = (store as RoomStore).currentRoom

    switch (name) {
      // Auth
      case AuthActionNames.SignIn:
        if (isAuthenticated)
          await services.trackAgent.do(TrackAgentActionNames.SignIn)
        break
      case AuthActionNames.LocalSignIn:
        if (isAuthenticated)
          await services.trackAgent.do(TrackAgentActionNames.LocalSignIn)
        break

      // Room
      case RoomActionNames.SetCurrentRoom:
        await services.trackAgent.do(
          TrackAgentActionNames.View,
          TrackAgentResourceTypes.Room,
          args[0],
        )
        break
      case RoomActionNames.AssignCurrentRoom:
        if (currentRoom)
          await services.trackAgent.do(
            TrackAgentActionNames.AssignAgent,
            TrackAgentResourceTypes.Room,
            currentRoom?.id,
          )
        break
      case RoomActionNames.UnassignCurrentRoom:
        if (currentRoom)
          await services.trackAgent.do(
            TrackAgentActionNames.UnassignAgent,
            TrackAgentResourceTypes.Room,
            currentRoom?.id,
          )
        break
      case RoomActionNames.AcceptCurrentRoomAssignment:
        if (currentRoom)
          await services.trackAgent.do(
            TrackAgentActionNames.AcceptAssignment,
            TrackAgentResourceTypes.Room,
            currentRoom?.id,
          )
        break
      case RoomActionNames.CloseRoom:
        if (currentRoom)
          await services.trackAgent.do(
            TrackAgentActionNames.CloseRoom,
            TrackAgentResourceTypes.Room,
            currentRoom?.id,
          )
        break
      case RoomActionNames.ReOpenRoom:
        if (currentRoom)
          await services.trackAgent.do(
            TrackAgentActionNames.ReOpenRoom,
            TrackAgentResourceTypes.Room,
            currentRoom?.id,
          )
        break
      case RoomActionNames.SnoozeRoom:
        if (currentRoom)
          await services.trackAgent.do(
            TrackAgentActionNames.SnoozeRoom,
            TrackAgentResourceTypes.Room,
            currentRoom?.id,
          )
        break
      case RoomActionNames.RefuseCurrentRoomAssignment:
        if (currentRoom)
          await services.trackAgent.do(
            TrackAgentActionNames.RefuseAssignment,
            TrackAgentResourceTypes.Room,
            currentRoom?.id,
          )
        break

      // Prescription
      case PrescriptionActionNames.CompleteCurrentPrescription:
        await services.trackAgent.do(
          TrackAgentActionNames.Create,
          TrackAgentResourceTypes.Prescription,
          useRoomStore().currentRoom?.id,
        )
        break
      case PrescriptionActionNames.InitPrescriptionListFilter:
        await services.trackAgent.do(
          TrackAgentActionNames.View,
          TrackAgentResourceTypes.Prescriptions,
          undefined,
          { ...args[1] },
        )
        break
      case PrescriptionActionNames.ChangePrescriptionListFilter:
        await services.trackAgent.do(
          TrackAgentActionNames.View,
          TrackAgentResourceTypes.Prescriptions,
          undefined,
          args[0],
        )
        break

      // Kid
      case KidActionNames.AddOrUpdateCurrentKidAntecedent:
        await services.trackAgent.do(
          TrackAgentActionNames.AddAntecedents,
          TrackAgentResourceTypes.Kid,
          (store as KidStore).currentKid?.id,
          {
            id: args[0].id,
          },
        )
        break
      case KidActionNames.RemoveCurrentKidAntecedent:
        await services.trackAgent.do(
          TrackAgentActionNames.RemoveAntecedents,
          TrackAgentResourceTypes.Kid,
          (store as KidStore).currentKid?.id,
          { id: args[0] },
        )
        break

      // Medical Report
      case MedicalReportActionNames.AddCurrentMedicalReportLibraryItem:
        await services.trackAgent.do(
          TrackAgentActionNames.Update,
          TrackAgentResourceTypes.MedicalReport,
          (store as MedicalReportStore).currentMedicalReport?.id,
          {
            topic: args[0],
            addedItemId: args[1].id,
          },
        )
        break
      case MedicalReportActionNames.RemoveCurrentMedicalReportLibraryItem:
        await services.trackAgent.do(
          TrackAgentActionNames.Update,
          TrackAgentResourceTypes.MedicalReport,
          (store as MedicalReportStore).currentMedicalReport?.id,
          {
            topic: args[0],
            addedItemId: args[1],
          },
        )
        break
      case MedicalReportActionNames.CompleteCurrentMedicalReportWithSavedReply:
        await services.trackAgent.do(
          TrackAgentActionNames.Update,
          TrackAgentResourceTypes.MedicalReport,
          (store as MedicalReportStore).currentMedicalReport?.id,
          {
            savedReplyId: args[1],
          },
        )
        break
      default:
        break
    }
  })
}
