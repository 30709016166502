import { IAPIService } from 'External/api/types'
import { convertFromAPI } from 'Models/converters/kid-antecedent-converter'
import { APIKidAntecedentForm, KidAntecedent } from 'Models/kid-antecedent'
import { IKidAntecedentService } from './__types__'

export default class KidAntecedentService implements IKidAntecedentService {
  // dependencies
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    this.apiService = apiService
  }

  async loadKidAntecedents(kidId: string): Promise<KidAntecedent[]> {
    const response = await this.apiService.getKidAntecedents(kidId)

    return convertFromAPI(response)
  }

  async addOrUpdateKidAntecedent(
    kidId: string,
    antecedent: APIKidAntecedentForm,
  ): Promise<void> {
    return await this.apiService.postOrUpdateKidAntecedent(kidId, antecedent)
  }

  async removeKidAntecedent(
    kidId: string,
    antecedentId: string,
  ): Promise<void> {
    return await this.apiService.removeKidAntecedent(kidId, antecedentId)
  }
}
