<script lang="ts" setup>
import { PropType } from 'vue'
import { usePanelStore } from 'Store/stores'
import { RoomStatuses } from 'Models/room'
import { usePanelController } from './controllers/panel'
import { useRoute } from 'vue-router'

// NOTE: those props are coming from route params
defineProps({
  roomId: {
    type: String,
    required: false,
  },
  parentId: {
    type: String,
    required: false,
  },
  roomsStatus: {
    type: String as PropType<RoomStatuses>,
    required: false,
  },
})

const panelStore = usePanelStore()
const { leftPanelName, middlePanelName, rightPanelName } = usePanelController(
  useRoute(),
  panelStore.nextPanel,
  panelStore.killPanel,
  panelStore.getPanelName,
)
</script>

<template>
  <main id="main" class="flex flex-row flex-1 max-h-full overflow-hidden">
    <!-- Left Panel -->
    <KeepAlive>
      <component
        :is="leftPanelName"
        v-if="$route.meta.keepAlive"
        panel-position="left"
        v-bind="$props"
      />
    </KeepAlive>
    <component
      :is="leftPanelName"
      v-if="!$route.meta.keepAlive"
      panel-position="left"
      v-bind="$props"
    />

    <!-- Middle Panel -->
    <KeepAlive>
      <component
        :is="middlePanelName"
        v-if="$route.meta.keepAlive"
        panel-position="middle"
        v-bind="$props"
      />
    </KeepAlive>
    <component
      :is="middlePanelName"
      v-if="!$route.meta.keepAlive"
      panel-position="middle"
      v-bind="$props"
    />

    <!-- Right Panel -->
    <KeepAlive>
      <component
        :is="rightPanelName"
        v-if="$route.meta.keepAlive"
        panel-position="right"
        v-bind="$props"
      />
    </KeepAlive>
    <component
      :is="rightPanelName"
      v-if="!$route.meta.keepAlive"
      panel-position="right"
      v-bind="$props"
    />
  </main>
</template>
