/* Vue plugin */
import { App } from '@vue/runtime-dom'
import { PanelsComponentNames } from '@/domains/panel/store/types'

// Left
import RoomList from '@/domains/room-list/views/RoomList.vue'
import Prescription from '@/views/left/Prescription.vue'
import AddBrandedDrug from '@/views/left/prescription/AddBrandedDrug.vue'
import AddDosage from '@/views/left/prescription/AddDosage.vue'
import AddDuration from '@/views/left/prescription/AddDuration.vue'
import MediaViewer from '@/views/left/MediaViewer.vue'
import LeftError from '@/domains/errors/views/LeftError.vue'
import KidParentRoomList from '@/domains/room-list/views/KidParentRoomList.vue'
import PrescriptionList from '@/views/left/PrescriptionList.vue'

// Middle
import Welcome from '@/views/middle/Welcome.vue'
import Chat from '@/views/middle/Chat.vue'
import PrescriptionDetail from '@/views/middle/PrescriptionDetail.vue'

// Right
import KidProfile from '@/domains/kid/views/KidProfile.vue'
import MedicalReportsHistory from '@/domains/medical-report/views/MedicalReportsHistory.vue'
import RightError from '@/domains/errors/views/RightError.vue'

export function install(app: App): void {
  // Left
  app.component(PanelsComponentNames.RoomList, RoomList)
  app.component(PanelsComponentNames.Prescription, Prescription)
  app.component(PanelsComponentNames.AddBrandedDrug, AddBrandedDrug)
  app.component(PanelsComponentNames.AddDosage, AddDosage)
  app.component(PanelsComponentNames.AddDuration, AddDuration)
  app.component(PanelsComponentNames.MediaViewer, MediaViewer)
  app.component(PanelsComponentNames.LeftError, LeftError)
  app.component(PanelsComponentNames.KidParentRoomList, KidParentRoomList)
  app.component(PanelsComponentNames.PrescriptionList, PrescriptionList)

  // Middle
  app.component(PanelsComponentNames.Welcome, Welcome)
  app.component(PanelsComponentNames.Chat, Chat)
  app.component(PanelsComponentNames.PrescriptionDetail, PrescriptionDetail)

  // Right
  app.component(PanelsComponentNames.KidProfile, KidProfile)
  app.component(
    PanelsComponentNames.MedicalReportsHistory,
    MedicalReportsHistory,
  )
  app.component(PanelsComponentNames.RightError, RightError)
}

const PanelsComponents = {
  install,
}

export default PanelsComponents
