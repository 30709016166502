<script lang="ts" setup>
import type { EmojiClickEvent } from 'emoji-picker-element/shared'

import BaseDropdown from '@/components/base/BaseDropdown.vue'

const emits = defineEmits<{
  (e: 'pickEmoji', unicode: string): void
}>()

const onPickEmoji = (ref: EmojiClickEvent) =>
  ref.detail.unicode && emits('pickEmoji', ref.detail.unicode)
</script>

<template>
  <BaseDropdown
    button-custom-class="flex items-center justify-center h-full px-4 py-2 mr-2 lg:px-2"
  >
    <template #button>
      <font-awesome-icon
        v-tooltip.auto="'Emoji'"
        :icon="['fal', 'smile']"
        class="focus:outline-none"
        fixed-width
      />
    </template>
    <template #default="{ close }">
      <EmojiPicker v-emoji-picker="{ onPickEmoji, close }" />
    </template>
  </BaseDropdown>
</template>
