<script setup lang="ts">
import { ShallowRef, computed, watch, PropType } from 'vue'
import { useEditor, EditorContent, Editor } from '@tiptap/vue-3'
import { Document } from '@tiptap/extension-document'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Placeholder } from '@tiptap/extension-placeholder'
import Spellcheck from '@/lib/tiptap/extensions/spellcheck'
import Mention from '@tiptap/extension-mention'
import buildMentionSuggestionExtension, {
  MentionSuggestionItem,
} from '@/lib/tiptap/extensions/mention-suggestion'
import { ControlEnter } from '@/lib/tiptap/extensions/control-enter'
import highlightErrors from '@/lib/tiptap/highlight-errors'
import { toHTML } from '@/lib/tiptap/helpers'
import { isEmpty } from '@/utils/is-empty'
import { debounce } from 'throttle-debounce'
import useServices from '@/hooks/services'

import MisspellingNode from './base-rich-text-editor/MisspellingNode.vue'
import MentionList from './base-rich-text-editor/MentionList.vue'

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  spellcheck: {
    type: Boolean,
    default: false,
  },
  mentions: {
    type: Boolean,
    default: false,
  },
  mentionSuggestions: {
    type: Object as PropType<MentionSuggestionItem[]>,
    required: false,
  },
  autoFocus: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const emits = defineEmits<{
  (e: 'update:modelValue', newValue: string): void
  (e: 'focus'): void
  (e: 'blur'): void
  (e: 'sendText'): void
  (e: 'checking', status: boolean): void
}>()

const services = useServices()

const insertText = (text: string) => {
  if (!editor.value) return
  editor.value.chain().insertContent(text).focus().run()
}
const setText = (text: string, triggerUpdate = true) => {
  if (!editor.value) return
  editor.value.chain().setContent(text, triggerUpdate).focus().run()
}
const getHTML = () => editor.value?.getHTML()
const getJSON = () => editor.value?.getJSON()

defineExpose({ insertText, setText, getHTML, getJSON })

const htmlContent = computed(() => toHTML(props.modelValue))

// Editor minimal extensions
const extensions = [
  Document,
  Paragraph,
  Text,
  Placeholder.configure({
    placeholder: props.placeholder,
    showOnlyWhenEditable: false,
  }),
  HardBreak.extend({
    addKeyboardShortcuts() {
      return {
        Enter: () => this.editor.commands.setHardBreak(),
      }
    },
  }),
  ControlEnter.configure({
    callback: () => {
      const rawText = editor.value?.getText()
      if (!isEmpty(rawText)) emits('sendText')
    },
  }),
]

// Enable extensions based on the properties
if (props.spellcheck) extensions.push(Spellcheck(MisspellingNode))
if (props.mentions && props.mentionSuggestions)
  extensions.push(
    Mention.configure({
      HTMLAttributes: { class: 'mention' },
      suggestion: buildMentionSuggestionExtension(
        MentionList,
        props.mentionSuggestions,
      ),
    }),
  )

const editor: ShallowRef<Editor | undefined> = useEditor({
  extensions,
  autofocus: props.autoFocus,
  content: htmlContent.value,
  parseOptions: {
    preserveWhitespace: 'full',
  },
  onCreate: ({ editor }) => {
    editor.view.dom.classList.add('scrollbar')
    if (props.autoFocus) editor.commands.focus('end')
    const text = editor.getText()
    if (props.spellcheck && !isEmpty(text)) checkspell(text)
  },
  onFocus: () => emits('focus'),
  onBlur: () => emits('blur'),
  onUpdate: ({ editor, transaction }) => {
    const text = editor.getText()
    emits('update:modelValue', text)
    const preventSpellCheck: boolean | undefined =
      transaction.getMeta('preventSpellCheck')
    if (props.spellcheck && !preventSpellCheck && !isEmpty(text))
      checkspell(text)
  },
})

const coreCheckspell = async (message: string) => {
  if (!editor.value) return
  if (message !== props.modelValue || editor.value.isEmpty) return // the message might have been modified or sent in the meantime (Debounce)
  const errors = await services.spellchecker.call(message)
  if (message !== props.modelValue || editor.value.isEmpty) return // the message might have been modified or sent in the meantime (API)
  highlightErrors(editor.value, message, errors)
  emits('checking', false)
}

const debouncedCheckspell = debounce(1000, (message: string) =>
  coreCheckspell(message),
)

const checkspell = (message: string) => {
  emits('checking', true)
  debouncedCheckspell(message)
}

watch(
  () => props.modelValue,
  async (newValue) => {
    // for some reason, the editor has been to be updated if the content
    // got cleared outside the editor.
    if (newValue === '' && editor.value) {
      // for unknown reason, we can't use a transaction here otherwise we won't see the placeholder message.
      editor.value.commands.clearNodes()
      editor.value.commands.clearContent(true)
      editor.value.commands.focus()
    }
  },
)
</script>

<template>
  <editor-content
    :editor="editor"
    class="rich-text-editor"
    spellcheck="false"
  />
</template>

<style scoped>
.rich-text-editor :deep(.ProseMirror) {
  @apply p-4 outline-none overflow-y-scroll;
  max-height: calc(100vh / 3);
  min-height: 4rem;
  font-family: ui-sans-serif, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.rich-text-editor :deep(.ProseMirror) * {
  white-space: pre-line;
}

.rich-text-editor :deep(.ProseMirror) p.is-editor-empty:first-child::before {
  @apply text-default-300 float-left pointer-events-none h-0;
  content: attr(data-placeholder);
}

.rich-text-editor :deep(.ProseMirror) .mention {
  @apply px-1 py-0.5 bg-purple-100 text-purple-700 rounded;
}
</style>
