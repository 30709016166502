<script lang="ts" setup>
import { computed } from 'vue'
import { useAgentStore } from 'Store/stores'
import { AgentTitle } from 'Models/agent'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import useModal from '@/hooks/modal'

import ListOfAgents from './ListOfAgents.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import TodayPlanningModal from '@/domains/planning/views/TodayPlanningModal.vue'
import useScopedI18n from '@/hooks/scoped-i18n'

const agentStore = useAgentStore()
const { openModal, closeModal, isModalOpen } = useModal()
const { t } = useScopedI18n()

const teamStatus = computed(() => agentStore.getTeamStatus)

const doctorsCount = computed(() =>
  !!teamStatus.value
    ? teamStatus.value.currentSlot.doctors.length +
      teamStatus.value.assigned.doctors.length
    : 0,
)
const nursesCount = computed(() =>
  !!teamStatus.value
    ? teamStatus.value.currentSlot.nurses.length +
      teamStatus.value.assigned.nurses.length
    : 0,
)

const hasDoctors = computed(
  () =>
    !!teamStatus.value &&
    (teamStatus.value.currentSlot.doctors.length > 0 ||
      teamStatus.value.nextSlot.doctors.length > 0 ||
      teamStatus.value.assigned.doctors.length > 0),
)
const hasNurse = computed(
  () =>
    !!teamStatus.value &&
    (teamStatus.value.currentSlot.nurses.length > 0 ||
      teamStatus.value.nextSlot.nurses.length > 0 ||
      teamStatus.value.assigned.nurses.length > 0),
)
</script>

<template>
  <BaseDropdown
    v-if="teamStatus"
    class="w-full hover:bg-gray-50"
    button-custom-class="flex w-full h-16 items-center justify-center cursor-pointer"
    :placement="DropdownPlacement.BottomStart"
  >
    <template #button="{ isOpen }">
      <div class="flex flex-col" :class="{ 'text-primary': !isOpen }">
        <div class="flex flex-col items-center font-mono">
          <font-awesome-icon
            class="mt-1 text-lg"
            :icon="['fal', 'user-nurse']"
          />
          <div class="text-xs font-semibold">
            {{ doctorsCount }}:{{ nursesCount }}
          </div>
        </div>
      </div>
    </template>

    <template #default="{ close }">
      <div class="h-auto w-auto flex flex-row flex-1 bg-white">
        <ListOfAgents
          v-if="hasDoctors"
          :current-slot-agents="teamStatus.currentSlot.doctors"
          :next-slot-agents="teamStatus.nextSlot.doctors"
          :assigned-agents="teamStatus.assigned.doctors"
          :are-doctors="true"
          :agent-title="AgentTitle.Doctor"
          class="border-r border-default-200"
          @agent-picked="close"
        />
        <ListOfAgents
          v-if="hasNurse"
          :current-slot-agents="teamStatus.currentSlot.nurses"
          :next-slot-agents="teamStatus.nextSlot.nurses"
          :assigned-agents="teamStatus.assigned.nurses"
          :agent-title="AgentTitle.Nurse"
          @agent-picked="close"
        />
      </div>
      <button
        class="text-sm text-primary p-1 hover:bg-blue-50"
        @click.stop="openModal"
      >
        {{ t('todaySchedule') }}
      </button>
    </template>
  </BaseDropdown>

  <TodayPlanningModal v-if="isModalOpen" @close="closeModal" />
</template>
