import { AgentSlot } from 'Models/planning'

export const isHourInSlot = (
  slot: AgentSlot,
  hour: number = new Date().getHours(),
): boolean => {
  const startHour = slot.startHour.getHours()
  const endHour = slot.endHour.getHours()
  return startHour <= hour && hour < endHour
}
