import { lowerFirst } from '../string/lower-first'
import { upperFirst } from '../string/upper-first'

export const buildEnum = <T, K extends Extract<keyof T, string>>(obj: T) =>
  ({
    ...Object.keys(obj)
      .filter((key) => key)
      .reduce(
        (myEnum, currentKey) => ({
          ...myEnum,
          [upperFirst(currentKey)]: lowerFirst(currentKey),
        }),
        {},
      ),
  } as {
    [key in Capitalize<K>]: Uncapitalize<key>
  })
