import { GetterTree } from './types'
import { toAPIDate } from '@/utils/format-date'
import { UIRoomListFilterParams } from '../../ui-models'

export const getFilterParams: GetterTree['getFilterParams'] = function ({
  filter,
}) {
  if (!filter) return null
  const params: UIRoomListFilterParams = {}

  if (filter.agent) params.agentId = filter.agent.id

  if (filter.date) params.date = toAPIDate(filter.date)

  if (filter.status) params.status = filter.status

  return params
}
