<script lang="ts" setup>
import { PropType } from 'vue'
import { UIPrescriptionSummary } from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import Info from './actions/Info.vue'

defineProps({
  prescription: {
    type: Object as PropType<UIPrescriptionSummary>,
    required: true,
  },
})
const emits = defineEmits(['goToRoom'])
const { t } = useScopedI18n()
</script>

<template>
  <div class="flex items-center divide-x divide-default-100">
    <div class="px-2">
      <button class="text-primary" @click="emits('goToRoom')">
        {{ t('showRoom') }}
      </button>
    </div>
    <div class="pl-2">
      <Info :prescription="prescription" />
    </div>
  </div>
</template>
