import { AgentDailySlots, Planning } from 'Models/planning'
import { IPlanningService } from 'Services/__types__'
import { IAPIService } from 'External/api/types'
import { convertFromAPI } from 'Models/converters/planning-converter'

export default class PlanningService implements IPlanningService {
  // dependencies
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    this.apiService = apiService
  }

  loadDailyPlanning: IPlanningService['loadDailyPlanning'] = async (date) => {
    const planning = await this.apiService.getDailyPlanning(date)
    const convertedPlanning: Planning = {}
    Object.keys(planning).map(
      (agentId) =>
        (convertedPlanning[agentId] = planning[agentId].map(convertFromAPI)),
    )
    return convertedPlanning
  }

  getAgentDailySlots: IPlanningService['getAgentDailySlots'] = async (
    agentId,
    startDate,
    endDate,
  ) => {
    const agentDailySlots = await this.apiService.getAgentDailySlots(
      agentId,
      startDate,
      endDate,
    )

    const convertedAgentDailtSlots: AgentDailySlots = agentDailySlots.map(
      (item) => ({
        date: new Date(item.date),
        slots: item.slots.map(convertFromAPI),
      }),
    )

    return convertedAgentDailtSlots
  }
}
