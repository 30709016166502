import { useInceptionChatStore } from 'Store/stores'
import { watch } from 'vue'
import { playSound } from './concerns/triggers-icon-and-sound'

export const unreadInceptionChatsPlugin = () => {
  watch(
    () => useInceptionChatStore().getUnreadCounter,
    (counter, oldCounter) => {
      // startup, we don't have to notify the agent
      if (oldCounter === null || oldCounter === undefined || !counter) return

      if (counter > oldCounter) playSound()
    },
    { immediate: true },
  )
}
