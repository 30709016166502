import { ActionTree } from './types'
import { isEmpty } from '@/utils/is-empty'

export const prevPanel: ActionTree['prevPanel'] = function (panelPosition) {
  const { components } = this.$state[panelPosition]

  if (isEmpty(components)) return

  // Prevent from removing the last component
  if (components.length === 1) return

  this.$state[panelPosition].components.pop()
}
