<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoomListMe } from '../../ui-models'

import RoomCardList from '@/domains/room-list/components/lists/shared/RoomCardList.vue'

defineProps({
  roomList: {
    type: Object as PropType<UIRoomListMe>,
    required: true,
  },
})

const { tc } = useScopedI18n()
</script>

<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <div
      class="flex flex-col flex-grow overflow-y-scroll scrollbar overflow-x-hidden px-5 pt-3 pb-5 space-y-5"
    >
      <!-- PENDING -->
      <RoomCardList
        :title="tc('pending', roomList.pendingCounter)"
        :list="roomList.pendingRooms"
        color="orange"
      />

      <!-- ACTIVE -->
      <RoomCardList
        :title="tc('active', roomList.activeCounter)"
        :list="roomList.activeRooms"
        color="default"
      />

      <!-- INACTIVE -->
      <RoomCardList
        :title="tc('inactive', roomList.inactiveCounter)"
        :list="roomList.inactiveRooms"
        color="white"
      />

      <!-- AWAKE -->
      <RoomCardList
        :title="tc('awake', roomList.awakeRoomsCounter)"
        :list="roomList.awakeRooms"
        color="orange"
      />

      <!-- ASLEEP -->
      <RoomCardList
        :title="tc('asleep', roomList.asleepRoomsCounter)"
        :list="roomList.asleepRooms"
        color="white"
      />
    </div>
  </div>
</template>
