import useModal, { UseModal } from '@/hooks/modal'

export default function useBrandeMenuModals(): {
  todayPlanningModal: UseModal
  illustrationsModal: UseModal
  medicalProtocolsModal: UseModal
  savedRepliesModal: UseModal
  changePasswordModal: UseModal
} {
  const todayPlanningModal = useModal()
  const illustrationsModal = useModal()
  const medicalProtocolsModal = useModal()
  const savedRepliesModal = useModal()
  const changePasswordModal = useModal()

  return {
    todayPlanningModal,
    illustrationsModal,
    medicalProtocolsModal,
    savedRepliesModal,
    changePasswordModal,
  }
}
