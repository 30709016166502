import { RouteRecordRaw } from 'vue-router'
import { buildBaseRoutes } from './base'
import { buildRoomRoutes } from './room'
import { buildKidParentRoutes } from './kid-parent'
import { buildPrescriptionsRoutes } from './prescriptions'

const routes: RouteRecordRaw[] = [
  ...buildBaseRoutes(),
  ...buildRoomRoutes(),
  ...buildKidParentRoutes(),
  ...buildPrescriptionsRoutes(),
]

export default routes
