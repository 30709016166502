import { RouteRecordRaw } from 'vue-router'
import { RoutesNames } from './type'

export const buildBaseRoutes = (): RouteRecordRaw[] => [
  {
    path: '/',
    redirect: { name: RoutesNames.Main },
  },
  {
    path: '/sign-in',
    name: RoutesNames.SignIn,
    meta: {
      protected: false,
    },
    // component: Login,
    component: (): unknown =>
      import(/* webpackChunkName: "bundled-sign-in" */ '@/views/Login.vue'),
  },
  {
    path: '/reset-password/:agentId/:passwordToken',
    name: RoutesNames.ResetPassword,
    meta: {
      protected: false,
    },
    props: true,
    // component: ResetPassword,
    component: (): unknown =>
      import(
        /* webpackChunkName: "bundled-reset-password" */ '@/views/ResetPassword.vue'
      ),
  },
  {
    path: '/sign-in/2fa',
    name: RoutesNames.SignInWith2FA,
    meta: {
      protected: false,
    },
    // component: Login2FA,
    component: (): unknown =>
      import(/* webpackChunkName: "bundled-sign-in" */ '@/views/Login2FA.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: RoutesNames.PageNotFound,
    meta: {
      protected: false,
    },
    component: (): unknown =>
      import(/* webpackChunkName: "bundled-404" */ '@/views/NotFound.vue'),
  },
]
