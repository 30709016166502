<script setup lang="ts">
import useInsertText from '@/hooks/insert-text'
import { ref, computed, watchEffect, nextTick } from 'vue'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
})

const emits = defineEmits<{
  (e: 'update:text', newValue: string): void
  (e: 'focus'): void
  (e: 'blur'): void
  (e: 'sendText'): void
}>()

const { insertText: coreInsertText } = useInsertText()

const textAreaElement = ref<HTMLTextAreaElement>()
const hasReachedMaxHeight = ref(false)

const chartareaMinHeight = 56 // in pixels
const chatareaMaxHeight = computed(() => window.innerHeight / 3)

const update = (event: Event) => {
  if (!event.currentTarget) return
  const target = event.currentTarget as HTMLTextAreaElement
  emits('update:text', target.value)
}

const insertText = (text: string) => {
  if (!textAreaElement.value) return
  coreInsertText(textAreaElement.value, text, (newText) =>
    emits('update:text', newText),
  )
}
defineExpose({ insertText })

watchEffect(async () => {
  let element = textAreaElement.value
  if (!element) return
  if (props.text) element.value = props.text

  await nextTick() // without this line, textAreaElement.value won't be updated / ready for the next statement

  const maxHeight = chatareaMaxHeight.value
  if (!element) return // the DOM element might be not present anymore after the nextTick call

  element.style.height =
    element.value.length === 0
      ? chartareaMinHeight + 'px'
      : element.scrollHeight + 'px'

  hasReachedMaxHeight.value = !!maxHeight && element.scrollHeight > maxHeight

  element.focus()
})
</script>

<template>
  <textarea
    ref="textAreaElement"
    v-focus
    :value="props.text"
    class="placeholder-default-300 w-full focus:outline-none scrollbar resize-none break-word rounded-t p-4 m-0 flex relative transition-height duration-200 overflow-hidden"
    :class="hasReachedMaxHeight && 'overflow-y-auto'"
    :style="`max-height: ${chatareaMaxHeight}px; min-height: 56px !important; height: 56px`"
    :placeholder="placeholder"
    @input="update"
    @focus="emits('focus')"
    @blur="emits('blur')"
    @keydown.enter.ctrl.exact.prevent="emits('sendText')"
  />
</template>

<style scoped>
textarea {
  font-family: ui-sans-serif, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
</style>
