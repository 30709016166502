import { ActionTree } from './types'
import { RoomWorkspaceEntityName } from 'Models/room-workspace'
import {
  useCoreChatStore,
  useKidStore,
  useMedicalReportStore,
  usePrescriptionStore,
} from 'Store/stores'
import Services from 'Services/services'

export const listenCurrentRoomWorkspace: ActionTree['listenCurrentRoomWorkspace'] =
  async function () {
    Services.getInstance().roomWorkspace.setListener(
      (workspace, entityName) => {
        if (workspace.roomId !== this.currentRoomId) return

        switch (entityName) {
          case null:
          case undefined:
            this.currentRoom = workspace.room
            usePrescriptionStore().updateCurrentPrescription(
              workspace.prescription,
            )
            useCoreChatStore().updateCurrentMessages(workspace.messages)
            useKidStore().updateCurrentKid(workspace.kid)
            useMedicalReportStore().updateCurrentMedicalReport(
              workspace.medicalReport,
            )
            useMedicalReportStore().updatePastMedicalReports(
              workspace.pastMedicalReports,
            )
            break

          case RoomWorkspaceEntityName.Room:
            this.currentRoom = workspace.room
            break

          case RoomWorkspaceEntityName.Kid:
            useKidStore().updateCurrentKid(workspace.kid)
            break

          case RoomWorkspaceEntityName.Messages:
            useCoreChatStore().updateCurrentMessages(workspace.messages)
            break

          case RoomWorkspaceEntityName.MedicalReport:
            useMedicalReportStore().updateCurrentMedicalReport(
              workspace.medicalReport,
            )
            break

          case RoomWorkspaceEntityName.PastMedicalReports:
            useMedicalReportStore().updatePastMedicalReports(
              workspace.pastMedicalReports,
            )
            break

          case RoomWorkspaceEntityName.Prescription:
            usePrescriptionStore().updateCurrentPrescription(
              workspace.prescription,
            )
            break

          default:
            break
        }
      },
    )
  }
