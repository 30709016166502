<script lang="ts" setup>
import { PropType } from 'vue'
import { usePanelStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import { PanelPosition } from '@/domains/panel/store/types'

import Header from '@/views/layouts/panels/Header.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import Container from '@/components/panel/side/layout/Container.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left | PanelPosition.Right>,
    required: true,
  },
})

const { rootPanel } = usePanelStore()
const { t } = useScopedI18n()
</script>

<template>
  <Container :panel-position="panelPosition">
    <Header :panel-position="panelPosition" :can-prev="false">
      <div class="w-1/2 bg-default-200 h-3 rounded-sm"></div>
    </Header>
    <Body>
      <div class="p-5 space-y-3">
        <div class="w-1/2 bg-default-200 h-3 rounded-sm"></div>
        <div class="w-2/3 bg-default-200 h-3 rounded-sm"></div>
        <div class="w-2/3 bg-default-200 h-3 rounded-sm"></div>
        <div class="w-1/4 bg-default-200 h-3 rounded-sm"></div>
      </div>
      <div class="flex flex-col items-center justify-center h-1/2 text-red-400">
        <div class="animate-bounce">
          <font-awesome-icon
            :icon="['fal', 'frown-open']"
            class="text-5xl mb-2"
          />
        </div>
        <p>Oups!</p>
        <BasePrimaryButton class="mt-5" @click="rootPanel(panelPosition)">
          {{ t('action') }}
        </BasePrimaryButton>
      </div>
    </Body>
  </Container>
</template>
