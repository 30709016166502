<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { RoutesNames } from '@/router/routes/type'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useRouteIntoTab } from '@/domains/room-list/controllers/route-into-tab'
import { UIRoomListUnassigned } from '../../ui-models'

const props = defineProps({
  unassigned: {
    type: Object as PropType<UIRoomListUnassigned>,
    required: true,
  },
})

const { t } = useScopedI18n()
const { isTabUnassigned } = useRouteIntoTab()

const activeColor = computed(() => {
  if (props.unassigned.counter >= 6 && props.unassigned.counter <= 10)
    return 'bg-yellow-500'
  else if (props.unassigned.counter > 10) return 'bg-orange-500'
  return 'bg-primary'
})
</script>

<template>
  <router-link
    v-tooltip="t('title')"
    :to="{
      name: RoutesNames.RoomsUnassigned,
    }"
    :class="
      isTabUnassigned ? 'cursor-default shadow-inner' : 'hover:bg-default-50'
    "
    class="flex flex-col"
  >
    <div
      class="flex flex-1 items-center justify-center"
      :class="isTabUnassigned && 'pt-1'"
    >
      <div class="relative flex flex-row items-center">
        <div v-if="unassigned.hasRooms" class="flex flex-row items-center">
          <div
            class="flex pt-1 font-mono rounded-full items-center justify-center w-10 h-10 text-base font-bold"
            :class="
              isTabUnassigned
                ? 'bg-gray-100 text-default'
                : `${activeColor} text-white`
            "
          >
            <div class="flex pb-1">
              {{ unassigned.counter }}
            </div>
          </div>
        </div>
        <div v-else class="px-2">
          <font-awesome-icon
            :icon="['fal', 'mug-hot']"
            class="text-xl text-green-500"
          />
        </div>
      </div>
    </div>

    <!-- Border-bottom -->
    <div v-if="isTabUnassigned" class="border-b-2 border-primary w-full" />
  </router-link>
</template>
