<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { UIMRLCoreItem } from '@/domains/medical-report/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import ItemLabel from './ItemLabel.vue'

const props = defineProps({
  item: {
    type: Object as PropType<UIMRLCoreItem>,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'remove', item: UIMRLCoreItem): void
}>()

const { t } = useScopedI18n()

const hover = ref(false)
const hoverTrash = ref(false)

const remove = () => emit('remove', props.item)
</script>

<template>
  <li
    class="flex justify-between items-center transition-all border-l-2 border-transparent"
    :class="hoverTrash && 'border-red-600 bg-default-50'"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="flex items-center px-5">
      <slot>
        <ItemLabel :item="item" :dot="true" />
        <font-awesome-icon
          v-if="item.canBeHidden && item.hidden"
          v-tooltip.auto="t('privateTooltip')"
          :icon="['fal', 'eye-slash']"
          class="ml-2 mb-px text-xs focus:outline-none"
        />
      </slot>
    </div>
    <button
      :id="item.id"
      class="focus:outline-none px-5 text-primary hover:text-red-600"
      :class="hover ? 'visible' : 'invisible'"
      @click="remove"
      @mouseover="hoverTrash = true"
      @mouseleave="hoverTrash = false"
    >
      <font-awesome-icon
        :icon="['fal', 'backspace']"
        class="focus:outline-none"
      />
    </button>
  </li>
</template>
