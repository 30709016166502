import { IRoomService } from 'Services/__types__'
import { IFirebaseService, ListenerChangeType } from 'External/firebase/types'
import { FBRoom, FBRoomAssignedAgent, Room, RoomStatuses } from 'Models/room'
import {
  convertFrom,
  convertFromCurrentRoom,
  convertToRoomAgent,
} from 'Models/converters/room-converter'
import {
  CollectionReference,
  DocumentReference,
  orderBy,
  query,
  Query,
  DocumentData,
  UpdateData,
  QueryConstraint,
  where,
  arrayUnion,
  serverTimestamp,
  documentId,
} from 'firebase/firestore'
import { compareAsc, startOfDay, endOfDay, isToday } from 'date-fns'
import { isEmpty } from '@/utils/is-empty'
import { Agent } from 'Models/agent'
import { differenceInMinutes } from '@/utils/difference-in-minutes'
import { IAPIService } from 'External/api/types'
import { $isProduction } from '@/plugins/vue-helpers/environment'

const LIMIT_IN_MINUTES = $isProduction ? 15 : 1

export default class RoomService implements IRoomService {
  // dependencies
  firebaseService: IFirebaseService
  apiService: IAPIService

  constructor(firebaseService: IFirebaseService, apiService: IAPIService) {
    this.firebaseService = firebaseService
    this.apiService = apiService
  }

  async loadAllByIds(roomIds: string[], batchSize = 10): Promise<Room[]> {
    if (roomIds.length === 0) return []
    return this.firebaseService.loadDocumentsByIds(
      roomIds,
      (ids: string[]) => this.findAllByIds(ids),
      batchSize,
    )
  }

  async close(room: Room, agent: Agent): Promise<void> {
    const changes: DocumentData = {
      status: {
        ...room.status,
        type: RoomStatuses.Closed,
        agent: convertToRoomAgent(agent),
        updatedAt: serverTimestamp(),
      },
    }
    await this.update(room.id, changes)
  }

  snooze: IRoomService['snooze'] = async (
    room,
    currentAgent,
    snoozedUntil,
    assignedAgent,
  ) => {
    let agent: UpdateData<FBRoomAssignedAgent> | null

    if (assignedAgent)
      agent = {
        ...convertToRoomAgent(assignedAgent),
        assignmentAccepted: false,
      }
    else agent = null

    const changes: DocumentData = {
      status: {
        ...room.status,
        type: RoomStatuses.Snoozed,
        agent: convertToRoomAgent(currentAgent),
        snoozedUntil: snoozedUntil,
        updatedAt: serverTimestamp(),
      },
      agent,
    }
    await this.update(room.id, changes)
  }

  async markAsRead(roomId: string, agentId: string): Promise<boolean> {
    const room = await this.load(roomId)

    if (!room || room.haveAgentsRead.includes(agentId)) return false

    const changes: DocumentData = {
      haveAgentsRead: arrayUnion(agentId),
    }

    await this.update(room.id, changes)

    return true
  }

  async markSavedReplyAsUsed(
    roomId: string,
    userId: string,
    savedReplyId: string,
  ): Promise<string[]> {
    return (
      await this.apiService.markSavedReplyAsUsed(roomId, userId, savedReplyId)
    ).savedReplyIds
  }

  async getUsedSavedReplies(userId: string): Promise<string[]> {
    return (await this.apiService.getUsedSavedReplies(userId)).savedReplyIds
  }

  assign(
    room: Room,
    assignedAgent: Agent | null,
    assignedBy: Agent,
  ): Promise<void> {
    let agent: UpdateData<FBRoomAssignedAgent> | null

    if (assignedAgent)
      agent = {
        ...convertToRoomAgent(assignedAgent),
        assignmentAccepted: assignedAgent.id === assignedBy.id,
      }
    else agent = null

    const changes: UpdateData<FBRoom> = {
      agent,
      assignedBy: convertToRoomAgent(assignedBy),
    }

    // special case: if the room was snoozed, then we need to un-snooze it by opening it.
    if (room.status.type === RoomStatuses.Snoozed) {
      changes['status.type'] = RoomStatuses.Open
      changes['status.updatedAt'] = serverTimestamp()
    }

    return this.update(room.id, changes)
  }

  async acceptAssignment(room: Room): Promise<void> {
    if (!room.assignedAgent) return

    const assignedAgent: UpdateData<FBRoomAssignedAgent> = {
      ...room.assignedAgent,
      assignmentAccepted: true,
    }
    const changes: UpdateData<FBRoom> = { agent: assignedAgent }

    // special case: if the room was snoozed, then we need to un-snooze it by opening it.
    if (room.status.type === RoomStatuses.Snoozed) {
      changes['status.type'] = RoomStatuses.Open
      changes['status.updatedAt'] = serverTimestamp()
    }

    return this.update(room.id, changes)
  }

  async refuseAssignment(room: Room): Promise<void> {
    const changes: UpdateData<FBRoom> = { agent: null }
    return this.update(room.id, changes)
  }

  load(roomId: string): Promise<Room | undefined> {
    return this.firebaseService.loadDocument(this.find(roomId))
  }

  loadAllOpen(): Promise<Room[] | undefined> {
    return this.firebaseService.loadDocuments(this.findAllOpen())
  }

  loadAllClosed(day: Date): Promise<Room[] | undefined> {
    return this.firebaseService.loadDocuments(this.findAllClosed(day))
  }

  loadAssignedClosed(day: Date, agentId: string): Promise<Room[] | undefined> {
    return this.firebaseService.loadDocuments(
      this.findAssignedClosed(day, agentId),
    )
  }

  listen(roomId: string, onChange: (newRoom: Room) => void): void {
    this.firebaseService.listenDocument(
      `room-${roomId}`,
      this.find(roomId),
      (newRoom: Room) => {
        onChange(newRoom)
      },
    )
  }

  unlisten(roomId: string): void {
    this.firebaseService.unlisten(`room-${roomId}`)
  }

  unlistenAllFromKidParent(parentId: string): void {
    this.firebaseService.unlisten(`rooms-${parentId}`)
  }

  async listenAllOpen(
    onChange: (newRooms: Room[], changeType?: ListenerChangeType) => void,
  ): Promise<void> {
    this.firebaseService.listenCollection(
      'openedRooms',
      this.findAllOpen(),
      (newRooms: Room[], changeType?: ListenerChangeType) => {
        onChange(newRooms, changeType)
      },
      true,
    )
  }

  listenAllFavorites(
    agentId: string,
    onChange: (newRooms: Room[]) => void,
  ): void {
    this.firebaseService.listenCollection(
      'favorites-rooms',
      this.findAllFavorites(agentId),
      (newRooms: Room[]) => {
        onChange(newRooms)
      },
    )
  }

  listenAllFromKidParent(
    parentId: string,
    onChange: (newRooms: Room[]) => void,
  ): void {
    this.firebaseService.listenCollection(
      `rooms-${parentId}`,
      this.findAllFromKidParent(parentId),
      (newRooms: Room[]) => {
        onChange(newRooms)
      },
    )
  }

  // NOTE: waiting for Agent's answer
  isPendingRoom: IRoomService['isPendingRoom'] = (
    lastMessageSenderType,
    date,
    now,
  ) => {
    if (!lastMessageSenderType || !date || lastMessageSenderType === 'agent')
      return false
    return this.isOutOfDate(now, date)
  }
  // NOTE: waiting for Parent's answer
  isInactiveRoom(room: Room, now: Date): boolean {
    if (!room.lastMessage?.createdAt) return false
    if (room.lastHumanSender !== 'agent') return false
    return this.isOutOfDate(now, room.lastMessage.createdAt)
  }

  filterAssigned(list: Room[], agentId: string): Room[] {
    return list.filter(
      (room) =>
        room.assignedAgent?.id === agentId &&
        room.status.type === RoomStatuses.Open,
    )
  }

  filterAllAssigned(list: Room[]): Room[] {
    return list.filter(
      (room) => room.assignedAgent && room.status.type === RoomStatuses.Open,
    )
  }

  filterAllTodayRooms(list: Room[]): Room[] {
    return list.filter((room) => isToday(room.createdAt))
  }

  filterUnassigned(list: Room[]): Room[] {
    return list
      .filter(
        (room) => !room.assignedAgent && room.status.type === RoomStatuses.Open,
      )
      .sort((roomA, roomB) => compareAsc(roomA.createdAt, roomB.createdAt))
  }

  filterSnoozed(list: Room[], awoken?: boolean, today?: Date): Room[] {
    const safeToday = startOfDay(today || new Date())
    const safeAwoken = awoken === undefined ? true : awoken
    const checkDate = (date: Date) => {
      const value = compareAsc(startOfDay(date), safeToday)
      if (safeAwoken) return value !== 1
      else return value === 1
    }
    return list
      .filter(
        (room) =>
          room.status.type === RoomStatuses.Snoozed &&
          room.status.snoozedUntil &&
          checkDate(room.status.snoozedUntil),
      )
      .sort((roomA, roomB) =>
        compareAsc(
          roomA.status.snoozedUntil || new Date(), // make TS happy
          roomB.status.snoozedUntil || new Date(),
        ),
      )
  }

  filterByRoomId(list: Room[], roomId: string): Room | undefined {
    return list.find((room) => room.id === roomId)
  }

  areAllLastMessagesRead(list: Room[], agentId: string): boolean {
    if (isEmpty(list)) return true
    return list.every((room) => room.haveAgentsRead.indexOf(agentId) !== -1)
  }

  async markAsFavorite(room: Room, agentId: string): Promise<boolean> {
    const markedByAgentsAsFavorite = [...room.markedByAgentsAsFavorite]

    if (markedByAgentsAsFavorite.includes(agentId)) return false
    markedByAgentsAsFavorite.push(agentId)

    this.update(room.id, {
      markedByAgentsAsFavorite,
    })
    return true
  }

  async unmarkAsFavorite(room: Room, agentId: string): Promise<boolean> {
    const markedByAgentsAsFavorite = [...room.markedByAgentsAsFavorite]

    if (isEmpty(markedByAgentsAsFavorite)) return false

    const idIndex = markedByAgentsAsFavorite.indexOf(agentId)
    markedByAgentsAsFavorite.splice(idIndex, 1)

    this.update(room.id, {
      markedByAgentsAsFavorite,
    })
    return true
  }

  async reOpen(room: Room, agent: Agent): Promise<void> {
    if (room.status.type === RoomStatuses.Closed) {
      const convertedAgent = convertToRoomAgent(agent)
      const changes: DocumentData = {
        status: {
          ...room.status,
          type: RoomStatuses.Open,
          agent: convertedAgent,
          updatedAt: serverTimestamp(),
        },
        assignedBy: convertedAgent,
        agent: { assignmentAccepted: true, ...convertedAgent },
      }
      await this.update(room.id, changes)
    }
  }

  getComments: IRoomService['getComments'] = async (roomId) => {
    return await this.apiService.getRoomComments(roomId)
  }

  comment: IRoomService['comment'] = async (
    roomId,
    comment,
    medicalReportAssignedAgentId,
    lastComment,
    shouldForceNewComment,
  ) => {
    if (
      medicalReportAssignedAgentId === lastComment?.agentId &&
      !shouldForceNewComment
    )
      return await this.apiService.updateRoomComment(
        roomId,
        lastComment.id,
        comment,
      )

    return await this.apiService.postRoomComment(roomId, comment)
  }

  //
  // PROTECTED
  //

  protected update(roomId: string, data: UpdateData<FBRoom>): Promise<void> {
    return this.firebaseService.updateDocument('rooms', roomId, data)
  }

  protected find(roomId: string): DocumentReference<Room> {
    return this.firebaseService.getDocument(
      'rooms',
      roomId,
      convertFromCurrentRoom,
    )
  }

  protected findAllByIds(roomIds: string[]): Query<Room> {
    return query(this.getCollection(), where(documentId(), 'in', roomIds))
  }

  protected findAllOpen(): Query<Room> {
    return query(
      this.getCollection(),
      where('status.type', 'in', [RoomStatuses.Open, RoomStatuses.Snoozed]),
      orderBy('lastMessage.createdAt', 'desc'),
    )
  }

  protected findAllClosed(day: Date): Query<Room> {
    return query(this.getCollection(), ...this.getClosedQuery(day))
  }

  protected findAllFavorites(agentId: string): Query<Room> {
    return query(
      this.getCollection(),
      where('markedByAgentsAsFavorite', 'array-contains', agentId),
      orderBy('lastMessage.createdAt', 'desc'),
    )
  }

  protected findAllFromKidParent(parentId: string): Query<Room> {
    return query(
      this.getCollection(),
      where('user.id', '==', parentId),
      orderBy('lastMessage.createdAt', 'desc'),
    )
  }

  protected findAssignedClosed(day: Date, agentId: string): Query<Room> {
    return query(
      this.getCollection(),
      where('agent.id', '==', agentId),
      ...this.getClosedQuery(day),
    )
  }

  protected getClosedQuery(day: Date): QueryConstraint[] {
    return [
      where('status.type', '==', RoomStatuses.Closed),
      where('status.updatedAt', '>=', startOfDay(day)),
      where('status.updatedAt', '<=', endOfDay(day)),
      orderBy('status.updatedAt', 'desc'),
    ]
  }

  protected getCollection(): CollectionReference<Room> {
    return this.firebaseService.getCollection<FBRoom, Room>(
      this.getCollectionName(),
      convertFrom,
    )
  }

  protected getCollectionName(): string {
    return 'rooms'
  }

  protected isOutOfDate(now: Date, date: Date): boolean {
    return differenceInMinutes(now, date) > LIMIT_IN_MINUTES
  }
}
