import {
  FBDynamicConfig,
  DynamicConfig,
  FBDeskConfig,
  DeskConfig,
} from 'Models/config'

export const convertFromDynamic = (
  fbDynamicConfig: FBDynamicConfig,
): DynamicConfig => ({
  paywall: fbDynamicConfig.paywall,
  drowning: fbDynamicConfig.drowning,
  almostDrowning: fbDynamicConfig.almostDrowning,
  closedToday: fbDynamicConfig.closedToday,
  closing: fbDynamicConfig.closing,
  closingMessageAt: fbDynamicConfig.closingMessageAt,
  covid: fbDynamicConfig.covid,
  fakeWaitingTime: fbDynamicConfig.fakeWaitingTime,
  open: fbDynamicConfig.open,
  openingHours: fbDynamicConfig.openingHours,
  waitingTime: fbDynamicConfig.waitingTime,
})

export const convertFromDesk = (fbDeskConfig: FBDeskConfig): DeskConfig => ({
  supervisorAgent: fbDeskConfig.supervisorAgent,
  version: fbDeskConfig.version,
  semversion: fbDeskConfig.semversion,
})
