<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { useRoomListStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import useNow from '@/hooks/now'
import { useRouteIntoTab } from '@/domains/room-list/controllers/route-into-tab'
import { RoutesNames } from '@/router/routes/type'
import { RoomListFilterChanges } from '@/domains/room-list/store/actions/types'
import { UIRoomListFilter } from '../../ui-models'

import Tab from '@/domains/room-list/components/lists/shared/Tab.vue'
import RoomListMe from './RoomListMe.vue'
import RoomListFavorites from './RoomListFavorites.vue'
import RoomListInceptionChat from './RoomListInceptionChat.vue'

defineProps({
  filter: {
    type: Object as PropType<UIRoomListFilter>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'updateFilter', changes: RoomListFilterChanges): void
}>()

const { t } = useScopedI18n()
const { now } = useNow(900000) // (15min) Prevent untimely re-rendering
const { isTabMe, isTabFavorites, isTabInceptionChat } = useRouteIntoTab()
const roomListStore = useRoomListStore()

const roomListFavoritesCounter = ref(0)

const roomListMe = computed(() => roomListStore.getRoomListMe(now.value))
</script>

<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <header class="flex flex-row items-center pl-5 pr-3 py-2">
      <div class="flex flex-1 flex-row space-x-4">
        <Tab
          v-if="roomListMe"
          :to-route-name="RoutesNames.RoomsMe"
          :is-active="isTabMe"
          class="flex"
        >
          <div>{{ t('me') }}</div>
          <div class="text-xs">({{ roomListMe.counter }})</div>
          <div
            v-if="roomListMe?.hasUnreadRooms"
            class="w-2 h-2 rounded-full bg-primary"
          />
        </Tab>
        <Tab
          :to-route-name="RoutesNames.RoomsFavorites"
          :is-active="isTabFavorites"
          class="flex"
        >
          <div>{{ t('favorites') }}</div>
          <div v-if="roomListFavoritesCounter" class="text-xs">
            ({{ roomListFavoritesCounter }})
          </div>
        </Tab>
        <Tab
          :to-route-name="RoutesNames.RoomsInceptionChat"
          :is-active="isTabInceptionChat"
          class="flex"
        >
          <div>{{ t('inceptionChat') }}</div>
          <template v-if="roomListMe?.hasUnreadInceptionChats">
            <div class="text-xs">
              ({{ roomListMe?.unreadInceptionChatRooms?.length }})
            </div>
            <div class="w-2 h-2 rounded-full bg-purple-500" />
          </template>
        </Tab>
      </div>
    </header>

    <RoomListMe v-if="roomListMe && isTabMe" :room-list="roomListMe" />

    <RoomListFavorites
      v-if="isTabFavorites"
      :filter="filter"
      @update-filter="(e) => emits('updateFilter', e)"
      @update-counter="(e) => (roomListFavoritesCounter = e)"
    />

    <RoomListInceptionChat
      v-if="roomListMe && isTabInceptionChat"
      :room-list="roomListMe"
    />
  </div>
</template>
