import { watch } from 'vue'
import { useAgentStore } from 'Store/stores'

export const refreshCurrentAgentPlugin = () => {
  const agentStore = useAgentStore()

  watch(
    () => agentStore.agents,
    (newAgents) => {
      if (!newAgents) return
      agentStore.refreshCurrentAgent()
    },
    { immediate: true },
  )
}
