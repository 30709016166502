import includes from './string-includes'

type Item = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

type ItemsFields<T> = {
  items: T[]
  fields: string[]
}

export const getMatches = <T>(
  itemsFields: ItemsFields<T>,
  query: string,
): T[] => {
  return itemsFields.items.filter((item: Item) =>
    itemsFields.fields.some((field) => includes(item[field], query)),
  )
}
