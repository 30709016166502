<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { UISavedReply } from '@/domains/saved-reply/ui-models'

import Leaf from './Leaf.vue'
import Node from './Node.vue'

const props = defineProps({
  node: {
    type: Object as PropType<UISavedReply>,
    required: true,
  },
  selectedSavedReply: {
    type: String,
    required: false,
  },
  usedSavedReplyIds: {
    type: Array as PropType<string[]> | undefined,
    required: false,
  },
  index: {
    type: Number,
    required: true,
  },
})

const isFirst = computed(() => props.index === 0)
const savedReplies = computed(() =>
  props.node.parentId ? [props.node] : props.node.children,
)
</script>

<template>
  <div
    v-if="!isFirst && !node.parentId"
    class="border-t border-default-100 my-3"
  />
  <div v-for="savedReply in savedReplies" :key="savedReply.id">
    <Leaf
      v-if="savedReply.leaf"
      :node="savedReply"
      :is-already-used="usedSavedReplyIds?.includes(savedReply.id) ?? false"
      :selected-saved-reply="selectedSavedReply"
      v-bind="$attrs"
    />
    <Node v-else :node="savedReply" v-bind="$attrs" />
  </div>
</template>
