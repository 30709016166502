import { GetterTree } from './types'

import { getAvailableMedicalReportLibraryItems } from './get-available-medical-report-library-items'
import { getCurrentMedicalReport } from './get-current-medical-report'
import { getMedicalReport } from './get-medical-report'
import { getPastMedicalReports } from './get-past-medical-reports'

export const getters: GetterTree = {
  getAvailableMedicalReportLibraryItems,
  getCurrentMedicalReport,
  getMedicalReport,
  getPastMedicalReports,
}
