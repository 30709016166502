<script lang="ts" setup>
import { PropType, computed, ref, watch } from 'vue'
import { useAgentStore } from 'Store/stores'
import { Agent } from 'Models/agent'
import { MedicalReportDoctor } from 'Models/medical-report'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import BaseButtonRadioGroup from '@/components/base/BaseButtonRadioGroup.vue'
import AgentPicker from '@/domains/agent/views/AgentPicker.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'

const props = defineProps({
  medicalOpinionRequested: {
    type: Boolean,
    required: false,
    default: false,
  },
  requestedDoctor: {
    type: Object as PropType<MedicalReportDoctor | null>,
    required: false,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'selectMedicalOpinionRequested', newMedicalOpinion: boolean): void
  (e: 'selectRequestedDoctor', newRequestedDoctor: Agent): void
  (e: 'selectAgent', agent: Agent | null): void
}>()

const { t } = useScopedI18n()
const agentStore = useAgentStore()

const medicalOpinion = ref<boolean>(false)

const medicalOpinions = computed(() => [
  { key: 'no', value: false, label: t('no') },
  { key: 'yes', value: true, label: t('yes') },
]) // need to be a computed because we're using translation keys
const assignableAgents = computed(() => agentStore.getAssignableAgents)

// NOTE: keep this watcher to update the local ref and to not have a delay when choosing options
watch(
  () => props.medicalOpinionRequested,
  (newOpinion) => (medicalOpinion.value = newOpinion),
  { immediate: true },
)
watch(medicalOpinion, (newPickedOpinion) => {
  if (!newPickedOpinion) emits('selectAgent', null)
  emits('selectMedicalOpinionRequested', newPickedOpinion)
})
</script>

<template>
  <Spacer class="border-t border-default-100" />
  <div class="px-5">
    <div class="flex flex-row items-center space-x-4">
      <div class="flex font-semibold">
        {{ t('title') }}
      </div>
      <BaseButtonRadioGroup
        v-model:initial-item="medicalOpinion"
        class="flex-1"
        :items="medicalOpinions"
      />
    </div>

    <main class="mt-1">
      <div
        v-if="medicalOpinion"
        class="pt-2 flex flex-row items-center w-full space-x-2"
      >
        <div class="font-semibold">{{ t('doctor') }}</div>
        <AgentPicker
          :selected-agent="requestedDoctor"
          :current-agent="currentAgent"
          :agents="$doctors(assignableAgents)"
          :allow-clear="false"
          :select-current-agent="false"
          menu-align="right"
          empty-label="noDoctorSelected"
          :reduced-height="true"
          container-class="mr-2"
          :show-counters="false"
          :placement="DropdownPlacement.Top"
          @select-agent="(agent) => emits('selectAgent', agent)"
        />
      </div>
    </main>
  </div>
</template>
