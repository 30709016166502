<script lang="ts" setup>
import { RoutesNames } from '@/router/routes/type'
import { useAuthStore, useUIStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import useBrandMenuModals from '../../controllers/brand-menu-modals'

import BaseAccordion from '@/components/base/BaseAccordion.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import IconLogoLine from '@/components/icons/IconLogoLine.vue'
import TodayPlanningModal from '@/domains/planning/views/TodayPlanningModal.vue'
import IllustrationsModal from '@/domains/knowledge-basis/components/illustrations/IllustrationsModal.vue'
import MedicalProtocolsModal from '@/domains/knowledge-basis/components/medical-protocols/MedicalProtocolsModal.vue'
import SavedRepliesModal from '@/domains/saved-reply/components/SavedRepliesModal.vue'
import AgentChangePasswordModal from '@/domains/agent/components/AgentChangePasswordModal.vue'

const { t } = useScopedI18n()
const { signOut } = useAuthStore()
const { changeLocale } = useUIStore()
const {
  todayPlanningModal,
  illustrationsModal,
  medicalProtocolsModal,
  savedRepliesModal,
  changePasswordModal,
} = useBrandMenuModals()
</script>

<template>
  <div
    class="flex min-w-[4rem] items-center justify-center border-b border-r border-default-100"
  >
    <BaseDropdown
      button-custom-class="flex"
      :placement="DropdownPlacement.BottomStart"
    >
      <template #button>
        <IconLogoLine v-if="$isProduction" class="text-primary" />
        <div v-else class="font-bold flex flex-col leading-tight">
          <div>DEV</div>
          <div class="text-xxs">MODE</div>
        </div>
      </template>
      <template #default="{ close: closeBrandMenu }">
        <ul class="flex flex-col w-80 divide-y divide-default-100">
          <!-- Links -->
<!--          <li class="link">-->
<!--            <button-->
<!--              class="hover:bg-default-50 p-5 text-left w-full"-->
<!--              @click.stop="todayPlanningModal.openModal(), closeBrandMenu()"-->
<!--            >-->
<!--              {{ t('schedule') }}-->
<!--            </button>-->
<!--          </li>-->
          <li class="link">
            <a
              :href="$desideratasURL"
              class="p-5 block font-normal"
              target="_blank"
            >
              <div class="flex justify-between items-center">
                {{ t('desiderata') }}
                <font-awesome-icon
                  :icon="['fal', 'arrow-up-right-from-square']"
                  fixed-width
                />
              </div>
            </a>
          </li>
<!--          <li class="link">-->
<!--            <a-->
<!--              :href="$welcomeKitURL"-->
<!--              class="p-5 block font-normal"-->
<!--              target="_blank"-->
<!--            >-->
<!--              <div class="flex justify-between items-center">-->
<!--                {{ t('welcomeKit') }}-->
<!--                <font-awesome-icon-->
<!--                  :icon="['fal', 'arrow-up-right-from-square']"-->
<!--                  fixed-width-->
<!--                />-->
<!--              </div>-->
<!--            </a>-->
<!--          </li>-->
          <li v-if="$canOpenAdminApp()" class="link">
            <a
              :href="$adminAppURL"
              class="p-5 block font-normal"
              target="_blank"
            >
              <div class="flex justify-between items-center">
                {{ t('adminApp') }}
                <font-awesome-icon
                  :icon="['fal', 'arrow-up-right-from-square']"
                  fixed-width
                />
              </div>
            </a>
          </li>

          <!-- Medical library -->
          <BaseAccordion
            header-classes="px-5 py-5 bg-default-50"
            button-classes="text-primary text-xs font-semibold uppercase"
            chevron-classes="text-primary"
            :chevron-fixed-width="true"
          >
            <template #title>
              {{ t('medicalLibrary') }}
            </template>

            <div class="divide-y divide-default-100">
              <li class="link pl-4">
                <button
                  class="p-5 text-left w-full"
                  @click.stop="illustrationsModal.openModal(), closeBrandMenu()"
                >
                  {{ t('illustrations') }}
                </button>
              </li>
              <li class="link pl-4">
                <button
                  class="p-5 text-left w-full"
                  @click.stop="
                    medicalProtocolsModal.openModal(), closeBrandMenu()
                  "
                >
                  {{ t('medicalProtocols') }}
                </button>
              </li>
              <li class="link pl-4">
                <button
                  class="p-5 text-left w-full"
                  @click.stop="savedRepliesModal.openModal(), closeBrandMenu()"
                >
                  {{ t('savedReplies') }}
                </button>
              </li>
              <li v-if="$canListPrescriptions()" class="link pl-4">
                <router-link
                  :to="{ name: RoutesNames.Prescriptions }"
                  class="p-5 block font-normal"
                >
                  {{ t('listPrescriptions') }}
                </router-link>
              </li>
            </div>
          </BaseAccordion>

          <!-- Settings -->
          <BaseAccordion
            header-classes="px-5 py-5 bg-default-50"
            button-classes="text-primary text-xs font-semibold uppercase"
            chevron-classes="text-primary"
            :chevron-fixed-width="true"
          >
            <template #title>
              {{ t('settings') }}
            </template>

            <div class="divide-y divide-default-100">
              <li class="link pl-4">
                <button
                  class="p-5 text-left w-full"
                  @click.stop="
                    changePasswordModal.openModal(), closeBrandMenu()
                  "
                >
                  {{ t('changePassword') }}
                </button>
              </li>
              <li class="link pl-4">
                <button class="p-5 text-left w-full" @click="changeLocale">
                  {{ t('changeLocale') }}
                </button>
              </li>

              <li class="link pl-4">
                <button class="p-5 text-left w-full" @click="signOut">
                  {{ t('signOut') }}
                </button>
              </li>
            </div>
          </BaseAccordion>
        </ul>
      </template>
    </BaseDropdown>

    <!-- Modals -->
    <TodayPlanningModal
      v-if="todayPlanningModal.isModalOpen.value"
      @close="todayPlanningModal.closeModal"
    />
    <IllustrationsModal
      v-if="illustrationsModal.isModalOpen.value"
      :read-only="true"
      @close="illustrationsModal.closeModal"
    />
    <MedicalProtocolsModal
      v-if="medicalProtocolsModal.isModalOpen.value"
      @close="medicalProtocolsModal.closeModal"
    />
    <SavedRepliesModal
      v-if="savedRepliesModal.isModalOpen.value"
      @close="savedRepliesModal.closeModal"
    />
    <AgentChangePasswordModal
      v-if="changePasswordModal.isModalOpen.value"
      @close="changePasswordModal.closeModal"
    />
  </div>
</template>
<style scoped>
.link {
  @apply text-sm text-primary hover:bg-default-50;
}
</style>
