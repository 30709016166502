<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { groupMessagesByDay, gatherBurstMessages } from '../helpers'
import { UICoreChatMessage } from '@/domains/core-chat/ui-models'
import { getAgentById } from '@/utils/agent/get-agent-by-id'
import { Agent } from 'Models/agent'

import BaseMessageDaySeparator from '@/domains/core-chat/components/base/BaseMessageDaySeparator.vue'
import CoreChatMessages from '@/domains/core-chat/components/CoreChatMessages.vue'

const props = defineProps({
  messages: {
    type: Object as PropType<UICoreChatMessage[]>,
    required: true,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  currentAgentId: {
    type: String,
    required: true,
  },
  useTimeAgo: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const groups = computed(() =>
  groupMessagesByDay(gatherBurstMessages(props.messages)),
)
</script>

<template>
  <div>
    <div v-for="group in groups" :key="group.day.toDateString()">
      <BaseMessageDaySeparator :day="group.day" />
      <div class="space-y-3">
        <CoreChatMessages
          v-for="message in group.messages"
          :key="message.id"
          :agent="
            message.sender?.id ? getAgentById(agents, message.sender.id) : null
          "
          :message="message"
          :is-current-agent-message="currentAgentId === message.sender.id"
          :use-time-ago="useTimeAgo"
        />
      </div>
    </div>
  </div>
</template>
