import { Ref, computed } from 'vue'
import { usePrescriptionStore } from 'Store/stores'
import { PrescriptionStatus } from 'Models/prescription'
import useModal from '@/hooks/modal'
import { useAsyncRunner } from '@/hooks/async-runner'

export default function generatePrescriptionPreview(): {
  isGeneratingPreview: Ref<boolean>
  isModalOpen: Ref<boolean>
  generatePreview: () => void
  closeModal: () => void
} {
  const { generateCurrentPrescriptionPreview } = usePrescriptionStore()
  const prescriptionStore = usePrescriptionStore()
  const { openModal, closeModal, isModalOpen } = useModal()
  const { isRunning: isGeneratingPreview, run } = useAsyncRunner()

  const prescription = computed(() => prescriptionStore.getCurrentPrescription)

  const generatePreview = async () =>
    await run(async () => {
      if (prescription.value?.status !== PrescriptionStatus.Created)
        await generateCurrentPrescriptionPreview()
      openModal()
    })

  return {
    isGeneratingPreview,
    isModalOpen,
    generatePreview,
    closeModal,
  }
}
