import { ActionTree } from './types'
import Services from 'Services/services'

export const loadPrescriptionPreview: ActionTree['loadPrescriptionPreview'] =
  async function (prescriptionId) {
    this.prescriptionPreview = null

    if (!prescriptionId) return

    const prescription = await Services.getInstance().prescription.loadPreview(
      prescriptionId,
    )

    this.prescriptionPreview = prescription
  }
