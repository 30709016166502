export const getTooltipElements = (): {
  tooltipContainer: HTMLElement | null
  tooltipContent: HTMLElement | null
} => {
  const tooltipContainer: HTMLElement | null =
    document.querySelector('#tooltip')
  const tooltipContent: HTMLElement | null =
    document.querySelector('#tooltipContent')

  return {
    tooltipContainer,
    tooltipContent,
  }
}
