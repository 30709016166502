import { Timestamp } from 'firebase/firestore'
import { Agent, AgentGender, TitleKey, DoctorTitleKey } from './agent'
import { KidParent } from './kid'
import { MRLItem } from './medical-report-library-item'
import { Prescription } from './prescription'
import { RoomKid } from './room'

// NOTE : Need the same values as keys to make the reverse mapping work, necessary for the radio button group
export enum EstimatedTimeSpent {
  Unknown = 'Unknown',
  LessThanFiveMinutes = 'LessThanFiveMinutes',
  BetweenFiveAndFifteenMinutes = 'BetweenFiveAndFifteenMinutes',
  MoreThanFifteenMinutes = 'MoreThanFifteenMinutes',
}

export enum EstimatedComplexity {
  Unknown = 'Unknown',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export type FBMedicalReport = {
  id: string
  kid: FBMedicalReportKid
  userId: string
  agent: FBMedicalReportAgent | null
  roomId: string
  roomCreatedAt: Timestamp
  instructions?: string
  supervisions?: FBMedicalReportItem[]
  causes?: FBMedicalReportItem[]
  diagnosis?: FBMedicalReportItem[]
  initialDirections?: FBMedicalReportItem[]
  directions?: FBMedicalReportItem[]
  estimatedTimeSpent?: EstimatedTimeSpent
  estimatedComplexity?: EstimatedComplexity
  medicalOpinionRequested?: boolean
  requestedDoctor?: FBMedicalReportDoctor | null
}

type FBMedicalCoreAgent = {
  id: string
  firstName: string
  lastName: string
  email: string
  title: string // deprecated but we still need it to please the iOS app
  gender: AgentGender
  profilePic?: string | undefined
}

export type FBMedicalReportAgent = FBMedicalCoreAgent & {
  titleKey: TitleKey
}

export type FBMedicalReportDoctor = FBMedicalCoreAgent & {
  titleKey: DoctorTitleKey
}

export type FBMedicalReportKid = {
  id: string
  firstName: string
  lastName: string
  birthDate: Timestamp
  gender: number
}

export type MedicalReport = {
  id: string
  kidId: string
  parentId: string
  kidParent?: KidParent
  roomId: string
  hasPrescription: boolean
  prescription?: Prescription
  hasLetter: boolean
  roomCreatedAt: Date
  agent: MedicalReportAgent | null
  instructions?: string
  supervisionsIds?: string[]
  causesIds?: string[]
  diagnosisIds?: string[]
  visibleDiagnosticIds?: string[]
  initialDirectionsIds?: string[]
  directionsIds?: string[]
  kid?: MedicalReportKid
  estimatedTimeSpent?: EstimatedTimeSpent
  estimatedComplexity?: EstimatedComplexity
  medicalOpinionRequested?: boolean
  requestedDoctor?: MedicalReportDoctor | null
}

export type Timeline = {
  roomId: string
  assignmentHistory: AssignmentHistory[]
}

export type AssignmentHistory = {
  assignedAt: Date
  agentId: string
}

export type MedicalReportKid = RoomKid & {
  lastName?: string
}

type MedicalReportCoreAgent = {
  id: string
  firstName: string
  lastName: string
  profilePic?: string | undefined
  gender: AgentGender
}

export type MedicalReportAgent = MedicalReportCoreAgent & {
  titleKey: TitleKey
}

export type MedicalReportDoctor = MedicalReportCoreAgent & {
  titleKey: DoctorTitleKey
}

export type FBMedicalReportItem = {
  id: string
  hidden?: boolean
}

export type MedicalReportChanges = {
  agent?: Agent
  instructions?: string
  causes?: MRLItem[]
  diagnosis?: MRLItem[]
  initialDirections?: MRLItem[]
  directions?: MRLItem[]
  supervisions?: MRLItem[]
  estimatedTimeSpent?: EstimatedTimeSpent
  estimatedComplexity?: EstimatedComplexity
  medicalOpinionRequested?: boolean
  requestedDoctor?: Agent | null
}
