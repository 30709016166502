import { MRLItem } from 'Models/medical-report-library-item'
import { AvailableLocales } from '@/locales'
import { UIMRLCategory, UIMRLItem } from '@/domains/medical-report/ui-models'
import { sortByPosition } from '@/utils/sort-by-position'
import { dynamicCompare } from '@/utils/array/dynamic-compare'
import { isEmpty } from '@/utils/is-empty'

export function buildUIMRLItem(
  id: string,
  title: string,
  severity: number,
  canBeHidden: boolean | undefined,
  hidden: boolean,
  position: number,
  isAlreadyAdded?: boolean,
): UIMRLItem {
  return {
    id,
    title,
    severity,
    canBeHidden: canBeHidden || false,
    hidden,
    position,
    isMediumSeverity: severity === 3,
    isHighSeverity: severity === 4,
    isAlreadyAdded,
  }
}

export function buildUIMRLItems(
  ids: string[] | undefined,
  library: MRLItem[] | null,
  locale: AvailableLocales,
  idsAdded?: string[],
): UIMRLItem[] | undefined {
  if (!ids || !library) return

  const items = idsToMRLItems(ids, library).map((item) => {
    const isAlreadyAdded = idsAdded?.includes(item.id) ?? false

    return buildUIMRLItem(
      item.id,
      item.title[locale],
      item.severity,
      item.canBeHidden,
      item.hidden ?? item.canBeHidden ? true : false,
      item.position,
      isAlreadyAdded,
    )
  })
  // sort alphabetically
  return items.sort(dynamicCompare<UIMRLItem>('title'))
}

export function buildUIMRLCategories(
  ids: string[] | undefined,
  library: MRLItem[] | null,
  locale: AvailableLocales,
  idsAdded?: string[],
  visibleDiagnosticIds?: string[],
): UIMRLCategory[] | undefined {
  if (!ids || !library) return

  const categories = getCategories(sortByPosition(library), locale)

  idsToMRLItems(ids, library).forEach((item) => {
    if (!item.parentId) return
    const category = categories[item.parentId]
    if (!category) return

    const isAlreadyAdded = idsAdded?.includes(item.id) ?? false

    let hidden = item.canBeHidden ? true : false
    if (
      item.canBeHidden &&
      visibleDiagnosticIds &&
      !isEmpty(visibleDiagnosticIds)
    ) {
      hidden = !visibleDiagnosticIds.includes(item.id)
    }

    category.items.push(
      buildUIMRLItem(
        item.id,
        item.title[locale],
        item.severity,
        item.canBeHidden ?? false,
        hidden,
        item.position,
        isAlreadyAdded,
      ),
    )
  })

  return Object.values(categories).filter(
    (category) => category.items.length > 0,
  )
}

const idsToMRLItems = (ids: string[], library: MRLItem[]) => {
  return sortByPosition(
    ids
      .map((id) => library.find((item) => item.id === id))
      .filter((item): item is MRLItem => !!item),
  )
}

const getCategories = (library: MRLItem[], locale: AvailableLocales) => {
  const initialValue: Record<string, UIMRLCategory> = {}
  return library.reduce((memo, item) => {
    if (!item.parentId) memo[item.id] = { name: item.title[locale], items: [] }
    return memo
  }, initialValue)
}
