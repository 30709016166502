<script lang="ts" setup>
import { PropType } from 'vue'
import type { EmojiClickEvent } from 'emoji-picker-element/shared'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import BaseDropdown from './BaseDropdown.vue'

defineProps({
  dropdownPlacement: {
    type: String as PropType<DropdownPlacement>,
    default: DropdownPlacement.Bottom,
  },
})

const emits = defineEmits<{
  (e: 'pickEmoji', unicode: string): void
}>()

const onPickEmoji = (ref: EmojiClickEvent) =>
  ref.detail.unicode && emits('pickEmoji', ref.detail.unicode)
</script>

<template>
  <BaseDropdown
    button-custom-class="flex items-center justify-center h-full py-2 px-4 lg:px-2"
    :placement="dropdownPlacement"
  >
    <template #button>
      <font-awesome-icon
        v-tooltip.auto="'Emoji'"
        :icon="['fal', 'smile']"
        class="focus:outline-none"
        fixed-width
      />
    </template>
    <template #default="{ close }">
      <EmojiPicker v-emoji-picker="{ onPickEmoji, close }" />
    </template>
  </BaseDropdown>
</template>
