<script lang="ts" setup>
import { DropdownPlacement } from '@/plugins/__types__/popper'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import TextSmall from '@/components/panel/side/shared/TextSmall.vue'

defineProps({
  reason: {
    type: String,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div class="pl-1">
    <BaseDropdown
      class="ml-2"
      button-custom-class="w-8 h-8 hover:bg-default-100 rounded"
      container-class="ring-red-600 ring-opacity-30"
      :placement="DropdownPlacement.BottomStart"
    >
      <template #button>
        <font-awesome-icon
          class="focus:outline-none text-red-600"
          :icon="['fal', 'triangle-exclamation']"
        />
      </template>

      <div class="flex flex-col p-5 space-y-2">
        <div class="font-medium">{{ t('kidParentIsProblematic') }}</div>
        <div>
          <TextSmall>{{ t('reason') }}</TextSmall>
          <div class="font-medium text-sm text-red-600 whitespace-pre-wrap">
            {{ reason }}
          </div>
        </div>
      </div>
    </BaseDropdown>
  </div>
</template>
