import {
  DocumentData,
  serverTimestamp,
  WithFieldValue,
} from 'firebase/firestore'
import {
  FBMessage,
  Message,
  MessageSender,
  MessageAgentSender,
  MessageSenderType,
} from 'Models/message'

export const convertFrom = (fbMessage: FBMessage): Message => ({
  id: fbMessage.id,
  type: fbMessage.type,
  content: fbMessage.content,
  createdAt: fbMessage.createdAt?.toDate() || new Date(),
  sender: convertSenderFrom(fbMessage.sender),
})

export const convertSenderFrom = (sender: MessageSender): MessageSender => {
  const { id, firstName, lastName, profilePic, email, type } = sender
  return {
    id,
    firstName,
    lastName,
    profilePic,
    email,
    type,
  }
}

export const convertTo = (message: WithFieldValue<Message>): DocumentData => {
  return {
    content: message.content,
    type: message.type,
    sender: convertSenderTo(message.sender as MessageSender), // NOTE: we only send agent messages
    createdAt: serverTimestamp(),
  }
}

const convertSenderTo = (
  sender: MessageSender,
): WithFieldValue<MessageSender> => {
  if (sender.type === MessageSenderType.Desk) {
    return sender
  }
  const safeSender = {
    id: sender.id,
    firstName: sender.firstName,
    lastName: sender.lastName,
    email: sender.email,
    profilePic: sender.profilePic,
    type: sender.type,
    adeli: sender.adeli,
    titleKey: sender.titleKey,
    gender: sender.gender,
    rpps: sender.rpps,
    bio: sender.bio,
    title: sender.title,
  }

  if (safeSender['adeli'] === undefined) delete safeSender['adeli']
  if (safeSender['rpps'] === undefined) delete safeSender['rpps']

  return safeSender as MessageAgentSender
}
