<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { RoutesNames } from '@/router/routes/type'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIAssignedRoomLists } from '../../ui-models'
import { useRouteIntoTab } from '@/domains/room-list/controllers/route-into-tab'

const props = defineProps({
  assigned: {
    type: Object as PropType<UIAssignedRoomLists>,
    required: true,
  },
})

const { t } = useScopedI18n()
const { isTabAssignedToMe } = useRouteIntoTab()

const hasRooms = computed(() => props.assigned.currentAgentCounter > 0)
const hasUnreadInceptionChatRooms = computed(
  () => props.assigned.unreadInceptionChatCounter > 0,
)
</script>

<template>
  <router-link
    v-tooltip="t('title')"
    :to="{
      name: RoutesNames.RoomsMe,
    }"
    :class="
      isTabAssignedToMe ? 'cursor-default shadow-inner' : 'hover:bg-default-50'
    "
    class="flex flex-col"
  >
    <div
      class="flex flex-1 items-center justify-center"
      :class="isTabAssignedToMe && 'pt-1'"
    >
      <div class="relative flex flex-row items-center space-x-3">
        <!-- CurrentAgent -->
        <div class="flex flex-row items-center space-x-2">
          <font-awesome-icon
            v-if="!hasRooms || !hasUnreadInceptionChatRooms"
            :icon="['fal', 'clipboard-user']"
            class="text-lg"
          />
          <div
            v-if="hasRooms"
            class="flex pt-1 font-mono rounded-full items-center justify-center w-6 h-6 text-sm font-semibold bg-primary text-white"
            :class="{
              'bg-opacity-40': isTabAssignedToMe,
            }"
          >
            <div class="flex pb-1">
              {{ assigned.currentAgentCounter }}
            </div>
          </div>
          <div
            v-if="hasUnreadInceptionChatRooms"
            class="flex pt-1 font-mono rounded-full items-center justify-center w-6 h-6 text-sm font-semibold bg-purple-500 text-white"
            :class="{
              'bg-opacity-40': isTabAssignedToMe,
            }"
          >
            <div class="flex pb-1">
              {{ assigned.unreadInceptionChatCounter }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Border-bottom -->
    <div v-if="isTabAssignedToMe" class="border-b-2 border-primary w-full" />
  </router-link>
</template>
