<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { RoutesNames } from '@/router/routes/type'
import { UIKidParent, UIKidParentTab } from '@/domains/kid/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'
import { PanelPosition } from '@/domains/panel/store/types'
import { RoomKid } from 'Models/room'

import Header from '@/views/layouts/panels/Header.vue'
import Title from '@/components/panel/shared/header/Title.vue'
import BaseTabs from '@/components/base/BaseTabs.vue'
import BaseTab from '@/components/base/BaseTab.vue'
import NoRooms from '@/domains/room-list/components/lists/shared/NoRooms.vue'
import BaseKidPicker from '@/components/base/BaseKidPicker.vue'
import RoomCardList from '@/domains/room-list/components/lists/shared/RoomCardList.vue'
import BaseAvatar from '@/components/base/BaseAvatar.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  tabs: {
    type: Array as PropType<UIKidParentTab[]>,
    required: true,
  },
  parent: {
    type: Object as PropType<UIKidParent>,
    required: true,
  },
  roomId: {
    type: String,
    required: false,
  },
  activeTabIndex: {
    type: Number,
    required: true,
  },
})

const emits = defineEmits(['update:activeTabIndex'])

const { t, tc } = useScopedI18n()

const selectedKid = ref<RoomKid | null>(null)

const onSelectKid = (kid: RoomKid) => (selectedKid.value = kid)
</script>

<template>
  <Header
    :panel-position="panelPosition"
    :can-prev="false"
    :to-route-name="roomId ? RoutesNames.ShortcutRoom : RoutesNames.Main"
  >
    <template #default>
      <Title>
        {{ $fullname(parent) }}
      </Title>
    </template>
  </Header>
  <div class="flex flex-col flex-1 overflow-hidden" v-bind="$attrs">
    <div id="leftPanel-body" class="flex flex-col flex-1 overflow-hidden">
      <div id="roomsList-header" class="flex flex-col">
        <Title class="p-5 pb-2"> {{ t('title') }}</Title>
      </div>
      <BaseTabs
        :model-value="activeTabIndex"
        nav-class="border-b border-default-100 px-5 pb-5"
        @update:model-value="emits('update:activeTabIndex', $event)"
      >
        <BaseTab
          v-for="(tab, index) in tabs"
          :key="index"
          :title="tc(`tabs.${tab.name}`, tab.counter)"
          class="flex flex-col flex-1 overflow-y-auto scrollbar"
        >
          <div v-if="tab.kids.length > 1" class="flex w-full pl-5 pt-3">
            <BaseKidPicker
              button-custom-class="z-index-30 py-1 rounded border border-default-300"
              :selected-kid="selectedKid"
              :kids="tab.kids"
              empty-label="all"
              @select-kid="onSelectKid"
              @unselect-kid="selectedKid = null"
            />
          </div>
          <div
            v-if="tab.groups.length > 0"
            class="flex flex-col flex-grow overflow-y-scroll scrollbar overflow-x-hidden space-y-3 px-5 pt-3 pb-5"
          >
            <RoomCardList
              v-for="({ rooms, kid }, kidIndex) in tab.groups"
              :key="kidIndex"
              :title="$fullname(kid) ?? ''"
              :list="rooms"
              :class="{ hidden: selectedKid?.id && kid.id !== selectedKid?.id }"
            >
              <template #icon>
                <BaseAvatar
                  type="user"
                  :first-name="kid.firstName"
                  :last-name="kid.lastName"
                  :size="1"
                  class="mr-1"
                />
              </template>
            </RoomCardList>
          </div>
          <NoRooms v-else />
        </BaseTab>
      </BaseTabs>
    </div>
  </div>
</template>
