import {
  APIBrandedDrug,
  APIBrandedDrugMolecule,
  BrandedDrug,
  BrandedDrugFilter,
  BrandedDrugFilterSex,
  BrandedDrugMolecule,
} from 'Models/branded-drug'

export const convertFromAPI = (brandedDrug: APIBrandedDrug): BrandedDrug => {
  return {
    id: brandedDrug.id,
    name: brandedDrug.name,
    mobileLabel: brandedDrug.mobileLabel,
    shortLabel: brandedDrug.shortLabel,
    molecules: brandedDrug.molecules.map(convertMoleculeFromAPI),
    filter: convertFilterFromAPI(brandedDrug),
  }
}

const convertMoleculeFromAPI = (
  molecule: APIBrandedDrugMolecule,
): BrandedDrugMolecule => {
  return {
    id: molecule.id,
    name: molecule.name,
    shortLabel: molecule.shortLabel,
    mobileLabel: molecule.mobileLabel,
  }
}

const convertFilterFromAPI = (
  brandedDrug: APIBrandedDrug,
): BrandedDrugFilter => {
  return {
    sex: genderOptionToSexOption(brandedDrug.filters.gender),
    minAge: brandedDrug.filters.minAge,
    maxAge: brandedDrug.filters.maxAge,
    agentGroupIds: brandedDrug.filters.agentGroupIds,
  }
}

const genderOptionToSexOption = (gender: string): BrandedDrugFilterSex => {
  switch (gender) {
    case 'girl':
      return BrandedDrugFilterSex.Female
    case 'boy':
      return BrandedDrugFilterSex.Male
    default:
      return BrandedDrugFilterSex.All
  }
}
