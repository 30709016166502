import { ActionTree } from './types'
import { PrescriptionListFilter } from 'Models/prescription'
import { setPrescriptions } from './concerns/load-prescriptions'
import Services from 'Services/services'

export const changePrescriptionListFilter: ActionTree['changePrescriptionListFilter'] =
  async function (changes) {
    if (!this.prescriptionListFilter) return

    const newFilter: PrescriptionListFilter = { ...this.prescriptionListFilter }

    if (changes.doctor !== undefined) newFilter.doctor = changes.doctor
    if (changes.supervisor !== undefined)
      newFilter.supervisor = changes.supervisor
    if (changes.startDate) newFilter.startDate = changes.startDate
    if (changes.endDate) newFilter.endDate = changes.endDate

    this.prescriptionListFilter = newFilter
    this.filteredPrescriptionList = null

    const prescriptionList = await setPrescriptions(
      Services.getInstance().prescription,
      Services.getInstance().kid,
      newFilter,
    )
    this.filteredPrescriptionList = prescriptionList
  }
