import { UIAssignmentHistory, UITimeline } from '@/domains/timeline/ui-models'
import { Agent } from 'Models/agent'
import { AssignmentHistory, Timeline } from 'Models/medical-report'

export const buildUITimeline = (
  agents: Agent[],
  timeline: Timeline | null,
): UITimeline | null => {
  if (!timeline) return null
  const assignmentHistory = buildAssignmentHistory(
    timeline.assignmentHistory,
    agents,
  )

  return {
    ...timeline,
    assignmentHistory,
    // more than 1 agent assigned
    hasAssignmentHistory: assignmentHistory.length > 1,
  }
}

const buildAssignmentHistory = (
  assignmentHistory: AssignmentHistory[],
  agents: Agent[],
): UIAssignmentHistory[] => {
  const history = []

  for (const assignment of assignmentHistory) {
    const agent = agents.find((agent) => agent.id === assignment.agentId)
    if (agent)
      history.push({
        agent,
        assignedAt: new Date(assignment.assignedAt),
      })
  }

  return history
}
