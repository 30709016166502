<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { UISavedReply } from '@/domains/saved-reply/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import Body from '@/components/panel/side/layout/Body.vue'
import Leaf from './Leaf.vue'

const props = defineProps({
  savedReplies: {
    type: Array as PropType<UISavedReply[]>,
    required: true,
  },
  usedSavedReplyIds: {
    type: Array as PropType<string[]> | undefined,
    required: false,
  },
  selectedSavedReply: {
    type: String,
    required: false,
  },
})

const { t } = useScopedI18n()

const hasResult = computed(() => props.savedReplies.length !== 0)
</script>

<template>
  <Body class="py-3 border-r border-default-100 w-1/2">
    <div v-if="!hasResult" class="flex items-center justify-center h-full">
      {{ t('noResult') }}
    </div>
    <div v-for="(savedReply, index) in savedReplies" v-else :key="index">
      <Leaf
        v-if="savedReply.leaf && savedReply.published"
        :is-already-used="usedSavedReplyIds?.includes(savedReply.id) ?? false"
        :node="savedReply"
        :selected-saved-reply="selectedSavedReply"
        v-bind="$attrs"
      />
    </div>
  </Body>
</template>
