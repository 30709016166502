<script lang="ts" setup>
import { computed, PropType } from 'vue'

import BaseCard from './BaseCard.vue'

const props = defineProps({
  list: {
    type: Object as PropType<unknown[]>,
    required: true,
  },
  color: {
    type: String as PropType<'default' | 'white' | 'orange' | 'purple'>,
    default: 'default',
  },
  emptyColor: {
    type: String as PropType<
      'default' | 'white' | 'orange' | 'purple' | undefined
    >,
    required: false,
  },
  itemPadding: {
    type: Boolean,
    default: false,
  },
})

const count = computed(() => props.list.length)
const hasBody = computed(() => count.value > 0)
const disabled = computed(() => count.value === 0)
const realColor = computed(
  () => (disabled.value ? props.emptyColor : null) || props.color,
)
</script>

<template>
  <BaseCard
    :color="realColor"
    :disabled="disabled"
    :body-padding="false"
    :hide-body="!hasBody"
  >
    <template #title>
      <slot name="title" :count="count">Number of items: {{ count }}</slot>
    </template>

    <template #body>
      <div
        v-if="hasBody"
        class="flex flex-col flex-grow overflow-x-hidden divide-y"
        :class="{
          'divide-default-100': color === 'default' || color === 'white',
          'divide-orange-100': color === 'orange',
          'divide-purple-100': color === 'purple',
        }"
      >
        <div
          v-for="(item, index) in list"
          :key="`BaseCardListItem-${index}`"
          :class="{ 'p-3': itemPadding }"
        >
          <slot name="item" v-bind="item"></slot>
        </div>
      </div>
    </template>
  </BaseCard>
</template>
