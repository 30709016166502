import { ActionTree } from './types'
import { UIFormPrescriptedDrug } from '@/domains/prescription/ui-models'
import {
  PrescriptedBrandedDrug,
  PrescriptedDrug,
  PrescriptedDrugDosage,
  PrescriptedDrugDrug,
  Prescription,
} from 'Models/prescription'
import { Drug } from 'Models/drug'
import { BrandedDrug } from 'Models/branded-drug'
import { debounce } from 'throttle-debounce'
import Services from 'Services/services'
import { useRoomStore } from 'Store/stores'

export const changeCurrentPrescription: ActionTree['changeCurrentPrescription'] =
  async function (changes) {
    const currentRoom = useRoomStore().currentRoom

    if (!currentRoom || !this.currentPrescription) return

    const modifiedPrescription = clonePrescription(this.currentPrescription)

    // new doctor (supervisor)
    if (changes.doctor) modifiedPrescription.doctorId = changes.doctor.id

    // new kid's last name?
    if (changes.kidLastName || changes.kidLastName === '')
      modifiedPrescription.kidLastName = changes.kidLastName

    // new kid's weight?
    if (changes.kidWeight !== undefined)
      modifiedPrescription.kidWeightInKg = changes.kidWeight

    // new instructions?
    if (changes.instructions || changes.instructions === '')
      modifiedPrescription.instructions = changes.instructions.split('\n')

    // new prescripted drug?
    if (changes.newPrescriptedDrug)
      addNewPrescriptedDrug(
        modifiedPrescription,
        changes.newPrescriptedDrug,
        this.brandedDrugs,
        this.drugs,
      )

    // toggle replaceable attribute?
    togglePrescriptedDrugReplaceable(
      modifiedPrescription,
      changes.prescriptedDrugToToggleReplaceable,
    )

    // delete prescripted drug?
    deletePrescriptedDrug(modifiedPrescription, changes.prescriptedDrugToDelete)

    this.currentPrescription = modifiedPrescription

    // persist the modifications through the API
    debouncedPersistToDraft(
      Services.getInstance(),
      currentRoom.id,
      this.currentPrescription,
    )
  }

// === Helper functions ===

const addNewPrescriptedDrug = (
  prescription: Prescription,
  form: UIFormPrescriptedDrug,
  brandedDrugs: BrandedDrug[] | null,
  drugs: Drug[] | null,
): void => {
  if (!brandedDrugs || !drugs) return

  const prescriptedDrug = buildPrescriptedDrug(form, brandedDrugs, drugs)

  if (prescriptedDrug) prescription.prescriptedDrugs.push(prescriptedDrug)
}

const togglePrescriptedDrugReplaceable = (
  prescription: Prescription,
  index: number | undefined,
) => {
  // NOTE: be careful, 0 is considered as false in Javascript
  if (index === undefined) return

  prescription.prescriptedDrugs[index].replaceable =
    !prescription.prescriptedDrugs[index].replaceable
}

const deletePrescriptedDrug = (
  prescription: Prescription,
  index: number | undefined,
) => {
  // NOTE: be careful, 0 is considered as false in Javascript
  if (index === undefined) return
  prescription.prescriptedDrugs.splice(index, 1)
}

const clonePrescription = (prescription: Prescription): Prescription => {
  const modifiedPrescription = { ...prescription }

  modifiedPrescription.prescriptedDrugs = prescription.prescriptedDrugs.map(
    (prescriptedDrug: PrescriptedDrug) => ({ ...prescriptedDrug }),
  )

  return modifiedPrescription
}

// === Helper functions to convert a UIFormPrescriptedDrug => PrescriptedDrug ===

const buildPrescriptedDrug = (
  form: UIFormPrescriptedDrug,
  brandedDrugs: BrandedDrug[],
  drugs: Drug[],
): PrescriptedDrug | null => {
  const brandedDrug = buildPrescriptedBrandedDrug(form, brandedDrugs)
  const drug = buildPrescriptedDrugDrug(form, drugs)
  const dosage = buildPrescriptedDrugDosage(form, drugs)
  const duration = form.duration?.value

  if (!brandedDrug || !drug || !duration || !dosage) return null

  return {
    brandedDrug,
    drug,
    dosage,
    duration,
    replaceable: true,
  }
}

const buildPrescriptedBrandedDrug = (
  form: UIFormPrescriptedDrug,
  brandedDrugs: BrandedDrug[],
): PrescriptedBrandedDrug | null => {
  const brandedDrug = brandedDrugs.find(
    (item) => item.id === form.brandedDrug?.id,
  )
  if (!brandedDrug) return null

  return {
    id: brandedDrug.id,
    name: brandedDrug.name,
    shortLabel: brandedDrug.shortLabel,
    molecules: brandedDrug.molecules.map((molecule) => ({
      id: molecule.id,
      name: molecule.name,
      shortLabel: molecule.shortLabel,
    })),
  }
}

const buildPrescriptedDrugDrug = (
  form: UIFormPrescriptedDrug,
  drugs: Drug[],
): PrescriptedDrugDrug | null => {
  const drug = drugs.find((item) => item.id === form.dosage?.drugId)

  if (!drug && !form.dosage?.drugName) return null // shouldn't happen
  if (!drug && form.dosage)
    return { name: form.dosage.drugName, shortLabel: form.dosage.drugName }
  if (!drug) return null

  return {
    id: drug.id,
    name: drug.name,
    shortLabel: drug.shortLabel,
  }
}

const buildPrescriptedDrugDosage = (
  form: UIFormPrescriptedDrug,
  drugs: Drug[],
): PrescriptedDrugDosage | null => {
  const dosages = drugs.map((drug) => drug.dosages).flat()
  const dosage = dosages.find((dosage) => dosage.id === form.dosage?.dosageId)

  if (!dosage && !form.dosage?.dosageName) return null // shouldn't happen
  if (!dosage && form.dosage)
    return { name: form.dosage.dosageName, shortLabel: form.dosage.dosageName }
  if (!dosage) return null

  return {
    id: dosage.id,
    name: dosage.name,
    shortLabel: dosage.shortLabel,
  }
}

// Don't call the API too many times in a row
const debouncedPersistToDraft = debounce(
  1000,
  async (services: Services, roomId: string, prescription: Prescription) => {
    await services.prescription.persistToDraft(roomId, prescription)

    // reload the prescription draft because it has been updated
    // the state will be asynchronously updated as well
    services.roomWorkspace.touch(roomId, prescription)
  },
)
