import { ActionTree } from './types'
import { isEmpty } from '@/utils/is-empty'
import { useAgentStore, useRoomStore } from 'Store/stores'
import Services from 'Services/services'

export const postText: ActionTree['postText'] = function (text) {
  const currentAgent = useAgentStore().currentAgent
  const currentRoom = useRoomStore().currentRoom

  if (!currentAgent || !currentRoom) return

  if (isEmpty(text)) return // Prevent from sending nothing

  Services.getInstance().message.postText(
    currentRoom.id,
    currentAgent,
    text.trimStart(),
  )

  Services.getInstance().localStorage.clearDraftMessage(currentRoom.id)
}
