<script lang="ts" setup>
import { PropType } from 'vue'
import { RoutesNames } from '@/router/routes/type'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIAssignedRoomLists } from '../../ui-models'
import { useRouteIntoTab } from '@/domains/room-list/controllers/route-into-tab'

defineProps({
  assigned: {
    type: Object as PropType<UIAssignedRoomLists>,
    required: true,
  },
})

const { t } = useScopedI18n()
const { isTabAssigned } = useRouteIntoTab()
</script>

<template>
  <router-link
    v-tooltip="t('title')"
    :to="{
      name: RoutesNames.RoomsTeam,
    }"
    :class="
      isTabAssigned ? 'cursor-default shadow-inner' : 'hover:bg-default-50'
    "
    class="flex flex-col"
  >
    <div
      class="flex flex-1 items-center justify-center"
      :class="isTabAssigned && 'pt-1'"
    >
      <div class="relative flex flex-row items-center space-x-3">
        <!-- Team -->
        <div class="flex flex-row items-center space-x-2">
          <font-awesome-icon :icon="['fal', 'inbox-full']" class="text-lg" />
          <div
            class="flex pt-1 font-mono rounded-full items-center justify-center w-6 h-6 text-sm font-semibold bg-gray-100 text-default"
          >
            <div class="flex pb-1">
              {{ assigned.teamCounter }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Border-bottom -->
    <div v-if="isTabAssigned" class="border-b-2 border-primary w-full" />
  </router-link>
</template>
