<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoomListUnassigned } from '../../ui-models'

import BaseLoader from '@/components/base/BaseLoader.vue'
import RoomCardList from '@/domains/room-list/components/lists/shared/RoomCardList.vue'

const props = defineProps({
  roomList: {
    type: Object as PropType<UIRoomListUnassigned>,
    required: true,
  },
})

const { tc, t } = useScopedI18n()

const isLoading = computed(
  () => !props.roomList.recentRooms || !props.roomList.pendingRooms,
)
</script>

<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <div
      class="flex flex-col flex-grow p-5 space-y-5 overflow-x-hidden overflow-y-scroll scrollbar"
    >
      <!-- PENDING -->
      <RoomCardList
        :title="tc('pending', roomList.pendingCounter)"
        :list="roomList.pendingRooms"
        color="orange"
      />

      <!-- ACTIVE -->
      <RoomCardList
        :title="tc('recent', roomList.recentCounter)"
        :list="roomList.recentRooms"
      />

      <!-- INACTIVE -->
      <RoomCardList
        :title="t('inactive')"
        :list="roomList.inactiveRooms"
        :show-count="false"
        color="white"
      />
    </div>

    <div
      v-if="isLoading"
      class="flex flex-col items-center justify-center flex-1"
    >
      <BaseLoader :is-loading="isLoading" />
    </div>
  </div>
</template>
