import {
  DocumentReference,
  CollectionReference,
  orderBy,
  query,
  Query,
  where,
  limit,
  setDoc,
  UpdateData,
  DocumentData,
} from 'firebase/firestore'
import { IFirebaseService } from 'External/firebase/types'
import { Room } from 'Models/room'
import {
  convertFrom,
  convertTo,
  convertAgentTo,
  convertMRLItemsTo,
  convertRoomTo,
} from 'Models/converters/medical-report-converter'
import {
  FBMedicalReport,
  MedicalReport,
  MedicalReportChanges,
} from 'Models/medical-report'
import { IMedicalReportService } from './__types__'
import {
  MRLStrictTopics,
  MRLItem,
  MRLibraries,
} from 'Models/medical-report-library-item'
import { IAPIService } from 'External/api/types'

export default class MedicalReportService implements IMedicalReportService {
  firebaseService: IFirebaseService
  apiService: IAPIService

  constructor(firebaseService: IFirebaseService, apiService: IAPIService) {
    this.firebaseService = firebaseService
    this.apiService = apiService
  }

  async create(room: Room): Promise<MedicalReport | undefined> {
    const medicalReportRef = this.getNewDocument(room.id)
    const medicalReport = convertRoomTo(room)
    await setDoc(medicalReportRef, medicalReport)
    return medicalReport
  }

  update(
    medicalReportId: string,
    changes: MedicalReportChanges,
  ): Promise<void> {
    const realChanges: DocumentData = {}

    if (changes.agent) realChanges.agent = convertAgentTo(changes.agent)
    if (changes.instructions || changes.instructions === '')
      realChanges.instructions = changes.instructions
    if (changes.causes) realChanges.causes = convertMRLItemsTo(changes.causes)
    if (changes.diagnosis)
      realChanges.diagnosis = convertMRLItemsTo(changes.diagnosis)
    if (changes.supervisions)
      realChanges.supervisions = convertMRLItemsTo(changes.supervisions)
    if (changes.initialDirections)
      realChanges.initialDirections = convertMRLItemsTo(
        changes.initialDirections,
      )
    if (changes.directions)
      realChanges.directions = convertMRLItemsTo(changes.directions)
    if (changes.estimatedTimeSpent)
      realChanges.estimatedTimeSpent = changes.estimatedTimeSpent
    if (changes.estimatedComplexity)
      realChanges.estimatedComplexity = changes.estimatedComplexity
    if (changes.medicalOpinionRequested !== undefined)
      realChanges.medicalOpinionRequested = changes.medicalOpinionRequested
    if (changes.requestedDoctor !== undefined)
      realChanges.requestedDoctor = convertAgentTo(changes.requestedDoctor)

    return this.rawUpdate(medicalReportId, realChanges)
  }

  listen(
    room: Room,
    onChange: (newMedicalReport: MedicalReport) => void,
  ): void {
    this.firebaseService.listenCollection(
      `medical-report-${room.id}`,
      this.findAllByRoomId(room.id),
      async () => {
        // NOTE: In order to avoid erasing an existing MR, double check its existence
        const medicalReport = await this.load(room.id)
        if (medicalReport) onChange(medicalReport)
        else return await this.create(room)
      },
    )
  }

  unlisten(medicalReportId: string): void {
    this.firebaseService.unlisten(`medical-report-${medicalReportId}`)
  }

  async loadAll(
    kidId: string,
    limit?: number,
  ): Promise<MedicalReport[] | undefined> {
    return await this.firebaseService.loadDocuments(this.findAll(kidId, limit))
  }

  async load(medicalReportId: string): Promise<MedicalReport | undefined> {
    return await this.firebaseService.loadDocument(this.find(medicalReportId))
  }

  getMRLItemIds(
    topic: MRLStrictTopics,
    medicalReport: MedicalReport,
  ): string[] | undefined {
    switch (topic) {
      case MRLStrictTopics.Causes:
        return medicalReport.causesIds
      case MRLStrictTopics.Diagnosis:
        return medicalReport.diagnosisIds
      case MRLStrictTopics.InitialDirections:
        return medicalReport.initialDirectionsIds
      case MRLStrictTopics.Directions:
        return medicalReport.directionsIds
      case MRLStrictTopics.Supervisions:
        return medicalReport.supervisionsIds
      default:
        return undefined
    }
  }

  getMRLibrary(
    topic: MRLStrictTopics,
    libraries: MRLibraries,
  ): MRLItem[] | null {
    switch (topic) {
      case MRLStrictTopics.Causes:
        return libraries.causes
      case MRLStrictTopics.Diagnosis:
        return libraries.diagnosis
      case MRLStrictTopics.InitialDirections:
      case MRLStrictTopics.Directions:
        return libraries.directions
      case MRLStrictTopics.Supervisions:
        return libraries.supervisions
      default:
        return null
    }
  }

  getTimeline: IMedicalReportService['getTimeline'] = async (roomId) => {
    return await this.apiService.getTimeline(roomId)
  }

  protected findAll(
    kidId: string,
    limitOfResults?: number,
  ): Query<MedicalReport> {
    const constraints = [
      where('kid.id', '==', kidId),
      orderBy('roomCreatedAt', 'desc'),
    ]

    if (limitOfResults) constraints.push(limit(limitOfResults))

    return query(this.getCollection(), ...constraints)
  }

  protected findAllByRoomId(medicalReportId: string): Query<MedicalReport> {
    return query(this.getCollection(), where('roomId', '==', medicalReportId))
  }

  protected find(medicalReportId: string): DocumentReference<MedicalReport> {
    return this.firebaseService.getDocument(
      'medical-reports',
      medicalReportId,
      convertFrom,
    )
  }

  protected getCollection(): CollectionReference<MedicalReport> {
    return this.firebaseService.getCollection<FBMedicalReport, MedicalReport>(
      ['medical-reports'],
      convertFrom,
    )
  }

  protected getNewDocument(roomId: string): DocumentReference<MedicalReport> {
    return this.firebaseService.getDocument<FBMedicalReport, MedicalReport>(
      'medical-reports',
      roomId,
      convertFrom,
      convertTo,
    )
  }

  protected rawUpdate(
    medicalReportId: string,
    data: UpdateData<FBMedicalReport>,
  ): Promise<void> {
    return this.firebaseService.updateDocument(
      'medical-reports',
      medicalReportId,
      data,
    )
  }
}
