<script lang="ts" setup>
import { computed, onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import {
  useKidStore,
  useMedicalReportStore,
  useSavedReplyStore,
  useUIStore,
  useRoomStore,
} from 'Store/stores'
import { useSavedReply } from '@/domains/saved-reply/controllers/saved-reply'
import { UISavedReply } from '../ui-models'

import SearchResult from '@/domains/saved-reply/components/SearchResult.vue'
import SearchInput from '@/domains/saved-reply/components/SearchInput.vue'
import List from '@/domains/saved-reply/components/List.vue'
import SavedReplyPreview from '@/domains/saved-reply/components/SavedReplyPreview.vue'

const emits = defineEmits<{
  (e: 'update:query', query: string): void
  (e: 'appendText', text: string): void
  (e: 'sendImage', image: string): void
}>()

const { currentKid } = storeToRefs(useKidStore())
const { locale } = storeToRefs(useUIStore())
const { currentRoom } = storeToRefs(useRoomStore())
const { completeCurrentMedicalReportWithSavedReply } = useMedicalReportStore()
const savedReplyStore = useSavedReplyStore()

const {
  safeAction,
  uiSavedReplies,
  language,
  queriedSavedReplies,
  isTitleOnly,
  numberOfResults,
  selectedSavedReplyId,
  onSwitchLanguage,
  savedReply,
  query,
} = useSavedReply({
  locale,
  savedReplyStore,
  parentFirstName: currentRoom.value?.parent.firstName,
  kidFirstName: currentKid.value?.firstName,
  kidSex: currentKid.value?.sex,
  markSavedReplyAsUsed: savedReplyStore.markSavedReplyAsUsed,
  completeCurrentMedicalReport: completeCurrentMedicalReportWithSavedReply,
})

const savedReplies = computed(() => savedReplyStore.savedReplies)
const usedSavedReplyIds = computed(() => savedReplyStore.usedSavedReplyIds)

const onPickSavedReply = (
  savedReplyId: string,
  event: 'click' | 'mouseover',
) => {
  selectedSavedReplyId.value = savedReplyId
  if (event !== 'click' || !savedReply.value) return

  if (savedReply.value.isImage) return sendImage(savedReply.value)
  return appendText(savedReply.value)
}

const appendText = (savedReply: UISavedReply) =>
  safeAction(savedReplies.value, savedReply, (text) =>
    emits('appendText', text),
  )
const sendImage = (savedReply: UISavedReply) =>
  safeAction(savedReplies.value, savedReply, (imageUrl) =>
    emits('sendImage', imageUrl),
  )

onBeforeMount(async () => {
  await savedReplyStore.setUsedSavedReplyIds()
})
</script>

<template>
  <div
    v-if="uiSavedReplies && usedSavedReplyIds"
    class="flex flex-1 flex-col min-w-full bg-white z-10 rounded border border-primary shadow-xl border-opacity-40"
  >
    <div class="flex flex-col">
      <div class="flex flex-row h-[40vh]">
        <SearchResult
          v-if="query"
          :used-saved-reply-ids="usedSavedReplyIds"
          :saved-replies="queriedSavedReplies"
          :selected-saved-reply="savedReply?.id"
          @pick-saved-reply="onPickSavedReply"
        />
        <List
          :class="query ? 'hidden' : 'block'"
          :used-saved-reply-ids="usedSavedReplyIds"
          :saved-replies="uiSavedReplies"
          :selected-saved-reply="savedReply?.id"
          @pick-saved-reply="onPickSavedReply"
        />
        <SavedReplyPreview
          v-if="savedReply"
          :saved-reply="savedReply"
          :query="query"
        />
      </div>
      <SearchInput
        v-model:query="query"
        v-model:isTitleOnly="isTitleOnly"
        :language="language"
        :number-of-results="numberOfResults"
        @switch-language="onSwitchLanguage"
      />
    </div>
  </div>
</template>
