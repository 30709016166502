import { ActionTree } from './types'
import { UIRoom } from '../../ui-models'

export const setInceptionChatRooms: ActionTree['setInceptionChatRooms'] =
  function (roomIds, inceptionChats, currentAgent) {
    const rooms = this.rooms

    if (!rooms) return // the rooms haven't been loaded yet

    const uiRooms = roomIds
      .map((roomId) => rooms.find((room) => room.id === roomId) || undefined)
      .filter((room): room is UIRoom => !!room)

    uiRooms.forEach((uiRoom) => {
      const chat = inceptionChats.find((chat) => chat.roomId === uiRoom.id)
      if (!chat) return
      uiRoom.hasUnreadInceptionChatMessages = chat.mustReadAgentIds.includes(
        currentAgent.id,
      )
      uiRoom.hasInceptionChatMessages = chat.hasMessages
      uiRoom.inceptionChatMessagesUpdatedAt = chat.updatedAt
    })

    // don't refresh the UI if no modifications have been brought to the
    // inception chat rooms
    if (JSON.stringify(uiRooms) === JSON.stringify(this.inceptionChatRooms))
      return

    this.inceptionChatRooms = uiRooms
  }
