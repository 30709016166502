import { GetterTree } from './types'
import { getBrandedDrugs } from './get-branded-drugs'
import { getCurrentFilteredPrescription } from './get-current-filtered-prescription'
import { getCurrentPrescriptionPreview } from './get-current-prescription-preview'
import { getCurrentPrescription } from './get-current-prescription'
import { getDrugDosages } from './get-drug-dosages'
import { getFilteredPrescriptionList } from './get-filtered-prescription-list'
import { getPrescriptionPreview } from './get-prescription-preview'
import { getPrescriptionListFilter } from './get-prescription-list-filter'
import { getPrescriptionListFilterParams } from './get-prescription-list-filter-params'

export const getters: GetterTree = {
  getBrandedDrugs,
  getCurrentFilteredPrescription,
  getCurrentPrescriptionPreview,
  getCurrentPrescription,
  getDrugDosages,
  getFilteredPrescriptionList,
  getPrescriptionPreview,
  getPrescriptionListFilter,
  getPrescriptionListFilterParams,
}
