import { GetterTree } from './types'
import { buildRoomListFavorites } from './concerns/build-room-list-favorites'
import { useAgentStore, useRoomStore } from 'Store/stores'

export const getRoomListFavorites: GetterTree['getRoomListFavorites'] =
  function () {
    return (pickedAgentId, roomsStatus) => {
      const favoritesRooms = useRoomStore().getFavoriteRooms
      const agents = useAgentStore().agents

      if (!favoritesRooms || !agents) return null

      return buildRoomListFavorites(
        agents,
        favoritesRooms,
        pickedAgentId,
        roomsStatus,
      )
    }
  }
