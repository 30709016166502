import { computed, ComputedRef, Ref } from 'vue'
import { Agent } from 'Models/agent'
import { getMatches } from '@/utils/matches'

export default function computeTabCounter(
  agents: Ref<Agent[]>,
  query: Ref<string>,
): {
  agentsQueried: ComputedRef<number>
  agentsTotal: ComputedRef<number>
  showAgentsRatio: ComputedRef<boolean>
} {
  const agentsQueried = computed(
    () =>
      getMatches<Agent>(
        {
          items: agents.value,
          fields: ['firstName', 'lastName'],
        },
        query.value,
      ).length,
  )
  const agentsTotal = computed(() => agents.value.length)
  const showAgentsRatio = computed(
    () => agentsQueried.value < agentsTotal.value,
  )

  return { agentsQueried, agentsTotal, showAgentsRatio }
}
