import { GetterTree } from './types'

export const getSavedReply: GetterTree['getSavedReply'] = function () {
  return (savedReplies, savedReplyId) => {
    if (!savedReplies) return null
    const savedReply = savedReplies.find(
      (savedReply) => savedReply.id === savedReplyId,
    )

    if (!savedReply) return null

    return savedReply
  }
}
