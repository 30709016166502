import { RoutesNames } from '@/router/routes/type'
import { ref, Ref } from 'vue'
import { LocationQuery, useRoute, useRouter } from 'vue-router'

export default function useOpenRoom(): {
  canGoToRoom: Ref<boolean>
  goToRoom: (
    roomId: string,
    targetBlank?: boolean,
    parentId?: string,
    hash?: string,
  ) => void
} {
  const canGoToRoom = ref(false)
  const router = useRouter()
  const route = useRoute()

  const goToRoom = (
    roomId: string,
    targetBlank = false,
    parentId?: string,
    hash?: string,
  ) => {
    if (targetBlank) return window.open('/room/' + roomId, '_blank')

    canGoToRoom.value = false // it prevents switching room while opening another room

    let to: To = {
      ...route,
      name: RoutesNames.ShortcutRoom,
      params: { ...route.params, roomId },
      hash: route.params.roomId !== roomId ? hash : route.hash,
    }

    if (parentId) to = { ...to, params: { ...to.params, parentId }, query: {} }

    router.push(to).then(() => (canGoToRoom.value = true)) // Now we can allow switching room again
  }

  return { canGoToRoom, goToRoom }
}

type To = {
  name: RoutesNames
  params: {
    roomId: string
    parentId?: string
  }
  query: LocationQuery
  hash?: string
}
