import { ActionTree } from './types'
import Services from 'Services/services'
import { useMedicalReportStore } from 'Store/stores'
import { KidAntecedent } from 'Models/kid-antecedent'
import { BuiltKidAntecedent } from 'Models/kid'
import { MRLItem } from 'Models/medical-report-library-item'

export const loadKidAntecedents: ActionTree['loadKidAntecedents'] =
  async function () {
    const antecedents = useMedicalReportStore().antecedents

    if (!this.currentKid || !antecedents) return

    const loadedKidAntecedents =
      await Services.getInstance().kidAntecedent.loadKidAntecedents(
        this.currentKid.id,
      )

    const selectedAntecedents = antecedents.filter((antecedent) =>
      loadedKidAntecedents
        .map((loadedKidAntecedent) => loadedKidAntecedent.id)
        .includes(antecedent.id),
    )

    const kidAntecedents = buildKidAntecedents(
      selectedAntecedents,
      loadedKidAntecedents,
    )

    this.currentKid.antecedents = kidAntecedents
    this.currentKid.antecedentIds = kidAntecedents.map(
      (kidAntecedent) => kidAntecedent.id,
    )
    this.currentKid.hiddenAntecedentIds = kidAntecedents
      .filter((kidAntecedent) => kidAntecedent.hidden)
      .map((kidAntecedent) => kidAntecedent.id)
  }

const buildKidAntecedents = (
  selectedAntecedents: MRLItem[],
  kidAntecedents: KidAntecedent[],
): BuiltKidAntecedent[] => {
  return selectedAntecedents.map((selectedAntecedent) => {
    const kidAntecedent = kidAntecedents.find(
      (kidAntecedent) => kidAntecedent.id === selectedAntecedent.id,
    )

    return {
      id: selectedAntecedent.id,
      title: selectedAntecedent.title,
      canBeHidden: selectedAntecedent.canBeHidden ?? false,
      hidden: kidAntecedent ? kidAntecedent.hidden : false,
      severity: selectedAntecedent.severity,
    }
  })
}
