import { GetterTree } from './types'
import { usePlanningStore } from 'Store/stores'
import { buildUITeamStatus } from './concerns/build-ui-team-status'

export const getTeamStatus: GetterTree['getTeamStatus'] = function ({
  agents,
}) {
  if (!agents) return null

  const todayPlanning = usePlanningStore().todayPlanning

  if (!todayPlanning) return null

  return buildUITeamStatus(agents, todayPlanning)
}
