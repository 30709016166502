import { DirectiveBinding, ObjectDirective } from 'vue'
import { removeAccents } from '@/utils/remove-accents'

const directive: VTextHighlightDirective = {
  beforeMount: (el, binding) => {
    highlightTextFromQuery(el, binding)
  },

  updated(el, binding) {
    highlightTextFromQuery(el, binding)
  },
}
export const VTextHighlight = directive

const highlightTextFromQuery = (
  el: HTMLElement,
  binding: DirectiveBinding<VTextHighlightBindingValue>,
) => {
  const { query, content } = binding.value

  if (!content) return

  if (query) {
    return (el.innerHTML = content.replace(
      new RegExp(`${removeAccents(query)}|${query}`, 'gi'),
      (match) => `<mark>${match}</mark>`,
    ))
  }

  if (binding.modifiers.html) return (el.innerHTML = content)

  return (el.textContent = content)
}

export type VTextHighlightDirective = ObjectDirective<
  HTMLElement,
  VTextHighlightBindingValue
>

type VTextHighlightBindingValue = {
  query?: string
  content?: string
}
