import { GetterTree } from './types'
import { buildRoomListMe } from './concerns/build-assigned-room-lists'
import { UIRoom } from '../../ui-models'
import { useAgentStore } from 'Store/stores'
import Services from 'Services/services'
import { Agent } from 'Models/agent'

export const getRoomListMe: GetterTree['getRoomListMe'] = function ({
  rooms,
  inceptionChatRooms,
}) {
  return (now) => {
    const currentAgent = useAgentStore().currentAgent

    if (!currentAgent || !rooms) return null

    const roomsAssignedToCurrentAgent =
      Services.getInstance().room.filterAssigned(
        rooms,
        currentAgent.id,
      ) as UIRoom[]

    const roomsAwakeAndAssignedToCurrentAgent = getRoomsAssignedToCurrentAgent(
      Services.getInstance().room.filterSnoozed(rooms, true) as UIRoom[],
      currentAgent,
    )

    const roomsAsleepAndAssignedToCurrentAgent = getRoomsAssignedToCurrentAgent(
      Services.getInstance().room.filterSnoozed(rooms, false) as UIRoom[],
      currentAgent,
    )

    return buildRoomListMe(
      roomsAssignedToCurrentAgent,
      roomsAwakeAndAssignedToCurrentAgent,
      roomsAsleepAndAssignedToCurrentAgent,
      inceptionChatRooms,
      currentAgent,
      now,
      Services.getInstance().room,
    )
  }
}

const getRoomsAssignedToCurrentAgent = (rooms: UIRoom[], currentAgent: Agent) =>
  rooms.filter((room) => room.assignedAgent?.id === currentAgent.id)
