<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { Chart as Highcharts } from 'highcharts-vue'
import { Options, XrangePointOptionsObject } from 'highcharts'
import useScopedI18n from '@/hooks/scoped-i18n'
import boyGrowthData from '@/assets/growth-boy.json'
import girlGrowthData from '@/assets/growth-girl.json'
import { GrowthSubDataset } from '@/domains/kid/models'
import { UIKidMeasureValue } from '@/domains/kid/ui-models'
import {
  getTickInterval,
  getMaxMonth,
  getChartRanges,
  getChartSeries,
  formatTooltip,
  formatXAxisLabel,
  formatYAxisLabel,
} from '@/domains/kid/helpers/measure-helpers'

const props = defineProps({
  ageInMonths: {
    type: Number,
    required: true,
  },
  sex: {
    type: String as PropType<'female' | 'male'>,
    required: true,
  },
  data: {
    type: Array as PropType<UIKidMeasureValue[]>,
    required: true,
    default: () => [],
  },
})

const { t, tc } = useScopedI18n()

const growthData = computed(() =>
  props.sex === 'female' ? girlGrowthData : boyGrowthData,
)

const heightData = computed<GrowthSubDataset>(() => growthData.value.height)
const tickInterval = computed(() => getTickInterval(props.ageInMonths))
const maxMonth = computed(() => getMaxMonth(props.ageInMonths))

const transformY = (y: number) => y * 100
const getRanges = (typeA: string, typeB: string): [number, number, number][] =>
  getChartRanges(heightData, maxMonth, typeA, typeB, transformY)

const getSeries = (type: string): XrangePointOptionsObject[] =>
  getChartSeries(heightData, maxMonth, type, transformY)

const chartOptions = computed(() => {
  const chartOptions: Options = {
    title: {
      text: undefined,
    },

    chart: {
      type: 'line',
      width: 800,
      height: 600,
      zoomType: 'x',
    },

    tooltip: {
      formatter: function () {
        return formatTooltip(this, tc)
      },
    },

    yAxis: {
      title: { text: t('yAxis') },
      labels: {
        formatter: function () {
          return formatYAxisLabel(this.value as number, tc)
        },
      },
    },

    xAxis: {
      title: { text: t('xAxis') },
      tickInterval: tickInterval.value,
      labels: {
        formatter: function () {
          return formatXAxisLabel(this.value as number, tc)
        },
      },
    },

    series: [
      {
        name: '+2 α / -2 α',
        data: getRanges('+2 α', '-2 α'),
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: '#FEF6DF',
        fillOpacity: 1,
        zIndex: 0,
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
      },
      {
        name: '-3 α / -2 α',
        data: getRanges('-3 α', '-2 α'),
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: '#FFE3A4',
        fillOpacity: 1,
        zIndex: 0,
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
      },
      {
        name: '-1 α',
        type: 'line',
        data: getSeries('-1 α'),
        color: '#3F81F7',
        opacity: 0.4,
        dashStyle: 'ShortDot',
        enableMouseTracking: false,
      },
      {
        name: 'M',
        type: 'line',
        data: getSeries('M'),
        color: '#3F81F7',
        opacity: 0.4,
        enableMouseTracking: false,
      },
      {
        name: 'Kid',
        type: 'line',
        data: props.data,
        color: '#3F81F7',
        opacity: 1,
        zIndex: 1,
        marker: {
          enabled: true,
          radius: 3,
          symbol: 'circle',
        },
      },
      {
        name: '+1 α',
        data: getSeries('+1 α'),
        color: '#3F81F7',
        type: 'line',
        opacity: 0.4,
        dashStyle: 'ShortDot',
        enableMouseTracking: false,
      },
      {
        name: '+2 α / +3 α',
        data: getRanges('+2 α', '+3 α'),
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: '#FFE3A4',
        fillOpacity: 1,
        zIndex: 0,
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
      },
    ],
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  }
  return chartOptions
})
</script>

<template>
  <Highcharts class="hc" :options="chartOptions"></Highcharts>
</template>
