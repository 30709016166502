export type APIBrandedDrug = {
  id: string
  name: string
  shortLabel: string
  mobileLabel: string
  molecules: APIBrandedDrugMolecule[]
  filters: APIBrandedDrugFilter
}

export type APIBrandedDrugMolecule = {
  id: string
  shortLabel: string
  mobileLabel: string
  name: string
  createdBy: string
  details: string
}

export type APIBrandedDrugFilter = {
  gender: APIBrandedDrugFilterGender
  minAge: number
  maxAge: number
  agentGroupIds: string[]
}

export enum APIBrandedDrugFilterGender {
  All = 'ALL',
  Boy = 'BOY',
  Girl = 'GIRL',
}

export type BrandedDrug = {
  id: string
  name: string
  shortLabel: string
  mobileLabel: string
  molecules: BrandedDrugMolecule[]
  filter: BrandedDrugFilter
}

export type BrandedDrugMolecule = {
  id: string
  name: string
  shortLabel: string
  mobileLabel: string
}

export type BrandedDrugFilter = {
  sex: BrandedDrugFilterSex
  minAge: number
  maxAge: number
  agentGroupIds: string[]
}

export enum BrandedDrugFilterSex {
  All = 'all',
  Female = 'female',
  Male = 'male',
}
