import {
  DocumentData,
  WithFieldValue,
  serverTimestamp,
} from 'firebase/firestore'
import { FBInceptionChat, InceptionChat } from 'Models/inception-chat'

export const convertFrom = (fbChat: FBInceptionChat): InceptionChat => ({
  id: fbChat.id,
  roomId: fbChat.roomId,
  agentIds: fbChat.agentIds,
  mustReadAgentIds: fbChat.mustReadAgentIds,
  hasMessages: fbChat.hasMessages,
  open: fbChat.open,
  updatedAt: fbChat.updatedAt?.toDate() || new Date(),
})

export const convertTo = (
  chat: WithFieldValue<InceptionChat>,
): DocumentData => {
  return {
    roomId: chat.roomId,
    agentIds: chat.agentIds,
    mustReadAgentIds: chat.mustReadAgentIds,
    hasMessages: chat.hasMessages,
    open: chat.open,
    updatedAt: serverTimestamp(),
  }
}
