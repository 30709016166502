import { ActionTree } from './types'

import { acceptCurrentRoomAssignment } from './accept-current-room-assignment'
import { assignCurrentRoom } from './assign-current-room'
import { closeRoom } from './close-room'
import { listenCurrentRoomWorkspace } from './listen-current-room-workspace'
import { reOpenRoom } from './re-open-room'
import { refuseCurrentRoomAssignment } from './refuse-current-room-assignment'
import { resetCurrentKidParentRooms } from './reset-current-kid-parent-rooms'
import { resetCurrentRoom } from './reset-current-room'
import { setClosedRoomsDate } from './set-closed-rooms-date'
import { setClosedRooms } from './set-closed-rooms'
import { setCurrentKidParentRooms } from './set-current-kid-parent-rooms'
import { setCurrentRoom } from './set-current-room'
import { snoozeRoom } from './snooze-room'
import { toggleCurrentRoomAsFavorite } from './toggle-current-room-as-favorite'
import { unassignCurrentRoom } from './unassign-current-room'

export const actions: ActionTree = {
  acceptCurrentRoomAssignment,
  assignCurrentRoom,
  closeRoom,
  listenCurrentRoomWorkspace,
  reOpenRoom,
  refuseCurrentRoomAssignment,
  resetCurrentKidParentRooms,
  resetCurrentRoom,
  setClosedRoomsDate,
  setClosedRooms,
  setCurrentKidParentRooms,
  setCurrentRoom,
  snoozeRoom,
  toggleCurrentRoomAsFavorite,
  unassignCurrentRoom,
}
