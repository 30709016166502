import { isEmpty } from '@/utils/is-empty'
import {
  Prescription,
  PrescriptionDraft,
  PrescriptionType,
  PrescriptionStatus,
  PrescriptedDrug,
  PrescriptionMolecule,
  PrescriptionDraftDrug,
  PrescriptionDraftMolecule,
  APIPrescriptionForm,
  APIPrescriptionDrugForm,
  APIPrescription,
  APIPrescriptionDrug,
  APIPrescriptionMolecule,
  APIPrescriptionPreview,
  PrescriptionPreview,
} from 'Models/prescription'

// === API ===

export const convertFromAPI = (prescription: APIPrescription): Prescription => {
  return {
    id: prescription.id,
    roomId: prescription.roomId,
    agentId: prescription.agentId,
    doctorId: prescription.doctorId,
    userId: prescription.userId,
    kidId: prescription.kidId,
    kidWeightInKg: prescription.kidWeightInKg,
    hadKidLastName: true,
    type: prescription.type,
    status: PrescriptionStatus.Created,
    instructions: prescription.instructions,
    couldNotBePersisted: false,
    createdAt: new Date(prescription.createdAt),
    pdfFileInBase64: prescription.pdfFileInBase64,
    mobile: prescription.mobile,
    prescriptedDrugs: prescription.prescriptedDrugs.map(
      convertPrescriptedDrugFromAPI,
    ),
  }
}

export const convertPreviewFromAPI = (
  prescription: APIPrescriptionPreview,
): PrescriptionPreview => {
  return {
    agentId: prescription.agentId,
    type: prescription.type,
    createdAt: new Date(prescription.createdAt),
    pdfFileInBase64: prescription.pdfFileInBase64,
    mobile: prescription.mobile,
  }
}

const convertPrescriptedDrugFromAPI = (
  drug: APIPrescriptionDrug,
): PrescriptedDrug => {
  const apiDrug = drug.brandedDrug.drugs ? drug.brandedDrug.drugs[0] : null
  const apiDosage = apiDrug ? apiDrug.dosages[0] : null
  const prescriptedDrug: PrescriptedDrug = {
    brandedDrug: {
      id: drug.brandedDrug.id,
      name: drug.brandedDrug.name,
      shortLabel: drug.brandedDrug.shortLabel,
      molecules: drug.brandedDrug.molecules.map(
        convertPrescriptedDrugMoleculeFromAPI,
      ),
    },
    drug: {
      id: apiDrug?.id,
      name: apiDrug?.name || drug.drug || '?',
      shortLabel: apiDrug?.shortLabel || drug.drug || '?',
    },
    dosage: {
      id: apiDosage?.id || drug.dosageId,
      name: apiDosage?.name || drug.dosage || '?',
      shortLabel: apiDosage?.shortLabel || drug.dosage || '?',
    },
    duration: drug.duration,
    replaceable: drug.replaceable,
  }

  if (!prescriptedDrug.drug.id) delete prescriptedDrug.drug.id
  if (!prescriptedDrug.dosage.id) delete prescriptedDrug.dosage.id

  return prescriptedDrug
}

const convertPrescriptedDrugMoleculeFromAPI = (
  molecule: APIPrescriptionMolecule,
): PrescriptionMolecule => {
  return {
    id: molecule.id,
    name: molecule.name,
    shortLabel: molecule.shortLabel,
  }
}

export const convertToAPIForm = (
  prescription: Prescription,
): APIPrescriptionForm => {
  const form: APIPrescriptionForm = {
    type: prescription.type,
    agentId: prescription.agentId,
    doctorId: prescription.doctorId,
    userId: prescription.userId,
    kidId: prescription.kidId,
    kidWeightInKg: prescription.kidWeightInKg,
    prescriptedDrugs: prescription.prescriptedDrugs.map(
      convertPrescriptedDrugToAPIForm,
    ),
  }

  if (prescription.kidLastName) form.kidLastName = prescription.kidLastName

  if (!isEmpty(prescription.instructions))
    form.instructions = prescription.instructions

  return form
}

const convertPrescriptedDrugToAPIForm = (
  prescriptedDrug: PrescriptedDrug,
): APIPrescriptionDrugForm => {
  if (prescriptedDrug.dosage.id)
    return {
      dosageId: prescriptedDrug.dosage.id,
      replaceable: prescriptedDrug.replaceable,
      duration: prescriptedDrug.duration,
    }

  return {
    brandedDrugId: prescriptedDrug.brandedDrug.id,
    drug: prescriptedDrug.drug.name,
    dosage: prescriptedDrug.dosage.name,
    replaceable: prescriptedDrug.replaceable,
    duration: prescriptedDrug.duration,
  }
}

// === DRAFT ===

export const convertFromDraft = (draft: PrescriptionDraft): Prescription => {
  return {
    roomId: draft.roomId,
    agentId: draft.agentId,
    doctorId: draft.doctorId,
    userId: draft.userId,
    kidId: draft.kidId,
    kidLastName: draft.kidLastName,
    hadKidLastName: !isEmpty(draft.kidLastName),
    kidWeightInKg: parseFloat(draft.kidWeight),
    type: PrescriptionType.Treatment,
    status: PrescriptionStatus.Draft,
    instructions: (draft.instructions || '').split('\n'),
    prescriptedDrugs: (
      draft.treatmentItems ||
      draft.prescriptedDrugs ||
      []
    ).map((item) => convertPrescriptedDrugFromDraft(item)),
  }
}

const convertPrescriptedDrugFromDraft = (
  item: PrescriptionDraftDrug,
): PrescriptedDrug => {
  const drug = {
    brandedDrug: {
      id: item.brandedDrug.id,
      name: item.brandedDrug.name,
      shortLabel: item.brandedDrug.shortLabel,
      molecules: item.brandedDrug.molecules.map((molecule) =>
        convertPrescriptionMoleculeFromDraft(molecule),
      ),
    },
    drug: {
      id: item.drug.id,
      name: item.drug.name,
      shortLabel: item.drug.shortLabel,
    },
    dosage: {
      id: item.dosage.id,
      name: item.dosage.name,
      shortLabel: item.dosage.shortLabel,
    },
    duration: item.duration,
    replaceable: item.replaceable,
  }

  if (!drug.drug.id) delete drug.drug.id
  if (!drug.dosage.id) delete drug.dosage.id

  return drug
}

const convertPrescriptionMoleculeFromDraft = (
  molecule: PrescriptionDraftMolecule,
): PrescriptionMolecule => {
  return {
    id: molecule.id,
    name: molecule.name,
    details: molecule.details,
    shortLabel: molecule.shortLabel,
  }
}

export const convertToDraft = (
  prescription: Prescription,
): PrescriptionDraft => {
  return {
    roomId: prescription.roomId,
    agentId: prescription.agentId,
    doctorId: prescription.doctorId,
    userId: prescription.userId,
    kidId: prescription.kidId,
    kidLastName: prescription.kidLastName,
    kidWeight: prescription.kidWeightInKg.toString(),
    instructions: prescription.instructions?.join('\n') || '',
    prescriptedDrugs: prescription.prescriptedDrugs.map(
      convertToPrescriptedDrugDraft,
    ),
    treatmentItems: prescription.prescriptedDrugs.map(
      convertToPrescriptedDrugDraft,
    ), // NOTE: to keep compatability with the Desk v1
  }
}

const convertToPrescriptedDrugDraft = (
  prescriptedDrug: PrescriptedDrug,
): PrescriptionDraftDrug => {
  const drug = {
    brandedDrug: {
      id: prescriptedDrug.brandedDrug.id,
      name: prescriptedDrug.brandedDrug.name,
      shortLabel: prescriptedDrug.brandedDrug.shortLabel,
      molecules: prescriptedDrug.brandedDrug.molecules.map((molecule) =>
        convertToPrescriptionMoleculeDraft(molecule),
      ),
    },
    drug: {
      id: prescriptedDrug.drug.id,
      name: prescriptedDrug.drug.name,
      shortLabel: prescriptedDrug.drug.shortLabel,
    },
    dosage: {
      id: prescriptedDrug.dosage.id,
      name: prescriptedDrug.dosage.name,
      shortLabel: prescriptedDrug.dosage.shortLabel,
    },
    duration: prescriptedDrug.duration,
    replaceable: prescriptedDrug.replaceable,
  }

  if (!drug.drug.id) delete drug.drug.id
  if (!drug.dosage.id) delete drug.dosage.id

  return drug
}

const convertToPrescriptionMoleculeDraft = (
  molecule: PrescriptionMolecule,
): PrescriptionDraftMolecule => {
  return {
    id: molecule.id,
    name: molecule.name,
    shortLabel: molecule.shortLabel,
  }
}
