import { ActionTree } from './types'
import { useUIStore } from 'Store/stores'
import { fail } from './concerns/fail'
import i18n from '@/plugins/vue-use/i18n'
import Services from 'Services/services'

export const resetPassword: ActionTree['resetPassword'] = async function (
  attributes,
) {
  try {
    await Services.getInstance().authentication.resetPassword(attributes)
    useUIStore().setIsPasswordRegistered(true)
  } catch (error) {
    fail(error, i18n.global.t('alerts.auth.wrongResetPassword'))
  }
}
