<script lang="ts" setup>
import { computed, Ref, ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useAsyncRunner } from '@/hooks/async-runner'
import useServices from '@/hooks/services'
import { useAuthStore } from 'Store/stores'

import BaseModal from '@/components/base/BaseModal.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'

const { t } = useScopedI18n()
const services = useServices()
const { signOut } = useAuthStore()
const { run, isRunning } = useAsyncRunner()

const password: Ref<string> = ref('')
const passwordConfirmation: Ref<string> = ref('')

const isValid = computed(
  () =>
    password.value === passwordConfirmation.value && password.value.length > 8,
)
const disabledButton = computed(() => isRunning.value || !isValid.value)

const submit = () =>
  run(() =>
    services.authentication.changeCurrentPassword(password.value).then(() => {
      signOut()
    }),
  )
</script>

<template>
  <BaseModal :small="true">
    <template #header>
      <h2 class="font-semibold text-base">{{ t('title') }}</h2>
    </template>

    <div class="py-8 px-8">
      <form class="flex flex-col space-y-4" @submit.prevent="submit">
        <input
          v-model="password"
          :placeholder="t('placeholders.password')"
          name="password"
          type="password"
          class="px-4 py-3 border-2 rounded border-blue-50 bg-blue-50 focus:outline-none focus:border-2 focus:border-blue-400 xl:text-sm"
        />
        <input
          v-model="passwordConfirmation"
          :placeholder="t('placeholders.passwordConfirmation')"
          name="passwordConfirmation"
          type="password"
          class="px-4 py-3 border-2 rounded border-blue-50 bg-blue-50 focus:outline-none focus:border-2 focus:border-blue-400 xl:text-sm"
        />
        <BasePrimaryButton
          :disabled="disabledButton"
          class="flex items-center justify-center space-x-5"
        >
          <span>{{ t('submit') }}</span>
          <BaseLoader
            v-if="isRunning"
            class="flex-grow-0"
            :is-loading="true"
            :icon-size="20"
          />
        </BasePrimaryButton>
      </form>
    </div>
  </BaseModal>
</template>
