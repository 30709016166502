<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

defineProps({
  isMarkedHasFavorite: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'toggleMarkAsFavorite'): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <button
    class="w-8 h-8 hover:bg-default-100 rounded flex items-center justify-center"
    @click="emits('toggleMarkAsFavorite')"
  >
    <font-awesome-icon
      v-tooltip.bottom="
        t(isMarkedHasFavorite ? 'unmarkTooltip' : 'markTooltip')
      "
      :icon="[isMarkedHasFavorite ? 'fas' : 'fal', 'thumbtack']"
      class="focus:outline-none text-primary"
    />
  </button>
</template>
