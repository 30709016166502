import {kidAgeInMonths} from '@/utils/kid-age-in-months'
import {removeAccents} from '@/utils/remove-accents'
import {
    CollectionReference,
    DocumentReference,
    Query,
    query,
    where,
} from 'firebase/firestore'
import {IFirebaseService} from 'External/firebase/types'
import {convertFrom} from 'Models/converters/medical-report-library-item-converter'
import {Kid} from 'Models/kid'
import {
    FBMRLItem,
    MRLItem,
    MRLItemFilterSexType,
    MRLTopics,
} from 'Models/medical-report-library-item'
import {IMedicalReportLibraryService} from './__types__'

export default class MedicalReportLibraryService
    implements IMedicalReportLibraryService {
    firebaseService: IFirebaseService

    constructor(firebaseService: IFirebaseService) {
        this.firebaseService = firebaseService
    }

    async listenAll(
        topicName: MRLTopics,
        onChange: (newMRLItems: MRLItem[]) => void,
    ): Promise<void> {
        await this.firebaseService.listenCollection(
            `medical-report-library-${topicName}`,
            this.findAll(topicName),
            (newMRLItems: MRLItem[]) => {
                onChange(newMRLItems)
            },
        )
    }

    loadAll(
        topicName: MRLTopics,
        ids?: string[],
    ): Promise<(MRLItem | undefined)[] | undefined> {
        if (ids) {
            return Promise.all(
                ids.map(
                    async (id) =>
                        await this.firebaseService.loadDocument(this.find(topicName, id)),
                ),
            )
        } else return this.firebaseService.loadDocuments(this.findAll(topicName))
    }

    async loadAllWithParents(
        topicName: MRLTopics,
        ids?: string[],
    ): Promise<(MRLItem | undefined)[] | undefined> {
        if (!ids || ids.length === 0) return []
        const mrlItems = await this.loadAll(topicName, ids)

        if (!mrlItems) return []

        const parentIds: string[] = mrlItems
            .map((item) => item?.parentId)
            .filter((id): id is string => id !== undefined && id !== '')
        const parents = await this.loadAllWithParents(topicName, parentIds)

        return parents ? [...(mrlItems || []), ...(parents || [])] : mrlItems
    }

    filter(
        items: MRLItem[],
        query: string,
        kid: Kid,
        agentGroupIds: string[],
        locale: string,
        rejectedIds?: string[],
    ): MRLItem[] {
        return items.filter(
            (item) =>
                (!rejectedIds || rejectedIds.indexOf(item.id) === -1) &&
                this.acceptItem(item, query, kid, agentGroupIds, locale),
        )
    }

    protected findAll(topicName: MRLTopics): Query<MRLItem> {
        return query(this.getCollection(topicName), where('published', '==', true))
    }

    protected find(topicName: MRLTopics, id: string): DocumentReference<MRLItem> {
        return this.firebaseService.getDocument(
            ['medical-report-library', topicName, 'items'],
            id,
            convertFrom,
        )
    }

    protected getCollection(topicName: MRLTopics): CollectionReference<MRLItem> {
        return this.firebaseService.getCollection<FBMRLItem, MRLItem>(
            ['medical-report-library', topicName, 'items'],
            convertFrom,
        )
    }

    protected acceptItem(
        item: MRLItem,
        query: string,
        kid: Kid,
        agentGroupIds: string[],
        locale: string,
    ): boolean {
        return (
            this.doesItemMatchTitle(item, query, locale) &&
            this.doesItemMatchFilters(item, kid) &&
            this.doesItemMatchAgentGroups(item, agentGroupIds)
        )
    }

    protected doesItemMatchTitle(
        item: MRLItem,
        query: string,
        locale: string,
    ): boolean {
        return (
            !query ||
            query.trim() === '' ||
            removeAccents(item.title[locale])
                .toLocaleLowerCase()
                .includes(removeAccents(query).toLocaleLowerCase())
        )
    }

    protected doesItemMatchFilters(item: MRLItem, kid: Kid): boolean {
        const ageInMonths = kidAgeInMonths(kid.birthDate)
        if (!item.filters) return true
        return (item.filters.sex === MRLItemFilterSexType.All || kid.sex.valueOf() === item.filters.sex.valueOf())
    }

    protected doesItemMatchAgentGroups(
        item: MRLItem,
        agentGroupIds: string[],
    ): boolean {
        if (!item.agentGroupIds || item.agentGroupIds.length === 0) return true
        return (
            item.agentGroupIds.includes('all') ||
            item.agentGroupIds.some((groupId) => agentGroupIds.includes(groupId))
        )
    }
}
