<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useMessageColors } from '@/domains/core-chat/controllers/message-colors'
import { UICoreChatMessageColor } from '@/domains/core-chat/ui-models'

const props = defineProps({
  color: {
    type: String as PropType<UICoreChatMessageColor>,
    required: false,
    default: 'default',
  },
})

const { t } = useScopedI18n()

const color = computed(() => props.color)
const { messageColors } = useMessageColors(color)
</script>

<template>
  <div
    class="text-primary relative overflow-hidden flex flex-row items-center justify-center space-x-2 cursor-pointer w-fit py-2 px-3 rounded-xl max-w-full border"
    :class="messageColors"
  >
    <font-awesome-icon class="text-2xl" :icon="['fas', 'play-circle']" />
    <div>{{ t('play') }}</div>
  </div>
</template>
