import { GetterTree } from './types'
import { buildUIRoom } from '@/domains/room-list/store/concerns/build-ui-room'
import { useAgentStore } from 'Store/stores'

export const getCurrentRoom: GetterTree['getCurrentRoom'] = function ({
  currentRoom,
}) {
  const currentAgent = useAgentStore().currentAgent

  if (!currentRoom || !currentAgent) return null

  return buildUIRoom(currentRoom, currentAgent.id)
}
