'use strict'
import axios, { AxiosResponse } from 'axios'
import APP_VERSION from 'External/api/version'
import { API_URL } from 'External/api/config'
import { instance as honeybadger } from '@/plugins/vue-use/honeybadger'

// NOTE: Full config: https://github.com/axios/axios#request-config
// axios.defaults.baseURL = API_URL || ''
// axios.defaults.headers.common['x-biloba-desk'] = APP_VERSION
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let jwt: string
export function setJwt(newJwt: string): void {
  jwt = newJwt
}

const config = {
  baseURL: API_URL || '',
  headers: { 'X-Biloba-desk': APP_VERSION },
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const Axios = axios.create(config)

// Add a request interceptor
Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (jwt && config.headers) {
      config.headers['Authorization'] = jwt
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
Axios.interceptors.response.use(
  function (response) {
    return parseAjaxResponse(response, [200, 201, 204])
  },
  function (error) {
    if (error.response?.status === 401) {
      return error.response
    }

    // Let us know which error it was
    honeybadger?.notify(error, 'API response error')

    return Promise.reject(error)
  },
)

export default Axios

// Put
export function put<P, T>(requestUrl: string, attributes: P): Promise<T> {
  return Axios.put(requestUrl, attributes)
}

// Post
export function post<P, T>(requestUrl: string, attributes: P): Promise<T> {
  return Axios.post(requestUrl, attributes)
}

// Get
export function get<P, T>(requestUrl: string, attributes?: P): Promise<T> {
  return Axios.get(requestUrl, attributes)
}

// Delete
export function remove<P, T>(requestUrl: string, attributes?: P): Promise<T> {
  return Axios.delete(requestUrl, attributes)
}

const parseAjaxResponse = (response: AxiosResponse, codes: number[]) => {
  if (typeof codes !== 'object') codes = [codes]
  if (codes.indexOf(response.status) === -1) throw Error(response.data.message)
  let item = response.data
  if (typeof item !== 'object' || item.error) {
    item = undefined
  }
  return item
}
