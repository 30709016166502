<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { useRoute } from 'vue-router'
import { RoutesNames } from '@/router/routes/type'
import { Message } from 'Models/message'
import { PanelPosition } from '@/domains/panel/store/types'
import useRotateImage from '@/hooks/rotate-image'
import useScopedI18n from '@/hooks/scoped-i18n'

import Header from '@/views/layouts/panels/Header.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import Video from './Video.vue'
import BaseImageOverlay from '@/components/base/BaseImageOverlay.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'
import Title from '@/components/panel/side/shared/Title.vue'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  media: {
    type: Object as PropType<Message>,
    required: true,
  },
  isCurrentMediaTheFirstOne: {
    type: Boolean,
    required: true,
  },
  isCurrentMediaTheLastOne: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'changeMedia', d: 'prev' | 'next'): void
}>()

const route = useRoute()
const { onRotateImage } = useRotateImage()
const { t } = useScopedI18n()

const isMediaLoaded = ref(false)
const prev = ref()
const next = ref()
const image = ref()

const isImage = computed(() => props.media.type === 'image')
const routeName = computed(() => route.name as RoutesNames)

const onMediaLoad = () => (isMediaLoaded.value = true)
const onChangeMedia = (ref: 'prev' | 'next') => emits('changeMedia', ref)
</script>

<template>
  <Header
    :panel-position="panelPosition"
    :to-route-name="routeName"
    class="rounded-t-lg"
  >
    <Title>
      {{ media.createdAt && $formatDate(media.createdAt, 'long') }}
    </Title>
  </Header>
  <Body class="items-center flex-1 p-5 overflow-y-hidden">
    <BaseLoader v-if="isImage" :is-loading="!isMediaLoaded">
      <div class="flex flex-wrap w-full h-full items-center justify-center">
        <BaseImageOverlay :src="media.content">
          <img
            ref="image"
            class="max-h-full max-w-full cursor-zoom-in"
            :src="media.content"
            alt=""
            @load="onMediaLoad"
          />
        </BaseImageOverlay>
      </div>
    </BaseLoader>
    <Video v-else :src="media.content" />
  </Body>
  <div class="flex items-center h-16 px-5 border-t border-default-100">
    <div class="flex w-full">
      <button
        ref="prev"
        v-keydown:ArrowLeft="onChangeMedia"
        :class="isCurrentMediaTheFirstOne ? 'invisible' : 'visible'"
        class="flex items-center justify-left text-primary w-1/3"
        @click="onChangeMedia('prev')"
      >
        <font-awesome-icon
          v-tooltip.auto="t('prev')"
          :icon="['fal', 'chevron-square-left']"
          class="mr-3 text-2xl focus:outline-none"
        />
      </button>
      <button
        v-if="isImage"
        ref="rotate"
        class="flex items-center justify-center text-primary w-1/3"
        @click="onRotateImage(image)"
      >
        <font-awesome-icon
          v-tooltip.auto="t('rotate')"
          :icon="['fal', 'sync-alt']"
          class="mr-3 text-2xl focus:outline-none"
        />
      </button>
      <button
        ref="next"
        v-keydown:ArrowRight="onChangeMedia"
        :class="isCurrentMediaTheLastOne ? 'invisible' : 'visible'"
        class="flex justify-end flex-1 text-primary w-1/3"
        @click="onChangeMedia('next')"
      >
        <font-awesome-icon
          v-tooltip.auto="t('next')"
          :icon="['fal', 'chevron-square-right']"
          class="ml-3 text-2xl focus:outline-none"
        />
      </button>
    </div>
  </div>
</template>
