import {
  RoomsGroupedByAgent,
  RoomsGroupedByKid,
  RoomsGroupedByParent,
  UIRoom,
} from '@/domains/room-list/ui-models'
import compareAsc from 'date-fns/compareAsc'
import { RoomAgent, RoomKid, RoomParent } from 'Models/room'

type RoomsGroupedBy<T> = {
  kid: RoomsGroupedByKid
  parent: RoomsGroupedByParent
  agent: RoomsGroupedByAgent<T>
}
// Signatures overloads
function groupRoomsBy<T extends RoomKid>(
  entityName: 'kid',
  entities: T[],
  rooms: UIRoom[],
  keepEntitiesWithoutRooms?: boolean,
): RoomsGroupedByKid<T>[]
function groupRoomsBy<T extends RoomParent>(
  entityName: 'parent',
  entities: T[],
  rooms: UIRoom[],
  keepEntitiesWithoutRooms?: boolean,
): RoomsGroupedByParent<T>[]
function groupRoomsBy<T extends RoomAgent>(
  entityName: 'agent',
  entities: T[],
  rooms: UIRoom[],
  keepEntitiesWithoutRooms?: boolean,
): RoomsGroupedByAgent<T>[]

// Implementation
function groupRoomsBy<T>(
  entityName: 'kid' | 'parent' | 'agent',
  entities: RoomKid[] | RoomParent[] | RoomAgent[],
  rooms: UIRoom[],
  keepEntitiesWithoutRooms = false,
): Partial<RoomsGroupedBy<T>[typeof entityName]>[] {
  const roomsGroupedBy = entities.map((entity) => {
    const filteredRooms = rooms
      .filter(
        (room) =>
          // because agent doesn't exist on UIRoom
          room[entityName === 'agent' ? 'assignedAgent' : entityName]?.id ===
          entity.id,
      )
      // Sort rooms by date
      .sort((roomA, roomB) =>
        compareAsc(
          roomB.lastMessage?.createdAt ?? roomB.createdAt,
          roomA.lastMessage?.createdAt ?? roomA.createdAt,
        ),
      )

    const group: Partial<RoomsGroupedBy<T>[typeof entityName]> = {
      [entityName]: entity,
      rooms: filteredRooms,
      counter: filteredRooms.length,
    }
    return group
  })

  const groups = keepEntitiesWithoutRooms
    ? roomsGroupedBy
    : roomsGroupedBy.filter(({ rooms }) => rooms && rooms.length > 0)

  return groups
}

export { groupRoomsBy }
