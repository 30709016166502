import { UIRoom } from '@/domains/room-list/ui-models'
import { isEmpty } from '@/utils/is-empty'
import { addRooms } from './add-rooms'
import { areRoomsEqual } from './are-rooms-equal'
import { removeDuplicateRooms } from './remove-duplicate-rooms'

export const modifiedRooms = (
  newRooms: UIRoom[],
  existingRooms: UIRoom[],
  currentAgentId: string,
): UIRoom[] | null => {
  const roomsToUpdate = getOnlyRoomsToUpdate(
    newRooms,
    existingRooms,
    currentAgentId,
  )

  // Nothing to update
  if (isEmpty(roomsToUpdate)) return null

  // Remove duplicates from existing rooms
  const existingRoomsWithoutDuplicates = removeDuplicateRooms(
    existingRooms,
    roomsToUpdate,
  )

  // Add new rooms to update to existing rooms
  const rooms = addRooms(existingRoomsWithoutDuplicates, roomsToUpdate)

  return rooms
}

const getOnlyRoomsToUpdate = (
  newRooms: UIRoom[],
  existingRooms: UIRoom[],
  currentAgentId: string,
): UIRoom[] => {
  const roomsToUpdate: UIRoom[] = []

  newRooms.forEach((newRoom) => {
    const existingRoom = existingRooms.find(
      (existingRoom) => existingRoom.id === newRoom.id,
    )

    if (!existingRoom) return

    // Do nothing because nothing relevant has changed
    if (areRoomsEqual(existingRoom, newRoom, currentAgentId)) return

    roomsToUpdate.push(newRoom)
  })

  return roomsToUpdate
}
