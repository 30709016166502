export function sortByPosition<T extends { position: number }>(
  list: T[],
  reverse?: boolean,
): T[] {
  return [...list].sort((item1, item2) => {
    if (!item1 || !item2) return 0
    return reverse
      ? item2.position - item1.position
      : item1.position - item2.position
  })
}
