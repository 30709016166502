import { CollectionReference, Query, query } from 'firebase/firestore'
import { IFirebaseService } from 'External/firebase/types'
import { convertFrom } from 'Models/converters/saved-reply-converter'
import {
  FBSavedReply,
  IllustrationsCategory,
  SavedReply,
} from 'Models/saved-reply'
import { ISavedReplyService } from './__types__'
import illustrationsCategories from './__data__/illustrations-categories'

export default class SavedReplyService implements ISavedReplyService {
  firebaseService: IFirebaseService

  constructor(firebaseService: IFirebaseService) {
    this.firebaseService = firebaseService
  }

  async listenAll(
    onChange: (newSavedReplies: SavedReply[]) => void,
  ): Promise<void> {
    await this.firebaseService.listenCollection(
      'saved-replies',
      this.findAll(),
      (newSavedReplies: SavedReply[]) => {
        onChange(newSavedReplies)
      },
    )
  }

  protected findAll(): Query<SavedReply> {
    return query(this.getCollection())
  }

  protected getCollection(): CollectionReference<SavedReply> {
    return this.firebaseService.getCollection<FBSavedReply, SavedReply>(
      ['saved-replies'],
      convertFrom,
    )
  }

  getIllustrationsCategories(): IllustrationsCategory[] {
    return illustrationsCategories
  }
}
