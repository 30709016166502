<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import { computed, PropType, ref } from 'vue'
import { UISavedReply } from '@/domains/saved-reply/ui-models'
import { $htmlToMobileContent } from '@/plugins/vue-helpers/html-to-mobile-content'
import { $sanitizeHtml } from '@/plugins/vue-helpers/sanitize-html'

import Body from '@/components/panel/side/layout/Body.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'

const props = defineProps({
  savedReply: {
    type: Object as PropType<UISavedReply>,
    required: true,
  },
  query: {
    type: String,
    required: true,
  },
})

const isMediaLoaded = ref(false)

const onMediaLoad = () => (isMediaLoaded.value = true)

const content = computed(() =>
  $sanitizeHtml($htmlToMobileContent(props.savedReply.content)),
)
</script>

<template>
  <div class="flex flex-1 flex-col w-1/2 border-l border-default-100">
    <Body class="h-full">
      <div v-if="savedReply.isImage">
        <BaseLoader :is-loading="!isMediaLoaded">
          <img
            class="rounded-lg"
            :src="savedReply.image"
            alt=""
            @load="onMediaLoad"
          />
        </BaseLoader>
      </div>
      <div v-else class="p-5">
        <p
          v-if="query"
          v-text-highlight.html="{ query, content }"
          class="p-5 rounded break-words bg-default-50"
        />
        <p
          v-else-if="content"
          class="p-5 rounded break-words bg-default-50"
          v-html="content"
        />
      </div>
    </Body>
  </div>
</template>
