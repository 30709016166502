<script lang="ts" setup>
import { onMounted, onUnmounted, PropType } from 'vue'
import { usePanelStore } from 'Store/stores'
import {
  PanelPosition,
  PanelsComponentNames,
} from '@/domains/panel/store/types'

import Header from '@/views/layouts/panels/Header.vue'
import IconLogoLine from '@/components/icons/IconLogoLine.vue'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left | PanelPosition.Right>,
    required: true,
  },
})

const { nextPanel } = usePanelStore()

let _timeOut: number

const timeOut = () => {
  _timeOut = window.setTimeout(() => {
    switch (props.panelPosition) {
      case 'left':
        nextPanel(PanelsComponentNames.LeftError)
        break
      case 'right':
        nextPanel(PanelsComponentNames.RightError)
        break
    }
  }, 20000)
}

const stopTimeOut = () => clearTimeout(_timeOut)

onMounted(() => timeOut())

onUnmounted(() => stopTimeOut())
</script>

<template>
  <Header :panel-position="panelPosition" :can-prev="false" :can-close="false">
    <div class="w-1/2 bg-default-200 h-3 animate-pulse rounded-sm"></div>
  </Header>
  <div class="p-5 space-y-3 animate-pulse">
    <div class="w-1/2 bg-default-200 h-3 rounded-sm"></div>
    <div class="w-2/3 bg-default-200 h-3 rounded-sm"></div>
    <div class="w-2/3 bg-default-200 h-3 rounded-sm"></div>
    <div class="w-1/4 bg-default-200 h-3 rounded-sm"></div>
  </div>
  <div class="flex flex-col items-center justify-center h-1/2">
    <div class="animate-bounce">
      <IconLogoLine width="60" height="60" class="text-default-300" />
    </div>
  </div>
</template>
