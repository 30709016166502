import { RouteLocationNormalizedLoaded } from 'vue-router'
import { PanelsComponentNames } from '@/domains/panel/store/types'
import { PanelStoreActions } from '@/domains/panel/store/actions/types'
import isEqual from 'lodash.isequal'

export const setPanelsFromRouteParams = (
  newRoute: RouteLocationNormalizedLoaded,
  oldRoute: RouteLocationNormalizedLoaded | undefined,
  nextPanel: PanelStoreActions['nextPanel'],
): void => {
  // KidParent
  if (!isEqual(newRoute.name, oldRoute?.name) && newRoute.params.parentId) {
    // Left
    nextPanel(PanelsComponentNames.KidParentRoomList, {
      root: true,
    })
  }

  // CurrentRoom
  if (newRoute.params.roomId) {
    // Middle
    nextPanel(PanelsComponentNames.Chat, { root: true })

    // Right
    nextPanel(PanelsComponentNames.KidProfile, {
      root: true,
    })
  }
}
