import { RoutesNames } from '@/router/routes/type'
import { MiddlewareNames, RequireAuthenticationMiddleware } from './type'
import { storeToRefs } from 'pinia'
import { useAuthStore } from 'Store/stores'

export default function buildRequireAuthenticationMiddleware(): RequireAuthenticationMiddleware {
  return {
    async [MiddlewareNames.RequireAuthentication](
      to,
      _from,
      next,
      nextMiddleware,
    ) {
      const { isAuthenticated } = storeToRefs(useAuthStore())
      if (!to.meta.protected && isAuthenticated.value) {
        // NOTE: if the user asks for the signIn page and if she/he was previously signed in,
        // it's safe to redirect to the worspace. Before, seing the whole screen, she/he will have to
        // wait for the Firebase authentication and so the loader will be displayed.
        next({ name: RoutesNames.Main })
      } else if (to.meta.protected && !isAuthenticated.value) {
        next({
          name: RoutesNames.SignIn,
          query: { nextUrl: to.fullPath },
        })
      } else {
        if (nextMiddleware) return nextMiddleware(to, _from, next)
        else return next()
      }
    },
  }
}
