<script lang="ts" setup>
import { PropType } from 'vue'
import { Agent } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseAvatar from '@/components/base/BaseAvatar.vue'

defineProps({
  agent: {
    type: Object as PropType<Agent>,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div v-if="agent.profilePic" class="flex mb-5">
    <BaseAvatar
      type="agent"
      :size="5"
      :has-ring="true"
      ring-color="ring-default-200"
      :profile-pic="agent.profilePic"
      :first-name="agent.firstName"
      :last-name="agent.lastName"
    />
  </div>
  <div class="font-semibold">
    {{ t('title', { firstName: agent.firstName, lastName: agent.lastName }) }}
  </div>
  <div>{{ t('sentence') }}</div>
</template>
