import { useRoomStore } from 'Store/stores'
import { ActionTree } from './types'
import Services from 'Services/services'

export const markSavedReplyAsUsed: ActionTree['markSavedReplyAsUsed'] =
  async function (savedReplyId) {
    const currentRoom = useRoomStore().currentRoom

    if (!currentRoom) return

    const usedSavedReplyIds =
      await Services.getInstance().room.markSavedReplyAsUsed(
        currentRoom.id,
        currentRoom.parent.id,
        savedReplyId,
      )

    this.usedSavedReplyIds = usedSavedReplyIds
  }
