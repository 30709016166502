import { ActionTree } from './types'
import {
  useCoreChatStore,
  useKidStore,
  useMedicalReportStore,
} from 'Store/stores'

export const resetCurrentRoom: ActionTree['resetCurrentRoom'] =
  async function () {
    if (!this.currentRoom) return

    this.currentRoomId = null
    this.currentRoom = null
    this.isCurrentRoomJustClosed = false

    useCoreChatStore().updateCurrentMessages(null)
    useKidStore().updateCurrentKid(null)
    useMedicalReportStore().updateCurrentMedicalReport(null)
    useMedicalReportStore().updatePastMedicalReports(null)
  }
