import { Timestamp } from 'firebase/firestore'
import { Nameable } from './shared'

export enum KidGender {
  Female = 'female',
  Male = 'male',
}

export type KidParent = {
  id: string
  firstName: string
  lastName: string
}

export type KidParentProblematic = {
  isProblematic: boolean
  reason: string | null
}

export type FBKidParent = {
  id: string
  firstName: string
  lastName: string
}

export type Kid = CoreKid & {
  birthDate: Date
  sex: KidGender
  weight?: number
  weightDateTaken?: Date
  antecedentIds: string[] | null
  hiddenAntecedentIds: string[] | null
  antecedents: BuiltKidAntecedent[] | null
  parentIds?: string[]
}

export type FBKid = CoreKid & {
  birthDate: Timestamp
  gender: number
  userIds: string[]
}

export type BuiltKidAntecedent = {
  id: string
  title: Record<string, string>
  canBeHidden: boolean // NOTE: for v1 compatibility. To be removed once v2 is in production
  hidden: boolean
  severity: number
}

export type CoreKid = Nameable & {
  id: string
  firstName: string
}

export type KidFamily = {
  parents: KidParent[]
  siblings: Kid[]
}

export enum KidMeasureUnit {
  Kilogram = 'kg',
  Meter = 'm',
  Centimeter = 'cm',
}

export type KidMeasureSet = {
  weight: KidMeasure[]
  height: KidMeasure[]
  head: KidMeasure[]
}

export type KidMeasure = {
  value: number
  unit: KidMeasureUnit
  createdAt: Date
}

export type APIKidMeasureSet = {
  weight: APIKidMeasure[]
  height: APIKidMeasure[]
  head: APIKidMeasure[]
}

export type APIKidMeasure = {
  value: number
  unit: KidMeasureUnit
  measuredAtMillis: number
}

export type APIKidParentProblematic = {
  isProblematic: boolean
  reason: string | null
}
