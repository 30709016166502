import { ActionTree } from './types'
import { MRLItem } from 'Models/medical-report-library-item'
import { setItemVisibility } from './concerns/set-item-visibility'
import Services from 'Services/services'

export const addCurrentMedicalReportLibraryItem: ActionTree['addCurrentMedicalReportLibraryItem'] =
  async function (topic, item) {
    const currentMedicalReport = this.currentMedicalReport
    const library = Services.getInstance().medicalReport.getMRLibrary(topic, {
      causes: this.causes,
      diagnosis: this.diagnosis,
      directions: this.directions,
      supervisions: this.supervisions,
    })

    if (!currentMedicalReport || !library) return

    const existingIds =
      Services.getInstance().medicalReport.getMRLItemIds(
        topic,
        currentMedicalReport,
      ) || []

    // Prevent duplication
    const allIds = [
      ...existingIds.filter((existingId) => existingId !== item.id),
      item.id,
    ]

    const newItems: MRLItem[] = []
    allIds.forEach((itemId) => {
      const mrlItem = library.find((mrlItem) => mrlItem.id === itemId)
      if (!mrlItem) return undefined

      const newItem = setItemVisibility(
        mrlItem,
        item,
        currentMedicalReport.visibleDiagnosticIds,
      )
      newItems.push(newItem)
    })

    await Services.getInstance().medicalReport.update(currentMedicalReport.id, {
      [topic]: newItems,
    })
  }
