<script lang="ts" setup>
import { PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore } from 'Store/stores'
import { Agent } from 'Models/agent'
import { UIRoom } from '@/domains/room-list/ui-models'

import BaseAvatar from '@/components/base/BaseAvatar.vue'
import RoomCardList from '@/domains/room-list/components/lists/shared/RoomCardList.vue'

defineProps({
  rooms: {
    type: Object as PropType<UIRoom[]>,
    required: true,
  },
  assignedAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
})

const { currentAgent } = storeToRefs(useAgentStore())
</script>

<template>
  <RoomCardList
    :title="$agentFullname(assignedAgent) ?? ''"
    :list="rooms"
    :color="currentAgent?.id === assignedAgent.id ? 'white' : 'default'"
  >
    <template #icon>
      <BaseAvatar
        type="agent"
        :first-name="assignedAgent.firstName"
        :last-name="assignedAgent.lastName"
        :profile-pic="assignedAgent.profilePic"
        :size="1"
        class="mr-1"
      />
    </template>
  </RoomCardList>
</template>
