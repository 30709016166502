import { $htmlToMobileContent } from '@/plugins/vue-helpers/html-to-mobile-content'
import { UISavedReply } from '@/domains/saved-reply/ui-models'
import { SavedReply } from 'Models/saved-reply'
import { MedicalReportStoreActions } from '@/domains/medical-report/store/actions/types'
import { computed, ref, Ref, watch } from 'vue'
import { SavedReplyStore } from '../store/types'
import { KidGender } from 'Models/kid'
import { AvailableLocales } from '@/locales'
import { SavedReplyStoreActions } from '../store/actions/types'
import { searchSavedReply } from './search-saved-reply'

export const useSavedReply = ({
  locale,
  savedReplyStore,
  parentFirstName,
  kidFirstName,
  kidSex,
  markSavedReplyAsUsed,
  completeCurrentMedicalReport,
}: {
  locale: Ref<AvailableLocales>
  savedReplyStore: SavedReplyStore
  parentFirstName: string | undefined
  kidFirstName: string | undefined
  kidSex: KidGender | undefined
  markSavedReplyAsUsed?: SavedReplyStoreActions['markSavedReplyAsUsed']
  completeCurrentMedicalReport?: MedicalReportStoreActions['completeCurrentMedicalReportWithSavedReply']
}) => {
  const language = ref<AvailableLocales>(locale.value)
  const isTitleOnly = ref(false)
  const selectedSavedReplyId = ref<string>('')
  const query = ref<string>('')

  const isSavedReplyPickerOpen = computed(
    () => savedReplyStore.isSavedReplyPickerOpen,
  )

  const uiSavedReplies = computed(() => {
    if (!parentFirstName || !kidFirstName || !kidSex) return null

    return savedReplyStore.getSavedReplies(
      language.value,
      parentFirstName,
      kidFirstName,
      kidSex,
    )
  })

  const queriedSavedReplies = computed(() => {
    if (!uiSavedReplies.value) return []
    return searchSavedReply(
      uiSavedReplies.value,
      query.value,
      isTitleOnly.value,
    )
  })

  const numberOfResults = computed(() => queriedSavedReplies.value?.length)

  const savedReply = computed(() =>
    savedReplyStore.getSavedReply(
      uiSavedReplies.value,
      selectedSavedReplyId.value,
    ),
  )

  const safeAction: SafeAction = (savedReplies, savedReply, action) => {
    if (!savedReply || !savedReplies) return

    const content = savedReply.isImage
      ? savedReply.image
      : $htmlToMobileContent(savedReply.content)

    if (!content) return

    action(content)

    // Only used in SavedReplyPicker
    markSavedReplyAsUsed?.(savedReply.id)
    // Only used in SavedReplyPicker
    completeCurrentMedicalReport?.(savedReplies, savedReply.id)
  }

  const onSwitchLanguage = () =>
    language.value === 'fr' ? (language.value = 'en') : (language.value = 'fr')

  watch(
    () => query.value,
    () => {
      // Reset selected saved reply when searching again
      selectedSavedReplyId.value = ''
    },
  )

  watch(
    () => isSavedReplyPickerOpen.value,
    (isOpen) => {
      // Reset query
      if (!isOpen) query.value = ''
    },
  )

  return {
    safeAction,
    language,
    uiSavedReplies,
    queriedSavedReplies,
    isTitleOnly,
    numberOfResults,
    selectedSavedReplyId,
    onSwitchLanguage,
    savedReply,
    query,
  }
}

/* === Types === */

type SafeAction = (
  savedReplies: SavedReply[] | null,
  savedReply: UISavedReply | null,
  action: (content: string) => void,
) => void
