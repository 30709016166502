interface Data<T> {
  items: T[]
  dateField: keyof T
}

export type TimeTree<T> = { [year: number]: { [month: number]: T[] } }

export const makeTimeTree = <T>(
  data: Data<T>,
  tree: TimeTree<T>,
): TimeTree<T> | null => {
  for (const item of data.items) {
    const date = item[data.dateField] as T[keyof T] & Date

    // isValidDate?
    if (!(date instanceof Date)) return null

    const year = date.getFullYear()
    const month = date.getMonth() + 1

    if (!tree[year]) {
      tree[year] = {}
    }

    if (!tree[year][month]) {
      tree[year][month] = []
    }

    tree[year][month].push(item)
  }

  return tree
}
