<script lang="ts" setup>
import { PropType } from 'vue'
import { Agent, AgentTitle } from 'Models/agent'
import { storeToRefs } from 'pinia'
import { useAgentStore, useRoomStore } from 'Store/stores'

import useGoToRoomList from '@/hooks/go-to-room-list'

import AgentInfo from './AgentInfo.vue'
import AgentsCategoryHeader from './AgentsCategoryHeader.vue'

defineProps({
  category: {
    type: String as PropType<'currentSlot' | 'assigned' | 'nextSlot'>,
    required: true,
  },
  agentTitle: {
    type: String as PropType<AgentTitle>,
    required: true,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'agentPicked'): void
}>()

const { currentAgent } = storeToRefs(useAgentStore())
const { currentRoomId } = storeToRefs(useRoomStore())
const { goToRoomList } = useGoToRoomList()

const onGoToRoomList = (agentId: string) => (
  goToRoomList(currentAgent.value?.id, agentId, currentRoomId.value),
  emits('agentPicked')
)
</script>

<template>
  <div>
    <AgentsCategoryHeader
      :title="`${category}.${agentTitle}`"
      :count="agents.length"
    />
    <ul class="flex flex-col">
      <li
        v-for="(agent, index) in agents"
        :key="index"
        class="flex flex-row items-center px-5 py-2 space-x-5 hover:bg-blue-50 cursor-pointer"
        @click="onGoToRoomList(agent.id)"
      >
        <AgentInfo :agent="agent" class="flex flex-1" />
        <div
          v-if="agent.numberOfRooms > 0"
          class="text-default-light font-mono text-xs"
        >
          ({{ agent.numberOfRooms }})
        </div>
      </li>
    </ul>
  </div>
</template>
