import { getCurrentInstance } from 'vue'
import i18n from '@/plugins/vue-use/i18n'
import camelize from '@/utils/camelize'
import { marked } from 'marked'

export type ScopedI18nTc = (
  key: string,
  count: number,
  named?: Record<string, unknown>,
) => string

export default function useScopedI18n(): {
  filename: string
  scope: string
  t: (key: string, named?: Record<string, unknown>) => string
  tc: ScopedI18nTc
  mt: (key: string, named?: Record<string, unknown>) => string
} {
  const { t, tc } = i18n.global
  const filename = getCurrentInstance()?.type.__file || '???'

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const scope = filename
    .split('biloba-desk/')
    .pop()!
    .replace(
      /src\/(components|views)\/(base|modal|panel\/(middle|shared|side\/(layout|left|right|shared)?))?/,
      '$1/',
    )
    .replace(/src\/domains\/([^\/]+)/, '$1/')
    .replace('//', '/')
    .replace(/^\//, '')
    .replace(/\//g, '.')
    .replace(/\.vue$/, '')
    .split('.')
    .map(camelize)
    .join('.')

  return {
    filename,
    scope,
    t: (key: string, named?: Record<string, unknown>) => {
      const scopedKey = `${scope}.${key}`
      return named ? t(scopedKey, named) : t(scopedKey)
    },
    tc: (key: string, count: number, named?: Record<string, unknown>) => {
      const scopedKey = `${scope}.${key}`
      return named ? tc(scopedKey, count, named) : tc(scopedKey, count)
    },
    mt: (key: string, named?: Record<string, unknown>) => {
      const scopedKey = `${scope}.${key}`
      const translation = named ? t(scopedKey, named) : t(scopedKey)
      return marked.parseInline(translation)
    },
  }
}
