import { TrackAgentCall } from 'Models/track-agent'

export default class BaseTrackAgentService {
  lastCall: TrackAgentCall | null

  constructor() {
    this.lastCall = null
  }

  protected async callOnce(
    call: TrackAgentCall,
    callback: () => Promise<void>,
  ): Promise<void> {
    if (this.isNewCall(call)) {
      this.lastCall = call
      return callback()
    } else return
  }

  protected isNewCall(call: TrackAgentCall): boolean {
    return (
      !this.lastCall ||
      this.lastCall.name !== call.name ||
      this.lastCall.resourceType != call.resourceType ||
      this.lastCall.resourceId !== call.resourceId ||
      JSON.stringify(this.lastCall.otherInformation) !==
        JSON.stringify(call.otherInformation)
    )
  }
}
