import { IAPIServiceOtherInformation } from 'External/api/types'

export enum TrackAgentActionNames {
  SignIn = 'SIGN_IN',
  LocalSignIn = 'LOCAL_SIGN_IN',
  SignOut = 'SIGN_OUT',
  View = 'VIEW',
  Create = 'CREATE',
  Update = 'UPDATE',
  AddAntecedents = 'ADD_ANTECEDENTS',
  RemoveAntecedents = 'REMOVE_ANTECEDENTS',
  AssignAgent = 'ASSIGN-AGENT',
  UnassignAgent = 'UNASSIGN-AGENT',
  AcceptAssignment = 'ACCEPT-ASSIGNMENT',
  RefuseAssignment = 'REFUSE-ASSIGNMENT',
  CloseRoom = 'CLOSE-ROOM',
  SnoozeRoom = 'SNOOZE-ROOM',
  ReOpenRoom = 'RE-OPEN',
}

export enum TrackAgentResourceTypes {
  Kid = 'KID',
  Room = 'ROOM',
  MedicalReport = 'MEDICAL-REPORT',
  Prescription = 'PRESCRIPTION',
  Prescriptions = 'PRESCRIPTIONS',
}

export type TrackAgentCall = {
  name: TrackAgentActionNames
  resourceType?: TrackAgentResourceTypes
  resourceId?: string
  otherInformation?: IAPIServiceOtherInformation
}
