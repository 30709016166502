import { Timestamp } from 'firebase/firestore'
import { AgentGender } from './agent'

export enum MessageSenderType {
  Agent = 'agent',
  Biloba = 'biloba',
  System = 'system',
  User = 'user',
  Desk = 'desk',
}

export enum MessageTypes {
  Text = 'text',
  Image = 'image',
  Video = 'video',
}

export type FBMessage = {
  id: string
  type: MessageTypes
  content: string
  createdAt?: Timestamp
  sender: MessageSender
}

export type Message = {
  id: string
  type: MessageTypes
  content: string
  createdAt?: Date
  sender: MessageSender
}

export type MessageSender = {
  id?: string
  firstName?: string
  lastName?: string
  profilePic?: string
  email?: string
  type: MessageSenderType
  adeli?: string
  titleKey?: string
  gender?: AgentGender
  online?: boolean
  rpps?: string
  bio?: {
    en: string
    fr: string
  }
  title?: string
  isWorking?: boolean
}

export type MessageAgentSender = {
  id: string
  firstName: string
  lastName: string
  profilePic?: string
  email: string
  type: MessageSenderType
  adeli?: string
  titleKey: string
  gender: AgentGender
  rpps?: string
  bio: {
    en: string
    fr: string
  }
  title: string
}
