<script lang="ts" setup>
import { PropType } from 'vue'
import { UIPrescription } from '@/domains/prescription/ui-models'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { Agent } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'
import Section from '@/components/panel/side/shared/Section.vue'
import AgentPicker from '@/domains/agent/views/AgentPicker.vue'

defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  prescriptionReferrerAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'changeDoctor', newDoctor: Agent): void
}>()

const { t } = useScopedI18n()

const onSelectDoctor = (agent: Agent) => emits('changeDoctor', agent)
</script>

<template>
  <Section class="flex flex-row items-center">
    <div class="flex flex-row items-center flex-1 space-x-1">
      <Title>
        {{ t('title') }}
      </Title>
      <font-awesome-icon
        v-tooltip.auto="{
          content: t('tooltip'),
          contentClasses: ['max-w-xs', 'break-words'],
        }"
        :icon="['fal', 'info-circle']"
        class="text-default-light cursor-help focus:outline-none"
      />
    </div>
    <div class="flex items-center">
      <AgentPicker
        :selected-agent="prescription.doctor"
        :current-agent="currentAgent"
        :agents="prescriptionReferrerAgents"
        :allow-clear="false"
        @select-agent="onSelectDoctor"
      />
    </div>
  </Section>
</template>
