import { ActionTree } from './types'

import { localSignIn } from './local-sign-in'
import { resetPassword } from './reset-password'
import { signInWithOAuth } from './sign-in-with-oauth'
import { signIn } from './sign-in'
import { signOut } from './sign-out'

export const actions: ActionTree = {
  localSignIn,
  resetPassword,
  signInWithOAuth,
  signIn,
  signOut,
}
