import { VTooltipBindingValue, VTooltipReference } from '../types'
import { setTooltipContent } from './set-tooltip-content'
import setTooltipInstance from './set-tooltip-instance'

export const showTooltip = async (
  el: VTooltipReference,
  tooltipContainer: HTMLElement,
  tooltipContent: HTMLElement,
  value: VTooltipBindingValue,
  modifiers: Record<string, boolean>,
): Promise<void> => {
  if (!el._tooltipInstance) {
    // Instantiate tooltip
    // Triggered one time per tooltip
    el._tooltipInstance = setTooltipInstance(el, tooltipContainer, modifiers)
  }

  // Add content into tooltip
  setTooltipContent(tooltipContainer, tooltipContent, value)

  // Show tooltip
  tooltipContainer.setAttribute('data-show', '')

  // Enable the event listeners
  await el._tooltipInstance.setOptions((options) => ({
    ...options,
    modifiers: [
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...options.modifiers,
      { name: 'eventListeners', enabled: true },
    ],
  }))

  // We need to tell Popper to update the tooltip position
  // after we show the tooltip, otherwise it will be incorrect
  el._tooltipInstance.update()
}
