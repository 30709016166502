<script lang="ts" setup>
import { PropType, computed } from 'vue'
import {
  UIMRLCategory,
  UIMRLCoreItem,
} from '@/domains/medical-report/ui-models'
import { MRLStrictTopics } from 'Models/medical-report-library-item'
import useScopedI18n from '@/hooks/scoped-i18n'

import Body from '@/components/panel/side/layout/Body.vue'
import SelectableItem from '@/components/panel/side/shared/list/SelectableItem.vue'
import CustomSelectableItem from '@/domains/antecedent/components/SelectableItem.vue'
import Section from '@/components/panel/side/shared/Section.vue'
import List from '@/components/panel/side/shared/list/List.vue'

const props = defineProps({
  topic: {
    type: String as PropType<MRLStrictTopics>,
    required: true,
  },
  groupedItems: {
    type: Array as PropType<UIMRLCategory[]>,
    required: true,
  },
  query: {
    type: String,
    required: false,
  },
})

const emits = defineEmits<{
  (
    e: 'select',
    {
      item,
      checked,
      hidden,
    }: { item: UIMRLCoreItem; checked: boolean; hidden: boolean },
  ): void
}>()

const { t } = useScopedI18n()

const isDiagnosis = computed(() => props.topic === MRLStrictTopics.Diagnosis)
const isSingleList = computed(
  () =>
    props.topic === MRLStrictTopics.InitialDirections ||
    props.topic === MRLStrictTopics.Directions ||
    props.topic === MRLStrictTopics.Supervisions,
)
const hasCategorizedItems = computed(
  () => props.groupedItems.length > 0 && !isSingleList.value,
)
const hasUncategorizedItems = computed(
  () =>
    props.groupedItems.length > 0 &&
    isSingleList.value &&
    props.groupedItems[0].items.length > 0,
)
const hasNoResults = computed(
  () =>
    props.groupedItems.length === 0 ||
    (props.groupedItems[0].items.length === 0 && isSingleList.value),
)
</script>

<template>
  <Body class="h-60">
    <div v-if="hasUncategorizedItems" class="py-3">
      <List key="mrl-category-0">
        <SelectableItem
          v-for="(item, index) in groupedItems[0].items"
          :key="`mrl-category-0-item-${index}`"
          :item="item"
          :name="topic"
          :query="query"
          @change="emits('select', { ...$event })"
        />
      </List>
    </div>
    <div v-if="hasCategorizedItems" class="divide-y divide-default-100">
      <List
        v-for="(category, categoryIndex) in groupedItems"
        :key="`mrl-category-${categoryIndex}`"
        :title="category.name"
        :is-big-title="true"
      >
        <div v-if="isDiagnosis">
          <CustomSelectableItem
            v-for="item in category.items"
            :key="`mrl-category-${categoryIndex}-item-${item.id}`"
            :item="item"
            :name="topic"
            :query="query"
            @select="emits('select', { ...$event })"
          />
        </div>
        <div v-else>
          <SelectableItem
            v-for="(item, index) in category.items"
            :key="`mrl-category-${categoryIndex}-item-${index}`"
            :item="item"
            :name="topic"
            :query="query"
            @change="emits('select', { ...$event })"
          />
        </div>
      </List>
    </div>
    <Section v-if="hasNoResults" class="mt-5 text-default-light">
      {{ t('noResult') }}
    </Section>
  </Body>
</template>
