import { Directive } from 'vue'

const directive: Directive = {
  beforeMount(el, binding, vnode) {
    el._keydownCallback = (event: KeyboardEvent) => {
      if (event.key === binding.arg) {
        binding.value(vnode.props?.ref)
      }
    }
    document.addEventListener('keydown', el._keydownCallback)
  },
  unmounted(el) {
    document.removeEventListener('keydown', el._keydownCallback)
    delete el._keydownCallback
  },
}
export const VKeydown = directive
