import { GetterTree } from './types'
import { filterQuickReplies } from './concerns/filter-quick-replies'

export const getQuickReplies: GetterTree['getQuickReplies'] = function () {
  return (locale, parentFirstName, kidFirstName, kidSex) => {
    const savedReplies = this.getSavedReplies(
      locale,
      parentFirstName,
      kidFirstName,
      kidSex,
    )
    if (!savedReplies) return null

    const categoryId = 'yb7vAolIleKgKak10uQX' // NOTE: match "Politesse" category

    return filterQuickReplies(savedReplies, categoryId)
  }
}
