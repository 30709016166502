<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { Agent, AgentPickable } from 'Models/agent'
import { $doctors, $nurses } from '@/plugins/vue-helpers/agents-profession'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseAccordion from '@/components/base/BaseAccordion.vue'
import AgentPickerList from './AgentPickerList.vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  selectedAgent: {
    type: Object as PropType<AgentPickable | null>,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  showCounters: {
    type: Boolean,
    default: true,
  },
  showNumberOfRoomAssignedByAgent: {
    type: Boolean,
    default: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  isLastCategory: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{
  (e: 'onSelectAgent', agent: Agent): void
}>()

const { tc } = useScopedI18n()

const isActive = ref(props.isActive)

const doctorsCount = computed(
  () => $doctors(props.agents.filter(({ isDoctor }) => isDoctor)).length,
)
const nursesCount = computed(
  () => $nurses(props.agents.filter(({ isDoctor }) => !isDoctor)).length,
)
</script>

<template>
  <BaseAccordion
    header-classes="bg-default-50 sticky top-0 z-10"
    button-classes="text-default-light text-xs font-semibold uppercase"
    chevron-classes="text-default-light"
    :force-active="isActive"
    :header-bottom-border="true"
    :thick-bottom-border="false"
    :show-bottom-border="!isLastCategory"
  >
    <template #title>
      <div class="flex items-center space-x-2">
        <div>{{ tc(title, agents.length) }}</div>
        <div
          v-if="showCounters"
          class="flex flex-row items-center space-x-2 rounded text-primary"
        >
          <div class="flex flex-row items-center space-x-1">
            <font-awesome-icon :icon="['fal', 'user-md']" />
            <div class="text-sm">{{ doctorsCount }}</div>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <font-awesome-icon :icon="['fal', 'user-nurse']" />
            <div class="text-sm">{{ nursesCount }}</div>
          </div>
        </div>
      </div>
    </template>

    <AgentPickerList
      :selected-agent="selectedAgent"
      :agents="agents"
      :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
      @on-select-agent="(agent) => emits('onSelectAgent', agent)"
    />
  </BaseAccordion>
</template>
