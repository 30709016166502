<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { Agent } from 'Models/agent'
import { UIRoomListClosed } from '@/domains/room-list/ui-models'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import TeamRooms from '@/domains/room-list/components/lists/shared/TeamRooms.vue'
import DatePicker from 'vue3-datepicker'
import AgentPicker from '@/domains/agent/views/AgentPicker.vue'

const props = defineProps({
  roomList: {
    type: Object as PropType<UIRoomListClosed>,
    required: true,
  },
  agentPicked: {
    type: Object as PropType<Agent | null>,
    required: false,
  },
  closedRoomsDate: {
    type: Object as PropType<Date>,
    required: true,
  },
  isTabClosed: {
    type: Boolean,
    required: true,
  },
  currentAgent: {
    type: Object as PropType<Agent | null>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'update:agentPicked', agent: Agent | null): void
  (e: 'update:closedRoomsDate', date: Date): void
}>()

const date = computed({
  get: () => props.closedRoomsDate ?? new Date(),
  set: (date) => emits('update:closedRoomsDate', date),
})
</script>

<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <div
      class="flex flex-row items-center px-5 pb-3 border-b border-default-100"
    >
      <DatePicker
        v-model="date"
        :typeable="true"
        :locale="$dateLocale()"
        :input-format="$dateFormat('short')"
        :upper-limit="new Date()"
        class="w-full px-2 py-2 text-sm border rounded cursor-pointer text-center focus:outline-none border-default-300 text-primary"
        size="10"
      />
      <AgentPicker
        v-if="currentAgent && roomList && roomList.agents.length > 1"
        :selected-agent="agentPicked"
        :current-agent="currentAgent"
        :agents="roomList.agents"
        :show-number-of-room-assigned-by-agent="false"
        :split-by-presence="false"
        empty-label="allTeamMembers"
        clear-label="allTeamMembers"
        :allow-clear="true"
        :offset-distance="4"
        :placement="DropdownPlacement.BottomStart"
        button-custom-class="z-index-30 py-1 rounded border border-default-300 ml-2"
        container-class="ml-2"
        @select-agent="(agent) => emits('update:agentPicked', agent)"
        @clear-agent="emits('update:agentPicked', null)"
      />
    </div>

    <TeamRooms
      :has-agent-picked="!!agentPicked"
      :rooms-by-agent="roomList.roomsByAgent"
      :unassigned-rooms="roomList.unassignedRooms"
    />
  </div>
</template>
