import { ref, Ref, ComputedRef, watch } from 'vue'
import useServices from '@/hooks/services'

export default function useInceptionChat(roomId: ComputedRef<string | null>): {
  hasMessages: Ref<boolean>
} {
  const { inceptionChat: inceptionChatService } = useServices()
  const hasMessages = ref<boolean>(false)

  watch(
    () => roomId.value,
    async (newRoomId) => {
      if (!newRoomId) return
      hasMessages.value = false // we don't want to display the icon from a previous room context
      hasMessages.value = await inceptionChatService.hasMessages(newRoomId)
    },
    { immediate: true },
  )

  return {
    hasMessages,
  }
}
