import { roomListEffectsPlugin } from './room-list-effects'
import { setAgentsPresencePlugin } from './set-agents-presence'
import { unreadInceptionChatsPlugin } from './unread-inception-chats'
import { setSystemPlugin } from './set-system'
import { refreshCurrentAgentPlugin } from './refresh-current-agent'
import { disabledAgentAccountPlugin } from './disabled-agent-account'
import { markCurrentRoomAsReadPlugin } from './mark-current-room-as-read'

export const PiniaPlugins = () => {
  roomListEffectsPlugin()
  setAgentsPresencePlugin()
  unreadInceptionChatsPlugin()
  refreshCurrentAgentPlugin()
  disabledAgentAccountPlugin()
  setSystemPlugin()
  markCurrentRoomAsReadPlugin()
}
