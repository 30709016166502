import { GetterTree } from './types'
import { buildUIPrescriptionPreview } from './concerns/build-ui-prescription'
import { useAgentStore } from 'Store/stores'

export const getPrescriptionPreview: GetterTree['getPrescriptionPreview'] =
  function ({ prescriptionPreview }) {
    const agents = useAgentStore().agents

    if (!agents || !prescriptionPreview) return null
    return buildUIPrescriptionPreview(prescriptionPreview, agents)
  }
