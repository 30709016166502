import { Agent } from 'Models/agent'

export const removeCurrentPlanningSlotAgents = (
  agents: Agent[],
  currentPlanningSlotAgents: Agent[],
): Agent[] => {
  const currentSlotAgentIds = currentPlanningSlotAgents.map(({ id }) => id)

  return agents.filter(({ id }) => !currentSlotAgentIds.includes(id))
}
