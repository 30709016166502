<script setup lang="ts">
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import { computed } from 'vue'
import Popper from '@askbiloba/vue3-popper'

import MisspellingPopover from './MisspellingPopover.vue'
import replaceNodeContent from '@/lib/tiptap/replace-node-content'

const props = defineProps(nodeViewProps)

const hint = computed(() => props.node?.attrs?.hint || 'Unknown')
const rawSuggestions = computed(() => props.node?.attrs?.suggestions || '')
const suggestions = computed(() =>
  rawSuggestions.value === '' ? [] : rawSuggestions.value.split('~'),
)

const updateContent = (text: string, closePopper: () => void) => {
  if (!props.getPos || !props.editor || !props.editor?.schema) return
  closePopper()
  replaceNodeContent(props.editor, text, props.getPos())
}
</script>

<template>
  <node-view-wrapper class="misspelling" as="span">
    <Popper
      placement="top"
      hover="true"
      offset-distance="0"
      :close-delay="50"
      teleport="body"
    >
      <template #content="{ close }">
        <MisspellingPopover
          :hint="hint"
          :suggestions="suggestions"
          @select="(suggestion) => updateContent(suggestion, close)"
        />
      </template>
      <node-view-content class="content" as="span" spellcheck="false" />
    </Popper>
  </node-view-wrapper>
</template>

<style scoped>
.misspelling .content {
  @apply border-b-2 border-red-600 hover:bg-red-200;
}

.misspelling :deep(div) {
  display: inline-block !important;
}
</style>
