import { GetterTree } from './types'
import { UIRoom } from '../../ui-models'
import Services from 'Services/services'

export const getAssignedRooms: GetterTree['getAssignedRooms'] = function ({
  rooms,
}) {
  if (!rooms) return null
  return Services.getInstance().room.filterAllAssigned(rooms) as UIRoom[]
}
