<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore } from 'Store/stores'
import { UIMedicalReport } from '@/domains/medical-report/ui-models'

import Body from '@/components/panel/side/layout/Body.vue'
import MedicalReport from '@/domains/medical-report/components/MedicalReport.vue'
import ReportSummary from '@/domains/medical-report/components/history/ReportSummary.vue'

const props = defineProps({
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
  isRoomJustClosed: {
    type: Boolean,
    required: true,
  },
})

const { currentAgent } = storeToRefs(useAgentStore())

const canEdit = computed(
  () => currentAgent.value?.id === props.medicalReport.agent?.id,
)
</script>
<template>
  <Body v-if="medicalReport && medicalReport.isDisplayed">
    <MedicalReport
      v-if="canEdit"
      :is-room-just-closed="isRoomJustClosed"
      :medical-report="medicalReport"
    />
    <ReportSummary
      v-else
      class="mx-2 mt-3"
      :current-room-id="medicalReport.roomId"
      :medical-report="medicalReport"
      :is-history="false"
    />
  </Body>
</template>
