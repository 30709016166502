<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

defineProps({
  title: {
    type: String,
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
})

const { tc } = useScopedI18n()
</script>

<template>
  <div
    class="px-5 py-2 bg-default-50 uppercase text-default-light text-xs font-semibold"
  >
    {{ tc(title, count) }}
  </div>
</template>
