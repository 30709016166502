import { IAPIService, IAPIServiceOtherInformation } from 'External/api/types'
import {
  TrackAgentActionNames,
  TrackAgentResourceTypes,
} from 'Models/track-agent'
import BaseTrackAgentService from './base-track-agent'
import { ITrackAgentService } from './__types__'
import revision from '@/assets/revision.json'

export default class TrackAgentService
  extends BaseTrackAgentService
  implements ITrackAgentService
{
  // External services
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    super()
    this.apiService = apiService
  }

  do(
    name: TrackAgentActionNames,
    resourceType?: TrackAgentResourceTypes,
    resourceId?: string,
    otherInformation?: IAPIServiceOtherInformation,
  ): Promise<void> {
    return this.callOnce(
      { name, resourceType, resourceId, otherInformation },
      () => {
        return this.apiService.trackAgent(name, resourceType, resourceId, {
          ...otherInformation,
          revision,
        })
      },
    )
  }
}
