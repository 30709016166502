import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore, useRoomStore } from 'Store/stores'
import Services from 'Services/services'

export const markCurrentRoomAsReadPlugin = () => {
  const { currentAgent } = storeToRefs(useAgentStore())
  const { currentRoom } = storeToRefs(useRoomStore())

  watch(
    () => currentRoom.value,
    (newCurrentRoom) => {
      if (!!newCurrentRoom && !!currentAgent.value)
        Services.getInstance().room.markAsRead(
          newCurrentRoom.id,
          currentAgent.value.id,
        )
    },
    { immediate: true },
  )
}
