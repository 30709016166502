import { UIQuickReply, UISavedReply } from '@/domains/saved-reply/ui-models'
import { isEmpty } from '@/utils/is-empty'

export const filterQuickReplies = (
  savedReplies: UISavedReply[],
  categoryId = 'yb7vAolIleKgKak10uQX',
): UIQuickReply[] => {
  if (isEmpty(savedReplies)) return []
  return getLeafs(savedReplies, categoryId, [])
}

const getLeafs = (
  tree: UISavedReply[],
  parentId: string,
  leafs: UIQuickReply[],
): UIQuickReply[] => {
  const children = tree.filter((child) => child.parentId === parentId)
  children.forEach((child) => {
    if (child.leaf) {
      leafs.push({
        id: child.id,
        title: child.title,
        content: child.content ?? '',
      })
    } else {
      getLeafs(tree, child.id, leafs)
    }
  })
  return leafs
}
