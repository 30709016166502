import { computed, watch, ComputedRef } from 'vue'

import type { RouteLocationNormalizedLoaded } from 'vue-router'
import {
  PanelPosition,
  // PanelsComponentNames,
  TPanelsComponentNames,
} from '@/domains/panel/store/types'
import { setPanelsFromRouteName } from './concerns/set-panels-from-route-name'
import { setPanelsFromRouteParams } from './concerns/set-panels-from-route-params'
import { setPanelsFromRouteQuery } from './concerns/set-panels-from-route-query'
import { PanelStoreActions } from '@/domains/panel/store/actions/types'
import { PanelStoreGetters } from '@/domains/panel/store/getters/types'
import { isEmpty } from '@/utils/is-empty'
import isEqual from 'lodash.isequal'

export const usePanelController = (
  route: RouteLocationNormalizedLoaded,
  nextPanel: PanelStoreActions['nextPanel'],
  killPanel: PanelStoreActions['killPanel'],
  getPanelName: PanelStoreGetters['getPanelName'],
): {
  leftPanelName: ComputedRef<TPanelsComponentNames | null>
  middlePanelName: ComputedRef<TPanelsComponentNames | null>
  rightPanelName: ComputedRef<TPanelsComponentNames | null>
} => {
  // Panels
  const leftPanelName = computed(() => getPanelName(PanelPosition.Left))
  const middlePanelName = computed(() => getPanelName(PanelPosition.Middle))
  const rightPanelName = computed(() => getPanelName(PanelPosition.Right))

  // HACK : Needed to make the route a computed property of the cloned object to watch it deeply
  // Otherwise we had no difference between the new and old set of data.
  // Source : https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
  const computedRoute = computed(() => ({ ...route }))

  watch(
    () => computedRoute.value,
    (newRoute, oldRoute) => {
      // The order of the following panel setters matters!
      if (newRoute.name && !isEqual(newRoute.name, oldRoute?.name))
        setPanelsFromRouteName(newRoute.name, nextPanel, killPanel)

      if (!isEmpty(newRoute.params))
        setPanelsFromRouteParams(newRoute, oldRoute, nextPanel)

      if (!isEmpty(newRoute.query))
        setPanelsFromRouteQuery(newRoute.query, nextPanel)
    },
    { deep: true, immediate: true },
  )

  return {
    leftPanelName,
    middlePanelName,
    rightPanelName,
  }
}
