import { GetterTree } from './types'
import { UICoreChatMessage } from '../../ui-models'

export const getCurrentMessages: GetterTree['getCurrentMessages'] = function ({
  currentMessages,
}) {
  if (currentMessages === null) return []

  const messages: UICoreChatMessage[] = currentMessages.map(
    (message): UICoreChatMessage => {
      return {
        id: message.id,
        type: message.type,
        content: message.content,
        sender: message.sender,
        postedAt: message.createdAt ?? new Date(), // should never happen
        burstMessages: [],
      }
    },
  )

  return messages
}
