/*
 * Build Services
 */

import {
  IAuthenticationService,
  IAgentService,
  IAgentGroupService,
  IRoomService,
  IMessageService,
  IKidService,
  IMedicalReportLibraryService,
  ITrackAgentService,
  ISavedReplyService,
  IMedicalReportService,
  IConfigService,
  IPrescriptionService,
  IDrugService,
  IPlanningService,
  IRoomWorkspaceService,
  IRoomAssignmentService,
  ISpellcheckerService,
  IInceptionChatService,
  IPastMedicalReportService,
  IRoomClosingService,
  IKidMeasureService,
  IKidParentService,
  IRoomSnoozingService,
  IKidAntecedentService,
} from 'Services/__types__'
import FirebaseService from 'External/firebase/service'
import APIService from 'External/api/service'
import LocalStorageService from 'External/local-storage/service'
import TimerService from 'External/timer/service'
import { ITimerService } from 'External/timer/types'
import AuthenticationService from 'Services/authentication'
import TrackAgentService from 'Services/track-agent'
import AgentService from 'Services/agent'
import AgentGroupService from 'Services/agent-group'
import PlanningService from 'Services/planning'
import RoomService from 'Services/room'
import MessageService from 'Services/message'
import KidService from './kid'
import MedicalReportLibraryService from './medical-report-library'
import SavedReplyService from './saved-reply'
import MedicalReportService from './medical-report'
import ConfigService from './config'
import PrescriptionService from './prescription'
import DrugService from './drug'
import { ILocalStorageService } from 'External/local-storage/types'
import RoomWorkspaceService from './room-workspace'
import PastMedicalReportService from './past-medical-report'
import RoomAssignmentService from './room-assignment'
import SpellcheckerService from './spellchecker'
import InceptionChatService from './inception-chat'
import RoomClosingService from './room-closing'
import KidMeasureService from './kid-measure'
import KidParentService from './kid-parent'
import RoomSnoozingService from './room-snoozing'
import KidAntecedentService from './kid-antecedent'

export default class Services {
  private static instance: Services

  authentication: IAuthenticationService
  trackAgent: ITrackAgentService
  agent: IAgentService
  agentGroup: IAgentGroupService
  room: IRoomService
  message: IMessageService
  kid: IKidService
  kidParent: IKidParentService
  kidMeasure: IKidMeasureService
  kidAntecedent: IKidAntecedentService
  medicalReportLibrary: IMedicalReportLibraryService
  medicalReport: IMedicalReportService
  timer: ITimerService
  savedReply: ISavedReplyService
  config: IConfigService
  planning: IPlanningService
  prescription: IPrescriptionService
  drug: IDrugService
  pastMedicalReport: IPastMedicalReportService
  roomWorkspace: IRoomWorkspaceService
  roomAssignment: IRoomAssignmentService
  roomClosing: IRoomClosingService
  roomSnoozing: IRoomSnoozingService
  localStorage: ILocalStorageService
  spellchecker: ISpellcheckerService
  inceptionChat: IInceptionChatService

  private constructor() {
    const firebase = new FirebaseService()
    const api = new APIService()
    const localStorage = new LocalStorageService(window.localStorage)
    this.authentication = new AuthenticationService(api, firebase, localStorage)
    this.trackAgent = new TrackAgentService(api)
    this.agent = new AgentService(firebase)
    this.agentGroup = new AgentGroupService(firebase)
    this.message = new MessageService(firebase)
    this.medicalReportLibrary = new MedicalReportLibraryService(firebase)
    this.medicalReport = new MedicalReportService(firebase, api)
    this.room = new RoomService(firebase, api)
    this.kid = new KidService(firebase, this.medicalReportLibrary)
    this.kidMeasure = new KidMeasureService(api)
    this.kidParent = new KidParentService(api)
    this.kidAntecedent = new KidAntecedentService(api)
    this.timer = new TimerService()
    this.savedReply = new SavedReplyService(firebase)
    this.config = new ConfigService(firebase)
    this.planning = new PlanningService(api)
    this.prescription = new PrescriptionService(api)
    this.drug = new DrugService(api)
    this.inceptionChat = new InceptionChatService(firebase)
    this.pastMedicalReport = new PastMedicalReportService(
      this.medicalReport,
      this.prescription,
      this.kid,
    )
    this.roomWorkspace = new RoomWorkspaceService(
      this.room,
      this.kid,
      this.message,
      this.medicalReport,
      this.pastMedicalReport,
      this.prescription,
    )
    this.roomAssignment = new RoomAssignmentService(
      this.room,
      this.medicalReport,
      this.prescription,
      this.agent,
      this.inceptionChat,
    )
    this.roomClosing = new RoomClosingService(
      this.room,
      this.inceptionChat,
      this.medicalReport,
    )
    this.roomSnoozing = new RoomSnoozingService(this.room, this.inceptionChat)
    this.localStorage = localStorage
    this.spellchecker = new SpellcheckerService(api)
  }

  public static getInstance(): Services {
    if (!Services.instance) {
      Services.instance = new Services()
    }
    return Services.instance
  }

  public static setInstance(services: Services): void {
    Services.instance = services
  }
}
