import { ref, onMounted, onBeforeUnmount } from 'vue'

export default function usePolling(
  poll: () => void,
  refreshPeriod = 1000,
): { stopPolling: () => void } {
  const mustStop = ref<boolean>(false)

  const startPolling = async () => {
    if (mustStop.value) return

    try {
      await poll()
    } catch (exception) {
      throw exception
    } finally {
      // We don't store the timeout id because it's not reliable enough
      // we might face a case when we do a cleartimeout on the previous timeout id
      window.setTimeout(startPolling, refreshPeriod)
    }
  }

  const stopPolling = () => (mustStop.value = true)

  onMounted(() => startPolling())

  onBeforeUnmount(() => stopPolling())

  return { stopPolling }
}
