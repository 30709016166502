import { GetterTree } from './types'
import { useAgentStore } from 'Store/stores'
import Services from 'Services/services'

export const getRoomListAssigned: GetterTree['getRoomListAssigned'] =
  function () {
    const currentAgent = useAgentStore().currentAgent

    if (!currentAgent) return null

    const rooms = this.getAssignedRooms

    if (!rooms) return null

    const teamCounter = rooms.filter(
      (room) => room.assignedAgent?.id !== currentAgent.id,
    ).length

    const currentAgentRooms = rooms.filter(
      (room) => room.assignedAgent?.id === currentAgent.id,
    )
    const currentAgentCounter = currentAgentRooms.length

    const inceptionChatCounter = this.inceptionChatRooms?.length || 0

    const unreadInceptionChatCounter =
      this.inceptionChatRooms?.filter(
        (room) => room.hasUnreadInceptionChatMessages,
      ).length || 0

    return {
      currentAgentCounter,
      currentAgentHasUnread:
        !Services.getInstance().room.areAllLastMessagesRead(
          currentAgentRooms,
          currentAgent.id,
        ),
      inceptionChatCounter,
      unreadInceptionChatCounter,
      teamCounter,
    }
  }
