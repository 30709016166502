import {
  getTotalOfRooms,
  filterWithRoomsStatus,
} from '@/domains/room-list/store/getters/concerns/build-room-list'
import { UIRoom, UIRoomListFavorites } from '@/domains/room-list/ui-models'
import { groupRoomsBy } from '@/utils/group-rooms-by'
import { isEmpty } from '@/utils/is-empty'
import { Agent } from 'Models/agent'
import { RoomStatuses } from 'Models/room'

export const buildRoomListFavorites = (
  agents: Agent[],
  favoritesRooms: UIRoom[],
  pickedAgentId: string | null,
  roomsStatus: RoomStatuses | null,
): UIRoomListFavorites => {
  let hasFilters = false
  const counter = favoritesRooms.length
  const hasRooms = counter > 0

  let roomsByAgent = groupRoomsBy('agent', agents, favoritesRooms).sort(
    // Sort alphabetically by firstName
    (groupA, groupB) =>
      groupA.agent.firstName.localeCompare(groupB.agent.firstName),
  )

  let unassignedRooms = favoritesRooms.filter((room) =>
    isEmpty(room.assignedAgent),
  )

  // Set new agents list
  agents = roomsByAgent.map((item) => item.agent)

  let counterFiltered = counter

  // Filter by agent
  if (pickedAgentId) {
    roomsByAgent = roomsByAgent.filter(
      (item) => item.agent.id === pickedAgentId,
    )
    counterFiltered = getTotalOfRooms(roomsByAgent)
    hasFilters = true
  }

  // Filter by rooms' status
  if (roomsStatus) {
    unassignedRooms = unassignedRooms.filter(
      (room) => room.status.type === roomsStatus,
    )
    roomsByAgent = filterWithRoomsStatus(roomsByAgent, roomsStatus)
    counterFiltered = getTotalOfRooms(roomsByAgent)
    // add unassignedRooms only if there is no filter on agent
    if (!pickedAgentId) counterFiltered += unassignedRooms.length
    hasFilters = true
  }

  return {
    unassignedRooms,
    roomsByAgent,
    agents,
    counter,
    hasRooms,
    hasFilters,
    counterFiltered,
  }
}
