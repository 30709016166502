import { range } from '@/utils/numbers'
import { isEmpty } from '@/utils/is-empty'
import { buildUIPlanningSlots } from './build-ui-planning-slots'
import { UIPlanning } from '../ui-models'
import { Agent } from 'Models/agent'
import { Planning } from 'Models/planning'

export const buildUIPlanning: BuildUIPlanning = function (
  planning,
  date,
  agent,
  agents,
) {
  if (!agent || !agents || !planning) return null

  const dutyHours = range(8, 21)

  const dutyAssignableAgents = agents
    .filter(
      (assignableAgent) =>
        !!planning[assignableAgent.id] && assignableAgent.id !== agent.id,
    )
    .sort((agentA, agentB) => agentA.firstName.localeCompare(agentB.firstName))

  if (!isEmpty(dutyAssignableAgents) && !!planning[agent.id])
    dutyAssignableAgents.unshift(agent)

  return {
    agents: dutyAssignableAgents,
    slots: buildUIPlanningSlots(
      agent,
      planning,
      dutyHours,
      dutyAssignableAgents,
      date,
    ),
  }
}

type BuildUIPlanning = (
  planning: Planning | null,
  date: Date,
  agent: Agent | null,
  agents: Agent[] | null,
) => UIPlanning | null
