<script lang="ts" setup>
import { PropType } from 'vue'
import { useKidStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import List from '@/components/panel/side/shared/list/List.vue'
import Item from '@/components/panel/side/shared/list/Item.vue'
import ItemLabel from '@/components/panel/side/shared/list/ItemLabel.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import AddAntecedent from '@/domains/antecedent/views/AddAntecedent.vue'

defineProps({
  hasAntecedents: {
    type: Boolean,
    required: true,
  },
  groupedAntecedents: {
    type: Object as PropType<UIKidProfile['groupedAntecedents']>,
  },
})

const { t } = useScopedI18n()
const { removeCurrentKidAntecedent } = useKidStore()
</script>

<template>
  <div class="px-5 py-5">
    <div class="rounded-lg border border-default-200 overflow-hidden">
      <BaseDropdown
        container-class="mr-1"
        :placement="DropdownPlacement.LeftStart"
        :full-width="true"
        :offset-distance="-15"
      >
        <template #button="{ isOpen }">
          <button
            class="flex flex-row items-center py-2 px-5 rounded-t-lg focus:outline-none w-full text-left bg-default-50"
            :class="{
              'border-b border-default-200': hasAntecedents,
            }"
          >
            <font-awesome-icon
              v-if="isOpen"
              :icon="['far', 'long-arrow-left']"
              class="flex text-xl mr-2"
            />
            <div class="flex flex-1 font-semibold">
              {{ t('title') }}
            </div>
            <div>
              <div
                class="flex items-center justify-center h-full text-primary"
                :class="isOpen ? 'text-lg' : 'text-sm'"
              >
                <font-awesome-icon
                  :icon="isOpen ? ['fal', 'times'] : ['far', 'plus']"
                />
              </div>
            </div>
          </button>
        </template>
        <template #default>
          <AddAntecedent />
        </template>
      </BaseDropdown>

      <main v-if="hasAntecedents" class="py-3">
        <div
          v-if="groupedAntecedents && groupedAntecedents.length > 0"
          class="space-y-1"
        >
          <List
            v-for="(group, groupIndex) in groupedAntecedents"
            :key="'antecedent-group-' + groupIndex"
            :title="group.name"
            :list="group.antecedents"
          >
            <Item
              v-for="(item, index) in group.antecedents"
              :key="'item-' + index"
              :item="item"
              @remove="removeCurrentKidAntecedent(item.id)"
            >
              <ItemLabel :item="item" :dot="true" />
              <font-awesome-icon
                v-if="item.canBeHidden && item.hidden"
                v-tooltip.auto="t('privateTooltip')"
                :icon="['fal', 'eye-slash']"
                class="ml-2 mb-px text-xs focus:outline-none"
              />
            </Item>
          </List>
        </div>
      </main>
    </div>
  </div>
</template>
