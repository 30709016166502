import {
  AssignedTabRouteNames,
  AssignedToMeTabRouteNames,
  ClosedTabRouteNames,
  FavoritesTabRouteNames,
  InceptionChatTabRouteNames,
  KidParentTabRouteNames,
  MeTabRouteNames,
  RoutesNames,
  SnoozedTabRouteNames,
  TeamTabRouteNames,
  UnassignedTabRouteNames,
} from '@/router/routes/type'
import { computed, ComputedRef } from 'vue'
import { useRoute } from 'vue-router'

export const useRouteIntoTab = (): {
  isTabAssignedToMe: ComputedRef<boolean>
  isTabAssigned: ComputedRef<boolean>
  isTabMe: ComputedRef<boolean>
  isTabTeam: ComputedRef<boolean>
  isTabClosed: ComputedRef<boolean>
  isTabUnassigned: ComputedRef<boolean>
  isTabSnoozed: ComputedRef<boolean>
  isTabFavorites: ComputedRef<boolean>
  isTabInceptionChat: ComputedRef<boolean>
  isTabKidParent: ComputedRef<boolean>
} => {
  const route = useRoute()

  const isTabAssigned = computed(() =>
    AssignedTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabAssignedToMe = computed(() =>
    AssignedToMeTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabMe = computed(() =>
    MeTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabTeam = computed(() =>
    TeamTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabClosed = computed(() =>
    ClosedTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabUnassigned = computed(() =>
    UnassignedTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabSnoozed = computed(() =>
    SnoozedTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabFavorites = computed(() =>
    FavoritesTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabInceptionChat = computed(() =>
    InceptionChatTabRouteNames.includes(route.name as RoutesNames),
  )
  const isTabKidParent = computed(() =>
    KidParentTabRouteNames.includes(route.name as RoutesNames),
  )

  return {
    isTabAssignedToMe,
    isTabAssigned,
    isTabMe,
    isTabTeam,
    isTabClosed,
    isTabUnassigned,
    isTabSnoozed,
    isTabFavorites,
    isTabInceptionChat,
    isTabKidParent,
  }
}
