type TreeNode<T> = T & {
  id: string
  parentId: string
  hasChildren?: true
  children?: TreeNode<T>[]
}

type Root<T> = {
  children: TreeNode<T>[]
  hasChildren?: false
}

export function makeTree<T>(nodes: TreeNode<T>[]): TreeNode<T>[] {
  const nodesMap = new Map<string, TreeNode<T>>(
    nodes.map((node) => [node.id, node]),
  )

  const virtualRoot: Root<T> = { children: [] }

  nodes.forEach((node) => {
    const parent = nodesMap.get(node.parentId) ?? virtualRoot
    if (!parent.children) parent.children = []
    parent.children?.push(node)
    if (parent.children) {
      parent.hasChildren = true
    }
  })

  return virtualRoot.children ?? []
}
