import { ActionTree } from './types'
import { nextPanel } from './next-panel'
import { prevPanel } from './prev-panel'
import { togglePanel } from './toggle-panel'
import { rootPanel } from './root-panel'
import { killPanel } from './kill-panel'
import { setPanelData } from './set-panel-data'

export const actions: ActionTree = {
  nextPanel,
  prevPanel,
  togglePanel,
  rootPanel,
  killPanel,
  setPanelData,
}
