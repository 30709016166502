import { createApp } from 'vue'
import '@/assets/tailwind.css'
import '@/assets/date-picker.css'
import App from '@/App.vue'
import router from '@/router'
import { createPinia } from 'pinia'
import i18n from '@/plugins/vue-use/i18n'
import '@/plugins/vue-use/highcharts'
import PanelsComponents from '@/plugins/vue-components/panels-components'
import VueTimeAgo from '@/plugins/vue-timeago'
import { FontAwesomeIcon } from '@/plugins/vue-components/font-awesome'
import { VFocus } from '@/plugins/vue-directive/v-focus'
import { VClickOutside } from '@/plugins/vue-directive/v-click-outside'
import { VKeydown } from '@/plugins/vue-directive/v-keydown'
import { VTooltip } from '@/plugins/vue-directive/v-tooltip'
import { VTextHighlight } from '@/plugins/vue-directive/v-text-highlight'
import AppHelpers from '@/plugins/vue-helpers'
import { VEmojiPicker } from './plugins/vue-directive/v-emoji-picker'
import VueEmojiPicker from './plugins/vue-emoji-picker'
import HoneyBadger from './plugins/vue-use/honeybadger'
import Services from 'Services/services'
import { ServicesKey } from './symbols'
import { augmentPlugin } from 'Store/plugins/augment'
import { PiniaPlugins } from 'Store/plugins'
import { useUIStore } from 'Store/stores'
import LocalTrackAgentService from 'Services/local-track-agent'

const app = createApp(App)
const pinia = createPinia()

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(HoneyBadger)

// Pinia Plugins
pinia.use(augmentPlugin)
PiniaPlugins()

// Components
app.use(PanelsComponents) // register globally all panels components
app.use(VueTimeAgo)
app.use(VueEmojiPicker)
app.component('FontAwesomeIcon', FontAwesomeIcon)

// Directives
app.directive('focus', VFocus)
app.directive('click-outside', VClickOutside)
app.directive('keydown', VKeydown)
app.directive('emoji-picker', VEmojiPicker)
app.directive('tooltip', VTooltip)
app.directive('text-highlight', VTextHighlight)

// Helpers
app.use(AppHelpers)

// Local tracking
if (import.meta.env.VITE_TRACK_AGENT === 'local')
  Services.getInstance().trackAgent = new LocalTrackAgentService()

// Services
app.provide(ServicesKey, Services.getInstance())

app.mount('#app')

// Set i18n locale
i18n.global.locale = useUIStore().locale
