import { ActionTree } from './types'

export const killPanel: ActionTree['killPanel'] = function (panelPosition) {
  this.$patch({
    [panelPosition]: {
      components: [],
      currentComponentData: undefined,
    },
  })
}
