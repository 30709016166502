import startOfDay from 'date-fns/startOfDay'
import { Room, RoomStatuses } from 'Models/room'

export const getRoomStatus = (room: Room | undefined): RoomStatuses | null => {
  if (!room) return null

  switch (room.status.type) {
    case RoomStatuses.Open:
      return RoomStatuses.Open
    default:
    case RoomStatuses.Closed:
      return RoomStatuses.Closed
    case RoomStatuses.Snoozed:
      if (
        !room.status.snoozedUntil ||
        startOfDay(room.status.snoozedUntil) <= new Date()
      )
        return RoomStatuses.Awake
      else return RoomStatuses.Asleep
  }
}
