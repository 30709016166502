<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

const { t } = useScopedI18n()
</script>

<template>
  <div class="flex flex-1 flex-col items-center justify-center">
    <font-awesome-icon
      class="text-default-400 text-4xl"
      :icon="['fas', 'inbox']"
    />
    <div class="mt-5 text-base">{{ t('title') }}</div>
  </div>
</template>
