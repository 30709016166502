import { ActionTree } from './types'

export const setPanelData: ActionTree['setPanelData'] = function (
  panelPosition,
  data,
) {
  if (Object.keys(data).length === 0) return

  const targetPanel = this.$state[panelPosition]
  targetPanel.currentComponentData = {
    ...targetPanel.currentComponentData,
    ...data,
  }
}
