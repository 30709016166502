<script lang="ts" setup>
import { computed, PropType, onBeforeMount, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { useRouteIntoTab } from '../controllers/route-into-tab'
import { useAgentStore, useRoomListStore } from 'Store/stores'
import { RoomListFilterChanges } from '@/domains/room-list/store/actions/types'
import { parseParam } from '@/utils/route/parse-param'
import {
  UIAssignedRoomLists,
  UIRoomListAwake,
  UIRoomListUnassigned,
} from '../ui-models'

import Navigation from './Navigation.vue'
import Assigned from './lists/Assigned.vue'
import Unassigned from './lists/RoomListUnassigned.vue'
import Snoozed from './lists/RoomListSnoozed.vue'
import AssignedToMe from './lists/AssignedToMe.vue'

defineProps({
  assigned: {
    type: Object as PropType<UIAssignedRoomLists>,
    required: true,
  },
  unassigned: {
    type: Object as PropType<UIRoomListUnassigned>,
    required: true,
  },
  roomListAwake: {
    type: Object as PropType<UIRoomListAwake>,
    required: true,
  },
})

const route = useRoute()
const router = useRouter()
const { agents } = storeToRefs(useAgentStore())
const roomListStore = useRoomListStore()
const { isTabAssigned, isTabUnassigned, isTabSnoozed, isTabAssignedToMe } =
  useRouteIntoTab()

const roomAgents = computed(() => agents.value ?? [])
const filter = computed(() => roomListStore.getFilter)

const onUpdateFilter = (changes: RoomListFilterChanges) =>
  roomListStore.changeRoomListFilter(changes)

watch(
  () => roomListStore.getFilterParams,
  (newParams) => {
    if (!newParams || !route.name) return
    router.push({ name: route.name, query: newParams, hash: route.hash })
  },
)

onBeforeMount(() => {
  roomListStore.initRoomListFilter(
    {
      agentId: parseParam(route, 'agentId'),
      date: parseParam(route, 'date'),
      status: parseParam(route, 'status'),
    },
    roomAgents.value,
  )
})
</script>

<template>
  <Navigation
    :assigned="assigned"
    :unassigned="unassigned"
    :room-list-awake="roomListAwake"
  />

  <AssignedToMe
    v-if="isTabAssignedToMe && filter"
    :filter="filter"
    @update-filter="onUpdateFilter"
  />

  <Unassigned v-if="isTabUnassigned" :room-list="unassigned" />

  <Assigned
    v-if="isTabAssigned && filter"
    :filter="filter"
    :agents="roomAgents"
    @update-filter="onUpdateFilter"
  />

  <Snoozed v-if="isTabSnoozed" :room-list-awake="roomListAwake" />
</template>
