import {
  UIInceptionChat,
  UIInceptionChatParticipant,
} from '../inception-chat/ui-models'
import { InceptionChatMessage } from 'Models/inception-chat-message'
import { Agent } from 'Models/agent'
import { MentionSuggestionItem } from '@/lib/tiptap/extensions/mention-suggestion'
import { $fullname } from '@/plugins/vue-helpers/fullname'
import { $agentTitle } from '@/plugins/vue-helpers/agent-title'
import { MessageSenderType } from 'Models/message'
import { UICoreChatMessage } from '../core-chat/ui-models'
import { InceptionChat } from 'Models/inception-chat'

export const buildChat = (
  chat: InceptionChat,
  agents: Agent[],
): UIInceptionChat => {
  return {
    id: chat.id,
    hasMessages: chat.hasMessages,
    participants: buildParticipants(chat.agentIds, agents),
  }
}

export const buildMessages = (
  messages: InceptionChatMessage[],
  agents: Agent[],
): UICoreChatMessage[] => {
  return messages.map((message) => buildMessage(message, agents))
}

export const buildMessage = (
  message: InceptionChatMessage,
  agents: Agent[],
): UICoreChatMessage => {
  const uiMessage: UICoreChatMessage = {
    id: message.id,
    type: message.type,
    content: message.content,
    postedAt: message.postedAt,
    sender: { type: MessageSenderType.Biloba },
    burstMessages: [],
  }

  const agent = agents.find((agent) => agent.id === message.agentId)

  if (!agent) return uiMessage

  return {
    ...uiMessage,
    sender: {
      ...agent,
      type: MessageSenderType.Agent,
    },
  }
}

export const buildAgentsToMention = (
  agents: Agent[],
  currentAgent: Agent | null,
): MentionSuggestionItem[] => {
  return agents
    .filter((agent) => agent.id !== currentAgent?.id)
    .map((agent) => ({
      id: agent.id,
      fullName: $fullname(agent, false) || agent.firstName,
      profilePic: agent.profilePic,
      active: agent.isWorking,
      title: $agentTitle(agent),
    }))
}

const buildParticipants = (
  agentIds: string[],
  agents: Agent[],
): UIInceptionChatParticipant[] => {
  const participants: UIInceptionChatParticipant[] = []

  agentIds.forEach((agentId) => {
    const agent = agents.find((localAgent) => localAgent.id === agentId)
    if (!agent) return
    participants.push({
      id: agent.id,
      firstName: agent.firstName,
      lastName: agent.lastName,
      profilePic: agent.profilePic,
    })
  })

  return participants
}
