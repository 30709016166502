import { ActionTree } from './types'
import { updateCurrentMessages } from './update-current-messages'
import { postText } from './post-text'
import { postImage } from './post-image'

export const actions: ActionTree = {
  updateCurrentMessages,
  postText,
  postImage,
}
