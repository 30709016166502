<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { sleep } from '@/utils/sleep'
import useClipboard from '@/hooks/clipboard'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import { UIRoom } from '@/domains/room-list/ui-models'
import { useRoomStore } from 'Store/stores'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import RoomInfoItem from './RoomInfoItem.vue'

const { toClipboard } = useClipboard()

defineProps({
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
})

const { t } = useScopedI18n()
const roomStore = useRoomStore()

const copied = ref(false)

const currentRoom = computed(() => roomStore.getCurrentRoom)

const onCopied = async () => {
  copied.value = true
  await sleep(1000)
  copied.value = false
}

const copy = async (content: string) => {
  try {
    await toClipboard(content)
    onCopied()
  } catch (e) {
    console.error(e)
    alert(`Error copying ${content} to the clipboard`)
  }
}
</script>

<template>
  <div v-if="copied" class="w-8 h-8 flex items-center justify-center">
    <font-awesome-icon
      class="focus:outline-none text-green-500"
      :icon="['fal', 'check']"
    />
  </div>
  <BaseDropdown
    v-else
    class="relative"
    button-custom-class="w-8 h-8 hover:bg-default-100 rounded"
    :placement="DropdownPlacement.BottomEnd"
  >
    <template #button>
      <font-awesome-icon
        class="focus:outline-none text-primary"
        :icon="['fal', 'info-circle']"
      />
    </template>
    <ul class="divide-y divide-default-100">
      <!-- Room Info -->
      <RoomInfoItem
        :content="
          t('content', {
            parentName: currentRoom?.parent && $fullname(currentRoom.parent),
            parentId: currentRoom?.parent.id,
            kidName: currentRoom?.kid && $fullname(currentRoom.kid),
            kidId: currentRoom?.kid.id,
            roomUrl: $roomURL(room.id),
          })
        "
        @copy="copy"
      />

      <!-- Prescription ID -->
      <RoomInfoItem
        v-for="(prescription, index) in room.prescriptions"
        :key="index"
        :content="t('prescriptionId', { data: prescription.id })"
        @copy="copy"
      />
    </ul>
  </BaseDropdown>
</template>
