import { Agent } from 'Models/agent'
import i18n from '../vue-use/i18n'

export const $agentFullname = (agent: Agent): string | undefined => {
  const fullname = `${agent.firstName} ${agent.lastName}`
  const i18nKey = `agentFullnames.${agent.titleKey}`

  if (fullname && agent.titleKey && i18n.global.te(i18nKey))
    return i18n.global.t(i18nKey, { fullname })

  return fullname
}
