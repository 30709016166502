import { Ref, ComputedRef, computed, ref, watch } from 'vue'
import Services from 'Services/services'
import { RoomComment } from 'Models/room'
import { isEmpty } from '@/utils/is-empty'
import { buildUIRoomComments } from './concerns/build-ui-room-comments'
import { Agent } from 'Models/agent'
import { UIRoomComment } from '../ui-models'
import { lastElOfArray } from '@/utils/last-el-of-array'
import { useAsyncRunner } from '@/hooks/async-runner'

export const useRoomComment: UseRoomComment = (
  services,
  currentRoomId,
  agents,
  roomStatusUpdatedAt,
  currentAgentId,
  assignedAgentId,
) => {
  const { run, isRunning: areCommentsLoading, hasError } = useAsyncRunner()

  const comment = ref<string>('')
  const comments = ref<RoomComment[] | null>(null)
  const uiComments = ref<UIRoomComment[] | null>(null)
  const isEditMode = ref<boolean>(false)

  const isCommentPresent = computed(() => !isEmpty(comment.value))

  const getLastComment = computed<RoomComment | null>(() =>
    comments.value ? lastElOfArray(comments.value) : null,
  )

  // Is the medical report's owner who posted the last comment
  const isMROwnerComment = computed(
    () => assignedAgentId?.value === getLastComment.value?.agentId,
  )

  // The room status has been updated since the lastComment has been posted
  const shouldForceNewComment = computed(() => {
    if (!getLastComment.value || !roomStatusUpdatedAt?.value) return false
    return (
      roomStatusUpdatedAt.value >= new Date(getLastComment.value.commentedAt)
    )
  })

  // We hide the lastComment when the owner is editing it
  const isLastCommentHidden = computed(
    () =>
      isEditorVisible.value &&
      isMROwnerComment.value &&
      !shouldForceNewComment.value,
  )

  const isCommentEditable = computed(
    () =>
      isMROwnerComment.value &&
      !isEditMode.value &&
      !shouldForceNewComment.value,
  )

  const canComment = computed(() => currentAgentId === assignedAgentId?.value)

  const isEditorVisible = computed(
    () =>
      canComment.value &&
      (!isMROwnerComment.value ||
        isEditMode.value ||
        shouldForceNewComment.value),
  )

  const submitComment = async () => {
    if (!assignedAgentId?.value) return

    // required
    isEditMode.value = false

    // Save comment
    await run(() =>
      services.room.comment(
        currentRoomId.value,
        comment.value,
        assignedAgentId.value ?? '',
        getLastComment.value,
        shouldForceNewComment.value,
      ),
    )

    // Force reload comments
    await run(() => getComments(currentRoomId.value))
  }

  const allowCommentEditing = () => (isEditMode.value = true)

  const getComments = async (roomId: string) => {
    // Required
    if (roomId !== currentRoomId.value)
      throw new Error(
        'Trying to get comments for a room that is not the current room',
      )

    const data = await run(() => services.room.getComments(roomId))
    if (!data) return

    comments.value = data.comments
    uiComments.value = buildUIRoomComments(agents, data.comments)
  }

  watch(
    () => isEditorVisible.value,
    () => {
      if (shouldForceNewComment.value) return (comment.value = '')

      // when editing, we prefill with the last comment
      comment.value = isMROwnerComment.value
        ? getLastComment.value?.comment ?? ''
        : ''
    },
    { immediate: true },
  )

  // Load comments when switching room
  watch(
    () => currentRoomId.value,
    async (newId) => {
      await getComments(newId)
    },
    { immediate: true },
  )

  return {
    comment,
    uiComments,
    isCommentPresent,
    isCommentEditable,
    isLastCommentHidden,
    isEditorVisible,
    submitComment,
    allowCommentEditing,
    areCommentsLoading,
    hasError,
  }
}

/* === Types === */

type UseRoomComment = (
  services: Services,
  currentRoomId: Ref<string>,
  agents: Agent[] | null,
  roomStatusUpdatedAt?: Ref<Date | undefined>,
  currentAgentId?: string,
  assignedAgentId?: Ref<string | undefined>,
) => {
  comment: Ref<string>
  uiComments: Ref<UIRoomComment[] | null>
  isCommentPresent: ComputedRef<boolean>
  isCommentEditable: ComputedRef<boolean>
  isLastCommentHidden: ComputedRef<boolean>
  isEditorVisible: ComputedRef<boolean>
  submitComment: () => void
  allowCommentEditing: () => void
  areCommentsLoading: Ref<boolean>
  hasError: Ref<boolean>
}
