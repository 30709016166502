<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { MRLStrictTopics } from 'Models/medical-report-library-item'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import AddMedicalReportLibraryItem from '@/domains/medical-report/views/AddMedicalReportLibraryItem.vue'

const props = defineProps({
  topic: {
    type: String as PropType<MRLStrictTopics>,
    required: true,
  },
  canAddSeveral: {
    type: Boolean,
    required: true,
  },
  length: {
    type: Number,
    required: true,
  },
})

const { tc } = useScopedI18n()

const placement = computed(() => {
  switch (props.topic) {
    case MRLStrictTopics.InitialDirections:
    case MRLStrictTopics.Directions:
    case MRLStrictTopics.Supervisions:
      return DropdownPlacement.Left
    default:
      return DropdownPlacement.LeftStart
  }
})
</script>
<template>
  <BaseDropdown
    :disabled="!canAddSeveral"
    :placement="placement"
    :reduced-height="true"
    :offset-distance="-10"
  >
    <template #button="{ isOpen }">
      <div
        class="flex flex-row px-5 w-full text-left items-center focus:outline-none space-x-2"
        :class="{
          'cursor-default font-semibold': !canAddSeveral,
          'cursor-pointer font-bold': isOpen && canAddSeveral,
          'cursor-pointer text-primary': !isOpen && canAddSeveral,
        }"
      >
        <font-awesome-icon
          v-if="isOpen"
          :icon="['far', 'long-arrow-left']"
          class="flex text-xl"
        />
        <div class="flex flex-1">{{ tc(`title.${topic}`, length) }}</div>
      </div>
    </template>

    <template #default>
      <AddMedicalReportLibraryItem :topic="topic" />
    </template>
  </BaseDropdown>
</template>
