import { ActionTree } from './types'
import { Prescription, PrescriptionStatus } from 'Models/prescription'
import { useRoomStore } from 'Store/stores'
import Services from 'Services/services'

export const completeCurrentPrescription: ActionTree['completeCurrentPrescription'] =
  async function () {
    const currentRoom = useRoomStore().currentRoom

    if (!this.currentPrescription || !currentRoom) return

    const modifiedPrescription: Prescription = { ...this.currentPrescription }

    try {
      const prescriptionId = await Services.getInstance().prescription.persist(
        currentRoom.id,
        this.currentPrescription,
      )

      modifiedPrescription.id = prescriptionId
      modifiedPrescription.status = PrescriptionStatus.Created
      modifiedPrescription.couldNotBePersisted = false
    } catch {
      // USECASE: Ordoclic has failed to create a prescription and so our API returns a 500 error.
      modifiedPrescription.couldNotBePersisted = true
    }

    this.currentPrescription = modifiedPrescription

    // reload the prescription because it has been updated
    // the state will be asynchronously updated as well
    Services.getInstance().roomWorkspace.touch(
      currentRoom.id,
      this.currentPrescription,
    )
  }
