import translationsEN from './en.json'
import translationsFR from './fr.json'

import agentTranslationsEN from '@/domains/agent/locales/en.json'
import agentTranslationsFR from '@/domains/agent/locales/fr.json'
import antecedentTranslationsEN from '@/domains/antecedent/locales/en.json'
import antecedentTranslationsFR from '@/domains/antecedent/locales/fr.json'
import kidTranslationsEN from '@/domains/kid/locales/en.json'
import kidTranslationsFR from '@/domains/kid/locales/fr.json'
import medicalReportTranslationsEN from '@/domains/medical-report/locales/en.json'
import medicalReportTranslationsFR from '@/domains/medical-report/locales/fr.json'
import planningTranslationsEN from '@/domains/planning/locales/en.json'
import planningTranslationsFR from '@/domains/planning/locales/fr.json'
import prescriptionTranslationsEN from '@/domains/prescription/locales/en.json'
import prescriptionTranslationsFR from '@/domains/prescription/locales/fr.json'
import roomListTranslationsEN from '@/domains/room-list/locales/en.json'
import roomListTranslationsFR from '@/domains/room-list/locales/fr.json'
import savedReplyTranslationsEN from '@/domains/saved-reply/locales/en.json'
import savedReplyTranslationsFR from '@/domains/saved-reply/locales/fr.json'
import inceptionChatTranslationsEN from '@/domains/inception-chat/locales/en.json'
import inceptionChatTranslationsFR from '@/domains/inception-chat/locales/fr.json'
import coreChatTranslationsEN from '@/domains/core-chat/locales/en.json'
import coreChatTranslationsFR from '@/domains/core-chat/locales/fr.json'
import knowledgeBasisTranslationsEN from '@/domains/knowledge-basis/locales/en.json'
import knowledgeBasisTranslationsFR from '@/domains/knowledge-basis/locales/fr.json'
import roomTranslationsEN from '@/domains/room/locales/en.json'
import roomTranslationsFR from '@/domains/room/locales/fr.json'

export type AvailableLocales = 'fr' | 'en'

export default {
  en: {
    ...translationsEN,
    ...agentTranslationsEN,
    ...antecedentTranslationsEN,
    ...kidTranslationsEN,
    ...medicalReportTranslationsEN,
    ...planningTranslationsEN,
    ...prescriptionTranslationsEN,
    ...roomListTranslationsEN,
    ...savedReplyTranslationsEN,
    ...inceptionChatTranslationsEN,
    ...coreChatTranslationsEN,
    ...knowledgeBasisTranslationsEN,
    ...roomTranslationsEN,
  },
  fr: {
    ...translationsFR,
    ...agentTranslationsFR,
    ...kidTranslationsFR,
    ...antecedentTranslationsFR,
    ...medicalReportTranslationsFR,
    ...planningTranslationsFR,
    ...prescriptionTranslationsFR,
    ...roomListTranslationsFR,
    ...savedReplyTranslationsFR,
    ...inceptionChatTranslationsFR,
    ...coreChatTranslationsFR,
    ...knowledgeBasisTranslationsFR,
    ...roomTranslationsFR,
  },
}
