<script lang="ts" setup>
import { PropType } from 'vue'

import BaseModalHeader from './base-modal/BaseModalHeader.vue'
import BaseModalBody from './base-modal/BaseModalBody.vue'
import BaseModalFooter from './base-modal/BaseModalFooter.vue'
import BaseModalLoader from './base-modal/BaseModalLoader.vue'
import BaseModalError from '@/components/base/base-modal/BaseModalError.vue'

defineProps({
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasError: {
    type: Boolean,
    required: false,
    default: false,
  },
  errorMessage: {
    type: String,
    required: false,
    default: 'Oups!',
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
  modalClass: {
    type: [String, Object] as PropType<Record<string, boolean> | string>,
  },
})

const emits = defineEmits<{
  (e: 'close'): void
}>()

const close = () => emits('close')

defineExpose({ close })
</script>

<template>
  <teleport to="#viewport">
    <div
      v-keydown:Escape="close"
      class="absolute top-0 bottom-0 left-0 right-0 bg-default bg-opacity-60 z-30 cursor-pointer"
      @click.self="close"
    >
      <div
        class="absolute bg-white rounded-lg flex flex-col flex-grow scrollbar border border-default-100 cursor-default"
        :class="[
          {
            'top-10 bottom-10 left-20 right-20': !small,
            'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-modal':
              small,
          },
          modalClass,
        ]"
      >
        <BaseModalLoader v-if="isLoading" @close="close" />

        <BaseModalError
          v-else-if="hasError"
          :message="errorMessage"
          @close="close"
        />

        <div v-else class="flex flex-grow flex-col overflow-x-hidden">
          <BaseModalHeader @close="close">
            <slot name="header"></slot>
          </BaseModalHeader>

          <BaseModalBody class="flex-1">
            <slot />
          </BaseModalBody>

          <BaseModalFooter v-if="!!$slots.footer">
            <slot name="footer"></slot>
          </BaseModalFooter>
        </div>
      </div>
    </div>
  </teleport>
</template>
