import { computed, ComputedRef } from 'vue'
import { UICoreChatMessageColor } from '../ui-models'

export const useMessageColors = (
  color: ComputedRef<UICoreChatMessageColor>,
) => {
  const messageColors = computed(() => {
    switch (color.value) {
      case 'white':
        return 'bg-white border-default-100'
      case 'blue':
        return 'bg-primary bg-opacity-20 border-transparent'
      case 'purple':
        return 'bg-purple-500 text-white border-transparent'
      default:
        return 'bg-default-100 border-transparent'
    }
  })

  return { messageColors }
}
