<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoom } from '@/domains/room-list/ui-models'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import BaseButtonLink from '@/components/base/BaseButtonLink.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import { RoomStatuses } from 'Models/room'

const props = defineProps({
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'clearAgent'): void
}>()

const { mt, t } = useScopedI18n()

const assignedAgent = computed(() => props.room.assignedAgent)

const isSnoozed = computed<boolean>(
  () => props.room.status.type === RoomStatuses.Snoozed,
)
</script>

<template>
  <div
    class="flex flex-col p-5 bg-default-50 border-t border-default-100 shadow-inner"
  >
    <div
      v-if="assignedAgent"
      class="flex items-center justify-center divide-x divide-gray-300"
    >
      <span
        v-if="assignedAgent.assignmentAccepted"
        v-html="
          mt(`sentence.${assignedAgent?.gender}`, {
            name: $fullname(assignedAgent),
          })
        "
      />
      <span
        v-else
        v-html="
          mt('assignmentWaitingForAcceptation.sentence', {
            name: $fullname(assignedAgent),
          })
        "
      />
      <div
        v-if="$canCancelPendingAssignment(room)"
        class="flex flex-col pl-4 ml-4"
      >
        <BaseDropdown
          v-if="isSnoozed"
          :placement="DropdownPlacement.Top"
          button-custom-class="text-primary"
        >
          <template #button>
            {{ t('cancelPendingAssignment.sentence') }}
          </template>
          <template #default>
            <div class="p-5 flex flex-col w-80 space-y-3">
              <p class="text-sm">
                {{ t('cancelPendingAssignment.warningText') }}
              </p>
              <BasePrimaryButton class="text-sm" @click="emits('clearAgent')">
                {{ t('cancelPendingAssignment.submitButton') }}
              </BasePrimaryButton>
            </div>
          </template>
        </BaseDropdown>
        <BaseButtonLink v-else @click="emits('clearAgent')">
          {{ t('cancelPendingAssignment.sentence') }}
        </BaseButtonLink>
      </div>
    </div>
  </div>
</template>
