import { GetterTree } from './types'
import { buildUIPrescriptionSummary } from './concerns/build-ui-prescription'
import { useAgentStore } from 'Store/stores'

export const getCurrentFilteredPrescription: GetterTree['getCurrentFilteredPrescription'] =
  function ({ currentFilteredPrescription }) {
    const agents = useAgentStore().agents

    if (!currentFilteredPrescription || !agents) return null
    return buildUIPrescriptionSummary(currentFilteredPrescription, agents)
  }
