export default function <T>(array: T[], number: number): T[][] {
  const size = array.length
  const modulo = size % number
  const division = Math.trunc(size / number) + (modulo > 0 ? 1 : 0)

  if (division === 0) return [array]

  let start = 0
  const groups = []

  for (let index = 0; index < division; index++) {
    let end = start + number
    if (end > size) end = size
    const lastGroup = array.slice(start, end)
    groups.push(lastGroup)
    start += number
  }

  return groups
}
