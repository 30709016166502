import { IAgentGroupService } from 'Services/__types__'
import { IFirebaseService } from 'External/firebase/types'
import { AgentGroup, FBAgentGroup } from 'Models/agent-group'
import { convertFrom } from 'Models/converters/agent-group-converter'
import { Query } from 'firebase/firestore'

export default class AgentGroupService implements IAgentGroupService {
  firebaseService: IFirebaseService

  constructor(firebaseService: IFirebaseService) {
    this.firebaseService = firebaseService
  }

  listenAll(onChange: (newAgentGroups: AgentGroup[]) => void): void {
    this.firebaseService.listenCollection(
      'agent-groups',
      this.findAll(),
      (newAgentGroups: AgentGroup[]) => {
        onChange(newAgentGroups)
      },
    )
  }

  loadAll(): Promise<AgentGroup[] | undefined> {
    return this.firebaseService.loadDocuments(this.findAll())
  }

  protected findAll(): Query<AgentGroup> {
    return this.firebaseService.getCollection<FBAgentGroup, AgentGroup>(
      'agent-groups',
      convertFrom,
    )
  }
}
