import { VTooltipBindingValue } from '../types'
import { containerBGColorDefaultClass } from './index'

export const setTooltipContent = (
  tooltipContainer: HTMLElement,
  tooltipContent: HTMLElement,
  value: VTooltipBindingValue,
): void => {
  // Add content into tooltip
  if (typeof value === 'string') {
    tooltipContent.innerText = value
  } else if (typeof value === 'object') {
    if (value.html) tooltipContent.innerHTML = value.content
    else tooltipContent.innerText = value.content

    // Add custom classes
    if (value.contentClasses) {
      value.contentClasses.forEach((contentClass) => {
        tooltipContent.classList.add(contentClass)
      })
    }
    if (value.containerBGColorClass) {
      tooltipContainer.classList.remove(containerBGColorDefaultClass)
      tooltipContainer.classList.add(value.containerBGColorClass)
    }
  }
}
