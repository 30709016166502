import {
  differenceInMinutes as coreDifferenceInMinutes,
  startOfMinute,
} from 'date-fns'

export function differenceInMinutes(futureDate: Date, pastDate: Date): number {
  return coreDifferenceInMinutes(
    startOfMinute(futureDate),
    startOfMinute(pastDate),
  )
}
