<script lang="ts" setup>
import { PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore } from 'Store/stores'
import { Agent } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'

import TitleAndSubTitleSlots from '@/components/panel/side/shared/TitleAndSubTitleSlots.vue'
import AgentPicker from '@/domains/agent/views/AgentPicker.vue'

defineProps({
  todayRoomsCount: {
    type: Number,
    required: true,
  },
  agentPicked: {
    type: Object as PropType<Agent | null>,
    required: false,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'selectAgent', agent: Agent): void
  (e: 'clearAgent'): void
}>()

const { t, tc } = useScopedI18n()

const { currentAgent } = storeToRefs(useAgentStore())

const onSelectAgent = (agent: Agent) => emits('selectAgent', agent)
const onClearAgent = () => emits('clearAgent')
</script>

<template>
  <TitleAndSubTitleSlots>
    <template #title>{{ t('todaySchedule') }}</template>
    <template #subTitle
      ><span class="font-semibold text-default">{{ todayRoomsCount }}</span>
      {{ tc('todayRooms', todayRoomsCount) }}</template
    >
  </TitleAndSubTitleSlots>
  <AgentPicker
    v-if="agents.length && currentAgent"
    :selected-agent="agentPicked"
    :current-agent="currentAgent"
    :select-current-agent="false"
    :agents="agents"
    :allow-clear="true"
    empty-label="noAgentSelected"
    clear-label="noAgent"
    menu-align="left"
    v-bind="$attrs"
    button-custom-class="py-1 rounded border border-default-300 ml-4"
    :offset-distance="4"
    @select-agent="onSelectAgent"
    @clear-agent="onClearAgent"
  />
</template>
