<script lang="ts" setup>
import { PropType } from 'vue'
import { UIMedicalReport } from '@/domains/medical-report/ui-models'
import { isEmpty } from '@/utils/is-empty'
import useScopedI18n from '@/hooks/scoped-i18n'

import MRLItems from '@/components/panel/shared/MRLItems.vue'

defineProps({
  causes: {
    type: Object as PropType<UIMedicalReport['causes']>,
    required: false,
  },
  diagnosis: {
    type: Object as PropType<UIMedicalReport['diagnosis']>,
    required: false,
  },
  initialDirections: {
    type: Object as PropType<UIMedicalReport['initialDirections']>,
    required: false,
  },
  directions: {
    type: Object as PropType<UIMedicalReport['directions']>,
    required: false,
  },
  supervisions: {
    type: Object as PropType<UIMedicalReport['supervisions']>,
    required: false,
  },
})

const { tc } = useScopedI18n()
</script>

<template>
  <MRLItems
    v-if="!isEmpty(causes) && causes"
    :title="tc('causes', causes.length)"
    :mrl-items="causes"
  />
  <MRLItems
    v-if="!isEmpty(diagnosis) && diagnosis"
    :title="tc('diagnosis', diagnosis.length)"
    :mrl-items="diagnosis"
  />
  <MRLItems
    v-if="!isEmpty(initialDirections) && initialDirections"
    :title="tc('initialDirections', initialDirections.length)"
    :mrl-items="initialDirections"
  />
  <MRLItems
    v-if="!isEmpty(directions) && directions"
    :title="tc('directions', directions.length)"
    :mrl-items="directions"
  />
  <MRLItems
    v-if="!isEmpty(supervisions) && supervisions"
    :title="tc('supervisions', supervisions.length)"
    :mrl-items="supervisions"
  />
</template>
