import { BuiltInAgentGroups } from 'Models/agent-group'
import { Room, RoomStatuses } from 'Models/room'
import BasePolicy from './base'

export default class PrescriptionPolicy extends BasePolicy {
  list(): boolean {
    return this.isMemberOf(BuiltInAgentGroups.PrescriptionInitiators)
  }

  open(room: Room): boolean {
    if (this.isMemberOf(BuiltInAgentGroups.PrescriptionInitiators)) return true

    if (room.status.type === RoomStatuses.Closed)
      return (
        room.hasPrescription ||
        this.isMemberOf(BuiltInAgentGroups.PrescriptionReferrers)
      )

    return false
  }
  delete(): boolean {
    return this.isMemberOf(BuiltInAgentGroups.PrescriptionErasers)
  }

  openReadonly(room: Room): boolean {
    return room.status.type === RoomStatuses.Closed && room.hasPrescription
  }

  requireDoctorPicker(): boolean {
    return !this.agent.isRegisteredDoctor
  }
}
