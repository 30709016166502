<script lang="ts" setup>
import { PropType, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { usePrescriptionStore } from 'Store/stores'
import { PanelPosition } from '@/domains/panel/store/types'
import useOpenRoom from '@/hooks/open-room'

import MiddleContainer from '@/components/panel/middle/layout/MiddleContainer.vue'
import PrescriptionDetailPanel from '@/components/panel/middle/PrescriptionDetail.vue'
import Loader from '@/components/panel/middle/Loader.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Middle>,
    required: true,
  },
})

const route = useRoute()
const { loadCurrentFilteredPrescription } = usePrescriptionStore()
const prescriptionStore = usePrescriptionStore()
const { goToRoom } = useOpenRoom()

const prescriptionId = computed(() =>
  route.params.prescriptionId ? String(route.params.prescriptionId) : null,
)
const prescription = computed(
  () => prescriptionStore.getCurrentFilteredPrescription,
)

const onGoToKidParentRooms = () => {
  if (!prescription.value) return
  goToRoom(prescription.value.roomId, false, prescription.value.parent.id)
}

const onGoToRoom = () => {
  if (!prescription.value) return
  goToRoom(prescription.value.roomId)
}
const loadPrescriptions = () => {
  if (!prescriptionId.value) return
  loadCurrentFilteredPrescription(prescriptionId.value)
}

watch(
  () => prescriptionId.value,
  () => loadPrescriptions(),
  { immediate: true },
)
</script>

<template>
  <MiddleContainer>
    <PrescriptionDetailPanel
      v-if="prescription"
      :panel-position="panelPosition"
      :prescription="prescription"
      @go-to-kid-parent-rooms="onGoToKidParentRooms"
      @go-to-room="onGoToRoom"
    />
    <Loader v-else />
  </MiddleContainer>
</template>
