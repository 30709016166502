import { Agent } from 'Models/agent'
import { UIPlanningSlot, UIPlanningSlots } from '../ui-models'
import { Planning } from 'Models/planning'
import { moveElement } from '@/utils/arrays'
import { isEmpty } from '@/utils/is-empty'
import { getPlanningSlotAgents } from '@/utils/agent/get-planning-slot-agents'
import { $doctors, $nurses } from '@/plugins/vue-helpers/agents-profession'

export const buildUIPlanningSlots = (
  agent: Agent,
  planning: Planning,
  dutyHours: Array<number>,
  dutyAssignableAgents: Agent[],
  now: Date,
): Record<string, never> | UIPlanningSlots => {
  if (!dutyHours || !dutyAssignableAgents) return {}

  const uiPlanningSlots: UIPlanningSlots = dutyHours.reduce(
    (uiPlanning: UIPlanningSlots, hour: number) => {
      // Retrieve agents into the given slot
      const agentsInSlot: Agent[] = getPlanningSlotAgents(
        dutyAssignableAgents,
        planning,
        hour,
      )

      const agentIds: Array<string> = agentsInSlot.map(
        (agentInSlot) => agentInSlot.id,
      )

      return {
        ...uiPlanning,
        [`${hour}h`]: {
          hour: hour,
          agentIds,
          doctors: $doctors(agentsInSlot),
          nurses: $nurses(agentsInSlot),
          isCurrentAgentDutyHour: agentIds.includes(agent.id),
          isNow: hour === now.getHours(),
          isPast: hour < now.getHours(),
        },
      }
    },
    {},
  )

  // Iterate on the built slots to compute the slots around the agent duty slots
  Object.keys(uiPlanningSlots).forEach((hour) => {
    const { doctors, nurses } = alignAgents(hour, uiPlanningSlots)
    uiPlanningSlots[hour] = {
      ...uiPlanningSlots[hour],
      doctors,
      nurses,
    }
  })

  return uiPlanningSlots
}

const getAroundAgents = (
  planningSlotsKeys: Array<string>,
  uiPlanningSlots: UIPlanningSlots,
) => {
  let doctors: Agent[] = []
  let nurses: Agent[] = []

  planningSlotsKeys.some((slotKey) => {
    if (!isEmpty(uiPlanningSlots[slotKey].doctors) && isEmpty(doctors))
      doctors = uiPlanningSlots[slotKey].doctors

    if (!isEmpty(uiPlanningSlots[slotKey].nurses) && isEmpty(nurses))
      nurses = uiPlanningSlots[slotKey].nurses

    if (!isEmpty(doctors) && !isEmpty(nurses)) return true
    return false
  })

  return { doctors, nurses }
}

const moveAgents = (agents: Agent[], aroundAgentIds: string[]) => {
  let movedAgents = agents

  agents.forEach((agent, agentIndex) => {
    const aroundAgentIndex = aroundAgentIds.indexOf(agent.id)
    if (aroundAgentIds.includes(agent.id) && agentIndex !== aroundAgentIndex) {
      movedAgents = moveElement<Agent>(
        movedAgents,
        agentIndex,
        aroundAgentIndex,
      )
    }
  })

  return movedAgents
}

const moveAllAgents = (
  currentSlot: UIPlanningSlot,
  aroundAgents: { doctors: Agent[]; nurses: Agent[] },
) => {
  const aroundDoctorsIds = aroundAgents.doctors.map(
    (aroundDoctor) => aroundDoctor.id,
  )
  const doctors = moveAgents(currentSlot.doctors, aroundDoctorsIds)

  const aroundNursesIds = aroundAgents.nurses.map(
    (aroundNurse) => aroundNurse.id,
  )
  const nurses = moveAgents(currentSlot.nurses, aroundNursesIds)

  return { doctors, nurses }
}

const alignAgents = (hour: string, uiPlanningSlots: UIPlanningSlots) => {
  const currentPlanningSlot = uiPlanningSlots[hour]

  const planningSlotsKeys = Object.keys(uiPlanningSlots) // ['8h', '9h', '10h', ...]
  const currentHourIndex = planningSlotsKeys.indexOf(hour) // hour: 10h, index: 2
  const previousSlotsKeys = planningSlotsKeys
    .slice(0, currentHourIndex)
    .reverse() // ['9h', '8h']
  const nextSlotsKeys = planningSlotsKeys.slice(currentHourIndex + 1) // ['11h', '12h', ...]
  const aroundAgents =
    currentHourIndex > 0
      ? getAroundAgents(previousSlotsKeys, uiPlanningSlots)
      : getAroundAgents(nextSlotsKeys, uiPlanningSlots)

  return moveAllAgents(currentPlanningSlot, aroundAgents)
}
