<script lang="ts" setup>
import { PropType, ref, watch } from 'vue'
import { Agent } from 'Models/agent'
import { PanelPosition } from '@/domains/panel/store/types'
import { UIRoom } from '@/domains/room-list/ui-models'
import { KidParentProblematic } from 'Models/kid'
import useOpenRoom from '@/hooks/open-room'
import useServices from '@/hooks/services'

import HeaderView from '@/views/layouts/panels/Header.vue'
import Title from '@/components/panel/shared/header/Title.vue'
import KidParentProblem from './KidParentProblem.vue'
import Actions from './Actions.vue'
import BaseBadge from '@/components/base/BaseBadge.vue'
import useScopedI18n from '@/hooks/scoped-i18n'

const { t } = useScopedI18n()

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Middle>,
    required: true,
  },
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: false,
  },
  assignableAgents: {
    type: Array as PropType<Agent[]>,
    required: false,
  },
})

const { goToRoom } = useOpenRoom()
const services = useServices()

const kidParentProblematic = ref<KidParentProblematic>({
  isProblematic: false,
  reason: null,
})

watch(
  () => props.room.parent.id,
  async (newParentId) =>
    (kidParentProblematic.value = await services.kidParent.loadProblematic(
      newParentId,
    )),
  { immediate: true },
)
</script>

<template>
  <HeaderView
    :panel-position="panelPosition"
    :can-close="false"
    :can-prev="false"
    class="relative"
  >
    <Title
      class="flex flex-row space-x-3 cursor-pointer text-primary"
      :class="{ 'text-red-600': kidParentProblematic.isProblematic }"
      @click="goToRoom(room.id, false, room.parent.id)"
    >
      {{ $fullname(room.parent) }}
    </Title>
    <BaseBadge v-if="room.isPayAsYouGo" class="px-2 py-1 ml-4">{{
      t('payAsYouGo')
    }}</BaseBadge>
    <KidParentProblem
      v-if="kidParentProblematic.isProblematic && kidParentProblematic.reason"
      :reason="kidParentProblematic.reason"
    />

    <Actions
      v-if="currentAgent && assignableAgents"
      class="ml-auto"
      :room="room"
      :current-agent="currentAgent"
      :assignable-agents="assignableAgents"
      v-bind="$attrs"
    />

    <div
      v-if="room.warningMessage"
      class="absolute left-0 right-0 z-10 flex items-center justify-center py-2 text-sm -bottom-14"
    >
      <div class="px-3 py-2 bg-yellow-100 rounded">
        {{ room.warningMessage }}
      </div>
    </div>
  </HeaderView>
</template>
