import {
  Agent,
  DoctorTitleKey,
  MedicalTeamTitleKeys,
  NonDoctorTitleKey,
} from 'Models/agent'

export const getAgentsByTitle = (agents: Agent[]) => {
  const titleList: AgentsByTitle = {
    [DoctorTitleKey.GeneralPractitioner]: [],
    [DoctorTitleKey.Pediatrician]: [],
    [DoctorTitleKey.PediatricSurgeon]: [],
    [DoctorTitleKey.Psychiatrist]: [],
    [DoctorTitleKey.ChildPsychiatrist]: [],
    [DoctorTitleKey.Obstetrician]: [],
    [DoctorTitleKey.Gynecologist]: [],
    [NonDoctorTitleKey.Nurse]: [],
    [NonDoctorTitleKey.PediatricNurse]: [],
    [NonDoctorTitleKey.PediatricNurseAssistant]: [],
    [NonDoctorTitleKey.Midwife]: [],
    [NonDoctorTitleKey.Psychologist]: [],
    [NonDoctorTitleKey.ChildPsychologist]: [],
    [NonDoctorTitleKey.MedicalDirector]: [],
  }

  for (const agent of agents) {
    if (Object.keys(titleList).includes(agent.titleKey)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      titleList[agent.titleKey].push(agent)
    }
  }

  return titleList
}

type AgentsByTitle = {
  [key in MedicalTeamTitleKeys]: Agent[]
}
