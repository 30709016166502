<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import { PropType } from 'vue'
import { UIPrescriptionPreview } from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

defineProps({
  prescriptionPreview: {
    type: Object as PropType<UIPrescriptionPreview>,
    required: true,
  },
  withDeleteButton: {
    type: Boolean,
    required: false,
  },
})

const emits = defineEmits<{
  (e: 'deletePrescription'): void
}>()

const { t, mt } = useScopedI18n()
</script>

<template>
  <div class="flex flex-row justify-between w-full h-full">
    <div class="flex flex-col justify-center h-full">
      <h1 class="font-semibold">
        {{ t('title') }}
      </h1>
      <h2 class="text-sm text-default-light">
        <span
          v-html="
            mt('subtitle', {
              name:
                prescriptionPreview.agent &&
                $agentFullname(prescriptionPreview.agent),
            })
          "
        />
        <span
          v-if="prescriptionPreview.createdAt"
          class="pl-2 ml-2 border-l border-default-300"
        >
          {{ $formatDate(prescriptionPreview.createdAt, 'long') }}
        </span>
      </h2>
    </div>
    <button
      v-if="withDeleteButton && $canDeletePrescription()"
      v-tooltip.bottom="t('deleteTooltip')"
      class="w-8 h-8 mt-4 rounded focus:outline-none text-primary hover:bg-red-50"
      @click="emits('deletePrescription')"
    >
      <font-awesome-icon
        :icon="['fal', 'trash-alt']"
        class="w-4 h-4 text-red-600 focus:outline-none"
      />
    </button>
  </div>
</template>
