<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  selectedIllustration: {
    type: String,
  },
  illustrationsLoaded: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'selectIllustration', url: string): void
  (e: 'illustrationLoaded', url: string): void
}>()

const isSelectedIllustration = computed(
  () => props.src === props.selectedIllustration,
)
</script>

<template>
  <div
    class="p-2 rounded-lg mb-2 cursor-pointer"
    :class="{
      'bg-blue-400': isSelectedIllustration,
      hidden: !illustrationsLoaded,
    }"
    @click="emits('selectIllustration', src)"
  >
    <img
      class="rounded-lg h-72"
      :src="src"
      alt=""
      @load="emits('illustrationLoaded', src)"
    />
  </div>
</template>
