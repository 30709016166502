<script lang="ts" setup>
import { PropType, computed, watch, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAgentStore, usePrescriptionStore } from 'Store/stores'
import { RoutesNames } from '@/router/routes/type'
import { UIPrescriptionListFilterChanges } from '@/domains/prescription/ui-models'
import { PanelPosition } from '@/domains/panel/store/types'
import { parseParam } from '@/utils/route/parse-param'

import Container from '@/components/panel/side/layout/Container.vue'
import Loader from '@/components/panel/side/shared/Loader.vue'
import ListPanel from '@/domains/prescription/components/prescription-list/PrescriptionList.vue'
import { isEmpty } from '@/utils/is-empty'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
})

const route = useRoute()
const router = useRouter()
const agentStore = useAgentStore()
const {
  changePrescriptionListFilter,
  initPrescriptionListFilter,
  resetFilteredPrescriptions,
} = usePrescriptionStore()
const prescriptionStore = usePrescriptionStore()

const currentAgent = computed(() => agentStore.currentAgent)
const filter = computed(() => prescriptionStore.getPrescriptionListFilter)
const filterParams = computed(
  () => prescriptionStore.getPrescriptionListFilterParams,
)
const prescriptions = computed(
  () => prescriptionStore.getFilteredPrescriptionList,
)
const prescriptionInitiatorAgents = computed(
  () => agentStore.getPrescriptionInitiatorAgents,
)
const prescriptionReferrerAgents = computed(
  () => agentStore.getPrescriptionReferrerAgents,
)
const prescriptionId = computed(() => String(route.params.prescriptionId))

const onUpdateFilter = (changes: UIPrescriptionListFilterChanges) =>
  changePrescriptionListFilter(changes)
const onOpenPrescription = (prescriptionId: string) => {
  router.push({
    name: RoutesNames.PrescriptionDetail,
    params: { prescriptionId },
    query: filterParams.value || {},
  })
}

// While
watch(
  () => prescriptionInitiatorAgents.value,
  (newPrescriptionInitiatorAgents) => {
    if (isEmpty(newPrescriptionInitiatorAgents)) return
    initPrescriptionListFilter({
      agentId: parseParam(route, 'agentId'),
      doctorId: parseParam(route, 'doctorId'),
      startDate: parseParam(route, 'startDate'),
      endDate: parseParam(route, 'endDate'),
    })
  },
  { immediate: true },
)

watch(
  () => filterParams.value,
  async (newParams) => {
    if (!newParams || !route.name) return
    router.push({ name: route.name, query: newParams })
  },
)

// After
onBeforeUnmount(() => resetFilteredPrescriptions())
</script>

<template>
  <Container :panel-position="panelPosition">
    <ListPanel
      v-if="
        $canListPrescriptions() &&
        filter &&
        currentAgent &&
        prescriptionInitiatorAgents &&
        prescriptionReferrerAgents
      "
      :panel-position="panelPosition"
      :prescriptions="prescriptions"
      :filter="filter"
      :current-agent="currentAgent"
      :prescription-id="prescriptionId"
      :prescription-initiator-agents="prescriptionInitiatorAgents"
      :prescription-referrer-agents="prescriptionReferrerAgents"
      @update-filter="onUpdateFilter"
      @open-prescription="onOpenPrescription"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
