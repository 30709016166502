import { LocationQuery } from 'vue-router'
import { PanelsComponentNames } from '@/domains/panel/store/types'
import { PanelStoreActions } from '@/domains/panel/store/actions/types'

export const setPanelsFromRouteQuery = (
  routeQuery: LocationQuery,
  nextPanel: PanelStoreActions['nextPanel'],
): void => {
  // Media
  if (routeQuery.media) {
    // Left
    nextPanel(PanelsComponentNames.MediaViewer, {
      data: { messageId: String(routeQuery.media) },
    })
  }
}
