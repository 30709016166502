import { IKidService, IMedicalReportLibraryService } from 'Services/__types__'
import { IFirebaseService } from 'External/firebase/types'
import { FBKid, FBKidParent, Kid, KidParent } from 'Models/kid'
import { convertFrom } from 'Models/converters/kid-converter'
import { convertFrom as convertFromKidParent } from 'Models/converters/kid-parent-converter'
import {
  CollectionReference,
  documentId,
  DocumentReference,
  query,
  Query,
  where,
} from 'firebase/firestore'

export default class KidService implements IKidService {
  firebaseService: IFirebaseService
  mrlService: IMedicalReportLibraryService

  constructor(
    firebaseService: IFirebaseService,
    mrlService: IMedicalReportLibraryService,
  ) {
    this.firebaseService = firebaseService
    this.mrlService = mrlService
  }

  async loadKidParents(kidId: string): Promise<KidParent[] | undefined> {
    const kid = await this.load(kidId)

    if (!kid?.parentIds) return undefined

    const kidParents = await this.firebaseService.loadDocuments(
      this.findAllKidParents(kid.parentIds),
    )

    return kidParents
  }

  async loadKidParent(parentId: string): Promise<KidParent | undefined> {
    const parent = await this.firebaseService.loadDocument(
      this.findKidParent(parentId),
    )
    return parent
  }

  listen(kidId: string, onChange: (newKid: Kid) => void): void {
    this.firebaseService.listenDocument(
      `kid-${kidId}`,
      this.find(kidId),
      async (newKid: Kid) => onChange(newKid),
    )
  }

  unlisten(kidId: string): void {
    this.firebaseService.unlisten(`kid-${kidId}`)
  }

  async load(kidId: string): Promise<Kid | undefined> {
    return this.firebaseService.loadDocument(this.find(kidId))
  }

  loadAllKidsFromParent: IKidService['loadAllKidsFromParent'] = async (
    parentId,
  ) => await this.firebaseService.loadDocuments(this.findAllForParent(parentId))

  protected find(kidId: string): DocumentReference<Kid> {
    return this.firebaseService.getDocument('kids', kidId, convertFrom)
  }

  protected findAllForParent(parentId: string): Query<Kid> {
    return query(
      this.getCollection(),
      where('userIds', 'array-contains', parentId),
    )
  }

  protected findKidParent(parentId: string): DocumentReference<KidParent> {
    return this.firebaseService.getDocument(
      'users',
      parentId,
      convertFromKidParent,
    )
  }

  protected findAllKidParents(parentIds: string[]): Query<KidParent> {
    return query(
      this.getKidParentCollection(),
      where(documentId(), 'in', parentIds),
    )
  }

  protected getKidParentCollection(): CollectionReference<KidParent> {
    return this.firebaseService.getCollection<FBKidParent, KidParent>(
      'users',
      convertFromKidParent,
    )
  }

  protected getCollection(): CollectionReference<Kid> {
    return this.firebaseService.getCollection<FBKid, Kid>(
      this.getCollectionName(),
      convertFrom,
    )
  }

  protected getCollectionName(): string {
    return 'kids'
  }
}
