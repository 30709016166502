<script lang="ts" setup>
import { UIPrescriptionSummary } from '@/domains/prescription/ui-models'
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

import PrescriptionListItem from './PrescriptionListItem.vue'

const props = defineProps({
  prescriptionId: {
    type: String,
    required: false,
  },
  prescriptions: {
    type: Object as PropType<UIPrescriptionSummary[]>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'openPrescription', id: string): void
}>()

const { t } = useScopedI18n()

const isEmpty = computed(() => props.prescriptions.length === 0)
</script>

<template>
  <div class="h-full">
    <div v-if="isEmpty" class="h-full flex items-center justify-center">
      <p class="px-5 text-red-400 font-semibold">
        {{ t('empty') }}
      </p>
    </div>
    <div
      v-for="prescription in prescriptions"
      v-else
      :key="prescription.id"
      class="even:bg-default-50 even:bg-opacity-50 cursor-pointer"
      @click="emits('openPrescription', prescription.id)"
    >
      <PrescriptionListItem
        :prescription="prescription"
        :prescription-id="prescriptionId"
      />
    </div>
  </div>
</template>
