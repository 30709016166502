export enum RoutesNames {
  // BASE
  SignIn = 'signIn',
  SignInWith2FA = 'signInWith2FA',
  PageNotFound = 'pageNotFound',
  ResetPassword = 'resetPassword',

  // ROOM
  ShortcutRoom = 'shortcutRoom',
  CurrentRoom = 'currentRoom',

  // ROOMS
  Main = 'main',
  RoomsMe = 'roomsMe',
  RoomsMeRoomDetail = 'roomsMeRoomDetail',
  RoomsSnoozed = 'roomsSnoozed',
  RoomsSnoozedRoomDetail = 'roomsSnoozedRoomDetail',
  RoomsClosed = 'roomsClosed',
  RoomsClosedRoomDetail = 'roomsClosedRoomDetail',
  RoomsTeam = 'roomsTeam',
  RoomsTeamRoomDetail = 'roomsTeamRoomDetail',
  RoomsUnassigned = 'roomsUnassigned',
  RoomsUnassignedRoomDetail = 'roomsUnassignedRoomDetail',
  RoomsFavorites = 'roomsFavorites',
  RoomsFavoritesRoomDetail = 'roomsFavoritesRoomDetail',
  RoomsInceptionChat = 'roomsInceptionChat',
  RoomsInceptionChatRoomDetail = 'roomsInceptionChatRoomDetail',

  // KID PARENT
  KidParent = 'kidParent',
  KidParentWithRoomStatus = 'kidParentWithRoomStatus',
  KidParentRoomDetail = 'kidParentRoomDetail',

  // KID
  KidOpen = 'kidOpen',
  KidOpenRoomDetail = 'kidOpenRoomDetail',
  kidClosed = 'kidClosed',
  kidClosedRoomDetail = 'kidClosedRoomDetail',
  KidAwake = 'kidAwake',
  KidAwakeRoomDetail = 'kidAwakeRoomDetail',
  KidAsleep = 'kidAsleep',
  KidAsleepRoomDetail = 'kidAsleepRoomDetail',

  // PRESCRIPTIONS
  Prescriptions = 'prescriptions',
  PrescriptionDetail = 'prescriptionDetail',
}

export const MeTabRouteNames = [
  RoutesNames.RoomsMe,
  RoutesNames.RoomsMeRoomDetail,
]
export const FavoritesTabRouteNames = [
  RoutesNames.RoomsFavorites,
  RoutesNames.RoomsFavoritesRoomDetail,
]
export const InceptionChatTabRouteNames = [
  RoutesNames.RoomsInceptionChat,
  RoutesNames.RoomsInceptionChatRoomDetail,
]
export const AssignedToMeTabRouteNames = [
  ...MeTabRouteNames,
  ...FavoritesTabRouteNames,
  ...InceptionChatTabRouteNames,
]

export const TeamTabRouteNames = [
  RoutesNames.RoomsTeam,
  RoutesNames.RoomsTeamRoomDetail,
]
export const ClosedTabRouteNames = [
  RoutesNames.RoomsClosed,
  RoutesNames.RoomsClosedRoomDetail,
]
export const AssignedTabRouteNames = [
  ...TeamTabRouteNames,
  ...ClosedTabRouteNames,
]

export const UnassignedTabRouteNames = [
  RoutesNames.RoomsUnassigned,
  RoutesNames.RoomsUnassignedRoomDetail,
]

export const SnoozedTabRouteNames = [
  RoutesNames.RoomsSnoozed,
  RoutesNames.RoomsSnoozedRoomDetail,
]

export const KidParentTabRouteNames = [
  RoutesNames.KidParent,
  RoutesNames.KidParentWithRoomStatus,
  RoutesNames.KidParentRoomDetail,
]
