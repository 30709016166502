import { createI18n } from 'vue-i18n'
import allMessages from '@/locales'

export default createI18n({
  locale: locale(String(import.meta.env.VITE_I18N_LOCALE)),
  fallbackLocale: locale(String(import.meta.env.VITE_I18N_FALLBACK_LOCALE)),
  messages: allMessages,
})

export function locale(envLocale: string | undefined): string {
  return envLocale || 'fr'
}
