<script lang="ts" setup>
import controller from '../controllers/generate-prescription-preview'

import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import PrescriptionModal from './ConfirmPrescriptionModal.vue'

const { generatePreview, isGeneratingPreview, closeModal, isModalOpen } =
  controller()
</script>

<template>
  <BasePrimaryButton :disabled="isGeneratingPreview" @click="generatePreview">
    <slot :is-generating-preview="isGeneratingPreview">
      <font-awesome-icon :icon="['fal', 'clipboard-prescription']" />
    </slot>

    <PrescriptionModal v-if="isModalOpen" @close="closeModal" />
  </BasePrimaryButton>
</template>
