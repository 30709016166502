import i18nPlugin from '@/plugins/vue-use/i18n'
import {
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from 'date-fns'

export enum KidAgeScope {
  MRH = 'mrh',
  Short = 'short',
  Growth = 'growth',
}

const mrhKidAge = (months: number, years: number, locale: string) => {
  const i18n = i18nPlugin.global
  const scope = 'birthday.mrh'

  if (months < 1) return i18n.t(`${scope}.month`, locale)
  else if (months <= 24)
    return i18n.t(`${scope}.months`, locale, {
      months,
    })
  else
    return i18n.t(
      `${scope}.${months - years * 12 < 6 ? 'years' : 'yearsAndHalf'}`,
      locale,
      {
        years,
      },
    )
}

export const kidAge = (
  birthday: Date,
  gender: string,
  locale: string,
  today?: Date,
  scope?: KidAgeScope,
): string => {
  today = today || new Date()

  const i18n = i18nPlugin.global

  const days = differenceInDays(today, birthday)
  const weeks = differenceInWeeks(today, birthday)
  const months = differenceInMonths(today, birthday)
  const years = differenceInYears(today, birthday)

  if (scope === KidAgeScope.MRH) return mrhKidAge(months, years, locale)

  const i18nScope = scope ? `birthday.${scope}` : 'birthday'

  if (days === 0) return i18n.t(`${i18nScope}.today.${gender}`, locale)
  else if (days < 15) return i18n.tc(`${i18nScope}.days`, days, locale)
  else if (months < 3)
    return i18n.t(`${i18nScope}.weeks`, locale, { weeks, n: days - weeks * 7 })
  else if (years < 2)
    return i18n.t(`${i18nScope}.months`, locale, {
      months,
      n: Math.trunc((days - months * 30.4167) / 7),
    })
  else
    return i18n.t(`${i18nScope}.years`, locale, {
      years,
      n: months - years * 12,
    })
}
