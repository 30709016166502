import { ActionTree } from './types'
import Services from 'Services/services'

export const removeCurrentKidAntecedent: ActionTree['removeCurrentKidAntecedent'] =
  async function (antecedentId) {
    const kidId = this.currentKid?.id

    if (!kidId) return

    await Services.getInstance().kidAntecedent.removeKidAntecedent(
      kidId,
      antecedentId,
    )

    // Force reload of antecedents
    this.loadKidAntecedents()
  }
