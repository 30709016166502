import { KidMeasuresResponse } from 'External/api/types'
import { APIKidMeasure, KidMeasureSet, KidMeasure } from 'Models/kid'

export const convertSetFrom = ({
  measures,
}: KidMeasuresResponse): KidMeasureSet => ({
  weight: measures.weight.map((measure) => convertFrom(measure)),
  height: measures.height.map((measure) => convertFrom(measure)),
  head: measures.head.map((measure) => convertFrom(measure)),
})

const convertFrom = (measure: APIKidMeasure): KidMeasure => ({
  value: measure.value,
  unit: measure.unit,
  createdAt: new Date(measure.measuredAtMillis),
})
