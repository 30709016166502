import { Agent } from 'Models/agent'
import { AgentGroup, BuiltInAgentGroups } from 'Models/agent-group'

export const filterAgentsBy = (
  groupName: BuiltInAgentGroups,
  agents: Agent[] | null,
  agentGroups: AgentGroup[] | null,
): Agent[] => {
  if (!agentGroups || !agents) return []

  const group = agentGroups.find((group) => group.name === groupName)

  if (!group) return []

  return agents
    .filter(
      (agent) =>
        group.agentIds.indexOf(agent.id) !== -1 && !agent.isAccountDisabled,
    )
    .sort(
      (agentA, agentB) =>
        (agentA.isWorking === agentB.isWorking
          ? 0
          : agentA.isWorking
          ? -1
          : 1) || // Sort by isWorking
        agentA.firstName.localeCompare(agentB.firstName), // Sort alphabetically by firstName with accents
    )
}
