import { ComputedRef, ref, Ref, computed, onBeforeMount } from 'vue'
import { Kid, KidFamily, KidParent } from 'Models/kid'
import useServices from '@/hooks/services'
import Services from 'Services/services'

export const useKidFamily = (
  kidId: string,
): {
  isLoadingKidFamily: Ref<boolean>
  kidFamily: ComputedRef<KidFamily>
} => {
  const services = useServices()

  const isLoadingKidFamily = ref(false)
  const parents = ref<KidParent[]>([])
  const siblings = ref<Kid[]>([])

  const kidFamily = computed(() => {
    return {
      parents: parents.value,
      siblings: siblings.value,
    }
  })

  const getFamily = async () => {
    isLoadingKidFamily.value = true
    parents.value = (await services.kid.loadKidParents(kidId)) ?? []
    siblings.value = await getSiblings(parents.value, services)
    isLoadingKidFamily.value = false
  }

  onBeforeMount(async () => {
    await getFamily()
  })

  return {
    isLoadingKidFamily,
    kidFamily,
  }
}

const getSiblings = async (
  parents: KidParent[],
  services: Services,
): Promise<Kid[]> => {
  const siblings: Kid[] = []
  const ids: string[] = []
  for (const parent of parents) {
    const kids = await services.kid.loadAllKidsFromParent(parent.id)

    if (!kids) return []

    for (const kid of kids) {
      if (!ids.includes(kid.id)) {
        siblings.push(kid)
        ids.push(kid.id)
      }
    }
  }

  return siblings
}
