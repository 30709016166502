import { ref } from 'vue'

export default function useRotateImage(): {
  onRotateImage: (el: HTMLElement) => void
} {
  const imageRotation = ref(0)

  const onRotateImage = (el: HTMLElement) => {
    if (!el.classList.contains('transform')) {
      el.classList.add('transform')
    }

    imageRotation.value += 90
    switch (imageRotation.value) {
      case 0:
      case 360:
        imageRotation.value = 0
        el.classList.remove('-rotate-90')
        break
      case 90:
        el.classList.add('rotate-90')
        break
      case 180:
        el.classList.remove('rotate-90')
        el.classList.add('rotate-180')
        break
      case 270:
        el.classList.remove('rotate-180')
        el.classList.add('-rotate-90')
    }
  }

  return {
    onRotateImage,
  }
}
