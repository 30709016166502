<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { isEmpty } from '@/utils/is-empty'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIKidMeasureSet } from '@/domains/kid/ui-models'

import BaseTabs from '@/components/base/BaseTabs.vue'
import BaseTab from '@/components/base/BaseTab.vue'
import KidHeightChart from '@/domains/kid/components/kid-measures/charts/KidHeightChart.vue'
import KidWeightChart from '@/domains/kid/components/kid-measures/charts/KidWeightChart.vue'
import KidHeadChart from '@/domains/kid/components/kid-measures/charts/KidHeadChart.vue'
import KidNoMeasuresMessage from './KidNoMeasuresMessage.vue'

const props = defineProps({
  ageInMonths: {
    type: Number,
    required: true,
  },
  sex: {
    type: String as PropType<'female' | 'male'>,
    required: true,
  },
  measureSet: {
    type: Object as PropType<UIKidMeasureSet>,
    required: true,
  },
})

const { t } = useScopedI18n()

const activeTabIndex = ref<number>(0)

const hasHeightData = computed(() => !isEmpty(props.measureSet.height))
const hasWeightData = computed(() => !isEmpty(props.measureSet.weight))
const hasHeadData = computed(() => !isEmpty(props.measureSet.head))
</script>

<template>
  <BaseTabs
    v-model="activeTabIndex"
    nav-class="flex items-center justify-center w-full"
  >
    <BaseTab :title="t('tabs.weight')" class="h-full overflow-y-auto scrollbar">
      <div
        v-if="hasWeightData"
        class="flex justify-center items-center flex-col h-full"
      >
        <KidWeightChart
          :age-in-months="ageInMonths"
          :sex="sex"
          :data="measureSet.weight"
          class="mt-4"
        />
        <p class="mt-8 text-center text-default-400">
          {{ t('zoomInstructions') }}
        </p>
      </div>
      <KidNoMeasuresMessage v-else />
    </BaseTab>
    <BaseTab :title="t('tabs.height')" class="h-full overflow-y-auto scrollbar">
      <div
        v-if="hasHeightData"
        class="flex justify-center items-center flex-col h-full"
      >
        <KidHeightChart
          :age-in-months="ageInMonths"
          :sex="sex"
          :data="measureSet.height"
          class="mt-4"
        />
        <p class="mt-8 text-center text-default-400">
          {{ t('zoomInstructions') }}
        </p>
      </div>
      <KidNoMeasuresMessage v-else />
    </BaseTab>
    <BaseTab :title="t('tabs.head')" class="h-full overflow-y-auto scrollbar">
      <div
        v-if="hasHeadData"
        class="flex justify-center items-center flex-col h-full"
      >
        <KidHeadChart
          :age-in-months="ageInMonths"
          :sex="sex"
          :data="measureSet.head"
          class="mt-4"
        />
        <p class="mt-8 text-center text-default-400">
          {{ t('zoomInstructions') }}
        </p>
      </div>
      <KidNoMeasuresMessage v-else />
    </BaseTab>
    <BaseTab :title="t('tabs.bmi')" class="h-full overflow-y-auto scrollbar">
      <div class="flex justify-center items-center flex-col h-full">
        <p class="text-center text-default-400">
          {{ t('BMIComingSoon') }}
        </p>
      </div>
    </BaseTab>
  </BaseTabs>
</template>
