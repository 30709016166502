<script lang="ts" setup>
import { PropType, computed, ref, watch, nextTick } from 'vue'
import { UIInceptionChatParticipant } from '../ui-models'
import { MentionSuggestionItem } from '@/lib/tiptap/extensions/mention-suggestion'
import { Agent } from 'Models/agent'
import { UICoreChatMessage } from '@/domains/core-chat/ui-models'

import CoreChatMessageListGroupedByDay from '@/domains/core-chat/components/CoreChatMessageListGroupedByDay.vue'
import MessageInput from './MessageInput.vue'
import MessageEmptyList from './MessageEmptyList.vue'

const props = defineProps({
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  messages: {
    type: Array as PropType<UICoreChatMessage[]>,
    required: true,
  },
  currentAgentId: {
    type: String,
    required: true,
  },
  agentsToMention: {
    type: Array as PropType<MentionSuggestionItem[]>,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  participants: {
    type: Array as PropType<UIInceptionChatParticipant[]>,
    required: true,
  },
})

const scrollView = ref<HTMLDivElement | null>(null)

const count = computed(() => props.messages.length)
const isEmpty = computed(() => count.value === 0)

const emits = defineEmits<{
  (e: 'sendText', html: string, mentionedAgentIds: string[]): void
}>()

const onSendText = (html: string, mentionedAgentIds: string[]) =>
  emits('sendText', html, mentionedAgentIds)

watch(
  () => count.value,
  async () => {
    await nextTick() // wait for the new message to pop up
    if (!scrollView.value) return
    scrollView.value.scrollTop = scrollView.value.scrollHeight
  },
  { immediate: true },
)
</script>

<template>
  <div class="w-full flex flex-grow h-full flex-col">
    <div ref="scrollView" class="flex-grow overflow-y-auto scrollbar px-4 pb-4">
      <MessageEmptyList v-if="isEmpty" />
      <CoreChatMessageListGroupedByDay
        v-else
        :agents="agents"
        :messages="messages"
        :current-agent-id="currentAgentId"
        :is-inception-chat="true"
      />
    </div>
    <div v-if="!readOnly" class="px-4">
      <MessageInput
        :participants="participants"
        :agents-to-mention="agentsToMention"
        @send-text="onSendText"
      />
    </div>
  </div>
</template>
