import { isEmpty } from '@/utils/is-empty'
import { Agent } from 'Models/agent'
import { MedicalReport } from 'Models/medical-report'
import {
  UIMedicalReport,
  UIMRLCategory,
} from '@/domains/medical-report/ui-models'
import {
  buildUIMRLCategories,
  buildUIMRLItems,
} from '@/domains/medical-report/store/getters/concerns/build-ui-medical-report-library-items'
import { buildUIPrescription } from '@/domains/prescription/store/getters/concerns/build-ui-prescription'
import { Prescription } from 'Models/prescription'
import { UIPrescription } from '@/domains/prescription/ui-models'
import { AvailableLocales } from '@/locales'
import { MRLItem } from 'Models/medical-report-library-item'

export function buildUIMedicalReport(
  medicalReport: MedicalReport,
  prescription: Prescription | null | undefined,
  locale: AvailableLocales,
  agents: Agent[],
  causes: MRLItem[] | null,
  diagnosis: MRLItem[] | null,
  directions: MRLItem[] | null,
  supervisions: MRLItem[] | null,
): UIMedicalReport {
  let uiPrescription: UIPrescription | undefined = undefined

  if (prescription && agents) {
    uiPrescription = buildUIPrescription(prescription, agents)
  }

  const uiMedicalReport: UIMedicalReport = {
    id: medicalReport.id,
    roomId: medicalReport.roomId,
    kidParent: medicalReport.kidParent,
    isDisplayed: !isEmpty(medicalReport.agent),
    hasPrescription: !!prescription,
    prescription: uiPrescription,
    hasLetter: medicalReport.hasLetter,
    roomCreatedAt: medicalReport.roomCreatedAt,
    agent: medicalReport.agent,
    instructions: medicalReport.instructions,
    groupedCauses: buildUIMRLCategories(
      medicalReport.causesIds,
      causes,
      locale,
    ),
    groupedDiagnosis: buildUIMRLCategories(
      medicalReport.diagnosisIds,
      diagnosis,
      locale,
      undefined,
      medicalReport.visibleDiagnosticIds,
    ),
    initialDirections: buildUIMRLItems(
      medicalReport.initialDirectionsIds,
      directions,
      locale,
    ),
    directions: buildUIMRLItems(
      medicalReport.directionsIds,
      directions,
      locale,
    ),
    supervisions: buildUIMRLItems(
      medicalReport.supervisionsIds,
      supervisions,
      locale,
    ),
    isEmpty: true,
    estimatedTimeSpent: medicalReport.estimatedTimeSpent,
    estimatedComplexity: medicalReport.estimatedComplexity,
    medicalOpinionRequested: medicalReport.medicalOpinionRequested,
    requestedDoctor: medicalReport.requestedDoctor,
  }

  uiMedicalReport.causes = extractUIMRLItems(uiMedicalReport.groupedCauses)
  uiMedicalReport.diagnosis = extractUIMRLItems(
    uiMedicalReport.groupedDiagnosis,
  )

  uiMedicalReport.isEmpty =
    isEmpty(uiMedicalReport.instructions) &&
    isEmpty(uiMedicalReport.supervisions) &&
    isEmpty(uiMedicalReport.causes) &&
    isEmpty(uiMedicalReport.diagnosis) &&
    isEmpty(uiMedicalReport.directions) &&
    isEmpty(uiMedicalReport.initialDirections) &&
    isEmpty(uiMedicalReport.estimatedTimeSpent) &&
    isEmpty(uiMedicalReport.estimatedComplexity)

  return uiMedicalReport
}

const extractUIMRLItems = (categories: UIMRLCategory[] | undefined) => {
  if (!categories) return
  return categories.map((category) => category.items).flat()
}
