<script lang="ts" setup>
import { PropType, toRefs } from 'vue'
import { Agent } from 'Models/agent'
import { PlanningAgentsTypes } from 'Models/planning'
import controller from '../../../../controllers/agents/compute-tab-counter'

const props = defineProps({
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  agentsType: {
    type: String as PropType<PlanningAgentsTypes>,
    required: true,
  },
  selectedAgentsType: {
    type: String as PropType<PlanningAgentsTypes>,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  query: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'selectAgents', agentsType: PlanningAgentsTypes): void
}>()

const { agents, query } = toRefs(props)
const { agentsQueried, agentsTotal, showAgentsRatio } = controller(
  agents,
  query,
)
</script>

<template>
  <li
    :class="
      agentsType === selectedAgentsType
        ? 'border-b-2 border-primary box-content'
        : 'cursor-pointer border-b-2 border-white text-primary'
    "
    class="flex items-center py-2 rounded-tl-md rounded-tr-md overflow-hidden hover:border-primary text-sm"
    @click="emits('selectAgents', agentsType)"
  >
    {{ title }} ({{
      showAgentsRatio ? `${agentsQueried}/${agentsTotal}` : agentsTotal
    }})
  </li>
</template>
