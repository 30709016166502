import Services from 'Services/services'
import { ActionTree } from './types'

export const setCurrentKidParent: ActionTree['setCurrentKidParent'] =
  async function (parentId) {
    if (!parentId) return

    this.currentKidParent = null

    const kidParent = await Services.getInstance().kid.loadKidParent(parentId)

    if (kidParent) this.currentKidParent = kidParent
  }
