<script lang="ts" setup>
import { PropType, computed, ref, watch, Ref } from 'vue'
import { UIPrescription } from '@/domains/prescription/ui-models'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { isEmpty } from '@/utils/is-empty'
import useScopedI18n from '@/hooks/scoped-i18n'

import Section from '@/components/panel/side/shared/Section.vue'

const props = defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'updateKidLastName', newLastName: string): void
}>()

const { t } = useScopedI18n()

const kidLastName: Ref<string | null> = ref(null)

const hasKidLastName = computed(() => !isEmpty(kidLastName.value))

watch(
  () => kidLastName.value,
  (newValue) => {
    if (newValue) emits('updateKidLastName', newValue)
  },
)

watch(
  () => props.prescription,
  (prescription) => (kidLastName.value = prescription.kidLastName),
  { immediate: true },
)
</script>

<template>
  <Section>
    <label v-if="!hasKidLastName" for="kidLastName" class="text-orange-500">
      {{
        t('label', {
          firstName: kidProfile.firstName,
        })
      }}
    </label>
    <input
      id="kidLastName"
      v-model="kidLastName"
      type="text"
      class="w-full rounded border border-gray-100 px-3 py-2 h-10 outline-none"
      :placeholder="t('placeholder')"
    />
  </Section>
</template>
