import { ActionTree } from './types'
import { useAgentStore, useRoomListStore, useUIStore } from 'Store/stores'
import Services from 'Services/services'

export const signOut: ActionTree['signOut'] = async function () {
  useUIStore().setIsLoading(true)

  await Services.getInstance().authentication.signOut()

  this.isAuthenticated = false
  useAgentStore().setCurrentAgent(null)
  useRoomListStore().setRooms(null)

  Services.getInstance().roomWorkspace.reset()
}
