import { Timestamp } from 'firebase/firestore'
import { AgentGender, TitleKey } from './agent'
import { KidGender } from './kid'
import { MessageSender, MessageSenderType, MessageTypes } from './message'

export enum RoomStatuses {
  Open = 'open',
  Snoozed = 'snoozed', // NOTE: Status coming from FB
  Closed = 'closed',
  Awake = 'awake', // NOTE: Status built in Inbox
  Asleep = 'asleep', // NOTE: Status built in Inbox
}

export type FBRoom = {
  id: string
  createdAt: Timestamp
  hasUserRead: boolean
  haveAgentsRead: string[] | null
  isAgentTyping: boolean
  isUserTyping: boolean
  hasLetter: boolean
  agentShiftIsEnding: boolean
  markedByAgentsAsFavorite: string[]
  lastHumanSender?: MessageSenderType
  lastUserThreadStartedAt?: Timestamp // 'thread' term means user's messages without agent's or robot's messages between
  prescriptions?: [
    {
      id: string
      status: 'CREATED'
      type: 'TREATMENT'
    },
  ]
  status: {
    type: RoomStatuses
    agent: FBRoomAgent
    updatedAt?: Timestamp // NOTE: when setting ServerTimestamp as the value during an update, FB resets the field for a very short period.
    snoozedUntil: Timestamp | null
  }
  agent: FBRoomAssignedAgent | null
  assignedBy: FBRoomAgent
  user: {
    id: string
    firstName: string
    lastName: string
    email?: string
    language?: string
  }
  kid: {
    id: string
    firstName: string
    lastName: string
    gender: number
    birthDate: Timestamp
  }
  payment?: {
    status: 'OK'
    type: 'CREDIT'
  }
  lastMessage: FBRoomLastMessage
}

export type FBRoomLastMessage = {
  id: string
  type: MessageTypes
  content: string
  createdAt?: Timestamp
  sender: {
    id?: string
    firstName?: string
    lastName?: string
    type: MessageSenderType
  }
}

export type FBRoomAgent = {
  id: string
  firstName: string
  lastName: string
  gender: AgentGender
  email: string
  titleKey: TitleKey
  title: string
  profilePic?: string | null
  bio: {
    en: string
    fr: string
  }
}

export type FBRoomAssignedAgent = FBRoomAgent & {
  assignmentAccepted: boolean
}

// Room object created from the raw data Firebase returns
export type Room = {
  id: string
  assignedAgent: RoomAssignedAgent | null
  parent: RoomParent
  lastMessage: RoomLastMessage | null
  assignedBy: RoomAgent | null
  createdAt: Date
  hasParentRead: boolean
  haveAgentsRead: string[]
  isParentTyping?: boolean
  isUnassigned: boolean
  isBeingAssigned: boolean
  isClosed: boolean
  kid: RoomKid
  status: RoomStatus
  prescriptions: Prescription[]
  hasPrescription: boolean
  hasLetter: boolean
  agentShiftIsEnding: boolean
  markedByAgentsAsFavorite: string[]
  lastParentThreadStartedAt?: Date
  lastHumanSender?: MessageSenderType
  payment?: {
    status: 'OK'
    type: 'CREDIT'
  }
}

type Prescription = {
  id: string
  status: 'CREATED'
  type: 'TREATMENT'
}

export type RoomStatus = {
  type: RoomStatuses
  agent: RoomAgent
  updatedAt: Date
  snoozedUntil: Date | null
}

export type RoomKid = {
  id: string
  firstName: string
  lastName: string
  gender: KidGender
  birthDate: Date
}

export type RoomAgent = {
  id: string
  firstName: string
  lastName: string
  email: string
  titleKey: TitleKey
  gender: AgentGender
  profilePic?: string | null
  title: string
}

export type RoomAssignedAgent = RoomAgent & { assignmentAccepted: boolean }

export type RoomParent = {
  id: string
  firstName: string
  lastName: string
}

export type RoomLastMessage = {
  id: string
  type: MessageTypes
  content: string
  createdAt?: Date // can be empty for a couple of milliseconds (see doc about the Firebase server timestamp)
  sender: MessageSender
}

export type RoomComment = {
  id: string
  agentId: string
  comment: string
  commentedAt: Date
}
