<script lang="ts" setup>
import { UIPrescriptionSummary } from '@/domains/prescription/ui-models'
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'

import BaseAvatar from '@/components/base/BaseAvatar.vue'

const props = defineProps({
  prescriptionId: {
    type: String,
    required: false,
  },
  prescription: {
    type: Object as PropType<UIPrescriptionSummary>,
    required: true,
  },
})

const { t } = useScopedI18n()

const selected = computed(() => props.prescriptionId === props.prescription.id)
const doctor = computed(() => props.prescription.doctor)
</script>

<template>
  <div class="border-t border-default-100 px-5 py-3">
    <div class="flex items-center">
      <div
        class="flex text-sm font-semibold capitalize truncate border-b-2 transition-colors duration-500"
        :class="
          selected ? 'border-primary' : 'border-white hover:border-default-300'
        "
      >
        {{ $fullname(prescription.parent) }}&nbsp;•&nbsp;
        <span
          v-tooltip="
            t('kidInfo', {
              age: $kidAge(prescription.kid, prescription.createdAt),
              weight: prescription.kid.weight,
            })
          "
        >
          {{ $fullname(prescription.kid) }}
        </span>
      </div>
      <div class="flex flex-1 justify-end text-xs text-default-400">
        {{ $formatDate(prescription.createdAt) }}
      </div>
    </div>
    <div class="flex items-center mt-2">
      <BaseAvatar
        type="agent"
        :first-name="doctor.firstName"
        :last-name="doctor.lastName"
        :profile-pic="doctor.profilePic"
        :size="2"
      />
      <span class="ml-2 text-sm">{{ $agentFullname(doctor) }}</span>
    </div>
  </div>
</template>
