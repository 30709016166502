import {
  Prescription,
  PrescriptedDrug,
  PrescriptionStatus,
  PrescriptionPreview,
} from '@/models/prescription'
import { isEmpty } from '@/utils/is-empty'
import { Agent } from 'Models/agent'
import {
  UIPrescription,
  UIPrescriptionItem,
  UIPrescriptionPreview,
  UIPrescriptionSummary,
} from '@/domains/prescription/ui-models'

export const buildUIPrescription = (
  prescription: Prescription,
  agents: Agent[],
  currentRoomKidLastName?: string,
): UIPrescription => {
  const hasInstructions = !isEmpty(prescription.instructions)
  const instructions = prescription.instructions ?? []
  const hasDrugs = !isEmpty(prescription.prescriptedDrugs)

  const items = prescription.prescriptedDrugs.map(buildUIPrescriptionItem)

  return {
    id: prescription.id,
    agent: agents.find((agent) => agent.id === prescription.agentId),
    doctor: agents.find((agent) => agent.id === prescription.doctorId),
    roomId: prescription.roomId,
    kidParentId: prescription.userId,
    kidLastName: prescription.kidLastName || currentRoomKidLastName || '',
    hadKidLastName: prescription.hadKidLastName,
    kidWeightInKg: prescription.kidWeightInKg,
    hasDrugs,
    instructions,
    hasInstructions,
    status: prescription.status,
    type: prescription.type,
    isValid: isValid(prescription),
    couldNotBePersisted: prescription.couldNotBePersisted || false,
    isNew: prescription.status === PrescriptionStatus.Draft,
    createdAt: prescription.createdAt,
    items,
    previewUrl: `data:application/pdf;base64,${prescription.pdfFileInBase64}`,
    mobile: prescription.mobile,
  }
}

const isValid = (prescription: Prescription): boolean => {
  return (
    !isEmpty(prescription.kidLastName) &&
    !isEmpty(prescription.kidWeightInKg) &&
    prescription.prescriptedDrugs.length > 0
  )
}

// === Summary (Prescription history) ===

export const buildUIPrescriptionSummary = (
  prescription: Prescription,
  agents: Agent[],
): UIPrescriptionSummary => {
  const doctor = agents.find((agent) => agent.id === prescription.doctorId)

  if (
    !prescription.id ||
    !prescription.createdAt ||
    !prescription.kid ||
    !prescription.parent ||
    !doctor
  )
    throw `Invalid prescription #${prescription.id || prescription.roomId}`

  return {
    id: prescription.id,
    roomId: prescription.roomId,
    doctor,
    kid: { ...prescription.kid, isBoy: prescription.kid.sex === 'male' },
    parent: prescription.parent,
    previewUrl: `data:application/pdf;base64,${prescription.pdfFileInBase64}`,
    createdAt: prescription.createdAt,
  }
}

// === Preview ===

const buildUIPrescriptionItem = (item: PrescriptedDrug): UIPrescriptionItem => {
  return {
    drug: item.brandedDrug.name,
    molecules: item.brandedDrug.molecules.map((module) => module.shortLabel),
    presentation: { long: item.drug.name, short: item.drug.shortLabel },
    dosage: { long: item.dosage.name, short: item.dosage.shortLabel },
    duration: item.duration,
    isReplaceable: item.replaceable,
  }
}

export const buildUIPrescriptionPreview = (
  prescriptionPreview: Prescription | PrescriptionPreview,
  agents: Agent[],
): UIPrescriptionPreview => {
  const hasInstructions = !isEmpty(prescriptionPreview.mobile?.instructions)

  return {
    type: prescriptionPreview.type,
    agent: agents.find((agent) => agent.id === prescriptionPreview.agentId),
    hasInstructions,
    createdAt: prescriptionPreview.createdAt,
    previewUrl: `data:application/pdf;base64,${prescriptionPreview.pdfFileInBase64}`,
    mobile: prescriptionPreview.mobile,
  }
}
