import { ActionTree } from './types'
import { Agent } from 'Models/agent'
import { RoomListFilter } from '../types'
import { startOfDay } from 'date-fns'
import { RoomStatuses } from 'Models/room'
import { UIRoomListFilterParams } from '../../ui-models'
import { getAgentById } from '@/utils/agent/get-agent-by-id'

export const initRoomListFilter: ActionTree['initRoomListFilter'] = function (
  urlParams,
  agents,
) {
  this.filter = buildFilter(urlParams, agents)
}

const buildFilter = (urlParams: UIRoomListFilterParams, agents: Agent[]) => {
  // default filter
  const filter: RoomListFilter = {
    agent: null,
    date: null,
    status: null,
  }

  if (urlParams.agentId) filter.agent = getAgentById(agents, urlParams.agentId)

  if (urlParams.date) filter.date = startOfDay(new Date(urlParams.date))

  if (urlParams.status) {
    const statusKey = Object.entries(RoomStatuses).find(
      (keyValue) => keyValue[1] === urlParams.status,
    )?.[0]

    if (statusKey)
      filter.status = RoomStatuses[statusKey as keyof typeof RoomStatuses]
  }

  return filter
}
