<script lang="ts" setup>
import { PropType } from 'vue'
import { UIMedicalReport } from '@/domains/medical-report/ui-models'
import { Agent } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'

import AgentPicker from '@/domains/agent/views/AgentPicker.vue'

defineProps({
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
  medicalReportAssignableAgents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div class="flex flex-row items-center w-full">
    <div>{{ t('title') }}</div>
    <AgentPicker
      :selected-agent="medicalReport.agent"
      :current-agent="currentAgent"
      :agents="medicalReportAssignableAgents"
      class="px-0"
      :allow-clear="false"
      v-bind="$attrs"
      :reduced-height="true"
    />
  </div>
</template>
