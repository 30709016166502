import { useSystemStore } from 'Store/stores'

export const setSystemPlugin = () => {
  const { setIsOffline, setSystemError } = useSystemStore()

  // Global events
  window.addEventListener('online', () => setIsOffline(false))
  window.addEventListener('offline', () => setIsOffline(true))
  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    if (event.reason.code !== 'API_ERROR') return
    setSystemError()
  }
}
