import { State } from './types'

export const state: State = {
  currentPrescription: null,
  prescriptionPreview: null,
  prescriptionListFilter: null,
  filteredPrescriptionList: null,
  currentFilteredPrescription: null,
  brandedDrugs: null,
  drugs: null,
}
