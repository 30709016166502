<script lang="ts" setup>
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIBrandedDrug } from '@/domains/prescription/ui-models'
import { PanelPosition } from '@/domains/panel/store/types'

import Header from '@/views/layouts/panels/Header.vue'
import HeaderTitle from '@/components/panel/shared/header/Title.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import Title from '@/components/panel/side/shared/Title.vue'
import LinkToPanel from '@/components/panel/side/shared/block/LinkToPanel.vue'
import Summary from './Summary.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'
import Search from '@/components/panel/side/shared/Search.vue'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  brandedDrugs: {
    type: Array as PropType<UIBrandedDrug[]>,
    required: true,
  },
  query: {
    type: String,
    required: true,
  },
  kidWeight: {
    type: Number,
    required: true,
  },
  kidAgeToday: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'update:query', query: string): void
  (e: 'select', brandedDrug: UIBrandedDrug): void
}>()

const { t, tc } = useScopedI18n()

const searchQuery = computed({
  get: () => props.query,
  set: (newQuery) => emits('update:query', newQuery),
})
</script>

<template>
  <Header :panel-position="panelPosition" :can-close="false">
    <HeaderTitle>
      {{ t('title') }}
    </HeaderTitle>
  </Header>

  <Summary :kid-age-today="kidAgeToday" :kid-weight="kidWeight" />
  <Title class="px-5 pt-5">{{ tc('drugs', brandedDrugs.length) }}</Title>
  <Search v-model:query="searchQuery" />

  <Body>
    <ul class="list-none list-inside divide-y divide-default-100">
      <li
        v-for="(brandedDrug, index) in brandedDrugs"
        :key="index"
        class="hover:bg-blue-50"
      >
        <LinkToPanel class="border-none" @click="emits('select', brandedDrug)">
          <div class="flex flex-1 flex-col mr-5">
            <div
              v-text-highlight="{ query, content: brandedDrug.name }"
              class="font-semibold"
            />
            <div
              v-for="(molecule, moleculeIndex) in brandedDrug.molecules"
              :key="moleculeIndex"
              v-text-highlight="{ query, content: molecule }"
              class="text-xxs leading-normal"
            />
          </div>

          <template #right>
            <font-awesome-icon
              :icon="['fal', 'chevron-square-right']"
              class="focus:outline-none text-xl"
            />
          </template>
        </LinkToPanel>
      </li>
    </ul>
    <Spacer />
  </Body>
</template>
