import { ActionTree } from './types'
import { useMedicalReportStore } from 'Store/stores'
import Services from 'Services/services'

export const addOrUpdateCurrentKidAntecedent: ActionTree['addOrUpdateCurrentKidAntecedent'] =
  async function (antecedent) {
    const kidId = this.currentKid?.id
    const antecedents = useMedicalReportStore().antecedents

    if (!kidId || !antecedents) return

    if (!antecedent.checked) return
    const mrlItem = antecedents?.find((mrlItem) => mrlItem.id === antecedent.id)
    if (!mrlItem) return undefined

    await Services.getInstance().kidAntecedent.addOrUpdateKidAntecedent(kidId, {
      antecedentId: mrlItem.id,
      hidden: mrlItem.canBeHidden ? antecedent.hidden : false,
    })

    // Force reload of antecedents
    this.loadKidAntecedents()
  }
