import { Ref, ref } from 'vue'
import { UIPlanningSlot } from '@/domains/planning/ui-models'
import { PlanningAgentsTypes } from 'Models/planning'

export default function showAgentList(): {
  showAgents: Ref<boolean>
  selectedSlot: Ref<UIPlanningSlot>
  agentsType: Ref<PlanningAgentsTypes>
  onOpenAgentList: (
    selectedPlanningSlot: UIPlanningSlot,
    selectedAgentsType: PlanningAgentsTypes,
  ) => void
  onCloseAgentsList: () => void
  onSelectAgents: (selectedAgentsType: PlanningAgentsTypes) => void
} {
  const showAgents = ref(false)
  const selectedSlot = ref()
  const agentsType = ref()

  const onOpenAgentList = (
    selectedPlanningSlot: UIPlanningSlot,
    selectedAgentsType: PlanningAgentsTypes,
  ) => {
    selectedSlot.value = selectedPlanningSlot
    agentsType.value = selectedAgentsType
    showAgents.value = true
  }
  const onCloseAgentsList = () => (showAgents.value = false)
  const onSelectAgents = (selectedAgentsType: PlanningAgentsTypes) => {
    agentsType.value = selectedAgentsType
  }

  return {
    showAgents,
    selectedSlot,
    agentsType,
    onOpenAgentList,
    onCloseAgentsList,
    onSelectAgents,
  }
}
