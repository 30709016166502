import { GetterTree } from './types'
import { getSavedReplies } from './get-saved-replies'
import { getSavedReply } from './get-saved-reply'
import { getQuickReplies } from './get-quick-replies'

export const getters: GetterTree = {
  getSavedReplies,
  getSavedReply,
  getQuickReplies,
}
