import { Room, RoomStatuses } from 'Models/room'
import BasePolicy from './base'

export default class InceptionAppPolicy extends BasePolicy {
  show(room: Room, hasMessages: boolean): boolean {
    return (
      room.status.type === RoomStatuses.Open ||
      room.status.type == RoomStatuses.Snoozed ||
      (room.status.type === RoomStatuses.Closed && hasMessages)
    )
  }
  postText(room: Room): boolean {
    return (
      this.show(room, true) &&
      (room.status.type == RoomStatuses.Open ||
        room.status.type == RoomStatuses.Snoozed)
    )
  }
}
