import { GetterTree } from './types'
import { buildUISavedReplies } from './concerns/build-ui-saved-reply'
import { sortByPosition } from '@/utils/sort-by-position'
import { UISavedReply } from '../../ui-models'

export const getSavedReplies: GetterTree['getSavedReplies'] = function ({
  savedReplies,
}) {
  return (locale, parentFirstName, kidFirstName, kidSex) => {
    if (!savedReplies || !locale) return null

    const uiSavedReplies: UISavedReply[] = []

    buildUISavedReplies(
      savedReplies,
      uiSavedReplies,
      locale,
      parentFirstName,
      kidFirstName,
      kidSex,
    )

    return sortByPosition(uiSavedReplies)
  }
}
