<script lang="ts" setup>
import { computed, onBeforeUnmount, PropType, watch } from 'vue'
import {
  useAgentStore,
  useCoreChatStore,
  usePanelStore,
  useRoomStore,
} from 'Store/stores'
import { Agent } from 'Models/agent'
import useOpenRoom from '@/hooks/open-room'
import {
  PanelPosition,
  PanelsComponentNames,
} from '@/domains/panel/store/types'

import MiddleContainer from '@/components/panel/middle/layout/MiddleContainer.vue'
import CoreChat from '@/components/panel/middle/Chat.vue'
import Loader from '@/components/panel/middle/Loader.vue'
import router from '@/router'
import { RoutesNames } from '@/router/routes/type'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Middle>,
    required: true,
  },
  roomId: {
    type: String,
    required: true,
  },
})

const agentStore = useAgentStore()
const { nextPanel } = usePanelStore()
const coreChatStore = useCoreChatStore()
const roomStore = useRoomStore()
const {
  assignCurrentRoom,
  acceptCurrentRoomAssignment,
  refuseCurrentRoomAssignment,
  unassignCurrentRoom,
  setCurrentRoom,
  resetCurrentRoom,
  toggleCurrentRoomAsFavorite,
  closeRoom,
  snoozeRoom,
  reOpenRoom,
} = useRoomStore()
const { goToRoom } = useOpenRoom()

const currentAgent = computed(() => agentStore.currentAgent)
const agents = computed(() => agentStore.agents)
const assignableAgents = computed(() => agentStore.getAssignableAgents)
const messages = computed(() => coreChatStore.getCurrentMessages)
const currentRoom = computed(() => roomStore.getCurrentRoom)

const onAssignAgent = async (agent?: Agent) => (
  await assignCurrentRoom(agent || currentAgent.value || undefined),
  props.roomId && goToRoom(props.roomId)
)
const onUnassignAgent = async () => (
  await unassignCurrentRoom(), props.roomId && goToRoom(props.roomId)
)
const onUnassignRoom = async () => {
  await unassignCurrentRoom()
  router.push({ name: RoutesNames.RoomsMe })
}

const onAcceptAssignment = async () => (
  await acceptCurrentRoomAssignment(), props.roomId && goToRoom(props.roomId)
)
const onRefuseAssignment = async () => (
  await refuseCurrentRoomAssignment(), props.roomId && goToRoom(props.roomId)
)
const onReOpenRoom = async () => (
  await reOpenRoom(), props.roomId && goToRoom(props.roomId)
)
const onCloseRoom = () => {
  closeRoom()
  // NOTE: reset panel in order to see the MedicalReport
  nextPanel(PanelsComponentNames.KidProfile)
}
const onToggleMarkAsFavorite = () => toggleCurrentRoomAsFavorite()
const onSnoozeRoom = (snoozedUntil: Date) => snoozeRoom(snoozedUntil)

// While
watch(
  () => props.roomId,
  async (newRoomId) => {
    if (!newRoomId) return
    // Get room data only when roomId has changed
    setCurrentRoom(newRoomId)
  },
  { immediate: true },
)

// After
onBeforeUnmount(() => resetCurrentRoom())
</script>

<template>
  <MiddleContainer>
    <CoreChat
      v-if="
        roomId &&
        messages &&
        messages.length > 0 &&
        currentRoom &&
        currentAgent &&
        agents
      "
      :panel-position="panelPosition"
      :messages="messages"
      :room="currentRoom"
      :current-agent="currentAgent"
      :assignable-agents="assignableAgents"
      :agents="agents"
      @select-agent="onAssignAgent"
      @clear-agent="onUnassignAgent"
      @accept-assignment="onAcceptAssignment"
      @refuse-assignment="onRefuseAssignment"
      @toggle-mark-as-favorite="onToggleMarkAsFavorite"
      @close-room="onCloseRoom"
      @unassign-room="onUnassignRoom"
      @snooze-room="onSnoozeRoom"
      @re-open-room="onReOpenRoom"
    />
    <Loader v-else />
  </MiddleContainer>
</template>
