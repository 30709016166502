import { KidGender } from 'Models/kid'
import i18n from '@/plugins/vue-use/i18n'

export enum Keywords {
  UserFirstName = 'userFirstName',
  KidFirstName = 'kidFirstName',
  KidGenderPronoun = 'kidGenderPronoun',
  KidGenderPronounObject = 'kidGenderPronounObject',
  KidGenderArticle = 'kidGenderArticle',
  KidGenderAgreement = 'kidGenderAgreement',
}

export function keywordConverter(
  savedReply: string,
  parentFirstName: string,
  kidFirstName: string,
  kidSex: KidGender,
): string {
  let newSavedReply = savedReply

  const regexp = /{([^{]+)}/g
  const keywordsFound = savedReply.match(regexp)

  if (keywordsFound) {
    keywordsFound.forEach((keyword) => {
      switch (keyword) {
        case `{${Keywords.UserFirstName}}`:
          newSavedReply = newSavedReply.replace(keyword, parentFirstName)
          break
        case `{${Keywords.KidFirstName}}`:
          newSavedReply = newSavedReply.replace(keyword, kidFirstName)
          break
        case `{${Keywords.KidGenderPronoun}}`:
          newSavedReply = newSavedReply.replace(
            keyword,
            i18n.global.t(`gender.pronoun.${kidSex}`),
          )
          break
        case `{${Keywords.KidGenderPronounObject}}`:
          newSavedReply = newSavedReply.replace(
            keyword,
            i18n.global.t(`gender.pronounObject.${kidSex}`),
          )
          break
        case `{${Keywords.KidGenderArticle}}`:
          newSavedReply = newSavedReply.replace(
            keyword,
            i18n.global.t(`gender.article.${kidSex}`),
          )
          break
        case `{${Keywords.KidGenderAgreement}}`:
          newSavedReply = newSavedReply.replace(
            keyword,
            kidSex === 'female' ? 'e' : '',
          )
          break
      }
    })
  }

  return newSavedReply
}
