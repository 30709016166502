import { Agent } from 'Models/agent'
import { AgentGroup, BuiltInAgentGroups } from 'Models/agent-group'

export default class BasePolicy {
  agent: Agent
  allAgentGroups: AgentGroup[]

  constructor(agent: Agent, allAgentGroups: AgentGroup[] | undefined) {
    this.agent = agent
    this.allAgentGroups = allAgentGroups || []
  }

  useNewFeatures(): boolean {
    return this.isAdmin() || this.isMemberOf(BuiltInAgentGroups.BetaTesters)
  }

  protected isAdmin(agent?: Agent): boolean {
    return this.isSimpleMemberOf(
      BuiltInAgentGroups.SystemAdministrators,
      agent || this.agent,
    )
  }

  protected isMemberOf(groupName: BuiltInAgentGroups, agent?: Agent): boolean {
    const group = this.getGroupByName(groupName)
    if (!group) return false
    if (group.addSystemAdministrators && this.isAdmin(agent)) {
      return true
    } else return this.isSimpleMemberOf(groupName, agent)
  }

  protected isSimpleMemberOf(
    groupName: BuiltInAgentGroups,
    agent?: Agent,
  ): boolean {
    const group = this.getGroupByName(groupName)
    if (!group) return false
    return group.agentIds.indexOf((agent || this.agent).id) !== -1
  }

  protected getGroupByName(
    groupName: BuiltInAgentGroups,
  ): AgentGroup | undefined {
    return this.allAgentGroups.find((group) => group.name === groupName)
  }
}
