<script setup lang="ts">
import { computed, ref, watch, PropType } from 'vue'
import { MentionSuggestionItem } from '@/lib/tiptap/extensions/mention-suggestion'

import BaseAvatar from '@/components/base/BaseAvatar.vue'

const props = defineProps({
  items: {
    type: Object as PropType<MentionSuggestionItem[]>,
    required: false,
  },
  command: {
    type: Function,
    required: true,
  },
})

const selectedIndex = ref<number>(0)

const safeItems = computed(() => props.items || [])

const onKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'ArrowUp') {
    upHandler()
    return true
  }

  if (event.key === 'ArrowDown') {
    downHandler()
    return true
  }

  if (event.key === 'Enter') {
    enterHandler()
    return true
  }

  return false
}

const upHandler = () => {
  selectedIndex.value =
    (selectedIndex.value + safeItems.value.length - 1) % safeItems.value.length
}

const downHandler = () => {
  selectedIndex.value = (selectedIndex.value + 1) % safeItems.value.length
}

const enterHandler = () => {
  selectItem(selectedIndex.value)
}

const selectItem = (index: number) => {
  const item = safeItems.value[index]

  if (item) {
    props.command({ id: item.id, label: item.fullName })
  }
}

watch(
  () => props.items,
  () => (selectedIndex.value = 0),
)

defineExpose({ onKeyDown })
</script>

<template>
  <div
    class="flex flex-col w-full border rounded shadow space-y-1 bg-white z-10 overflow-hidden"
  >
    <template v-if="items?.length">
      <button
        v-for="(item, index) in items"
        :key="index"
        class="px-2 py-1 flex items-start space-x-4"
        :class="{
          'bg-purple-500 text-white': index === selectedIndex,
          'pt-2': index === 0,
        }"
        @click="selectItem(index)"
        @mouseover="selectedIndex = index"
      >
        <BaseAvatar
          type="agent"
          :profile-pic="item.profilePic"
          :size="2"
          :working-dot-enabled="true"
          :is-working="item.active"
          :working-dot-class="{
            'border-purple-500': index === selectedIndex,
            'border-white': index !== selectedIndex,
          }"
        />
        <div class="-space-y-0.5 text-left">
          <p class="text-sm">{{ item.fullName }}</p>
          <p
            class="text-xxs"
            :class="{
              'text-white': index === selectedIndex,
              'text-default-400': index !== selectedIndex,
            }"
          >
            {{ item.title }}
          </p>
        </div>
      </button>
    </template>
    <div v-else class="px-2 py-1 text-center">
      {{ $t('richTextEditor.mention.noResults') }}
    </div>
  </div>
</template>

<style scoped>
.items {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;
}

.item.is-selected {
  border-color: #000;
}
</style>
