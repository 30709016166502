<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { KidParent } from 'Models/kid'

defineProps({
  kidParent: {
    type: Object as PropType<KidParent>,
    required: true,
  },
  roomId: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (
    e: 'goToKidParentRoomList',
    roomId: string,
    targetBlank: false,
    parendId: string,
  ): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <div>
    {{ t('parent') }}
    <span
      class="text-primary cursor-pointer text-sm"
      @click="emits('goToKidParentRoomList', roomId, false, kidParent.id)"
    >
      {{ $fullname(kidParent) }}
    </span>
  </div>
</template>
