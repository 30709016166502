import { ActionTree } from './types'

export const refreshCurrentAgent: ActionTree['refreshCurrentAgent'] =
  function () {
    const currentAgent = this.currentAgent
    if (!currentAgent || !this.agents) return

    const newAgent = this.agents.find((agent) => agent.id === currentAgent.id)

    if (!!newAgent) this.currentAgent = newAgent
  }
