import { ActionTree } from './types'
import { useAgentStore } from 'Store/stores'
import Services from 'Services/services'

export const setClosedRooms: ActionTree['setClosedRooms'] = async function (
  roomId,
) {
  const day = this.closedRoomsDate

  if (!roomId && !day) {
    // case when we look at the closed rooms without a room
    this.setClosedRoomsDate(new Date())
    return
  }

  if (!useAgentStore().currentAgent || !day) return

  const rooms = await Services.getInstance().room.loadAllClosed(day)

  if (rooms) this.closedRooms = rooms
}
