import {
  UIConfig,
  UIDeskConfig,
  UIDynamicConfig,
} from '@/domains/config/ui-models'
import { GetterTree } from './types'

export const getConfig: GetterTree['getConfig'] = function ({ dynamic, desk }) {
  if (!dynamic || !desk) return null

  const uiDynamicConfig: UIDynamicConfig = {
    drowning: dynamic?.drowning,
    almostDrowning: dynamic?.almostDrowning,
    closing: dynamic?.closing,
    closed: !dynamic?.open,
    closedToday: dynamic?.closedToday,
  }

  const uiDeskConfig: UIDeskConfig = {
    version: desk?.version,
    supervisorAgent: desk?.supervisorAgent,
    semversion: desk?.semversion,
  }

  const uiConfig: UIConfig = {
    dynamic: uiDynamicConfig,
    desk: uiDeskConfig,
  }

  return uiConfig
}
