<script lang="ts" setup>
import { PropType, watch, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usePanelStore } from 'Store/stores'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import MediaViewerComponent from '@/components/panel/side/left/MediaViewer.vue'
import useManageMedia from '@/hooks/manage-media'
import { PanelPosition } from '@/domains/panel/store/types'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
})

const router = useRouter()
const route = useRoute()
const { prevPanel } = usePanelStore()

const {
  setMediaId,
  media,
  isCurrentMediaTheFirstOne,
  isCurrentMediaTheLastOne,
  onChangeMedia,
} = useManageMedia()

const onClose = () => (prevPanel(props.panelPosition), clearMediaQuery())

const clearMediaQuery = () => router.push({ query: { media: undefined } })

watch(
  () => route.query.media,
  (newMedia) => {
    if (!newMedia?.toString()) return
    setMediaId(newMedia.toString())
  },
  { immediate: true },
)

onUnmounted(() => clearMediaQuery())
</script>

<template>
  <Container v-keydown:Escape="onClose" :panel-position="panelPosition">
    <MediaViewerComponent
      v-if="media"
      :panel-position="panelPosition"
      :media="media"
      :is-current-media-the-first-one="isCurrentMediaTheFirstOne"
      :is-current-media-the-last-one="isCurrentMediaTheLastOne"
      @change-media="onChangeMedia"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
