import { instance as honeybadger } from '@/plugins/vue-use/honeybadger'
import { useUIStore } from 'Store/stores'

export const fail = (error: unknown, message: string): void => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  honeybadger?.notify(error as any)
  /* eslint-enable @typescript-eslint/no-explicit-any */

  useUIStore().setAuthMessage(message)
  useUIStore().setIsLoading(false)
}
