import { isEmpty } from '@/utils/is-empty'
import {
  DoctorTitleKey,
  FBAgent,
  Agent,
  TitleKey,
  DEFAULT_PROFILE_PIC_URL,
} from 'Models/agent'

export const convertFrom = (fbAgent: FBAgent): Agent => ({
  id: fbAgent.id,
  firstName: fbAgent.firstName,
  lastName: fbAgent.lastName,
  email: fbAgent.email,
  isAccountDisabled: fbAgent.isDisabled ?? false,
  titleKey: fbAgent.titleKey,
  gender: fbAgent.gender,
  profilePic: fbAgent.profilePic || DEFAULT_PROFILE_PIC_URL,
  rpps: fbAgent.rpps,
  adeli: fbAgent.adeli,
  locale: fbAgent.locale,
  bio: { ...fbAgent.bio },
  title: fbAgent.title,
  // a few business rules:
  // - only doctors with a ADELI number can generate prescriptions (and a professsional address)
  isDoctor: isDoctor(fbAgent.titleKey),
  isRegisteredDoctor:
    isDoctor(fbAgent.titleKey) &&
    !isEmpty(fbAgent.rpps) &&
    !isEmpty(fbAgent.adeli) &&
    !isEmpty(fbAgent.professionalAddress) &&
    !isEmpty(fbAgent.professionalPhone),
  isWorking: false,
  numberOfRooms: 0,
})

const isDoctor = (titleKey: TitleKey) =>
  Object.values(DoctorTitleKey).includes(titleKey as DoctorTitleKey)
