import { GetterTree } from './types'
import { useAgentStore, useRoomStore } from 'Store/stores'
import { Room, RoomKid, RoomStatuses } from 'Models/room'
import { UIKidParentTab } from '../../ui-models'
import { groupRoomsBy } from '@/utils/group-rooms-by'
import { buildUIRooms } from '@/domains/room-list/store/concerns/build-ui-room'
import Services from 'Services/services'

export const getCurrentKidParentRoomsTabs: GetterTree['getCurrentKidParentRoomsTabs'] =
  function () {
    const currentAgent = useAgentStore().currentAgent
    const currentKidParentRooms = useRoomStore().currentKidParentRooms

    if (!currentKidParentRooms || !currentAgent) return []

    const kids: RoomKid[] = []
    const kidIds: string[] = []
    for (const room of currentKidParentRooms) {
      const { kid } = room
      const kidId = kid.id
      if (!kidIds.includes(kidId)) {
        kids.push(room.kid)
        kidIds.push(kidId)
      }
    }

    const buildTab = (name: RoomStatuses, rooms: Room[]): UIKidParentTab => ({
      name,
      counter: rooms?.length ?? 0,
      kids,
      groups: groupRoomsBy<RoomKid>(
        'kid',
        kids,
        buildUIRooms(rooms, currentAgent.id) ?? [],
      ),
    })

    const openRooms = buildTab(
      RoomStatuses.Open,
      currentKidParentRooms?.filter(
        (room) => room.status.type === RoomStatuses.Open,
      ),
    )
    const closedRooms = buildTab(
      RoomStatuses.Closed,
      currentKidParentRooms?.filter(
        (room) => room.status.type === RoomStatuses.Closed,
      ),
    )
    const awakeRooms = buildTab(
      RoomStatuses.Awake,
      Services.getInstance().room.filterSnoozed(currentKidParentRooms),
    )
    const asleepRooms = buildTab(
      RoomStatuses.Asleep,
      Services.getInstance().room.filterSnoozed(currentKidParentRooms, false),
    )

    return [openRooms, closedRooms, awakeRooms, asleepRooms]
  }
