import { Point } from 'highcharts'

export enum MeasureType {
  Height = 'height',
  Weight = 'weight',
  Head = 'head',
}

export type GrowthPoint = Point & {
  date?: string
  kidAge: string
  formattedValue: string
}

export type GrowthSubDataset = Record<string, { x: number[]; y: number[] }>
export type GrowthTransformValue = (value: number) => number
