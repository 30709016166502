import { computed, ComputedRef, Ref, ref } from 'vue'
import { Agent } from 'Models/agent'
import { PlanningAgentsTypes } from 'Models/planning'
import { getMatches } from '@/utils/matches'
import { UIPlanningSlot } from '@/domains/planning/ui-models'

export default function queryAgents(
  planningSlot: Ref<UIPlanningSlot>,
  agentsType: Ref<PlanningAgentsTypes>,
  emits: {
    (e: 'closeAgentsList'): void
    (e: 'selectAgents', agentsType: PlanningAgentsTypes): void
  },
): {
  query: Ref<string>
  agents: ComputedRef<Agent[]>
  onClose: () => void
  onSelectAgents: (agentsType: PlanningAgentsTypes) => void
} {
  const query = ref('')

  const agents = computed(() => {
    return getMatches<Agent>(
      {
        items: planningSlot.value[agentsType.value],
        fields: ['firstName', 'lastName'],
      },
      query.value,
    )
  })

  const onClose = () => emits('closeAgentsList')
  const onSelectAgents = (agentsType: PlanningAgentsTypes) =>
    emits('selectAgents', agentsType)

  return {
    query,
    agents,
    onClose,
    onSelectAgents,
  }
}
