import { modifiedRooms } from './modified-rooms'
import { removeDuplicateRooms } from './remove-duplicate-rooms'
import { addRooms } from './add-rooms'
import { deproxifyArrayOfObjects } from '@/utils/array/deproxify-array-of-objects'
import { ListenerChangeType } from 'External/firebase/types'
import { Room } from 'Models/room'
import { UIRoom } from '../../../ui-models'
import { buildUIRooms } from '../../concerns/build-ui-room'
import { isEmpty } from '@/utils/is-empty'

export const processNewRooms = (
  newRooms: Room[],
  existingRooms: UIRoom[] | null,
  changeType: ListenerChangeType,
  currentAgentId: string,
): UIRoom[] | null => {
  const newUIRooms = buildUIRooms(newRooms, currentAgentId)

  if (!existingRooms || isEmpty(existingRooms)) return newUIRooms

  // Required
  existingRooms = deproxifyArrayOfObjects(existingRooms)

  switch (changeType) {
    case 'added':
      return addRooms(existingRooms, newUIRooms)
    case 'modified':
      const rooms = modifiedRooms(newUIRooms, existingRooms, currentAgentId)

      // Nothing to update
      if (!rooms) return null

      return rooms
    case 'removed':
      return removeDuplicateRooms(existingRooms, newUIRooms)
  }
}
