import { IAPIService } from 'External/api/types'
import { convertProblematicFromAPI } from 'Models/converters/kid-parent-converter'
import { KidParentProblematic } from 'Models/kid'
import { IKidParentService } from './__types__'

export default class KidParentService implements IKidParentService {
  // dependencies
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    this.apiService = apiService
  }

  async loadProblematic(parentId: string): Promise<KidParentProblematic> {
    const response = await this.apiService.getKidParentProblematic(parentId)

    return convertProblematicFromAPI(response)
  }
}
