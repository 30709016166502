import { GetterTree } from './types'
import { getFilter } from './get-filter'
import { getFilterParams } from './get-filter-params'
import { getAssignedRooms } from './get-assigned-rooms'
import { getRoomListMe } from './get-room-list-me'
import { getRoomListTeam } from './get-room-list-team'
import { getRoomListClosed } from './get-room-list-closed'
import { getRoomListFavorites } from './get-room-list-favorites'
import { getRoomListAwake } from './get-room-list-awake'
import { getRoomListAsleep } from './get-room-list-asleep'
import { getRoomListAssigned } from './get-room-list-assigned'
import { getRoomListUnassigned } from './get-room-list-unassigned'

export const getters: GetterTree = {
  getFilter,
  getFilterParams,
  getAssignedRooms,
  getRoomListAssigned,
  getRoomListUnassigned,
  getRoomListAwake,
  getRoomListAsleep,
  getRoomListMe,
  getRoomListTeam,
  getRoomListClosed,
  getRoomListFavorites,
}
