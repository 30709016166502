import i18n from '@/plugins/vue-use/i18n'

export const listItems = <ET, VT>(
  enumeration: ET,
  scope: string,
  removedValue?: VT,
): Array<{ key: string; value: VT; label: string }> =>
  Object.entries(enumeration)
    .filter(
      (keyValue) => keyValue[1] !== removedValue && isNaN(Number(keyValue[0])),
    )
    .map(([key, value]) => ({
      key,
      value,
      label: i18n.global.t(`enums.${scope}.${key}`),
    }))
