<script lang="ts" setup>
import { computed, PropType, Ref, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import {
  useAgentStore,
  useKidStore,
  useMedicalReportStore,
  useRoomStore,
} from 'Store/stores'
import { Agent } from 'Models/agent'
import { UIMedicalReport, UIMRLItem } from '@/domains/medical-report/ui-models'
import { MRLStrictTopics } from 'Models/medical-report-library-item'
import { debounce } from 'throttle-debounce'
import { MedicalReportChanges } from 'Models/medical-report'
import useOpenRoom from '@/hooks/open-room'

import AssignedAgent from './AssignedAgent.vue'
import MedicalReportAgentPicker from './MedicalReportAgentPicker.vue'
import PrescriptionDetails from './PrescriptionDetails.vue'
import GroupedCollection from './GroupedCollection.vue'
import Collection from './Collection.vue'
import AddInstructions from './AddInstructions.vue'
import AddMedicalOpinionRequested from './AddMedicalOpinionRequested.vue'
import AddEstimatedTimeSpent from './AddEstimatedTimeSpent.vue'
import AddEstimatedComplexity from './AddEstimatedComplexity.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'
import KidParent from './KidParent.vue'
import RoomComments from '@/domains/room/views/RoomComments.vue'

const props = defineProps({
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
})

const agentStore = useAgentStore()
const { currentKidParent } = storeToRefs(useKidStore())
const { currentRoom } = storeToRefs(useRoomStore())
const { changeCurrentMedicalReport, removeCurrentMedicalReportLibraryItem } =
  useMedicalReportStore()
const { goToRoom } = useOpenRoom()

const isInstructionsSaved = ref(true)

const currentAgent = computed(() => agentStore.currentAgent)
const medicalReportAssignableAgents = computed(
  () => agentStore.getMedicalReportAssignableAgents,
)
const roomStatusUpdatedAt = computed(() => currentRoom.value?.status.updatedAt)

let debouncedUpdate: debounce<
  (changes: MedicalReportChanges, isSaved: Ref<boolean>) => void
> | null = null

const onSelectAgent = (agent: Agent) => update({ agent })
const update = (changes: MedicalReportChanges) =>
  changeCurrentMedicalReport(changes)
const onUpdateInstructions = (instructions: string) => {
  isInstructionsSaved.value = false
  if (debouncedUpdate) debouncedUpdate({ instructions }, isInstructionsSaved)
}
const onRemove = (topic: MRLStrictTopics, item: UIMRLItem) =>
  removeCurrentMedicalReportLibraryItem(topic, item.id)

watch(
  () => props.medicalReport.id,
  () => {
    isInstructionsSaved.value = true
    if (debouncedUpdate) debouncedUpdate.cancel()
    debouncedUpdate = debounce(
      250,
      (changes: MedicalReportChanges, isSaved: Ref<boolean>) => {
        update(changes)
        isSaved.value = true
      },
    )
  },
  { immediate: true },
)
</script>

<template>
  <main class="flex flex-col space-y-1 pt-3">
    <AssignedAgent
      v-if="medicalReport"
      :is-history="false"
      :medical-report="medicalReport"
      class="px-5"
    />
    <MedicalReportAgentPicker
      v-if="currentAgent && $canAssignMedicalReport()"
      class="px-5"
      :medical-report="medicalReport"
      :medical-report-assignable-agents="medicalReportAssignableAgents"
      :current-agent="currentAgent"
      @select-agent="onSelectAgent"
    />

    <KidParent
      v-if="currentKidParent"
      class="px-5 pb-2"
      :kid-parent="currentKidParent"
      :room-id="medicalReport.roomId"
      @go-to-kid-parent-room-list="goToRoom"
    />
    <PrescriptionDetails
      v-if="medicalReport.hasPrescription && medicalReport.prescription"
      class="mx-5"
      :room-id="medicalReport.roomId"
      :prescription="medicalReport.prescription"
    />
    <GroupedCollection
      :topic="MRLStrictTopics.Causes"
      :categories="medicalReport.groupedCauses"
      @remove="onRemove"
    />
    <GroupedCollection
      :topic="MRLStrictTopics.Diagnosis"
      :categories="medicalReport.groupedDiagnosis"
      @remove="onRemove"
    />
    <Collection
      :topic="MRLStrictTopics.Directions"
      :items="medicalReport.directions"
      :single="true"
      @remove="onRemove"
    />
    <Collection
      :topic="MRLStrictTopics.Supervisions"
      :items="medicalReport.supervisions"
      @remove="onRemove"
    />
    <AddInstructions
      :medical-report="medicalReport"
      :is-instruction-saved="isInstructionsSaved"
      @update="onUpdateInstructions"
    />
    <RoomComments
      :key="medicalReport.roomId"
      :current-room-id="medicalReport.roomId"
      :room-status-updated-at="roomStatusUpdatedAt"
      :assigned-agent-id="medicalReport.agent?.id"
    />

    <template v-if="currentAgent && !currentAgent.isDoctor">
      <AddMedicalOpinionRequested
        :medical-opinion-requested="medicalReport.medicalOpinionRequested"
        :requested-doctor="medicalReport.requestedDoctor"
        :current-agent="currentAgent"
        @select-medical-opinion-requested="
          (medicalOpinionRequested) => update({ medicalOpinionRequested })
        "
        @select-agent="(requestedDoctor) => update({ requestedDoctor })"
      />
      <Spacer />
    </template>

    <!-- NOTE: These fields are temporarily hidden until we know which data we really need -->
    <div class="hidden">
      <AddEstimatedTimeSpent
        :estimated-time-spent="medicalReport.estimatedTimeSpent"
        @select-estimated-time-spent="
          (estimatedTimeSpent) => update({ estimatedTimeSpent })
        "
      />
      <AddEstimatedComplexity
        :estimated-complexity="medicalReport.estimatedComplexity"
        @select-estimated-complexity="
          (estimatedComplexity) => update({ estimatedComplexity })
        "
      />
      <Spacer />
    </div>
  </main>
</template>
