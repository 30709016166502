import {
  LeftPanelComponentNames,
  RightPanelComponentNames,
  PanelPosition,
  TPanelsComponentNames,
} from '@/domains/panel/store/types'

export function getPanelPosition(
  componentName: TPanelsComponentNames,
): PanelPosition {
  if (componentName in LeftPanelComponentNames) {
    return PanelPosition.Left
  }
  if (componentName in RightPanelComponentNames) {
    return PanelPosition.Right
  }
  return PanelPosition.Middle
}
