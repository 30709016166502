import { IAPIServiceOtherInformation } from 'External/api/types'
import {
  TrackAgentActionNames,
  TrackAgentResourceTypes,
} from 'Models/track-agent'
import BaseTrackAgentService from './base-track-agent'
import { ITrackAgentService } from './__types__'

export default class LocalTrackAgentService
  extends BaseTrackAgentService
  implements ITrackAgentService
{
  do(
    name: TrackAgentActionNames,
    resourceType?: TrackAgentResourceTypes,
    resourceId?: string,
    otherInformation?: IAPIServiceOtherInformation,
  ): Promise<void> {
    return this.callOnce(
      { name, resourceType, resourceId, otherInformation },
      () => {
        return new Promise(() => {
          console.log(
            '[TrackAgent]',
            name,
            resourceType,
            resourceId,
            otherInformation,
          )
        })
      },
    )
  }
}
