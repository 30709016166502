<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { MessageSenderType } from 'Models/message'
import { UIRoomComment } from '@/domains/room/ui-models'

import CoreChatMessageHeader from '@/domains/core-chat/components/CoreChatMessageHeader.vue'
import BaseMessageText from '@/domains/core-chat/components/base/BaseMessageText.vue'

defineProps({
  comments: {
    type: Array as PropType<UIRoomComment[]>,
    required: true,
  },
  isEditable: {
    type: Boolean,
    required: true,
  },
  isLastCommentHidden: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'allowCommentEditing'): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <div class="space-y-2 pb-3">
    <CoreChatMessageHeader
      v-for="({ id, agent, commentedAt, comment }, index) in comments"
      :key="id"
      :agent="agent"
      :sender="{ ...agent, type: MessageSenderType.Agent }"
      :posted-at="commentedAt"
      :class="{
        hidden: isLastCommentHidden && index === comments.length - 1,
      }"
    >
      <BaseMessageText :text="comment" />
      <button
        v-if="isEditable && index === comments.length - 1"
        class="text-primary mt-1 rounded-lg text-sm px-3 focus:outline-none focus:shadow-outline"
        @click="emits('allowCommentEditing')"
      >
        <font-awesome-icon
          :icon="['fal', 'user-edit']"
          class="focus:outline-none"
        />&nbsp;{{ t('edit') }}
      </button>
    </CoreChatMessageHeader>
  </div>
</template>
