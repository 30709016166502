import { Store } from 'pinia'
import { PanelStoreActions, PanelData } from './actions/types'
import { GetterTree } from './getters/types'

export interface State {
  left: Panel
  middle: Panel
  right: Panel
}

export type PanelStore<G = GetterTree> = Store<
  'panel',
  State,
  G,
  PanelStoreActions
>

//
// Panel Types
//

type Panel = {
  components: TPanelsComponentNames[]
  currentComponentData: PanelData | undefined
}

export enum PanelPosition {
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
}

export enum LeftPanelComponentNames {
  RoomList = 'RoomList',
  Prescription = 'Prescription',
  AddBrandedDrug = 'AddBrandedDrug',
  AddDosage = 'AddDosage',
  AddDuration = 'AddDuration',
  MediaViewer = 'MediaViewer',
  LeftError = 'LeftError',
  KidParentRoomList = 'KidParentRoomList',
  PrescriptionList = 'PrescriptionList',
}

export enum MiddlePanelComponentNames {
  Welcome = 'Welcome',
  Chat = 'Chat',
  Test = 'Test',
  PrescriptionDetail = 'PrescriptionDetail',
}

export enum RightPanelComponentNames {
  KidProfile = 'KidProfile',
  MedicalReportsHistory = 'MedicalReportsHistory',
  AgentProfile = 'AgentProfile',
  RightError = 'RightError',
}

export type TPanelsComponentNames =
  | LeftPanelComponentNames
  | MiddlePanelComponentNames
  | RightPanelComponentNames

// NOTE: use this object as argument when using setPanel function (e.g.: setPanel(PanelsComponentNames.RoomList))
export const PanelsComponentNames = {
  ...LeftPanelComponentNames,
  ...MiddlePanelComponentNames,
  ...RightPanelComponentNames,
}
