<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { Agent, AgentPickable } from 'Models/agent'

import AgentPickerItem from './AgentPickerItem.vue'

const props = defineProps({
  selectedAgent: {
    type: Object as PropType<AgentPickable | null>,
    required: false,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  selectCurrentAgent: {
    type: Boolean,
    default: true,
  },
  numberOfRooms: {
    type: Number,
    required: false,
  },
  showNumberOfRoomAssignedByAgent: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{
  (e: 'selectCurrentAgent'): void
}>()

const canSelectCurrentAgent = computed(
  () =>
    props.currentAgent &&
    props.agents &&
    props.agents.some((agent) => agent.id === props.currentAgent.id) &&
    props.selectCurrentAgent,
)

const amICurrentAgent = computed(
  () => props.selectedAgent?.id === props.currentAgent.id,
)
</script>

<template>
  <div
    v-if="canSelectCurrentAgent"
    :class="amICurrentAgent ? 'bg-green-100' : 'hover:bg-blue-50'"
  >
    <AgentPickerItem
      :agent="currentAgent"
      :counter="showNumberOfRoomAssignedByAgent ? numberOfRooms : 0"
      :selected="amICurrentAgent"
      @click="emits('selectCurrentAgent')"
    />
  </div>
</template>
