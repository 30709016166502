import { ActionTree } from './types'
import { useKidStore } from 'Store/stores'
import Services from 'Services/services'

export const setCurrentKidParentRooms: ActionTree['setCurrentKidParentRooms'] =
  async function () {
    const currentKidParent = useKidStore().currentKidParent

    if (!currentKidParent) return

    Services.getInstance().room.listenAllFromKidParent(
      currentKidParent.id,
      (newRooms) => (this.currentKidParentRooms = newRooms),
    )
  }
