import { Ref, ref } from 'vue'

export default (): UseModal => {
  const isModalOpen = ref(false)

  const openModal = () => (isModalOpen.value = true)
  const closeModal = () => (isModalOpen.value = false)

  return {
    openModal,
    closeModal,
    isModalOpen,
  }
}

export type UseModal = {
  openModal: () => void
  closeModal: () => void
  isModalOpen: Ref<boolean>
}
