import { Agent } from 'Models/agent'
import { Kid } from 'Models/kid'
import { MedicalReport } from 'Models/medical-report'
import { Message } from 'Models/message'
import { Prescription, PrescriptionStatus } from 'Models/prescription'
import { Room } from 'Models/room'
import {
  RoomWorkspace,
  RoomWorkspaceContext,
  RoomWorkspaceEntityName,
  RoomWorkspaceEntityType,
} from 'Models/room-workspace'
import {
  IKidService,
  IMedicalReportService,
  IMessageService,
  IPastMedicalReportService,
  IPrescriptionService,
  IRoomService,
  IRoomWorkspaceService,
} from './__types__'

export default class RoomWorkspaceService implements IRoomWorkspaceService {
  DEFAULT_MAX_WORKSPACES = 20

  roomService: IRoomService
  kidService: IKidService
  messageService: IMessageService
  medicalReportService: IMedicalReportService
  pastMedicalReportService: IPastMedicalReportService
  prescriptionService: IPrescriptionService

  workspaces: RoomWorkspace[]
  listener?: (workspace: RoomWorkspace, name?: RoomWorkspaceEntityName) => void
  maxWorkspaces: number

  constructor(
    roomService: IRoomService,
    kidService: IKidService,
    messageService: IMessageService,
    medicalReportService: IMedicalReportService,
    pastMedicalReportService: IPastMedicalReportService,
    prescriptionService: IPrescriptionService,
    maxWorkspaces?: number,
  ) {
    this.roomService = roomService
    this.kidService = kidService
    this.messageService = messageService
    this.medicalReportService = medicalReportService
    this.pastMedicalReportService = pastMedicalReportService
    this.prescriptionService = prescriptionService
    this.workspaces = []
    this.maxWorkspaces = maxWorkspaces || this.DEFAULT_MAX_WORKSPACES
  }

  /**
   * Register a new workspace (room, kid, medicalReport, messages) dedicated to a room.
   * Behind the scene, it will start listening to the changes of the room, kid, medicalReport and messages of the room.
   * Each change will call the listener attached to the service.
   * If we've got an entry for the roomId, the function will call the listener to the service.
   *
   * @param roomId The ID of the room
   * @returns True if a new workspace has been added, false if a workspace already exists for the roomId
   */
  add(roomId: string, context: RoomWorkspaceContext): boolean {
    let workspace = this.findWorkspace(roomId)

    if (workspace) {
      if (this.listener) this.listener(workspace)
      this.promoteWorkspace(workspace)
      return false
    }

    workspace = this.buildWorkspace(roomId, context)
    this.workspaces.unshift(workspace)

    this.listenRoom(roomId) // <--- all starts from here!

    this.runGarbageCollector()

    return true
  }

  /**
   * Reload non Firebase objects related to a room so that the callback listening to any updates of this room
   * gets the most recent version of those objects.
   *
   * @param roomId The id of the room
   */
  touch(roomId: string, prescription?: Prescription): void {
    const workspace = this.findWorkspace(roomId)
    if (!workspace)
      throw new Error('You must first add the room to the workspace.')

    if (!!prescription) workspace.prescription = prescription

    if (workspace.prescription?.status === PrescriptionStatus.Created)
      this.loadPrescription(workspace.roomId)

    if (workspace.prescription?.status === PrescriptionStatus.Draft)
      this.loadPrescriptionDraft(workspace.roomId)

    return
  }

  /**
   * Clears the workspaces and the listeners attached to them.
   *
   */
  reset(): void {
    this.workspaces.map((workspace) => this.unlistenAll(workspace))
    this.workspaces = []
  }

  /**
   * Register the callback that is called when an entity (room, kid, ...etc) has been modified.
   *
   * @param callback The callback will receive the modified workspace and the kind of entity that has been modified
   */
  setListener(
    callback: (
      workspace: RoomWorkspace,
      name?: RoomWorkspaceEntityName,
    ) => void,
  ): void {
    this.listener = callback
  }

  getStack(): RoomWorkspace[] {
    return this.workspaces
  }

  private buildWorkspace(
    roomId: string,
    context: RoomWorkspaceContext,
  ): RoomWorkspace {
    return {
      roomId,
      context,
      room: null,
      kid: null,
      messages: null,
      medicalReport: null,
      pastMedicalReports: null,
      prescription: null,
    }
  }

  private receiveEntity(
    roomId: string,
    entityName: RoomWorkspaceEntityName,
    entity: RoomWorkspaceEntityType,
  ) {
    const workspace = this.findWorkspace(roomId)
    if (!workspace) return // orphan callback, probably called while we were unlistening the room
    const previousEntity = workspace[entityName]

    this.updateWorkspace(workspace, entityName, entity)

    if (!previousEntity) this.onReceivingFirstEntity(workspace, entityName)
    else this.onReceivingUpdatedEntity(workspace, entityName)

    if (this.listener) this.listener(workspace, entityName)
  }

  private onReceivingFirstEntity(
    workspace: RoomWorkspace,
    entityName: RoomWorkspaceEntityName,
  ) {
    const room = workspace.room

    if (!room) return // without a room, we can't go any further

    switch (entityName) {
      case RoomWorkspaceEntityName.Room:
        this.listenMessages(workspace.roomId)
        this.listenKid(workspace.roomId, room.kid.id)
        this.listenMedicalReport(room)
        this.listenPastMedicalReports(
          room,
          workspace.context.maxPastMedicalReports,
        )
        break
      case RoomWorkspaceEntityName.Kid:
        if (!workspace.kid) return // can't happen, only here to please TS
        this.listenPrescription(
          room,
          workspace.kid,
          workspace.context.currentAgent,
          workspace.context.supervisorAgentId,
        )
        break

      default:
        break
    }
  }

  private onReceivingUpdatedEntity(
    workspace: RoomWorkspace,
    entityName: RoomWorkspaceEntityName,
  ) {
    const room = workspace.room

    if (!room) return // without a room, we can't go any further

    switch (entityName) {
      case RoomWorkspaceEntityName.Room:
        if (room.hasPrescription && !workspace.prescription)
          this.loadPrescription(room.id)
    }
  }

  private listenRoom(roomId: string) {
    this.roomService.listen(roomId, (room: Room) =>
      this.receiveEntity(roomId, RoomWorkspaceEntityName.Room, room),
    )
  }

  private listenMessages(roomId: string) {
    this.messageService.listenAll(roomId, (messages: Message[]) =>
      this.receiveEntity(roomId, RoomWorkspaceEntityName.Messages, messages),
    )
  }

  private listenKid(roomId: string, kidId: string) {
    this.kidService.listen(kidId, (kid: Kid) =>
      this.receiveEntity(roomId, RoomWorkspaceEntityName.Kid, kid),
    )
  }

  private listenMedicalReport(room: Room) {
    this.medicalReportService.listen(
      room,
      ((roomId: string, medicalReport: MedicalReport) =>
        this.receiveEntity(
          roomId,
          RoomWorkspaceEntityName.MedicalReport,
          medicalReport,
        )).bind(this, room.id),
    )
  }

  private listenPastMedicalReports(room: Room, limit: number) {
    this.pastMedicalReportService
      .loadAll(room.kid.id, limit, room.id)
      .then((medicalReports) =>
        this.receiveEntity(
          room.id,
          RoomWorkspaceEntityName.PastMedicalReports,
          medicalReports || null,
        ),
      )
  }

  private listenPrescription(
    room: Room,
    kid: Kid,
    agent: Agent,
    supervisorAgentId: string,
  ) {
    this.prescriptionService
      .loadOrBuildFromRoom(room, kid, agent, supervisorAgentId)
      .then((prescription) =>
        this.receiveEntity(
          room.id,
          RoomWorkspaceEntityName.Prescription,
          prescription,
        ),
      )
  }

  private loadPrescription(roomId: string) {
    this.prescriptionService
      .loadFromRoomId(roomId)
      .then((prescription) =>
        this.receiveEntity(
          roomId,
          RoomWorkspaceEntityName.Prescription,
          prescription,
        ),
      )
  }

  private loadPrescriptionDraft(roomId: string) {
    this.prescriptionService
      .loadFromDraft(roomId)
      .then((prescription) =>
        this.receiveEntity(
          roomId,
          RoomWorkspaceEntityName.Prescription,
          prescription,
        ),
      )
  }

  private promoteWorkspace(workspace: RoomWorkspace) {
    this.workspaces = this.workspaces.filter(
      (iterator) => iterator.roomId !== workspace.roomId,
    )
    this.workspaces.unshift(workspace)
  }

  private findWorkspace(roomId: string): RoomWorkspace | undefined {
    return this.workspaces.find((workspace) => workspace.roomId === roomId)
  }

  private updateWorkspace<RoomWorkspace, Key extends keyof RoomWorkspace>(
    workspace: RoomWorkspace,
    key: Key,
    entity: RoomWorkspace[Key],
  ) {
    workspace[key] = entity
  }

  private runGarbageCollector(): void {
    if (this.workspaces.length <= this.maxWorkspaces) return

    const workspace = this.workspaces.pop()

    if (!workspace) return

    this.unlistenAll(workspace)
  }

  private unlistenAll(workspace: RoomWorkspace): void {
    if (workspace.medicalReport)
      this.medicalReportService.unlisten(workspace.medicalReport.id)
    if (workspace.kid) this.kidService.unlisten(workspace.kid.id)
    if (workspace.messages) this.messageService.unlistenAll(workspace.roomId)
    if (workspace.room) this.roomService.unlisten(workspace.roomId)
  }
}
