import { ActionTree } from './types'
import i18n from '@/plugins/vue-use/i18n'

export const changeLocale: ActionTree['changeLocale'] = function () {
  switch (this.locale) {
    case 'fr':
      i18n.global.locale = 'en'
      this.locale = 'en'
      break
    case 'en':
      i18n.global.locale = 'fr'
      this.locale = 'fr'
  }
}
