import { RouteRecordRaw } from 'vue-router'
import { RoutesNames } from './type'

import Main from '@/domains/main/Main.vue'

// Shared with all routes
const defaultProps = {
  component: Main,
  props: true,
  meta: {
    protected: true,
  },
}

export const buildKidParentRoutes = (): RouteRecordRaw[] => [
  {
    path: '/kidparent/:parentId',
    name: RoutesNames.KidParent,
    ...defaultProps,
    children: [
      {
        path: ':roomsStatus',
        name: RoutesNames.KidParentWithRoomStatus,
        ...defaultProps,
        children: [
          {
            path: 'rooms/:roomId',
            name: RoutesNames.KidParentRoomDetail,
            ...defaultProps,
          },
        ],
      },
    ],
  },
]
