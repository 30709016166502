import { ActionTree } from './types'
import { changeCurrentPrescription } from './change-current-prescription'
import { changePrescriptionListFilter } from './change-prescription-list-filter'
import { completeCurrentPrescription } from './complete-current-prescription'
import { generateCurrentPrescriptionPreview } from './generate-current-prescription-preview'
import { initPrescriptionListFilter } from './init-prescription-list-filter'
import { loadCurrentFilteredPrescription } from './load-current-filtered-prescription'
import { resetFilteredPrescriptions } from './reset-filtered-prescriptions'
import { setBrandedDrugs } from './set-branded-drugs'
import { updateCurrentPrescription } from './update-current-prescription'
import { setDrugs } from './set-drugs'
import { loadPrescriptionPreview } from './load-prescription-preview'
import { deletePrescription } from './delete-prescription'

export const actions: ActionTree = {
  changeCurrentPrescription,
  changePrescriptionListFilter,
  completeCurrentPrescription,
  generateCurrentPrescriptionPreview,
  initPrescriptionListFilter,
  loadCurrentFilteredPrescription,
  resetFilteredPrescriptions,
  setBrandedDrugs,
  updateCurrentPrescription,
  setDrugs,
  loadPrescriptionPreview,
  deletePrescription,
}
