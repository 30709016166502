import { GetterTree } from './types'

export const getFilter: GetterTree['getFilter'] = function ({ filter }) {
  if (!filter) return null

  const { agent, date, status } = filter
  return {
    agent: agent || null,
    date: date || null,
    status: status || null,
  }
}
