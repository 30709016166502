<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'
import useModal from '@/hooks/modal'

import BaseButtonIcon from '@/components/base/BaseButtonIcon.vue'
import IllustrationsModal from './IllustrationsModal.vue'

const { t } = useScopedI18n()

const { openModal, closeModal, isModalOpen } = useModal()
</script>

<template>
  <BaseButtonIcon class="ml-2 lg:px-2" @click.stop="openModal">
    <font-awesome-icon
      v-tooltip="t('illustrations')"
      :icon="['fal', 'book']"
      class="focus:outline-none"
      fixed-width
    />
  </BaseButtonIcon>

  <IllustrationsModal v-if="isModalOpen" @close="closeModal" />
</template>
