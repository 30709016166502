<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import { PropType } from 'vue'
import { UIPlanningSlot } from '@/domains/planning/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'

defineProps({
  planningSlot: {
    type: Object as PropType<UIPlanningSlot>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'closeAgentsList'): void
}>()

const { t } = useScopedI18n()

const onClose = () => emits('closeAgentsList')
</script>

<template>
  <header
    class="flex flex-row flex-shrink-0 w-full h-12 bg-white border-b border-default-100"
  >
    <div
      class="flex flex-1 flex-wrap items-center content-center h-full flex-shrink pl-5"
    >
      <Title>
        <span
          v-html="
            t('dutyTeam', {
              startHour: planningSlot.hour,
              endHour: planningSlot.hour + 1,
            })
          "
      /></Title>
    </div>

    <aside class="flex items-center justify-center">
      <!-- right -->
      <button
        v-tooltip.bottom="$t('tooltips.close')"
        class="flex items-center justify-center mx-3 w-8 h-8 focus:outline-none text-primary hover:bg-default-100 rounded"
        @click="onClose"
      >
        <font-awesome-icon
          :icon="['fal', 'times']"
          class="focus:outline-none text-2xl"
        />
      </button>
    </aside>
  </header>
</template>
