<script lang="ts" setup>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  ref,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useKidStore, useRoomStore } from 'Store/stores'
import { RoutesNames } from '@/router/routes/type'
import { RoomStatuses } from 'Models/room'
import useRoomsStatusTab from '@/hooks/rooms-status-tab'
import { PanelPosition } from '@/domains/panel/store/types'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import KidParentRoomListPanel from '@/domains/room-list/components/KidParentRoomList.vue'

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  roomsStatus: {
    type: String as PropType<RoomStatuses>,
    required: false,
  },
  parentId: {
    type: String,
    required: true,
  },
  roomId: {
    type: String,
    required: false,
  },
})

const route = useRoute()
const router = useRouter()
const { tabIndex, roomsStatusFromIndex, setRoomsStatusFromIndex } =
  useRoomsStatusTab(props.roomsStatus)
const kidStore = useKidStore()
const { setCurrentKidParent, resetCurrentKidParent } = useKidStore()
const { setCurrentKidParentRooms, resetCurrentKidParentRooms } = useRoomStore()

const activeTabIndex = ref(tabIndex.value)

const tabs = computed(() => kidStore.getCurrentKidParentRoomsTabs)
const kidParent = computed(() => kidStore.getCurrentKidParent)

// Before
onBeforeMount(async () => {
  if (props.parentId) {
    await setCurrentKidParent(props.parentId)
    setCurrentKidParentRooms()
  }
})

// While
watch(
  () => route.params,
  async (newParams) => {
    if (!props.parentId || props.parentId !== newParams.parentId) {
      await setCurrentKidParent(String(newParams.parentId))
      setCurrentKidParentRooms()
    }
  },
)

watch(activeTabIndex, (newActiveTabIndex) => {
  // Change route.params.roomsStatus according to the selected tab index
  setRoomsStatusFromIndex(newActiveTabIndex)
  router.push({
    name: RoutesNames.KidParentWithRoomStatus,
    params: {
      roomsStatus: roomsStatusFromIndex.value,
    },
  })
})

// After
onBeforeUnmount(() => (resetCurrentKidParent(), resetCurrentKidParentRooms()))
</script>

<template>
  <Container :panel-position="panelPosition">
    <KidParentRoomListPanel
      v-if="kidParent && tabs"
      v-model:active-tab-index="activeTabIndex"
      :panel-position="panelPosition"
      :tabs="tabs"
      :parent="kidParent"
      :room-id="roomId"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
