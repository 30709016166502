<script lang="ts" setup>
import { PropType, computed, ref } from 'vue'
import { Agent, AgentPickable } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'
import { getMatches } from '@/utils/matches'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import AgentPickerList from '../components/agent-picker/AgentPickerList.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import BaseSearch from '@/components/base/BaseSearch.vue'
import AgentsByPresence from '../components/agent-picker/AgentsByPresence.vue'
import AgentsByTitle from '../components/agent-picker/AgentsByTitle.vue'
import ClearButton from '../components/agent-picker/ClearButton.vue'
import SelectedAgent from '../components/agent-picker/SelectedAgent.vue'
import CurrentAgent from '../components/agent-picker/CurrentAgent.vue'

const props = defineProps({
  selectedAgent: {
    type: Object as PropType<AgentPickable | null>,
    required: false,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  emptyLabel: {
    type: String,
    required: false,
    default: 'emptyLabel',
  },
  splitByPresence: {
    type: Boolean,
    required: false,
    default: true,
  },
  splitByTitle: {
    type: Boolean,
    required: false,
    default: false,
  },
  allowClear: {
    type: Boolean,
    required: false,
    default: true,
  },
  clearLabel: {
    type: String,
    required: false,
    default: 'clearLabel',
  },
  menuAlign: {
    type: String as PropType<'left' | 'right'>,
    default: 'right',
  },
  selectCurrentAgent: {
    type: Boolean,
    default: true,
  },
  reducedHeight: {
    type: Boolean,
    default: false,
  },
  showCounters: {
    type: Boolean,
    default: true,
  },
  showNumberOfRoomAssignedByAgent: {
    type: Boolean,
    default: true,
  },
  buttonCustomClass: {
    type: String,
    default: '',
  },
  containerClass: {
    type: String,
    default: '',
  },
  offsetDistance: {
    type: Number,
    default: 8,
  },
  placement: {
    type: String as PropType<DropdownPlacement>,
    default: DropdownPlacement.Bottom,
  },
})

const emits = defineEmits<{
  (e: 'selectAgent', agent: Agent): void
  (e: 'clearAgent'): void
}>()

const { t } = useScopedI18n()

const query = ref<string>('')

const queriedAgents = computed(() =>
  getMatches<Agent>(
    { items: props.agents, fields: ['firstName', 'lastName'] },
    query.value,
  ),
)

const agentList = computed(() =>
  isQuerying.value ? queriedAgents.value : props.agents,
)

const isQuerying = computed(() => query.value.length > 0)

const hasAgents = computed(() => agentList.value.length > 0)

const clearQuery = () => (query.value = '')
const onSelectAgent = (agent: Agent, closePicker: () => void) => (
  clearQuery(), emits('selectAgent', agent), closePicker()
)
const onClearAgent = (closePicker: () => void) => (
  clearQuery(), emits('clearAgent'), closePicker()
)
</script>

<template>
  <BaseDropdown
    :placement="placement"
    :offset-distance="offsetDistance"
    :menu-align="menuAlign"
    :reduced-height="reducedHeight"
    :button-custom-class="buttonCustomClass"
    :container-class="containerClass"
    @close-callback="clearQuery"
  >
    <template #button="{ isOpen }">
      <SelectedAgent
        :is-open="isOpen"
        :selected-agent="selectedAgent"
        :empty-label="emptyLabel"
      />
    </template>

    <template #default="{ close: closePicker }">
      <div class="flex flex-col min-w-max overflow-y-hidden">
        <div class="flex flex-col">
          <CurrentAgent
            :selected-agent="selectedAgent"
            :current-agent="currentAgent"
            :agents="agents"
            :number-of-rooms="currentAgent.numberOfRooms"
            :show-number-of-room-assigned-by-agent="
              showNumberOfRoomAssignedByAgent
            "
            :select-current-agent="selectCurrentAgent"
            @select-current-agent="onSelectAgent(currentAgent, closePicker)"
          />
          <ClearButton
            v-if="allowClear && selectedAgent"
            :clear-label="clearLabel"
            @clear="onClearAgent(closePicker)"
          />
          <div class="px-4 py-2">
            <BaseSearch v-model:query="query" />
          </div>
        </div>
        <AgentsByTitle
          v-if="splitByTitle && !isQuerying"
          :selected-agent="selectedAgent"
          :agents="agentList"
          @select-agent="(agent) => onSelectAgent(agent, closePicker)"
        />
        <AgentsByPresence
          v-else-if="splitByPresence && !isQuerying"
          :selected-agent="selectedAgent"
          :current-agent="currentAgent"
          :agents="agentList"
          @close-picker="closePicker"
          @clear-query="clearQuery"
          @select-agent="(agent) => onSelectAgent(agent, closePicker)"
        />
        <AgentPickerList
          v-else-if="hasAgents"
          :selected-agent="selectedAgent"
          :agents="agentList"
          :current-agent="currentAgent"
          :show-number-of-room-assigned-by-agent="
            showNumberOfRoomAssignedByAgent
          "
          :query="query"
          @on-select-agent="(agent) => onSelectAgent(agent, closePicker)"
        />
        <div v-else class="px-4 py-2">
          {{ t('noResult') }}
        </div>
      </div>
    </template>
  </BaseDropdown>
</template>
