<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { EstimatedComplexity } from 'Models/medical-report'
import { listItems } from '@/utils/enum/list-items'

import BaseButtonRadioGroup from '@/components/base/BaseButtonRadioGroup.vue'

const props = defineProps({
  estimatedComplexity: {
    type: String as PropType<EstimatedComplexity>,
    required: false,
    default: EstimatedComplexity.Unknown,
  },
})

const emits = defineEmits<{
  (
    e: 'selectEstimatedComplexity',
    estimatedComplexity: EstimatedComplexity,
  ): void
}>()

const { t } = useScopedI18n()

const estimatedComplexityItems = computed(() =>
  listItems<typeof EstimatedComplexity, string>(
    EstimatedComplexity,
    'estimatedComplexity',
    EstimatedComplexity.Unknown,
  ),
)

const initialItem = computed({
  get: () => props.estimatedComplexity,
  set: (newItem) => emits('selectEstimatedComplexity', newItem),
})
</script>

<template>
  <div class="px-5">
    <div class="flex font-semibold">
      {{ t('title') }}
    </div>
    <main class="mt-1 mb-3">
      <BaseButtonRadioGroup
        v-model:initialItem="initialItem"
        :items="estimatedComplexityItems"
      />
    </main>
  </div>
</template>
