<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore, useRoomStore } from 'Store/stores'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { UIMedicalReport } from '@/domains/medical-report/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useRouter, useRoute } from 'vue-router'

import BaseCardTabs from '@/components/base/BaseCardTabs.vue'
import BaseCardTab from '@/components/base/BaseCardTab.vue'
import Warning from '@/domains/medical-report/components/Warning.vue'
import PastMedicalReports from '@/domains/medical-report/components/PastMedicalReports.vue'
import CurrentMedicalReport from './CurrentMedicalReport.vue'
import ChatIcon from '@/domains/inception-chat/components/InceptionChatIcon.vue'
import InceptionChatView from '@/domains/inception-chat/views/InceptionChatView.vue'
import useInceptionChat from '@/domains/inception-chat/hook'

const props = defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
  medicalReport: {
    type: Object as PropType<UIMedicalReport>,
    required: true,
  },
  isRoomJustClosed: {
    type: Boolean,
    required: true,
  },
})

const { t } = useScopedI18n()
const { currentAgent } = storeToRefs(useAgentStore())
const { currentRoom, currentRoomId } = storeToRefs(useRoomStore())
const { hasMessages } = useInceptionChat(computed(() => currentRoomId.value))
const router = useRouter()
const route = useRoute()

const activeTab = ref<string>(
  props.medicalReport.isDisplayed ? 'medical-report' : 'history',
)

const hasWarn = computed(
  () => props.isRoomJustClosed || props.medicalReport.isEmpty,
)

const pushHash = (hash: string) => router.push({ ...route, hash: `#${hash}` })

watch(
  () => props.medicalReport.id,
  (newRoomId, oldRoomId) => {
    const tabName = router.currentRoute.value.hash?.replace('#', '')
    if (['medical-report', 'chat', 'history'].includes(tabName))
      activeTab.value = tabName
    else if (props.medicalReport.isDisplayed) activeTab.value = 'medical-report'
    else activeTab.value = 'history'

    if (!!oldRoomId && newRoomId !== oldRoomId) pushHash(activeTab.value)
  },
  { immediate: true },
)

watch(
  () => activeTab.value,
  (tabName) => pushHash(tabName),
  { immediate: true },
)
</script>

<template>
  <BaseCardTabs
    v-if="currentAgent && currentRoom && currentRoomId"
    v-model="activeTab"
    class="mx-5 mb-5"
    :class="{ 'h-full': activeTab === 'chat' }"
    :body-padding="false"
  >
    <template #title-anchor>
      <Warning v-if="isRoomJustClosed && medicalReport.isDisplayed" />
    </template>

    <BaseCardTab
      name="medical-report"
      :title="t('medicalReport')"
      :highlighted="hasWarn"
      :position="0"
      :hidden="!medicalReport.isDisplayed"
    >
      <CurrentMedicalReport
        v-if="medicalReport && medicalReport.isDisplayed"
        :medical-report="medicalReport"
        :is-room-just-closed="isRoomJustClosed"
      />
    </BaseCardTab>

    <BaseCardTab name="chat" class="h-full" :position="1">
      <template #title>
        <ChatIcon
          :room-id="currentRoomId"
          :agent-id="currentAgent.id"
          :has-messages="hasMessages"
        />
      </template>
      <InceptionChatView
        :key="currentRoom.id"
        :active-tab="activeTab"
        class="h-full"
      />
    </BaseCardTab>

    <BaseCardTab
      :title="t('medicalReportHistory')"
      name="history"
      :position="2"
    >
      <PastMedicalReports :kid-profile="kidProfile" />
    </BaseCardTab>
  </BaseCardTabs>
</template>
