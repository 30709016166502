<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  selectedProtocol: {
    type: String,
  },
  protocolsLoaded: {
    type: Boolean,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'selectProtocol', url: string): void
  (e: 'protocolLoaded', url: string): void
}>()

const isSelectedProtocol = computed(() => props.src === props.selectedProtocol)
</script>

<template>
  <div
    class="p-2 rounded-lg mb-2 cursor-pointer"
    :class="{
      'bg-blue-400': isSelectedProtocol,
      hidden: !protocolsLoaded,
    }"
    @click="emits('selectProtocol', src)"
  >
    <img
      class="rounded-lg h-80"
      :src="src"
      alt=""
      @load="emits('protocolLoaded', src)"
    />
  </div>
</template>
