<script lang="ts" setup>
import { computed, PropType, watch } from 'vue'
import { PanelPosition } from '@/domains/panel/store/types'
import { storeToRefs } from 'pinia'
import { useKidStore, useMedicalReportStore, useRoomStore } from 'Store/stores'

import Loader from '@/components/panel/side/shared/Loader.vue'
import Container from '@/components/panel/side/layout/Container.vue'
import KidProfileComponent from '@/domains/kid/components/kid-profile/KidProfile.vue'

defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Right>,
    required: true,
  },
})

const { isCurrentRoomJustClosed } = storeToRefs(useRoomStore())
const kidStore = useKidStore()
const medicalReportStore = useMedicalReportStore()

const kidProfile = computed(() => kidStore.getCurrentKidProfile)
const medicalReport = computed(() => medicalReportStore.getCurrentMedicalReport)

watch(
  () => kidProfile.value?.id,
  (newKidId) => {
    if (newKidId) kidStore.loadKidAntecedents()
  },
  { immediate: true },
)
</script>

<template>
  <Container :panel-position="panelPosition">
    <KidProfileComponent
      v-if="kidProfile && medicalReport"
      :kid-profile="kidProfile"
      :panel-position="panelPosition"
      :medical-report="medicalReport"
      :is-room-just-closed="isCurrentRoomJustClosed"
    />
    <Loader v-else :panel-position="panelPosition" />
  </Container>
</template>
