import { useRouter } from 'vue-router'
import { RoutesNames } from '@/router/routes/type'
import { useRouteIntoTab } from '@/domains/room-list/controllers/route-into-tab'

export default function useGoToRoomList(): {
  goToRoomList: (
    currentAgentId: string | undefined,
    agentId: string,
    currentRoomId: string | null,
  ) => void
} {
  const router = useRouter()
  const { isTabTeam } = useRouteIntoTab()

  const goToRoomList = (
    currentAgentId: string | undefined,
    agentId: string,
    currentRoomId: string | null,
  ) => {
    let to: To = {
      name: RoutesNames.RoomsMe,
    }

    if (isTabTeam.value && currentRoomId && currentAgentId !== agentId)
      to = {
        ...to,
        name: RoutesNames.RoomsTeamRoomDetail,
        query: { agentId },
        params: { roomId: currentRoomId },
        hash: router.currentRoute.value.hash,
      }
    else if (currentAgentId !== agentId)
      to = {
        ...to,
        name: RoutesNames.RoomsTeam,
        query: { agentId },
        hash: router.currentRoute.value.hash,
      }

    router.push(to)
  }

  return { goToRoomList }
}

type To = {
  name: RoutesNames
  query?: { agentId: string }
  params?: {
    roomId: string
  }
  hash?: string
}
