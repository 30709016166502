<script lang="ts" setup>
import { ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import medicalProtocols from 'Services/__data__/medical-protocals'

import BaseModal from '@/components/base/BaseModal.vue'
import MedicalProtocolsModalBody from './MedicalProtocolsModalBody.vue'

const { t } = useScopedI18n()

const selectedProtocol = ref<string>()

const onSelectProtocol = (url: string) => (selectedProtocol.value = url)
</script>

<template>
  <BaseModal modal-class="h-2/3">
    <template #header>
      <h1 class="font-semibold">{{ t('medicalProtocols') }}</h1>
    </template>

    <MedicalProtocolsModalBody
      v-if="medicalProtocols"
      :protocols="medicalProtocols"
      :selected-protocol="selectedProtocol"
      @select-protocol="onSelectProtocol"
    />
  </BaseModal>
</template>
