import { RoutesNames } from '@/router/routes/type'
import { differenceInDays } from '@/utils/difference-in-days'
import { Room, RoomStatuses } from 'Models/room'
import { useRoomStore } from 'Store/stores'
import { LocationQuery, RouteLocationNormalized } from 'vue-router'

export const getRouteName = (
  from: RouteLocationNormalized,
  agentId: string,
  room: Room,
): RoutesNames => {
  // Exception
  if (
    (from.name === RoutesNames.RoomsFavorites ||
      from.name === RoutesNames.RoomsFavoritesRoomDetail) &&
    room.markedByAgentsAsFavorite.includes(agentId)
  )
    return RoutesNames.RoomsFavoritesRoomDetail

  if (
    from.name === RoutesNames.RoomsInceptionChat ||
    from.name === RoutesNames.RoomsInceptionChatRoomDetail
  )
    return RoutesNames.RoomsInceptionChatRoomDetail

  switch (room.status.type) {
    case RoomStatuses.Open:
      return getRoutingInfoOpenRoom(room, agentId)
    case RoomStatuses.Closed:
      return getRoutingInfoClosedRoom(room.status.updatedAt, from.query)
    case RoomStatuses.Snoozed:
      return RoutesNames.RoomsSnoozedRoomDetail
    default:
      return RoutesNames.RoomsTeamRoomDetail
  }
}

export const getRoutingInfoOpenRoom = (
  room: Room,
  agentId: string,
): RoutesNames => {
  // Unassigned
  if (!room.assignedAgent) return RoutesNames.RoomsUnassignedRoomDetail

  // Team
  if (room.assignedAgent.id !== agentId) return RoutesNames.RoomsTeamRoomDetail

  // Me
  return RoutesNames.RoomsMeRoomDetail
}

// Closed
export const getRoutingInfoClosedRoom = (
  date: Date,
  query: LocationQuery,
): RoutesNames => {
  const roomStore = useRoomStore()

  if (
    (!roomStore.closedRoomsDate ||
      differenceInDays(roomStore.closedRoomsDate, date) !== 0) &&
    !query.date
  ) {
    roomStore.setClosedRoomsDate(date)
  }

  return RoutesNames.RoomsClosedRoomDetail
}
