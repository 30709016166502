import { GetterTree } from './types'
import Services from 'Services/services'
import { UIRoom } from '../../ui-models'
import { groupRoomsBy } from '@/utils/group-rooms-by'

export const getRoomListAsleep: GetterTree['getRoomListAsleep'] = function ({
  rooms,
}) {
  return (agents) => {
    if (!rooms || !agents) return null

    const asleepRooms = Services.getInstance().room.filterSnoozed(
      rooms,
      false,
    ) as UIRoom[]
    if (!asleepRooms) return null

    const roomsByAgent = groupRoomsBy('agent', agents, asleepRooms).sort(
      // Sort alphabetically by firstName
      (groupA, groupB) =>
        groupA.agent.firstName.localeCompare(groupB.agent.firstName),
    )

    const counter = asleepRooms.length
    const hasRooms = counter > 0

    return {
      rooms: removeRoomsWithAssignedAgent(asleepRooms),
      hasRooms,
      counter,
      roomsByAgent,
    }
  }
}

const removeRoomsWithAssignedAgent = (rooms: UIRoom[]) =>
  rooms.filter((room) => room.assignedAgent === null)
