<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIRoom } from '@/domains/room-list/ui-models'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import { isRoomAsleep } from '@/utils/is-room-asleep'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'

const props = defineProps({
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'acceptAssignment'): void
  (e: 'refuseAssignment'): void
}>()

const { t } = useScopedI18n()

const isAsleep = computed<boolean>(() =>
  isRoomAsleep(props.room.status.snoozedUntil, props.room.status.type),
)
</script>

<template>
  <div class="flex flex-col p-5 border-t border-default-100">
    <div class="flex items-center justify-center">
      <button
        class="focus:outline-none text-primary"
        @click="emits('refuseAssignment')"
      >
        {{ t('refuse') }}
      </button>
      <span class="mx-3"></span>
      <BaseDropdown v-if="isAsleep" :placement="DropdownPlacement.Top">
        <template #button>
          <BasePrimaryButton>
            {{ t('accept') }}
          </BasePrimaryButton>
        </template>
        <template #default>
          <div class="p-5 flex flex-col w-80 space-y-3">
            <p class="text-sm">
              {{ t('warningText') }}
            </p>
            <BasePrimaryButton
              class="text-sm"
              @click="emits('acceptAssignment')"
            >
              {{ t('submitButton') }}
            </BasePrimaryButton>
          </div>
        </template>
      </BaseDropdown>
      <BasePrimaryButton v-else @click="emits('acceptAssignment')">
        {{ t('accept') }}
      </BasePrimaryButton>
    </div>
  </div>
</template>
