<script lang="ts" setup>
import { computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { $formatDate } from '@/plugins/vue-helpers/format-date'
import { isToday } from 'date-fns'

const props = defineProps({
  day: {
    type: Date,
    required: true,
  },
})

const { t } = useScopedI18n()

const label = computed(() =>
  isToday(props.day) ? t('today') : $formatDate(props.day, 'long'),
)
</script>
<template>
  <div class="flex flex-row items-center justify-center my-8">
    <div class="flex flex-1 h-px bg-default-100" />
    <div
      class="relative flex flex-row items-center space-x-1 py-2 px-3 rounded-md bg-default-50 border border-default-100 text-xs text-default-light"
    >
      <span>{{ label }}</span>
      <span v-if="!isToday(day)">•</span>
      <div v-if="!isToday(day)">
        {{ t('ago', { date: $timeAgo(day) }) }}
      </div>
    </div>
    <div class="flex flex-1 h-px bg-default-100" />
  </div>
</template>
