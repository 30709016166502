<script lang="ts" setup>
import { PropType, ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import {
  UIPrescriptionPreview,
  UIMobilePrescriptionItem,
} from '@/domains/prescription/ui-models'

import Title from '@/components/panel/side/shared/Title.vue'

defineProps({
  prescriptionPreview: {
    type: Object as PropType<UIPrescriptionPreview>,
    required: true,
  },
})

const { t } = useScopedI18n()

const selectedItem = ref<UIMobilePrescriptionItem | null>(null)
</script>

<template>
  <div class="h-full">
    <div class="flex flex-row flex-1 h-full">
      <div class="flex flex-1 flex-col p-5 border-r border-default-100">
        <iframe
          class="flex flex-1 w-full"
          :src="prescriptionPreview.previewUrl"
        />
      </div>
      <div class="w-96 relative overflow-hidden">
        <div class="overflow-y-scroll scrollbar h-full">
          <div class="flex flex-col m-5">
            <Title class="mb-5">{{ t('mobileTab') }}</Title>
            <div
              v-if="
                prescriptionPreview.hasInstructions &&
                prescriptionPreview.mobile
              "
              class="bg-indigo-50 w-full rounded-lg p-6 mb-6"
            >
              <h5 class="mb-1 font-bold">
                {{ t('instructions') }}
              </h5>
              <p
                v-for="(line, index) in prescriptionPreview.mobile.instructions"
                :key="`instructions-${index}`"
                class="mb-1"
              >
                {{ line }}
              </p>
            </div>

            <div v-if="prescriptionPreview.mobile">
              <div
                v-for="(item, index) in prescriptionPreview.mobile
                  .prescriptionItems"
                :key="`prescription-item-${index}`"
                class="bg-default-100 rounded-lg p-5 mb-5 cursor-pointer"
                @click="selectedItem = item"
              >
                <h3 class="text-xl font-bold mb-0">
                  {{ item.title }}
                </h3>
                <h5 class="font-semibold">
                  {{ item.subtitle }}
                </h5>
                <p v-if="item.description" class="mt-2">
                  {{ item.description }}
                </p>

                <div
                  v-if="item.card"
                  class="relative flex items-center rounded-lg bg-white px-5 pt-6 pb-5 mt-8"
                >
                  <div
                    v-if="item.badge"
                    class="absolute bg-primary px-2 py-px text-white rounded-md -top-3 right-5 flex items-center"
                  >
                    <small>{{ item.badge }}</small>
                  </div>
                  <p>{{ item.card }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Transition>
          <div
            v-if="selectedItem"
            class="flex absolute bottom-0 p-5 w-full bg-default-300 rounded-t-lg overflow-hidden"
          >
            <div class="flex-grow space-y-4">
              <h3 class="text-xl font-bold mb-0">
                {{ selectedItem.title }}
              </h3>
              <p v-if="selectedItem.details">{{ selectedItem.details }}</p>
              <p v-else>{{ t('noDetails') }}</p>
            </div>

            <font-awesome-icon
              :icon="['fal', 'times']"
              class="absolute top-0 right-0 cursor-pointer py-2 px-1"
              fixed-width
              @click="selectedItem = null"
            />
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(100%);
}
</style>
