<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { UIPrescriptionSummary } from '@/domains/prescription/ui-models'
import { sleep } from '@/utils/sleep'
import useClipboard from '@/hooks/clipboard'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'

import BaseDropdown from '@/components/base/BaseDropdown.vue'
import InfoItem from './InfoItem.vue'

defineProps({
  prescription: {
    type: Object as PropType<UIPrescriptionSummary>,
    required: true,
  },
})

const { toClipboard } = useClipboard()
const { t } = useScopedI18n()

const copied = ref(false)

const onCopied = async () => {
  copied.value = true
  await sleep(1000)
  copied.value = false
}
const copy = async (title: string, content: string) => {
  try {
    await toClipboard(`${title}: ${content}`)
    onCopied()
  } catch (e) {
    console.error(e)
    alert(`Error copying ${title} to the clipboard`)
  }
}
</script>

<template>
  <div v-if="copied" class="w-8 h-8 flex items-center justify-center">
    <font-awesome-icon
      class="focus:outline-none text-green-500"
      :icon="['fal', 'check']"
    />
  </div>
  <BaseDropdown
    v-else
    button-custom-class="w-8 h-8 hover:bg-default-100 rounded"
    :placement="DropdownPlacement.BottomEnd"
  >
    <template #button>
      <font-awesome-icon
        class="focus:outline-none text-primary"
        :icon="['fal', 'info-circle']"
      />
    </template>
    <ul>
      <!-- Room URL -->
      <InfoItem
        :title="t('roomURL')"
        :content="$roomURL(prescription.roomId)"
        @copy="copy"
      />

      <!-- Room ID -->
      <InfoItem
        :title="t('roomId')"
        :content="prescription.roomId"
        @copy="copy"
      />

      <!-- Parent ID -->
      <InfoItem
        :title="t('parentId')"
        :content="prescription.parent.id"
        @copy="copy"
      />

      <!-- Prescription ID -->
      <InfoItem
        :title="t('prescriptionId')"
        :content="prescription.id"
        @copy="copy"
      />
    </ul>
  </BaseDropdown>
</template>
