import { computed, ComputedRef, ref } from 'vue'
import { Message } from 'Models/message'
import { useCoreChatStore } from 'Store/stores'
import { useRouter } from 'vue-router'

export default function useManageMedia(): {
  media: ComputedRef<Message | undefined>
  setMediaId: (mediaId: string) => void
  onChangeMedia: (direction: 'prev' | 'next') => void
  isCurrentMediaTheFirstOne: ComputedRef<boolean>
  isCurrentMediaTheLastOne: ComputedRef<boolean>
} {
  const router = useRouter()
  const mediaId = ref()
  const coreChatStore = useCoreChatStore()

  const medias = computed(() => coreChatStore.getCurrentMediaMessages)

  const media = computed<Message | undefined>(() => {
    if (!mediaId.value || !medias.value) return undefined
    return medias.value.find((media) => media.id === mediaId.value)
  })

  const numberOfMedias = computed(() => medias.value?.length - 1)
  const indexOfCurrentMedia = computed(
    () => media.value && medias.value?.indexOf(media.value),
  )
  const isCurrentMediaTheFirstOne = computed(
    () => indexOfCurrentMedia.value === 0,
  )
  const isCurrentMediaTheLastOne = computed(
    () => indexOfCurrentMedia.value === numberOfMedias.value,
  )

  const setMediaId = (id: string) => (mediaId.value = id)
  const onChangeMedia = (direction: 'prev' | 'next') => {
    if (indexOfCurrentMedia.value === undefined) return

    let index: number | undefined
    switch (direction) {
      case 'prev':
        index = indexOfCurrentMedia.value - 1
        break
      case 'next':
        index = indexOfCurrentMedia.value + 1
        break
    }

    if (index === undefined) return
    const message = medias.value[index]
    if (!message) return
    router.push({ query: { media: message.id } })
  }

  return {
    setMediaId,
    media,
    onChangeMedia,
    isCurrentMediaTheFirstOne,
    isCurrentMediaTheLastOne,
  }
}
