import { RouteRecordRaw } from 'vue-router'
import { RoutesNames } from './type'

import Main from '@/domains/main/Main.vue'

export const buildRoomRoutes = (): RouteRecordRaw[] => [
  {
    path: '/room',
    redirect: { name: RoutesNames.Main },
    ...defaultProps,
    children: [
      {
        path: ':roomId',
        name: RoutesNames.ShortcutRoom,
        ...defaultProps,
      },
    ],
  },
  {
    path: '/rooms',
    name: RoutesNames.Main,
    redirect: { name: RoutesNames.RoomsTeam },
    ...defaultProps,
    children: [
      ...currentAgentRooms,
      ...teamRooms,
      ...closedRooms,
      ...unassignedRooms,
      ...snoozedRooms,
      ...favoritesRooms,
      ...inceptionChatRooms,
    ],
  },
]

// Shared with all routes
const defaultProps = {
  component: Main,
  props: true,
  meta: {
    protected: true,
  },
}
const children = {
  path: ':roomId',
  ...defaultProps,
}

//
// Open rooms assigned to the currentAgent
//
const currentAgentRooms = [
  {
    path: 'me',
    name: RoutesNames.RoomsMe,
    ...defaultProps,
    children: [
      {
        ...children,
        name: RoutesNames.RoomsMeRoomDetail,
      },
    ],
  },
]

//
// Open rooms assigned to the team (all agents except currentAgent)
//
const teamRooms = [
  {
    path: 'team',
    name: RoutesNames.RoomsTeam,
    ...defaultProps,
    children: [
      {
        ...children,
        name: RoutesNames.RoomsTeamRoomDetail,
      },
    ],
  },
]

//
// Closed rooms (assigned or not)
//
const closedRooms = [
  {
    path: 'closed',
    name: RoutesNames.RoomsClosed,
    ...defaultProps,
    children: [
      {
        ...children,
        name: RoutesNames.RoomsClosedRoomDetail,
      },
    ],
  },
]

//
// Unassigned rooms
//
const unassignedRooms = [
  {
    path: 'unassigned/',
    name: RoutesNames.RoomsUnassigned,
    ...defaultProps,
    children: [
      {
        ...children,
        name: RoutesNames.RoomsUnassignedRoomDetail,
      },
    ],
  },
]

//
// Snoozed rooms (unassigned)
//
const snoozedRooms = [
  {
    path: 'snoozed',
    name: RoutesNames.RoomsSnoozed,
    ...defaultProps,
    children: [
      {
        ...children,
        name: RoutesNames.RoomsSnoozedRoomDetail,
      },
    ],
  },
]

//
// Favorites rooms
//
const favoritesRooms = [
  {
    path: 'favorites',
    name: RoutesNames.RoomsFavorites,
    ...defaultProps,
    children: [
      {
        ...children,
        name: RoutesNames.RoomsFavoritesRoomDetail,
      },
    ],
  },
]

//
// InceptionChat rooms
//
const inceptionChatRooms = [
  {
    path: 'internal-chat',
    name: RoutesNames.RoomsInceptionChat,
    ...defaultProps,
    children: [
      {
        ...children,
        name: RoutesNames.RoomsInceptionChatRoomDetail,
      },
    ],
  },
]
