import { APIKidAntecedent, KidAntecedent } from 'Models/kid-antecedent'

export const convertFromAPI = (
  antecedents: APIKidAntecedent[],
): KidAntecedent[] => {
  return antecedents.map((antecedent) => ({
    id: antecedent.antecedentId,
    hidden: antecedent.hidden,
  }))
}
