<script lang="ts" setup>
import { PropType } from 'vue'
import { UIPrescription } from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import PrescriptedDrug from './PrescriptedDrug.vue'
import Title from '@/components/panel/side/shared/Title.vue'

defineProps({
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'startNew'): void
  (e: 'togglePrescriptedDrugReplaceable', index: number): void
  (e: 'deletePrescriptedDrug', index: number): void
}>()

const { t } = useScopedI18n()
</script>

<template>
  <div>
    <button
      type="button"
      class="flex flex-row w-full items-center mb-2 px-5 pt-5 pb-3 focus:outline-none text-primary"
      @click="emits('startNew')"
    >
      <Title class="flex flex-1">
        {{ t('title') }}
      </Title>

      <font-awesome-icon
        v-tooltip.auto="t('title')"
        :icon="['far', 'plus']"
        class="focus:outline-none"
      />
    </button>
    <PrescriptedDrug
      v-for="(drug, index) in prescription.items"
      :key="index"
      :prescripted-drug="drug"
      :index="index"
      @toggle-replaceable="emits('togglePrescriptedDrugReplaceable', index)"
      @delete="emits('deletePrescriptedDrug', index)"
    />
  </div>
</template>
