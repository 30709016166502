import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore, useAuthStore } from 'Store/stores'

export const disabledAgentAccountPlugin = () => {
  const { currentAgent } = storeToRefs(useAgentStore())
  const { signOut } = useAuthStore()

  watch(
    () => currentAgent.value,
    async (newCurrentAgent) => {
      if (!!newCurrentAgent && newCurrentAgent.isAccountDisabled)
        await signOut()
    },
    { immediate: true },
  )
}
