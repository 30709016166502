export enum PlanningAgentsTypes {
  Doctors = 'doctors',
  Nurses = 'nurses',
}

export type APIAgentSlot = {
  date: string
  agentId: string
  startHour: string
  endHour: string
}

export type APIPlanning = {
  [agentId: string]: APIAgentSlot[]
}

export type APIAgentDailySlots = {
  date: string
  slots: APIAgentSlot[]
}[]

export type AgentSlot = {
  date: Date
  agentId: string
  startHour: Date
  endHour: Date
}

export type Planning = {
  [agentId: string]: AgentSlot[]
}

export type AgentDailySlots = {
  date: Date
  slots: AgentSlot[]
}[]
