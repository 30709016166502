import { KidParent } from 'Models/kid'
import { MedicalReport } from 'Models/medical-report'
import { Prescription } from 'Models/prescription'
import {
  IKidService,
  IMedicalReportService,
  IPastMedicalReportService,
  IPrescriptionService,
} from './__types__'

export default class PastMedicalReportService
  implements IPastMedicalReportService
{
  medicalReportService: IMedicalReportService
  prescriptionService: IPrescriptionService
  kidService: IKidService

  constructor(
    medicalReportService: IMedicalReportService,
    prescriptionService: IPrescriptionService,
    kidService: IKidService,
  ) {
    this.medicalReportService = medicalReportService
    this.prescriptionService = prescriptionService
    this.kidService = kidService
  }

  async loadAll(
    kidId: string,
    limit?: number,
    exceptRoomId?: string,
  ): Promise<MedicalReport[] | undefined> {
    const [kidParents, medicalReports, prescriptions] = await Promise.all([
      this.kidService.loadKidParents(kidId),
      this.medicalReportService.loadAll(kidId, limit),
      this.prescriptionService.loadAll({ kidId }),
    ])

    if (!kidParents || !medicalReports) return undefined

    if (exceptRoomId && medicalReports[0]?.roomId === exceptRoomId)
      medicalReports.shift()

    if (medicalReports.length === 0) return []

    return medicalReports.map((medicalReport) =>
      this.enhanceMedicalReport(medicalReport, kidParents, prescriptions),
    )
  }

  private enhanceMedicalReport(
    medicalReport: MedicalReport,
    kidParents: KidParent[],
    prescriptions: Prescription[],
  ): MedicalReport {
    medicalReport.kidParent = kidParents.find(
      (kidParent) => kidParent.id === medicalReport.parentId,
    )

    medicalReport.prescription = prescriptions.find(
      (prescription) => prescription.roomId === medicalReport.roomId,
    )

    if (medicalReport.prescription) medicalReport.hasPrescription = true

    return medicalReport
  }
}
