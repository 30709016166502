<script lang="ts" setup>
import { PropType } from 'vue'
import { Agent } from 'Models/agent'
import useScopedI18n from '@/hooks/scoped-i18n'

import PlanningModalAgentItem from './PlanningModalAgentItem.vue'
import Section from '@/components/panel/side/shared/Section.vue'

defineProps({
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  query: {
    type: String,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div v-if="agents.length > 0" class="flex flex-col">
    <div v-for="(agent, index) in agents" :key="`list-${agent.id}-${index}`">
      <PlanningModalAgentItem :agent="agent" :query="query" />
    </div>
  </div>
  <Section v-else class="text-default-light pt-2">{{ t('noResult') }}</Section>
</template>
