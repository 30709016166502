<script lang="ts" setup>
import {
  computed,
  ref,
  provide,
  ComponentInternalInstance,
  Ref,
  PropType,
  defineComponent,
} from 'vue'
import { TabsStateKey } from './symbols'

import BaseCard from './BaseCard.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
  },
  color: {
    type: String as PropType<'default' | 'white' | 'orange' | 'purple'>,
    default: 'default',
  },
  bodyPadding: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['update:modelValue'])

const active = computed(() => props.modelValue)

const tabs: Ref<ComponentInternalInstance[]> = ref([])

const navItems = computed(() => {
  const list: {
    type: 'tab' | 'separator'
    tab?: ComponentInternalInstance
    indexOrName?: string | number
    isActive?: boolean
    isHighlighted?: boolean
  }[] = []
  tabs.value.forEach((tab, index) => {
    const hidden = (tab.props.hidden as boolean) || false
    if (hidden) return

    const indexOrName = (tab.props.name as string | undefined) || index
    list.push({
      type: 'tab',
      tab,
      indexOrName,
      isActive: indexOrName === active.value,
      isHighlighted: !!tab.props.highlighted,
    })
    if (index < tabs.value.length - 1) list.push({ type: 'separator' })
  })
  return list
})

const selectTab = (tab: string | number) => {
  emit('update:modelValue', tab)
}

const hasCustomTabNav = (tab: ComponentInternalInstance) =>
  !!tab.exposed?.hasTitleSlot()

const buildTabNav = (tab: ComponentInternalInstance) => {
  return defineComponent({
    name: 'BaseCardTabNav',
    render() {
      if (!tab.exposed?.renderTitleSlot) return null
      return tab.exposed.renderTitleSlot()
    },
  })
}

provide(TabsStateKey, {
  active,
  tabs,
})
</script>

<template>
  <BaseCard
    :color="color"
    :title-padding="false"
    :body-padding="bodyPadding"
    class="overflow-hidden"
  >
    <template #title>
      <div class="w-full overflow-hidden px-3">
        <div class="flex items-center space-x-3 h-11 overflow-x-auto scrollbar">
          <div
            v-for="(
              { type, tab, indexOrName, isActive, isHighlighted }, index
            ) in navItems"
            :key="index"
            class="group"
            :class="{
              'h-6 w-px bg-default-200': type === 'separator',
              'h-full relative shrink-0': type === 'tab',
            }"
          >
            <div
              v-if="type === 'tab' && tab && indexOrName !== undefined"
              class="px-2 space-x-2 flex items-center h-full"
              :class="{
                'cursor-pointer': !isActive,
                'text-default-600': isActive && !isHighlighted,
                'text-primary': !isActive && !isHighlighted,
                'text-orange-500 font-semibold': isHighlighted,
              }"
              @click="selectTab(indexOrName)"
            >
              <span v-if="tab.props.title">{{ tab.props.title }}</span>
              <component :is="buildTabNav(tab)" v-if="hasCustomTabNav(tab)" />
            </div>
            <div
              v-if="type === 'tab' && tab"
              class="absolute bottom-0 left-0 right-0 border-b-2"
              :class="{
                'border-transparent': !isActive,
                'group-hover:border-primary': !isActive && !isHighlighted,
                'border-primary': isActive && !isHighlighted,
                'group-hover:border-orange-500': !isActive && isHighlighted,
                'border-orange-500': isActive && isHighlighted,
              }"
            />
          </div>
        </div>
      </div>

      <slot name="title-anchor" />
    </template>
    <template #body>
      <slot />
    </template>
  </BaseCard>
</template>
