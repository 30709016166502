import Favico from 'favico.js'
import notificationSound from '@/assets/notification.wav'
import { UIAssignedRoomLists } from '@/domains/room-list/ui-models'

export const triggersIconAndSound = (
  roomList: UIAssignedRoomLists | null,
  oldRoomList?: UIAssignedRoomLists | null,
) => {
  const getNewValue = (): null | {
    hasHasUnreadChanged: boolean
    hasUnread: boolean
  } => {
    if (!roomList || !oldRoomList) return null

    return {
      hasHasUnreadChanged:
        roomList['currentAgentHasUnread'] !==
        oldRoomList['currentAgentHasUnread'],
      hasUnread: roomList['currentAgentHasUnread'],
    }
  }

  const roomListValues = getNewValue()

  if (!roomListValues) return // unable to get the info about the roomList

  //
  // Sound Notification
  //
  if (roomListValues.hasHasUnreadChanged && roomListValues.hasUnread)
    playSound()

  //
  // Displays and removes notification dot on favicon
  //
  roomListValues.hasUnread ? updateFavicon(' ') : resetFavicon()
}

const favicon = new Favico({
  position: 'up',
  animation: 'none',
  type: 'circle',
  bgColor: '#f59f0b',
})

const updateFavicon = (counter: number | string) => {
  try {
    favicon.badge(counter)
  } catch (error) {
    console.log("[WARNING] can't update the favicon badge, reason: ", error)
  }
}

const resetFavicon = () => {
  try {
    favicon.reset()
  } catch (error) {
    console.log("[WARNING] can't reset the favicon badge, reason: ", error)
  }
}

export const playSound = async (): Promise<void> => {
  const sound = new Audio(notificationSound)

  try {
    return sound.play()
  } catch (error) {
    console.log("WARNING: can't play a sound, reason: ", error)
  }
}
