import i18n from '@/plugins/vue-use/i18n'
import { AvailableLocales } from '@/locales'

export function getDurations(locale: AvailableLocales): AvailableDrugDurations {
  const b = (scope: DurationScope, i: number) => {
    const label = i18n.global.tc(`durations.${scope}`, i + 1, locale)
    return {
      label,
      value: i18n.global.t('duration', locale, { duration: label }),
    }
  }

  const days = [...Array(14).keys()].map((i) => b('days', i))
  const weeks = [...Array(5).keys()].map((i) => b('weeks', i))
  const months = [...Array(6).keys()].map((i) => b('months', i))

  return { days, weeks, months }
}

type DurationScope = 'days' | 'weeks' | 'months'
export type AvailableDrugDurations = {
  days: { label: string; value: string }[]
  weeks: { label: string; value: string }[]
  months: { label: string; value: string }[]
}
