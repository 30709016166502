import { GetterTree } from './types'
import { buildUIPrescriptionSummary } from './concerns/build-ui-prescription'
import { useAgentStore } from 'Store/stores'

export const getFilteredPrescriptionList: GetterTree['getFilteredPrescriptionList'] =
  function ({ filteredPrescriptionList }) {
    const agents = useAgentStore().agents

    if (!filteredPrescriptionList || !agents) return null
    return filteredPrescriptionList.map((prescription) =>
      buildUIPrescriptionSummary(prescription, agents),
    )
  }
