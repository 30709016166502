/* eslint-disable @typescript-eslint/no-explicit-any */
export default function useIsChrome(): boolean {
  const isOpera =
    !!(window as any).opera || navigator.userAgent.indexOf(' OPR/') >= 0
  const isEdge = /Edg/.test(navigator.userAgent)
  const isChrome =
    !!(window as any).chrome && // Is true in Opera
    /Chrome/.test(navigator.userAgent) && // Is true in Opera
    /Google Inc/.test(navigator.vendor) && // Is true in Edge
    !isOpera &&
    !isEdge

  return isChrome
}
