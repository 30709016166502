import { Prescription, PrescriptionListFilter } from 'Models/prescription'
import Services from 'Services/services'

export const setPrescriptions = async (
  prescriptionService: Services['prescription'],
  kidService: Services['kid'],
  filter: PrescriptionListFilter,
): Promise<Prescription[]> => {
  const prescriptions = await prescriptionService.loadAll(filter)

  // One big Promise.all to run in // all the requests to Firebase
  await Promise.all([
    ...prescriptions.map(async (prescription) => {
      const kid = await kidService.load(prescription.kidId)
      if (kid) prescription.kid = { ...kid, weight: prescription.kidWeightInKg }
    }),
    ...prescriptions.map(async (prescription) => {
      const parent = await kidService.loadKidParent(prescription.userId)
      if (parent) prescription.parent = { ...parent }
    }),
  ])

  return prescriptions
}
