<script lang="ts" setup>
import { computed, PropType, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import { useAgentStore, useRoomListStore, useRoomStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useRouteIntoTab } from '@/domains/room-list/controllers/route-into-tab'
import { RoutesNames } from '@/router/routes/type'
import { Agent } from 'Models/agent'
import { RoomListFilterChanges } from '@/domains/room-list/store/actions/types'
import { UIRoomListFilter } from '../../ui-models'
import { useRoute } from 'vue-router'
import { getAgentById } from '@/utils/agent/get-agent-by-id'

import RoomListTeam from './RoomListTeam.vue'
import RoomListClosed from './RoomListClosed.vue'
import Tab from '@/domains/room-list/components/lists/shared/Tab.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'

const props = defineProps({
  filter: {
    type: Object as PropType<UIRoomListFilter>,
    required: true,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'updateFilter', changes: RoomListFilterChanges): void
}>()

const route = useRoute()
const { t, tc } = useScopedI18n()
const { isTabTeam, isTabClosed } = useRouteIntoTab()
const { currentAgent } = storeToRefs(useAgentStore())
const roomListStore = useRoomListStore()
const { closedRoomsDate } = storeToRefs(useRoomStore())
const { setClosedRooms, setClosedRoomsDate } = useRoomStore()

const agentPicked = ref<{
  roomListTeam: Agent | null
  roomListClosed: Agent | null
}>({
  roomListTeam: null,
  roomListClosed: null,
})

const roomListTeam = computed(() =>
  roomListStore.getRoomListTeam(agentPicked.value.roomListTeam?.id ?? null),
)
const roomListClosed = computed(() =>
  roomListStore.getRoomListClosed(agentPicked.value.roomListClosed?.id ?? null),
)
const agentPickedTeam = computed({
  get: () => agentPicked.value.roomListTeam,
  set: (newAgentPickedTeam) => (
    (agentPicked.value.roomListTeam = newAgentPickedTeam),
    emits('updateFilter', { agent: newAgentPickedTeam })
  ),
})
const agentPickedClosed = computed({
  get: () => agentPicked.value.roomListClosed,
  set: (newAgentPickedClosed) => (
    (agentPicked.value.roomListClosed = newAgentPickedClosed),
    emits('updateFilter', { agent: newAgentPickedClosed })
  ),
})
const pickedClosedRoomsDate = computed({
  get: () => closedRoomsDate.value ?? new Date(),
  set: (date) => (
    setClosedRoomsDate(date),
    (agentPickedClosed.value = null),
    emits('updateFilter', { date })
  ),
})

watch(
  () => [pickedClosedRoomsDate.value, isTabClosed.value, isTabTeam.value],
  async ([newCloseRoomDate, newIsTabClosed, newIsTabTeam]) => {
    if (newCloseRoomDate || newIsTabClosed) await setClosedRooms()

    if (newIsTabClosed)
      emits('updateFilter', {
        agent: agentPickedClosed.value,
        date: pickedClosedRoomsDate.value,
      })

    if (newIsTabTeam) {
      emits('updateFilter', {
        agent: agentPickedTeam.value,
        date: null,
      })
    }
  },
  { immediate: true },
)

watch(
  () => route.query,
  (newQuery) => {
    if (
      isTabTeam.value &&
      !!newQuery.agentId &&
      newQuery.agentId !== agentPickedTeam.value?.id
    ) {
      agentPickedTeam.value = getAgentById(
        props.agents,
        String(newQuery.agentId),
      )
    }
  },
  { immediate: true },
)

onMounted(() => {
  if (isTabClosed.value) {
    if (props.filter.date) pickedClosedRoomsDate.value = props.filter.date
    if (props.filter.agent)
      agentPicked.value.roomListClosed = props.filter.agent
  }

  if (isTabTeam.value) {
    if (props.filter.agent) agentPicked.value.roomListTeam = props.filter.agent
  }
})
onBeforeUnmount(() => roomListStore.resetRoomListFilter())
</script>

<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <header class="flex flex-row items-center pl-5 pr-3 py-2">
      <div class="flex flex-1 flex-row space-x-4">
        <Tab
          v-if="roomListTeam"
          :to-route-name="RoutesNames.RoomsTeam"
          :is-active="isTabTeam"
          class="flex"
        >
          <div>{{ t('team') }}</div>
          <div class="text-xs">
            <div v-if="roomListTeam.hasFilters" class="tracking-wide">
              ({{ roomListTeam.counterFiltered }}/{{ roomListTeam.counter }})
            </div>
            <div v-else>({{ roomListTeam.counter }})</div>
          </div>
        </Tab>
        <Tab
          v-if="roomListClosed && isTabClosed"
          :to-route-name="RoutesNames.RoomsClosed"
          :is-active="isTabClosed"
          class="flex"
        >
          <div>{{ tc('closed', roomListClosed?.counter ?? 0) }}</div>
          <div v-if="isTabClosed && roomListClosed" class="text-xs">
            <div v-if="roomListClosed.hasFilters" class="tracking-wide">
              ({{ roomListClosed.counterFiltered }}/{{
                roomListClosed.counter
              }})
            </div>
            <div v-else>({{ roomListClosed.counter }})</div>
          </div>
        </Tab>
        <Tab
          v-else
          :to-route-name="RoutesNames.RoomsClosed"
          :is-active="isTabClosed"
          class="flex"
        >
          <div>{{ tc('closed', 0) }}</div>
        </Tab>
      </div>
    </header>

    <RoomListTeam
      v-if="roomListTeam && isTabTeam"
      v-model:agentPicked="agentPickedTeam"
      :room-list="roomListTeam"
      :current-agent="currentAgent"
    />

    <RoomListClosed
      v-if="roomListClosed && isTabClosed"
      v-model:agentPicked="agentPickedClosed"
      v-model:closedRoomsDate="pickedClosedRoomsDate"
      :is-tab-closed="isTabClosed"
      :room-list="roomListClosed"
      :current-agent="currentAgent"
    />
    <div v-else-if="isTabClosed" class="flex h-80">
      <BaseLoader :is-loading="true" />
    </div>
  </div>
</template>
