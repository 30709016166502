import { ActionTree } from './types'

import { addOrUpdateCurrentKidAntecedent } from './add-or-update-current-kid-antecedent'
import { loadKidAntecedents } from './load-kid-antecedents'
import { removeCurrentKidAntecedent } from './remove-current-kid-antecedent'
import { resetCurrentKidParent } from './reset-current-kid-parent'
import { setCurrentKidParent } from './set-current-kid-parent'
import { updateCurrentKid } from './update-current-kid'

export const actions: ActionTree = {
  addOrUpdateCurrentKidAntecedent,
  loadKidAntecedents,
  removeCurrentKidAntecedent,
  resetCurrentKidParent,
  setCurrentKidParent,
  updateCurrentKid,
}
