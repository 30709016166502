import { VTooltipDirective, VTooltipReference } from '../types'
import { showTooltip } from './show-tooltip'
import { cleanTooltip } from './clean-tooltip'

import '@/assets/vTooltip.css'
import { hideTooltip } from './hide-tooltip'
import { getTooltipElements } from './get-tooltip-elements'

export const containerBGColorDefaultClass = 'vtooltip-bg-color'

const directive: VTooltipDirective = {
  //
  // BEFORE MOUNT
  //
  mounted(el: VTooltipReference, binding) {
    // Prevent empty tooltip
    if (!binding.value) return

    const { tooltipContainer, tooltipContent } = getTooltipElements()
    if (!tooltipContainer || !tooltipContent) return

    // SHOW
    el._showTooltip = async () =>
      await showTooltip(
        el,
        tooltipContainer,
        tooltipContent,
        binding.value,
        binding.modifiers,
      )

    // HIDE
    el._hideTooltip = async () =>
      await hideTooltip(el, tooltipContainer, tooltipContent)

    if (!el._showTooltip || !el._hideTooltip) return

    // Listen events
    el.addEventListener('mouseenter', el._showTooltip)
    el.addEventListener('mouseleave', el._hideTooltip)
    el.addEventListener('mousedown', el._hideTooltip)
  },

  //
  // BEFORE UPDATE
  //
  beforeUpdate(el: VTooltipReference, binding) {
    if (!el || !binding.value) return

    const { tooltipContainer, tooltipContent } = getTooltipElements()
    if (!tooltipContainer || !tooltipContent) return

    if (el._hideTooltip && tooltipContainer.hasAttribute('data-show'))
      el._hideTooltip()

    if (binding.value === binding.oldValue) return

    // Unlisten event
    if (el._showTooltip) el.removeEventListener('mouseenter', el._showTooltip)

    // Overwrite to set a new value
    el._showTooltip = async () =>
      await showTooltip(
        el,
        tooltipContainer,
        tooltipContent,
        binding.value,
        binding.modifiers,
      )

    // Listen event
    el.addEventListener('mouseenter', el._showTooltip)
  },

  //
  // BEFORE UNMOUNT
  //
  beforeUnmount(el: VTooltipReference) {
    const { tooltipContainer, tooltipContent } = getTooltipElements()
    if (!tooltipContainer || !tooltipContent) return

    cleanTooltip(tooltipContainer, tooltipContent)

    // Unlisten events
    if (el._showTooltip) el.removeEventListener('mouseenter', el._showTooltip)
    if (el._hideTooltip) {
      el.removeEventListener('mouseleave', el._hideTooltip)
      el.removeEventListener('mousedown', el._hideTooltip)
    }

    // Destroy instance
    el._tooltipInstance?.destroy()

    delete el._showTooltip
    delete el._hideTooltip
    delete el._tooltipInstance
  },
}
export const VTooltip = directive
