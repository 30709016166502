<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { useRouter } from 'vue-router'
import { UICoreChatMessage } from '../ui-models'

import BaseMessageImage from '@/domains/core-chat/components/base/BaseMessageImage.vue'
import BaseMessageVideo from '@/domains/core-chat/components/base/BaseMessageVideo.vue'
import BaseMessageText from '@/domains/core-chat/components/base/BaseMessageText.vue'

const props = defineProps({
  messageId: {
    type: String as PropType<UICoreChatMessage['id']>,
    required: true,
  },
  messageType: {
    type: String as PropType<UICoreChatMessage['type']>,
    required: true,
  },
  messageContent: {
    type: String as PropType<UICoreChatMessage['content']>,
    required: true,
  },
  messageSenderType: {
    type: String as PropType<UICoreChatMessage['sender']['type']>,
    required: true,
  },
})

const router = useRouter()

const isPostedByUser = computed(() => props.messageSenderType === 'user')
const isPostedByRobot = computed(
  () =>
    props.messageSenderType !== 'user' && props.messageSenderType !== 'agent',
)
const isImage = computed(() => props.messageType === 'image')
const isVideo = computed(() => props.messageType === 'video')

const color = computed(() => {
  if (isPostedByRobot.value) return 'white'
  if (isPostedByUser.value) return 'blue'
  return 'default'
})

const openMedia = () => router.push({ query: { media: props.messageId } })
</script>

<template>
  <!-- Image -->
  <BaseMessageImage
    v-if="isImage"
    v-bind="$attrs"
    :src="messageContent"
    :color="color"
    @click="openMedia"
  />

  <!-- Video -->
  <BaseMessageVideo
    v-else-if="isVideo"
    v-bind="$attrs"
    :color="color"
    @click="openMedia"
  />

  <!-- Text -->
  <BaseMessageText
    v-else
    v-bind="$attrs"
    :text="messageContent"
    :color="color"
    :class="isPostedByRobot && 'text-default-light italic'"
  />
</template>
