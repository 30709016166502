<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { sleep } from '@/utils/sleep'

const props = defineProps({
  query: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'update:query', query: string): void
}>()

const { t } = useScopedI18n()

const search = ref<HTMLInputElement | null>(null)

const searchQuery = computed({
  get: () => props.query,
  set: (newQuery) => emits('update:query', newQuery),
})

onMounted(async () => {
  await sleep(100) // NOTE: hack
  search.value?.focus()
})
</script>

<template>
  <div class="flex items-center relative">
    <input
      ref="search"
      v-model="searchQuery"
      class="w-full rounded border border-gray-100 px-3 py-2 h-10 pl-12 focus:outline-none focus:border-primary focus:shadow-lg"
      :placeholder="t('search')"
    />
    <div
      class="absolute flex items-center justify-center border-r border-gray-100 w-10 h-6 rounded-l text-xs"
      :class="searchQuery ? 'text-primary cursor-pointer' : 'text-default-300'"
      @click="searchQuery = ''"
    >
      <font-awesome-icon
        v-if="searchQuery"
        v-tooltip.left="t('erase')"
        :icon="['fal', 'times-circle']"
        class="focus:outline-none"
      />
      <font-awesome-icon
        v-else
        :icon="['fal', 'search']"
        class="focus:outline-none"
      />
    </div>
  </div>
</template>
