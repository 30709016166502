import { State, TPanelsComponentNames } from '../types'
import { NextPanelOptions, ActionTree, PanelStoreActions } from './types'
import { getPanelPosition } from '@/utils/get-panel-position'

export const nextPanel: ActionTree['nextPanel'] = function (
  componentName,
  options,
) {
  managePanel(this.$state, componentName, this.setPanelData, options)
}

function managePanel(
  panels: State,
  componentName: TPanelsComponentNames,
  setPanelData: PanelStoreActions['setPanelData'],
  options?: NextPanelOptions,
): void {
  // Set panel position
  const panelPosition = getPanelPosition(componentName)

  const components = panels[panelPosition].components

  if (components && panelPosition) {
    // Set data
    if (options?.data) {
      setPanelData(panelPosition, options.data)
    }

    if (components.includes(componentName)) {
      const lastIndex = components.length - 1
      const componentIndex = components.indexOf(componentName)

      // Prevent from adding the component if it is already in last position in the components' array
      if (componentIndex === lastIndex) {
        return
      }

      // If the next component is the root component of the components' array then we erase every element from the array in order to keep only the root component
      if (componentIndex === 0) {
        panels[panelPosition].components = []
      }

      // If the next component is already in the components' array but neither first nor last position then we remove it
      if (componentIndex > 0 && componentIndex < lastIndex) {
        panels[panelPosition].components.splice(componentIndex, 1)
      }
    }
  }

  // the next component must be the new root of the components' array
  if (options?.root) {
    panels[panelPosition].components = []
  }

  // we add the next component in the components' array
  panels[panelPosition].components.push(componentName)
}
