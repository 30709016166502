<script lang="ts" setup>
import { computed, useSlots } from 'vue'

const slots = useSlots()

defineEmits(['action'])

const hasRightSlot = computed(() => !!slots.right)
</script>

<template>
  <button
    class="flex px-5 py-5 w-full border-b border-default-100 text-left items-center focus:outline-none"
    @click="$emit('action')"
  >
    <div class="flex flex-1" :class="!hasRightSlot && 'text-primary'">
      <slot><!-- Body --></slot>
    </div>
    <div v-if="hasRightSlot" class="flex text-primary">
      <slot name="right"><!-- Right --></slot>
    </div>
  </button>
</template>
