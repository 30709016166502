<script lang="ts" setup>
import { PropType } from 'vue'
import useOpenRoom from '@/hooks/open-room'
import { UIRoom } from '../../../ui-models'
import { storeToRefs } from 'pinia'
import { useAgentStore, useRoomStore } from 'Store/stores'

import BaseCardList from '@/components/base/BaseCardList.vue'
import RoomSummary from '@/domains/room-list/components/RoomSummary.vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  showCount: {
    type: Boolean,
    required: false,
    default: true,
  },
  list: {
    type: Object as PropType<UIRoom[]>,
    required: true,
  },
  color: {
    type: String as PropType<'default' | 'white' | 'orange' | 'purple'>,
    default: 'default',
  },
  hashUrl: {
    type: String,
    required: false,
  },
})

const { goToRoom } = useOpenRoom()
const { currentRoomId } = storeToRefs(useRoomStore())
const { currentAgent } = storeToRefs(useAgentStore())

const castRoom = (rawRoom: unknown): UIRoom => rawRoom as UIRoom
const visitRoom = (room: UIRoom) => {
  if (currentRoomId.value !== room.id)
    goToRoom(room.id, undefined, undefined, props.hashUrl)
}
</script>

<template>
  <BaseCardList :list="list" :color="color" empty-color="white">
    <template #title="{ count }">
      <slot name="icon" />
      <div class="text-sm">{{ title }}</div>
      <div v-if="showCount" class="text-xs">({{ count }})</div>
    </template>

    <template #item="room">
      <RoomSummary
        :room="castRoom(room)"
        :current-room-id="currentRoomId"
        :current-agent="currentAgent"
        @open-room="goToRoom"
        @click="visitRoom(castRoom(room))"
      />
    </template>
  </BaseCardList>
</template>
