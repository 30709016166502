import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faClipboardUser,
  faClipboardList,
  faAlarmClock,
  faSmile,
  faBolt,
  faExpandAlt,
  faCompressAlt,
  faInfoCircle,
  faTimes,
  faExternalLink,
  faBackspace,
  faFrownOpen,
  faChevronSquareLeft,
  faChevronSquareRight,
  faCheck,
  faClock,
  faNotesMedical,
  faClipboardPrescription,
  faUserMd,
  faEllipsisH,
  faEnvelopeOpenText,
  faAngleLeft,
  faThumbtack,
  faCopy,
  faTimesCircle as faTimesCircleLight,
  faTrashAlt,
  faSquare,
  faSyncAlt,
  faLongArrowRight,
  faUserEdit,
  faInboxOut,
  faMugHot,
  faEyeSlash,
  faEye,
  faSearch,
  faCommentAlt,
  faUserFriends,
  faUserNurse,
  faSpellCheck,
  faBook,
  faPaperclipVertical,
  faMessage,
  faListTree,
  faInboxFull,
  faChartLine,
  faTriangleExclamation,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-light-svg-icons'
import {
  faPaperPlane,
  faUserCheck,
  faCheckSquare,
  faTimesCircle,
  faCaretUp,
  faCaretDown,
  faInbox,
  faWifiSlash,
  faPlayCircle,
  faImage,
  faThumbtack as faThumbtackSolid,
  faUserMd as faUserMdSolid,
  faCaretRight,
  faCaretLeft,
  faMessage as faMessageSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faPlus,
  faChevronRight,
  faChevronDown,
  faChevronDoubleRight,
  faSnooze,
  faWind,
  faHourglassHalf,
  faFolders,
  faLongArrowLeft,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faClipboardUser,
  faClipboardList,
  faAlarmClock,
  faPaperPlane,
  faSmile,
  faBolt,
  faExpandAlt,
  faCompressAlt,
  faUserCheck,
  faCheckSquare,
  faTimesCircle,
  faPlus,
  faChevronRight,
  faChevronDown,
  faInfoCircle,
  faTimes,
  faExternalLink,
  faBackspace,
  faCaretUp,
  faCaretDown,
  faInbox,
  faWifiSlash,
  faPlayCircle,
  faImage,
  faFrownOpen,
  faChevronSquareLeft,
  faChevronSquareRight,
  faCheck,
  faClock,
  faNotesMedical,
  faClipboardPrescription,
  faUserMd,
  faEllipsisH,
  faChevronDoubleRight,
  faEnvelopeOpenText,
  faAngleLeft,
  faThumbtack,
  faThumbtackSolid,
  faCopy,
  faTimesCircleLight,
  faWind,
  faSnooze,
  faUserMdSolid,
  faHourglassHalf,
  faTrashAlt,
  faSquare,
  faSyncAlt,
  faLongArrowRight,
  faUserEdit,
  faInboxOut,
  faCaretRight,
  faMugHot,
  faEyeSlash,
  faEye,
  faSearch,
  faFolders,
  faCommentAlt,
  faUserFriends,
  faUserNurse,
  faCaretLeft,
  faLongArrowLeft,
  faSpellCheck,
  faBook,
  faPaperclipVertical,
  faMessage,
  faMessageSolid,
  faListTree,
  faInboxFull,
  faChartLine,
  faTriangleExclamation,
  faArrowUpRightFromSquare,
)

export { FontAwesomeIcon }
