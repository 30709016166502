import { GetterTree } from './types'
import { useUIStore, useAgentStore, useMedicalReportStore } from 'Store/stores'
import { buildAntecedentCategories } from './concerns/build-antecedent-categories'
import Services from 'Services/services'

export const getCurrentKidAvailableAntecedents: GetterTree['getCurrentKidAvailableAntecedents'] =
  function ({ currentKid }) {
    return (query) => {
      const locale = useUIStore().locale
      const antecedents = useMedicalReportStore().antecedents

      if (!antecedents || !currentKid || !locale) return null

      const allItems = Services.getInstance().medicalReportLibrary.filter(
        antecedents,
        query,
        currentKid,
        useAgentStore().getCurrentAgentGroupIds || [],
        locale,
      )

      return buildAntecedentCategories(
        antecedents,
        allItems,
        currentKid.hiddenAntecedentIds ?? [],
        locale,
        currentKid.antecedentIds ?? [],
      )
    }
  }
