import { ActionTree } from './types'
import Services from 'Services/services'

export const changeCurrentMedicalReport: ActionTree['changeCurrentMedicalReport'] =
  function (changes) {
    if (!this.currentMedicalReport) return

    Services.getInstance().medicalReport.update(
      this.currentMedicalReport.id,
      changes,
    )
  }
