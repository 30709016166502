import { State } from './types'

export const state: State = {
  snoozedRooms: null,
  closedRooms: null,
  closedRoomsDate: null,
  favoritesRooms: null,
  currentRoomId: null,
  currentRoom: null,
  isCurrentRoomJustClosed: false,
  currentKidParentRooms: null,
}
