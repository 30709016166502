import { isEmpty } from '@/utils/is-empty'
import { Room, RoomStatuses } from 'Models/room'
import { storeToRefs } from 'pinia'
import { IRoomService } from 'Services/__types__'
import { useAgentStore } from 'Store/stores'
import { RoutesNames } from '../routes/type'
import { getRoomStatus } from './concerns/get-room-status'
import { getRouteName } from './concerns/get-routing-info'
import { MiddlewareNames, RoomDispatcherMiddleware } from './type'

export default function buildRoomDispatcherMiddleware(
  roomService: IRoomService,
): RoomDispatcherMiddleware {
  return {
    async [MiddlewareNames.RoomDispatcher](to, from, next) {
      const { currentAgent } = storeToRefs(useAgentStore())

      if (!currentAgent.value || isEmpty(to.params)) return next()

      const { roomId, parentId } = to.params

      let roomsStatus: RoomStatuses | null = null
      let room: Room | undefined
      if (roomId) {
        room = await roomService.load(String(roomId))
        roomsStatus = getRoomStatus(room)
      }

      // KidParent
      if (
        parentId &&
        roomsStatus &&
        to.name !== RoutesNames.KidParentRoomDetail
      ) {
        return next({
          ...to,
          name: RoutesNames.KidParentRoomDetail,
          params: { ...to.params, roomsStatus },
        })
      }

      // Rooms
      if (room && roomsStatus && !parentId) {
        const name = getRouteName(from, currentAgent.value.id, room)
        if (to.name === name) return next()
        return next({ ...to, name })
      }

      return next()
    },
  }
}
