import { h, defineComponent, onMounted, ref } from 'vue'
import { Picker } from 'emoji-picker-element'

const EmojiPicker = defineComponent({
  name: 'EmojiPicker',
  setup() {
    const el = ref()

    onMounted(() => {
      const picker = new Picker()
      if (el.value) el.value.appendChild(picker)
    })

    return () =>
      h('div', {
        ref: el,
      })
  },
})

export default EmojiPicker
