<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useMedicalReportStore, usePanelStore } from 'Store/stores'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIKidProfile } from '@/domains/kid/ui-models'
import { PanelsComponentNames } from '@/domains/panel/store/types'

import MedicalReportsHistory from '@/domains/medical-report/components/MedicalReportsHistory.vue'
import LinkToPanel from '@/components/panel/side/shared/block/LinkToPanel.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'

defineProps({
  kidProfile: {
    type: Object as PropType<UIKidProfile>,
    required: true,
  },
})

const { t } = useScopedI18n()
const medicalReportStore = useMedicalReportStore()
const { nextPanel } = usePanelStore()

const thisYear = computed(() => {
  const date = new Date()
  return date.getFullYear()
})

const medicalReports = computed(() => medicalReportStore.getPastMedicalReports)

const showMedicalReportsHistory = () =>
  nextPanel(PanelsComponentNames.MedicalReportsHistory)
</script>
<template>
  <div v-if="medicalReports?.length === 0" class="px-5 py-3">
    <font-awesome-icon :icon="['fal', 'info-circle']" class="mr-1" />
    {{ t('noReports') }}
  </div>
  <div v-else-if="medicalReports">
    <MedicalReportsHistory
      :medical-reports="medicalReports"
      :kid-profile="kidProfile"
      :this-year="thisYear"
      :show-counters="false"
    />
    <LinkToPanel class="text-primary" @click="showMedicalReportsHistory">
      {{ t('openAll') }}
    </LinkToPanel>
  </div>
  <div v-else class="flex h-80">
    <BaseLoader :is-loading="true" />
  </div>
</template>
