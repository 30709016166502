import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  WithFieldValue,
} from 'firebase/firestore'

export const firebaseConverter = <S, D>(
  converterFrom: (attributes: S) => D,
  converterTo?: (attributes: WithFieldValue<D>) => DocumentData,
): FirestoreDataConverter<D> => ({
  toFirestore: (data: WithFieldValue<D>): DocumentData => {
    if (converterTo) return converterTo(data)
    if (!data) return {}
    return data as D
  },
  // converterTo ? converterTo(data) : data?.valueOf || {},
  fromFirestore: (snap: QueryDocumentSnapshot<S>) => converterFrom(snap.data()),
})
