<script lang="ts" setup>
import { PropType } from 'vue'
import { UIPrescription } from '@/domains/prescription/ui-models'
import useScopedI18n from '@/hooks/scoped-i18n'

import Title from '@/components/panel/side/shared/Title.vue'
import BaseAvatar from '@/components/base/BaseAvatar.vue'
import ReadOnlyPrescriptionModalButton from '@/domains/prescription/components/ReadOnlyPrescriptionModalButton.vue'

defineProps({
  roomId: {
    type: String,
    required: true,
  },
  prescription: {
    type: Object as PropType<UIPrescription>,
    required: true,
  },
})

const { t } = useScopedI18n()
</script>

<template>
  <div class="mb-3 border border-default-100 rounded-lg">
    <ReadOnlyPrescriptionModalButton
      v-if="prescription.id"
      class="flex flex-row px-3 items-center bg-default-50 py-2 w-full text-left focus:outline-none border-b border-default-200"
      :prescription-id="prescription.id"
    >
      <div class="flex-1">
        <Title>
          <font-awesome-icon
            :icon="['fal', 'clipboard-prescription']"
            class="focus:outline-none"
          />&nbsp;
          {{ t('prescription') }}
        </Title>
      </div>
      <div class="text-sm text-primary">{{ t('preview') }}</div>
    </ReadOnlyPrescriptionModalButton>

    <div class="px-3 pt-2 mb-3 space-y-2">
      <div
        v-if="prescription.doctor"
        class="flex flex-row items-center space-x-2"
      >
        <Title>{{ t('prescriber') }}</Title>
        <div
          v-tooltip.bottom="$agentTitle(prescription.doctor)"
          class="flex flex-row items-center space-x-1"
        >
          <BaseAvatar
            type="agent"
            :first-name="prescription.doctor.firstName"
            :last-name="prescription.doctor.lastName"
            :profile-pic="prescription.doctor.profilePic"
            :size="1"
          />
          <div>
            {{ $fullname(prescription.doctor) }}
          </div>
        </div>
      </div>
      <div>
        <Title class="mb-1">
          {{ t('treatment') }}
        </Title>
        <ul class="list-inside list-none">
          <li v-for="(item, index) in prescription.items" :key="index">
            <span
              v-tooltip.bottom="{
                content: `
                  ${
                    item.molecules &&
                    `${t('molecule')} <b>${item.molecules}</b><br />`
                  }
                  ${
                    item.presentation.short &&
                    `${t('presentation')} <b>${
                      item.presentation.short
                    }</b><br />`
                  }
                  ${
                    item.dosage.short &&
                    `${t('dosage')} <b>${item.dosage.short}</b><br />`
                  }
                  ${
                    item.duration &&
                    `${t('duration')} <b>${item.duration}</b><br />`
                  }
                  `,
                contentClasses: ['max-w-xs', 'break-words'],
                html: true,
              }"
            >
              • {{ item.drug }}
            </span>
          </li>
        </ul>
      </div>
      <div v-if="prescription.hasInstructions">
        <Title class="mb-1">
          {{ t('instructions') }}
        </Title>
        <p
          v-for="(instruction, index) in prescription.instructions"
          :key="index"
        >
          <span class="italic bg-purple-50 px-1">
            {{ instruction }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
