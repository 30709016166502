import { startOfDay, compareAsc } from 'date-fns/esm/fp'
import { RoomStatuses } from 'Models/room'

export const isRoomAsleep = (
  snoozedUntilDate: Date | null,
  roomStatus: RoomStatuses,
): boolean => {
  const safeToday = startOfDay(new Date())
  const checkDate = () => {
    if (!snoozedUntilDate) return false
    return compareAsc(startOfDay(snoozedUntilDate), safeToday) === -1
  }
  return roomStatus === RoomStatuses.Snoozed && checkDate()
}
