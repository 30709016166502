import i18n from '@/plugins/vue-use/i18n'
import { AvailableLocales } from '@/locales'

export function getPackaging(locale: AvailableLocales): AvailableDrugPackaging {
  const b = (scope: PackagingScope, i: number) => {
    const label = i18n.global.tc(`packaging.${scope}`, i + 1, locale)
    return { label, value: label }
  }

  const shots = [...Array(1).keys()].map((i) => b('shots', i))
  const boxes = [...Array(3).keys()].map((i) => b('boxes', i))
  const vials = [...Array(3).keys()].map((i) => b('vials', i))
  const bottles = [...Array(4).keys()].map((i) => b('bottles', i))
  const tubes = [...Array(4).keys()].map((i) => b('tubes', i))

  return { shots, boxes, vials, bottles, tubes }
}

type PackagingScope = 'shots' | 'boxes' | 'vials' | 'bottles' | 'tubes'
export type AvailableDrugPackaging = {
  shots: { label: string; value: string }[]
  boxes: { label: string; value: string }[]
  vials: { label: string; value: string }[]
  bottles: { label: string; value: string }[]
  tubes: { label: string; value: string }[]
}
