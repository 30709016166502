import {
  BrandedDrugResponse,
  BrandedDrugsResponse,
  IAPIService,
  IAPIServiceOtherInformation,
  PrescriptionDraftResponse,
  PrescriptionPreviewResponse,
  PrescriptionPreviewsResponse,
  PrescriptionsResponse,
  PrescriptionResponse,
  SignInResponse,
  ResetPasswordResponse,
  SpellcheckResponse,
  KidMeasuresResponse,
  PlanningResponse,
  KidParentProblematicResponse,
  KidAntecedentsResponse,
} from './types'
import Credentials, { ResetPasswordAttributes } from 'Models/credentials'
import { post, get, put, remove, setJwt } from './helpers'
import { APIPrescriptionForm, PrescriptionDraft } from 'Models/prescription'
import { toAPIDate } from '@/utils/format-date'
import { APIKidAntecedentForm } from 'Models/kid-antecedent'

export default class APIService implements IAPIService {
  async signIn(credentials: Credentials): Promise<SignInResponse> {
    return post('/agent/login', credentials)
  }
  async signInWithOAuth(token: string): Promise<SignInResponse> {
    return post('/agent/oauth/token', { token })
  }
  async resetPassword({
    agentId,
    passwordToken,
    password,
  }: ResetPasswordAttributes): Promise<ResetPasswordResponse> {
    return post(`/agent/password/${agentId}/${passwordToken}`, {
      password,
    })
  }

  async changeCurrentPassword(newPassword: string): Promise<void> {
    return post('/agent/password', { password: newPassword })
  }

  async refreshJwt(token: string): Promise<SignInResponse> {
    this.setJwt(token)
    return get('/agent/refresh')
  }

  setJwt(token: string): void {
    setJwt(token)
  }

  trackAgent(
    name: string,
    resourceType?: string,
    resourceId?: string,
    otherInformation?: IAPIServiceOtherInformation,
  ): Promise<void> {
    return post('/desk/logs', {
      action: {
        name: name,
        resourceType: resourceType,
        resourceId,
        createdAt: new Date().toISOString(),
        ...otherInformation,
      },
    })
  }

  getAllPrescriptions(
    agentId: string | null,
    doctorId: string | null,
    kidId: string | null,
    startDate: string,
    endDate: string,
    noPDF?: boolean,
  ): Promise<PrescriptionsResponse> {
    return get(`/desk/prescriptions`, {
      params: { agentId, doctorId, kidId, startDate, endDate, noPDF },
    })
  }

  getPrescription(prescriptionId: string): Promise<PrescriptionResponse> {
    return get(`/desk/prescriptions/${prescriptionId}`)
  }

  getPrescriptions(roomId: string): Promise<PrescriptionsResponse> {
    return get(`/rooms/${roomId}/prescriptions`)
  }

  getPrescriptionDraft(roomId: string): Promise<PrescriptionDraftResponse> {
    return get(`/rooms/${roomId}/prescriptions/draft`)
  }

  persistPrescriptionDraft(
    roomId: string,
    prescriptionDraft: PrescriptionDraft,
  ): Promise<void> {
    return post(`/rooms/${roomId}/prescriptions/draft`, [prescriptionDraft])
  }

  getPrescriptionPreview(
    prescriptionId: string,
  ): Promise<PrescriptionPreviewResponse> {
    return get(`/desk/prescriptions/${prescriptionId}/preview`)
  }

  deletePrescription(prescriptionId: string): Promise<void> {
    return remove(`/desk/prescriptions/${prescriptionId}`)
  }

  buildPrescriptionPreview(
    roomId: string,
    prescription: APIPrescriptionForm,
  ): Promise<PrescriptionPreviewsResponse> {
    return post(`/rooms/${roomId}/prescriptions/preview`, [prescription])
  }

  persistPrescription(
    roomId: string,
    prescription: APIPrescriptionForm,
  ): Promise<PrescriptionsResponse> {
    return post(`/rooms/${roomId}/prescriptions`, [prescription])
  }

  getBrandedDrugs(): Promise<BrandedDrugsResponse> {
    return get('/branded-drugs')
  }

  getBrandedDrug(brandedDrugId: string): Promise<BrandedDrugResponse> {
    return get(`/branded-drugs/${brandedDrugId}`)
  }

  getDailyPlanning(date: Date): Promise<PlanningResponse> {
    return get(`/desk/calendar`, {
      params: { date: toAPIDate(date) },
    })
  }

  getAgentDailySlots: IAPIService['getAgentDailySlots'] = (
    agentId,
    startDate,
    endDate,
  ) => {
    return get(`/desk/calendar/agent/${agentId}`, {
      params: {
        startDate: toAPIDate(startDate),
        endDate: toAPIDate(endDate),
      },
    })
  }

  spellcheck(text: string): Promise<SpellcheckResponse> {
    return post('/desk/corrector', { text })
  }

  markSavedReplyAsUsed: IAPIService['markSavedReplyAsUsed'] = (
    roomId,
    userId,
    savedReplyId,
  ) => {
    return post(`/desk/users/${userId}/used-saved-replies`, {
      roomId,
      savedReplyId,
    })
  }

  getUsedSavedReplies: IAPIService['getUsedSavedReplies'] = (userId) => {
    return get(`/desk/users/${userId}/used-saved-replies`)
  }

  getTimeline: IAPIService['getTimeline'] = (roomId) => {
    return get(`/desk/rooms/${roomId}/timeline`)
  }

  getRoomComments: IAPIService['getRoomComments'] = (roomId) => {
    return get(`/desk/rooms/${roomId}/comments`)
  }

  postRoomComment: IAPIService['postRoomComment'] = (roomId, comment) => {
    return post(`/desk/rooms/${roomId}/comments`, { comment })
  }

  updateRoomComment: IAPIService['updateRoomComment'] = (
    roomId,
    commentId,
    comment,
  ) => {
    return put(`/desk/rooms/${roomId}/comments/${commentId}`, { comment })
  }

  getKidMeasures(kidId: string): Promise<KidMeasuresResponse> {
    return get(`/desk/measures/${kidId}`)
  }

  getKidParentProblematic(
    parentId: string,
  ): Promise<KidParentProblematicResponse> {
    return get(`/desk/users/${parentId}/is-problematic`)
  }

  getKidAntecedents(kidId: string): Promise<KidAntecedentsResponse> {
    return get(`/desk/kids/${kidId}/antecedents`)
  }

  postOrUpdateKidAntecedent(
    kidId: string,
    antecedent: APIKidAntecedentForm,
  ): Promise<void> {
    return put(`/desk/kids/${kidId}/antecedents`, antecedent)
  }

  removeKidAntecedent(kidId: string, antecedentId: string): Promise<void> {
    return remove(`/desk/kids/${kidId}/antecedents/${antecedentId}`)
  }
}
