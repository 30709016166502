<script lang="ts" setup>
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import {
  useAuthStore,
  useConfigStore,
  useSystemStore,
  useUIStore,
} from 'Store/stores'
import { checkAuthAndRedirect } from '@/utils/route'
import useIsChrome from './hooks/is-chrome'

// Global layout
import BaseViewport from '@/components/base/BaseViewport.vue'
import Loader from '@/components/Loader.vue'
import Offline from '@/components/Offline.vue'
import Banner from '@/views/Banner.vue'
import SmallScreen from '@/views/SmallScreen.vue'
import BadBrowser from './views/BadBrowser.vue'
import BaseTooltipContainer from './components/base/BaseTooltipContainer.vue'

const router = useRouter()
const route = useRoute()
const isChrome = useIsChrome()
const { isAuthenticated } = storeToRefs(useAuthStore())
const { systemError, isOffline } = storeToRefs(useSystemStore())
const { isLoading } = storeToRefs(useUIStore())
const configStore = useConfigStore()

const config = computed(() => configStore.getConfig)

watch(
  () => isAuthenticated.value,
  (newIsAuthenticated) => {
    checkAuthAndRedirect(
      newIsAuthenticated,
      route.fullPath,
      route.query.nextUrl,
      router.push,
    )
  },
)
</script>

<template>
  <template v-if="isChrome">
    <SmallScreen />
    <BaseViewport>
      <Banner v-if="config" :config="config" :system-error="systemError" />
      <Offline v-if="isOffline" />
      <Loader v-else-if="isLoading" />
      <router-view v-else />
    </BaseViewport>
  </template>
  <BadBrowser v-else />
  <BaseTooltipContainer />
</template>
