<script lang="ts" setup>
import useScopedI18n from '@/hooks/scoped-i18n'

const { t } = useScopedI18n()
</script>

<template>
  <div
    class="text-orange-500 bg-orange-100 text-center border border-orange-500 rounded p-2 m-5"
  >
    {{ t('errorMessage') }}
  </div>
</template>
