import { ActionTree } from './types'
import { Agent } from 'Models/agent'
import { isHourInSlot } from '@/utils/is-hour-in-slot'
import { Room } from 'Models/room'
import { usePlanningStore, useRoomListStore } from 'Store/stores'

export const setAgentsPresence: ActionTree['setAgentsPresence'] = function () {
  const todayPlanning = usePlanningStore().todayPlanning
  const assignedRooms = useRoomListStore().getAssignedRooms ?? []

  if (!this.agents || !todayPlanning) return

  const augmentedAgents: Agent[] = this.agents.map((agent): Agent => {
    let isInCurrentSlot = false
    if (!!todayPlanning[agent.id])
      isInCurrentSlot = todayPlanning[agent.id].some((slot) =>
        isHourInSlot(slot),
      )

    return {
      ...agent,
      numberOfRooms: getNumberOfRooms(assignedRooms, agent),
      isWorking: hasAssignedRoom(assignedRooms, agent.id) || isInCurrentSlot,
    }
  })

  this.agents = augmentedAgents
}

const hasAssignedRoom = (assignedRooms: Room[], agentId: string) => {
  if (!assignedRooms) return false
  return assignedRooms.some(
    ({ assignedAgent }) => assignedAgent?.id === agentId,
  )
}

const getNumberOfRooms = (assignedRooms: Room[], agent: Agent): number =>
  assignedRooms?.filter(({ assignedAgent }) => assignedAgent?.id === agent.id)
    .length ?? 0
