import { Nameable } from 'Models/shared'

export const $fullname = (
  person: Nameable,
  short = true,
): string | undefined => {
  if (!person) return undefined
  return !person.lastName
    ? person.firstName
    : short
    ? `${person.firstName} ${person.lastName.slice(0, 1)}.`
    : `${person.firstName} ${person.lastName}`
}
