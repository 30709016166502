import { containerBGColorDefaultClass } from './index'

// Clean css and reset classes
export const cleanTooltip = (
  tooltipContainer: HTMLElement,
  tooltipContent: HTMLElement,
): void => {
  // hide tooltip
  tooltipContainer.removeAttribute('data-show')
  // Clean style
  tooltipContainer.removeAttribute('style')
  tooltipContainer.removeAttribute('class')
  tooltipContainer.classList.add(containerBGColorDefaultClass)
  tooltipContent.removeAttribute('class')
  tooltipContent.textContent = ''
}
