<script lang="ts" setup>
import { ref } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import useServices from '@/hooks/services'
import { useCoreChatStore } from 'Store/stores'

import BaseModal from '@/components/base/BaseModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import IllustrationsModalBody from './IllustrationsModalBody.vue'

defineProps({
  readOnly: {
    type: Boolean,
    default: false,
  },
})

const { t } = useScopedI18n()
const { postImage } = useCoreChatStore()
const illustrationsCategories =
  useServices().savedReply.getIllustrationsCategories()

const modal = ref<InstanceType<typeof BaseModal>>()
const selectedIllustration = ref<string>()

const onSelectIllustration = (url: string) => (selectedIllustration.value = url)
const closeModal = () => modal.value?.close()
const cancel = () => (selectedIllustration.value = undefined)
const send = () => (
  !!selectedIllustration.value && postImage(selectedIllustration.value),
  closeModal()
)
</script>

<template>
  <BaseModal ref="modal">
    <template #header>
      <h1 class="font-semibold">{{ t('illustrations') }}</h1>
    </template>

    <IllustrationsModalBody
      v-if="illustrationsCategories"
      :categories="illustrationsCategories"
      :selected-illustration="selectedIllustration"
      @select-illustration="onSelectIllustration"
    />

    <template v-if="selectedIllustration && !readOnly" #footer>
      <div class="flex flex-row space-x-5">
        <BaseButton class="bg-white border border-default-200" @click="cancel">
          <span class="text-default-dark">{{ t('cancel') }}</span>
        </BaseButton>
        <BasePrimaryButton @click="send">{{ t('send') }}</BasePrimaryButton>
      </div>
    </template>
  </BaseModal>
</template>
