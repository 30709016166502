<script lang="ts" setup>
import { computed, PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { UIMRLCoreItem } from '@/domains/medical-report/ui-models'

import OriginalSelectableItem from '@/components/panel/side/shared/list/SelectableItem.vue'
import ItemLabel from '@/components/panel/side/shared/list/ItemLabel.vue'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  item: {
    type: Object as PropType<UIMRLCoreItem>,
    required: true,
  },
  query: {
    type: String,
    required: false,
  },
})

const emits = defineEmits<{
  (
    e: 'select',
    {
      item,
      checked,
      hidden,
    }: {
      item: UIMRLCoreItem
      checked: boolean
      hidden: boolean
    },
  ): void
}>()

const { t } = useScopedI18n()

const checked = computed(() => props.item.isAlreadyAdded ?? false)
const hidden = computed(() => props.item.hidden)

const hasHiddenToggler = computed(
  () => props.item.canBeHidden && props.item.isAlreadyAdded,
)

const toggleHidden = () => onChange(checked.value, !hidden.value)

const toggleChecked = () => onChange(!checked.value)

const onChange = (checked: boolean, hidden?: boolean) =>
  emits('select', {
    item: props.item,
    checked,
    hidden: hidden ?? props.item.hidden,
  })
</script>

<template>
  <OriginalSelectableItem
    name="antecedents"
    :item="item"
    @change="toggleChecked"
  >
    <label
      class="flex flex-1 py-1 cursor-pointer"
      :for="`antecedents-${item.id}`"
    >
      <ItemLabel :query="query" :item="item" />
    </label>
    <button
      v-if="hasHiddenToggler"
      v-tooltip.auto="t(hidden ? 'privateTooltip' : 'publicTooltip')"
      class="h-full px-2 ml-4 hover:bg-default-100"
      @click.stop="toggleHidden"
    >
      <font-awesome-icon
        :icon="['fal', hidden ? 'eye-slash' : 'eye']"
        class="focus:outline-none text-primary"
      />
    </button>
  </OriginalSelectableItem>
</template>
