import {
  IRoomSnoozingService,
  IRoomService,
  IInceptionChatService,
} from './__types__'

export default class RoomSnoozingService implements IRoomSnoozingService {
  roomService: IRoomService
  inceptionChatService: IInceptionChatService

  constructor(
    roomService: IRoomService,
    inceptionChatService: IInceptionChatService,
  ) {
    this.roomService = roomService
    this.inceptionChatService = inceptionChatService
  }

  snooze: IRoomSnoozingService['snooze'] = async (
    room,
    currentAgent,
    snoozedUntil,
    assignedAgent,
  ) => {
    await Promise.all([
      this.roomService.snooze(room, currentAgent, snoozedUntil, assignedAgent),
      this.inceptionChatService.close(room.id),
    ])
  }
}
