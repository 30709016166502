<script lang="ts" setup>
import { PropType, useSlots, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { usePanelStore } from 'Store/stores'
import { RoutesNames } from '@/router/routes/type'
import { PanelPosition } from '@/domains/panel/store/types'

const props = defineProps({
  canClose: {
    type: Boolean,
    default: true,
  },
  canPrev: {
    type: Boolean,
    default: true,
  },
  canKill: {
    type: Boolean,
    default: false,
  },
  panelPosition: {
    type: String as PropType<PanelPosition>,
    required: true,
  },
  toRouteName: {
    type: String as PropType<RoutesNames>,
    required: false,
  },
  isInModal: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emits = defineEmits(['onHideModal'])

const { killPanel, rootPanel, prevPanel } = usePanelStore()

const slots = useSlots()
const router = useRouter()
const route = useRoute()

const hasLeftSlot = computed(() => (props.canPrev ? true : !!slots.left))
const hasRightSlot = computed(() => (props.canClose ? true : !!slots.right))

const onClose = () => {
  if (!!props.isInModal) emits('onHideModal')
  else if (props.canKill) {
    killPanel(props.panelPosition)
  } else {
    rootPanel(props.panelPosition)
    if (props.toRouteName)
      router.push({ name: props.toRouteName, hash: route.hash })
  }
}

const onPrev = () => {
  prevPanel(props.panelPosition)
  if (props.toRouteName)
    router.push({ name: props.toRouteName, hash: route.hash })
}
</script>

<template>
  <header
    class="flex flex-row flex-shrink-0 w-full h-16 bg-white border-b border-default-100"
  >
    <aside
      v-if="hasLeftSlot"
      id="left-slot"
      class="flex items-center justify-center"
    >
      <slot name="left">
        <!-- left -->
        <button
          v-if="canPrev"
          v-tooltip.bottom="$t('tooltips.back')"
          class="flex items-center justify-center mx-3 w-8 h-8 focus:outline-none text-primary hover:bg-default-100 rounded"
          @click="onPrev"
        >
          <font-awesome-icon
            :icon="['fal', 'angle-left']"
            class="focus:outline-none text-3xl"
          />
        </button>
      </slot>
    </aside>

    <div
      class="flex flex-1 flex-wrap items-center content-center h-full flex-shrink"
      :class="
        (hasLeftSlot && !hasRightSlot && 'pr-5') ||
        (hasRightSlot && !hasLeftSlot && 'pl-5') ||
        (hasLeftSlot || hasRightSlot ? '' : 'px-5')
      "
    >
      <slot>
        <!-- body -->
      </slot>
    </div>

    <aside
      v-if="hasRightSlot"
      id="right-slot"
      class="flex items-center justify-center overflow-x-hidden"
    >
      <slot name="right">
        <!-- right -->
        <button
          v-if="canClose"
          v-tooltip.bottom="$t('tooltips.close')"
          class="flex items-center justify-center mx-3 w-8 h-8 focus:outline-none text-primary hover:bg-default-100 rounded"
          @click="onClose"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            class="focus:outline-none text-2xl"
          />
        </button>
      </slot>
    </aside>
  </header>
</template>
