<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import useScopedI18n from '@/hooks/scoped-i18n'
import { useSavedReplyStore, useUIStore } from 'Store/stores'
import { KidGender } from 'Models/kid'
import { useSavedReply } from '@/domains/saved-reply/controllers/saved-reply'

import BaseModal from '@/components/base/BaseModal.vue'
import List from './List.vue'
import SavedReplyPreview from './SavedReplyPreview.vue'
import SearchResult from '@/domains/saved-reply/components/SearchResult.vue'
import SearchInput from '@/domains/saved-reply/components/SearchInput.vue'

const { t } = useScopedI18n()
const { locale } = storeToRefs(useUIStore())
const savedReplyStore = useSavedReplyStore()

const {
  uiSavedReplies,
  language,
  queriedSavedReplies,
  isTitleOnly,
  numberOfResults,
  selectedSavedReplyId,
  onSwitchLanguage,
  savedReply,
  query,
} = useSavedReply({
  locale,
  savedReplyStore,
  parentFirstName: 'LeParentDeToto',
  kidFirstName: 'Toto',
  kidSex: KidGender.Male,
})

const onPickSavedReply = (savedReplyId: string) =>
  (selectedSavedReplyId.value = savedReplyId)
</script>

<template>
  <BaseModal :is-loading="false">
    <template #header>
      <h1 class="font-semibold text-base">{{ t('title') }}</h1>
    </template>

    <div v-if="uiSavedReplies" class="flex flex-col flex-grow max-h-full">
      <div
        class="flex flex-row flex-1 overflow-y-auto scrollbar overflow-x-hidden"
      >
        <SearchResult
          v-if="query"
          :saved-replies="queriedSavedReplies"
          :selected-saved-reply="savedReply?.id"
          @pick-saved-reply="onPickSavedReply"
        />
        <List
          :class="query ? 'hidden' : 'block'"
          :saved-replies="uiSavedReplies"
          @pick-saved-reply="onPickSavedReply"
        />
        <SavedReplyPreview
          v-if="savedReply"
          :query="query"
          :saved-reply="savedReply"
        />
      </div>
      <SearchInput
        v-model:query="query"
        v-model:isTitleOnly="isTitleOnly"
        class="border-b"
        :language="language"
        :number-of-results="numberOfResults"
        @switch-language="onSwitchLanguage"
      />
    </div>
  </BaseModal>
</template>
