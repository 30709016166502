import { GetterTree } from './types'
import { useAgentStore, usePrescriptionStore, useUIStore } from 'Store/stores'
import { PrescriptionStatus } from 'Models/prescription'
import { buildUIMedicalReport } from './concerns/build-ui-medical-report'

export const getCurrentMedicalReport: GetterTree['getCurrentMedicalReport'] =
  function ({ currentMedicalReport }) {
    const locale = useUIStore().locale
    const agents = useAgentStore().agents

    if (
      !currentMedicalReport ||
      !this.supervisions ||
      !this.causes ||
      !this.diagnosis ||
      !this.directions ||
      !agents ||
      !locale
    )
      return null

    const currentPrescription = usePrescriptionStore().currentPrescription

    const prescription =
      currentPrescription?.status === PrescriptionStatus.Created
        ? currentPrescription
        : null

    return buildUIMedicalReport(
      currentMedicalReport,
      prescription,
      locale,
      agents,
      this.causes,
      this.diagnosis,
      this.directions,
      this.supervisions,
    )
  }
