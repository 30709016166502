import { differenceInSeconds, isSameDay } from 'date-fns'
import {
  UICoreChatMessage,
  UICoreChatMessageListGroupedByDay,
} from './ui-models'

const DEFAULT_TIME_BETWEEN_TWO_CONSECUTIVE_MESSAGES_IN_SECONDS = 60

export const groupMessagesByDay = (
  list: UICoreChatMessage[],
): UICoreChatMessageListGroupedByDay[] => {
  const groups: UICoreChatMessageListGroupedByDay[] = []
  let lastMessage: UICoreChatMessage | null = null

  list.forEach((message) => {
    if (lastMessage && isSameDay(message.postedAt, lastMessage.postedAt))
      groups[groups.length - 1].messages.push(message)
    else
      groups.push({
        day: message.postedAt,
        messages: [message],
      })

    lastMessage = message
  })

  return groups
}

export const gatherBurstMessages = (
  list: UICoreChatMessage[],
  timeBetweenTwoMessagesInSeconds?: number,
): UICoreChatMessage[] => {
  const newList: UICoreChatMessage[] = []
  let lastMessage: UICoreChatMessage | null = null
  let lastPostedAt: Date | null = null

  list.forEach((originalMessage) => {
    if (!timeBetweenTwoMessagesInSeconds)
      timeBetweenTwoMessagesInSeconds =
        DEFAULT_TIME_BETWEEN_TWO_CONSECUTIVE_MESSAGES_IN_SECONDS

    // get a fresh copy of the original message to avoid messing with the original list
    const message: UICoreChatMessage = {
      ...originalMessage,
      burstMessages: [],
    }

    const timeBetween = lastPostedAt
      ? differenceInSeconds(message.postedAt, lastPostedAt)
      : timeBetweenTwoMessagesInSeconds + 1

    if (
      timeBetween > timeBetweenTwoMessagesInSeconds ||
      message.sender?.id !== lastMessage?.sender?.id
    ) {
      newList.push(message)
      lastMessage = message
    } else if (lastMessage) {
      lastMessage.burstMessages.push(message)
    }

    lastPostedAt = message.postedAt
  })

  return newList
}
