<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { usePlanningStore } from 'Store/stores'
import { Planning } from 'Models/planning'
import { Agent, AgentPickable } from 'Models/agent'
import { getAgentsInPlanningSlot } from '@/utils/agent/get-agents-in-planning-slot'
import { getAgentsWithRoom } from '@/utils/agent/get-agents-with-room'
import { getInactiveAgents } from '@/utils/agent/get-inactive-agents'

import AgentPickerCategory from './AgentPickerCategory.vue'

const props = defineProps({
  selectedAgent: {
    type: Object as PropType<AgentPickable | null>,
    required: false,
  },
  currentAgent: {
    type: Object as PropType<Agent>,
    required: true,
  },
  agents: {
    type: Array as PropType<Agent[]>,
    required: true,
  },
  showCounters: {
    type: Boolean,
    default: true,
  },
  showNumberOfRoomAssignedByAgent: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{
  (e: 'selectAgent', agent: Agent): void
}>()

const planning = computed<Planning | null>(
  () => usePlanningStore().$state.todayPlanning,
)

// Assigned agents
const assignedAgents = computed(() => {
  return getAgentsWithRoom(props.agents)
})
const hasAssignedAgents = computed(
  () => assignedAgents.value && assignedAgents.value.length > 0,
)

// Inactive agents
const inactiveAgents = computed(() => {
  return getInactiveAgents(props.agents)
})
const hasInactiveAgents = computed(
  () => inactiveAgents.value && inactiveAgents.value.length > 0,
)

// Agents in current slot
const currentPlanningSlotAgents = computed(() => {
  if (!planning.value) return null
  return getAgentsInPlanningSlot(props.agents, planning.value)
})
const hasCurrentSlotAgents = computed(
  () =>
    currentPlanningSlotAgents.value &&
    currentPlanningSlotAgents.value.length > 0,
)

// Agents in slot H+1
const h1PlanningSlotAgents = computed(() => {
  if (!planning.value) return null
  return getAgentsInPlanningSlot(props.agents, planning.value, 1)
})
const hasH1PlanningSlotAgents = computed(
  () => h1PlanningSlotAgents.value && h1PlanningSlotAgents.value.length > 0,
)

// Agents in slot H+2
const h2PlanningSlotAgents = computed(() => {
  if (!planning.value) return null
  return getAgentsInPlanningSlot(props.agents, planning.value, 2)
})
const hasH2PlanningSlotAgents = computed(
  () => h2PlanningSlotAgents.value && h2PlanningSlotAgents.value.length > 0,
)

// Agents in slot H+3
const h3PlanningSlotAgents = computed(() => {
  if (!planning.value) return null
  return getAgentsInPlanningSlot(props.agents, planning.value, 3)
})
const hasH3PlanningSlotAgents = computed(
  () => h3PlanningSlotAgents.value && h3PlanningSlotAgents.value.length > 0,
)

const hasAgents = computed(
  () =>
    hasAssignedAgents.value ||
    hasInactiveAgents.value ||
    hasCurrentSlotAgents.value ||
    hasH1PlanningSlotAgents.value ||
    hasH2PlanningSlotAgents.value ||
    hasH3PlanningSlotAgents.value,
)
</script>

<template>
  <div v-if="hasAgents" class="overflow-y-auto scrollbar">
    <AgentPickerCategory
      v-if="currentPlanningSlotAgents && hasCurrentSlotAgents"
      title="currentSlot"
      :selected-agent="selectedAgent"
      :agents="currentPlanningSlotAgents"
      :show-counters="showCounters"
      :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
      :is-active="true"
      @on-select-agent="(agent) => emits('selectAgent', agent)"
    />
    <AgentPickerCategory
      v-if="assignedAgents && hasAssignedAgents"
      title="assigned"
      :selected-agent="selectedAgent"
      :agents="assignedAgents"
      :show-counters="showCounters"
      :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
      @on-select-agent="(agent) => emits('selectAgent', agent)"
    />
    <AgentPickerCategory
      v-if="h1PlanningSlotAgents && hasH1PlanningSlotAgents"
      title="nextSlotH1"
      :selected-agent="selectedAgent"
      :agents="h1PlanningSlotAgents"
      :show-counters="showCounters"
      :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
      @on-select-agent="(agent) => emits('selectAgent', agent)"
    />
    <AgentPickerCategory
      v-if="h2PlanningSlotAgents && hasH2PlanningSlotAgents"
      title="nextSlotH2"
      :selected-agent="selectedAgent"
      :agents="h2PlanningSlotAgents"
      :show-counters="showCounters"
      :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
      @on-select-agent="(agent) => emits('selectAgent', agent)"
    />
    <AgentPickerCategory
      v-if="h3PlanningSlotAgents && hasH3PlanningSlotAgents"
      title="nextSlotH3"
      :selected-agent="selectedAgent"
      :agents="h3PlanningSlotAgents"
      :show-counters="showCounters"
      :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
      @on-select-agent="(agent) => emits('selectAgent', agent)"
    />
    <AgentPickerCategory
      v-if="inactiveAgents && hasInactiveAgents"
      title="inactive"
      :selected-agent="selectedAgent"
      :agents="inactiveAgents"
      :show-counters="showCounters"
      :show-number-of-room-assigned-by-agent="showNumberOfRoomAssignedByAgent"
      :is-last-category="true"
      @on-select-agent="(agent) => emits('selectAgent', agent)"
    />
  </div>
</template>
