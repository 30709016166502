<script lang="ts" setup>
import { PropType } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import {
  UIMRLCategory,
  UIMRLCoreItem,
} from '@/domains/medical-report/ui-models'
import { MRLStrictTopics } from 'Models/medical-report-library-item'

import Body from '@/components/panel/side/layout/Body.vue'
import SingleSelectableItem from '@/components/panel/side/shared/list/SingleSelectableItem.vue'
import Section from '@/components/panel/side/shared/Section.vue'

defineProps({
  topic: {
    type: String as PropType<MRLStrictTopics>,
    required: true,
  },
  groupedItems: {
    type: Array as PropType<UIMRLCategory[]>,
    required: true,
  },
  query: {
    type: String,
    required: false,
  },
})

const emits = defineEmits<{
  (
    e: 'select',
    {
      item,
      checked,
      hidden,
    }: { item: UIMRLCoreItem; checked: boolean; hidden: boolean },
  ): void
}>()

const { t } = useScopedI18n()
</script>
<template>
  <Body class="h-60 py-2">
    <div v-if="groupedItems[0].items.length > 0">
      <SingleSelectableItem
        v-for="(item, index) in groupedItems[0].items"
        :key="`mrl-category-0-item-${index}`"
        :item="item"
        :name="topic"
        :query="query"
        @change="emits('select', { ...$event })"
      />
    </div>
    <Section v-else class="mt-5 text-default-light">
      {{ t('noResult') }}
    </Section>
  </Body>
</template>
