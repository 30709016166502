import {
  differenceInMonths,
  differenceInDays,
  addMonths,
  getDaysInMonth,
} from 'date-fns'

export const kidAgeInMonths = (birthdate: Date, today?: Date): number => {
  return differenceInMonths(today || new Date(), birthdate)
}

/**
 * Return the difference in months (floating number)
 * @param date1
 * @param date2
 */
export const preciseKidAgeInMonths = (birthdate: Date, today?: Date) => {
  today = today || new Date()
  const months = differenceInMonths(today, birthdate)
  const tmpDate = addMonths(birthdate, months)
  const days = differenceInDays(today, tmpDate) / getDaysInMonth(tmpDate)
  return months + Math.trunc(days * 1000) / 1000
}
