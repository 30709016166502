import { kidAgeInMonths } from '@/utils/kid-age-in-months'
import { IAPIService } from 'External/api/types'
import {
  BrandedDrug,
  BrandedDrugFilter,
  BrandedDrugFilterSex,
} from 'Models/branded-drug'
import { IDrugService } from './__types__'
import { Kid } from 'Models/kid'
import { convertFromAPI as convertBrandedDrugFromAPI } from 'Models/converters/branded-drug-converter'
import { convertFromAPI as convertDrugsFromAPI } from 'Models/converters/drug-converter'
import { Drug } from 'Models/drug'
import includes from '@/utils/string-includes'

export default class DrugService implements IDrugService {
  // dependencies
  apiService: IAPIService

  constructor(apiService: IAPIService) {
    this.apiService = apiService
  }

  async loadBrandedDrugs(): Promise<BrandedDrug[]> {
    const response = await this.apiService.getBrandedDrugs()
    return response.brandedDrugs.map(convertBrandedDrugFromAPI)
  }

  filterBrandedDrugs(
    brandedDrugs: BrandedDrug[],
    kid: Kid,
    agentGroupIds: string[],
    query?: string | null,
  ): BrandedDrug[] {
    return brandedDrugs.filter(
      (brandedDrug) =>
        this.acceptBrandedDrug(brandedDrug, kid, agentGroupIds) &&
        (!query ||
          includes(brandedDrug.name, query) ||
          brandedDrug.molecules.some((mol) => includes(mol.name, query))),
    )
  }

  async loadDrugs(brandedDrugId: string): Promise<Drug[]> {
    const response = await this.apiService.getBrandedDrug(brandedDrugId)
    return convertDrugsFromAPI(response.brandedDrug.drugs)
  }

  private acceptBrandedDrug(
    brandedDrug: BrandedDrug,
    kid: Kid,
    agentGroupIds: string[],
  ): boolean {
    return (
      this.doesBrandedDrugMatchKid(brandedDrug.filter, kid) &&
      this.doesBrandedDrugMatchAgentGroups(brandedDrug.filter, agentGroupIds)
    )
  }

  protected doesBrandedDrugMatchKid(
    filter: BrandedDrugFilter,
    kid: Kid,
  ): boolean {
    const ageInMonths = kidAgeInMonths(kid.birthDate)
    if (!filter) return true
    return (filter.sex === BrandedDrugFilterSex.All || kid.sex.valueOf() === filter.sex.valueOf())
  }

  protected doesBrandedDrugMatchAgentGroups(
    filter: BrandedDrugFilter,
    agentGroupIds: string[],
  ): boolean {
    if (!filter.agentGroupIds || filter.agentGroupIds.length === 0) return true
    return (
      filter.agentGroupIds.includes('all') ||
      filter.agentGroupIds.includes('ALL') ||
      filter.agentGroupIds.some((groupId) => agentGroupIds.includes(groupId))
    )
  }
}
