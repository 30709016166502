import { ActionTree } from './types'
import { useAgentStore, useMedicalReportStore } from 'Store/stores'
import Services from 'Services/services'

export const reOpenRoom: ActionTree['reOpenRoom'] = async function () {
  const currentAgent = useAgentStore().currentAgent
  const currentMedicalReport = useMedicalReportStore().currentMedicalReport

  if (!this.currentRoom || !currentAgent || !currentMedicalReport) return

  await Services.getInstance().roomClosing.reOpen(
    this.currentRoom,
    currentAgent,
    currentMedicalReport.id,
  )
}
