<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { UIMRLItem } from '@/domains/medical-report/ui-models'
import { MRLStrictTopics } from 'Models/medical-report-library-item'
import { isEmpty } from '@/utils/is-empty'

import List from '@/components/panel/side/shared/list/List.vue'
import Item from '@/components/panel/side/shared/list/Item.vue'
import CategoryHeader from './CategoryHeader.vue'

const props = defineProps({
  topic: {
    type: String as PropType<MRLStrictTopics>,
    required: true,
  },
  items: {
    type: Array as PropType<UIMRLItem[]>,
    required: false,
  },
  single: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<{
  (e: 'remove', topic: MRLStrictTopics, item: UIMRLItem): void
}>()

const canDisplayAddButton = computed(
  () => !props.single || (props.items || []).length === 0,
)
</script>

<template>
  <div class="pb-3">
    <CategoryHeader
      :can-add-several="canDisplayAddButton"
      :topic="topic"
      :length="items?.length ?? 0"
      v-bind="$attrs"
    />

    <main v-if="!isEmpty(items)" class="pl-2">
      <List>
        <Item
          v-for="(item, itemIndex) in items"
          :key="`list-${topic}-${itemIndex}`"
          :item="item"
          @remove="emits('remove', topic, item)"
        />
      </List>
    </main>
  </div>
</template>
