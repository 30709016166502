<script lang="ts" setup>
import { PropType, ref, computed, Ref } from 'vue'
import { UIBrandedDrug, UIDrugDosage } from '@/domains/prescription/ui-models'
import { isEmpty } from '@/utils/is-empty'
import useScopedI18n from '@/hooks/scoped-i18n'
import { PanelPosition } from '@/domains/panel/store/types'

import Header from '@/views/layouts/panels/Header.vue'
import Body from '@/components/panel/side/layout/Body.vue'
import Title from '@/components/panel/side/shared/Title.vue'
import LinkToPanel from '@/components/panel/side/shared/block/LinkToPanel.vue'
import Summary from './Summary.vue'
import Spacer from '@/components/panel/side/shared/Spacer.vue'
import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import TitleAndSubTitleSlots from '@/components/panel/side/shared/TitleAndSubTitleSlots.vue'
import BaseAccordion from '@/components/base/BaseAccordion.vue'
import Section from '@/components/panel/side/shared/Section.vue'

const emits = defineEmits(['select'])

const props = defineProps({
  panelPosition: {
    type: String as PropType<PanelPosition.Left>,
    required: true,
  },
  brandedDrug: {
    type: Object as PropType<UIBrandedDrug>,
    required: true,
  },
  dosages: {
    type: Array as PropType<UIDrugDosage[]>,
    required: true,
  },
  kidWeight: {
    type: Number,
    required: true,
  },
  kidAgeToday: {
    type: String,
    required: true,
  },
})

const { t, tc } = useScopedI18n()

const drugNameInput: Ref<string | null> = ref(null)
const dosageNameInput: Ref<string | null> = ref(null)

const haveDrugDosages = computed(() => !isEmpty(props.dosages))
const isCustomDrugDosageValid = computed(
  () => !isEmpty(drugNameInput.value) && !isEmpty(dosageNameInput.value),
)
const customDrugDosage = computed<UIDrugDosage | null>(() => {
  if (!drugNameInput.value || !dosageNameInput.value) return null
  return { drugName: drugNameInput.value, dosageName: dosageNameInput.value }
})

const submitCustomDrugDosage = () => {
  if (!isCustomDrugDosageValid.value || !customDrugDosage.value) return
  emits('select', customDrugDosage.value)
}
</script>

<template>
  <Header :panel-position="panelPosition" :can-close="false">
    <TitleAndSubTitleSlots>
      <template #title>
        {{ t('title') }}
      </template>
      <template #subTitle>
        {{ brandedDrug.name }}
      </template>
    </TitleAndSubTitleSlots>
  </Header>

  <Summary :kid-age-today="kidAgeToday" :kid-weight="kidWeight" />
  <Title class="p-5 mb-3 shadow-lg">
    {{
      tc('drugs', dosages.length, {
        drugName: brandedDrug.name,
      })
    }}
  </Title>

  <Body>
    <ul class="list-none list-inside divide-y divide-default-100">
      <li
        v-for="(dosage, index) in dosages"
        :key="index"
        class="hover:bg-blue-50"
      >
        <LinkToPanel class="border-none" @click="emits('select', dosage)">
          <div class="flex space-x-2">
            <div class="font-semibold">
              {{ dosage.drugName }}
            </div>
            <div>({{ dosage.dosageName }})</div>
          </div>
          <template #right>
            <font-awesome-icon
              :icon="['fal', 'chevron-square-right']"
              class="focus:outline-none text-xl"
            />
          </template>
        </LinkToPanel>
      </li>
    </ul>

    <BaseAccordion
      class="border-default-100"
      header-classes="py-5"
      :class="{ 'border-t': haveDrugDosages }"
      :force-active="!haveDrugDosages"
    >
      <template #title>{{ t('customDosage') }}</template>
      <Section>
        <form id="customDrugDosage" @submit.prevent="submitCustomDrugDosage">
          <label for="drugName" class="text-default-light">
            {{ t('presentation') }}
          </label>
          <input
            id="drugName"
            v-model.trim="drugNameInput"
            type="text"
            name="drugName"
            class="w-full rounded border border-gray-100 px-3 py-2 h-10 outline-none"
            :placeholder="t('presentationPlaceholder')"
          />
          <Spacer />
          <label for="dosageName" class="text-default-light">
            {{ t('dosage') }}
          </label>
          <input
            id="dosage"
            v-model="dosageNameInput"
            type="text"
            name="dosageName"
            class="w-full rounded border px-3 py-2 h-10 outline-none border-gray-100"
            :placeholder="t('dosagePlaceholder')"
          />
          <Spacer />
          <BasePrimaryButton :disabled="!isCustomDrugDosageValid">
            {{ t('button') }}
          </BasePrimaryButton>
        </form>
      </Section>
    </BaseAccordion>
  </Body>
</template>
