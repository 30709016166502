<script lang="ts" setup>
import { PropType, computed } from 'vue'
import useScopedI18n from '@/hooks/scoped-i18n'
import { DropdownPlacement } from '@/plugins/__types__/popper'
import { isRoomAsleep } from '@/utils/is-room-asleep'
import { UIRoom } from '@/domains/room-list/ui-models'

import BasePrimaryButton from '@/components/base/BasePrimaryButton.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'

const props = defineProps({
  room: {
    type: Object as PropType<UIRoom>,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'selectAgent'): void
}>()

const { t } = useScopedI18n()

const isAsleep = computed<boolean>(() =>
  isRoomAsleep(props.room.status.snoozedUntil, props.room.status.type),
)
</script>

<template>
  <div class="flex flex-col p-5 border-t border-default-100">
    <div class="flex flex-col items-center justify-center">
      <BaseDropdown
        v-if="isAsleep"
        :placement="DropdownPlacement.Top"
        button-custom-class="px-5 py-2 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 bg-primary text-white"
      >
        <template #button>
          <font-awesome-icon :icon="['fa', 'user-check']" class="mr-3" />
          {{ t('sentence') }}
        </template>
        <template #default>
          <div class="p-5 flex flex-col w-80 space-y-3">
            <p class="text-sm">{{ t('warningText') }}</p>
            <BasePrimaryButton class="text-sm" @click="emits('selectAgent')">
              {{ t('submitButton') }}
            </BasePrimaryButton>
          </div>
        </template>
      </BaseDropdown>
      <BasePrimaryButton v-else @click="emits('selectAgent')">
        <font-awesome-icon :icon="['fa', 'user-check']" class="mr-3" />
        {{ t('sentence') }}
      </BasePrimaryButton>
    </div>
  </div>
</template>
