import { APIAgentSlot, AgentSlot } from 'Models/planning'

export const convertFromAPI = (agentSlot: APIAgentSlot): AgentSlot => {
  return {
    date: new Date(agentSlot.date),
    agentId: agentSlot.agentId,
    startHour: new Date(agentSlot.startHour),
    endHour: new Date(agentSlot.endHour),
  }
}
